import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { loop } from 'assets/images/pages/home';
import { Image } from 'components';

const keyPrefix = 'home';

const Loop = (props) => {
  const { blur = 2, children, ...rest } = props;
  const { t } = useTranslation('pages', { keyPrefix });

  return (
    <Box
      position="relative"
      display="flex"
      {...rest}
      width={177}
      height={177}
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
    >
      {children}

      <Box
        width={175}
        height={175}
        borderRadius="50%"
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          backdropFilter: `blur(${blur}px)`,
          transform: 'translate(-50%, -50%)',
        }}
      />

      <Image
        src={loop}
        alt={t('loop.alt')}
        top={-13}
        position="absolute"
      />
    </Box>
  );
};

export default Loop;
