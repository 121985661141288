export const {
  NODE_ENV: env,
  REACT_APP_API: api,
} = process.env;

export const dev = env === 'development';
export const prod = env === 'production';
export const test = env === 'test';

const config = {
  env,
  dev,
  prod,
  test,
  api,
};

export default config;
