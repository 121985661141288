export const paragraph = {
  fontSize: '16px',
  fontWeight: 300,
  letterSpacing: '0.01em',
  lineHeight: '24px',
};

export const paragraph_sm = {
  fontSize: '14px',
  fontWeight: 300,
  letterSpacing: '0.02em',
  lineHeight: '20px',
};

export const display_xl = {
  fontSize: '48px',
  fontWeight: 600,
  letterSpacing: '-0.02em',
  lineHeight: '90px',
};

export const display_l = {
  fontSize: '32px',
  fontWeight: 600,
  letterSpacing: '-0.02em',
  lineHeight: '64px',
};

export const display_m = {
  fontSize: '24px',
  fontWeight: 500,
  letterSpacing: '-0.02em',
  lineHeight: '48px',
};

export const display_sm = {
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '36px',
  letterSpacing: '-0.012em',
};

export const display_s = {
  fontSize: '18px',
  fontWeight: 500,
  letterSpacing: '-0.02em',
  lineHeight: '36px',
};

export const body_m = {
  fontSize: '16px',
  fontWeight: 500,
  letterSpacing: '0.015em',
  lineHeight: '24px',
};

export const body_s = {
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0.015em',
  lineHeight: '24px',
};

export const body_xs = {
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.015em',
  lineHeight: '24px',
};

export const caption1 = {
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.01em',
  lineHeight: '24px',
};

export const caption2 = {
  fontSize: '12px',
  fontWeight: 300,
  lettterSpacing: '0.01em',
  lineHeight: '16px',
};

export const button_l = {
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '0.015em',
  lineHeight: '24px',
};

export const button_m = {
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.015em',
  lineHeight: '28px',
};

export const button_s = {
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.015em',
  lineHeight: '24px',
};

export const chip_m = {
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '18px',
};

export const chip_s = {
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
};

export const badge = {
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '18px',
};

export const button_tab = {
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.02em',
  lineHeight: '20px',
};

export const overline1 = {
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.02em',
  lineHeight: '18px',
  textTransform: 'uppercase',
};
