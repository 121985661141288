import { getArray } from 'utils/helpers';

import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

export const mapParentToFormData = (parent, studentId) => {
  const relation = parent.relations.find((r) => r.student_id === studentId);

  return {
    parent_id: parent.id,
    avatar_file_id: [parent.avatar],
    full_name: [{ ...parent, avatarSize: 16, pl: 1, label: parent.full_name, value: parent.id }],
    email: parent.email || '',
    phone_number: parent.phone_number || '',
    note: getInitialTextEditorState(parent.notes),
    zipcode: parent.zip || '',
    address: parent.address || '',
    apart: parent.address || '',
    state: parent.state || '',
    relation: relation ? relation.relation : undefined,
    notify_projects: relation ? relation.notify_projects : false,
    country: parent.country,
    city: parent.city,
    api_relations: parent.relations,
    api_language: parent.preferred_language,
  };
};

export const mapParentsToHealthData = (oldData = {}, parents = []) => {
  let firstContactFound = false;
  let secondContactFound = false;

  const healthDetails = {};

  for (let i = 0; i < parents.length; i++) {
    const parent = parents[i];
    if (!parent.is_emergency_contact || secondContactFound) continue;

    if (!firstContactFound) {
      firstContactFound = true;
      healthDetails.first_contact_name = getArray(parent.full_name)[0]?.label;
      healthDetails.first_contact_phone = parent.phone_number;
      continue;
    }

    secondContactFound = true;
    healthDetails.second_contact_name = getArray(parent.full_name)[0]?.label;
    healthDetails.second_contact_phone = parent.phone_number;
  }

  return { ...oldData, ...healthDetails };
};
