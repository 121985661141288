import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Tabs as MuiTabs, styled } from '@mui/material';

import { dev } from 'utils/config';
import { makeOptions, propagateRefs } from 'utils/helpers';
import Holder from 'components/Holder';
import Tab from 'components/Tab';
import Fieldset from 'components/Dev/Fieldset';

const StyledTabs = styled(MuiTabs, {
  label: 'Tabs-Root',
})(({ theme }) => ({
  minHeight: 0,
  '& .MuiTab-root:not(:last-child)': {
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: '1px',
      height: `calc(100% - ${theme.spacing(1.5)})`,
      position: 'absolute',
      right: '-1px',
      backgroundColor: theme.palette.other.line,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&.Mui-selected::after': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiTabs-indicator': {
    height: '100%',
    borderWidth: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

/**
 * @prop {string['fullWidth'|'standard']} [variant="standard"] - Tabs variant
 * @prop {number|string} [width] - Whole component width
 * @prop {function} [onValue] - Value getter
 * @prop {object[]} [options] - Array of <Tab /> component props (could be used instaed of children)
 * @prop {ReactNode} [children] - <Tab /> components list
 * @prop {boolean} [initFocus] - Initial focus (works after the value changed from falsy to true)
 * Rest of props will be passed to <Holder /> component (root wrapper)
 */
const Tabs = forwardRef((props, ref) => {
  const {
    variant,
    width,
    value,
    onChange,
    onValue,
    children,
    options,
    initFocus,
    ...rest
  } = props;

  const handleChange = useCallback((e, v) => {
    typeof onChange === 'function' && onChange(e, v);
    typeof onValue === 'function' && onValue(v);
  }, [onChange, onValue]);

  const content = useMemo(() => {
    if (Array.isArray(options)) {
      return options.map((opt) => (
        <Tab
          key={opt.value}
          initFocus={opt.value === value && initFocus}
          {...opt}
        />
      ));
    }
    return children;
  }, [options, children, value, initFocus]);

  return (
    <Holder
      p={0.5}
      radius={3}
      stroke={1}
      width={width}
      color="divider"
      display="inline-block"
      bgcolor="other.white"
      {...rest}
    >
      <StyledTabs
        ref={ref}
        value={value}
        onChange={handleChange}
        variant={variant}
      >
        {content}
      </StyledTabs>
    </Holder>
  );
});
Tabs.Tab = Tab;

Tabs.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onValue, onBlur, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue || 0}
      render={({ field }) => {
        const {
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (v) => {
          controlOnChange(v);
          typeof onValue === 'function' && onValue(v);
        };
        const handleBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <Tabs
            ref={propagateRefs(ref, controlRef)}
            onValue={handleChange}
            onBlur={handleBlur}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [tab, setTab] = useState('teacher');
    const [width, setWidth] = useState();
    const [variant, setVariant] = useState();
    const [iconPosition, setIconPosition] = useState();
    const [icon, setIcon] = useState();

    const options = useMemo(() => {
      return makeOptions(['teacher', 'student', 'organization', 'parent'])
        .map((opt) => ({ ...opt, icon, iconPosition }));
    }, [icon, iconPosition]);

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="width"
            value={width}
            onChange={setWidth}
            options={[undefined, 600, '100%']}
          />
          <Fieldset.Field
            legend="variant"
            value={variant}
            onChange={setVariant}
            options={[undefined, 'standard', 'fullWidth']}
          />
          <Fieldset.Field
            legend="icon"
            value={icon}
            onChange={setIcon}
            options={[undefined, 'User', 'Calendar', 'Birthday']}
          />
          <Fieldset.Field
            legend="iconPosition"
            value={iconPosition}
            onChange={setIconPosition}
            options={[undefined, 'start', 'end', 'top', 'bottom']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Tabs\n`}
          {`  value="${tab}"\n`}
          {`  onValue={setTab}\n`}
          {`  width={${width}}\n`}
          {`  variant="${variant}"\n`}
          {`  options={[\n`}
          {`    {\n`}
          {`      value: 'teacher',\n`}
          {`      label: 'Teacher',\n`}
          {icon && (
          <>
          {`      icon: '${icon}',\n`}
          </>
          )}
          {iconPosition && (
          <>
          {`      iconPosition: '${iconPosition}',\n`}
          </>
          )}
          {`    },\n`}
          {`    ...\n`}
          {`  ]}\n`}
          {`/>\n`}
          {`\n`}
          {`<Box mt={2}>\n`}
          {`  Tab: {tab}\n`}
          {`</Box>\n`}
        </Box>

        <Box p={2}>
          <Tabs
            value={tab}
            onValue={setTab}
            width={width}
            variant={variant}
            options={options}
          />

          <Box mt={2}>
            Tab: {tab}
          </Box>
        </Box>
      </Box>
    );
  };
  Tabs.Demo = Demo;
}

export default Tabs;
