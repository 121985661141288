import { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetFavoriteIdeasQuery, useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, useBookmarkIdea, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize, checkBookmarked } from 'utils/helpers';

import { ProjectLayout } from 'layouts';
import { SidebarFilters, FiltersBar, IdeaCard } from 'views';
import { Center, Icon, Input, Button, Modal, ProgressBox, Pagination } from 'components';

import mapRequest from './mapRequest';

const { selectFilters, selectActiveFilters, selectPageFilters, selectPagination } = selectors;

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const FavoriteIdeas = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'ideas' });
  const { auth, progress } = useAuth();
  const {
    onSearch,
    tempSearch,
    onSetFilter,
    onSetActiveFilter,
    onResetPageFilter,
    onResetActiveFilters,
    onChangePage,
  } = usePageFilter();

  const [signupInformationOpen, setSignupInformationOpen] = useState(false);

  const filters = useSelector(selectFilters);
  const pageFilters = useSelector(selectPageFilters);
  const activeFilters = useSelector(selectActiveFilters);
  const pagination = useSelector(selectPagination);

  const [bookmark, bookmarkState] = useBookmarkIdea();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data, isLoading, isFetching } = useGetFavoriteIdeasQuery(
    mapRequest({ me, filters: pageFilters, pagination }),
    {
      skip: (auth && !me?.space_id) || progress,
    },
  );

  const closeSignupInformationModal = useCallback(() => {
    setSignupInformationOpen(false);
  }, [setSignupInformationOpen]);

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress || bookmarkState?.isLoading;

  return (
    <ProjectLayout
      subheader={
        <Center flexGrow={1}>
          <PageTitle>
            <Typography variant="subtitle2">{t('subheader.title')}</Typography>
          </PageTitle>
          <Grid container width="100%" mx={0} spacing={3}>
            <Grid item xs={6}>
              <Input
                value={tempSearch}
                onValue={onSearch}
                iconLeft="Search"
                fullWidth={true}
                placeholder={t('subheader.page_search')}
              />
            </Grid>
          </Grid>
        </Center>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filtersConfig={{ tags: { grouped: true } }}
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Modal open={signupInformationOpen} onClose={closeSignupInformationModal}>
        <Modal.Body>
          <Center pt={4} flexDirection="column">
            <Typography display="inline-block" mb={2} variant="body4">
              {t('signup_information.text')}
            </Typography>
            <Button to="/sign-up">{t('signup_information.signup')}</Button>
          </Center>
        </Modal.Body>
      </Modal>

      <Box p={4} pl={3}>
        <FiltersBar
          filters={activeFilters}
          mb={3}
          onRemove={handleRemoveSelectedFilters}
          onClear={onResetActiveFilters}
        />

        <ProgressBox loading={loading}>
          <Grid container spacing={3}>
            {getArray(data?.ideas).map((idea) => (
              <Grid key={`ideas-grid-${idea.id}`} item xs={6}>
                <IdeaCard
                  id={idea.id}
                  title={idea.name}
                  tags={idea.tags}
                  photos={idea.photos}
                  level={idea.level}
                  bookmarked={checkBookmarked(idea.bookmarks, me?.id)}
                  bookmarks={idea.bookmarks}
                  onBookmark={() => bookmark(idea, me?.id)}
                />
              </Grid>
            ))}
          </Grid>
        </ProgressBox>

        <Pagination
          total={data?.ideas_qty}
          page={pagination?.page}
          mt={6}
          onChangePage={onChangePage}
        />
      </Box>
    </ProjectLayout>
  );
};

export default FavoriteIdeas;
