import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, styled } from '@mui/material';

const Root = styled(Box, {
  label: 'Ref-Root',
  shouldForwardProp: (prop) => prop !== 'hidden',
})(({ theme, hidden, color }) => ({
  cursor: 'pointer',
  color: color || 'inherit',
  textDecoration: 'none',
  position: hidden ? undefined : 'relative',
  '&::after': hidden ? undefined : {
    content: '""',
    display: 'block',
    position: 'absolute',
    borderBottom: '1px solid currentColor',
    width: '100%',
    height: '100%',
    left: 0,
    bottom: 0,
    opacity: 0,
    transform: 'scaleX(0)',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.short,
    }),
  },
  '&:hover, &:focus': {
    outlineColor: 'transparent',
    outlineStyle: 'solid',
    outlineOffset: 0,
    outlineWidth: 0,
    ...(!hidden && {
      '&::after': {
        bottom: 0,
        opacity: 0.75,
        transform: 'scaleX(1)',
      },
    }),
  },
}));

/**
 * Represents project link. Keeps all the link styles. By default it uses react-router Link
 * component under the hood to handle project routes navigation, but also allows redefine it to
 * HTML <a> tag (to handle absolute pathes, or anchors). It also allows hide all the styles keeping
 * link semantic (set 'hidden' property to true), that could be helpful to wrap images or blocks.
 * Or even make any other component behave as a link (for example button that looks like a link).
 *
 * Usage:
 *
 * 1. Default (as react-router Link component):
 *  <Ref to="/path">Path</Ref>
 *
 * 2. Absolute path (as HTML <a> tag):
 *  <Ref component="a" blank to="https://wikipedia.org">Link (open in the new tab)</Ref>
 *
 * 3. Hide styles (could be both variants: react-router Link or simple <a> tag):
 *  <Ref hidden to="/path">
 *    <img src="hello-kitty.png" alt="Hello Kitty" />
 *  </Ref>
 *
 * 4. Show simple div with link styles:
 *  <Ref component="div" onClick={handleClick}>Looks like a link, but it is not!</Ref>
 * 
 * By default link inherit color from parent component, but you can setup it with [color] property.
 * Theme colors available, e.g.: color="primary.main".
 * 
 * @param {string} [to] - link path (optional)
 * @param {boolean} [blank] - open in the new tab
 * @param {string} [color="primary.main"] - link color (support "inherit" property or theme path)
 * @param {boolean} [hidden] - Hide link styles (except cursor property).
 * @param {string|ReactElement} - component to use as a link (e.g. component="div")
 */
const Ref = forwardRef((props, ref) => {
  const {
    to,
    blank,
    color = 'primary.main',
    hidden,
    children,
    component: initComponent,
    ...rest
  } = props;

  const component = useMemo(() => {
    return !initComponent && to ? Link : initComponent || 'span';
  }, [initComponent, to]);

  const defaults = useMemo(() => {
    const result = {};

    if (component === 'a') {
      if (to) {
        result.href = to;
      }
      if (blank) {
        result.target = '_blank';
        result.rel = 'noreferrer';
      }
    }
    if (component === Link) {
      result.to = to;
    }
    return result;
  }, [component, blank, to])

  return (
    <Root
      ref={ref}
      color={color}
      component={component}
      hidden={hidden}
      {...defaults}
      {...rest}
    >
      {children}
    </Root>
  );
});

export default Ref;
