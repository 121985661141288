import { useMemo, useCallback } from 'react';
import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { formatDate, getArray } from 'utils/helpers';
import { useAuth, useMessage, withProps } from 'hooks';

import {
  useGetQuestionQuery,
  useGetMeQuery,
  useCreateQuestionCommentMutation,
  useDeleteQuestionMutation,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Icon, Gallery, Container, IconButton } from 'components';
import { ArticleGeneralInformation, PageSubheader, Comments } from 'views';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Question = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_question' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: question,
    isLoading,
    isFetching,
  } = useGetQuestionQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const [createComment, createCommentState] = useCreateQuestionCommentMutation();
  const [deleteQuestion, deleteQuestionState] = useDeleteQuestionMutation();

  const loading = isLoading || isFetching || deleteQuestionState.isLoading;

  const handleCreateComment = useCallback(
    async (comment) => {
      if (!id) {
        m.error(t('error.failed_create_comment'));
        return;
      }
      const { text, replyCommentId } = comment;

      try {
        await createComment({
          spaceId: me.space_id,
          name: text,
          question: id,
          replied_comment: replyCommentId,
        }).unwrap();
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else {
          m.error(t('error.failed_create_comment'));
        }
      }
    },
    [id, me, m, t, createComment],
  );

  const handleDeleteQuestion = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteQuestion({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/knowledge-base');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteQuestion, navigate, id, t, m]);

  const tags = useMemo(() => {
    return getArray(question?.tags).map((tag) => ({ label: tag.name, id: tag.id }));
  }, [question]);

  const photos = useMemo(() => {
    return getArray(question?.photos).map((photo) => ({ src: photo }));
  }, [question]);

  const withPhotos = photos.length > 0;
  const isOwner = me && question && me?.space_id === question?.space_id;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={question?.name}
          backUrl="/knowledge-base/questions"
          backTitle={t('back_button')}
          pageActions={
            isOwner && {
              [t('page_actions.edit')]: {
                type: 'button',
                disabled: loading,
                variant: 'outlined',
                size: 'small',
                name: 'Edit',
                border: true,
                Component: IconButton,
                to: `${pathname}/edit`,
              },
              [t('page_actions.delete')]: {
                type: 'button',
                size: 'small',
                disabled: loading,
                color: 'error',
                name: 'Delete',
                Component: IconButton,
                onClick: handleDeleteQuestion,
              },
            }
          }
        />
      }
    >
      <Article py={4}>
        <Container>
          <ArticleGeneralInformation
            slug="Question"
            author={question?.author}
            tags={tags}
            dateCreated={formatDate(question?.date_created)}
          />

          {withPhotos && (
            <>
              <Center mb={2} justifyContent="flex-start">
                <Center width={24} height={24}>
                  <Icon name="Image" />
                </Center>
                <Typography ml={0.5} variant="body2" color="textBlack.dark">
                  {t('gallery')}
                </Typography>
              </Center>
              <Gallery images={photos} />
            </>
          )}

          <Center mb={2} justifyContent="flex-start">
            <Center width={24} height={24}>
              <Icon name="Forum" fontSize="small" />
            </Center>
            <Typography ml={0.5} variant="body2" color="textBlack.dark">
              {t('comments')}
            </Typography>
          </Center>

          <Comments
            me={me}
            disabled={!auth}
            comments={question?.comments}
            loading={createCommentState.isLoading}
            onSendComment={handleCreateComment}
          />
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default Question;
