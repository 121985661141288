import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

import { useRules } from 'hooks';
import { getArray } from 'utils/helpers';
import { useGetGroupsQuery } from 'store/api';

import { Form, SelectMultiple } from 'components';

const PortfolioGroupSearchForm = (props) => {
  const { id, onSubmit, form, me } = props;

  const [search, setSearch] = useState('');

  const { requiredArray, required } = useRules();

  const { t } = useTranslation('views', { keyPrefix: 'PortfolioGroupSearchForm' });

  const { data } = useGetGroupsQuery(
    { user_id: me?.id, space_id: me?.space_id, limit: 10, offset: 0 },
    {
      skip: !me?.id || !me?.space_id,
    },
  );

  const groupOptions = useMemo(() => {
    if (!data?.groups) return [];

    return getArray(data.groups).map((group) => ({
      label: group.name,
      value: group.id,
      projects: group.projects,
    }));
  }, [data]);

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      <SelectMultiple.Control
        name="groups"
        fullWidth
        placeholder={t('search_groups')}
        inputValue={search}
        onInputChange={(_e, v) => setSearch(v)}
        options={groupOptions}
        disableCloseOnSelect
        rules={{ requiredArray, required }}
      />

      <Box height={500} />
    </Form>
  );
};

export default PortfolioGroupSearchForm;
