import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { useForm, useGroupSearch, useUsersSearch } from 'hooks';
import { getArray } from 'utils/helpers';

import { Modal, Button, Select, Tabs, Form } from 'components';

const AssignProjectModal = (props) => {
  const { open, spaceId, loading, onClose, onValue } = props;

  const [tab, setTab] = useState('student');

  const { t } = useTranslation('views', { keyPrefix: 'AssignProjectModal' });
  const {
    form,
    valid,
    rules: { required },
  } = useForm();

  useEffect(() => {
    if (open) return;
    form.reset();
  }, [form, open]);

  const watchStudent = form.watch('student');
  const watchGroup = form.watch('group');

  const {
    data: groups,
    loading: groupsLoading,
    search: groupSearch,
    onSearch: onGroupSearch,
  } = useGroupSearch({
    userId: watchStudent,
    spaceId: spaceId,
    disabled: !open || !watchStudent,
  });

  const {
    options: studentOptions,
    loading: studentsLoading,
    search: studentSearch,
    onSearch: onStudentSearch,
  } = useUsersSearch({
    spaceId: spaceId,
    disabled: !open || loading,
    mapToOptions: true,
    filters: {
      roles: ['student', 'teacher', 'admin'],
      groups: [],
    },
  });

  const handleSubmit = useCallback(
    (formData) => {
      if (typeof onValue !== 'function') return;
      if (tab === 'student') {
        return onValue({ student: formData.student });
      }

      return onValue({ group: formData.group });
    },
    [tab, onValue],
  );

  const groupOptions = getArray(groups?.groups).map((group) => ({
    label: group.name,
    value: group.id,
  }));

  return (
    <Modal open={open} onClose={onClose} size={516}>
      <Modal.Body>
        <Form id="assign-project-form" form={form} onSubmit={handleSubmit}>
          <Stack pt={4} spacing={1.5}>
            <Select.Control
              collapse
              box={false}
              itemsMax={5}
              name="student"
              placeholder={t('search_student')}
              label={t('choose_student')}
              fullWidth
              rules={{ required }}
              loading={studentsLoading}
              options={studentOptions}
              searchable
              search={studentSearch}
              onSearch={onStudentSearch}
            />

            <Tabs
              onValue={setTab}
              value={tab}
              width="100%"
              variant="fullWidth"
              options={[
                { icon: 'Student', label: 'Student', value: 'student' },
                { icon: 'Group', label: 'Group', value: 'group' },
              ]}
            />
            {watchStudent && tab === 'group' && (
              <Select.Control
                collapse
                box={false}
                itemsMax={5}
                name="group"
                placeholder={t('search_group')}
                fullWidth
                loading={groupsLoading}
                searchable
                search={groupSearch}
                onSearch={onGroupSearch}
                options={groupOptions}
              />
            )}
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer sx={{ borderTop: 'none' }} pt={0}>
        <Button
          type="submit"
          form="assign-project-form"
          size="small"
          radius={2}
          disabled={!valid || (tab === 'group' && !watchGroup) || loading}
        >
          {t('assign_project')}
        </Button>
        <Button size="small" radius={2} variant="outlined">
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignProjectModal;
