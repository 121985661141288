import { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import { dev } from 'utils/config';
import { useMessage } from 'hooks';

import { Button } from 'components';
import Fieldset from 'components/Dev/Fieldset';

const CopyToClipboard = ({ text, copyText, children }) => {
  const { t } = useTranslation('components', { keyPrefix: 'CopyToClipboard' });

  const m = useMessage();
  const handleCopy = useCallback(() => {
    m.info(copyText || t('text_copied'));
  }, [m, t, copyText]);

  return (
    <ReactCopyToClipboard text={text} onCopy={handleCopy}>
      {children}
    </ReactCopyToClipboard>
  );
};

if (dev) {
  const Demo = () => {
    const [text, setText] = useState('example 1');
    const [copyText, setCopyText] = useState(undefined);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="text"
            value={text}
            onChange={setText}
            options={['example 1', 123]}
          />
          <Fieldset.Field
            legend="copyText"
            value={copyText}
            onChange={setCopyText}
            options={[undefined, 'Text copied', 'Link copied']}
          />
        </Fieldset>

        <CopyToClipboard text={text} copyText={copyText}>
          <Button>Copy</Button>
        </CopyToClipboard>
      </Box>
    );
  };

  CopyToClipboard.Demo = Demo;
}

export default CopyToClipboard;
