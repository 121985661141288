import { getArray, editorStateToRawHtml } from 'utils/helpers';

const mapRequest = (formData) => {
  const { tags, sources, level, description, ...rest } = formData;

  return {
    ...rest,
    photos: [],
    users_bookmarks: [],
    description: editorStateToRawHtml(description) || undefined,
    source: getArray(sources)
      .map((source) => source.url)
      .filter(Boolean),
    level: level,
    tags: getArray(tags).map((tag) => tag.value),
  };
};

export default mapRequest;
