import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { useGetIdeasQuery, useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, useBookmarkIdea, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize, checkBookmarked } from 'utils/helpers';

import { IdeaCard } from 'views';
import { ProgressBox, Pagination } from 'components';

import { mapIdeasRequest } from './mappers';

const { selectPageFilters, selectPagination } = selectors;

const Ideas = (props) => {
  const { spaceId } = props;

  const { auth, progress } = useAuth();
  const { onSetFilter, onResetPageFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const [bookmark, bookmarkState] = useBookmarkIdea();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data, isLoading, isFetching } = useGetIdeasQuery(
    mapIdeasRequest({ spaceId, filters: pageFilters, pagination }),
    {
      skip: (auth && !spaceId) || progress,
    },
  );

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress || bookmarkState?.isLoading;

  return (
    <>
      <ProgressBox loading={loading} minHeight={400}>
        <Grid container spacing={3}>
          {getArray(data?.ideas).map((idea) => (
            <Grid key={`ideas-grid-${idea.id}`} item xs={6}>
              <IdeaCard
                id={idea.id}
                title={idea.name}
                tags={idea.tags}
                photos={idea.photos}
                level={idea.level}
                bookmarked={checkBookmarked(idea.bookmarks, me?.id)}
                bookmarks={idea.bookmarks}
                onBookmark={() => bookmark(idea, me?.id)}
              />
            </Grid>
          ))}
        </Grid>
      </ProgressBox>
      <Pagination
        total={data?.ideas_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default Ideas;
