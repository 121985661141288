import { format } from 'date-fns';
import { convertToHTML, convertFromHTML } from 'draft-convert';

import i18n from 'i18n';

import { ROLE } from './constants';

export const generateArray = (n) => {
  return [...Array(n).keys()].map((v) => v + 1);
};

export const getArray = (v) => (Array.isArray(v) ? v : []);

export const getString = (v) => (typeof v === 'string' ? v : '');

export const getFunc =
  (v) =>
  (...args) =>
    typeof v === 'function' && v(...args);

export const isObject = (v) => {
  return !!v && !Array.isArray(v) && typeof v === 'object';
};

export const capitalize = (v) => {
  if (typeof v === 'string' && v.length > 0) {
    return `${v[0].toUpperCase()}${v.slice(1).toLowerCase()}`;
  }
  return v;
};

export const makeOptions = (list, keep) => {
  return getArray(list).map((value) => ({
    value,
    label: keep ? value : String(value).split('_').map(capitalize).join(' '),
  }));
};

export const propagateRefs =
  (...refs) =>
  (el) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(el);
      } else if (ref) {
        ref.current = el;
      }
    });
    return el;
  };

export const toFormData = (data) => {
  const formData = new FormData();

  if (isObject(data)) {
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });
  }
  return formData;
};

export const sleep = (t = 1000, success = true, data) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      success ? resolve(data) : reject(data);
    }, t);
  });

export const formatDate = (dateString, dateFormat = 'dd.MM.yyyy') => {
  if (!dateString) return '';
  return format(new Date(dateString), dateFormat);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const editorStateToRawHtml = (editorState) => {
  const content = editorState.getCurrentContent();
  if (!content.hasText()) return '';
  const html = convertToHTML({
    styleToHTML: (style) => {
      const styleMap = {
        LEFT: {
          display: 'inline-block',
          width: '100%',
          textAlign: 'left',
        },
        RIGHT: {
          display: 'inline-block',
          width: '100%',
          textAlign: 'right',
        },
        CENTER: {
          display: 'inline-block',
          width: '100%',
          textAlign: 'center',
        },
        JUSTIFY: {
          display: 'inline-block',
          width: '100%',
          textAlign: 'justify',
        },
      };
      const styles = styleMap[style];
      if (styles) return <span style={styles}></span>;
    },
  })(content);
  return html.replace(/(?:\r\n|\r|\n)/g, '');
};

export const rawHtmlToEditorState = (html) => {
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node.style.textAlign === 'left') {
        return currentStyle.add('LEFT');
      } else if (nodeName === 'span' && node.style.textAlign === 'right') {
        return currentStyle.add('RIGHT');
      } else if (nodeName === 'span' && node.style.textAlign === 'center') {
        return currentStyle.add('CENTER');
      } else if (nodeName === 'span' && node.style.textAlign === 'justify') {
        return currentStyle.add('JUSTIFY');
      } else {
        return currentStyle;
      }
    },
  })(html);
  return contentState;
};

export const generateRandomPassword = (length = 12) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let password = '';
  for (let i = 0; i <= length; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
};

export const hasPermission = (userPermissions = {}, permissions) => {
  return permissions.filter((permission) => !userPermissions[permission]).length === 0;
};

export const formatPhoneNumber = (number) => {
  if (!number) return '';
  return number.replace(/^(\d{3})(\d{3})(\d{3})(\d{3}).*/, '+$1 ($2) $3-$4');
};

export const formatBirthDate = (date) => {
  return date;
};

export const getProfileBasePath = (role) => {
  if (role === ROLE.STUDENT) return '/administration/students';
  if (role === ROLE.PARENT) return '/administration/parents';
  if (role === ROLE.ADMIN) return '/administration/admins';
  if (role === ROLE.TEACHER) return '/administration/teachers';
  return '/administration/spaces';
};

export const keyCodes = {
  enter: 13,
  esc: 27,
  space: 32,
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
};

export const checkBookmarked = (bookmarks, userId) => {
  return bookmarks.indexOf(userId) !== -1;
};

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng || 'en';
