import { getArray, editorStateToRawHtml } from 'utils/helpers';

const mapStudentWorks = (studentWork) => {
  return {
    name: studentWork.name,
    description: studentWork.description,
    link: getArray(studentWork.photo)[0] || studentWork.link,
  };
};

const getProjectAvatar = (project) => {
  return getArray(project.photos)[0] || '';
};

const mapRequest = ({ about, groups }, isIndividual = false) => {
  const { about_me, photo, ...personal } = about;

  const projectIds = [];
  const groupIds = groups.map((group) => group.value);

  const projectCards = [];
  groups.forEach((group) => {
    group.selected.forEach((project) => {
      projectIds.push(project.id);

      projectCards.push({
        name: project.name,
        description: editorStateToRawHtml(project.description) || undefined,
        photos: getArray(project.student_works).map(mapStudentWorks),
        source: getArray(project.sources).map((source) => source?.link),
        project: project.id,
        avatar: getProjectAvatar(project),
      });
    });
  });

  return {
    name: personal.name,
    first_name: personal.first_name,
    last_name: personal.last_name,
    about_me: editorStateToRawHtml(about_me) || undefined,
    public: personal.public,
    projects: projectIds,
    groups: isIndividual ? [] : groupIds,
    project_cards: projectCards,
    photo: getArray(photo)[0],
  };
};

export default mapRequest;
