import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { cotton } from 'assets/images/holders';

import { Modal, ImageHolder, Center } from 'components';

const RequestSuccessModal = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'single_collection.request_collection' });

  return (
    <Modal open={open} onClose={onClose} size={516}>
      <Modal.Body>
        <Center pt={6} pb={10} flexDirection="column">
          <ImageHolder
            p={1}
            size={88}
            alt={t('success_message')}
            src={cotton}
            origin="container"
            shadow="smooth"
            mb={2}
            bgcolor="warning.contrastText"
          />
          <Typography variant="body4" maxWidth={374} textAlign="center" color="textBlack.light">
            {t('success_message')}
          </Typography>
        </Center>
      </Modal.Body>
    </Modal>
  );
};

export default RequestSuccessModal;
