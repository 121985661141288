import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { propagateRefs } from 'utils/helpers';
import Input from 'components/Input';

/**
 * Shortcut for input with username role. Contains UsernameField.Control validation.
 */
const UsernameField = forwardRef((props, ref) => {
  const { ...rest } = props;

  return <Input ref={ref} {...rest} />;
});

UsernameField.Control = forwardRef((props, ref) => {
  const { name, rules, onValue, onBlur, inputRef, defaultValue = '', ...rest } = props;

  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error, isTouched } = fieldState;
        const {
          ref: controlRef,
          onChange: controlOnChange,
          onBlur: controlOnBlur,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };
        const handleBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <UsernameField
            ref={ref}
            inputRef={propagateRefs(inputRef, controlRef)}
            onValue={handleChange}
            onBlur={handleBlur}
            error={isTouched && (error?.message || !!error)}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

export default UsernameField;
