import { useMemo } from 'react';
import { Typography, CardActionArea, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { capitalize } from 'utils/helpers';
import { Tags, Paper, Center, ImageHolder, Icon, ButtonBase } from 'components';
import { LevelTag } from 'views';

const Root = styled(Paper, { label: 'IdeaCard-Root' })(({ border, theme }) => ({
  padding: 0,
  width: '100%',
  height: '100%',
  flexShrink: 0,
  border: border ? '1px solid' : undefined,
  borderColor: border ? theme.palette.controls.main : undefined,
}));

const BookmarkButton = styled(ButtonBase)(({ theme }) => ({
  padding: 0,
  height: 'auto',
  color: theme.palette.textBlack.dark,
  marginRight: 4,
}));

const IdeaCard = (props) => {
  const { t } = useTranslation('views', { keyPrefix: 'IdeaCard' });

  const {
    id,
    title,
    level,
    photos,
    tags,
    bookmarked,
    bookmarks,
    border,
    size = 'default',
    onClick,
    onBookmark,
    ...rest
  } = props;

  const photo = useMemo(() => {
    if (!photos?.length) return null;
    return photos[0];
  }, [photos]);

  return (
    <Root
      radius={size === 'small' ? 2 : undefined}
      shadow={!border ? 'standard' : 'none'}
      border={border}
      size={size}
      {...rest}
    >
      <CardActionArea
        component={Link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: size === 'small' ? 1.5 : 2,
          '& .MuiCardActionArea-focusHighlight': { background: 'transparent' },
        }}
        to={`/ideas/${id}`}
        onClick={onClick}
      >
        <Center width="100%">
          <ImageHolder mr={2} flexShrink={0} size={size === 'small' ? 128 : 136} src={photo} />
          <Box flexGrow={1}>
            <Center justifyContent="space-between">
              <LevelTag level={level} label={capitalize(level)} size="medium" mb={1} />

              <BookmarkButton
                sx={{ color: bookmarked ? 'primary.main' : 'textBlack.main' }}
                type="button"
                variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  typeof onBookmark === 'function' && onBookmark();
                }}
              >
                <Icon name={bookmarked ? 'BookmarkFilled' : 'Bookmark'} fontSize="medium" />
              </BookmarkButton>
            </Center>
            <Typography component="div" variant="subtitle4" mb={1}>
              {title}
            </Typography>

            <Tags max={3} title={t('tags')} tags={tags} name={title} />
          </Box>
        </Center>
      </CardActionArea>
    </Root>
  );
};

export default IdeaCard;
