import { Box } from '@mui/material';

import { useGetMeQuery } from 'store/api';
import { useAuth } from 'hooks';

import { Container } from 'components';

import StudentProjectStatistics from './StudentProjectStatistics';

const Dashboard = () => {
  const { auth } = useAuth();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  if (!me) return null;

  return (
    <Box pt={3}>
      <Container>
        <Box mb={4}>
          <StudentProjectStatistics />
        </Box>
      </Container>
    </Box>
  );
};

export default Dashboard;
