import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'store';

const {
  setActiveFilter: setActiveFilterAction,
  setActiveFilters: setActiveFiltersAction,
  setFilter: setFilterAction,
  setSearch: setSearchAction,
  setPagination: setPaginationAction,
  resetPageFilter: resetPageFilterAction,
} = actions;

const MIN_SEARCH_LENGTH = 3;
const SEARCH_DELAY = 600;
let searchTimeout;

const usePageFilter = (opts) => {
  const { minSearchLength = MIN_SEARCH_LENGTH } = opts || {};

  const dispatch = useDispatch();

  const [tempSearch, setTempSearch] = useState('');

  const handleSearch = useCallback(
    (value) => {
      setTempSearch(value);
      if (searchTimeout) clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        if (value?.length >= minSearchLength || !value?.length) {
          setSearchAction({ search: value });
        }
      }, SEARCH_DELAY);
    },
    [setTempSearch, minSearchLength],
  );

  const handleSetFilter = useCallback(
    (name, type, value) => {
      dispatch(setFilterAction({ name, type, value }));
    },
    [dispatch],
  );

  const handleSetActiveFilter = useCallback(
    (name, value) => {
      dispatch(setActiveFilterAction({ name, value }));
    },
    [dispatch],
  );

  const handleResetPageFilter = useCallback(() => {
    dispatch(resetPageFilterAction());
  }, [dispatch]);

  const handleResetActiveFilters = useCallback(() => {
    dispatch(setActiveFiltersAction({ filters: {} }));
  }, [dispatch]);

  const handleChangePage = useCallback(
    (page) => {
      dispatch(setPaginationAction({ page }));
    },
    [dispatch],
  );

  return {
    tempSearch,
    onSearch: handleSearch,
    onSetFilter: handleSetFilter,
    onSetActiveFilter: handleSetActiveFilter,
    onResetPageFilter: handleResetPageFilter,
    onResetActiveFilters: handleResetActiveFilters,
    onChangePage: handleChangePage,
  };
};

export default usePageFilter;
