import { Box, Typography } from '@mui/material';

const Description = (props) => {
  const { title, description } = props;

  return (
    <Box px={2.25} mb={4}>
      {title && (
        <Typography component="div" variant="overline1" mb={1} color="textBlack.dark">
          {title}
        </Typography>
      )}
      <Typography
        variant="paragraph"
        component="div"
        sx={{ maxWidth: '904px' }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  );
};

export default Description;
