import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { api as baseUrl } from 'utils/config';
import { toFormData } from 'utils/helpers';

const secure = (query) => ({
  ...query,
  headers: {
    ...query?.headers,
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

const slice = createApi({
  tagTypes: ['ME'],
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (build) => ({
    // AUTH
    // [POST] /auth
    authorizeUser: build.mutation({
      invalidatesTags: ['ME'],
      query: (body) => ({
        method: 'POST',
        url: '/auth',
        body,
      }),
    }),

    // USERS
    // [POST] /users/validate-email
    validateEmail: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/users/validate-email',
      }),
    }),

    // [POST] /users/validate-sms
    validateSms: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/users/validate-sms',
      }),
    }),

    // [POST] /users/email-reset-password
    resetEmailPassword: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/users/email-reset-password',
      }),
    }),

    updatePassword: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/users/update-password',
      }),
    }),

    updateLanguage: build.mutation({
      query: ({ user_id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/users/${user_id}/language`,
        }),
    }),

    deleteNotifications: build.mutation({
      invalidatesTags: ['NOTIFICATIONS'],
      query: () =>
        secure({
          method: 'DELETE',
          url: `/notifications`,
        }),
    }),

    updateNotificationPermissions: build.mutation({
      invalidatesTags: ['ME'],
      query: ({ user_id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/users/${user_id}/notifications`,
        }),
    }),

    changePassword: build.mutation({
      query: (body) =>
        secure({
          body,
          method: 'PATCH',
          url: '/users/me/password',
        }),
    }),

    // [POST] /users/sms-reset-password
    resetSmsPassword: build.mutation({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/users/sms-reset-password',
      }),
    }),

    // [GET] /users/me
    getMe: build.query({
      providesTags: ['ME'],
      query: () =>
        secure({
          method: 'GET',
          url: '/users/me',
        }),
    }),

    // [POST] /notifications/:space_id
    getNotifications: build.query({
      providesTags: ['NOTIFICATIONS'],
      query: ({ space_id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/notifications/${space_id}`,
        }),
    }),

    // [POST] /notifications/:space_id
    updateNotificationStatus: build.mutation({
      invalidatesTags: ['NOTIFICATIONS'],
      query: ({ notification_id }) =>
        secure({
          method: 'PATCH',
          url: `/notifications/${notification_id}`,
        }),
    }),

    // [POST] /users/achievements
    getUserAchievements: build.query({
      providesTags: ['USER_ACHIEVEMENTS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/users/achievements',
        }),
    }),

    // STUDENTS
    // [GET] /spaces/:space_id/students
    getStudents: build.query({
      query: ({ spaceId }) =>
        secure({
          method: 'GET',
          url: `/spaces/${spaceId}/students`,
        }),
    }),

    // SPACES
    // [POST] /spaces
    registerSpace: build.mutation({
      invalidatesTags: ['SPACES'],
      query: (body) => ({
        body,
        method: 'POST',
        url: '/spaces',
      }),
    }),

    // [POST] /spaces/list
    getSpaces: build.query({
      providesTags: ['SPACES'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/spaces/list',
        }),
    }),

    // [GET] /spaces/:space_id
    getSpace: build.query({
      providesTags: ['SPACE'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/spaces/${id}`,
        }),
    }),

    // [DELETE] /spaces/:space_id
    deleteSpace: build.mutation({
      invalidatesTags: ['SPACE', 'SPACES'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/spaces/${id}`,
        }),
    }),

    // [POST] /spaces/achievements
    updateAchievements: build.mutation({
      invalidatesTags: ['ME', 'SPACE'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/achievements`,
        }),
    }),

    // [POST] /groups
    getGroups: build.query({
      providesTags: ['GROUPS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/groups',
        }),
    }),

    getGroupsWithLeaders: build.query({
      providesTags: ['GROUPS_WITH_LEADERS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/groups_leader_filter`,
        }),
    }),

    getGroup: build.query({
      providesTags: ['GROUP'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/groups/${id}`,
        }),
    }),

    getGroupProjectStatistics: build.query({
      providesTags: ['GROUP_PROJECT_STATISTICS'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/group_projects_statistics/${id}`,
        }),
    }),

    getUsers: build.query({
      providesTags: ['USERS'],
      query: ({ space_id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${space_id}/students_roles_filter`,
        }),
    }),

    getUser: build.query({
      providesTags: ['USER'],
      query: ({ user_id }) =>
        secure({
          method: 'GET',
          url: `/users/${user_id}`,
        }),
    }),

    createTeacher: build.mutation({
      invalidatesTags: ['USERS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/teacher`,
        }),
    }),

    updateTeacher: build.mutation({
      invalidatesTags: ['USERS', 'USER'],
      query: ({ teacherId, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/teacher/${teacherId}`,
        }),
    }),

    createGroup: build.mutation({
      invalidatesTags: ['GROUPS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/group`,
        }),
    }),

    editGroup: build.mutation({
      invalidatesTags: ['GROUP'],
      query: ({ groupId, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/groups/${groupId}`,
        }),
    }),

    deleteGroup: build.mutation({
      invalidatesTags: ['GROUP'],
      query: ({ groupId }) =>
        secure({
          method: 'DELETE',
          url: `/groups/${groupId}`,
        }),
    }),

    createAdmin: build.mutation({
      invalidatesTags: ['USERS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/admin`,
        }),
    }),

    updateAdmin: build.mutation({
      invalidatesTags: ['USERS', 'USER'],
      query: ({ adminId, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/admin/${adminId}`,
        }),
    }),

    // [GET] /nick_name
    generateNickname: build.mutation({
      query: () => ({
        method: 'GET',
        url: '/nick_name',
      }),
    }),

    // [GET] /relations
    getRelations: build.query({
      providesTags: ['RELATIONS'],
      query: () => ({
        method: 'GET',
        url: '/relations',
      }),
    }),

    // [POST] /spaces/countries
    getCountries: build.query({
      providesTags: ['COUNTRIES'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/spaces/countries',
        }),
    }),

    // [POST] /spaces/countries
    getCities: build.query({
      providesTags: ['CITIES'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/spaces/cities',
        }),
    }),

    // STATUSES
    // [GET] /status
    getStatuses: build.query({
      providesTags: ['STATUSES'],
      query: () => ({
        method: 'GET',
        url: `/status`,
      }),
    }),

    // LEVELS
    // [GET] /spaces/:space_id/tags
    getLevels: build.query({
      providesTags: ['LEVELS'],
      query: () =>
        secure({
          method: 'GET',
          url: `/levels`,
        }),
    }),

    // TAGS
    // [GET] /spaces/:space_id/tags
    getTags: build.query({
      providesTags: ['TAGS'],
      query: ({ spaceId }) =>
        secure({
          method: 'GET',
          url: `/spaces/${spaceId}/tags`,
        }),
    }),

    // [POST] /tags/:space_id
    createTag: build.mutation({
      invalidatesTags: ['TAGS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/tags/${spaceId}`,
        }),
    }),

    // IDEAS
    // [POST] /ideas
    getIdeas: build.query({
      providesTags: ['IDEAS'],
      query: ({ spaceId, ...body }) => {
        const query = {
          body,
          method: 'POST',
          url: spaceId ? `/ideas/${spaceId}` : '/ideas',
        };
        return spaceId ? secure(query) : query;
      },
    }),

    // [POST] /idea_bookmarks
    getFavoriteIdeas: build.query({
      providesTags: ['FAVORITE_IDEAS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/ideas_bookmarks',
        }),
    }),

    // [GET] /ideas/:id
    getIdea: build.query({
      providesTags: ['IDEA'],
      query: ({ id }) => ({
        method: 'GET',
        url: `/ideas/${id}`,
      }),
    }),

    // [POST] /spaces/:space_id/idea
    createIdea: build.mutation({
      invalidatesTags: ['IDEAS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/idea`,
        }),
    }),

    // [DELETE] /ideas/:id
    deleteIdea: build.mutation({
      invalidatesTags: ['IDEA', 'IDEAS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/ideas/${id}`,
        }),
    }),

    // [PATCH] /ideas/:id
    updateIdea: build.mutation({
      invalidatesTags: ['IDEA', 'IDEAS', 'FAVORITE_IDEAS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/ideas/${id}`,
        }),
    }),

    // [POST] /ideas/:id/publish
    publishIdea: build.mutation({
      invalidatesTags: ['IDEA', 'IDEAS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/ideas/${id}/publish`,
        }),
    }),

    // [POST] /ideas/:id
    moderateIdea: build.mutation({
      invalidatesTags: ['IDEA', 'IDEAS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/ideas/${id}`,
        }),
    }),

    // [GET] /ideas/projects/:idea_id
    getIdeaProjects: build.query({
      providesTags: ['IDEA_PROJECTS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/ideas/projects/${id}`,
        }),
    }),

    // TIPS
    // [POST] /tips/?:space_id - If space id isn't provided, only public tips are loaded.
    getTips: build.query({
      providesTags: ['TIPS'],
      query: ({ spaceId, ...body }) => {
        const query = {
          body,
          method: 'POST',
          url: spaceId ? `/tips/${spaceId}` : '/tips',
        };
        return spaceId ? secure(query) : query;
      },
    }),

    getTip: build.query({
      providesTags: ['TIP'],
      query: ({ id }) => ({
        method: 'GET',
        url: `/tips/${id}`,
      }),
    }),

    createTip: build.mutation({
      invalidatesTags: ['TIPS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/tip`,
        }),
    }),

    deleteTip: build.mutation({
      invalidatesTags: ['TIP', 'TIPS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/tips/${id}`,
        }),
    }),

    updateTip: build.mutation({
      invalidatesTags: ['TIP', 'TIPS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/tips/${id}`,
        }),
    }),

    // QUESTIONS
    // [POST] /questions/?:space_id - If space id isn't provided, only public questions are loaded.
    getQuestions: build.query({
      providesTags: ['QUESTIONS'],
      query: ({ spaceId, ...body }) => {
        const query = {
          body,
          method: 'POST',
          url: spaceId ? `/questions/${spaceId}` : '/questions',
        };
        return spaceId ? secure(query) : query;
      },
    }),

    getQuestion: build.query({
      providesTags: ['QUESTION'],
      query: ({ id }) => ({
        method: 'GET',
        url: `/questions/${id}`,
      }),
    }),

    createQuestion: build.mutation({
      invalidatesTags: ['QUESTIONS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/question`,
        }),
    }),

    createQuestionComment: build.mutation({
      invalidatesTags: ['QUESTION'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/question_comments/${spaceId}`,
        }),
    }),

    updateQuestion: build.mutation({
      invalidatesTags: ['QUESTION', 'QUESTIONS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/questions/${id}`,
        }),
    }),

    deleteQuestion: build.mutation({
      invalidatesTags: ['QUESTION', 'QUESTIONS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/questions/${id}`,
        }),
    }),

    // STORAGE
    // [POST] /storage/upload_temporary_file
    uploadTemporaryFile: build.mutation({
      query: ({ file }) => ({
        method: 'POST',
        url: '/storage/upload_temporary_file',
        body: toFormData({ file }),
      }),
    }),

    // [POST] /storage/upload_temporary_files
    uploadTemporaryFiles: build.mutation({
      query: ({ files }) => ({
        method: 'POST',
        url: '/storage/upload_temporary_files',
        body: toFormData({ files }),
      }),
    }),

    // PROJECTS
    // [POST] /projects
    getProjects: build.query({
      providesTags: ['PROJECTS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/projects',
        }),
    }),

    // [GET] /projects/:id
    getProject: build.query({
      providesTags: ['PROJECT'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/projects/${id}`,
        }),
    }),

    // [POST] /spaces/:space_id/project
    createProject: build.mutation({
      invalidatesTags: ['PROJECT', 'PROJECTS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${id}/project`,
        }),
    }),

    // [POST] /projects/:project_id
    updateProject: build.mutation({
      invalidatesTags: ['PROJECT', 'PROJECTS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/projects/${id}`,
        }),
    }),

    // [DELETE] /projects/:id
    deleteProject: build.mutation({
      invalidatesTags: ['PROJECT', 'PROJECTS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/projects/${id}`,
        }),
    }),

    // [DELETE] /projects/:id
    deleteChildProject: build.mutation({
      invalidatesTags: ['CHILD_PROJECT', 'CHILD_PROJECTS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/child_projects/${id}`,
        }),
    }),

    // [POST] /projects/:project_id/request
    requestProject: build.mutation({
      invalidatesTags: ['PROJECT'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/projects/${id}/request`,
        }),
    }),

    // [POST] /projects/:project_id/assign
    assignProject: build.mutation({
      invalidatesTags: ['PROJECT'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/projects/${id}/assign`,
        }),
    }),

    // [POST] /child_projects/:space_id
    getChildProjects: build.query({
      providesTags: ['CHILD_PROJECTS'],
      query: ({ space_id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/child_projects/${space_id}`,
        }),
    }),

    // [POST] /child_projects
    getUserProjects: build.query({
      providesTags: ['USER_PROJECTS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: `/child_projects`,
        }),
    }),

    // [POST] /group_child_projects
    getGroupProjects: build.query({
      providesTags: ['GROUP_CHILD_PROJECTS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: `/group_child_projects`,
        }),
    }),

    // [GET] /child_projects/:id
    getChildProject: build.query({
      providesTags: ['CHILD_PROJECT'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/child_projects/${id}`,
        }),
    }),

    // [GET] /user_projects_statistics/:user_id
    getUserProjectStatistics: build.query({
      providesTags: ['USER_PROJECT_STATISTICS'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/user_projects_statistics/${id}`,
        }),
    }),

    // [POST] /child_projects/:child_project_id
    updateStudentWork: build.mutation({
      invalidatesTags: ['CHILD_PROJECT', 'ME'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/child_projects/${id}`,
        }),
    }),

    // COLLECTIONS
    // [POST] /collections
    getCollections: build.query({
      providesTags: ['COLLECTIONS'],
      query: (body) =>
        secure({
          body,
          method: 'POST',
          url: '/collections',
        }),
    }),

    // [POST] /child_collections/:space_id
    getChildCollections: build.query({
      providesTags: ['CHILD_COLLECTIONS'],
      query: ({ space_id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/child_collections/${space_id}`,
        }),
    }),

    // [POST] /child_collections/:space_id
    getChildCollection: build.query({
      providesTags: ['CHILD_COLLECTION'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/child_collections/${id}`,
        }),
    }),

    // [DELETE] /child_collections/:id
    deleteChildCollection: build.mutation({
      invalidatesTags: ['CHILD_COLLECTION', 'CHILD_COLLECTIONS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/child_collections/${id}`,
        }),
    }),

    // [GET] /collections/:id
    getCollection: build.query({
      providesTags: ['COLLECTION'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/collections/${id}`,
        }),
    }),

    // [POST] /spaces/:space_id/collection
    createCollection: build.mutation({
      invalidatesTags: ['COLLECTION', 'COLLECTIONS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${id}/collection`,
        }),
    }),

    // [POST] /collections/:collection_id
    updateCollection: build.mutation({
      invalidatesTags: ['COLLECTION', 'COLLECTIONS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/collections/${id}`,
        }),
    }),

    // [PATCH] /collections/:collection_id/projects
    updateCollectionProjects: build.mutation({
      invalidatesTags: ['COLLECTION'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/collections/${id}/projects`,
        }),
    }),

    // [DELETE] /collections/:id
    deleteCollection: build.mutation({
      invalidatesTags: ['COLLECTION', 'COLLECTIONS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/collections/${id}`,
        }),
    }),

    // [POST] /collections/:collection_id/assign
    assignCollection: build.mutation({
      invalidatesTags: ['COLLECTION'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/collections/${id}/assign`,
        }),
    }),

    // [POST] /collections/:collection_id/request
    requestCollection: build.mutation({
      invalidatesTags: ['COLLECTION'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/collections/${id}/request`,
        }),
    }),

    // PORTFOLIO
    // [POST] /portfolios/:space_id/groups_and_students
    getPortfolios: build.query({
      providesTags: ['PORTFOLIOS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/portfolios/${spaceId}/groups_and_students`,
        }),
    }),

    getPortfolio: build.query({
      providesTags: ['PORTFOLIO'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/portfolios/${id}`,
        }),
    }),

    // [POST] /spaces/:space_id/portfolio
    createPortfolio: build.mutation({
      invalidatesTags: ['PORTFOLIOS'],
      query: ({ space_id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${space_id}/portfolio`,
        }),
    }),

    // [DELETE] /portfolios/:id
    deletePortfolio: build.mutation({
      invalidatesTags: ['PORTFOLIO', 'PORTFOLIOS'],
      query: ({ id }) =>
        secure({
          method: 'DELETE',
          url: `/portfolios/${id}`,
        }),
    }),

    // [DELETE] /portfolios/:id
    updatePortfolio: build.mutation({
      invalidatesTags: ['PORTFOLIO', 'PORTFOLIOS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/portfolios/${id}`,
        }),
    }),

    // [POST] /portfolios/:id/share
    sharePortfolio: build.mutation({
      invalidatesTags: ['PORTFOLIO'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/portfolios/${id}/share`,
        }),
    }),

    // STUDENTS
    getStudent: build.query({
      providesTags: ['STUDENT'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/student/${id}`,
        }),
    }),

    createStudent: build.mutation({
      invalidatesTags: ['STUDENTS'],
      query: ({ spaceId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/spaces/${spaceId}/student`,
        }),
    }),

    updateStudent: build.mutation({
      invalidatesTags: ['STUDENT', 'STUDENTS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/student/${id}`,
        }),
    }),

    updateStudentPermissions: build.mutation({
      invalidatesTags: ['STUDENT', 'STUDENTS'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/student/${id}/permissions`,
        }),
    }),

    createStudentHealthDetails: build.mutation({
      invalidatesTags: ['STUDENT'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/student/${id}/health_details`,
        }),
    }),

    updateStudentHealthDetails: build.mutation({
      invalidatesTags: ['STUDENT'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/student/${id}/health_details`,
        }),
    }),

    // PARENTS
    getParent: build.query({
      providesTags: ['PARENT'],
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/parents/${id}`,
        }),
    }),

    fetchParent: build.mutation({
      query: ({ id }) =>
        secure({
          method: 'GET',
          url: `/parents/${id}`,
        }),
    }),

    createParent: build.mutation({
      invalidatesTags: ['PARENTS'],
      query: ({ studentId, ...body }) =>
        secure({
          body,
          method: 'POST',
          url: `/parents/${studentId}/parent`,
        }),
    }),

    updateParent: build.mutation({
      invalidatesTags: ['PARENTS', 'PARENT'],
      query: ({ parentId, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/parents/${parentId}`,
        }),
    }),

    // ADMIN
    // [POST] /storage/upload_temporary_file
    updateAdminDetails: build.mutation({
      invalidatesTags: ['ME'],
      query: ({ id, ...body }) =>
        secure({
          body,
          method: 'PATCH',
          url: `/admin/${id}`,
        }),
    }),
  }),
});

export const {
  useAuthorizeUserMutation,
  useValidateEmailMutation,
  useValidateSmsMutation,
  useResetEmailPasswordMutation,
  useResetSmsPasswordMutation,
  useUpdatePasswordMutation,
  useChangePasswordMutation,
  useGetMeQuery,
  useRegisterSpaceMutation,
  useUploadTemporaryFileMutation,
  useUploadTemporaryFilesMutation,
  useGetTagsQuery,
  useGetTipsQuery,
  useGetTipQuery,
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useCreateTipMutation,
  useCreateQuestionMutation,
  useCreateQuestionCommentMutation,
  useCreateTagMutation,
  useUpdateTipMutation,
  useUpdateQuestionMutation,
  useDeleteTipMutation,
  useDeleteQuestionMutation,
  useGetIdeaQuery,
  useGetIdeasQuery,
  useCreateIdeaMutation,
  useUpdateIdeaMutation,
  useDeleteIdeaMutation,
  useGetLevelsQuery,
  usePublishIdeaMutation,
  useGetGroupsQuery,
  useUpdateAdminDetailsMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetChildProjectsQuery,
  useGetCollectionsQuery,
  useGetChildCollectionsQuery,
  useCreateCollectionMutation,
  useUpdateCollectionProjectsMutation,
  useGetCollectionQuery,
  useDeleteCollectionMutation,
  useAssignProjectMutation,
  useRequestProjectMutation,
  useGetChildProjectQuery,
  useGetStatusesQuery,
  useUpdateStudentWorkMutation,
  useUpdateProjectMutation,
  useUpdateCollectionMutation,
  useAssignCollectionMutation,
  useGetStudentsQuery,
  useGetChildCollectionQuery,
  useGetIdeaProjectsQuery,
  useCreatePortfolioMutation,
  useGetPortfoliosQuery,
  useGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetStudentQuery,
  useCreateStudentMutation,
  useCreateStudentHealthDetailsMutation,
  useUpdateStudentHealthDetailsMutation,
  useUpdateStudentMutation,
  useUpdateStudentPermissionsMutation,
  useGetParentQuery,
  useCreateParentMutation,
  useUpdateParentMutation,
  useGetUsersQuery,
  useCreateTeacherMutation,
  useCreateAdminMutation,
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetRelationsQuery,
  useGenerateNicknameMutation,
  useFetchParentMutation,
  useGetUserQuery,
  useCreateGroupMutation,
  useGetGroupQuery,
  useEditGroupMutation,
  useGetGroupsWithLeadersQuery,
  useDeleteGroupMutation,
  useGetUserProjectStatisticsQuery,
  useGetUserProjectsQuery,
  useDeleteChildCollectionMutation,
  useUpdateLanguageMutation,
  useGetGroupProjectStatisticsQuery,
  useUpdateAdminMutation,
  useGetFavoriteIdeasQuery,
  useUpdateTeacherMutation,
  useGetGroupProjectsQuery,
  useRequestCollectionMutation,
  useGetUserAchievementsQuery,
  useGetSpacesQuery,
  useGetSpaceQuery,
  useDeleteChildProjectMutation,
  useDeleteSpaceMutation,
  useUpdateAchievementsMutation,
  useUpdateNotificationPermissionsMutation,
  useGetNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useDeleteNotificationsMutation,
  useSharePortfolioMutation,
} = slice;

export const actions = {
  useAuthorizeUserMutation,
  useValidateEmailMutation,
  useValidateSmsMutation,
  useResetEmailPasswordMutation,
  useResetSmsPasswordMutation,
  useUpdatePasswordMutation,
  useGetMeQuery,
  useGetTagsQuery,
  useGetTipQuery,
  useRegisterSpaceMutation,
  useUploadTemporaryFileMutation,
  useUploadTemporaryFilesMutation,
  useGetTipsQuery,
  useCreateTipMutation,
  useGetQuestionsQuery,
  useGetQuestionQuery,
  useCreateQuestionMutation,
  useCreateQuestionCommentMutation,
  useCreateTagMutation,
  useUpdateTipMutation,
  useUpdateQuestionMutation,
  useDeleteTipMutation,
  useDeleteQuestionMutation,
  useGetIdeaQuery,
  useGetIdeasQuery,
  useCreateIdeaMutation,
  useUpdateIdeaMutation,
  useDeleteIdeaMutation,
  useGetLevelsQuery,
  usePublishIdeaMutation,
  useGetGroupsQuery,
  useChangePasswordMutation,
  useUpdateAdminDetailsMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetChildProjectsQuery,
  useGetCollectionsQuery,
  useGetChildCollectionsQuery,
  useCreateCollectionMutation,
  useUpdateCollectionProjectsMutation,
  useGetCollectionQuery,
  useDeleteCollectionMutation,
  useAssignProjectMutation,
  useRequestProjectMutation,
  useGetChildProjectQuery,
  useGetStatusesQuery,
  useUpdateStudentWorkMutation,
  useUpdateProjectMutation,
  useUpdateCollectionMutation,
  useAssignCollectionMutation,
  useGetStudentsQuery,
  useGetChildCollectionQuery,
  useGetIdeaProjectsQuery,
  useCreatePortfolioMutation,
  useGetPortfoliosQuery,
  useGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetStudentQuery,
  useCreateStudentMutation,
  useCreateStudentHealthDetailsMutation,
  useUpdateStudentHealthDetailsMutation,
  useUpdateStudentMutation,
  useUpdateStudentPermissionsMutation,
  useGetUsersQuery,
  useCreateTeacherMutation,
  useCreateAdminMutation,
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetRelationsQuery,
  useGenerateNicknameMutation,
  useFetchParentMutation,
  useGetUserQuery,
  useCreateGroupMutation,
  useGetGroupQuery,
  useEditGroupMutation,
  useGetGroupsWithLeadersQuery,
  useDeleteGroupMutation,
  useGetUserProjectStatisticsQuery,
  useGetUserProjectsQuery,
  useDeleteChildCollectionMutation,
  useUpdateLanguageMutation,
  useGetGroupProjectStatisticsQuery,
  useUpdateAdminMutation,
  useGetFavoriteIdeasQuery,
  useUpdateTeacherMutation,
  useGetGroupProjectsQuery,
  useRequestCollectionMutation,
  useGetUserAchievementsQuery,
  useGetSpacesQuery,
  useGetSpaceQuery,
  useDeleteChildProjectMutation,
  useDeleteSpaceMutation,
  useUpdateAchievementsMutation,
  useUpdateNotificationPermissionsMutation,
  useGetNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useDeleteNotificationsMutation,
  useSharePortfolioMutation,
};

export default slice;
