import { Typography, Box, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Tag, Paper } from 'components';

import { STATUS } from 'utils/constants';
import { getStatusStyles } from 'styles/helpers';

const StepperRoot = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  right: '100%',
  top: theme.spacing(12),
  transform: `translateX(-${theme.spacing(3)})`,
  width: 216,
}));

const Step = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  marginBottom: theme.spacing(1.5),
  position: 'relative',
}));

const Circle = styled(Box)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  position: 'absolute',
  left: 0,
  top: 4,
  border: '4px solid currentColor',
}));

const Line = styled(Box)(({ theme }) => ({
  width: 4,
  borderRadius: theme.shape.borderRadius,
  position: 'absolute',
  left: `-${theme.spacing(2.25)}`,
  top: 0,
  bottom: 0,
  background: 'currentColor',
}));

const STEP_COLOR_BY_STATUS = {
  [STATUS.PENDING]: 'controls.disabled',
  [STATUS.IN_PROGRESS]: 'warning.light',
  [STATUS.COMPLETED]: 'primary.main',
};

const getStatus = (currentStep, step) => {
  if (currentStep === step) return STATUS.IN_PROGRESS;
  return currentStep > step ? STATUS.COMPLETED : STATUS.PENDING;
};

const Stepper = (props) => {
  const { steps, currentStep } = props;

  const { t } = useTranslation('components', { keyPrefix: 'Stepper' });

  return (
    <StepperRoot shadow={false} py={3} px={4.5}>
      <Stack>
        {steps.map((step, index) => {
          const status = getStatus(currentStep, index);
          const color = STEP_COLOR_BY_STATUS[status];

          return (
            <Step key={`step-${step.id}`}>
              <Box mb={1}>
                <Circle color={color} />
                <Typography variant="caption1" color="secondary.contrastText">
                  {t('step', { index: index + 1 })}
                </Typography>
              </Box>
              <Box position="relative">
                <Line color={color} />
                <Typography
                  component="div"
                  variant={status === STATUS.IN_PROGRESS ? 'button3' : 'caption1'}
                  color={
                    status === STATUS.IN_PROGRESS ? 'textBlack.main' : 'textBlack.contrastText'
                  }
                  mb={1}
                >
                  {step.label}
                </Typography>
                <Tag
                  px={0.5}
                  size="medium"
                  sx={getStatusStyles({ status: status, border: true })}
                  label={status}
                />
              </Box>
            </Step>
          );
        })}
      </Stack>
    </StepperRoot>
  );
};
export default Stepper;
