import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMessage } from 'hooks';
import { editorStateToRawHtml } from 'utils/helpers';

import { useUpdateStudentWorkMutation, useUploadTemporaryFileMutation } from 'store/api';

const useUpdateProcessPhotos = (opts) => {
  const { project, onSuccess } = opts || {};

  const { t } = useTranslation('pages', { keyPrefix: 'single_project' });
  const m = useMessage();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [updateStudentWork, updateStudentWorkState] = useUpdateStudentWorkMutation();

  const handeAddPhotoOfProcess = useCallback(
    async (formData, state) => {
      if (!project) {
        m.error(t('error.failed_add_photo'));
        return;
      }

      const { photo, ...itemData } = formData;
      const [file] = photo;

      if (file instanceof File) {
        try {
          const { file_id } = await uploadFile({ file }).unwrap();

          itemData.link = file_id;
        } catch (err) {
          m.error(t('error.failed_upload_photo'));
          return;
        }
      }

      try {
        let studentWorks = project.student_works.slice(0);
        if (state === 'edit') {
          studentWorks.splice(formData.index, 1, {
            name: itemData.name,
            link: itemData.link,
            description: editorStateToRawHtml(itemData.description) || undefined,
          });
        } else {
          studentWorks.push({
            name: itemData.name,
            description: editorStateToRawHtml(itemData.description) || undefined,
            link: itemData.link,
          });
        }

        const { status } = await updateStudentWork({
          id: project.id,
          photos: studentWorks,
          status: project.project_status !== 'In Progress' ? 'In Progress' : undefined,
        }).unwrap();

        if (status === 'OK') {
          m.success(t(state === 'edit' ? 'success_update_photo' : 'success_add_photo'));

          typeof onSuccess === 'function' && onSuccess();
        }
      } catch (err) {
        m.error(t('error.failed_add_photo'));
      }
    },
    [updateStudentWork, uploadFile, onSuccess, project, t, m],
  );

  const handleDeletePhotoOfProcess = useCallback(
    async (index) => {
      if (!project) {
        m.error(t('error.failed_delete_photo'));
        return;
      }

      try {
        const studentWorks = project.student_works.slice(0);
        studentWorks.splice(index, 1);

        const { status } = await updateStudentWork({
          id: project.id,
          photos: studentWorks,
          status: undefined,
        }).unwrap();

        if (status === 'OK') {
          m.success(t('success_delete_photo'));
          typeof onSuccess === 'function' && onSuccess();
        }
      } catch (err) {
        m.error(t('error.failed_delete_photo'));
      }
    },
    [updateStudentWork, onSuccess, project, t, m],
  );

  const handleAddStudentVideo = useCallback(
    async (formData) => {
      if (!project) {
        m.error(t('error.failed_add_video'));
        return;
      }

      try {
        const { status } = await updateStudentWork({
          id: project.id,
          photos: project.student_works,
          video: formData?.video,
        }).unwrap();

        if (status === 'OK') {
          m.success(t('success_add_video'));
          typeof onSuccess === 'function' && onSuccess();
        }
      } catch (err) {
        m.error(t('error.failed_add_video'));
      }
    },
    [updateStudentWork, onSuccess, project, t, m],
  );

  return useMemo(
    () => ({
      handleAddStudentVideo,
      handeAddPhotoOfProcess,
      handleDeletePhotoOfProcess,
      loading: uploadFileState.isLoading || updateStudentWorkState.isLoading,
    }),
    [
      handeAddPhotoOfProcess,
      handleAddStudentVideo,
      handleDeletePhotoOfProcess,
      uploadFileState,
      updateStudentWorkState,
    ],
  );
};

export default useUpdateProcessPhotos;
