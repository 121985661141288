import { getArray } from 'utils/helpers';

const mapRequest = (formData) => {
  const { tags, public: formPublic, name } = formData;

  return {
    name,
    public: formPublic,
    photos: [],
    tags: getArray(tags).map((tag) => tag.value),
  };
};

export default mapRequest;
