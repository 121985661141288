import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Center, Tag } from 'components';
import { getStatusStyles } from 'styles/helpers';

const Circle = styled(Box)({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'currentColor',
});

const TagsCount = styled(Tag)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.input.main,
}));

const TagHolder = styled(Center)({
  justifyContent: 'flex-start',
});

const StyledTag = styled(Tag, {
  shouldForwardProp: (prop) => !['active', 'status'].includes(prop),
})(({ theme, status, active }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  backgroundColor: active ? theme.palette.controls.main : theme.palette.other.white,
  ...getStatusStyles({ status, border: true }),
}));

const checkActive = (tags = [], tag) => {
  const foundIndex = tags.findIndex((item) => item.id === tag.id);
  return foundIndex !== -1;
};

const TagItem = (props) => {
  const { tag, onChange, ...rest } = props;

  return (
    <TagHolder mb={1}>
      <StyledTag
        size="medium"
        clickable
        onClick={() => onChange(tag)}
        pl={1}
        startAdornment={<Circle />}
        label={tag.name}
        status={tag.id}
        {...rest}
      />
      {tag.qty > 0 && (
        <Typography ml={1} variant="caption1" color="textBlack.light">
          ({tag.qty})
        </Typography>
      )}
    </TagHolder>
  );
};

const StatusFilter = (props) => {
  const { value = [], total, tags = [], onValue } = props;

  const { t } = useTranslation('views', { keyPrefix: 'StatusFilter' });

  const handleChange = useCallback(
    (tag) => {
      if (typeof onValue !== 'function') return;

      const foundIndex = value.findIndex((item) => item.id === tag.id);
      if (foundIndex === -1) {
        onValue([...value, tag]);
      } else {
        const changed = [...value];
        changed.splice(foundIndex, 1);
        onValue(changed);
      }
    },
    [value, onValue],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Center mb={1} justifyContent="space-between">
        <Typography color="textBlack.light">{t('title')}</Typography>
        {total > 0 && <TagsCount size="small" label={total} />}
      </Center>

      <Stack mt={1} sx={{ maxHeight: '432px', overflow: 'auto' }}>
        {tags.map((tag, index) => (
          <TagItem
            key={`status-filter-${tag.id}-${index}`}
            tag={tag}
            active={checkActive(value, tag)}
            onChange={handleChange}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default StatusFilter;
