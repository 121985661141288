import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid } from '@mui/material';

import { getArray } from 'utils/helpers';
import { useForm } from 'hooks';

import { PaperCard, Paper } from 'components';
import { CardContent } from 'views';

import FilterForm from './FilterForm';

const AssignedStudents = (props) => {
  const { students, ...rest } = props;

  const { form } = useForm();
  const watchStudent = form.watch('student');
  const watchGroup = form.watch('group');

  const { t } = useTranslation('pages', { keyPrefix: 'single_collection' });

  const filteredStudents = useMemo(() => {
    if (!watchStudent && !watchGroup) return students;

    return students.filter((student) => {
      const selectedStudent = watchStudent && student.id === watchStudent;
      const selectedGroup = watchGroup && student.id === watchGroup;

      if (!watchStudent) return selectedGroup;
      if (!watchGroup) return selectedStudent;
      return selectedGroup && selectedStudent;
    });
  }, [watchStudent, watchGroup, students]);

  const formOptions = useMemo(() => {
    if (!students) return {};

    const usersById = {};
    const groups = [{ label: 'None', value: null }];

    for (let i = 0; i < students.length; i++) {
      const student = students[i];

      if (!student?.author?.first_name) {
        groups.push({
          label: student.author.name,
          value: student.id,
          avatar: student.author.avatar,
        });
        continue;
      }

      if (!usersById[student.author.id]) {
        usersById[student.author.id] = {
          label: student.author.name,
          value: student.id,
          avatar: student.author.avatar,
        };
      }
    }

    return {
      groups,
      users: [{ label: 'None', value: null }, ...Object.values(usersById)],
    };
  }, [students]);

  return (
    <Box component="section" {...rest}>
      <Typography variant="subtitle3" component="h3" mb={1}>
        {t('students_working_on_project')}
      </Typography>

      <FilterForm formOptions={formOptions} form={form} />

      <Paper shadow="small" border width={1}>
        <Grid container spacing={3}>
          {getArray(filteredStudents).map((student) => (
            <Grid item xs={4} key={student.id}>
              <PaperCard border>
                <CardContent
                  participants={student.group_participants}
                  projects_qty={student.projects_qty}
                  projects_published={student.projects_published}
                  avatar={student.author?.avatar}
                  name={student.author?.name}
                  variant="collection_assigned_student"
                  type="student"
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default AssignedStudents;
