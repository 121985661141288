import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth, useForm, useMessage } from 'hooks';
import {
  useGetMeQuery,
  useUpdateAdminDetailsMutation,
  useUploadTemporaryFileMutation,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Paper, Form, Container, Tabs } from 'components';
import { UserInformationForm, PageSubheader } from 'views';

import mapRequest from './mapRequest';

const contentWidth = 936;

const mapInitialData = (user) => {
  if (!user) return {};
  return {
    full_name: user?.profile?.name || '',
    first_name: user?.profile?.first_name || '',
    last_name: user?.profile?.last_name || '',
    avatar: [user?.profile?.avatar],
    phone_number: user?.phone_number || '',
    email: user?.email || '',
  };
};

const UserTab = (props) => {
  const { id, tab, onTabChange } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'profile.user' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const {
    data: profile,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const [updateAdminDetails, updateAdminDetailsState] = useUpdateAdminDetailsMutation();
  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleUpdateUser = useCallback(
    async (formData) => {
      const postData = { ...formData };
      const [file] = postData.avatar;

      if (file) {
        try {
          if (file instanceof File) {
            const { file_id } = await uploadFile({ file }).unwrap();
            postData.avatar_file_id = file_id;
            form.setValue('avatar', [{ id: file_id, url: URL.createObjectURL(file) }]);
          } else {
            postData.avatar_file_id = file?.id || file?.url || file;
          }
        } catch (err) {
          m.error('error_avatar_upload');
          return;
        }
      }

      try {
        const { status } = await updateAdminDetails({
          id: profile?.profile?.id,
          ...mapRequest(profile, postData),
        }).unwrap();

        if (status === 'OK') m.success(t('update_success'));
      } catch (err) {
        console.error(err);
        m.error(t('update_failed'));
      }
    },
    [updateAdminDetails, uploadFile, m, profile, t, form],
  );

  useEffect(() => {
    form.reset(mapInitialData(profile));
  }, [form, profile]);

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    updateAdminDetailsState.isLoading ||
    uploadFileState.isLoading;

  return (
    <Form id={`${tab}-form`} width="100%" form={form} onSubmit={handleUpdateUser}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backTitle={t('back_title')}
            backUrl={`/profile/${id}`}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.save')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading || !form.formState.isDirty,
              },
            }}
          />
        }
      >
        <Container width={contentWidth}>
          <Box py={4}>
            <Typography
              width="100%"
              component="h3"
              mb={2}
              color="secondary.main"
              variant="subtitle3"
            >
              {t('page_title')}
            </Typography>

            <Tabs
              width={396}
              variant="fullWidth"
              value={tab}
              onValue={onTabChange}
              options={[
                {
                  label: 'User information',
                  value: id,
                },
                {
                  label: 'Individual settings',
                  value: `${id}/settings`,
                },
              ]}
            />
            <Box pt={3}>
              <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
                <UserInformationForm
                  title={t('personal_information')}
                  form={form}
                  valid={valid}
                  rules={rules}
                />
              </Paper>
            </Box>
          </Box>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default UserTab;
