import { getArray, editorStateToRawHtml } from 'utils/helpers';

const mapStep = (step, index) => {
  if (!step?.name) return false;
  return {
    photos: step.photos || [],
    name: step.name,
    description: editorStateToRawHtml(step.description) || undefined,
    step_number: index + 1,
    source: getArray(step?.sources)
      .map((source) => source.url)
      .filter(Boolean),
  };
};

const mapRequest = (formData) => {
  const {
    name,
    idea_id,
    tags,
    sources,
    level,
    copyright,
    photos,
    description,
    steps,
    collections,
  } = formData;

  return {
    idea_id: idea_id || null,
    name: name,
    copyright: copyright || undefined,
    photos: photos || [],
    level: level || '',
    signers: [],
    groups: [],
    description: editorStateToRawHtml(description) || undefined,
    source: getArray(sources)
      .map((source) => source.url)
      .filter(Boolean),
    tags: getArray(tags).map((tag) => tag.value),
    collections: getArray(collections).map((collection) => collection.value),
    steps: getArray(steps).map(mapStep).filter(Boolean),
  };
};

export default mapRequest;
