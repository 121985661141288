import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import MuiFormHelperText from '@mui/material/FormHelperText';
import styled from '@mui/material/styles/styled';

import { dev } from 'utils/config';
import Fieldset from 'components/Dev/Fieldset';

const StyledFormHelperText = styled(MuiFormHelperText, {
  label: 'FormHelperText-Text',
})(({ theme, disabled }) => ({
  margin: 0,
  marginLeft: theme.spacing(1),
  fontSize: '11px',
  opacity: disabled ? 0.5 : 1,
}));

/**
 * Shows helper text with collapse animation (if nullable value has passed after the text,
 * will wait for animation end before text removing).
 */
const FormHelperText = forwardRef((props, ref) => {
  const { children, error, disabled } = props;
  const [current, setCurrent] = useState(children);

  const content = useMemo(() => (
    !current && children ? children : current
  ), [current, children]);

  useEffect(() => {
    if (!current && children) {
      setCurrent(children);
    }
    if (current && children && current !== children) {
      setCurrent(children);
    }
  }, [children, current]);

  const handleExited = useCallback(() => {
    setCurrent(children);
  }, [children]);

  return (
    <Collapse
      ref={ref}
      in={!!children}
      onExited={handleExited}
    >
      <StyledFormHelperText
        error={error}
        disabled={disabled}
      >
        {content}
      </StyledFormHelperText>
    </Collapse>
  );
});

if (dev) {
  const Demo = () => {
    const [children, setChildren] = useState('Helper text');
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, 'Helper text', 'This field is required!']}
          />
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box>
          <FormHelperText
            error={error}
            disabled={disabled}
          >
            {children}
          </FormHelperText>
        </Box>
      </Box>
    );
  };
  FormHelperText.Demo = Demo;
}

export default FormHelperText;
