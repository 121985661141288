import { useMemo } from 'react';
import { Typography, Stack, Box, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ROLE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';
import {
  Tag,
  Tags,
  Center,
  ImageHolder,
  Checkbox,
  IconButton,
  Select,
  LinearProgress,
  Icon,
  AvatarGroup,
} from 'components';
import { LevelTag } from 'views';
import { getStatusStyles } from 'styles/helpers';

const DragButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: -8,
  top: -4,
  minWidth: 'auto',
  padding: 0,
  color: theme.palette.secondary.light,
}));

const Circle = styled(Box)({
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: 'currentColor',
});

const ImageRoot = styled(Box)({
  position: 'relative',
});

const Info = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
}));

const Level = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const VariantCollection = (props) => {
  const { title, level, photos, avatar, tags, projectsQty } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  const photo = useMemo(() => {
    if (avatar) return avatar;
    if (!photos?.length) return null;
    return photos[0];
  }, [avatar, photos]);

  return (
    <Box width={1}>
      <ImageRoot>
        <ImageHolder
          m={0}
          origin="container"
          flexShrink={0}
          width="100%"
          height={135}
          src={photo}
          radius={3}
          sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        />
        <Level>
          <LevelTag level={level} label={capitalize(level)} size="small" mb={1} />
        </Level>
      </ImageRoot>
      <Info flexGrow={1}>
        <Typography component="div" variant="subtitle4" mb={2}>
          {title}
        </Typography>

        {projectsQty > 0 && (
          <Tags
            titleProps={{ minWidth: 80 }}
            mb={2}
            max={1}
            title={t('projects')}
            justifyContent="space-between"
            tags={[
              {
                pl: 1.5,
                pr: 0.5,
                startAdornment: <Icon name="Project" />,
                name: projectsQty,
                id: projectsQty,
              },
            ]}
            name={title}
          />
        )}
        <Tags
          justifyContent="space-between"
          max={2}
          titleProps={{ minWidth: 80 }}
          title={t('tags')}
          tags={tags}
          name={title}
        />
      </Info>
    </Box>
  );
};

const VariantIdea = (props) => {
  const { title, tags, level, size, photo, tagsTitle } = props;

  return (
    <Center width="100%">
      <ImageHolder mr={2} flexShrink={0} size={size === 'small' ? 128 : 136} src={photo} />
      <Box flexGrow={1}>
        <Center justifyContent="space-between">
          <LevelTag level={level} label={capitalize(level)} size="medium" mb={1} />
        </Center>
        <Typography component="div" variant="subtitle4" mb={1}>
          {title}
        </Typography>

        <Tags max={3} title={tagsTitle} tags={tags} name={title} />
      </Box>
    </Center>
  );
};

const VariantIdeaSelectable = (props) => {
  const { title, tags, level, size, photos, tagsTitle, selected = false } = props;
  return (
    <Center width="100%">
      <ImageHolder
        mr={2}
        flexShrink={0}
        size={size === 'small' ? 128 : 136}
        src={getArray(photos)[0]}
      />
      <Box flexGrow={1} position="relative">
        <Center justifyContent="space-between">
          <LevelTag level={level} label={capitalize(level)} size="medium" mb={1} />
        </Center>
        <Typography component="div" variant="subtitle4" mb={1}>
          {title}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            '& .MuiFormControlLabel-root': { marginRight: 0 },
          }}
        >
          <Checkbox checked={selected} />
        </Box>

        <Tags max={3} title={tagsTitle} tags={tags} name={title} />
      </Box>
    </Center>
  );
};

const VariantIdeaReordable = (props) => {
  const { title, tags, level, size, photos, tagsTitle, onDelete } = props;
  return (
    <Center width="100%" pl={3} position="relative">
      <ImageHolder
        mr={2}
        flexShrink={0}
        size={size === 'small' ? 128 : 136}
        src={getArray(photos)[0]}
      />
      <Box flexGrow={1}>
        <Center justifyContent="space-between">
          <LevelTag level={level} label={capitalize(level)} size="medium" mb={1} />
        </Center>
        <Typography component="div" variant="subtitle4" mb={1}>
          {title}
        </Typography>

        <DragButton variant="text" name="Drag" fontSize={24} />

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 3,
            '& .MuiFormControlLabel-root': { marginRight: 0 },
          }}
        >
          <IconButton
            color="error"
            variant="outlined"
            border
            name="Delete"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              typeof onDelete === 'function' && onDelete();
            }}
          />
        </Box>

        <Tags max={3} title={tagsTitle} tags={tags} name={title} />
      </Box>
    </Center>
  );
};

const VariantProjectSelectable = (props) => {
  const { title, description, owner, size, photos, selected = false } = props;
  return (
    <Center width="100%">
      <ImageHolder
        mr={2}
        flexShrink={0}
        size={size === 'small' ? 128 : 136}
        src={getArray(photos)[0]}
      />
      <Box flexGrow={1} position="relative">
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            '& .MuiFormControlLabel-root': { marginRight: 0 },
          }}
        >
          <Checkbox checked={selected} />
        </Box>
        <Typography component="div" variant="subtitle4" mb={1}>
          {title}
        </Typography>
        {owner?.name && (
          <Center justifyContent="space-between" mb={1}>
            <Tag
              label={owner.name}
              avatar={owner.avatar}
              size="small"
              bgcolor="input.main"
              avatarSize={24}
            />
          </Center>
        )}
        <Typography
          component="div"
          variant="paragraph"
          color="textBlack.light"
          mb={1}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Box>
    </Center>
  );
};

const VariantProjectReordable = (props) => {
  const { title, description, size, photos, owner, onDelete } = props;
  return (
    <Center width="100%" pl={3} position="relative">
      <ImageHolder
        mr={2}
        flexShrink={0}
        size={size === 'small' ? 128 : 136}
        src={getArray(photos)[0]}
      />
      <Box flexGrow={1}>
        <DragButton variant="text" name="Drag" fontSize={24} />

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 3,
            '& .MuiFormControlLabel-root': { marginRight: 0 },
          }}
        >
          <IconButton
            color="error"
            variant="outlined"
            border
            name="Delete"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              typeof onDelete === 'function' && onDelete();
            }}
          />
        </Box>
        <Typography component="div" variant="subtitle4" mb={1}>
          {title}
        </Typography>

        {owner?.name && (
          <Center justifyContent="space-between" mb={1}>
            <Tag
              label={owner.name}
              avatar={owner.avatar}
              size="small"
              bgcolor="input.main"
              avatarSize={24}
            />
          </Center>
        )}

        <Typography
          component="div"
          variant="paragraph"
          color="textBlack.light"
          mb={1}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Box>
    </Center>
  );
};

const VariantProjectStudent = (props) => {
  const { title, photo, status, type } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  return (
    <Center width="100%">
      <ImageHolder mr={1.5} flexShrink={0} size={112} src={photo} />
      <Box flexGrow={1}>
        <Typography component="div" variant="subtitle5" mb={2} color="textRegular.light">
          {title}
        </Typography>

        <Tags
          max={3}
          justifyContent="space-between"
          mb={1}
          size="small"
          title={t('project_status')}
          titleProps={{ variant: 'body3' }}
          tags={[status]}
          name={title}
        />
        <Tags
          max={3}
          justifyContent="space-between"
          title={t('project_type')}
          titleProps={{ variant: 'body3' }}
          tags={[type]}
          name={title}
        />
      </Box>
    </Center>
  );
};

const VariantProjectAssignStudent = (props) => {
  const { name, title, photo, level, userOptions = [] } = props;

  return (
    <Center width="100%">
      <ImageHolder mr={1.5} flexShrink={0} size={104} src={photo} />
      <Box flexGrow={1}>
        <LevelTag level={level} label={capitalize(level)} size="small" mb={1} />
        <Typography component="div" variant="body3" color="textBlack.dark" mb={1}>
          {title}
        </Typography>
        <Select.Control
          box={false}
          collapse
          itemsMax={5}
          fullWidth
          name={`${name}.student`}
          options={userOptions}
        />
      </Box>
    </Center>
  );
};

const VariantCollectionAssignedStudent = (props) => {
  const { name, avatar, projects_qty, projects_published, participants } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  const progress = useMemo(() => {
    return (projects_published / projects_qty) * 100;
  }, [projects_qty, projects_published]);

  return (
    <Box width="100%">
      <Center mb={2.5} justifyContent="space-between">
        <Center>
          <ImageHolder mr={1} flexShrink={0} size={24} src={avatar} />
          <Typography component="div" variant="body2" color="text.secondary">
            {name}
          </Typography>
        </Center>

        <AvatarGroup
          size={16}
          max={4}
          avatars={getArray(participants).map((p) => ({ id: p.id, src: p.avatar }))}
        />
      </Center>
      <Box>
        <LinearProgress variant="determinate" value={progress} />

        <Center mt={0.5} justifyContent="space-between">
          <Typography component="div" variant="caption1" color="textBlack.light">
            {t('project_published')}
          </Typography>
          <Typography component="div" variant="caption1" color="textBlack.light">
            {projects_published}/{projects_qty}
          </Typography>
        </Center>
      </Box>
    </Box>
  );
};

const VariantStudentWork = (props) => {
  const { title, photo, description } = props;

  const theme = useTheme();

  return (
    <Box width="100%">
      <ImageRoot>
        <ImageHolder
          m={0}
          origin="container"
          flexShrink={0}
          width="100%"
          height={135}
          src={photo}
          radius={3}
          sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        />
      </ImageRoot>
      <Info flexGrow={1}>
        <Typography component="div" variant="subtitle4" mb={0.5}>
          {title}
        </Typography>
        <Typography
          sx={{ '& p': { margin: 0, marginBottom: theme.spacing(1) } }}
          component="div"
          variant="paragraph2"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Info>
    </Box>
  );
};

const VariantPortfolio = (props) => {
  const { title, photo, projectsQty } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  return (
    <Center width="100%" alignItems="stretch">
      <ImageHolder mr={2} flexShrink={0} width={136} height={92} src={photo} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        <Typography component="div" variant="subtitle5" color="textBlack.dark" mb={1}>
          {title}
        </Typography>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light">
            {t('includes_projects')}
          </Typography>
          <Box width={32} pb={1}>
            <Tag
              fullWidth
              justifyContent="center"
              size="medium"
              bgcolor="input.main"
              label={projectsQty || 0}
            />
          </Box>
        </Center>
      </Box>
    </Center>
  );
};

const VariantPortfolioListItem = (props) => {
  const { title, photo, projectsQty, username, userAvatar } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  return (
    <Center width="100%" alignItems="stretch">
      <ImageHolder mr={2} flexShrink={0} width={160} height={120} src={photo} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        <Typography component="div" variant="subtitle5" color="textBlack.dark" mb={1}>
          {title}
        </Typography>
        <Center justifyContent="space-between" mb={1}>
          <Typography variant="body4" color="textBlack.light">
            {t('user')}
          </Typography>
          <Box>
            <Tag
              justifyContent="center"
              size="medium"
              bgcolor="input.main"
              label={username}
              avatar={userAvatar}
              avatarSize={16}
              pl={1}
            />
          </Box>
        </Center>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light">
            {t('includes_projects')}
          </Typography>
          <Box width={32}>
            <Tag
              fullWidth
              justifyContent="center"
              size="medium"
              bgcolor="input.main"
              label={projectsQty || 0}
            />
          </Box>
        </Center>
      </Box>
    </Center>
  );
};

const iconByRole = {
  student: 'Student',
  teacher: 'Teacher',
  parent: 'Parent',
  admin: 'Admin',
  personal: 'User',
  organization: 'Organization',
};

const VariantUser = (props) => {
  const { avatar, username, fullName, email, role, groups } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  const getExtraTagProps = () => ({
    startAdornment: <Icon name="Group" />,
    pl: 1,
    color: 'textBlack.light',
  });

  return (
    <Center width="100%" alignItems="stretch">
      <ImageHolder mr={2} flexShrink={0} width={160} height={152} src={avatar} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        <Center justifyContent="space-between" mb={1}>
          <Typography component="div" variant="subtitle5" color="textBlack.dark">
            {username}
          </Typography>
          {role === ROLE.STUDENT && <Tag size="medium" bgcolor="input.main" label={fullName} />}
        </Center>
        <Box>
          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('role')}
            </Typography>
            <Box>
              <Tag
                justifyContent="center"
                size="medium"
                bgcolor="input.main"
                startAdornment={<Icon name={iconByRole[role]} />}
                label={t(`role_${role}`)}
                pl={1}
                minWidth={106}
              />
            </Box>
          </Center>
          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('email')}
            </Typography>
            <Box sx={{ '& a': { textDecoration: 'none' } }}>
              <Typography variant="body3" color="textRegular.light">
                {email || '-'}
              </Typography>
            </Box>
          </Center>
          {groups?.length > 0 && (
            <Center justifyContent="space-between">
              <Typography variant="body4" color="textBlack.light">
                {t('groups')}
              </Typography>
              <Box>
                <Tags
                  name={username}
                  max={2}
                  justifyContent="space-between"
                  mb={1}
                  size="medium"
                  titleProps={{ variant: 'body3' }}
                  tags={groups}
                  getExtraTagProps={getExtraTagProps}
                />
              </Box>
            </Center>
          )}
        </Box>
      </Box>
    </Center>
  );
};

const VariantSpace = (props) => {
  const { avatar, name, role, members, registrationDate, status } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  return (
    <Center width="100%" alignItems="stretch">
      <ImageHolder mr={2} flexShrink={0} width={160} height={152} src={avatar} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        <Center justifyContent="space-between" mb={1}>
          <Typography component="div" variant="subtitle5" color="textBlack.dark">
            {name}
          </Typography>
          <Tag
            startAdornment={<Circle />}
            pl={1}
            label={status}
            size="small"
            sx={getStatusStyles({ status, border: true })}
          />
        </Center>
        <Box>
          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('role')}
            </Typography>
            <Box>
              <Tag
                justifyContent="center"
                size="medium"
                bgcolor="input.main"
                startAdornment={<Icon name={iconByRole[role]} />}
                label={t(`role_${role}`)}
                pl={1}
                minWidth={106}
              />
            </Box>
          </Center>
          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('members')}
            </Typography>
            <Box>
              <Tag
                justifyContent="center"
                size="medium"
                bgcolor="input.main"
                startAdornment={<Icon name="Group" />}
                label={members || 0}
                pl={1}
              />
            </Box>
          </Center>

          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('registration_date')}
            </Typography>
            <Box>
              <Tag
                justifyContent="center"
                size="medium"
                bgcolor="input.main"
                startAdornment={<Icon name="Date" />}
                label={registrationDate || '-'}
                pl={1}
              />
            </Box>
          </Center>
        </Box>
      </Box>
    </Center>
  );
};

const VariantGroupUser = (props) => {
  const { avatar, username, fullName, email, role } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  return (
    <Center width={1}>
      <ImageHolder mr={2} flexShrink={0} width={160} height={112} src={avatar} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        flexGrow={1}
      >
        <Center justifyContent="space-between" mb={2}>
          <Typography component="div" variant="subtitle5" color="textBlack.dark">
            {username}
          </Typography>
          {role === ROLE.STUDENT && <Tag size="medium" bgcolor="input.main" label={fullName} />}
        </Center>
        <Box>
          <Center justifyContent="space-between" mb={1}>
            <Typography variant="body4" color="textBlack.light">
              {t('role')}
            </Typography>
            <Box>
              <Tag
                justifyContent="center"
                size="medium"
                bgcolor="input.main"
                startAdornment={<Icon name={iconByRole[role]} />}
                label={t(`role_${role}`)}
                pl={1}
                minWidth={106}
              />
            </Box>
          </Center>
          <Center justifyContent="space-between">
            <Typography variant="body4" color="textBlack.light">
              {t('email')}
            </Typography>
            <Box sx={{ '& a': { textDecoration: 'none' } }}>
              <Typography variant="body3" color="textRegular.light">
                {email || '-'}
              </Typography>
            </Box>
          </Center>
        </Box>
      </Box>
    </Center>
  );
};

const VariantGroup = (props) => {
  const { name, location, leader, participants, schedule } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  const scheduleInfo = useMemo(() => {
    const info = getArray(schedule)[0];
    if (!info) return {};

    let result = {
      totalDays: 0,
    };

    const days = Object.entries(info);
    for (let i = 0; i < days.length; i++) {
      const [day, time] = days[i];
      if (!time) continue;

      if (!result.displayTime) {
        result.displayDay = day;
        result.displayTime = time.replace(/^(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2');
      }

      result.totalDays += 1;
    }

    return result;
  }, [schedule]);

  return (
    <Box width={1}>
      <Center justifyContent="space-between" mb={1}>
        <Typography component="div" variant="subtitle5" color="textBlack.dark">
          {name}
        </Typography>
        {scheduleInfo?.displayTime && (
          <Center>
            <Tag
              label={t('schedule.every', { day: t(`schedule.${scheduleInfo.displayDay}`) })}
              size="medium"
              bgcolor="input.main"
              startAdornment={<Icon name="Calendar" />}
              pl={1}
            />
            <Tag
              label={scheduleInfo.displayTime}
              size="medium"
              bgcolor="input.main"
              startAdornment={<Icon name="Clock" />}
              pl={1}
              ml={1}
            />
            {scheduleInfo?.totalDays > 1 && (
              <Tag
                label={`${scheduleInfo.totalDays - 1}+`}
                size="medium"
                bgcolor="input.main"
                ml={1}
              />
            )}
          </Center>
        )}
      </Center>
      <Stack gap={2}>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light">
            {t('location')}
          </Typography>
          <Box maxWidth={236} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            <Typography color="textBlack.light" variant="body3">
              {location || '-'}
            </Typography>
          </Box>
        </Center>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light">
            {t('group_leader')}
          </Typography>
          <Box>
            <Tag
              avatar={leader?.avatar}
              label={leader?.name}
              color="textRegular.light"
              bgcolor="input.main"
            />
          </Box>
        </Center>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light">
            {t('students_in_group')}
          </Typography>
          <Box>
            <AvatarGroup
              size="xsmall"
              max={4}
              avatars={getArray(participants).map((p) => ({ id: p.id, src: p.avatar }))}
            />
          </Box>
        </Center>
      </Stack>
    </Box>
  );
};

const CardContent = (props) => {
  const { variant, ...rest } = props;

  const Component = useMemo(() => {
    switch (variant) {
      case 'idea':
        return VariantIdea;
      case 'idea_selectable':
        return VariantIdeaSelectable;
      case 'idea_reordable':
        return VariantIdeaReordable;
      case 'project_selectable':
        return VariantProjectSelectable;
      case 'project_reordable':
        return VariantProjectReordable;
      case 'project_student':
        return VariantProjectStudent;
      case 'project_assign_student':
        return VariantProjectAssignStudent;
      case 'collection':
        return VariantCollection;
      case 'collection_assigned_student':
        return VariantCollectionAssignedStudent;
      case 'student_work':
        return VariantStudentWork;
      case 'portfolio':
        return VariantPortfolio;
      case 'portfolio_list_item':
        return VariantPortfolioListItem;
      case 'user':
        return VariantUser;
      case 'space':
        return VariantSpace;
      case 'group_user':
        return VariantGroupUser;
      case 'group':
        return VariantGroup;
      default:
        return null;
    }
  }, [variant]);

  return <Component {...rest} />;
};

export default CardContent;
