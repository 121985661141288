import { Stack as MuiStack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps } from 'hooks';

import { Input, Select, SelectMultiple, FormRow, TextEditor } from 'components';
import { UploadImage } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const CollectionInformationForm = (props) => {
  const {
    rules: { required, startsWithUppercase },

    tagsOptions,
    loadingTags,
    onCreateTag,

    levelOptions,
    loadingLevels,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CollectionInformationForm' });

  return (
    <Stack mt={2}>
      <FormRow label={t('name.label')}>
        <Input.Control
          fullWidth
          type="text"
          name="name"
          rules={{
            required,
            minLength: 6,
            validate: (v) => startsWithUppercase(v, t('name.label')),
          }}
          placeholder={t('name.placeholder')}
        />
      </FormRow>

      <FormRow label={t('photos.label')} optional alignItems="flex-start">
        <UploadImage.Control name="photos" />
      </FormRow>

      <FormRow label={t('level.label')}>
        <Select.Control
          fullWidth
          name="level"
          placeholder={t('level.placeholder')}
          options={levelOptions}
          disabled={loadingLevels}
          rules={{ required }}
        />
      </FormRow>

      <FormRow label={t('tags.label')} optional>
        <SelectMultiple.Control
          creatable
          fullWidth
          itemsMax={6}
          defaultValue={[]}
          options={tagsOptions}
          disabled={loadingTags}
          name="tags"
          placeholder={t('tags.placeholder')}
          onCreate={onCreateTag}
        />
      </FormRow>

      <FormRow optional label={t('description.label')} alignItems="flex-start">
        <TextEditor.Control
          optional
          fullWidth
          name="description"
          placeholder={t('description.placeholder')}
        />
      </FormRow>
    </Stack>
  );
};

export default CollectionInformationForm;
