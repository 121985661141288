import { forwardRef, useState } from 'react';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import Button from 'components/Button';
import DropdownBox from 'components/DropdownBox';
import DropdownPopover from 'components/DropdownPopover';
import DropdownCollapse from 'components/DropdownCollapse';

/**
 * NOTE! "box" property could be setup only once on component initialization
 */
const Dropdown = forwardRef((props, ref) => {
  const {
    box: initBox,
    collapse: initCollapse,
    offsetX,
    offsetY,
    paperProps,
    anchorOrigin,
    onAfterClose,
    transformOrigin,
    ...rest
  } = props;

  const [box] = useState(initBox);
  const [collapse] = useState(initCollapse);

  if (!!box !== !!initBox) {
    console.error(
      '[Dropdown]: "box" property could not be changed after component initialization!',
    );
  }
  if (!!collapse !== !!initCollapse) {
    console.error(
      '[Dropdown]: "collapse" property could not be changed after component initialization!',
    );
  }

  if (collapse) {
    return <DropdownCollapse {...rest} onAfterClose={onAfterClose} />;
  }

  if (box) {
    return <DropdownBox {...rest} paperProps={paperProps} onAfterClose={onAfterClose} />;
  }
  return (
    <DropdownPopover
      {...rest}
      offsetX={offsetX}
      offsetY={offsetY}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onAfterClose={onAfterClose}
    />
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box>
        <Box mb={2}>
          Simply a wrapper arround {`<DropdownBox />`} and {`<DropdownPopover />`} components. By
          default it is a {`<DropdownPopover />`} component. To change it, setup "box" property to
          "true". Note! You can setup "box" property only once, while component initialized.
        </Box>

        <Box component="pre">
          {`<Dropdown\n`}
          {`  box={true}\n`}
          {`  target={<Button>Trigger Box</Button>}\n`}
          {`>\n`}
          {`  <Box width={250}>\n`}
          {`    Box dropdown content\n`}
          {`  </Box>\n`}
          {`</Dropdown>\n`}
          {`\n`}
          {`<Dropdown\n`}
          {`  target={<Button>Trigger Popover</Button>}\n`}
          {`>\n`}
          {`  Popover dropdown content\n`}
          {`</Dropdown>\n`}
        </Box>

        <Box p={4} display="flex">
          <Box mr={1}>
            <Dropdown box={true} target={<Button>Trigger Box</Button>}>
              <Box width={250}>Box dropdown content</Box>
            </Dropdown>
          </Box>

          <Dropdown target={<Button>Trigger Popover</Button>}>Popover dropdown content</Dropdown>
        </Box>
      </Box>
    );
  };
  Dropdown.Demo = Demo;
}

export default Dropdown;
