import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetChildProjectsQuery, useGetStatusesQuery } from 'store/api';
import { getArray } from 'utils/helpers';

import { ProgressStatistics } from 'views';

import mapRequest from './mapRequest';

const PER_PAGE = 9;

const mockStatuses = {
  opened: 'Opened',
  closed: 'Closed',
};

const MyProjects = (props) => {
  const { me, pageFilters, loading, auth } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'learning' });

  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('Opened');

  const { data: statuses } = useGetStatusesQuery();

  const {
    data: childProjects,
    isLoading: childProjectsLoading,
    isFetching: childProjectsFetching,
  } = useGetChildProjectsQuery(
    mapRequest({
      me,
      filters: pageFilters,
      pagination: { perPage: PER_PAGE, page: currentPage },
      status: status,
      lazy: true,
    }),
    {
      skip: (auth && !me?.space_id) || loading,
    },
  );

  const statusOptions = useMemo(() => {
    if (!statuses) return [];

    return Object.values(mockStatuses).map((status) => ({ label: status, value: status }));
  }, [statuses]);

  const statistics = useMemo(() => {
    const { statistics } = childProjects || {};
    if (status === 'Opened') {
      return [
        {
          label: t('statistics.assigned'),
          name: 'assigned',
          count: statistics?.assigned || 0,
          color: 'secondary.light',
        },
        {
          label: t('statistics.in_progress'),
          name: 'progress',
          count: statistics?.progress || 0,
          color: 'warning.dark',
        },
        {
          label: t('statistics.completed'),
          name: 'completed',
          count: statistics?.completed || 0,
          color: 'success.dark',
        },
      ];
    }

    return [
      {
        label: t('statistics.abandoned'),
        name: 'abandoned',
        count: statistics?.abandoned || 0,
        color: 'secondary.light',
      },
      {
        label: t('statistics.published'),
        name: 'published',
        count: statistics?.published || 0,
        color: 'success.dark',
      },
    ];
  }, [childProjects, status, t]);

  const childProjectList = useMemo(() => {
    const { child_projects } = childProjects || {};
    return getArray(child_projects).map((project) => ({
      id: project.id,
      level: project.level,
      name: project.name,
      photo: project.avatar,
      to: `/learning/student-project/${project.id}`,
    }));
  }, [childProjects]);

  return (
    <ProgressStatistics
      title={t('my_projects')}
      loading={loading || childProjectsLoading || childProjectsFetching}
      statistics={statistics}
      data={childProjectList}
      total={childProjects?.child_projects_qty || 0}
      status={status}
      statuses={statusOptions}
      onStatusChange={setStatus}
      perPage={PER_PAGE}
      page={currentPage}
      onLoadMore={() => setCurrentPage(currentPage + 1)}
    />
  );
};

export default MyProjects;
