import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, styled } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';
import { keyCodes, propagateRefs } from 'utils/helpers';

import { Tag, DropdownMenu, Icon, InputContainer, FormLabel, FormHelperText } from 'components';
import { StyledInput } from 'components/Input';

const filter = createFilterOptions();

const Root = styled(Box, {
  label: 'SelectMultiple-Root',
})({
  display: 'inline-block',
});

const TargetRoot = styled(Box)({
  width: '100%',
});

const Placeholder = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(),
  color: theme.palette.input.placeholder,
  opacity: 0.5,
  whiteSpace: 'nowrap',
}));

const StyledInputContainer = styled(InputContainer)(({ theme }) => ({
  maxHeight: '100%',
  paddingLeft: theme.spacing(1),
  paddingTop: '7px',
  paddingBottom: '7px',
  '& > .MuiBox-root': {
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
}));

const Input = styled(StyledInput)(({ theme }) => ({
  '&.MuiInputBase-root.MuiOutlinedInput-root': {
    minHeight: 24,
    flexGrow: 1,
    boxShadow: 'none',
    border: 0,
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  '.MuiInputBase-input': {
    height: 24,
    boxSizing: 'border-box',
    padding: '4px 6px',
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },
}));

const SelectMultiple = forwardRef((props, ref) => {
  const { t } = useTranslation('components', { keyPrefix: 'SelectMultiple' });

  const {
    name,
    fullWidth,
    error,
    disabled,
    optional,
    label: formLabel,
    creatable,
    placeholder,
    defaultValue = [],
    options = [],
    inputValue = undefined,
    onInputChange = undefined,
    value = [],
    onValue,
    onCreate,
    onBlur,
    chips = true,
    disableCloseOnSelect = false,
    renderPreviewItem,
    itemsMax,
    max,
    ...rest
  } = props;

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      if (!creatable) return filtered;

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          label: inputValue,
        });
      }

      return filtered;
    },
    [creatable],
  );

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: `customized-multiple-select-${name}`,
    defaultValue: defaultValue,
    multiple: true,
    freeSolo: true,
    openOnFocus: true,
    options: options,
    inputValue,
    onInputChange,
    value,
    disableCloseOnSelect,
    onChange: (_event, changed) => {
      typeof onValue === 'function' && onValue(changed.filter((item) => !!item));
    },
    filterOptions,
  });

  const inputProps = useMemo(() => {
    return getInputProps();
  }, [getInputProps]);

  const handleCreateOption = useCallback(
    (option) => {
      if (!option.value && typeof onCreate === 'function') {
        return onCreate(option, (createdOption) => {
          typeof onValue === 'function' && onValue([...value, createdOption]);
        });
      }
    },
    [value, onValue, onCreate],
  );

  const getExtraTagProps = useCallback(
    (...args) => {
      const [extraTagProps] = args;
      const { option } = extraTagProps || {};

      const optionProps = getOptionProps(...args);

      const isSelected = value.some((selected) => option.value === selected.value);
      const selected = optionProps['aria-selected'] === true || isSelected;
      const disabled = optionProps['aria-disabled'] === true;

      const iconName = selected ? 'CheckboxChecked' : 'CheckboxEmpty';
      const iconColor = selected ? 'primary' : 'controls';

      const startAdornment = !option.value ? (
        t('create')
      ) : (
        <Icon color={iconColor} name={iconName} />
      );

      const onClick = (...clickArgs) => {
        if (!option.value) return handleCreateOption(option);
        return optionProps.onClick(...clickArgs);
      };

      return {
        ...optionProps,
        onClick,
        disabled,
        startAdornment,
        px: 1,
      };
    },
    [value, getOptionProps, handleCreateOption, t],
  );

  const dropdownLabel = useMemo(
    () => t(creatable ? 'select_or_create' : 'select_option'),
    [creatable, t],
  );

  const isOpen = useMemo(() => groupedOptions.length > 0, [groupedOptions]);
  const helperText = typeof error !== 'boolean' ? error : null;
  const isValueLimitReached = max === value?.length;

  return (
    <Root width={fullWidth ? '100%' : undefined}>
      <DropdownMenu
        {...getListboxProps()}
        box
        ref={ref}
        fullWidth={fullWidth}
        itemsSize={32}
        itemsMax={itemsMax}
        paperProps={fullWidth ? { width: '100%' } : null}
        open={isOpen}
        getExtraTagProps={getExtraTagProps}
        options={groupedOptions}
        label={dropdownLabel}
        target={
          <TargetRoot ref={setAnchorEl}>
            <div {...getRootProps()}>
              {formLabel && (
                <Box mb={0.5}>
                  <FormLabel error={!!error} disabled={disabled} optional={!!optional}>
                    {formLabel}
                  </FormLabel>
                </Box>
              )}

              <StyledInputContainer
                iconRight={<Icon flipY={isOpen} fontSize={15} name="ArrowDown" />}
                fullWidth
                onBlur={onBlur}
                className={focused ? 'focused' : ''}
                error={!!error}
                cursor="pointer"
              >
                {chips &&
                  value.map((option, index) => {
                    const { label, ...optionRest } = option;
                    const { onDelete, ...tagProps } = getTagProps({ index });
                    return (
                      <Tag
                        key={`${label}-index`}
                        label={label}
                        clickable
                        bgcolor="input.main"
                        onClick={onDelete}
                        {...optionRest}
                        {...tagProps}
                      />
                    );
                  })}
                {!chips && isValueLimitReached && <Placeholder>{placeholder}</Placeholder>}
                <Input
                  sx={{ display: isValueLimitReached ? 'none' : '' }}
                  placeholder={placeholder}
                  inputProps={inputProps}
                  onKeyDown={(e) => {
                    if (e.keyCode === keyCodes.enter && onCreate) {
                      e.stopPropagation();
                      handleCreateOption({ label: inputProps.value });
                    }
                  }}
                />
              </StyledInputContainer>
            </div>
          </TargetRoot>
        }
        {...rest}
      />

      <FormHelperText error disabled={disabled}>
        {helperText}
      </FormHelperText>

      {!chips && (
        <Stack mt={1} spacing={1}>
          {value.map((option, index) => {
            const { label, ...restOption } = option;
            const { onDelete, ...tagProps } = getTagProps({ index });
            return typeof renderPreviewItem === 'function' ? (
              renderPreviewItem({ option, index, tagProps, onDelete })
            ) : (
              <Tag
                key={`${option.label}-index`}
                label={option.label}
                clickable
                bgcolor="input.main"
                size="medium"
                onClick={onDelete}
                {...restOption}
                {...tagProps}
              />
            );
          })}
        </Stack>
      )}
    </Root>
  );
});

SelectMultiple.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onValue, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <SelectMultiple
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const topFilms = [
    { label: 'The Shawshank Redemption', value: 1994 },
    { label: 'The Godfather', value: 1972 },
    { label: 'The Godfather: Part II', value: 1974 },
    { label: 'The Dark Knight', value: 2008 },
    { label: '12 Angry Men', value: 1957 },
    { label: "Schindler's List", value: 1993 },
    { label: 'Pulp Fiction', value: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      value: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', value: 1966 },
    { label: 'Fight Club', value: 1999 },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      value: 2001,
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      value: 1980,
    },
    { label: 'Forrest Gump', value: 1994 },
    { label: 'Inception', value: 2010 },
    {
      label: 'The Lord of the Rings: The Two Towers',
      value: 2002,
    },
  ];

  const Demo = () => {
    return (
      <Box>
        <SelectMultiple
          label="Top films"
          options={topFilms}
          defaultValue={[topFilms[1]]}
          value={[topFilms[1]]}
          fullWidth
          creatable
          itemsMax={5}
        />
      </Box>
    );
  };

  SelectMultiple.Demo = Demo;
}

export default SelectMultiple;
