import { useState, useMemo } from 'react';

import { getArray } from 'utils/helpers';
import { useGetCountriesQuery } from 'store/api';

const SEARCH_DELAY = 800;
let timeout = null;

const useCountrySearch = (opts) => {
  const { disabled, mapToOptions = false } = opts || {};

  const [tempSearch, setTempSearch] = useState('');
  const [search, setSearch] = useState('');

  const { data, isLoading, isFetching } = useGetCountriesQuery(
    Object.assign({ limit: 300, name: search }),
    {
      skip: disabled,
    },
  );

  return useMemo(() => {
    const result = {
      data,
      loading: isLoading || isFetching,
      search: tempSearch,
      onSearch: (val) => {
        setTempSearch(val);
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          setSearch(val);
        }, SEARCH_DELAY);
      },
    };
    if (mapToOptions && data) {
      result.options = getArray(data?.countries).map((country) => ({
        label: country,
        value: country,
      }));
    }
    return result;
  }, [data, isLoading, isFetching, tempSearch, mapToOptions]);
};

export default useCountrySearch;
