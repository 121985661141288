import { forwardRef, useCallback } from 'react';
import { Button as MuiButton, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const buttonBaseSizes = {
  large: 48,
  medium: 40,
  small: 32,
  xsmall: 24,
};

const Btn = styled(MuiButton, {
  shouldForwardProp: (prop) => !['radius', 'size'].includes(prop),
})(({ theme, radius, size, color, width, variant }) => ({
  borderRadius: theme.shape.borderRadius * radius,
  boxShadow: theme.shadows[0],
  textTransform: 'initial',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderWidth: '1.5px',
  borderStyle: 'solid',
  borderColor: (variant === 'contained' && theme.palette[color]?.main) || 'currentColor',
  width,
  whiteSpace: 'nowrap',
  minWidth: buttonBaseSizes[size] || buttonBaseSizes.medium,
  height: buttonBaseSizes[size] || buttonBaseSizes.medium,
  maxHeight: buttonBaseSizes[size] || buttonBaseSizes.medium,
  '&:hover': {
    boxShadow: theme.shadows[0],
  },
  '&.Mui-disabled': {
    color: theme.palette.controls.hover,
    borderColor: theme.palette.input.disabled,
    backgroundColor: theme.palette.input.disabled,
  },

  // Outlined
  '&.MuiButton-outlined': {
    borderWidth: '1.5px',
    background: theme.palette.other.white,
    '&:hover': {
      color: theme.palette[color]?.dark,
      borderColor: 'currentColor',
    },
  },

  // Text
  '&.MuiButton-text': {
    background: 'transparent',
    borderColor: 'transparent',
    '&:hover': {
      background: 'transparent',
      color: theme.palette[color]?.dark,
    },
    '&.Mui-disabled': {
      background: 'transparent',
      color: theme.palette.controls.hover,
    },
  },
}));

/**
 * Button
 * @param {number} [radius=3] - Border radius
 * @param {string['xsmall'|'small'|'medium'|'large']} [size="medium"] - Button size
 * @param {string['outlined'|'contained'|'text']} [variant="contained"] - Button variant
 * @param {string} [to] - navigate with react router to given path
 */
const Button = forwardRef((props, ref) => {
  const {
    size = 'medium',
    radius = 3,
    children,
    variant = 'contained',
    onClick,
    to,
    ...rest
  } = props;

  const nav = useNavigate();

  const handleClick = useCallback((...args) => {
    if (to) {
      nav(to);
    }
    typeof onClick === 'function' && onClick(...args);
  }, [onClick, to, nav]);

  return (
    <Btn
      {...rest}
      ref={ref}
      size={size}
      radius={radius}
      variant={variant}
      onClick={handleClick}
    >
      {children}
    </Btn>
  );
});
export default Button;
