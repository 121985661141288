import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'components';
import { ROLE } from 'utils/constants';

const NavigationTabs = (props) => {
  const { role, id, value, profileBasePath } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'user_profile' });

  const navigate = useNavigate();
  const handleTabChange = (v) => navigate(`${profileBasePath}/${v}`);

  const options = useMemo(() => {
    const arr = [
      {
        label: t('tabs.profile'),
        value: id,
        icon: 'User',
      },
    ];

    if (role === ROLE.STUDENT) {
      arr.push({
        label: t('tabs.projects'),
        value: `${id}/projects`,
        icon: 'Project',
      });
    }

    if ([ROLE.TEACHER, ROLE.STUDENT].includes(role)) {
      arr.push({
        label: t('tabs.portfolio'),
        value: `${id}/portfolio`,
        icon: 'Collection',
      });
    }

    return arr;
  }, [role, id, t]);

  return (
    <Tabs
      width={options.length * 199}
      variant="fullWidth"
      value={value}
      onValue={handleTabChange}
      options={options}
    />
  );
};

export default NavigationTabs;
