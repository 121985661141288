import { forwardRef, useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';
import { propagateRefs, getArray } from 'utils/helpers';
import { makeSvgDashBorder } from 'styles/helpers';

import { Center, Dropzone, ImageHolder, IconButton } from 'components';

const imageTypesToAccept = ['image/jpeg', 'image/png'];

const ImagePreview = styled(ImageHolder, {
  label: 'UploadImage-Preview',
  shouldForwardProp: (prop) => !['disabled', 'error'].includes(prop),
})(({ theme, disabled, error }) => ({
  position: 'relative',
  padding: theme.spacing(0.25),
  transition: theme.transitions.create(),
  borderRadius: theme.shape.borderRadius * 2,
  opacity: disabled ? 0.5 : 1,
  backgroundImage: makeSvgDashBorder({
    dash: [3, 6],
    width: error ? 3.5 : 2.5,
    radius: theme.shape.borderRadius * 2,
    color: error ? theme.palette.error.dark : theme.palette.primary.main,
  }),
  '& > *': {
    pointerEvents: disabled ? 'none' : 'all',
  },
}));

const ImagePreviewWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .MuiButton-root': {
    position: 'absolute',
    right: 4,
    top: 4,
  },
}));

const PreviewWrapper = styled(Center)(({ theme }) => ({
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

const UploadImage = forwardRef((props, ref) => {
  const {
    multiple = true,
    width = 456,
    value,
    error,
    inputRef,
    onDelete,
    onChange,
    ...rest
  } = props;

  const handleOnChange = (...args) => {
    typeof onChange === 'function' && onChange(...args);
  };

  const previews = useMemo(() => {
    return getArray(value).map((preview) => {
      if (preview instanceof File) {
        return { url: URL.createObjectURL(preview) };
      }
      if (preview?.file instanceof File) {
        return { url: URL.createObjectURL(preview.file) };
      }
      return preview;
    });
  }, [value]);

  return (
    <Box {...rest}>
      {previews && (
        <PreviewWrapper width={width} justifyContent="flex-start" mb={2}>
          {previews.map((imagePreview, index) => (
            <ImagePreviewWrapper key={`image-preview-${index}`}>
              <ImagePreview src={imagePreview.url} size={104} alt="Preview" color="other.white" />
              <IconButton
                onClick={() => {
                  if (onDelete) onDelete(index, imagePreview);
                }}
                type="button"
                color="error"
                size="xsmall"
                name="Delete"
              />
            </ImagePreviewWrapper>
          ))}
        </PreviewWrapper>
      )}

      <Dropzone
        ref={ref}
        complete
        inputRef={inputRef}
        minWidth={width}
        preview={false}
        maxFiles={10}
        onChange={handleOnChange}
        multiple={multiple}
        accept={imageTypesToAccept}
        error={error}
      />
    </Box>
  );
});

UploadImage.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue = [], onBlur, onChange, inputRef, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const {
          ref: controlRef,
          onChange: controlOnChange,
          onBlur: controlOnBlur,
          value,
          ...restField
        } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleOnBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        const handleOnDelete = (index) => {
          const changed = [...value];
          changed.splice(index, 1);
          controlOnChange(changed);
        };

        return (
          <UploadImage
            ref={ref}
            error={error?.message || !!error}
            value={value}
            onDelete={handleOnDelete}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputRef={propagateRefs(inputRef, controlRef)}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box p={2}>
        <UploadImage />
      </Box>
    );
  };
  UploadImage.Demo = Demo;
}

export default UploadImage;
