import { getArray, editorStateToRawHtml } from 'utils/helpers';

const mapStudentWorks = (studentWork) => {
  return {
    name: studentWork.name,
    description: studentWork.description,
    link: studentWork?.link || getArray(studentWork.photo)[0],
  };
};

const getProjectAvatar = (project) => {
  return getArray(project.photos)[0] || '';
};

const mapRequest = ({ about, groups, me, userId, source = 'group_projects' }) => {
  const { space_id } = me;
  const { about_me, ...personal } = about;

  const projectIds = [];
  const groupIds = groups.map((group) => group.value);

  const projectCards = [];
  groups.forEach((group) => {
    group.selected.forEach((project) => {
      projectIds.push(project.id);

      projectCards.push({
        name: project.name,
        description: editorStateToRawHtml(project.description) || undefined,
        photos: getArray(project.student_works).map(mapStudentWorks),
        source: getArray(project.sources),
        project: project.id,
        avatar: getProjectAvatar(project),
      });
    });
  });

  return {
    user_id: userId,
    space_id,
    name: personal.name,
    first_name: personal.first_name,
    last_name: personal.last_name,
    about_me: editorStateToRawHtml(about_me) || undefined,
    public: personal.public,
    projects: projectIds,
    groups: source === 'group_projects' ? groupIds : [],
    project_cards: projectCards,
  };
};

export default mapRequest;
