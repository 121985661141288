import { useAuth } from 'hooks';
import { Navigate } from 'react-router-dom';

// TODO: Will be extended later
const ProtectedRoute = (props) => {
  const { children, auth: authCondition, authRedirect = '/' } = props;

  const { auth, progress } = useAuth();

  if (typeof authCondition === 'boolean') {
    if (progress) {
      // Wait for status update
      // TODO: Show loading
      return null;
    }
    if ((authCondition && !auth) || (!authCondition && auth)) {
      return (<Navigate to={authRedirect} replace />);
    }
  }
  return children;
};

export default ProtectedRoute;
