import { useMemo, useCallback } from 'react';
import { styled, Typography, Box, Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from 'utils/constants';
import { formatDate } from 'utils/helpers';
import { withProps, useAuth, useMessage } from 'hooks';
import { useGetChildCollectionQuery, useDeleteChildCollectionMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, IconButton, ImageHolder, Tag, LinearProgress } from 'components';
import { PageSubheader, RelatedProjects, Workflow } from 'views';
import { Description } from 'views/ArticleGeneralInformation';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Bar = styled(Box, { label: 'Collection-Bar' })(({ theme }) => ({
  backgroundColor: theme.palette.other.white,
  borderRadius: theme.shape.borderRadius * 3,
  gap: theme.spacing(2),
  display: 'inline-flex',
}));

const Collection = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'single_collection' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const {
    data: collection,
    isLoading,
    isFetching,
  } = useGetChildCollectionQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const [deleteCollection, deleteCollectionState] = useDeleteChildCollectionMutation();

  const handleDeleteCollection = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteCollection({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/learning/collections');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteCollection, navigate, id, t, m]);
  const loading = isLoading || isFetching || deleteCollectionState.isLoading;

  const pageActions = {
    [t('page_actions.delete')]: {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeleteCollection,
      aclConfig: { permissions: [PERMISSIONS.DELETE_COLLECTION] },
    },
  };

  const progress = useMemo(() => {
    if (!collection) return 0;
    const { projects_qty, projects_published } = collection;

    return (projects_published / projects_qty) * 100;
  }, [collection]);

  return (
    <ProjectLayout
      subheader={
        collection && (
          <PageSubheader
            title={collection.name}
            backUrl="/learning/collections"
            backTitle={t('back_button')}
            pageActions={pageActions}
          />
        )
      }
    >
      <Article py={4} pb={16}>
        <Container>
          <Box>
            <Typography component="div" variant="subtitle5" mb={2} color="textBlack.dark">
              {t('general_information')}
            </Typography>

            <Center justifyContent="flex-start" alignItems="flex-start" pl={2}>
              <ImageHolder src={collection?.avatar} width={226} height={180} />

              <Box>
                <Bar ml={2} mb={2} px={2} py={1}>
                  <Stack width="100%" direction="row" alignItems="center" spacing={2}>
                    <Center>
                      <Typography variant="body4" color="secondary.contrastText">
                        {t('owner')}
                      </Typography>
                      <Tag
                        ml={0.5}
                        p={0.5}
                        border
                        avatar={collection?.owner?.avatar}
                        avatarSize={24}
                        label={collection?.owner?.name || ''}
                        color="textRegular.light"
                        size="medium"
                        sx={{
                          border: '1px solid',
                          borderColor: 'controls.main',
                          backgroundColor: 'input.main',
                        }}
                      />
                    </Center>
                    <Center>
                      <Typography variant="body4" color="secondary.contrastText">
                        {t('progress')}
                      </Typography>
                      <Box width={96} ml={0.5}>
                        <LinearProgress variant="determinate" value={progress} />
                      </Box>
                      {collection?.projects_qty > 0 && (
                        <Box sx={{ minWidth: 20 }} ml={0.5}>
                          <Typography variant="caption1" color="secondary.contrastText">
                            {collection?.projects_published}/{collection?.projects_qty}
                          </Typography>
                        </Box>
                      )}
                    </Center>
                    <Center>
                      <Typography variant="body4" color="secondary.contrastText">
                        {t('date_created')}
                      </Typography>
                      <Tag
                        ml={0.5}
                        p={0.5}
                        border
                        label={formatDate(collection?.date_created)}
                        size="medium"
                        sx={{
                          border: '1px solid',
                          borderColor: 'controls.main',
                          backgroundColor: 'input.main',
                        }}
                      />
                    </Center>
                  </Stack>
                </Bar>
                <Description title={t('description')} description={collection?.description} />
              </Box>
            </Center>
          </Box>

          <RelatedProjects
            mt={4}
            mb={4}
            title={t('related_projects')}
            projects={collection?.projects}
            gridProps={{ pl: 2 }}
            child
          />

          {collection?.workflow?.length > 0 ? (
            <Workflow data={collection?.workflow} mb={4} />
          ) : undefined}
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default Collection;
