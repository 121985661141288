import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack as MuiStack } from '@mui/material';

import { useForm, withProps } from 'hooks';
import { Button, Form, Input, Dropzone } from 'components';
import { LanguageSelect } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const profilePictureAccept = ['image/jpeg', 'image/png'];

const keyPrefix = 'signup.step2';

const Step2 = (props) => {
  const { onSubmit, onCancel, type, visible } = props;
  const { t } = useTranslation('pages', { keyPrefix });
  const {
    form,
    valid,
    rules: { required, requiredArray },
  } = useForm();
  const { reset, getValues } = form;

  useEffect(() => {
    // The way to reset the state (espacially isValid value) after type change
    reset({ ...getValues() });
  }, [type, reset, getValues]);

  const nameRules = useMemo(
    () => ({
      required: type === 'personal' ? required : false,
      deps: ['organization'],
    }),
    [type, required],
  );

  const organizationRules = useMemo(
    () => ({
      required: type === 'organization' ? required : false,
      deps: ['first_name', 'last_name'],
    }),
    [type, required],
  );

  return (
    <Form my={1} mt={2} width={456} form={form} onSubmit={onSubmit}>
      <Stack mb={1}>
        <Dropzone.Control
          fullWidth
          name="avatar"
          label={t('avatar.label')}
          accept={profilePictureAccept}
          rules={{ validate: requiredArray }}
        />

        {type === 'personal' && (
          <>
            <Input.Control
              fullWidth
              name="first_name"
              initFocus={type === 'personal' && visible}
              label={t('first_name.label')}
              placeholder={t('first_name.placeholder')}
              rules={nameRules}
            />

            <Input.Control
              fullWidth
              name="last_name"
              label={t('last_name.label')}
              placeholder={t('last_name.placeholder')}
              rules={nameRules}
            />
          </>
        )}

        {type === 'organization' && (
          <Input.Control
            fullWidth
            name="organization"
            initFocus={type === 'organization' && visible}
            label={t('organization.label')}
            placeholder={t('organization.placeholder')}
            rules={organizationRules}
          />
        )}

        <LanguageSelect.Control fullWidth name="language" label={t('language.label')} />

        <Stack width="100%" direction="row" pt={2}>
          <Button fullWidth variant="outlined" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button fullWidth type="submit" disabled={!valid}>
            {t('submit')}
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};

export default Step2;
