import { Box, Typography, Grid } from '@mui/material';

import { getArray } from 'utils/helpers';

import { ProjectCard } from 'views';

const RelatedProjects = (props) => {
  const { projects, title, gridProps = {}, child = false, ...rest } = props;

  return (
    <Box {...rest}>
      {title && (
        <Typography variant="subtitle5" component="h3" mb={2}>
          {title}
        </Typography>
      )}

      <Grid container spacing={3} {...gridProps}>
        {getArray(projects).map((project) => (
          <Grid key={`projects-grid-${project.id}`} item xs={4}>
            <ProjectCard
              id={project.id}
              title={project.name}
              tags={project.tags}
              avatar={project.avatar || getArray(project?.photos)[0]}
              level={project.level}
              assignedStudentsCount={project?.assigned_students?.length}
              to={`/learning/${child ? 'student-project' : 'projects'}/${project.id}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RelatedProjects;
