import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useGetMeQuery } from 'store/api';
import { useAuth } from 'hooks';

import { Container, Center, Tabs } from 'components';

import GroupProjectStatistics from './GroupProjectStatistics';
import StudentProjectStatistics from './StudentProjectStatistics';

const Dashboard = () => {
  const [tab, setTab] = useState('students_statistic');

  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  const { auth } = useAuth();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const tabOptions = [
    {
      value: 'students_statistic',
      label: t('students_statistic'),
      icon: 'Analytic2',
      width: 192,
    },
    {
      value: 'my_statistic',
      label: t('my_statistic'),
      icon: 'Analytic',
      width: 192,
    },
  ];

  return (
    <Box pt={3}>
      <Container>
        <Center pb={3} justifyContent="space-between">
          <Tabs
            width={396}
            variant="fullWidth"
            sx={{ backgroundColor: 'other.white' }}
            value={tab}
            options={tabOptions}
            onValue={setTab}
          />
        </Center>
        {tab === 'students_statistic' && (
          <>
            <Box mb={4}>
              <GroupProjectStatistics />
            </Box>
            <Box mb={4}>
              <StudentProjectStatistics />
            </Box>
          </>
        )}
        {tab === 'my_statistic' && (
          <Box mb={4}>
            <StudentProjectStatistics userId={me?.id} />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;
