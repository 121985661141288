import { forwardRef, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box } from '@mui/material';

import { dev } from 'utils/config';

/**
 * Forms wrapper
 * @prop {object} form - react-hook-form useForm hook instance. Required.
 */
const Form = forwardRef((props, ref) => {
  const { children, form, onSubmit, stopPropagation = false, debug, ...rest } = props;
  const { handleSubmit, getValues } = form;

  useEffect(() => {
    if (debug) {
      window.debugForm = getValues;
    }
  }, [debug, getValues]);

  return (
    <Box
      ref={ref}
      component="form"
      onSubmit={(e) => {
        if (stopPropagation) e.stopPropagation();
        return handleSubmit(onSubmit)(e);
      }}
      {...rest}
    >
      <FormProvider {...form}>
        {children}
      </FormProvider>
    </Box>
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box pb={2}>
        <Box mb={2}>Use this component as a root element with <b>react-hook-form</b>. It will create context for controlled form components (described in the project readme) to avoid some boilerplate.</Box>

        <Box component="pre">
          {`const form = useForm();\n`}
          {`\n`}
          {`return (\n`}
          {`  <Form form={form} onSubmit={console.log}>\n`}
          {`    ...\n`}
          {`  </Form>\n`}
          {`);\n`}
        </Box>
      </Box>
    );
  };
  Form.Demo = Demo;
}

export default Form;
