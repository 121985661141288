import { useCallback, useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';

import { dev } from 'utils/config';
import { useImagePreload, withProps } from 'hooks';
import Fieldset from 'components/Dev/Fieldset';

import projectPreview from 'assets/images/examples/project_preview.png';
import placeholder from 'assets/images/holders/placeholder.png';

const ImgBox = withProps(Box, { component: 'img' });

const Img = styled(ImgBox, {
  shouldForwardProp: (prop) => !['transition', 'loading', 'error', 'fit'].includes(prop),
})(({ theme, loading, error, src, transition, transform, opacity, fit }) => {
  const result = {
    transform,
    opacity: !error && (loading || !src) ? 0 : (opacity || 1),
    transition: !transition
      ? 'none'
      : theme.transitions.create(['all'], {
        duration: Number.isFinite(transition)
          ? transition
          : theme.transitions.duration.shortest,
      }),
  };
  if (fit) {
    result.objectFit = typeof fit === 'string' ? fit : 'cover';
  }
  return result;
});

/**
 * The same as native <img> tag, but allows to avoid partical image rendering effect. Take a look
 * at useImagePreload hook description.
 * Also it allow to show image right after loading with a smooth opacity transition.
 * You can disable transition passing [transition=false], or change transition duration passing
 * [transition=<NUMBER_OF_MILISECONDS>]
 *
 * TODO: Think about loading process visualization
 * TODO: Fallback image
 */
const Image = (props) => {
  const {
    fit,
    src: link,
    transition = true,
    alt = 'No description',
    ...rest
  } = props;

  const { src, error, loading } = useImagePreload({
    src: link,
  });

  return (
    <Img
      src={(error || !src) ? placeholder : src}
      fit={fit}
      alt={error || alt}
      loading={loading}
      error={error}
      transition={transition}
      {...rest}
    />
  );
};

if (dev) {
  const transitionOptions = [undefined, 0, 100, 500, 2000];
  const fitOptions = [undefined, 'cover', 'contain', 'fill', 'none'];
  const sizeOptions = [undefined, 'auto', 100, 200, 300, 500];

  const Demo = () => {
    const [componentToUse, setComponentToUse] = useState(null);
    const [stamp, setStamp] = useState(null);
    const [exampleStamp, setExampleStamp] = useState(new Date().getTime());

    const [transition, setTransition] = useState(undefined);
    const [fit, setFit] = useState(undefined);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState('auto');

    useEffect(() => {
      if (!exampleStamp) {
        setExampleStamp(new Date().getTime());
      }
    }, [exampleStamp]);

    const handleRefreshExampleStamp = useCallback((setter) => (v) => {
      setExampleStamp(null);
      setter(v);
    }, []);

    return (
      <Box>
        <Box mb={2}>Show image with smooth transition only after full loading.</Box>
        <Box mb={2}>Open browser dev tools, go to Network tab, setup throttling option to "Fast 3G", and press the button below to refresh images using native {`<img />`} tag, or the {`<Image />`} component to feel the difference.</Box>
        <Box mb={2}>This could be usefull for gallery or heavy images loading and displaying.</Box>

        <Box display="flex" gap={2} mb={3}>
          <button
            onClick={() => {
              setStamp(new Date().getTime());
              setComponentToUse('img');
            }}
          >
            {`<img />`}
          </button>
          <button
            onClick={() => {
              setStamp(new Date().getTime());
              setComponentToUse('image');
            }}
          >
            {`<Image />`}
          </button>
        </Box>

        {stamp && (
          <Box display="flex" gap={4} flexWrap="wrap" minHeight="100px">
            {new Array(2).fill(null).map((n, i) => {
              const link = `${projectPreview}?t=${stamp + i}`;

              if (componentToUse === 'img') {
                return (
                  <img key={link} src={link} width="300" alt="Project Preview" />
                );
              }
              return (
                <Image
                  key={link}
                  src={link}
                  width={300}
                  transition={300}
                  alt="Project Preview"
                />
              );
            })}
          </Box>
        )}

        <Box my={3}>
          <Box display="flex" flexWrap="wrap" mb={4}>
            <Fieldset>
              <Fieldset.Field
                legend="transition"
                value={transition}
                onChange={handleRefreshExampleStamp(setTransition)}
                options={transitionOptions}
              />

              <Fieldset.Field
                legend="fit"
                value={fit}
                onChange={handleRefreshExampleStamp(setFit)}
                options={fitOptions}
              />

              <Fieldset.Field
                legend="width"
                value={width}
                onChange={handleRefreshExampleStamp(setWidth)}
                options={sizeOptions}
              />

              <Fieldset.Field
                legend="height"
                value={height}
                onChange={handleRefreshExampleStamp(setHeight)}
                options={sizeOptions}
              />
            </Fieldset>
          </Box>

          <Box component="pre">
            {`<Image\n`}
            {`  fit="${fit}"\n`}
            {`  width={${width}}\n`}
            {`  height={${height}}\n`}
            {`  src="${projectPreview}"\n`}
            {`  transition={${transition}}\n`}
            {`/>\n`}
          </Box>

          <Box height={height}>
            {exampleStamp && (
              <Image
                fit={fit}
                width={width}
                height={height}
                transition={transition}
                src={`${projectPreview}?t=${exampleStamp}`}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  Image.Demo = Demo;
}

export default Image;
