import { useMemo, useCallback } from 'react';
import { styled, Typography } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { formatDate, getArray } from 'utils/helpers';
import { withProps, useAuth, useMessage } from 'hooks';
import { useGetMeQuery, useGetTipQuery, useDeleteTipMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, Gallery, Icon, IconButton } from 'components';
import { ArticleGeneralInformation, PageSubheader } from 'views';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Tip = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_tip' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: tip,
    isLoading,
    isFetching,
  } = useGetTipQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const [deleteTip, deleteTipState] = useDeleteTipMutation();

  const loading = isLoading || isFetching || deleteTipState.isLoading;

  const handleDeleteTip = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteTip({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/knowledge-base');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteTip, navigate, id, t, m]);

  const tags = useMemo(() => {
    return getArray(tip?.tags).map((tag) => ({ label: tag.name, id: tag.id }));
  }, [tip]);

  const photos = useMemo(() => {
    return getArray(tip?.photos).map((photo) => ({ src: photo }));
  }, [tip]);

  const withPhotos = photos.length > 0;
  const isOwner = me && tip && me?.space_id === tip?.space_id;

  return (
    <ProjectLayout
      subheader={
        tip && (
          <PageSubheader
            title={tip.name}
            backUrl="/knowledge-base/tips"
            backTitle={t('back_button')}
            pageActions={
              isOwner && {
                [t('page_actions.edit')]: {
                  type: 'button',
                  disabled: loading,
                  variant: 'outlined',
                  size: 'small',
                  name: 'Edit',
                  border: true,
                  Component: IconButton,
                  to: `${pathname}/edit`,
                },
                [t('page_actions.delete')]: {
                  type: 'button',
                  size: 'small',
                  disabled: loading,
                  color: 'error',
                  name: 'Delete',
                  Component: IconButton,
                  onClick: handleDeleteTip,
                },
              }
            }
          />
        )
      }
    >
      <Article py={4}>
        <Container>
          {!loading && (
            <ArticleGeneralInformation
              slug="Tip"
              author={tip.author}
              description={tip.description}
              tags={tags}
              sources={tip.sources}
              dateCreated={formatDate(tip.date_created)}
            />
          )}

          {withPhotos && (
            <>
              <Center my={2} justifyContent="flex-start">
                <Center width={24} height={24}>
                  <Icon name="Image" />
                </Center>
                <Typography ml={0.5} variant="body2" color="textBlack.dark">
                  {t('gallery')}
                </Typography>
              </Center>
              <Gallery images={photos} />
            </>
          )}
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default Tip;
