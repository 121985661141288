import { Box, styled } from '@mui/material';

import ProjectLayout from 'layouts/ProjectLayout';
import background from 'assets/images/background.svg';

const Root = styled(Box, {
  label: 'GuestLayout-Children',
})(({ theme }) => ({
  minHeight: '100%',
  overflow: 'hidden',
  backgroundSize: 'auto 100vh',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'bottom right',
  backgroundImage: `url(${background})`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(9),
}));

const GuestLayout = (props) => {
  const { children, ...rest } = props;

  return (
    <ProjectLayout {...rest}>
      <Root pt={9}>{children}</Root>
    </ProjectLayout>
  );
};

export default GuestLayout;
