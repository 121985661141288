import { styled } from '@mui/material';
import { useSwiper } from 'swiper/react';

import { Center, IconButton } from 'components';

const NavigationControl = styled(IconButton, {
  shouldForwardProp: (prop) => !['direction'].includes(prop),
})(({ theme, direction }) => ({
  width: '32px',
  backgroundColor: theme.palette.textRegular.contrastText,
  color: theme.palette.primary.main,
  borderRadius:
    direction === 'prev'
      ? `${theme.shape.borderRadius * 2}px 0 0 ${theme.shape.borderRadius * 2}px`
      : `0 ${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0`,
  '& .MuiSvgIcon-root': {
    fontSize: '15px',
  },
}));

const SwiperNavigation = (props) => {
  const swiper = useSwiper();

  return (
    <Center sx={{ gap: '8px' }} mt={1} justifyContent="flex-end" pr={1} {...props}>
      <NavigationControl
        disabled={!swiper.allowClick || !swiper.allowSlidePrev}
        name="ArrowLeft"
        onClick={() => swiper.slidePrev()}
        color="controls"
        direction="prev"
      />
      <NavigationControl
        disabled={!swiper.allowClick || !swiper.allowSlideNext}
        name="ArrowRight"
        onClick={() => swiper.slideNext()}
        color="controls"
        direction="next"
      />
    </Center>
  );
};

export default SwiperNavigation;
