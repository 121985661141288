import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const keyPrefix = 'FormLabel';

const FormLabel = forwardRef((props, ref) => {
  const { disabled, error, children, optional, variant, ...rest } = props;
  const { t } = useTranslation('components', { keyPrefix });

  return (
    <Typography
      mb={0.5}
      ref={ref}
      variant={variant || 'caption1'}
      sx={{ opacity: disabled ? 0.5 : 1 }}
      color={error ? 'error.main' : 'textBlack.light'}
      {...rest}
    >
      {children}

      {children && optional && (
        <Typography
          ml={0.5}
          variant={variant || 'caption1'}
          color={error ? 'error.light' : 'secondary.contrastText'}
        >
          ({t('optional')})
        </Typography>
      )}
    </Typography>
  );
});

export default FormLabel;
