import { useMemo } from 'react';
import { Typography, CardActionArea, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { capitalize } from 'utils/helpers';
import { Tags, Paper, ImageHolder, Icon } from 'components';
import { LevelTag } from 'views';

const ImageRoot = styled(Box)({
  position: 'relative',
});

const Info = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
}));

const Level = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}));

const ProjectCard = (props) => {
  const { t } = useTranslation('views', { keyPrefix: 'ProjectCard' });

  const { id, title, level, photos, avatar, tags, bookmarked, assignedStudentsCount, to, ...rest } =
    props;

  const photo = useMemo(() => {
    if (avatar) return avatar;
    if (!photos?.length) return null;
    return photos[0];
  }, [avatar, photos]);

  return (
    <Paper
      radius={3}
      shadow="standard"
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
      }}
      {...rest}
    >
      <CardActionArea
        component={Link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: 0,
          '& .MuiCardActionArea-focusHighlight': { background: 'transparent' },
        }}
        to={to}
      >
        <Box width="100%">
          <ImageRoot>
            <ImageHolder
              m={0}
              origin="container"
              flexShrink={0}
              width="100%"
              height={135}
              src={photo}
              radius={3}
              sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            />
            <Level>
              <LevelTag level={level} label={capitalize(level)} size="small" mb={1} />
            </Level>
          </ImageRoot>
          <Info flexGrow={1}>
            <Typography component="div" variant="subtitle4" mb={2}>
              {title}
            </Typography>

            {assignedStudentsCount > 0 && (
              <Tags
                titleProps={{ minWidth: 80 }}
                mb={2}
                max={1}
                title={t('assigned')}
                justifyContent="space-between"
                tags={[
                  {
                    pl: 1.5,
                    pr: 0.5,
                    startAdornment: <Icon name="Student2" />,
                    name: t('assigned_students', { count: assignedStudentsCount }),
                    id: assignedStudentsCount,
                  },
                ]}
                name={title}
              />
            )}
            <Tags
              justifyContent="space-between"
              max={2}
              titleProps={{ minWidth: 80 }}
              title={t('tags')}
              tags={tags}
              name={title}
            />
          </Info>
        </Box>
      </CardActionArea>
    </Paper>
  );
};

export default ProjectCard;
