import { useState, useCallback } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dev } from 'utils/config';
import { getArray } from 'utils/helpers';
import { Avatar, Center, Button, Paper, Modal } from 'components';

import CommentCompose from './CommentCompose';

const QuickAction = styled(Button)({
  paddingLeft: 0,
  paddingRight: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
});

const MAX_INDENTATION_LEVEL = 1;

const Comment = (props) => {
  const {
    comment,
    me,
    level,
    commentToReply,
    parentComment,
    disabled,
    onInteraction,
    onReply,
    onSendComment,
  } = props;

  const { t } = useTranslation('components', { keyPrefix: 'Comment' });

  const [showReplies, setShowReplies] = useState(false);

  const handleReply = useCallback(
    (e) => {
      const interactionEnabled = !onInteraction || onInteraction(e);
      if (!interactionEnabled) return;
      onReply && onReply(level >= MAX_INDENTATION_LEVEL ? parentComment : comment);
    },
    [comment, onReply, parentComment, level, onInteraction],
  );

  const handleCancel = useCallback(() => {
    onReply && onReply(null);
  }, [onReply]);

  const toggleReplies = useCallback(() => {
    setShowReplies((show) => !show);
  }, [setShowReplies]);

  const repliesCount = comment?.replies?.length || 0;

  return (
    <>
      <Box display="flex" width="100%">
        <Box mr={2} flexShrink={0}>
          <Avatar badge src={comment.avatar} />
        </Box>
        <Box>
          <Center mb={1} justifyContent="flex-start">
            <Typography variant="body2" mr={1} color="text.primary">
              {comment.author_name}
            </Typography>
            <Typography variant="body4" color="secondary.main">
              {t('time_spent', { date_string: comment.time_spent })}
            </Typography>
          </Center>

          <Box mb={1.5}>
            <Typography variant="paragraph">{comment.name}</Typography>
          </Box>

          <Center mt={2} sx={{ gap: '12px' }} justifyContent="flex-start">
            <QuickAction
              radius={2}
              size="small"
              variant="text"
              iconLeft="Reply"
              disabled={disabled}
              onClick={handleReply}
            >
              <Typography variant="button3">{t('reply')}</Typography>
            </QuickAction>

            {repliesCount > 0 && (
              <QuickAction
                onClick={toggleReplies}
                radius={2}
                size="small"
                variant="text"
                iconLeft={showReplies ? 'ArrowUp' : 'ArrowDown'}
              >
                <Typography variant="button3">
                  {t(showReplies ? 'hide_replies' : 'show_replies', { count: repliesCount })}
                </Typography>
              </QuickAction>
            )}
          </Center>
        </Box>
      </Box>

      {repliesCount > 0 && showReplies && (
        <Stack pl={7} spacing={3} width="100%">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              me={me}
              comment={reply}
              parentComment={comment}
              commentToReply={commentToReply}
              onReply={onReply}
              onSendComment={onSendComment}
              level={level + 1}
            />
          ))}
        </Stack>
      )}

      {commentToReply?.id === comment.id && (
        <Box pl={7}>
          <CommentCompose
            me={me}
            commentToReply={commentToReply}
            onSendComment={onSendComment}
            onInteraction={onInteraction}
            onCancel={handleCancel}
          />
        </Box>
      )}
    </>
  );
};

const Comments = (props) => {
  const { disabled, loading, comments, me, onSendComment } = props;

  const { t } = useTranslation('components', { keyPrefix: 'Comment' });

  const [commentToReply, setCommentToReply] = useState(null);
  const [signupInformationOpen, setSignupInformationOpen] = useState(false);

  const closeSignupInformationModal = useCallback(() => {
    setSignupInformationOpen(false);
  }, [setSignupInformationOpen]);

  const handleInteraction = useCallback(
    (e) => {
      if (!me) {
        setSignupInformationOpen(true);
        return false;
      }

      return true;
    },
    [setSignupInformationOpen, me],
  );

  return (
    <>
      <Paper loading={loading} p={3} shadow="standard" width="100%">
        <CommentCompose
          onInteraction={handleInteraction}
          me={me}
          disabled={disabled}
          onSendComment={onSendComment}
        />

        <Stack pl={7} pr={1} width="100%" spacing={3} sx={{ maxHeight: '632px', overflow: 'auto' }}>
          {getArray(comments).map((comment) => (
            <Comment
              me={me}
              level={0}
              key={comment.id}
              commentToReply={commentToReply}
              comment={comment}
              disabled={disabled}
              onReply={setCommentToReply}
              onSendComment={onSendComment}
            />
          ))}
        </Stack>
      </Paper>
      <Modal open={signupInformationOpen} onClose={closeSignupInformationModal}>
        <Center flexDirection="column">
          <Typography display="inline-block" mb={2} variant="body4">
            {t('signup_information')}
          </Typography>
          <Button to="/sign-up">{t('signup')}</Button>
        </Center>
      </Modal>
    </>
  );
};

if (dev) {
  const comments = [
    {
      id: '3fa85f64-5217-4562-b3fc-2c963f66afa6',
      name: 'string',
      author_name: 'string',
      avatar: 'string',
      replies: [
        {
          id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          name: 'string',
          author_name: 'string',
          avatar: 'string',
          time_spent: 'string',
        },
      ],
      time_spent: 'string',
    },
  ];

  const Demo = () => {
    return (
      <Box p={2} flexGrow={1}>
        <Comments comments={comments} />
      </Box>
    );
  };

  Comments.Demo = Demo;
}

export default Comments;
