import { useMemo } from 'react';

import { useFetchParentMutation } from 'store/api';

const useGetParentProfile = () => {
  const [fetchParent, fetchParentState] = useFetchParentMutation();

  return useMemo(() => {
    const fetch = async (id) => {
      try {
        const parent = await fetchParent({ id }).unwrap();
        return parent;
      } catch (err) {
        return null;
      }
    };

    return [fetch, fetchParentState];
  }, [fetchParent, fetchParentState]);
};

export default useGetParentProfile;
