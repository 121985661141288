import { useMemo, useCallback, useState } from 'react';
import { styled, Typography, Box } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROLE, PERMISSIONS } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';
import { withProps, useAuth, useMessage } from 'hooks';
import {
  useGetMeQuery,
  useGetCollectionQuery,
  useDeleteCollectionMutation,
  useAssignCollectionMutation,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, Gallery, IconButton, ImageHolder } from 'components';
import { PageSubheader, RelatedProjects, AssignCollectionModal } from 'views';
import { Description, TagsBar } from 'views/ArticleGeneralInformation';
import { getLevelStyles } from 'views/LevelTag';

import AssignedStudents from './AssignedStudents';
import RequestCollection from './RequestCollection';
import RequestSuccessModal from './RequestSuccessModal';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Collection = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_collection' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: collection,
    isLoading,
    isFetching,
  } = useGetCollectionQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [requestSuccessModalOpen, setRequestSuccessModalOpen] = useState(false);

  const [deleteCollection, deleteCollectionState] = useDeleteCollectionMutation();
  const [assignCollection, assignCollectionState] = useAssignCollectionMutation();

  const handleDeleteCollection = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteCollection({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/learning/collections');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteCollection, navigate, id, t, m]);

  const photos = useMemo(() => {
    if (!collection?.photos) return [];
    return getArray(collection.photos)
      .slice(1, collection.photos.length)
      .map((photo) => ({ src: photo }));
  }, [collection]);

  const avatar = useMemo(() => {
    if (!collection?.photos) return null;
    return collection.photos[0];
  }, [collection]);

  const tagGroups = useMemo(() => {
    if (!collection) return [];

    const { level, tags } = collection;

    const result = [];
    if (!!level) {
      result.push({
        getExtraTagProps: () => ({
          sx: getLevelStyles({ level, border: true }),
        }),
        title: t('level'),
        tags: [{ id: level, name: capitalize(level) }],
      });
    }

    if (tags?.length > 0) {
      result.push({ title: t('related_tags'), tags: tags });
    }

    return result;
  }, [collection, t]);

  const withPhotos = photos.length > 0;
  const loading = isLoading || isFetching || deleteCollectionState.isLoading;

  const closeAssignModal = useCallback(() => {
    setAssignModalOpen(false);
  }, [setAssignModalOpen]);

  const handleAssignCollection = useCallback(
    async ({ student, group, projects, self }) => {
      try {
        const { status } = await assignCollection({
          id,
          student_id: student,
          group_id: group || undefined,
          projects:
            group && !self
              ? Object.entries(projects).map(([projectId, student]) => ({
                  student_id: student?.student,
                  project_id: projectId,
                }))
              : [],
        }).unwrap();

        if (status === 'OK') {
          m.success(t('collection_assigned'));
          closeAssignModal();
        }
      } catch (err) {
        console.error(err);
        m.error(t('error.failed_assign_collection'));
      }
    },
    [id, m, t, assignCollection, closeAssignModal],
  );

  const isCollectionAssigned = useMemo(() => {
    if (!collection || !me) return false;
    const { students } = collection;
    return (
      students?.length > 0 &&
      students.findIndex((student) => student?.space_id === me.space_id) !== -1
    );
  }, [collection, me]);

  const isCollectionRequested = useMemo(() => {
    if (!collection?.requested_users?.length || !me) return false;
    const { requested_users } = collection;

    const found = requested_users.find((user) => !!user[me?.id]);
    if (!found) return;

    const now = new Date();
    const requestDate = new Date(found[me?.id]);

    const diffTime = Math.abs(now - requestDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 1;
  }, [collection, me]);

  const pageActions = {
    [t('page_actions.edit')]: {
      type: 'button',
      disabled: loading,
      variant: 'outlined',
      size: 'small',
      name: 'Edit',
      border: true,
      Component: IconButton,
      to: `${pathname}/edit`,
      aclConfig: {
        roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
        permissions: [PERMISSIONS.EDIT_COLLECTION],
      },
    },
    [t('page_actions.delete')]: {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeleteCollection,
      aclConfig: {
        roles: [ROLE.ADMIN, ROLE.TEACHER],
        permissions: [PERMISSIONS.DELETE_COLLECTION],
      },
    },
    [t(
      isCollectionAssigned ? 'page_actions.collection_assigned' : 'page_actions.work_on_collection',
    )]: {
      type: 'button',
      size: 'small',
      disabled: loading || isCollectionAssigned || assignCollectionState.isLoading,
      variant: 'outlined',
      onClick: () => handleAssignCollection({ student: me?.id, self: true }),
      radius: 2,
      sx: { paddingLeft: 0, paddingRight: 0 },
      aclConfig: { roles: [ROLE.ADMIN, ROLE.TEACHER], permissions: [PERMISSIONS.ASSIGN_USER] },
    },
    [t('page_actions.assign_collection')]: {
      type: 'button',
      size: 'small',
      disabled: loading || assignCollectionState.isLoading,
      radius: 2,
      sx: { paddingLeft: 0, paddingRight: 0 },
      onClick: () => setAssignModalOpen(true),
      aclConfig: { roles: [ROLE.ADMIN, ROLE.TEACHER], permissions: [PERMISSIONS.ASSIGN_USER] },
    },
    [isCollectionRequested
      ? t('page_actions.collection_requested')
      : t('page_actions.request_collection')]: {
      type: 'button',
      size: 'small',
      disabled: loading || isCollectionRequested,
      radius: 2,
      sx: { paddingLeft: 0, paddingRight: 0 },
      collectionId: id,
      Component: RequestCollection,
      onSuccess: () => setRequestSuccessModalOpen(true),
      aclConfig: { roles: [ROLE.STUDENT], permissions: [PERMISSIONS.REQUEST_COLLECTION] },
    },
  };

  return (
    <ProjectLayout
      subheader={
        collection && (
          <PageSubheader
            title={collection.name}
            backUrl="/learning/collections"
            backTitle={t('back_button')}
            pageActions={pageActions}
          />
        )
      }
    >
      <Article py={4} pb={16}>
        <Container>
          <Box>
            <Typography component="div" variant="subtitle5" mb={2} color="textBlack.dark">
              {t('general_information')}
            </Typography>

            <Center justifyContent="flex-start" alignItems="flex-start" pl={2}>
              <ImageHolder src={avatar} width={226} height={180} />

              <Box>
                {tagGroups.length > 0 && <TagsBar ml={2} groups={tagGroups} />}

                <Description title={t('description')} description={collection?.description} />
              </Box>
            </Center>
          </Box>

          {withPhotos && (
            <Box mt={4} pl={2}>
              <Gallery images={photos} />
            </Box>
          )}

          <RelatedProjects
            mt={4}
            title={t('related_projects')}
            projects={collection?.projects}
            gridProps={{ pl: 2 }}
          />

          {collection?.students?.length > 0 ? (
            <AssignedStudents students={collection.students} mt={4} />
          ) : undefined}
        </Container>
      </Article>

      <AssignCollectionModal
        open={assignModalOpen}
        spaceId={me?.space_id}
        projects={collection?.projects}
        onClose={closeAssignModal}
        loading={assignCollectionState.isLoading}
        onValue={handleAssignCollection}
      />

      <RequestSuccessModal
        open={requestSuccessModalOpen}
        onClose={() => setRequestSuccessModalOpen(false)}
      />
    </ProjectLayout>
  );
};

export default Collection;
