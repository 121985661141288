import { useCallback, useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material';

import { Center, Icon, RippleBox } from 'components';

const ActionHolder = styled(RippleBox, {
  label: 'ActionHolder-Root',
})(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-flex',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  borderRadius: theme.shape.borderRadius * 2,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}));

const Messages = (props) => {
  const { children } = props;
  const rootRef = useRef(null);

  const handleAction = useCallback((key) => {
    const { current: notify } = rootRef;

    const handleClose = () => {
      typeof notify?.closeSnackbar === 'function' && notify.closeSnackbar(key);
    };
    return (
      <Center>
        <ActionHolder ripple onClick={handleClose}>
          <Icon.Close />
        </ActionHolder>
      </Center>
    );
  }, []);

  return (
    <SnackbarProvider
      ref={rootRef}
      maxSnack={5}
      autoHideDuration={7000}
      action={handleAction}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Messages;
