import { getArray } from 'utils/helpers';

const mapRequest = (me, filters) => {
  const { search, activeFilters } = filters;

  const { tags } = activeFilters || {};

  return { spaceId: me?.space_id, name: search || null, tags: getArray(tags).map((tag) => tag.id) };
};

export default mapRequest;
