import { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetCollectionsQuery, useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';

import { CardContent } from 'views';
import { ProgressBox, Pagination, PaperCard } from 'components';

import MyCollections from './MyCollections';

import mapRequest from './mapRequest';

const { selectPageFilters, selectPagination } = selectors;

const Collections = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'learning' });
  const { auth, progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data, isLoading, isFetching } = useGetCollectionsQuery(
    mapRequest({ me, filters: pageFilters, pagination }),
    {
      skip: (auth && !me?.space_id) || progress,
    },
  );

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress;

  return (
    <Box>
      <MyCollections me={me} pageFilters={pageFilters} auth={auth} loading={loading} />

      <Box mt={4}>
        <Typography variant="subtitle5" color="textBlack.dark">
          {t('all_collections')}
        </Typography>
      </Box>

      <ProgressBox loading={loading} mt={3}>
        <Grid container spacing={3}>
          {getArray(data?.collections).map((collection) => (
            <Grid key={`collections-grid-${collection.id}`} item xs={4}>
              <PaperCard actionAreaStyle={{ p: 0 }} to={`/learning/collections/${collection.id}`}>
                <CardContent
                  variant="collection"
                  id={collection.id}
                  title={collection.name}
                  tags={collection.tags}
                  avatar={collection.avatar}
                  projectsQty={collection.projects_qty}
                  level={collection.level}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </ProgressBox>

      <Pagination
        total={data?.collections_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </Box>
  );
};

export default Collections;
