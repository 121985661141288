import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';

import { getArray } from 'utils/helpers';

import { Center, Button, Tags, Icon } from 'components';

const Bar = styled(Box, { label: 'FitlersBar-Bar' })(({ theme }) => ({
  display: 'inline-flex',
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.other.white,
  boxShadow: theme.shadows.standard,
  minHeight: 40,
}));

const FiltersBar = (props) => {
  const { filters = {}, onRemove, onClear, size, ...rest } = props;

  const { t } = useTranslation('views', { keyPrefix: 'FiltersBar' });

  const getExtraTagProps = useCallback(
    ({ tag, name }) => {
      return {
        endAdornment: <Icon name="Close" />,
        pr: 1,
        avatarSize: 16,
        pl: tag?.avatar ? 1 : undefined,
        onClick: () => {
          typeof onRemove === 'function' && onRemove({ name, value: tag });
        },
      };
    },
    [onRemove],
  );

  const withValues = useMemo(() => {
    if (Object.keys(filters)?.length === 0) return false;
    return Object.values(filters).filter(Boolean).length > 0;
  }, [filters]);

  if (!withValues) return null;

  return (
    <Center justifyContent="flex-start" {...rest}>
      <Button type="button" variant="outlined" size={size} onClick={onClear}>
        {t('clear_all')}
      </Button>

      <Bar borderRadius={3} ml={2}>
        {getArray(Object.entries(filters)).map(
          ([name, selected], index) =>
            selected?.length > 0 && (
              <Tags
                width="auto"
                mr={filters.length !== index + 1 ? 2 : undefined}
                key={`filters-bar-${name}`}
                name={name}
                title={t(`title.${name}`)}
                tags={selected}
                clickable
                size={size}
                getExtraTagProps={getExtraTagProps}
              />
            ),
        )}
      </Bar>
    </Center>
  );
};

export default FiltersBar;
