import { useState } from 'react';
import { Box, Typography, Stack, styled } from '@mui/material';

import { dev } from 'utils/config';
import { getArray, makeOptions } from 'utils/helpers';
import { Tag } from 'components';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(Stack, {
  label: 'Tags-Root',
})(({ theme, width }) => ({
  width: width || '100%',
  gap: theme.spacing(1),
}));

/**
 * @prop {string} [title] - Title to be displayed before the tags list
 * @prop {Object[]} tags - Tag list (can contain props that could be passed to <Tag />)
 * @prop {string} tags[].id - The id of the tag
 * @prop {string} tags[].name - The label to be displayed
 * @prop {number} [max=-1] - Max tags to show before +x. If -1 all tags are displayed.
 * @prop {string['small'|'medium']|number} [size='small'] - Height size of the tag
 * @prop {function} [getExtraTagProps] - get extra props that can be passed to each <Tag />
 */
const Tags = (props) => {
  const {
    name,
    tags,
    title,
    max = -1,
    size = 'medium',
    clickable,
    titleProps = {},
    getExtraTagProps,
    ...rest
  } = props;

  const totalTags = tags?.length || 0;

  let clampedMax = max === -1 ? totalTags : max < 2 ? 2 : max;

  if (totalTags === clampedMax) {
    clampedMax += 1;
  }

  clampedMax = Math.min(totalTags + 1, clampedMax);

  const maxTags = Math.min(totalTags, clampedMax);
  const extraTags = Math.max(totalTags - clampedMax, totalTags - maxTags, 0);

  return (
    <Root width="100%" direction="row" alignItems="center" {...rest}>
      {title && (
        <Typography variant="body4" color="textBlack.light" {...titleProps}>
          {title}
        </Typography>
      )}

      <Stack direction="row" alignItems="center" spacing={1}>
        {getArray(tags)
          .slice(0, maxTags)
          .map((tag, index) => {
            const { id, name: tagName, ...tagProps } = tag;
            const extraTagProps =
              typeof getExtraTagProps === 'function' && getExtraTagProps({ tag, name, index });

            return (
              <Tag
                size={size}
                key={`${name}-${id}`}
                label={tagName}
                title={tagName}
                bgcolor="input.main"
                clickable={clickable}
                overflow="hidden"
                textOverflow="ellipsis"
                {...extraTagProps}
                {...tagProps}
              />
            );
          })}

        {extraTags ? (
          <Tag
            size={size}
            label={`+${extraTags}`}
            bgcolor="input.main"
            {...(getExtraTagProps && getExtraTagProps({ index: totalTags - 1 }))}
          />
        ) : null}
      </Stack>
    </Root>
  );
};

if (dev) {
  const tags = makeOptions([
    'creativity',
    'innovation',
    'modeling',
    'development',
    'design',
    'quiz',
  ]).map((option) => ({
    id: option.value,
    name: option.label,
  }));

  const Demo = () => {
    const [title, setTitle] = useState(undefined);
    const [size, setSize] = useState(undefined);
    const [max, setMax] = useState(undefined);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="title"
            value={title}
            onChange={setTitle}
            options={[undefined, 'Tags:', 'Related tags:']}
          />
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'small', 'medium', 38]}
          />
          <Fieldset.Field legend="max" value={max} onChange={setMax} options={[undefined, 2, 5]} />
        </Fieldset>

        <Tags max={max} size={size} title={title} tags={tags} />
      </Box>
    );
  };

  Tags.Demo = Demo;
}

export default Tags;
