import { Typography } from '@mui/material';
import { styled, alpha, darken } from '@mui/material/styles';

import { Ref } from 'components';

const Root = styled(Ref, {
  label: 'HeaderNavButton-Root',
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ theme, active }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius * 3,
  height: 40,
  minWidth: 72,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  backgroundColor: active ? theme.palette.primary.dark : undefined,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover, &:focus': {
    outlineColor: 'transparent',
    outlineWidth: 0,
    outlineOffset: 0,
    backgroundColor: active
      ? darken(theme.palette.primary.dark, 0.1)
      : alpha(theme.palette.primary.dark, 0.5),
  },
}));

const HeaderNavButton = (props) => {
  const { children, active, to, ...rest } = props;

  return (
    <Root
      hidden
      to={to}
      color="inherit"
      active={active}
      {...rest}
    >
      <Typography variant="body3">
        {children}
      </Typography>
    </Root>
  );
};

export default HeaderNavButton;
