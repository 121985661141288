import { forwardRef, useMemo, Children, cloneElement, useRef, useLayoutEffect } from 'react';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import { useToggle } from 'hooks';
import Popover from 'components/Popover';
import Button from 'components/Button';

/**
 * Allows to attach a dropdown to an element. Will automatically show the dropdown on click, and
 * hide it on outside click.
 * @prop {ReactNode} target - Trigger target (button, icon, etc). Should be able to add the onClick
 * listener
 * @prop {ReactNode} children - The dropdown content.
 * Rest of props will be passed to <Popover /> component.
 */
const DropdownPopover = forwardRef((props, ref) => {
  const { children, target, onAfterClose, ...rest } = props;

  const targetRef = useRef(null);
  const [open, toggleOpen] = useToggle();

  const triggerTarget = useMemo(() => {
    const targetChilds = Children.toArray(target);

    if (targetChilds.length > 1) {
      console.error('[DrpdownPopover]: only one node allowed as a target (first one will be used)');
    }
    return (
      targetChilds[0] &&
      cloneElement(targetChilds[0], {
        ref: propagateRefs(targetRef, targetChilds[0].ref),
      })
    );
  }, [target]);

  useLayoutEffect(() => {
    const { current } = targetRef;

    if (current) {
      const listenClick = toggleOpen.on;
      current.addEventListener('click', listenClick);

      return () => {
        current.removeEventListener('click', listenClick);
      };
    }
  }, [toggleOpen]);

  if (!triggerTarget) {
    return null;
  }
  return (
    <>
      {triggerTarget}

      <Popover
        {...rest}
        ref={ref}
        onClose={(...args) => {
          toggleOpen.off(...args);
          typeof onAfterClose === 'function' && onAfterClose(...args);
        }}
        anchorEl={targetRef.current}
        open={open}
      >
        {children}
      </Popover>
    </>
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box p={2}>
        <Box component="pre">
          {`<DropdownPopover\n`}
          {`  target={\n`}
          {`    <Button>Trigger</Button>\n`}
          {`  }\n`}
          {`>\n`}
          {`  Dropdown content\n`}
          {`</DropdownPopover>\n`}
        </Box>

        <Box p={4}>
          <DropdownPopover target={<Button>Trigger</Button>}>Dropdown content</DropdownPopover>
        </Box>
      </Box>
    );
  };
  DropdownPopover.Demo = Demo;
}

export default DropdownPopover;
