import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Collapse } from '@mui/material';

import { ROLE, TEACHER_PERMISSIONS } from 'utils/constants';
import { hasPermission, getProfileBasePath } from 'utils/helpers';
import { useAuth, useForm, useMessage } from 'hooks';
import {
  useGetMeQuery,
  useUploadTemporaryFileMutation,
  useUpdateTeacherMutation,
  useGetUserQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Paper, Form, Container, Tabs } from 'components';
import { UserInformationForm, PageSubheader, IndividualSettingsForm } from 'views';

const contentWidth = 936;

const mapInitialData = (teacher) => {
  if (!teacher) return {};

  const userData = {
    first_name: teacher.profile?.first_name || '',
    last_name: teacher.profile?.last_name || '',
    avatar: [teacher.profile?.avatar],
    cached_avatar_id: teacher.profile?.avatar,
    phone_number: teacher.phone_number || '',
    email: teacher.email || '',
  };

  Object.entries(TEACHER_PERMISSIONS).forEach(([name, permissions]) => {
    userData[name] = hasPermission(teacher.profile?.permissions, permissions);
  });

  return userData;
};

const EditTeacher = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [tab, setTab] = useState('information');

  const { t } = useTranslation('pages', { keyPrefix: 'edit_teacher' });
  const { auth } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [updateTeacher, updateTeacherState] = useUpdateTeacherMutation();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: teacher,
    isLoading: teacherLoading,
    isFetching: teacherFetching,
  } = useGetUserQuery(
    { user_id: id },
    {
      skip: !id,
    },
  );

  useEffect(() => {
    form.reset(mapInitialData(teacher));
  }, [form, teacher]);

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleSubmit = async (formData) => {
    const { avatar, cached_avatar_id, avatar_last_modified } = formData;

    const [file] = avatar;

    let avatarId = cached_avatar_id;
    if (file instanceof File && file.lastModified !== avatar_last_modified) {
      try {
        const { file_id } = await uploadFile({ file: file }).unwrap();
        avatarId = file_id;
        form.setValue(`cached_avatar_id`, file_id);
        form.setValue(`avatar_last_modified`, file.lastModified);
      } catch (e) {
        console.error(e);
        m.error(t('error.failed_upload_photo'));
      }
    }

    try {
      const postData = {
        teacherId: id,
        language: 'en',
        avatar_file_id: avatarId,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone_number: formData.phone_number || null,
      };

      Object.entries(TEACHER_PERMISSIONS).forEach(([name, permissions]) => {
        permissions.forEach((permission) => {
          if (!postData.permissions) postData.permissions = {};
          postData.permissions[permission] = formData[name];
        });
      });

      const { user_id } = await updateTeacher(postData).unwrap();

      if (user_id) return navigate(`/administration/teachers/${user_id}`);
    } catch (err) {
      console.error(err);
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else if (!!err?.data?.detail?.error) {
        return m.error(err.data.detail.error);
      } else {
        m.error(t('error.failed_update_teacher'));
      }
    }
  };

  const loading =
    isLoading ||
    isFetching ||
    uploadFileState.isLoading ||
    updateTeacherState.isLoading ||
    teacherLoading ||
    teacherFetching;

  return (
    <Form id={`new-teacher-form`} width="100%" form={form} onSubmit={handleSubmit}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backTitle={t('back_button')}
            backUrl={`${getProfileBasePath(ROLE.TEACHER)}/${id}`}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
                radius: 2,
              },
              [t('page_actions.edit')]: {
                type: 'submit',
                size: 'small',
                radius: 2,
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth}>
          <Box py={4}>
            <Typography
              width="100%"
              component="h3"
              mb={2}
              color="secondary.main"
              variant="subtitle3"
            >
              {t(`page_title.${tab}`)}
            </Typography>

            <Tabs
              width={396}
              variant="fullWidth"
              value={tab}
              onValue={setTab}
              options={[
                {
                  label: t('tabs.information'),
                  value: 'information',
                },
                {
                  label: t('tabs.settings'),
                  value: `settings`,
                },
              ]}
            />
            <Box pt={3}>
              <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
                <Collapse sx={{ width: '100%' }} in={tab === 'information'}>
                  <UserInformationForm
                    title={t('personal_information')}
                    form={form}
                    valid={valid}
                    rules={rules}
                    me={me}
                    withGroups={false}
                  />
                </Collapse>
                <Collapse sx={{ width: '100%' }} in={tab === 'settings'}>
                  <IndividualSettingsForm
                    title={t('password')}
                    form={form}
                    valid={valid}
                    rules={rules}
                    me={me}
                    hidePassword
                  />
                </Collapse>
              </Paper>
            </Box>
          </Box>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default EditTeacher;
