export const primary_default = '#12A195';
export const primary_dark = '#027E73';
export const primary_light = '#B3DFDB';
export const primary_background = '#F7FCFB';

export const secondary_default = '#0F2744';
export const secondary_light = '#637892';
export const secondary_dark = '#214066';
export const secondary_background = '#96A2B0';

export const success_default = '#16C79A';
export const success_light = '#5CD8B8';
export const success_dark = '#0F8B6C';
export const success_background = '#E8F9F5';

export const error_default = '#F05454';
export const error_light = '#F58787';
export const error_dark = '#A83B3B';
export const error_background = '#FEEEEE';

export const warning_default = '#FFC453';
export const warning_light = '#FFD687';
export const warning_dark = '#EF8B2F';
export const warning_background = '#FFF9EE';

export const text_black_default = '#1C1D21';
export const text_black_light = '#646464';
export const text_black_dark = '#303030';
export const text_black_background = '#939393';

export const text_default = '#006192';
export const text_light = '#3F89AE';
export const text_dark = '#01517A';
export const text_background = '#F4F7FA';
export const text_disabled = '#ABB8CA';

export const text_secondary = '#728095';

export const text_tag_default = '#48515B';
export const text_tag_disabled = '#AFB8C0';
export const text_tag_light = primary_default;

export const disabled_default = '#E0E4EB';
export const disabled_light = '#C8CFDA33';

export const other_tooltip = '#2D343D';
export const other_line = '#C1C7CF';
export const other_divider = '#E6EAEE';
export const other_hover = '#F7F8FA';
export const other_white = '#FFFFFF';
export const other_black = '#000000';
export const other_background = '#FFFAF2';
export const other_pagination_bullet = '#E2EFEE';

export const other_chip_default = '#F3F5F9';
export const other_chip_primary = '#D4E3FF';
export const other_chip_purple = '#F1EEFF';

export const other_controls_hover = '#C7CED4';
export const other_controls_default = '#E3E7EB';
export const other_controls_disabled = '#EEF1F4';

export const other_input_hover = '#E9EBEF';
export const other_input_default = '#F4F5F8';
export const other_input_disabled = '#F7F8FA';
export const other_input_placeholder = '#7F8F9F';

export const progress_light = '#EBF6F1';
export const progress_main = '#46BD84';
