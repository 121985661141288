import { useCallback } from 'react';
import { styled, Typography, Box, Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getArray, getProfileBasePath } from 'utils/helpers';
import { withProps, useAuth, useMessage, useUsersSearch } from 'hooks';
import { useGetMeQuery, useGetGroupQuery, useDeleteGroupMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, PaperCard, Icon, IconButton, Input, ProgressBox } from 'components';
import { PageSubheader, CardContent } from 'views';
import { MetaData, TagsBar, Description } from 'views/ArticleGeneralInformation';

import NavigationTabs from './NavigationTabs';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const GroupInformation = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'single_group' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: group,
    isLoading: groupLoading,
    isFetching: groupFetching,
  } = useGetGroupQuery({ id }, { skip: !Boolean(id) || !auth });

  const {
    data: students,
    loading: studentsLoading,
    tempSearch: studentSearch,
    onSearch: onStudentSearch,
  } = useUsersSearch({
    spaceId: me?.space_id,
    disabled: !Boolean(group?.id),
    filters: {
      roles: [],
      groups: [group?.id],
    },
  });

  const [deleteGroup, deleteGroupState] = useDeleteGroupMutation();

  const handleDeleteGroup = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteGroup({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/administration/groups');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteGroup, navigate, id, t, m]);

  const loading = groupLoading || groupFetching || deleteGroupState.isLoading;

  const tagGroups = [
    {
      title: t('tags.group_leader'),
      tags: [
        {
          avatar: group?.leader?.avatar,
          avatarSize: 24,
          pl: 1,
          color: 'textRegular.light',
          label: group?.leader?.username,
          border: true,
        },
      ],
    },
    {
      title: t('tags.students'),
      tags: [
        { pl: 1, startAdornment: <Icon name="Student" />, label: group?.participants?.length },
      ],
    },
  ];

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={group?.name}
          backUrl="/administration/groups"
          backTitle={t('back_button')}
          pageActions={{
            [t('page_actions.edit')]: {
              type: 'button',
              disabled: loading,
              variant: 'outlined',
              size: 'small',
              name: 'Edit',
              border: true,
              Component: IconButton,
              to: `/administration/groups/${id}/edit`,
            },
            [t('page_actions.delete')]: {
              type: 'button',
              size: 'small',
              disabled: loading,
              color: 'error',
              name: 'Delete',
              Component: IconButton,
              onClick: handleDeleteGroup,
            },
          }}
        />
      }
    >
      <Article py={4}>
        <Container>
          <Box mb={3}>
            <NavigationTabs tab="information" groupId={group?.id} />
          </Box>
          <Box>
            <Typography component="div" variant="subtitle5" mb={2} color="textBlack.dark">
              {t('general_information')}
            </Typography>

            <TagsBar groups={tagGroups} />

            <MetaData>
              {group?.schedule && (
                <MetaData.Schedule
                  title={t('date_and_time')}
                  schedule={getArray(group?.schedule)[0]}
                />
              )}
              {group?.address && <MetaData.Text title={t('location')} text={group?.address} />}
            </MetaData>

            {group?.description && (
              <Description title={t('description')} description={group.description} />
            )}
          </Box>

          <Box>
            <Typography component="div" variant="subtitle5" mb={2} color="textBlack.dark">
              {t('students')}
            </Typography>

            <Box width={584} mb={3}>
              <Input
                value={studentSearch}
                onValue={(s) => onStudentSearch(null, s)}
                iconLeft="Search"
                fullWidth
                placeholder={t('students_search')}
              />
            </Box>

            <ProgressBox loading={studentsLoading}>
              <Grid container spacing={3}>
                {getArray(students?.users).map((user) => (
                  <Grid key={`users-grid-${user.id}`} item xs={6}>
                    <PaperCard to={`${getProfileBasePath(user?.role)}/${user.id}`}>
                      <CardContent
                        variant="group_user"
                        avatar={user.avatar}
                        username={user.username}
                        fullName={user.full_name}
                        role={user.role}
                        email={user.email}
                      />
                    </PaperCard>
                  </Grid>
                ))}
              </Grid>
            </ProgressBox>
          </Box>
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default GroupInformation;
