import { useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getArray } from 'utils/helpers';
import { useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter, useAcl } from 'hooks';

import { ProjectLayout } from 'layouts';
import { Center, Tabs, Icon, Input, DropdownMenu, Button } from 'components';
import { FiltersBar, SidebarFilters } from 'views';

import Projects from './Projects';
import Collections from './Collections';
import { PERMISSIONS } from 'utils/constants';

const { selectFilters, selectActiveFilters } = selectors;

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const ActionsRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Learning = () => {
  const acl = useAcl();
  const navigate = useNavigate();
  const projectsPath = useMatch(`/learning/projects`);
  const collectionsPath = useMatch(`/learning/collections`);

  const tab = projectsPath ? 'projects' : 'collections';

  const { t } = useTranslation('pages', { keyPrefix: 'learning' });
  const { auth } = useAuth();
  const { onSearch, onResetActiveFilters, tempSearch, onSetActiveFilter, onResetPageFilter } =
    usePageFilter();

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const tabOptions = useMemo(() => {
    return [
      {
        value: 'projects',
        label: t('projects'),
        icon: 'Project',
        width: 192,
      },
      {
        value: 'collections',
        label: t('collections'),
        icon: 'Collection2',
        width: 192,
      },
    ];
  }, [t]);

  const handleTabValue = useCallback(
    (value) => {
      navigate(`/learning/${value}`, { replace: false });
    },
    [navigate],
  );

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  useEffect(() => {
    if (!projectsPath && !collectionsPath) {
      navigate(`/learning/projects`, { replace: false });
    }
  }, [projectsPath, collectionsPath, navigate]);

  const pageActions = useMemo(() => {
    const options = [];

    if (acl.allow({ permissions: [PERMISSIONS.CREATE_PROJECT] })) {
      options.push({
        pl: 1,
        startAdornment: <Icon name="Project" />,
        label: t('subheader.create_project'),
        value: 'create_project',
        onClick: () => {
          if (me) navigate('/learning/projects/new');
        },
      });
    }

    if (acl.allow({ permissions: [PERMISSIONS.CREATE_COLLECTION] })) {
      options.push({
        pl: 1,
        startAdornment: <Icon name="Collection2" />,
        label: t('subheader.create_collection'),
        value: 'create_collection',
        onClick: () => {
          if (me) navigate('/learning/collections/new');
        },
      });
    }

    return options;
  }, [acl, me, navigate, t]);

  return (
    <ProjectLayout
      subheader={
        <Center flexGrow={1}>
          <PageTitle>
            <Typography variant="subtitle2">{t('subheader.title')}</Typography>
          </PageTitle>
          <Grid container width="100%" mx={0} spacing={3}>
            <Grid item xs={6}>
              <Input
                value={tempSearch}
                onValue={onSearch}
                iconLeft="Search"
                fullWidth={true}
                placeholder={t('subheader.page_search')}
              />
            </Grid>
            <Grid item xs={6}>
              {pageActions.length > 0 && (
                <ActionsRoot>
                  <DropdownMenu
                    minWidth={200}
                    target={<Button iconRight="ArrowDown">{t('subheader.create_new')}</Button>}
                    options={pageActions}
                  />
                </ActionsRoot>
              )}
            </Grid>
          </Grid>
        </Center>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Box p={4} pl={3}>
        <FiltersBar
          filters={activeFilters}
          mb={3}
          onRemove={handleRemoveSelectedFilters}
          onClear={onResetActiveFilters}
        />

        <Center pb={3} justifyContent="space-between">
          <Tabs
            sx={{ backgroundColor: 'other.white' }}
            value={tab}
            variant="standard"
            options={tabOptions}
            onValue={handleTabValue}
          />
        </Center>

        {tab === 'projects' && <Projects me={me} />}
        {tab === 'collections' && <Collections me={me} />}
      </Box>
    </ProjectLayout>
  );
};

export default Learning;
