import { Box, styled } from '@mui/material';

import { Center, FormLabel } from 'components';

const Row = styled(Center)({
  width: '100%',
});

const InputHolder = styled(Box)({
  width: 456,
});

const FormRow = (props) => {
  const { label, optional, children, hideLabel, labelWidth = 224, alignItems, ...rest } = props;

  return (
    <Row justifyContent="flex-start" alignItems={alignItems || 'center'} {...rest}>
      {!hideLabel && (
        <FormLabel
          optional={optional}
          variant="body4"
          width={labelWidth}
          mr={2}
          mt={alignItems === 'flex-start' ? 1 : undefined}
          flexShrink={0}
        >
          {label}
        </FormLabel>
      )}
      <InputHolder>{children}</InputHolder>
    </Row>
  );
};

export default FormRow;
