import { forwardRef, useState, useMemo, useRef, useEffect } from 'react';
import { Box, styled, darken } from '@mui/material';

import avatar from 'assets/images/examples/avatar.png';
import { dev } from 'utils/config';
import { keyCodes } from 'utils/helpers';
import { useCombineRefs } from 'hooks';

import Avatar from 'components/Avatar';
import Fieldset from 'components/Dev/Fieldset';
import RippleBox from 'components/RippleBox';

const Root = styled(RippleBox, {
  label: 'Tag-Root',
  shouldForwardProp: (prop) => !['disabled'].includes(prop),
})(({ theme, disabled, ripple, bgcolor, color }) => ({
  ...theme.typography.body4,
  cursor: ripple ? 'pointer' : 'default',
  overflow: 'hidden',
  display: 'inline-flex',
  color: disabled ? theme.palette.tag.disabled : color || theme.palette.tag.main,
  borderRadius: theme.shape.borderRadius * 2,
  pointerEvents: disabled ? 'none' : undefined,
  backgroundColor: bgcolor || theme.palette.primary.contrastText,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover, &:focus': {
    outlineColor: 'transparent',
    outlineWidth: 0,
    backgroundColor: ripple ? darken(theme.palette.primary.contrastText, 0.03) : undefined,
  },
}));

const Piece = styled(Box, {
  label: 'Tag-Piece',
})({
  display: 'inline-flex',
  alignItems: 'center',
});

export const tagSizes = {
  small: 24,
  medium: 32,
  large: 40,
};

/**
 * @prop {ReactNode} [label] - Tag content
 * @prop {ReactNode} [children] - Tag content (if both "label" and "children" will be passed, label
 * will be placed before children)
 * @prop {boolean} [clickable] - Enable ripple effect
 * @prop {boolean} [fullWidth] - Inline or fullwidth tag
 * @prop {string['small'|'medium']|number} [size='small'] - Height size of the tag
 * @prop {ReactNode} [endAdornment] - Content after the label/children (placed max to the right)
 * @prop {ReactNode} [startAdornment] - Content before the label/children (placed max to the left)
 * @prop {string} [avatar] - Avatar link (will replace "startAdornment" if both passed)
 * @prop {boolean} [disabled] - Disabled styles
 * @prop {number} [tabIndex=0] - Element is focused by default (setup to -1 to disable)
 * @prop {number} [avatarSize] - Size of the avatar. If not set - height size of the tag is used
 */
const Tag = forwardRef((props, ref) => {
  const {
    label,
    avatar,
    disabled,
    children,
    clickable,
    fullWidth,
    endAdornment,
    startAdornment,
    size = 'small',
    avatarSize,
    tabIndex = 0,
    ...rest
  } = props;

  const rootRef = useRef(null);
  const handleRef = useCombineRefs(ref, rootRef);

  const height = useMemo(() => {
    if (typeof size === 'string' && tagSizes[size]) {
      return tagSizes[size];
    }
    if (Number.isFinite(size)) {
      return size;
    }
    return 24;
  }, [size]);

  const startContent = useMemo(() => {
    return !avatar ? startAdornment : <Avatar size={avatarSize || height} src={avatar} />;
  }, [avatar, startAdornment, height, avatarSize]);

  useEffect(() => {
    const { current: root } = rootRef;

    if (root) {
      const listenTrigger = (e) => {
        if (document.activeElement === root) {
          if (e.keyCode === keyCodes.enter || e.keyCode === keyCodes.space) {
            e.preventDefault();
            root.click();
          }
        }
      };
      window.addEventListener('keydown', listenTrigger);

      return () => {
        window.removeEventListener('keydown', listenTrigger);
      };
    }
  }, []);

  return (
    <Root
      ref={handleRef}
      {...rest}
      height={height}
      ripple={clickable}
      maxHeight={height}
      disabled={disabled}
      tabIndex={tabIndex}
      width={fullWidth ? '100%' : undefined}
    >
      {startContent && <Piece sx={{ opacity: disabled ? 0.5 : 1 }}>{startContent}</Piece>}

      <Piece pl={startContent ? 0.5 : 1} pr={1}>
        {label}
        {children}
      </Piece>

      {endAdornment && (
        <Piece sx={{ opacity: disabled ? 0.5 : 1 }} ml="auto">
          {endAdornment}
        </Piece>
      )}
    </Root>
  );
});

if (dev) {
  const Demo = () => {
    const [size, setSize] = useState('medium');
    const [disabled, setDisabled] = useState();
    const [label, setLabel] = useState('Noiro Bohdan');
    const [avatarSrc, setAvatarSrc] = useState('./avatar.png');
    const [children, setChildren] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'small', 'medium', 38]}
          />
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Noiro Bohdan', 'Mega Foggy']}
          />
          <Fieldset.Field
            legend="avatar"
            value={avatarSrc}
            onChange={setAvatarSrc}
            options={[undefined, './avatar.png']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, 'Jone Doe']}
          />
        </Fieldset>

        <Tag disabled={disabled} size={size} label={label} avatar={avatarSrc ? avatar : undefined}>
          {children}
        </Tag>
      </Box>
    );
  };
  Tag.Demo = Demo;
}

export default Tag;
