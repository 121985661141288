import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Center, Icon, Tag } from 'components';

const TagsCount = styled(Tag)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.input.main,
}));

const TagHolder = styled(Center)({
  justifyContent: 'flex-start',
});

const StyledTag = styled(Tag, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    border: '1px solid',
    borderColor: theme.palette.controls.main,
    backgroundColor: active ? theme.palette.controls.main : theme.palette.other.white,
  }),
);

const checkActive = (tags = [], tag) => {
  const foundIndex = tags.findIndex((item) => item.id === tag.id);
  return foundIndex !== -1;
};

const iconNameByRole = {
  student: 'Student',
  admin: 'Admin',
  parent: 'Parent',
  teacher: 'Teacher',
  personal: 'User',
  organization: 'Organization',
};

const TagItem = (props) => {
  const { tag, onChange, ...rest } = props;

  return (
    <TagHolder mb={1}>
      <StyledTag
        size="medium"
        clickable
        startAdornment={<Icon name={iconNameByRole[tag.id]} />}
        onClick={() => onChange(tag)}
        pl={1}
        label={tag.name}
        {...rest}
      />
      {tag.qty > 0 && (
        <Typography ml={1} variant="caption1" color="textBlack.light">
          ({tag.qty})
        </Typography>
      )}
    </TagHolder>
  );
};

const RolesFilter = (props) => {
  const { value = [], total, tags, onValue } = props;

  const { t } = useTranslation('views', { keyPrefix: 'RolesFilter' });

  const handleChange = useCallback(
    (tag) => {
      if (typeof onValue !== 'function') return;

      const foundIndex = value.findIndex((item) => item.id === tag.id);
      if (foundIndex === -1) {
        onValue([...value, tag]);
      } else {
        const changed = [...value];
        changed.splice(foundIndex, 1);
        onValue(changed);
      }
    },
    [value, onValue],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Center mb={1} justifyContent="space-between">
        <Typography color="textBlack.light">{t('title')}</Typography>
        {total > 0 && <TagsCount size="small" label={total} />}
      </Center>

      <Stack mt={1} sx={{ maxHeight: '432px', overflow: 'auto' }}>
        {tags.map((tag, index) => (
          <TagItem
            key={`tag-filter-${tag.id}-${index}`}
            tag={tag}
            active={checkActive(value, tag)}
            onChange={handleChange}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default RolesFilter;
