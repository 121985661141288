import { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useForm } from 'hooks';

import { Modal, Button, Input, Form } from 'components';

const AddStudentVideoModal = (props) => {
  const { open, loading, onClose, onSubmit } = props;

  const { t } = useTranslation('views', { keyPrefix: 'AddStudentVideoModal' });

  const {
    form,
    valid,
    rules: { required },
  } = useForm();

  const handleSubmit = useCallback(
    (formData) => {
      typeof onSubmit === 'function' && onSubmit(formData);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (!open) form.reset();
  }, [open, form]);

  return (
    <Modal size={516} open={open} onClose={onClose}>
      <Modal.Body>
        <Box pt={4}>
          <Form id="add-student-video-form" form={form} onSubmit={handleSubmit}>
            <Input.Control
              fullWidth
              label={t('video.label')}
              placeholder={t('video.placeholder')}
              name="video"
              rules={{ required }}
            />
          </Form>
        </Box>
      </Modal.Body>
      <Modal.Footer sx={{ border: 'none' }} pt={0}>
        <Button
          form="add-student-video-form"
          type="submit"
          radius={2}
          size="small"
          disabled={loading || !valid}
        >
          {t('save')}
        </Button>
        <Button
          type="button"
          radius={2}
          variant="outlined"
          size="small"
          onClick={() => {
            form.reset();
            typeof onClose === 'function' && onClose();
          }}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStudentVideoModal;
