import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, styled } from '@mui/material';
import { useSelector } from 'react-redux';

import { usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';

import { useGetIdeasQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';

import { Paper, Tabs, Input } from 'components';
import { QuickFilters, IdeaCard } from 'views';

const { selectFilters, selectActiveFilters, selectPageFilters } = selectors;

const IdeasRoot = styled(Stack, { label: 'IdeaSearch-IdeasRoot' })(({ theme }) => ({
  minHeight: '500px',
  maxHeight: '500px',
  overflow: 'auto',
}));

const IdeaSearch = (props) => {
  const { selectedIdea, tabOptions = [], disabled, onSelect } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'new_project' });

  const { onSearch, tempSearch, onSetFilter, onSetActiveFilter, onResetPageFilter } =
    usePageFilter();

  const filters = useSelector(selectFilters);
  const pageFilters = useSelector(selectPageFilters);
  const activeFilters = useSelector(selectActiveFilters);

  const requestData = useMemo(() => {
    const { activeFilters } = pageFilters || {};

    return {
      name: pageFilters?.search || null,
      tags: getArray(activeFilters?.tags).map((tag) => tag.id),
      levels: getArray(activeFilters?.levels).map((level) => level.id),
      limit: 20,
      offset: 0,
    };
  }, [pageFilters]);

  const {
    data: ideas,
    isLoading,
    isFetching,
  } = useGetIdeasQuery(requestData, { skip: disabled || !pageFilters?.search });

  useEffect(() => {
    if (!ideas?.filter_data) return;
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: ideas.filter_data.tags });
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: ideas.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
  }, [ideas, onSetFilter]);

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  const loading = isLoading || isFetching;

  return (
    <>
      <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
        {t('choose_idea_title')}
      </Typography>

      <Tabs
        mb={2}
        width={396}
        variant="fullWidth"
        value={tabOptions[0]?.value}
        options={tabOptions}
        disabled
      />

      <Paper width="100%" overflow="visible" pt={4} pb={4} px={15} loading={loading}>
        <Input
          value={tempSearch}
          onValue={onSearch}
          iconLeft="Search"
          fullWidth={true}
          placeholder={t('search_idea')}
        />

        <QuickFilters
          mt={2}
          filters={filters}
          activeFilters={activeFilters}
          onChange={onSetActiveFilter}
        />

        <IdeasRoot width={1} mt={2} spacing={2} pr={1.5}>
          {!!pageFilters?.search &&
            getArray(ideas?.ideas).map((idea) => (
              <IdeaCard
                key={idea.id}
                border
                size="small"
                id={idea.id}
                title={idea.name}
                tags={idea.tags}
                photos={idea.photos}
                level={idea.level}
                onClick={(e) => {
                  e.preventDefault();
                  typeof onSelect == 'function' && onSelect(idea);
                }}
                sx={selectedIdea?.id === idea.id ? { borderColor: 'primary.main' } : undefined}
              />
            ))}
        </IdeasRoot>
      </Paper>
    </>
  );
};

export default IdeaSearch;
