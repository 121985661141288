import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps } from 'hooks';

import { FormRow, SelectMultiple, Input, FormTitle, Switch } from 'components';
import { UploadImage } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const QuestionForm = (props) => {
  const {
    title,
    rules: { required, startsWithUppercase },
    tagsOptions,
    loadingTags,
    onCreateTag,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'QuestionForm' });

  return (
    <>
      <FormTitle width="100%" sx={{}}>
        <Typography color="secondary.main" variant="body2">
          {title}
        </Typography>
      </FormTitle>
      <Stack mt={2}>
        <FormRow label={t('name.label')}>
          <Input.Control
            fullWidth
            type="text"
            name="name"
            rules={{ required, validate: (v) => startsWithUppercase(v, t('name.label')) }}
            placeholder={t('name.placeholder')}
          />
        </FormRow>
        <FormRow optional alignItems="flex-start" label={t('photos.label')}>
          <UploadImage.Control name="photos" />
        </FormRow>
        <FormRow label={t('tags.label')} optional>
          <SelectMultiple.Control
            creatable
            fullWidth
            itemsMax={6}
            defaultValue={[]}
            options={tagsOptions}
            disabled={loadingTags}
            name="tags"
            placeholder={t('tags.placeholder')}
            onCreate={onCreateTag}
          />
        </FormRow>

        <FormRow label={t('public.label')}>
          <Switch.Control fullWidth name="public" />
        </FormRow>
      </Stack>
    </>
  );
};

export default QuestionForm;
