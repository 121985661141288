import { useMemo, forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { propagateRefs } from 'utils/helpers';
import { useMixRules, useRules } from 'hooks';

import { Input, Select } from 'components';

import { months } from './defaults';

const BirthdayInput = forwardRef((props, ref) => {
  const { value = {}, onValue, placeholders = {} } = props;

  const { t } = useTranslation('components', { keyPrefix: 'BirthdayInput' });

  const handleChange = (n, v) => {
    typeof onValue === 'function' && onValue(Object.assign(value, { [n]: v }));
  };

  const monthOptions = useMemo(() => {
    return months.map((m, index) => ({ label: t(`months.${m}`), value: index }));
  }, [t]);

  return (
    <Grid ref={ref} container spacing={1.5}>
      <Grid item xs={3.5}>
        <Input
          value={value?.day || ''}
          fullWidth
          type="number"
          name="day"
          placeholder={placeholders?.day}
          onValue={(v) => handleChange('day', v)}
          inputProps={{ min: 1, max: 31 }}
        />
      </Grid>
      <Grid item xs={5}>
        <Select
          value={value?.month}
          fullWidth
          name="month"
          options={monthOptions}
          placeholder={placeholders?.month}
          onValue={(v) => handleChange('month', v)}
          itemsMax={5}
        />
      </Grid>
      <Grid item xs={3.5}>
        <Input
          value={value?.year || ''}
          fullWidth
          type="number"
          name="year"
          placeholder={placeholders?.year}
          onValue={(v) => handleChange('year', v)}
          inputProps={{ min: 1800 }}
        />
      </Grid>
    </Grid>
  );
});

BirthdayInput.Control = forwardRef((props, ref) => {
  const { name, rules: initRules, defaultValue, onValue, ...rest } = props;
  const { control } = useFormContext();

  const { birthday } = useRules();
  const rules = useMixRules(initRules, birthday);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <BirthdayInput
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

export default BirthdayInput;
