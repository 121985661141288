import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

import { dev } from 'utils/config';
import { withProps } from 'hooks';

/**
 * Shortcut for <Box display="flex" justifyContent="center" alignItems="center" />
 * Each predefined property could be rewrited while using.
 */
const Root = styled(Box, {
  label: 'Center-Root',
})({
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
});

const Center = withProps(Root, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

if (dev) {
  const Demo = () => {
    return (
      <div>
        <div>Shortcut for <code>{`<Box display="flex" justifyContent="center" alignItems="center" />`}</code>.</div>
        <div>Your content will always be centered by default, but you still can pass your props as for usual Material UI {`<Box />`} component:</div>
        <Box mt={2} pl={4}>
          <code>{`<Center width="300px" height="200px" border="1px solid black">Text</Center>`}:</code>
          <Center width="300px" height="200px" border="1px solid black">Text</Center>
        </Box>
        <Box mt={2} pl={4}>
          <Box component="code" display="block">{`<Center width="300px" height="200px" border="1px solid black" justifyContent="space-between>`}:</Box>
          <Box pl={2} component="code" display="block">{`  <div>Content 1</div>`}:</Box>
          <Box pl={2} component="code" display="block">{`  <div>Content 2</div>`}:</Box>
          <Box component="code" display="block">{`</Center>`}:</Box>
          <Center width="300px" height="200px" border="1px solid black" justifyContent="space-between">
            <div>Content 1</div>
            <div>Content 2</div>
          </Center>
        </Box>
      </div>
    );
  };
  Center.Demo = Demo;
}

export default Center;
