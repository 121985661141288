import { forwardRef, useMemo, useState } from 'react';
import { Box, styled } from '@mui/material';

import { dev } from 'utils/config';
import Center from 'components/Center';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(Center, {
  label: 'Holder-Root',
  shouldForwardProp: (prop) => !['shadow'].includes(prop),
})(({ theme, shadow }) => ({
  overflow: 'hidden',
  boxShadow: shadow ? theme.shadows[shadow] : undefined,
}));

/**
 * Rounded container often used in the project for different things.
 * @param {string} [color] - Border color (could be theme path)
 * @param {number} [stroke] - Border width
 * @param {number} [radius=2] - Border radius
 * @param {number|string} [size] - width and height
 * @param {number|string} [width] - width (priority)
 * @param {number|string} [height] - height (priority)
 * @param {string} [shadow] - name of shadow
 */
const Holder = forwardRef((props, ref) => {
  const { children, color, stroke, radius = 2, width, height, size, sx, ...rest } = props;

  if (stroke !== undefined && !Number.isFinite(stroke)) {
    throw new Error('[Holder]: stroke property should be undefined or integer.');
  }
  const sizing = useMemo(() => {
    const result = {};

    if (size || size === 0) {
      result.width = size;
      result.height = size;
    }
    if (width || width === 0) {
      result.width = width;
    }
    if (height || height === 0) {
      result.height = height;
    }
    return result;
  }, [width, height, size]);

  return (
    <Root
      ref={ref}
      display="inline-flex"
      {...rest}
      {...sizing}
      sx={{
        borderRadius: radius,
        border: stroke,
        borderColor: color,
        ...sx,
      }}
    >
      {children}
    </Root>
  );
});

const sizeOptions = [undefined, 0, 1, 2, 3, 4, 5, 10];
const colorOptions = [
  undefined,
  'primary.main',
  'secondary.main',
  'success.main',
  'warning.main',
  'error.main',
  'red',
  '#0033FF',
];

if (dev) {
  const Demo = () => {
    const [radius, setRadius] = useState(5);
    const [stroke, setStroke] = useState(5);
    const [color, setColor] = useState('secondary.main');
    const [bgcolor, setBgcolor] = useState('primary.main');

    return (
      <Box>
        <Box mb={4}>Rounded container often used in the project for different things.</Box>
        <Box display="flex" flexWrap="wrap" mb={4}>
          <Fieldset>
            <Fieldset.Field
              legend="radius"
              value={radius}
              onChange={setRadius}
              options={sizeOptions}
            />

            <Fieldset.Field
              legend="stroke"
              value={stroke}
              onChange={setStroke}
              options={sizeOptions}
            />

            <Fieldset.Field
              legend="color"
              value={color}
              onChange={setColor}
              options={colorOptions}
            />

            <Fieldset.Field
              legend="bgcolor"
              value={bgcolor}
              onChange={setBgcolor}
              options={colorOptions}
            />
          </Fieldset>
        </Box>

        <Box component="pre">
          {`<Holder\n`}
          {`  width={200}\n`}
          {`  height={200}\n`}
          {`  color="${color}"\n`}
          {`  bgcolor="${bgcolor}"\n`}
          {`  radius={${radius}}\n`}
          {`  stroke={${stroke}}\n`}
          {`>\n`}
          {`  Holder\n`}
          {`</Holder>`}
        </Box>

        <Holder
          radius={Number.isFinite(+radius) ? +radius : undefined}
          stroke={Number.isFinite(+stroke) ? +stroke : undefined}
          color={color}
          bgcolor={bgcolor}
          width="200px"
          height="200px"
        >
          Holder
        </Holder>
      </Box>
    );
  };
  Holder.Demo = Demo;
}

export default Holder;
