import { useMemo } from 'react';
import { Route } from 'react-router-dom';

import { getArray } from 'utils/helpers';
import ProtectedRoute from './ProtectedRoute';

const getRoute = (route, index) => {
  const {
    children,
    path,
    auth,
    roles,
    element,
    authRedirect,
    ...props
  } = route;

  return (
    <Route
      {...props}
      key={index}
      path={path}
      element={
        <ProtectedRoute
          auth={auth}
          roles={roles}
          authRedirect={authRedirect}
        >
          {element}
        </ProtectedRoute>
      }
    >
      {getArray(children).map(getRoute)}
    </Route>
  );
};

const useProtectedRoutes = (routes) => {
  return useMemo(() => {
    return getArray(routes).map(getRoute);
  }, [routes]);
};

export default useProtectedRoutes;
