import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { withProps } from 'hooks';
import { TEACHER_PERMISSIONS, ROLE } from 'utils/constants';

import { FormRow, Switch, FormTitle } from 'components';
import { PasswordField } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const IndividualSettingsForm = (props) => {
  const {
    me,
    title,
    visible,
    form,
    rules: { required, password, passwordOptional },
    hidePassword = false,
    createPassword = false,
    optionalPassword = true,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'IndividualSettingsForm' });

  const watchNewPassword = form.watch('new_password');
  const passwordRules = useMemo(() => {
    return { required: !!watchNewPassword ? required : false };
  }, [watchNewPassword, required]);

  return (
    <>
      {!hidePassword && (
        <>
          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {title}
            </Typography>
          </FormTitle>
          <Stack mt={2} mb={2} width="100%">
            {!createPassword && (
              <FormRow label={t('old_password.label')}>
                <PasswordField.Control
                  fullWidth
                  initFocus={visible}
                  name="password"
                  optional
                  rules={passwordRules}
                  placeholder={t('old_password.placeholder')}
                />
              </FormRow>
            )}
            <FormRow label={t('new_password.label')}>
              <PasswordField.Control
                fullWidth
                generate
                newPassword
                optional
                name="new_password"
                rules={optionalPassword ? { passwordOptional } : { validate: password }}
                placeholder={t('new_password.placeholder')}
              />
            </FormRow>
          </Stack>
        </>
      )}

      {me?.role === ROLE.ADMIN && (
        <>
          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('individual_permissions')}
            </Typography>
          </FormTitle>
          <Stack mt={2} width="100%">
            {Object.entries(TEACHER_PERMISSIONS).map(([name]) => (
              <FormRow key={name} label={t(`permissions.${name}`)}>
                <Switch.Control fullWidth name={name} />
              </FormRow>
            ))}
          </Stack>
        </>
      )}
    </>
  );
};

export default IndividualSettingsForm;
