import { useCallback } from 'react';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getArray, capitalize } from 'utils/helpers';
import { Center, Tag } from 'components';
import { LevelTag } from 'views';

const LevelsCount = styled(Tag)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.input.main,
}));

const LevelHolder = styled(Center)({
  justifyContent: 'flex-start',
});

const LevelFilter = (props) => {
  const { value = [], total, levels, onValue } = props;

  const { t } = useTranslation('views', { keyPrefix: 'LevelFilter' });

  const handleChange = useCallback(
    (level) => {
      if (typeof onValue !== 'function') return;

      const foundIndex = value.findIndex((item) => item.id === level.id);
      if (foundIndex === -1) {
        onValue([...value, level]);
      } else {
        const changed = [...value];
        changed.splice(foundIndex, 1);
        onValue(changed);
      }
    },
    [value, onValue],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Center mb={1} justifyContent="space-between">
        <Typography color="textBlack.light">{t('title')}</Typography>
        {total > 0 && <LevelsCount size="small" label={total} />}
      </Center>

      <Stack mt={1}>
        {getArray(levels).map((level, index) => (
          <LevelHolder mb={1} key={`level-filter-${level.id}-${index}`}>
            <LevelTag
              level={level.id}
              size="medium"
              clickable
              border
              label={capitalize(level.name)}
              onClick={() => handleChange(level)}
            />
            {level.qty > 0 && (
              <Typography ml={1} variant="caption1" color="textBlack.light">
                ({level.qty})
              </Typography>
            )}
          </LevelHolder>
        ))}
      </Stack>
    </Box>
  );
};

export default LevelFilter;
