import { forwardRef, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import { useMixRules, useRules } from 'hooks';
import MaskField from 'components/MaskField';

/**
 * Input for the phone number. All the props equals to <Input /> component props with little
 * changes:
 * @prop {function} [onChange] - Callback with next args: arg[0] - event object, arg[1] - unmasked
 * phone number value.
 * @prop {function} [onValue] - arg[0] - unmasked phone number value.
 */
const PhoneField = forwardRef((props, ref) => {
  const { onChange, onValue, value, ...rest } = props;

  const handleChange = useCallback(
    (e) => {
      const v = e.target.value.replace(/[^\d]/g, '');
      typeof onChange === 'function' && onChange(e, v);
      typeof onValue === 'function' && onValue(v);
    },
    [onChange, onValue],
  );

  return (
    <MaskField
      ref={ref}
      {...rest}
      value={value}
      type="tel"
      onChange={handleChange}
      mask={value && +value[0] === 1 ? '+9-999-999-9999' : '+999-999-9999'}
    />
  );
});

PhoneField.Control = forwardRef((props, ref) => {
  const { name, defaultValue = '', inputRef, onValue, onBlur, rules: initRules, ...rest } = props;

  const { control, trigger } = useFormContext();
  const { phone } = useRules();
  const rules = useMixRules(initRules, phone);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error, isTouched } = fieldState;
        const {
          value,
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (v) => {
          if (v === value) {
            // handle extra event
            return;
          }
          controlOnChange(v);
          typeof onValue === 'function' && onValue(v);
        };
        const handleOnBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
        };
        return (
          <PhoneField
            ref={ref}
            value={value}
            onValue={handleChange}
            onBlur={handleOnBlur}
            error={isTouched && (error?.message || !!error)}
            inputRef={propagateRefs(inputRef, controlRef)}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box>
        <Box component="pre" mt={1}>
          {`<PhoneField\n`}
          {`  value={value}\n`}
          {`  onValue={setValue}\n`}
          {`  label="Phone"\n`}
          {`  iconLeft="Phone"\n`}
          {`  placeholder="e.x +380 (980) 070 365"\n`}
          {`/>\n`}
        </Box>

        <Box p={2}>
          <PhoneField label="Phone" iconLeft="Phone" placeholder="e.x +380 (980) 070 365" />
        </Box>
      </Box>
    );
  };
  PhoneField.Demo = Demo;
}

export default PhoneField;
