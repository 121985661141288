import { Stack as MuiStack, Typography, styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { allowedImageTypes } from 'utils/constants';
import { withProps, useRules, useGroupSearch, useMessage } from 'hooks';
import { useGenerateNicknameMutation } from 'store/api';

import {
  Form,
  Input,
  FormRow,
  Dropzone,
  PhoneField,
  SelectMultiple,
  Icon,
  Tag,
  FormTitle,
  Button,
  BirthdayInput,
} from 'components';

import { LanguageSelect } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const GenerateButton = styled(Button, { label: 'GenerateButton' })(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: 152,
  '& .MuiTypography-root': {
    paddingRight: theme.spacing(0.5),
  },
}));

const isGroupLeader = (groups, groupId, studentId) => {
  if (!studentId || !groups?.length) return false;

  const foundIndex = groups.findIndex((g) => g.value === groupId && g?.leader_id === studentId);
  return foundIndex !== -1;
};

const StudentPersonalInformationForm = (props) => {
  const { id, studentId, me, form, visible, onSubmit } = props;

  const { t } = useTranslation('views', { keyPrefix: 'StudentPersonalInformationForm' });
  const { required, phone } = useRules();
  const m = useMessage();

  const [generateNickname, generateNicknameState] = useGenerateNicknameMutation();

  const {
    options: groupOptions,
    search: groupSearch,
    onSearch: onGroupSearch,
  } = useGroupSearch({
    spaceId: me?.space_id,
    disabled: !me?.space_id,
    mapToOptions: true,
  });

  const renderGroupTag = useCallback(
    ({ option, index, tagProps, onDelete }) => {
      return (
        <Tag
          key={`${option.label}-${index}`}
          label={option.label}
          clickable
          color="textBlack.light"
          bgcolor="input.main"
          size="medium"
          onClick={(...args) => {
            if (isGroupLeader(groupOptions, option.value, studentId)) {
              return m.error(t('user_is_leader'));
            }

            typeof onDelete === 'function' && onDelete(...args);
          }}
          startAdornment={<Icon name="Group" />}
          endAdornment={<Icon color="error" name="Close" />}
          px={1}
          {...tagProps}
        />
      );
    },
    [groupOptions, studentId, m, t],
  );

  return (
    <Form id={id} form={form} onSubmit={onSubmit}>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('personal_information')}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        <FormRow label={t('avatar_file_id.label')} alignItems="flex-start" optional>
          <Dropzone.Control
            fullWidth
            accept={allowedImageTypes}
            name="avatar_file_id"
            onChange={() => {
              if (!form) return;
              form.setValue('cached_avatar_id', null);
            }}
          />
        </FormRow>

        <FormRow label={t('username.label')}>
          <Box display="flex">
            <Input.Control
              fullWidth
              name="username"
              initFocus={visible}
              placeholder={t('username.placeholder')}
              rules={{ required }}
            />

            <GenerateButton
              type="button"
              iconLeft="Generate"
              variant="outlined"
              ml={2}
              onClick={async () => {
                try {
                  const { name } = await generateNickname().unwrap();
                  form.setValue('username', name);
                  form.trigger('username');
                } catch (e) {
                  console.error(e);
                }
              }}
              disabled={generateNicknameState.isLoading}
            >
              {t('generate_random')}
            </GenerateButton>
          </Box>
        </FormRow>

        <FormRow label={t('first_name.label')}>
          <Input.Control
            fullWidth
            name="first_name"
            placeholder={t('first_name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow label={t('last_name.label')}>
          <Input.Control
            fullWidth
            name="last_name"
            placeholder={t('last_name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow label={t('birthday.label')}>
          <BirthdayInput.Control
            fullWidth
            name="birthday"
            placeholders={{
              day: t('birthday.day'),
              month: t('birthday.month'),
              year: t('birthday.year'),
            }}
          />
        </FormRow>

        <FormRow label={t('phone.label')}>
          <PhoneField.Control
            optional
            fullWidth
            name="phone_number"
            placeholder={t('phone.placeholder')}
            rules={{
              required,
              phone,
            }}
          />
        </FormRow>

        <FormRow label={t('language.label')}>
          <LanguageSelect.Control
            fullWidth
            name="language"
            placeholder={t('language.placeholder')}
          />
        </FormRow>
      </Stack>

      {studentId && (
        <>
          <FormTitle mt={2} width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('groups_classes')}
            </Typography>
          </FormTitle>
          <Stack mt={2} width="100%">
            <FormRow label={t('groups.label')} alignItems="flex-start">
              <SelectMultiple.Control
                fullWidth
                chips={false}
                name="groups"
                placeholder={t('groups.placeholder')}
                options={groupOptions}
                inputValue={groupSearch}
                onInputChange={onGroupSearch}
                renderPreviewItem={renderGroupTag}
              />
            </FormRow>
          </Stack>
        </>
      )}
    </Form>
  );
};

export default StudentPersonalInformationForm;
