import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

import { useAuth, withProps } from 'hooks';
import { Avatar, Button, DropdownMenu, Icon } from 'components';

const Label = withProps(Stack, {
  spacing: 1,
  direction: 'row',
  alignItems: 'center',
});

const keyPrefix = 'header';

const ProfileButton = (props) => {
  const { me } = props;
  const { t } = useTranslation('views', { keyPrefix });
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const abbr = useMemo(() => {
    const result = [];
    const [firstName, lastName] = (me?.profile?.name || '').split(' ');

    if (typeof firstName === 'string' && firstName) {
      result.push(firstName[0].toUpperCase());
    }
    if (typeof lastName === 'string' && lastName) {
      result.push(lastName[0].toUpperCase());
    }
    return result.join('');
  }, [me]);

  const options = useMemo(
    () => [
      {
        onClick: () => navigate('/profile'),
        value: 'profile',
        label: (
          <Label>
            <Avatar size={16} border={false} src={me?.profile?.avatar} children={abbr} />
            <span>{t('profile')}</span>
          </Label>
        ),
      },
      {
        onClick: () => navigate('/settings'),
        value: 'settings',
        label: (
          <Label>
            <Icon name="Setting" />
            <span>{t('settings')}</span>
          </Label>
        ),
      },
    ],
    [t, abbr, me, navigate],
  );

  return (
    <DropdownMenu
      minWidth={200}
      options={options}
      itemsSize="medium"
      anchorOrigin="bottomRight"
      transformOrigin="topRight"
      target={
        <Button
          variant="outlined"
          iconLeft={
            <Avatar badge radius={2} size="xsmall" src={me?.profile?.avatar} children={abbr} />
          }
          iconRight={<Icon.ArrowDown fontSize={14} sx={{ mr: 0.5 }} />}
        >
          {me?.profile?.name}
        </Button>
      }
      footer={
        <Button
          fullWidth
          radius={2}
          color="error"
          size="xsmall"
          variant="outlined"
          onClick={handleSignOut}
        >
          {t('signout')}
        </Button>
      }
    />
  );
};

export default ProfileButton;
