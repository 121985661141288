import { useMemo } from 'react';
import { styled, AvatarGroup as MuiAvatarGroup } from '@mui/material';
import Avatar, { sizeScheme } from 'components/Avatar';

const StyledAvatarGroup = styled(MuiAvatarGroup)(({ size, theme, max }) => ({
  marginLeft: 4,
  '& .MuiAvatar-root': {
    width: size,
    height: size,
    fontSize: `${size / 2}px`,
    borderWidth: 1,
    marginLeft: -4,
    color: theme.palette.textBlack.light,
    backgroundColor: theme.palette.controls.main,
  },
  '& .MuiAvatar-colorDefault': {
    zIndex: max,
  },
}));

const AvatarGroup = (props) => {
  const { size, max, avatars, children, ...rest } = props;

  const sizing = useMemo(() => {
    return {
      width: typeof size === 'string' ? sizeScheme[size] : size,
      height: typeof size === 'string' ? sizeScheme[size] : size,
    };
  }, [size]);

  const content = useMemo(() => {
    if (Array.isArray(avatars)) {
      return avatars.map((avatar, index) => (
        <Avatar sx={{ zIndex: index + 1 }} key={avatar.id} size={size} circle src={avatar.src} />
      ));
    }
    return children;
  }, [avatars, children, size]);

  return (
    <StyledAvatarGroup size={sizing.width} max={max} {...rest}>
      {content}
    </StyledAvatarGroup>
  );
};

export default AvatarGroup;
