import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, FormControlLabel, Checkbox as MuiCheckbox, styled } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';
import { Icon, FormLabel } from 'components';
import Fieldset from 'components/Dev/Fieldset';
import { propagateRefs } from 'utils/helpers';
import { useCombineRefs } from 'hooks';

const StyledCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  padding: 0,
  color: theme.palette.controls.main,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  borderRadius: '7px',
  border: '4px solid transparent',
  backgroundColor: 'transparent',
  '&:hover': {
    color: theme.palette.controls.hover,
  },

  '&.Mui-checked:not(.Mui-disabled)': {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },

  '&.Mui-disabled': {
    color: theme.palette.controls.disabled,
  },

  '&.Mui-focusVisible': {
    borderColor: theme.palette.chip.main,
    backgroundColor: theme.palette.chip.main,
  },
}));

/**
 * @prop {string|number} [size="small"] - The same as Icon component "fontSize" property.
 * @prop {ReactNode} [label] - Checkbox label
 * @prop {boolean} [initFocus] - Set initial focus (works on each changing from falsy value to true)
 * All the other props will be passed to the MUI Checkbox element
 */
const Checkbox = forwardRef((props, ref) => {
  const {
    label: initLabel,
    labelVariant,
    onChange,
    onValue,
    disabled,
    size = 'small',
    fullWidth,
    initFocus,
    inputRef,
    ...rest
  } = props;

  const innerInputRef = useRef(null);
  const handleInputRef = useCombineRefs(inputRef, innerInputRef);

  const handleChange = useCallback((e) => {
    typeof onChange === 'function' && onChange(e);
    typeof onValue === 'function' && onValue(e.target.checked);
  }, [onChange, onValue]);

  const label = useMemo(() => {
    if (!initLabel) {
      return null;
    }
    return (
      <FormLabel variant={labelVariant} ml={0.5} disabled={disabled}>
        {initLabel}
      </FormLabel>
    );
  }, [initLabel, labelVariant, disabled]);

  useEffect(() => {
    const { current: input } = innerInputRef;

    if (input && initFocus) {
      input.focus();
    }
  }, [initFocus]);

  return (
    <FormControlLabel
      sx={{ width: fullWidth ? '100%' : undefined }}
      ref={ref}
      label={label}
      disabled={disabled}
      control={
        <StyledCheckbox
          {...rest}
          disableRipple
          color="primary"
          disabled={disabled}
          onChange={handleChange}
          inputRef={handleInputRef}
          icon={
            <Icon.CheckboxEmpty fontSize={size} />
          }
          checkedIcon={
            <Icon.CheckboxChecked fontSize={size} />
          }
        />
      }
    />
  );
});

Checkbox.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, inputRef, onChange, onBlur, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue || false}
      render={({ field }) => {
        const {
          value,
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <Checkbox
            ref={ref}
            inputRef={propagateRefs(inputRef, controlRef)}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={value}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [size, setSize] = useState('small');
    const [label, setLabel] = useState('Agree with terms of use');
    const [disabled, setDisabled] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'xsmall', 'small', 'medium', 'large', 22]}
          />
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Agree with terms of use']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre" p={2}>
          {`<Checkbox\n`}
          {`  size="${size}"\n`}
          {`  label={${label}}\n`}
          {`  disabled={${disabled}}\n`}
          {`/>\n`}
        </Box>

        <Checkbox
          size={size}
          label={label}
          disabled={disabled}
        />
      </Box>
    );
  };
  Checkbox.Demo = Demo;
}

export default Checkbox;
