import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetMeQuery, useGetGroupsWithLeadersQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray } from 'utils/helpers';

import { CardContent } from 'views';
import { ProgressBox, Pagination, PaperCard } from 'components';

const { selectPageFilters, selectPagination } = selectors;

const mapRequest = (pageFilters, pagination) => {
  const { search, activeFilters } = pageFilters;

  const { leaders } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    name: search || undefined,
    leaders: getArray(leaders).map((leader) => leader.id),
    groups: [],
    limit: perPage,
    offset: offset,
  };
};

const Groups = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'administration' });
  const { auth, progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: groups,
    isLoading: groupsLoading,
    isFetching: groupsFetching,
  } = useGetGroupsWithLeadersQuery(
    {
      spaceId: me?.space_id,
      ...mapRequest(pageFilters, pagination),
    },
    { skip: !me?.space_id },
  );

  useEffect(() => {
    if (!groups) return;
    onSetFilter('leaders', FILTER_TYPE.USERS, { users: groups.leaders, total: groups.leaders_qty });
  }, [onSetFilter, groups, t]);

  const loading = isLoading || isFetching || progress || groupsLoading || groupsFetching;

  return (
    <Box>
      <ProgressBox loading={loading}>
        <Grid container spacing={3}>
          {getArray(groups?.groups).map((group) => (
            <Grid key={`groups-grid-${group.id}`} item xs={6}>
              <PaperCard to={`/administration/groups/${group.id}`}>
                <CardContent
                  variant="group"
                  location={group.address}
                  participants={group.participants}
                  leader={group.leader}
                  name={group.name}
                  schedule={group.schedule}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </ProgressBox>

      <Pagination
        total={groups?.groups_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </Box>
  );
};

export default Groups;
