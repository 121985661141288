import { useMemo, useCallback, useState } from 'react';
import { styled, Typography, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS, ROLE } from 'utils/constants';
import { capitalize, formatDate, getArray } from 'utils/helpers';
import { withProps, useAuth, useMessage, useAcl } from 'hooks';
import { useGetMeQuery, useGetChildProjectQuery, useDeleteChildProjectMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, Gallery, Icon, IconButton } from 'components';
import {
  ArticleGeneralInformation,
  PageSubheader,
  Steps,
  Workflow,
  StudentWork,
  StudentWorkModal,
  AddStudentVideoModal,
} from 'views';
import MetaData from 'views/ArticleGeneralInformation/MetaData';

import useUpdateProcessPhotos from './hooks/useUpdateProcessPhotos.hook';

import ProjectStatus from './ProjectStatus';
import ProgressUpdateSuccessModal from './ProgressUpdateSuccessModal';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const ChildProject = (props) => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'single_project' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();
  const acl = useAcl();

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [progressModalOpen, setProgressModalOpen] = useState(false);
  const [progressSuccessModalOpen, setProgressSuccessModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState(null);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: project,
    isLoading,
    isFetching,
  } = useGetChildProjectQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const [deleteProject, deleteProjectState] = useDeleteChildProjectMutation();

  const admin = me?.role === ROLE.ADMIN;
  const closeStudentWorkModal = () => {
    setVideoModalOpen(false);
    setProgressModalOpen(false);
    setSelectedWork(null);
  };

  const closeProgressSuccessModal = () => {
    setProgressSuccessModalOpen(false);
  };

  const {
    loading: updatingProcess,
    handeAddPhotoOfProcess,
    handleAddStudentVideo,
    handleDeletePhotoOfProcess,
  } = useUpdateProcessPhotos({
    project,
    onSuccess: () => {
      closeStudentWorkModal();

      if (!admin) setProgressSuccessModalOpen(true);
    },
  });

  const handleDeleteProject = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteProject({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/learning/projects');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteProject, navigate, id, t, m]);

  const onRequestAddStudentWork = (e) => {
    e.preventDefault();
    setProgressModalOpen(true);
  };

  const onRequestAddVideo = (e) => {
    e.preventDefault();
    setVideoModalOpen(true);
  };

  const onRequestEditStudentWork = (work, index) => {
    setProgressModalOpen(true);
    setSelectedWork({ ...work, index });
  };

  const tags = useMemo(() => {
    return getArray(project?.tags).map((tag) => ({ label: tag.name, id: tag.id }));
  }, [project]);

  const photos = useMemo(() => {
    return getArray(project?.photos).map((photo) => ({ src: photo }));
  }, [project]);

  const withPhotos = project?.photos?.length > 0;
  const withSteps = project?.steps?.length > 0;
  const loading = isLoading || isFetching || deleteProjectState.isLoading;

  const pageActions = {
    [t('page_actions.delete')]: {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeleteProject,
      aclConfig: { permissions: [PERMISSIONS.DELETE_PROJECT] },
    },
  };

  return (
    <ProjectLayout
      subheader={
        project && (
          <PageSubheader
            title={project.name}
            backUrl="/learning/projects"
            backTitle={t('back_button')}
            pageActions={pageActions}
          />
        )
      }
    >
      <Article py={4}>
        <Container>
          {!loading && project && (
            <ArticleGeneralInformation
              slug="Project"
              author={project.author}
              description={project.description}
              level={project.level}
              tags={tags}
              sources={project.sources}
              dateCreated={formatDate(project.date_created)}
              customMetaData={
                <MetaData>
                  <MetaData.Text
                    title={t('project_type')}
                    text={
                      <Center>
                        <Icon name={project.project_type === 'individual' ? 'User' : 'Group'} />
                        <Box ml={0.5} component="span">
                          {capitalize(project?.project_type)}
                        </Box>
                      </Center>
                    }
                  />
                  {acl.allow({ permissions: [PERMISSIONS.EDIT_PROJECT_STATUS] }) && (
                    <ProjectStatus admin={admin} projectId={id} />
                  )}
                </MetaData>
              }
            />
          )}

          {withPhotos && (
            <>
              <Center my={2} justifyContent="flex-start">
                <Center width={24} height={24}>
                  <Icon name="Image" />
                </Center>
                <Typography ml={0.5} variant="body2" color="textBlack.dark">
                  {t('gallery')}
                </Typography>
              </Center>
              <Gallery images={photos} />
            </>
          )}

          {withSteps && (
            <>
              <Typography variant="subtitle3" color="textBlack.dark">
                {t('project_steps')}
              </Typography>
              <Steps mt={2} steps={project?.steps} />
            </>
          )}

          <Box mb={4}>
            <Typography variant="subtitle3" color="textBlack.dark">
              {t('student_work')}
            </Typography>
            <Center mt={1.5} mb={0.5} justifyContent="flex-start">
              <Center justifyContent="flex-start" width={24}>
                <Icon name="Image" fontSize="xsmall" />
              </Center>
              <Typography ml={0.5} variant="body2" color="textBlack.dark">
                {t('photos_of_process')}
              </Typography>
            </Center>

            <StudentWork
              pl={3}
              admin={admin}
              studentWorks={project?.student_works}
              onRequestEditStudentWork={onRequestEditStudentWork}
              onRequestAddStudentWork={onRequestAddStudentWork}
              onRequestAddVideo={onRequestAddVideo}
            />
          </Box>

          {project?.workflow?.length > 0 ? <Workflow data={project?.workflow} mb={4} /> : undefined}
        </Container>
      </Article>

      <AddStudentVideoModal
        loading={updatingProcess}
        open={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        onSubmit={handleAddStudentVideo}
      />
      <StudentWorkModal
        loading={updatingProcess}
        open={progressModalOpen}
        initialData={selectedWork}
        onClose={closeStudentWorkModal}
        onDelete={() => {
          if (!selectedWork) return;
          handleDeletePhotoOfProcess(selectedWork?.index);
        }}
        onSubmit={handeAddPhotoOfProcess}
      />

      <ProgressUpdateSuccessModal
        open={progressSuccessModalOpen}
        onClose={closeProgressSuccessModal}
        onRequestAddStudentWork={onRequestAddStudentWork}
      />
    </ProjectLayout>
  );
};

export default ChildProject;
