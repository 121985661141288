import { useCallback } from 'react';

import { useCreateTagMutation } from 'store/api';

import { useMessage } from 'hooks';

const useCreateTag = (props) => {
  const { me } = props || {};

  const m = useMessage();
  const [createTag, createTagState] = useCreateTagMutation();

  const handleCreateTag = useCallback(
    async (option, onOptionCreated) => {
      if (!option?.label) return;

      try {
        const response = await createTag({ name: option.label, spaceId: me?.space_id }).unwrap();
        const { id, name } = response;

        if (id) {
          onOptionCreated({ label: name, value: id });
        }
      } catch (err) {
        if (err?.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        }
      }
    },
    [createTag, me, m],
  );

  return [handleCreateTag, createTagState];
};

export default useCreateTag;
