import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Collapse, Box } from '@mui/material';

import { getArray } from 'utils/helpers';

import {
  useGetMeQuery,
  useCreatePortfolioMutation,
  useUploadTemporaryFileMutation,
} from 'store/api';
import { useForm, useMessage, useAuth, useUploadPortfolioAvatars } from 'hooks';

import { ProjectLayout } from 'layouts';
import {
  PageSubheader,
  PortfolioGroupSearchForm,
  PortfolioGroupProjectSearchForm,
  PortfolioPersonalInformationForm,
  PortfolioEditProjectsForm,
} from 'views';
import { Container, Tabs, Paper, Stepper } from 'components';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

import mapRequest from './mapRequest';

const contentWidth = 936;

const FORMS = {
  GROUPS_SEARCH: 'groups',
  PROJECTS_SEARCH: 'projects',
  PROJECTS_EDIT: 'edit_projects',
  ABOUT_ME: 'about_me',
};

const STEPS = [
  { id: FORMS.GROUPS_SEARCH },
  { id: FORMS.PROJECTS_SEARCH },
  { id: FORMS.PROJECTS_EDIT },
  { id: FORMS.ABOUT_ME },
];

const NewPortfolio = (props) => {
  const { redirectPath = '/portfolio', backUrl = '/portfolio' } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'new_portfolio' });
  const { auth, progress } = useAuth();
  const { state } = useLocation();
  const m = useMessage();
  const navigate = useNavigate();

  const { form: groupSearchForm, valid: groupSearchFormValid } = useForm();
  const { form: projectSearchForm, valid: projectSearchFormValid } = useForm();
  const { form: projectsEditForm, valid: projectsEditFormValid } = useForm();
  const { form: aboutMeForm, valid: aboutMeFormValid } = useForm();

  const watchGroups = groupSearchForm.watch('groups');

  const [step, setStep] = useState(0);
  const [groupTab, setGroupTab] = useState(null);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  useEffect(() => {}, [state]);

  useEffect(() => {
    function resetState() {
      window.history.replaceState({}, document.title);
    }
    window.addEventListener('beforeunload', () => resetState);
    return () => {
      resetState();
      window.removeEventListener('beforeunload', resetState);
    };
  }, []);

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [uploadProjectAvatars, uploadProjectAvatarsState] = useUploadPortfolioAvatars();
  const [createPortfolio, createPortfolioState] = useCreatePortfolioMutation();

  const changeGroup = (v) => {
    setGroupTab(groupOptions.find((g) => g.value === v) || null);
  };

  const handleCreatePortfolio = useCallback(
    async (formData) => {
      try {
        const postData = mapRequest({
          about: formData,
          groups: projectsEditForm.getValues().groups,
          me,
        });

        const { photo } = formData;
        const [file] = photo || [];

        if (file instanceof File) {
          const { file_id } = await uploadFile({ file }).unwrap();
          postData.photo = file_id;
        }

        postData.project_cards = await uploadProjectAvatars(postData.project_cards);

        const { portfolio_id } = await createPortfolio(postData).unwrap();

        if (portfolio_id) {
          navigate(`${redirectPath}/${portfolio_id}`);
        } else {
          m.error(t('error_failed_create'));
        }
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_create'));
        }
      }
    },
    [
      me,
      uploadFile,
      uploadProjectAvatars,
      projectsEditForm,
      createPortfolio,
      navigate,
      m,
      t,
      redirectPath,
    ],
  );

  const groupOptions = useMemo(() => {
    return getArray(watchGroups).map((group) => ({
      ...group,
      icon: 'Group',
    }));
  }, [watchGroups]);

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadFileState.isLoading ||
    uploadProjectAvatarsState.isLoading ||
    createPortfolioState.isLoading;

  const submitGroupsSearch = useCallback(
    (formData) => {
      projectSearchForm.setValue('groups', formData.groups);
      setGroupTab(formData.groups[0]);
      setStep((curr) => curr + 1);
    },
    [setStep, setGroupTab, projectSearchForm],
  );

  const submitProjectsSearch = useCallback(
    (formData) => {
      projectsEditForm.setValue(
        'groups',
        formData.groups.map((group) => {
          return {
            ...group,
            selected: group.selected.map((project) => ({
              ...project,
              description:
                typeof project.description === 'string'
                  ? getInitialTextEditorState(project.description)
                  : project.description,
            })),
          };
        }),
      );
      setGroupTab(formData.groups[0]);
      setStep((curr) => curr + 1);
    },
    [setStep, setGroupTab, projectsEditForm],
  );

  const submitProjectsEdit = useCallback(() => {
    setStep((curr) => curr + 1);
  }, [setStep]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const submitFn = [
          submitGroupsSearch,
          submitProjectsSearch,
          submitProjectsEdit,
          handleCreatePortfolio,
        ][step];

        if (!submitFn) return;

        submitFn(formData);
      } catch (err) {}
    },
    [step, submitGroupsSearch, submitProjectsSearch, submitProjectsEdit, handleCreatePortfolio],
  );

  const isLastStep = STEPS.length - 1 === step;

  const stepsWithLabels = useMemo(
    () => STEPS.map((s) => ({ ...s, label: t(`steps.${s.id}`) })),
    [t],
  );

  const isValidStep = {
    [FORMS.GROUPS_SEARCH]: groupSearchFormValid,
    [FORMS.PROJECTS_SEARCH]: projectSearchFormValid,
    [FORMS.PROJECTS_EDIT]: projectsEditFormValid,
    [FORMS.ABOUT_ME]: aboutMeFormValid,
  }[STEPS[step]?.id];

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={backUrl}
          backTitle={t('back_button')}
          pageActions={{
            [t('page_actions.previous_step')]: {
              type: 'button',
              disabled: loading || step === 0,
              variant: 'outlined',
              size: 'small',
              iconLeft: 'ArrowLeft',
              radius: 2,
              onClick: () => setStep(step - 1),
            },
            ...(isLastStep
              ? {
                  [t('page_actions.create')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    form: STEPS[step]?.id,
                  },
                }
              : {
                  [t('page_actions.next_step')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    iconRight: 'ArrowRight',
                    form: STEPS[step]?.id,
                  },
                }),
          }}
        />
      }
    >
      <Container width={contentWidth} py={4} position="relative">
        <Stepper steps={stepsWithLabels} currentStep={step} />

        {STEPS[step] && (
          <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
            {t(`page_${STEPS[step].id}_title`)}
          </Typography>
        )}

        <Collapse sx={{ width: '100%' }} in={step === 0}>
          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <PortfolioGroupSearchForm
              id={FORMS.GROUPS_SEARCH}
              form={groupSearchForm}
              me={me}
              onSubmit={handleSubmit}
            />
          </Paper>
        </Collapse>

        {watchGroups?.length > 0 && [1, 2].includes(step) && state?.source !== 'own_projects' ? (
          <Box mb={2}>
            <Tabs value={groupTab?.value || null} onValue={changeGroup} options={groupOptions} />
          </Box>
        ) : null}
        <Collapse sx={{ width: '100%' }} in={step === 1}>
          <PortfolioGroupProjectSearchForm
            id={FORMS.PROJECTS_SEARCH}
            form={projectSearchForm}
            onSubmit={handleSubmit}
            activeGroup={groupTab}
          />
        </Collapse>
        <Collapse sx={{ width: '100%' }} in={step === 2}>
          <PortfolioEditProjectsForm
            id={FORMS.PROJECTS_EDIT}
            form={projectsEditForm}
            onSubmit={handleSubmit}
            activeGroup={groupTab}
          />
        </Collapse>
        <Collapse sx={{ width: '100%' }} in={step === 3}>
          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <PortfolioPersonalInformationForm
              id={FORMS.ABOUT_ME}
              form={aboutMeForm}
              onSubmit={handleSubmit}
              visible={step === 3}
            />
          </Paper>
        </Collapse>
      </Container>
    </ProjectLayout>
  );
};

export default NewPortfolio;
