import { useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PERMISSIONS } from 'utils/constants';
import { getArray } from 'utils/helpers';
import { useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter, useAcl } from 'hooks';

import { ProjectLayout } from 'layouts';
import { Center, Tabs, Icon, Input, Button, DropdownMenu } from 'components';
import { FiltersBar, SidebarFilters } from 'views';

import Users from './Users';
import Groups from './Groups';
import AdminAccounts from './AdminAccounts';

const { selectFilters, selectActiveFilters } = selectors;

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const ActionsRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Administration = () => {
  const acl = useAcl();
  const navigate = useNavigate();
  const path = useMatch(`/administration/:slug`);
  const tab = path?.params?.slug || 'users';

  const { t } = useTranslation('pages', { keyPrefix: 'administration' });
  const { auth } = useAuth();
  const { onSearch, onResetActiveFilters, tempSearch, onSetActiveFilter, onResetPageFilter } =
    usePageFilter();

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const tabOptions = useMemo(() => {
    const opts = [
      {
        value: 'users',
        label: t('users'),
        icon: 'User',
        width: 192,
      },
      {
        value: 'groups',
        label: t('groups'),
        icon: 'Group',
        width: 192,
      },
    ];

    if (acl.allow({ permissions: [PERMISSIONS.SUPER_ADMIN] })) {
      opts.push({
        value: 'spaces',
        label: t('spaces'),
        icon: 'Admin',
        width: 192,
      });
    }

    return opts;
  }, [t, acl]);

  const handleTabValue = useCallback(
    (value) => {
      navigate(`/administration/${value}`, { replace: false });
    },
    [navigate],
  );

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter, tab]);

  useEffect(() => {
    if (!tab) {
      navigate(`/administration/users`, { replace: false });
    }
  }, [tab, navigate]);

  const pageActions = useMemo(() => {
    const actions = [];

    if (acl.allow({ permissions: [PERMISSIONS.CREATE_USER] })) {
      actions.push({
        pl: 1,
        startAdornment: <Icon name="User" />,
        label: t('subheader.create_user'),
        value: 'create_user',
        onClick: () => {
          if (me) navigate('/administration/users/new');
        },
      });
    }

    if (acl.allow({ permissions: [PERMISSIONS.CREATE_GROUP] })) {
      actions.push({
        pl: 1,
        startAdornment: <Icon name="Group" />,
        label: t('subheader.create_group'),
        value: 'create_group',
        onClick: () => {
          if (me) navigate('/administration/groups/new');
        },
      });
    }

    return actions;
  }, [acl, me, navigate, t]);

  return (
    <ProjectLayout
      subheader={
        <Center flexGrow={1}>
          <PageTitle>
            <Typography variant="subtitle2">{t('subheader.title')}</Typography>
          </PageTitle>
          <Grid container width="100%" mx={0} spacing={3}>
            <Grid item xs={6}>
              {tab !== 'spaces' && (
                <Input
                  value={tempSearch}
                  onValue={onSearch}
                  iconLeft="Search"
                  fullWidth={true}
                  placeholder={t('subheader.page_search')}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {pageActions.length > 0 && (
                <ActionsRoot>
                  <DropdownMenu
                    minWidth={200}
                    target={<Button iconRight="ArrowDown">{t('subheader.create_new')}</Button>}
                    options={pageActions}
                  />
                </ActionsRoot>
              )}
            </Grid>
          </Grid>
        </Center>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Box p={4} pl={3}>
        <FiltersBar
          filters={activeFilters}
          mb={3}
          onRemove={handleRemoveSelectedFilters}
          onClear={onResetActiveFilters}
        />

        <Center pb={3} justifyContent="space-between">
          <Tabs
            sx={{ backgroundColor: 'other.white' }}
            value={tab}
            variant="standard"
            options={tabOptions}
            onValue={handleTabValue}
          />
        </Center>

        {tab === 'users' && <Users me={me} />}
        {tab === 'groups' && <Groups me={me} />}
        {tab === 'spaces' && <AdminAccounts me={me} />}
      </Box>
    </ProjectLayout>
  );
};

export default Administration;
