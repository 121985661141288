import { useCallback } from 'react';
import { Navigate, useMatch, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ROLE, PERMISSIONS } from 'utils/constants';
import { useAuth } from 'hooks';
import { useGetMeQuery, useGetPortfoliosQuery } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Tabs, Container } from 'components';
import { PageSubheader } from 'views';

import UserTab from './UserTab';
import PortfolioTab from './PortfolioTab';

const Profile = () => {
  const { id = 'me' } = useParams();
  const navigate = useNavigate();
  const userPath = useMatch(`/profile/${id}`);
  const portfolioPath = useMatch(`/profile/${id}/portfolio`);
  const { t } = useTranslation('pages', { keyPrefix: 'profile' });
  const { auth } = useAuth();

  const tab = portfolioPath ? `${id}/portfolio` : id;

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: portfolios,
    isLoading,
    isFetching,
  } = useGetPortfoliosQuery(
    {
      spaceId: me?.space_id,
      users: [me?.id],
      groups: [],
      name: null,
      limit: 10,
      offset: 0,
    },
    { skip: !me?.space_id || !me?.id },
  );

  const handleTabChange = useCallback(
    (v) => {
      navigate(`/profile/${v}`);
    },
    [navigate],
  );

  if (!userPath && !portfolioPath) {
    return <Navigate to="/profile/me" />;
  }

  if (id !== 'me' && me && me?.role !== ROLE.ADMIN) {
    return <Navigate to={`/profile/me`} />;
  }

  const loading = isLoading || isFetching;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={t('page_title')}
          pageActions={
            portfolios?.portfolios_qty > 0 && portfolioPath
              ? {
                  [t('portfolio.create_portfolio')]: {
                    to: '/profile/portfolio/new',
                    size: 'small',
                    radius: 2,
                    aclConfig: { permissions: [PERMISSIONS.CREATE_PORTFOLIO] },
                  },
                }
              : undefined
          }
        />
      }
    >
      <Container>
        <Box py={4}>
          {me?.role !== ROLE.PARENT && (
            <Tabs
              width={396}
              variant="fullWidth"
              value={tab}
              onValue={handleTabChange}
              options={[
                {
                  label: t('tabs.user_information'),
                  value: id,
                  icon: 'User',
                },
                {
                  label: t('tabs.portfolio'),
                  value: `${id}/portfolio`,
                  icon: 'Collection',
                },
              ]}
            />
          )}

          {tab === id && <UserTab id={id} />}
          {me?.role !== ROLE.PARENT && tab === `${id}/portfolio` && (
            <PortfolioTab portfolios={portfolios?.portfolios} loading={loading} />
          )}
        </Box>
      </Container>
    </ProjectLayout>
  );
};

export default Profile;
