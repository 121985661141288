import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useForm, useMessage, useAuth, useUploadPhotos } from 'hooks';
import { getArray } from 'utils/helpers';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper } from 'components';
import { PageSubheader, QuestionForm } from 'views';

import {
  useGetMeQuery,
  useCreateQuestionMutation,
  useCreateTagMutation,
  useGetTagsQuery,
} from 'store/api';

import mapRequest from './mapRequest';

const contentWidth = 936;

const NewQuestion = () => {
  const m = useMessage();
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'new_question' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createQuestion, createQuestionState] = useCreateQuestionMutation();
  const [createTag, createTagState] = useCreateTagMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    createQuestionState.isLoading ||
    uploadPhotosState.isLoading;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleCreateQuestion = useCallback(
    async (formData) => {
      const { photos } = formData;
      const postData = mapRequest(formData);

      if (photos.length > 0) {
        const photoIds = await uploadPhotos('photos', photos);
        if (photoIds.length === 0) return;

        postData.photos = photoIds;
      }

      try {
        const { question_id } = await createQuestion({
          spaceId: me.space_id,
          ...postData,
        }).unwrap();

        if (question_id) {
          navigate(`/knowledge-base/question/${question_id}`);
        }
      } catch (err) {
        if (err?.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (err?.status === 409) {
          m.error(t('error_409'));
        }
      }
    },
    [createQuestion, uploadPhotos, me, m, t, navigate],
  );

  const handleCreateTag = useCallback(
    async (option, onOptionCreated) => {
      if (!option?.label) return;

      try {
        const response = await createTag({ name: option.label, spaceId: me?.space_id }).unwrap();
        const { id, name } = response;

        if (id) {
          onOptionCreated({ label: name, value: id });
        }
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        }
      }
    },
    [createTag, me, m],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  return (
    <Form width="100%" form={form} onSubmit={handleCreateQuestion}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/knowledge-base/questions"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.create')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={3} color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>
          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <QuestionForm
              title={t('form_title')}
              rules={rules}
              loadingTags={loadingTags}
              tagsOptions={tagsOptions}
              onCreateTag={handleCreateTag}
            />
          </Paper>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewQuestion;
