import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { useGetProjectsQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';

import { ProjectCard } from 'views';
import { ProgressBox, Pagination } from 'components';

import { mapProjectsRequest } from './mappers';

const { selectPageFilters, selectPagination } = selectors;

const Projects = (props) => {
  const { spaceId } = props;
  const { auth, progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const { data, isLoading, isFetching } = useGetProjectsQuery(
    mapProjectsRequest({ spaceId, filters: pageFilters, pagination }),
    {
      skip: (auth && !spaceId) || progress,
    },
  );

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress;

  return (
    <>
      <ProgressBox loading={loading} mt={3} minHeight={400}>
        <Grid container spacing={3}>
          {getArray(data?.projects).map((project) => (
            <Grid key={`projects-grid-${project.id}`} item xs={4}>
              <ProjectCard
                id={project.id}
                title={project.name}
                tags={project.tags}
                photos={project.photos}
                level={project.level}
                assignedStudentsCount={project?.assigned_students?.length}
                to={`/learning/projects/${project.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </ProgressBox>

      <Pagination
        total={data?.projects_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default Projects;
