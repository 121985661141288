import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Tabs } from 'components';

const NavigationTabs = (props) => {
  const { groupId, tab } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'single_group' });
  const navigate = useNavigate();

  return (
    <Tabs
      width={597}
      sx={{ backgroundColor: 'other.white' }}
      value={tab}
      variant="fullWidth"
      options={[
        {
          value: 'information',
          label: t('tabs.information'),
          icon: 'Group',
        },
        {
          value: 'projects',
          label: t('tabs.projects'),
          icon: 'Project',
        },
        {
          value: 'portfolio',
          label: t('tabs.portfolio'),
          icon: 'Collection',
        },
      ]}
      onValue={(value) => {
        navigate(`/administration/groups/${groupId}/${value}`, { replace: false });
      }}
    />
  );
};

export default NavigationTabs;
