import { useTranslation } from 'react-i18next';

import { Tabs } from 'components';

const NavigationTabs = (props) => {
  const { value, onValue } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'space' });

  return (
    <Tabs
      width={597}
      variant="fullWidth"
      value={value}
      onValue={onValue}
      options={[
        {
          label: t('tabs.ideas'),
          value: 'ideas',
          icon: 'Idea',
        },
        {
          label: t('tabs.projects'),
          value: 'projects',
          icon: 'Project',
        },
        {
          label: t('tabs.collections'),
          value: 'collections',
          icon: 'Collection',
        },
      ]}
    />
  );
};

export default NavigationTabs;
