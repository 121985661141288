import { Box, Typography } from '@mui/material';

import { Icon } from 'components';

const PresentationCard = (props) => {
  const {
    title,
    icon,
    caption,
    content,
    actions,
    ...rest
  } = props;

  return (
    <Box width={456} {...rest}>
      {icon && (
        <Box mb={2} fontSize={0}>
          <Icon
            name={icon}
            color="primary"
            fontSize={80}
          />
        </Box>
      )}
      {caption && (
        <Typography
          mb={2}
          fontSize="32px"
          fontWeight={400}
          lineHeight={2}
          letterSpacing="-0.001em"
          color="#59988C"
        >
          {caption}
        </Typography>
      )}

      <Typography
        fontWeight={600}
        fontSize="68px"
        letterSpacing="0.015em"
        lineHeight={1.1}
        color="textBlack.dark"
      >
        {title}
      </Typography>

      <Box fontSize={0} pr={1} mt={4}>
        <Typography
          fontWeight={300}
          fontSize="18px"
          lineHeight={2}
          letterSpacing="0.02em"
          color="textBlack.dark"
        >
          {content}
        </Typography>
      </Box>

      {actions && (
        <Box mt={2}>
          {actions}
        </Box>
      )}
    </Box>
  )
};

export default PresentationCard;
