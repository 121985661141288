import { Box, Typography } from '@mui/material';

import { ReactComponent as ExpLevel } from 'assets/icons/exp_level2.svg';

export const IconStar = ({ level = 1 }) => (
  <Box position="relative" lineHeight={0}>
    <ExpLevel />

    <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <Typography variant="body3" color="primary.dark">
        {level}
      </Typography>
    </Box>
  </Box>
);
