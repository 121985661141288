import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMessage } from 'hooks';

import { useUpdateStudentWorkMutation } from 'store/api';

const useUpdateProjectStatus = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'single_project' });
  const m = useMessage();

  const [updateStudentWork, updateStudentWorkState] = useUpdateStudentWorkMutation();

  const updateStatus = useCallback(
    async (project) => {
      try {
        const { status } = await updateStudentWork({
          id: project.id,
          photos: project.student_works,
          video: project?.student_video,
          status: project.status,
        }).unwrap();

        if (status === 'OK') {
          m.success(t('success_status_update'));
        }
      } catch (err) {
        m.error(t('error.failed_add_video'));
      }
    },
    [updateStudentWork, t, m],
  );

  return useMemo(
    () => ({
      updateStatus,
      loading: updateStudentWorkState.isLoading,
    }),
    [updateStatus, updateStudentWorkState],
  );
};

export default useUpdateProjectStatus;
