import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectors } from 'store/state/pageFilter';

import { FILTER_TYPE } from 'utils/constants';
import { getArray } from 'utils/helpers';
import { useGetTipsQuery } from 'store/api';
import { usePageFilter, useAuth } from 'hooks';

import { ProgressBox } from 'components';
import { KnowledgeCard } from 'views';

import mapRequest from './mapRequest';

const { selectPageFilters } = selectors;

const Tips = (props) => {
  const { me } = props;

  const filters = useSelector(selectPageFilters);

  const { auth, progress } = useAuth();
  const { onSetFilter } = usePageFilter();

  const { data, isLoading, isFetching } = useGetTipsQuery(mapRequest(me, filters), {
    skip: (auth && !me?.space_id) || progress,
  });

  useEffect(() => {
    if (!data?.tags) return;
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.tags, total: data.tags_qty });
  }, [data, onSetFilter]);

  const loading = progress || isLoading || isFetching;

  return (
    <ProgressBox loading={loading}>
      <Grid container spacing={3}>
        {getArray(data?.tips).map((tip) => (
          <Grid item xs={6} key={`tips-grid-${tip.id}`}>
            <KnowledgeCard to={`/knowledge-base/tip/${tip.id}`} title={tip.name} tags={tip.tags} />
          </Grid>
        ))}
      </Grid>
    </ProgressBox>
  );
};

export default Tips;
