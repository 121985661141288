import { useState, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';

import { getArray } from 'utils/helpers';
import { useGetCollectionsQuery } from 'store/api';

import { SelectMultiple, Tag, Icon } from 'components';

let searchTimeout;
const SEARCH_DELAY = 600;
const MIN_SEARCH_LENGTH = 3;

const CollectionSelect = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');

  const { data, isLoading, isFetching } = useGetCollectionsQuery({
    name: search,
    tags: [],
    levels: [],
    limit: 20,
    offset: 0,
  });

  const options = useMemo(() => {
    if (!data?.collections?.length) return [];

    return getArray(data.collections).map((collection) => ({
      label: collection.name,
      value: collection.id,
      level: collection.level,
    }));
  }, [data]);

  const renderPreviewItem = useCallback(({ option, index, tagProps, onDelete }) => {
    return (
      <Tag
        key={`${option.label}-${index}`}
        label={option.label}
        clickable
        color="textBlack.light"
        bgcolor="input.main"
        size="medium"
        onClick={onDelete}
        startAdornment={<Icon name="Collection" />}
        endAdornment={<Icon color="error" name="Close" />}
        px={1}
        {...tagProps}
      />
    );
  }, []);

  const handleSearch = useCallback(
    (_e, value) => {
      setInputValue(value);
      if (searchTimeout) clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        if (value?.length >= MIN_SEARCH_LENGTH || !value?.length) {
          setSearch(value);
        }
      }, SEARCH_DELAY);
    },
    [setSearch, setInputValue],
  );

  // TODO: change option preview renderOption

  return (
    <Box>
      <SelectMultiple.Control
        options={options}
        chips={false}
        defaultValue={[]}
        renderPreviewItem={renderPreviewItem}
        inputValue={inputValue}
        onInputChange={handleSearch}
        disabled={isLoading || isFetching}
        {...props}
      />
    </Box>
  );
};

export default CollectionSelect;
