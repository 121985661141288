import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getArray } from 'utils/helpers';
import { useUpdateIdeaMutation } from 'store/api';

import useMessage from './useMessage.hook';

const useBookmarkIdea = () => {
  const m = useMessage();
  const { t } = useTranslation('hooks', { keyPrefix: 'use_bookmark_idea' });

  const [updateIdea, { isLoading }] = useUpdateIdeaMutation();

  return useMemo(() => {
    const handleBookmark = async (idea, userId) => {
      try {
        const {
          id,
          name,
          copyright,
          description,
          level,
          public: ideaPublic,
          photos,
          sources,
          tags,
          bookmarks,
        } = idea || {};

        const bookmarksCopy = bookmarks.slice(0);
        const foundIndex = bookmarksCopy.indexOf(userId);

        if (foundIndex !== -1) {
          bookmarksCopy.splice(foundIndex, 1);
        } else {
          bookmarksCopy.push(userId);
        }

        const { status } = await updateIdea({
          id,
          name,
          copyright,
          level,
          public: ideaPublic,
          description,
          photos,
          source: getArray(sources).map((s) => s.link),
          tags: getArray(tags).map((t) => t.id),
          users_bookmarks: bookmarksCopy,
        }).unwrap();

        if (status === 'OK') m.success(t(foundIndex === -1 ? 'bookmarked' : 'removed'));
      } catch (e) {
        console.error(e);
        m.error(t('failed'));
      }
    };

    return [handleBookmark, { isLoading }];
  }, [updateIdea, isLoading, m, t]);
};

export default useBookmarkIdea;
