import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';

import { dev } from 'utils/config';
import { useCombineRefs } from 'hooks';
import { getArray, keyCodes, makeOptions, propagateRefs } from 'utils/helpers';

import DropdownMenu from 'components/DropdownMenu';
import Input from 'components/Input';
import InputContainer from 'components/InputContainer';
import Fieldset from 'components/Dev/Fieldset';
import Icon from 'components/Icon';
import Tag from 'components/Tag';
import FormLabel from 'components/FormLabel';
import FormHelperText from 'components/FormHelperText';

const FormWrapper = styled(Box, {
  label: 'Select-FormWrapper',
})({
  display: 'inline-block',
});

const Placeholder = styled(Box, {
  label: 'Select-empty',
})(({ theme }) => ({
  ...theme.typography.body4,
  color: theme.palette.input.placeholder,
}));

const LabelHolder = styled(Box, {
  label: 'Select-LabelHolder',
})({
  disaply: 'flex',
  alignItems: 'center',
});

const keyPrefix = 'Select';

/**
 * @prop {boolean} [disabled]
 * @prop {boolean|string} [error] - error state and error message
 * @prop {boolean} [optional] - optional mark next to the label (if presented)
 * @prop {string} [value] - selected item value (item.value)
 * @prop {function} [onChange] - change handler (returns selected item itself)
 * @prop {function} [onValue] - return selected item value (item.value)
 * @prop {ReactNode} [children] - Select container custom layout
 * @prop {boolean} [fullWidth]
 * @prop {function} [onBlur] - Handle blur of select container
 * @prop {string|number} [itemsSize='medium'] - Options item size (Tag component 'size' prop)
 * @prop {ReactNode} [empty=t('Select.empty')] - Empty select box text
 * @prop {string} [placeholder=t('Select.placeholder')] - Placeholder
 * @prop {boolean} [initFocus] - Initial focus (works after the value changed from falsy to true)
 */
const Select = forwardRef((props, ref) => {
  const { t } = useTranslation('components', { keyPrefix });

  const {
    box = true,
    collapse = false,
    disabled,
    error,
    label: formLabel,
    optional,
    value,
    onChange,
    onValue,
    options,
    children,
    fullWidth,
    onBlur,
    itemsMax,
    itemsSize = 'medium',
    itemsMinWidth,
    empty = t('empty'),
    placeholder = t('placeholder'),
    initFocus,
    iconLeft,
    searchable,
    search,
    onSearch,
    ...rest
  } = props;

  const rootRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const handleRef = useCombineRefs(rootRef, ref);

  const [open, setOpen] = useState(false);
  const [refocus, setRefocus] = useState(false);

  const paperProps = useMemo(
    () => ({
      ...(fullWidth && {
        width: '100%',
      }),
    }),
    [fullWidth],
  );

  const opts = useMemo(
    () =>
      getArray(options).map((opt) => ({
        ...opt,
        tabIndex: open ? 0 : -1,
      })),
    [options, open],
  );

  const label = useMemo(() => getArray(opts).find((opt) => opt?.value === value), [opts, value]);

  const labelElement = useMemo(() => {
    if (!label) {
      return null;
    }
    return <Tag bgcolor="transparent" {...label} tabIndex={-1} size={itemsSize} />;
  }, [label, itemsSize]);

  const child = useMemo(() => {
    if (getArray(opts).length === 0 && !children) {
      return <Placeholder whiteSpace="nowrap">{empty}</Placeholder>;
    }
    return children;
  }, [children, opts, empty]);

  useEffect(() => {
    const { current: root } = rootRef;
    const { current: menu } = menuRef;
    const { current: input } = inputRef;

    if (root && menu && input) {
      // Move focus between items on arrow keys
      const handleArrowKeys = (e) => {
        if ([keyCodes.arrowDown, keyCodes.arrowUp].includes(e.keyCode)) {
          e.preventDefault();

          if (!open) {
            setOpen(true);
            return;
          }
          const items = [...menu.querySelectorAll('[tabindex]')].filter((el) => {
            return Number(el.getAttribute('tabindex')) >= 0;
          });
          const indexItems = [input, ...items];
          const currentIndex = indexItems.findIndex((item) => document.activeElement === item);
          const moveIndex = e.keyCode === keyCodes.arrowDown ? 1 : -1;
          const nextIndex = currentIndex + moveIndex;
          const next = Math.min(Math.max(0, nextIndex), indexItems.length - 1);
          indexItems[next].focus();
        }
      };
      root.addEventListener('keydown', handleArrowKeys);

      return () => {
        root.removeEventListener('keydown', handleArrowKeys);
      };
    }
  }, [open]);

  useEffect(() => {
    const { current: input } = inputRef;

    if (input && initFocus) {
      input.focus();
    }
  }, [initFocus]);

  const handleToggle = useCallback(
    (v) => {
      setOpen(v);
      if (v) {
        typeof onSearch === 'function' && onSearch('');
      }
    },
    [onSearch],
  );

  const handleSelect = useCallback(
    (v) => {
      typeof onChange === 'function' && onChange(v);
      typeof onValue === 'function' && onValue(v?.value);
      setOpen(false);

      // Root element needs to be focused after closing
      setRefocus(true);
    },
    [onChange, onValue],
  );

  const handleAfterClose = useCallback(() => {
    const { current: input } = inputRef;

    // After closing return focus to the root element
    if (!open && refocus && input) {
      input.focus();
    }
    if (refocus) {
      // Refresh flag for refocus of the root element after closing
      setRefocus(false);
    }

    typeof onSearch === 'function' && onSearch('');
  }, [refocus, open, onSearch]);

  const helperText = typeof error !== 'boolean' ? error : null;

  let targetChild = labelElement && <LabelHolder>{labelElement}</LabelHolder>;
  if (open && searchable) {
    targetChild = (
      <Input
        fullWidth
        initFocus
        placeholder={placeholder}
        value={search}
        onValue={onSearch}
        outline={false}
        sx={{
          '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
            minHeight: '100%',
          },
        }}
      />
    );
  }

  return (
    <FormWrapper ref={handleRef} width={fullWidth ? '100%' : undefined} {...rest}>
      {formLabel && (
        <Box mb={0.5}>
          <FormLabel error={!!error} disabled={disabled} optional={!!optional}>
            {formLabel}
          </FormLabel>
        </Box>
      )}

      <DropdownMenu
        collapse={collapse}
        box={box}
        open={open}
        fullWidth
        menuRef={menuRef}
        itemsSize={itemsSize}
        itemsMax={itemsMax}
        minWidth={itemsMinWidth}
        options={opts}
        onToggle={handleToggle}
        onSelect={handleSelect}
        paperProps={paperProps}
        onAfterClose={handleAfterClose}
        target={
          <InputContainer
            mb={0}
            ref={inputRef}
            onBlur={onBlur}
            cursor="pointer"
            error={!!error}
            disabled={!!disabled}
            fullWidth={fullWidth}
            placeholder={placeholder}
            iconLeft={iconLeft}
            iconRight={<Icon flipY={open} fontSize={15} name="ArrowDown" />}
          >
            {targetChild}
          </InputContainer>
        }
      >
        {child}
      </DropdownMenu>

      <FormHelperText mt={0.5} error disabled={disabled}>
        {helperText}
      </FormHelperText>
    </FormWrapper>
  );
});

Select.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onValue, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <Select
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [fullWidth, setFullWidth] = useState();
    const [placeholder, setPlaceholder] = useState();
    const [children, setChildren] = useState();
    const [options, setOptions] = useState('[...]');
    const [disabled, setDisabled] = useState();
    const [error, setError] = useState();
    const [label, setLabel] = useState();
    const [optional, setOptional] = useState();

    const [value, setValue] = useState();

    const child = useMemo(() => {
      if (children) {
        return <div>Custom Content</div>;
      }
      return children;
    }, [children]);

    const opts = useMemo(() => {
      if (options) {
        return makeOptions(['english_language', 'ukrainian', 'spanish']);
      }
      return options;
    }, [options]);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="placeholder"
            value={placeholder}
            onChange={setPlaceholder}
            options={[undefined, 'Select an option']}
          />
          <Fieldset.Field
            legend="fullWidth"
            value={fullWidth}
            onChange={setFullWidth}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, '<div>Custom Content</div>']}
          />
          <Fieldset.Field
            legend="options"
            value={options}
            onChange={setOptions}
            options={[undefined, '[...]']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Select an option']}
          />
          <Fieldset.Field
            legend="optional"
            value={optional}
            onChange={setOptional}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, true, false, 'This field is required!']}
          />
        </Fieldset>

        <Select
          value={value}
          onValue={setValue}
          options={opts}
          fullWidth={fullWidth}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
          optional={optional}
          label={label}
        >
          {child}
        </Select>
      </Box>
    );
  };
  Select.Demo = Demo;
}

export default Select;
