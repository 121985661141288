import { useCallback } from 'react';
import { Navigate, useMatch, useNavigate, useParams } from 'react-router-dom';

import { ROLE } from 'utils/constants';
import { useAuth } from 'hooks';
import { useGetMeQuery } from 'store/api';

import UserTab from './UserTab';
import IndividualSettingsTab from './IndividualSettingsTab';

const Profile = () => {
  const { id = 'me' } = useParams();
  const navigate = useNavigate();
  const userPath = useMatch(`/profile/${id}/edit`);
  const settingsPath = useMatch(`profile/${id}/settings/edit`);
  const { auth } = useAuth();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const tab = settingsPath ? `${id}/settings` : id;

  const handleTabChange = useCallback(
    (v) => {
      navigate(`/profile/${v}/edit`);
    },
    [navigate],
  );

  if (!userPath && !settingsPath) {
    return <Navigate to={`/profile/${id}/edit`} />;
  }

  if (id !== 'me' && me && me?.role !== ROLE.ADMIN) {
    return <Navigate to={`/profile/me/edit`} />;
  }

  if (tab === id) {
    return <UserTab tab={tab} onTabChange={handleTabChange} id={id} />;
  }
  if (tab === `${id}/settings`) {
    return <IndividualSettingsTab tab={tab} onTabChange={handleTabChange} id={id} />;
  }

  return null;
};

export default Profile;
