import { useTranslation, Trans } from 'react-i18next';
import { Stack } from '@mui/material';

import { useForm, useOneOfRequired } from 'hooks';
import { Button, Form, Input, PhoneField, Checkbox, Ref, Tabs } from 'components';
import { PasswordField } from 'views';

const keyPrefix = 'signup.step1';

const Step1 = (props) => {
  const { onSubmit, visible } = props;
  const { t } = useTranslation('pages', { keyPrefix });
  const {
    form,
    valid,
    rules: { required, email, phone, order },
  } = useForm();

  const oneOfFieldIsRequired = useOneOfRequired(form, {
    email: t('email.label'),
    phone_number: t('phone.label'),
  });

  return (
    <Form my={1} mt={2} width={456} form={form} onSubmit={onSubmit}>
      <Stack spacing={2} alignItems="center">
        <Tabs.Control
          mb={1}
          width={396}
          name="type"
          defaultValue="personal"
          variant="fullWidth"
          options={[
            {
              value: 'personal',
              label: t('type.personal.label'),
              icon: 'User',
            },
            {
              value: 'organization',
              label: t('type.organization.label'),
              icon: 'Organization',
            },
          ]}
        />
        <Input.Control
          fullWidth
          type="email"
          name="email"
          initFocus={!!visible}
          label={t('email.label')}
          placeholder={t('email.placeholder')}
          rules={{
            validate: order([oneOfFieldIsRequired, email]),
            deps: ['phone_number'],
          }}
        />
        <PhoneField.Control
          optional
          fullWidth
          name="phone_number"
          label={t('phone.label')}
          placeholder={t('phone.placeholder')}
          rules={{
            validate: order([oneOfFieldIsRequired, phone]),
            deps: ['email'],
          }}
        />
        <PasswordField.Control
          fullWidth
          newPassword
          name="password"
          rules={{ required }}
          label={t('password.label')}
          placeholder={t('password.placeholder')}
        />
        <Checkbox.Control
          fullWidth
          size="xsmall"
          name="agree_tos"
          rules={{ required }}
          label={
            <Trans t={t} i18nKey="agreeTos.label">
              I agree to the{' '}
              <Ref blank to="/terms-of-service">
                Terms of Service
              </Ref>{' '}
              and{' '}
              <Ref blank to="/privacy-policy">
                Privacy Policy
              </Ref>
            </Trans>
          }
        />
        <Stack spacing={2} alignItems="center" width={216} pt={1}>
          <Button fullWidth type="submit" disabled={!valid}>
            {t('next')}
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};

export default Step1;
