import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Typography } from '@mui/material';

import { notebook } from 'assets/images/holders';

import { sleep } from 'utils/helpers';
import { useUpdatePasswordMutation, useUpdateLanguageMutation } from 'store/api';
import { useForm, useMessage, useAuth } from 'hooks';

import { GuestLayout } from 'layouts';
import { PasswordField, LanguageSelect } from 'views';
import { Button, Form, ImageHolder, Paper } from 'components';

const keyPrefix = 'login_from_invitation';

const LoginFromInvitation = () => {
  const { t } = useTranslation('pages', { keyPrefix });
  const {
    form,
    valid,
    rules: { required },
  } = useForm();
  const { login, progress } = useAuth();

  const [updatePassword, { isLoading, isSuccess }] = useUpdatePasswordMutation();
  const [updateLanguage, { isLoading: languageUpdating }] = useUpdateLanguageMutation();

  const m = useMessage();

  const hash = window.location.search.split('hash=')[1];

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const { password, language } = formData;
        const { user_login, user_id } = await updatePassword({ hash, password }).unwrap();

        const response = await login({ username: user_login, password });
        await sleep();

        await updateLanguage({ user_id, language }).unwrap();

        if (response.status === 403) {
          m.error(t('something_went_wrong'));
        }
      } catch (ex) {
        console.error(ex);
        m.error(t('something_went_wrong'));
      }
    },
    [hash, updatePassword, updateLanguage, login, m, t],
  );

  const loading = isLoading || languageUpdating || progress;

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        alt={t('title')}
        src={notebook}
        origin="container"
        bgcolor="primary.main"
      />

      <Typography my={3} color="primary.dark" variant="subtitle2">
        {t('title')}
      </Typography>

      <Paper py={3} width={696} alignItems="center" loading={loading}>
        <Collapse in={!isSuccess}>
          <Form
            width={456}
            form={form}
            display="flex"
            alignItems="center"
            flexDirection="column"
            onSubmit={handleSubmit}
          >
            <PasswordField.Control
              fullWidth
              name="password"
              rules={{ required }}
              indicateValidation
              label={t('password.label')}
              placeholder={t('password.placeholder')}
            />

            <LanguageSelect.Control
              mt={2}
              rules={{ required }}
              fullWidth
              name="language"
              label={t('language.label')}
              placeholder={t('language.placeholder')}
            />

            <Box width={217} mt={4}>
              <Button disabled={!valid} fullWidth type="submit">
                {t('login')}
              </Button>
            </Box>
          </Form>
        </Collapse>

        <Collapse in={!!isSuccess}>
          <Box width={456} textAlign="center">
            <Typography variant="body3" color="textBlack.light">
              {t('success')}
            </Typography>
          </Box>
        </Collapse>
      </Paper>
    </GuestLayout>
  );
};

export default LoginFromInvitation;
