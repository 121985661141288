import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useForm, useMessage, useAuth, useUploadPhotos, useCreateTag } from 'hooks';
import { getArray } from 'utils/helpers';
import { useGetMeQuery, useCreateTipMutation, useGetTagsQuery } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper } from 'components';
import { PageSubheader, TipForm } from 'views';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };

const NewTip = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_tip' });
  const { auth, progress } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { form, valid, rules } = useForm();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createTag, createTagState] = useCreateTag({ me });
  const [createTip, createTipState] = useCreateTipMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleErrorResponse = useCallback(
    (err, generalMessage) => {
      if (err?.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else if (generalMessage) {
        m.error(generalMessage);
      }
    },
    [m],
  );

  const handleCreateTip = useCallback(
    async (formData) => {
      try {
        const { photos } = formData;
        const postData = mapRequest(formData);

        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;

          postData.photos = photoIds;
        }

        const { tip_id } = await createTip({
          spaceId: me.space_id,
          ...postData,
        }).unwrap();

        if (tip_id) navigate(`/knowledge-base/tip/${tip_id}`);
      } catch (err) {
        handleErrorResponse(err, t('error_failed_create'));
      }
    },
    [createTip, handleErrorResponse, uploadPhotos, me, t, navigate],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const loading =
    isLoading || isFetching || progress || uploadPhotosState.isLoading || createTipState.isLoading;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;

  return (
    <Form width="100%" form={form} onSubmit={handleCreateTip}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/knowledge-base/tips"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.create')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={3} color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>

          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <TipForm
              title={t('form_title')}
              rules={rules}
              loadingTags={loadingTags}
              tagsOptions={tagsOptions}
              defaultSourceValue={defaultSourceValue}
              onCreateTag={createTag}
            />
          </Paper>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewTip;
