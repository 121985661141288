import { styled, Typography, Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { page_empty } from 'assets/images/holders';

import { getArray } from 'utils/helpers';
import { withProps, useAuth } from 'hooks';
import { useGetGroupQuery, useGetPortfoliosQuery } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, PaperCard, Paper, ImageHolder, Button } from 'components';
import { PageSubheader, CardContent } from 'views';

import NavigationTabs from './NavigationTabs';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const GroupPortfolio = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'single_group' });
  const { auth } = useAuth();

  const {
    data: group,
    isLoading: groupLoading,
    isFetching: groupFetching,
  } = useGetGroupQuery({ id }, { skip: !Boolean(id) || !auth });

  const { data: portfolios } = useGetPortfoliosQuery(
    {
      spaceId: group?.space_id,
      users: [],
      groups: [group?.id],
      name: '',
      limit: 10,
      offset: 0,
    },
    { skip: !group?.space_id || !group?.id },
  );

  const loading = groupLoading || groupFetching;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={group?.name}
          backUrl="/administration/groups"
          backTitle={t('back_button')}
          pageActions={{
            [t('create_portfolio')]: {
              type: 'button',
              size: 'small',
              to: '/portfolio/new',
            },
          }}
        />
      }
    >
      <Article py={4}>
        <Container>
          <Box mb={3}>
            <NavigationTabs tab="portfolio" groupId={group?.id} />
          </Box>
          <Box>
            {!portfolios?.length && (
              <Paper width="100%" loading={loading}>
                <Center width="100%" py={5} flexDirection="column">
                  <ImageHolder mb={3} alt="no results" size={210} src={page_empty} />
                  <Typography component="div" mb={2} color="primary" variant="body2">
                    {t('portfolio_empty_text')}
                  </Typography>
                  <Button>{t('create_portfolio')}</Button>
                </Center>
              </Paper>
            )}
            {portfolios?.length > 0 && (
              <Grid container spacing={3}>
                {getArray(portfolios).map((portfolio) => (
                  <Grid item xs={6} key={portfolio.id}>
                    <PaperCard size="small" to={`/profile/portfolio/${portfolio.id}`}>
                      <CardContent
                        variant="portfolio"
                        photo={portfolio?.user?.avatar}
                        title={portfolio.name}
                        projectsQty={portfolio.projects_qty}
                      />
                    </PaperCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default GroupPortfolio;
