import { Typography, Box, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getArray, getProfileBasePath } from 'utils/helpers';
import { useGetProfile, usePageFilter } from 'hooks';
import { useGetUserProjectStatisticsQuery, useGetUserProjectsQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';

import { ProjectLayout } from 'layouts';
import { Center, Paper, Icon, Pagination } from 'components';
import { PageSubheader, TopTags, ProjectStatus, SidebarFilters, RelatedProjects } from 'views';

import NavigationTabs from '../NavigationTabs';

const { selectFilters, selectActiveFilters, selectPageFilters, selectPagination } = selectors;

const mapRequest = (pageFilters, pagination) => {
  const { search, activeFilters } = pageFilters;

  const { status, collections } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    name: search || undefined,
    status: getArray(status).map((status) => status.id),
    collections: getArray(collections).map((collection) => collection.id),
    limit: perPage,
    offset: offset,
  };
};

const containerWidth = 1112;

const UserProjects = (props) => {
  const { role } = props;
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'user_profile' });

  const { onSetActiveFilter, onChangePage } = usePageFilter();

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);
  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const [profile] = useGetProfile({ id, role });

  const { data: projectStatistics } = useGetUserProjectStatisticsQuery(
    { id: profile?.id },
    { skip: !Boolean(profile?.id) },
  );

  const { data: userProjects } = useGetUserProjectsQuery(
    Object.assign({ user_id: id }, mapRequest(pageFilters, pagination)),
    { skip: !Boolean(id) },
  );

  const profileBasePath = getProfileBasePath(role);

  const statusItems = [
    {
      count: projectStatistics?.statistics?.assigned,
      percentage: projectStatistics?.statistics?.assigned_percent,
      label: t('statistics.assigned'),
    },
    {
      count: projectStatistics?.statistics?.progress,
      percentage: projectStatistics?.statistics?.progress_percent,
      label: t('statistics.progress'),
    },
    {
      count: projectStatistics?.statistics?.completed,
      percentage: projectStatistics?.statistics?.completed_percent,
      label: t('statistics.completed'),
    },
    {
      count: projectStatistics?.statistics?.published,
      percentage: projectStatistics?.statistics?.published_percent,
      label: t('statistics.published'),
    },
    {
      count: projectStatistics?.statistics?.abandoned,
      percentage: projectStatistics?.statistics?.abandoned_percent,
      label: t('statistics.abandoned'),
    },
  ];

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={profile?.profile?.username}
          backUrl="/administration/users"
          backTitle={t('back_title')}
        />
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Box p={4} pl={3} maxWidth={containerWidth}>
        <Box mb={3}>
          <NavigationTabs
            role={role}
            id={id}
            value={`${id}/projects`}
            profileBasePath={profileBasePath}
          />
        </Box>
        {projectStatistics && (
          <Box mb={3}>
            <ProjectStatus title={t('project_status')} items={statusItems} />
          </Box>
        )}
        <Box mb={4}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TopTags
                title={t('top_10_tags.title')}
                description={t('top_10_tags.description')}
                tags={getArray(projectStatistics?.top_tags)}
              />
            </Grid>
            <Grid item xs={4}>
              <Paper shadow="small" width={1} mb={3}>
                <Center mb={2} color="textBlack.light">
                  <Icon fontSize={24} name="Project" />
                  <Typography variant="body2" ml={0.5}>
                    {t('average_time_spent_on_project')}
                  </Typography>
                </Center>
                <Typography variant="subtitle5" color="textBlack.main">
                  {projectStatistics?.time_spent?.project}
                </Typography>
              </Paper>
              <Paper shadow="small" width={1}>
                <Center mb={2} color="textBlack.light">
                  <Icon fontSize={24} name="Collection" />
                  <Typography variant="body2" ml={0.5}>
                    {t('average_time_spent_on_collection')}
                  </Typography>
                </Center>
                <Typography variant="subtitle5" color="textBlack.main">
                  {projectStatistics?.time_spent?.collection}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <RelatedProjects projects={userProjects?.child_projects} />
        </Box>

        <Pagination
          total={userProjects?.child_projects_qty}
          page={pagination?.page}
          mt={6}
          onChangePage={onChangePage}
        />
      </Box>
    </ProjectLayout>
  );
};

export default UserProjects;
