import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';

import { dev } from 'utils/config';
import notebook from 'assets/images/holders/notebook.svg';
import { ImageHolder, Ref } from 'components';

const keyPrefix = 'ProjectButton';

const ProjectButton = forwardRef((props, ref) => {
  const { t } = useTranslation('views', { keyPrefix });

  return (
    <Stack
      ref={ref}
      spacing={2}
      direction="row"
      alignItems="center"
      {...props}
    >
      <Ref to="/" hidden>
        <ImageHolder
          p={1}
          size={40}
          alt="Notebook"
          src={notebook}
          origin="container"
          bgcolor="primary.dark"
        />
      </Ref>

      <Typography variant="subtitle5" color="primary.contrastText">
        <Ref to="/" color="inherit">
          {t('app_name')}
        </Ref>
      </Typography>
    </Stack>
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box p={2}>
        <Box component="pre">
          {`<Box bgcolor="primary.main" width="400px" p={2}>\n`}
          {`  <ProjectButton />\n`}
          {`</Box>\n`}
        </Box>
        <Box bgcolor="primary.main" width="400px" p={2}>
          <ProjectButton />
        </Box>
      </Box>
    );
  };
  ProjectButton.Demo = Demo;
}

export default ProjectButton;
