import { getArray } from 'utils/helpers';

const mapRequest = ({ me, filters, pagination }) => {
  const { search, activeFilters } = filters;

  const { users, groups } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    spaceId: me?.space_id,
    name: search || null,
    users: getArray(users).map((user) => user.id),
    groups: getArray(groups).map((group) => group.id),
    limit: perPage,
    offset: offset,
  };
};

export default mapRequest;
