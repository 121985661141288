import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { getArray } from 'utils/helpers';
import { useForm, useUsersSearch, useGroupSearch } from 'hooks';

import { Modal, Button, Select, Tabs, Form, PaperCard } from 'components';
import { CardContent } from 'views';

const getGroupUserOptions = (participants) => {
  return getArray(participants).map((user) => ({
    value: user.id,
    label: user.username,
    avatar: user.avatar,
    avatarSize: 24,
  }));
};

const AssignCollectionModal = (props) => {
  const { spaceId, open, projects, loading, onClose, onValue } = props;

  const [tab, setTab] = useState('student');

  const { t } = useTranslation('views', { keyPrefix: 'AssignCollectionModal' });
  const { form, valid } = useForm();

  useEffect(() => {
    if (open) return;
    form.reset();
  }, [form, open]);

  const watchStudent = form.watch('student');
  const watchGroup = form.watch('group');

  const {
    options: studentOptions,
    loading: studentsLoading,
    search: studentSearch,
    onSearch: onStudentSearch,
  } = useUsersSearch({
    spaceId: spaceId,
    disabled: !open || loading || tab === 'group',
    mapToOptions: true,
    filters: {
      roles: ['student', 'teacher', 'admin'],
      groups: [],
    },
  });

  const {
    data: groups,
    loading: groupsLoading,
    search: groupSearch,
    onSearch: onGroupSearch,
  } = useGroupSearch({
    spaceId: spaceId,
    disabled: !open || loading || tab === 'student',
  });

  const handleSubmit = useCallback(
    (formData) => {
      if (typeof onValue !== 'function') return;
      return onValue(
        tab === 'student'
          ? { student: formData.student }
          : { group: formData.group, projects: formData.projects },
      );
    },
    [tab, onValue],
  );

  const groupOptions = useMemo(() => {
    if (!groups) return [];
    return getArray(groups?.groups).map((group) => ({
      value: group.id,
      label: group.name,
      participants: group.participants,
    }));
  }, [groups]);

  const participants = useMemo(() => {
    return groupOptions.find((g) => g.value === watchGroup)?.participants || [];
  }, [watchGroup, groupOptions]);

  return (
    <Modal open={open} onClose={onClose} size={516}>
      <Modal.Body>
        <Form id="assign-collection-form" form={form} onSubmit={handleSubmit}>
          <Stack pt={4} spacing={1.5}>
            {tab === 'student' && (
              <Select.Control
                box={false}
                collapse
                itemsMax={5}
                name="student"
                placeholder={t('search_student')}
                label={t('choose_student')}
                fullWidth
                loading={studentsLoading}
                options={studentOptions}
                searchable
                search={studentSearch}
                onSearch={onStudentSearch}
              />
            )}

            {tab === 'group' && (
              <Typography variant="caption1" color="textBlack.light">
                {t('choose_student_group')}
              </Typography>
            )}

            <Tabs
              onValue={setTab}
              value={tab}
              width="100%"
              variant="fullWidth"
              options={[
                { icon: 'Student', label: 'Student', value: 'student' },
                { icon: 'Group', label: 'Group', value: 'group' },
              ]}
            />

            {tab === 'group' && (
              <>
                <Select.Control
                  box={false}
                  collapse
                  itemsMax={5}
                  name="group"
                  placeholder={t('search_group')}
                  fullWidth
                  loading={groupsLoading}
                  options={groupOptions}
                  searchable
                  search={groupSearch}
                  onSearch={onGroupSearch}
                />
                {watchGroup && (
                  <Stack spacing={1}>
                    {getArray(projects).map((project) => (
                      <PaperCard border size="small" key={`assign-project-${project.id}`}>
                        <CardContent
                          variant="project_assign_student"
                          name={`projects.${project.id}`}
                          level={project.level}
                          title={project.name}
                          userOptions={getGroupUserOptions(participants)}
                        />
                      </PaperCard>
                    ))}
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer sx={{ borderTop: 'none' }} pt={0}>
        <Button
          type="submit"
          form="assign-collection-form"
          size="small"
          radius={2}
          disabled={!valid || loading || (!watchGroup && !watchStudent)}
        >
          {t('assign_collection')}
        </Button>
        <Button size="small" radius={2} variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignCollectionModal;
