import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack, Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { OBJECT_TYPE } from 'utils/constants';
import { getArray, formatDate } from 'utils/helpers';
import { useAuth, useMessage } from 'hooks';
import { actions, selectors } from 'store';

import {
  useGetMeQuery,
  useGetNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useDeleteNotificationsMutation,
} from 'store/api';

import { Modal, Center, Button, Tag, Avatar, Icon } from 'components';

const { selectIsNotificationsModalOpen } = selectors;
const { closeNotificationsModal } = actions;

const AuthorAvatar = styled(Avatar)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(1.5),
  top: theme.spacing(1.5),
}));

const NotificationRoot = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(1.5),
  paddingLeft: theme.spacing(5.5),
  position: 'relative',
}));

const iconByObjectType = {
  [OBJECT_TYPE.PROJECT]: 'Project',
  [OBJECT_TYPE.STUDENT_PROJECT]: 'Project',
  [OBJECT_TYPE.COLLECTION]: 'Collection',
  [OBJECT_TYPE.STUDENT_COLLECTION]: 'Collection',
  [OBJECT_TYPE.GROUP]: 'Group',
  [OBJECT_TYPE.IDEA]: 'Idea',
  [OBJECT_TYPE.TIP]: 'Idea2',
  [OBJECT_TYPE.QUESTION]: 'Forum',
  [OBJECT_TYPE.USER]: 'User',
};

const getObjectUrl = (notification) => {
  if (notification?.object_type === OBJECT_TYPE.STUDENT_PROJECT)
    return `/learning/student-project/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.PROJECT)
    return `/learning/projects/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.COLLECTION)
    return `/learning/collections/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.STUDENT_COLLECTION)
    return `/learning/child-collection/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.GROUP)
    return `/administration/groups/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.IDEA) return `/ideas/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.PORTFOLIO)
    return `/portfolio/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.QUESTION)
    return `/knowledge-base/question/${notification.entity_id}`;
  if (notification?.object_type === OBJECT_TYPE.TIP)
    return `/knowledge-base/tip/${notification.entity_id}`;
  return false;
};

const NotificationsModal = () => {
  const m = useMessage();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { t } = useTranslation('views', { keyPrefix: 'NotificationsModal' });
  const modalOpen = useSelector(selectIsNotificationsModalOpen);

  const [limit, setLimit] = useState(20);

  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();
  const [clearNotifications] = useDeleteNotificationsMutation();

  const { data: me } = useGetMeQuery(undefined, { skip: !auth });
  const { data: notifications } = useGetNotificationsQuery(
    { space_id: me?.space_id, limit: limit, offset: 0, status: false },
    { skip: !me || !modalOpen },
  );

  const handleNotificationStatusChange = async (notification) => {
    try {
      await updateNotificationStatus({
        notification_id: notification.id,
      }).unwrap();
    } catch (err) {
      console.error(err);
      if (!!err?.data?.detail?.error) {
        return m.error(err.data.detail.error);
      }
      m.error(t('error.notification_status_change'));
    }
  };

  const handleClearNotifications = async () => {
    try {
      await clearNotifications().unwrap();
      closeNotificationsModal();
    } catch (err) {
      console.error(err);
      if (!!err?.data?.detail?.error) {
        return m.error(err.data.detail.error);
      }
      m.error(t('error.clear_notifications'));
    }
  };

  const handleObjectRedirect = (url) => {
    navigate(url);
    closeNotificationsModal();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        closeNotificationsModal();
      }}
      size={516}
      paperProps={{
        sx: { right: 0, top: 0, bottom: 0, left: 'initial', transform: 'none', borderRadius: 0 },
      }}
    >
      <Modal.Body pt={6} height="100%" overflow="hidden" display="flex" flexDirection="column">
        <Center justifyContent="space-between">
          <Typography variant="subtitle3" color="success.dark">
            {t('notifications')}
          </Typography>
          <Button
            variant="text"
            color="primary"
            sx={{ padding: 0 }}
            size="xsmall"
            onClick={handleClearNotifications}
          >
            {t('clear_all')}
          </Button>
        </Center>

        <Box flexGrow={1} overflow="auto">
          <Stack gap={2} mb={2}>
            {getArray(notifications?.notifications).map((notification) => {
              const objectIcon = iconByObjectType[notification.object_type];
              const objectUrl = getObjectUrl(notification);
              return (
                <NotificationRoot key={notification.id}>
                  <AuthorAvatar size={24} src={notification.author.avatar} />
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box>
                      <Center justifyContent="flex-start" flexWrap="wrap" pr={2} gap={0.5}>
                        <Tag
                          label={notification.author.name}
                          color="textRegular.light"
                          bgcolor="textRegular.contrastText"
                        />
                        <Typography variant="body4" color="textBlack.dark" ml={0.5}>
                          {notification.message}
                        </Typography>
                        {notification?.object_title && (
                          <Tag
                            pl={objectIcon ? 1 : undefined}
                            startAdornment={objectIcon ? <Icon name={objectIcon} /> : undefined}
                            label={notification.object_title}
                            color="textRegular.light"
                            bgcolor="textRegular.contrastText"
                            clickable={!!objectUrl}
                            onClick={objectUrl ? () => handleObjectRedirect(objectUrl) : undefined}
                          />
                        )}
                      </Center>

                      <Box mt={0.5}>
                        <Typography variant="caption1" color="textBlack.contrastText">
                          {formatDate(notification?.date_created)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box flexShrink={0}>
                      <Button
                        variant="text"
                        type="button"
                        color="primary"
                        size="xsmall"
                        sx={{ padding: 0 }}
                        onClick={() => handleNotificationStatusChange(notification)}
                      >
                        {notification.marked_read ? t('mark_unread') : t('mark_read')}
                      </Button>
                    </Box>
                  </Box>
                </NotificationRoot>
              );
            })}
            {!notifications?.notifications?.length && (
              <Typography
                component="p"
                mt={2}
                textAlign="center"
                variant="body4"
                color="textBlack.light"
              >
                {t('empty')}
              </Typography>
            )}
          </Stack>
        </Box>

        {notifications?.notifications_qty > limit && (
          <Box>
            <Button
              type="button"
              variant="text"
              color="primary"
              onClick={() => {
                setLimit(notifications?.notifications_qty);
              }}
            >
              {t('view_all')}
            </Button>
          </Box>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default NotificationsModal;
