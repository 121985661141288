import { useMemo, useCallback } from 'react';
import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth, useGroupSearch, useUsersSearch } from 'hooks';
import { useGetMeQuery, useGetStatusesQuery } from 'store/api';

import { Center, Paper, Select, Icon } from 'components';

const FilterBarRoot = (props) => {
  const { children, ...rest } = props;
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  return (
    <Paper py={1} shadow="standard" overflow="visible" {...rest}>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="body4" color="textBlack.dark">
          {t('filters.filter_by')}
        </Typography>
        {children}
      </Stack>
    </Paper>
  );
};

export const GroupFilterBar = (props) => {
  const { groupId, value = {}, onValue, ...rest } = props;

  const { auth } = useAuth();
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data: statuses } = useGetStatusesQuery();

  const onGroupsLoaded = useCallback(
    (data) => {
      if (!data?.groups?.length || !!groupId) return;
      typeof onValue === 'function' && onValue('group', data.groups[0]?.id);
    },
    [onValue, groupId],
  );

  const { options: groupOptions } = useGroupSearch({
    userId: me?.id,
    spaceId: me?.space_id,
    mapToOptions: true,
    disabled: !auth || !me,
    onData: onGroupsLoaded,
  });

  const statusOptions = useMemo(() => {
    if (!statuses) return [];
    return Object.values(statuses).map((status) => ({ label: status, value: status }));
  }, [statuses]);

  return (
    <FilterBarRoot {...rest}>
      {!groupId && (
        <Center width={272}>
          <Select
            iconLeft={
              <Center color="secondary.light">
                <Icon name="Group" />
                <Typography ml={1} variant="caption1" color="textBlack.contrastText">
                  {t('filters.group')} /
                </Typography>
              </Center>
            }
            fullWidth
            name="group"
            options={groupOptions}
            value={value?.group}
            onValue={(v) => onValue('group', v)}
            itemsMax={5}
          />
        </Center>
      )}
      <Center width={272}>
        <Select
          iconLeft={
            <Center>
              <Typography variant="caption1" color="textBlack.contrastText">
                {t('filters.status')} /
              </Typography>
            </Center>
          }
          fullWidth
          name="status"
          value={value?.status}
          options={statusOptions}
          onValue={(v) => onValue('status', v)}
          itemsMax={5}
        />
      </Center>
    </FilterBarRoot>
  );
};

export const UserFilterBar = (props) => {
  const { userId, value = {}, onValue, ...rest } = props;

  const { auth } = useAuth();
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data: statuses } = useGetStatusesQuery();

  const onUsersLoaded = useCallback(
    (data) => {
      if (!data?.users?.length || !!userId || value?.user) return;
      typeof onValue === 'function' && onValue('user', data.users[0]?.id);
    },
    [onValue, userId, value],
  );

  const {
    options: userOptions,
    tempSearch: studentSearch,
    onSearch: onStudentSearch,
  } = useUsersSearch({
    onData: onUsersLoaded,
    mapToOptions: true,
    spaceId: me?.space_id,
    filters: {
      roles: [],
      groups: [],
    },
  });
  const statusOptions = useMemo(() => {
    if (!statuses) return [];
    return Object.values(statuses).map((status) => ({ label: status, value: status }));
  }, [statuses]);

  return (
    <FilterBarRoot {...rest}>
      {!userId && (
        <Center width={272}>
          <Select
            fullWidth
            name="user"
            options={userOptions}
            value={value?.user}
            onValue={(v) => onValue('user', v)}
            itemsMax={5}
            searchable
            search={studentSearch}
            onSearch={(v) => onStudentSearch(null, v)}
            placeholder={t('search_user')}
          />
        </Center>
      )}
      <Center width={272}>
        <Select
          iconLeft={
            <Center>
              <Typography variant="caption1" color="textBlack.contrastText">
                {t('filters.status')} /
              </Typography>
            </Center>
          }
          fullWidth
          name="status"
          value={value?.status}
          options={statusOptions}
          onValue={(v) => onValue('status', v)}
          itemsMax={5}
        />
      </Center>
    </FilterBarRoot>
  );
};
