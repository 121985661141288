import { useState, useCallback } from 'react';
import { styled, Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getArray } from 'utils/helpers';

import { Button, Modal, Checkbox } from 'components';
import LevelTag from 'views/LevelTag';

const LevelList = styled(Stack, { label: 'LevelFilter-List' })({
  '& .MuiTypography-body2': {
    marginBottom: 0,
  },
});

const checkActive = (levels = [], level) => {
  const foundIndex = levels.findIndex((item) => item.id === level.id);
  return foundIndex !== -1;
};

const LevelFilter = (props) => {
  const { value = [], levels, onValue } = props;

  const { t } = useTranslation('views', { keyPrefix: 'QuickFilters' });

  const [currentValue, setCurrentValue] = useState([]);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (level) => {
      const foundIndex = currentValue.findIndex((item) => item.id === level.id);
      if (foundIndex === -1) {
        setCurrentValue([...currentValue, level]);
      } else {
        const changed = [...currentValue];
        changed.splice(foundIndex, 1);
        setCurrentValue(changed);
      }
    },
    [currentValue, setCurrentValue],
  );

  const handleApply = useCallback(() => {
    setOpen(false);
    typeof onValue === 'function' && onValue(currentValue);
  }, [currentValue, setOpen, onValue]);

  const handleClear = useCallback(() => {
    typeof onValue === 'function' && onValue([]);
    setCurrentValue([]);
  }, [onValue]);

  const openModal = useCallback(() => {
    setOpen(true);
    setCurrentValue(value);
  }, [setOpen, setCurrentValue, value]);

  return (
    <>
      <Button
        radius={2}
        variant="outlined"
        size="small"
        iconLeft="Level"
        onClick={openModal}
        sx={{ paddingRight: 0 }}
      >
        {t('LevelFilter.levels')}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)} size="medium">
        <Modal.Header title={t('LevelFilter.levels')} />
        <Modal.Body>
          <LevelList px={1} spacing={3}>
            {getArray(levels).map((level) => (
              <Box key={level.id}>
                <Checkbox
                  labelVariant="body2"
                  label={<LevelTag size="medium" level={level.id} label={level.name} />}
                  checked={checkActive(currentValue, level)}
                  onChange={() => {
                    handleChange(level);
                  }}
                />
              </Box>
            ))}
          </LevelList>
        </Modal.Body>

        <Modal.Footer>
          <Button size="small" onClick={handleApply}>
            {t('apply')}
          </Button>
          <Button size="small" variant="outlined" onClick={handleClear}>
            {t('clear_all')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LevelFilter;
