import { useParams } from 'react-router-dom';

import { useGetPortfolioQuery } from 'store/api';
import { useAuth } from 'hooks';

import OwnPortfolio from './OwnPortfolio';
import GroupsPortfolio from './GroupsPortfolio';

const isIndividual = (portfolio) => {
  if (!portfolio) return false;
  const { project_cards } = portfolio;
  return project_cards.findIndex((g) => !g.group_id && g.group_name === 'individual') !== -1;
};

const EditPortfolio = (props) => {
  const { id } = useParams();
  const { auth } = useAuth();

  const { data: portfolio } = useGetPortfolioQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  if (isIndividual(portfolio)) return <OwnPortfolio {...props} />;
  return <GroupsPortfolio {...props} />;
};

export default EditPortfolio;
