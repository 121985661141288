import { useMemo, useCallback, useState } from 'react';
import { styled, Typography, Box } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from 'utils/constants';
import { checkBookmarked, formatDate, getArray } from 'utils/helpers';
import { withProps, useAuth, useMessage, useBookmarkIdea } from 'hooks';
import {
  useGetMeQuery,
  useGetIdeaQuery,
  useDeleteIdeaMutation,
  usePublishIdeaMutation,
  useGetIdeaProjectsQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, Gallery, Icon, IconButton } from 'components';
import { ArticleGeneralInformation, PageSubheader, RelatedProjects } from 'views';

import BlockIdeaModal from './BlockIdeaModal';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Idea = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_idea' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const [bookmark] = useBookmarkIdea();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data: ideaProjects } = useGetIdeaProjectsQuery(
    { id, limit: 6, offset: 0 },
    {
      skip: !auth || !id,
    },
  );

  const {
    data: idea,
    isLoading,
    isFetching,
  } = useGetIdeaQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const [deleteIdea, deleteIdeaState] = useDeleteIdeaMutation();
  const [publishIdea, publishIdeaState] = usePublishIdeaMutation();

  const handlePublishIdea = useCallback(async () => {
    if (!id) {
      m.error(t('error.publish_failed'));
      return;
    }
    try {
      const { status } = await publishIdea({
        id,
      }).unwrap();

      if (status === 'OK') {
        m.success(t('publish_success'));
      } else {
        m.error(t('error.publish_failed'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.publish_failed'));
      }
    }
  }, [publishIdea, id, t, m]);

  const handleDeleteIdea = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteIdea({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/ideas');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteIdea, navigate, id, t, m]);

  const tags = useMemo(() => {
    return getArray(idea?.tags).map((tag) => ({ label: tag.name, id: tag.id }));
  }, [idea]);

  const photos = useMemo(() => {
    return getArray(idea?.photos).map((photo) => ({ src: photo }));
  }, [idea]);

  const withRelatedProjects = ideaProjects?.projects?.length > 0;
  const withPhotos = photos.length > 0;
  const loading = isLoading || isFetching || deleteIdeaState.isLoading;

  const pageActions = {
    [t('page_actions.edit')]: {
      type: 'button',
      disabled: loading,
      variant: 'outlined',
      size: 'small',
      name: 'Edit',
      border: true,
      Component: IconButton,
      to: `${pathname}/edit`,
      aclConfig: {
        permissions: [PERMISSIONS.EDIT_IDEA],
      },
    },
    [t('page_actions.delete')]: {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeleteIdea,
      aclConfig: {
        permissions: [PERMISSIONS.DELETE_IDEA],
      },
    },
    [t('page_actions.create_project')]: {
      type: 'button',
      disabled: loading,
      size: 'small',
      radius: 2,
      onClick: () => {
        navigate('/learning/projects/new', {
          state: { source: 'idea', idea_id: idea?.id },
        });
      },
      aclConfig: {
        permissions: [PERMISSIONS.CREATE_PROJECT],
      },
    },
    [t('page_actions.block')]: {
      type: 'button',
      size: 'small',
      disabled: loading || publishIdeaState.isLoading,
      color: 'error',
      variant: 'outlined',
      onClick: () => {
        setBlockModalOpen(true);
      },
      aclConfig: {
        permissions: [PERMISSIONS.SUPER_ADMIN],
      },
    },
    ...(!idea?.approved && {
      [t('page_actions.make_public')]: {
        type: 'button',
        size: 'small',
        disabled: loading || publishIdeaState.isLoading,
        color: 'primary',
        onClick: handlePublishIdea,
        aclConfig: {
          permissions: [PERMISSIONS.SUPER_ADMIN],
        },
      },
    }),
  };

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={idea?.name}
          backUrl="/ideas"
          backTitle={t('back_button')}
          pageActions={pageActions}
        />
      }
    >
      <Article py={4}>
        <Container>
          {idea && (
            <ArticleGeneralInformation
              slug="Idea"
              author={idea.author}
              level={idea.level}
              description={idea.description}
              tags={tags}
              sources={idea.sources}
              dateCreated={formatDate(idea.date_created)}
              bookmarked={checkBookmarked(idea?.bookmarks, me?.id)}
              onBookmark={() => bookmark(idea, me?.id)}
            />
          )}

          {withPhotos && (
            <>
              <Center my={2} justifyContent="flex-start">
                <Center width={24} height={24}>
                  <Icon name="Image" />
                </Center>
                <Typography ml={0.5} variant="body2" color="textBlack.dark">
                  {t('gallery')}
                </Typography>
              </Center>
              <Gallery images={photos} />
            </>
          )}

          {withRelatedProjects && (
            <Box mt={4}>
              <Typography variant="subtitle3" color="textBlack.dark">
                {t('related_projects')}
              </Typography>
              <RelatedProjects projects={ideaProjects?.projects} gridProps={{ pl: 3, pt: 1.5 }} />
            </Box>
          )}
        </Container>
      </Article>

      <BlockIdeaModal
        open={blockModalOpen}
        onClose={() => {
          setBlockModalOpen(false);
        }}
        onDelete={handleDeleteIdea}
      />
    </ProjectLayout>
  );
};

export default Idea;
