import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth, useForm, useMessage } from 'hooks';
import { useGetMeQuery, useChangePasswordMutation, useUpdateAdminDetailsMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Paper, Form, Container, Tabs } from 'components';
import { IndividualSettingsForm, PageSubheader } from 'views';

import mapInitialData from './mapInitialData';
import mapRequest from './mapRequest';

const contentWidth = 936;

const IndividualSettingsTab = (props) => {
  const { id, tab, onTabChange } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'profile.settings' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const [changePassword, changePasswordState] = useChangePasswordMutation();
  const [updateAdminDetails, updateAdminDetailsState] = useUpdateAdminDetailsMutation();

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleUpdateSettings = useCallback(
    async (formData) => {
      try {
        const { password, new_password } = formData;

        if (new_password) {
          const { status } = await changePassword({ new_password, password }).unwrap();
          m.success(t(status === 'OK' ? 'update_success' : 'update_failed'));
        }

        const { status } = await updateAdminDetails({
          id: me?.profile?.id,
          ...mapRequest(me, formData),
        }).unwrap();

        if (status === 'OK') m.success(t('update_success'));
      } catch (err) {
        if (err?.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else {
          m.error(t('update_failed'));
        }
      }
    },
    [changePassword, updateAdminDetails, m, me, t],
  );

  useEffect(() => {
    if (!me) return;
    form.reset(mapInitialData(me));
  }, [me, form]);

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    changePasswordState.isLoading ||
    updateAdminDetailsState.isLoading;

  return (
    <Form id={`${tab}-form`} width="100%" form={form} onSubmit={handleUpdateSettings}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backTitle={t('back_title')}
            backUrl={`/profile/${id}`}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.save')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading || !form.formState.isDirty,
              },
            }}
          />
        }
      >
        <Container width={contentWidth}>
          <Box py={4}>
            <Typography
              width="100%"
              component="h3"
              mb={2}
              color="secondary.main"
              variant="subtitle3"
            >
              {t('page_title')}
            </Typography>
            <Tabs
              width={396}
              variant="fullWidth"
              value={tab}
              onValue={onTabChange}
              options={[
                {
                  label: 'User information',
                  value: id,
                },
                {
                  label: 'Individual settings',
                  value: `${id}/settings`,
                },
              ]}
            />
            <Box pt={3}>
              <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
                <IndividualSettingsForm
                  title={t('password')}
                  form={form}
                  valid={valid}
                  rules={rules}
                  me={me}
                />
              </Paper>
            </Box>
          </Box>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default IndividualSettingsTab;
