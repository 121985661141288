import { getArray, editorStateToRawHtml } from 'utils/helpers';

const mapRequest = (formData) => {
  const { name, tags, level, photos, description } = formData;

  return {
    name: name,
    photos: photos || [],
    level: level || '',
    description: editorStateToRawHtml(description) || undefined,
    tags: getArray(tags).map((tag) => tag.value),
    projects: [],
  };
};

export default mapRequest;
