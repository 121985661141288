import { useState, forwardRef, Fragment } from 'react';
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';
import { propagateRefs, getArray } from 'utils/helpers';

import { Button, Input } from 'components';

const Repeater = forwardRef((props, ref) => {
  const {
    value,
    name,
    children,
    addButtonText,
    AddButtonComponent,
    itemDefaultValue,
    keyProperty,
    onChange,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'Repeater' });

  const item_f = children;

  const addAfter = (idx) => {
    if (itemDefaultValue) {
      const val = typeof itemDefaultValue === 'function' ? itemDefaultValue(idx) : itemDefaultValue;
      typeof onChange === 'function' && onChange([...value, { ...val }]);
    }
  };

  const remove = (idx) => {
    if (value.length <= 1) return;
    const newValue = [...value];
    newValue.splice(idx, 1);
    typeof onChange === 'function' && onChange(newValue);
  };

  return (
    <Stack spacing={1} ref={ref}>
      {getArray(value).map((row, index) => {
        const itemName = `${name}[${index}]`;
        return (
          <Fragment key={`${itemName}-${row[keyProperty]}`}>
            {typeof item_f === 'function' && item_f({ row, name: itemName, index, remove })}
          </Fragment>
        );
      })}

      {AddButtonComponent ? (
        <AddButtonComponent count={value?.length} onClick={() => addAfter(value?.length - 1)} />
      ) : (
        <Box>
          <Button
            type="button"
            variant="text"
            iconLeft="Add"
            onClick={() => addAfter(value?.length - 1)}
          >
            {addButtonText || t('add_new')}
          </Button>
        </Box>
      )}
    </Stack>
  );
});

Repeater.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue = [], children, onChange, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const {
          value: controlValue,
          ref: controlRef,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };

        return (
          <Repeater
            name={name}
            value={controlValue}
            error={error?.message || !!error}
            ref={propagateRefs(controlRef, ref)}
            onChange={handleOnChange}
            {...rest}
            {...restField}
          >
            {children}
          </Repeater>
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [demoItems, setDemoItems] = useState([
      { name: 'string0' },
      { name: 'string1' },
      { name: 'string2' },
    ]);

    return (
      <Box p={2}>
        <Repeater
          value={demoItems}
          name="sources"
          onAdd={() => {
            setDemoItems([...demoItems, { name: `string${demoItems.length}` }]);
          }}
        >
          {({ name, row }) => {
            return (
              <Box key={name}>
                <Input label={`${name}.name - ${row.name}`} name={`${name}.name`} />
              </Box>
            );
          }}
        </Repeater>
      </Box>
    );
  };

  Repeater.Demo = Demo;
}

export default Repeater;
