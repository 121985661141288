import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps, useRules } from 'hooks';

import { Form, FormRow, PhoneField, Input, FormTitle, TextEditor } from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const StudentHealthDetailsForm = (props) => {
  const { id, onSubmit, form } = props;

  const { phone } = useRules();

  const { t } = useTranslation('views', { keyPrefix: 'StudentHealthDetailsForm' });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('emergency_contact', { count: 1 })}
        </Typography>
      </FormTitle>
      <Stack mt={2} mb={2} width="100%">
        <FormRow label={t('emergency_contact_name.label')}>
          <Input.Control
            fullWidth
            name="first_contact_name"
            placeholder={t('emergency_contact_name.placeholder')}
          />
        </FormRow>

        <FormRow label={t('emergency_contact_phone.label')}>
          <PhoneField.Control
            optional
            fullWidth
            name="first_contact_phone"
            placeholder={t('emergency_contact_phone.placeholder')}
            rules={{
              phone,
            }}
          />
        </FormRow>
      </Stack>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('emergency_contact', { count: 2 })}
        </Typography>
      </FormTitle>
      <Stack mt={2} mb={2} width="100%">
        <FormRow label={t('emergency_contact_name.label')}>
          <Input.Control
            fullWidth
            name="second_contact_name"
            placeholder={t('emergency_contact_name.placeholder')}
          />
        </FormRow>

        <FormRow label={t('emergency_contact_phone.label')}>
          <PhoneField.Control
            optional
            fullWidth
            name="second_contact_phone"
            placeholder={t('emergency_contact_phone.placeholder')}
            rules={{
              phone,
            }}
          />
        </FormRow>
      </Stack>

      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('notes')}
        </Typography>
      </FormTitle>
      <Stack mt={2} mb={2} width="100%">
        <FormRow label={t('note.label')} alignItems="flex-start">
          <TextEditor.Control optional fullWidth name="note" placeholder={t('note.placeholder')} />
        </FormRow>
      </Stack>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('allergies')}
        </Typography>
      </FormTitle>
      <Stack mt={2} mb={2} width="100%">
        <FormRow label={t('food_allergies.label')} alignItems="flex-start">
          <TextEditor.Control
            optional
            fullWidth
            name="food_allergies"
            placeholder={t('food_allergies.placeholder')}
            styleControlsLayout={['block']}
          />
        </FormRow>
        <FormRow label={t('drugs_allergies.label')} alignItems="flex-start">
          <TextEditor.Control
            optional
            fullWidth
            name="drugs_allergies"
            placeholder={t('drugs_allergies.placeholder')}
            styleControlsLayout={['block']}
          />
        </FormRow>
      </Stack>
    </Form>
  );
};

export default StudentHealthDetailsForm;
