import { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUsersSearch, useMessage } from 'hooks';
import { useGetMeQuery, useSharePortfolioMutation } from 'store/api';

import { DropdownMenu, Button, Select, Center, CopyToClipboard } from 'components';
import { ROLE } from 'utils/constants';

const SharePortfolio = (props) => {
  const { portfolioId, children, onClick, ...rest } = props;

  const m = useMessage();
  const { t } = useTranslation('pages', { keyPrefix: 'single_portfolio.share_portfolio' });

  const [user, setUser] = useState(null);

  const [sharePortfolio, sharePortfolioState] = useSharePortfolioMutation();
  const { data: me, isLoading, isFetching } = useGetMeQuery(undefined);

  const {
    options: userOptions,
    tempSearch: userSearch,
    onSearch: onUserSearch,
  } = useUsersSearch({
    spaceId: me?.space_id,
    disabled: isLoading || isFetching,
    mapToOptions: true,
    filters: {
      roles: [ROLE.TEACHER, ROLE.ADMIN, ROLE.STUDENT],
      groups: [],
    },
  });

  const handleClose = useCallback(() => {
    setUser(null);
  }, []);

  const handleSubmit = async () => {
    try {
      const { status } = await sharePortfolio({ id: portfolioId, user_id: me?.id }).unwrap();

      if (status === 'OK') {
        m(t('success_message'));
        handleClose();
      }
    } catch (err) {
      console.error(err);
      m(t('error'));
    }
  };

  return (
    <DropdownMenu onAfterClose={handleClose} target={<Button {...rest}>{children}</Button>}>
      <Box width={332}>
        <Select
          collapse
          box={false}
          name="user"
          iconLeft="User"
          placeholder={t('choose_user')}
          fullWidth
          value={user}
          onValue={setUser}
          options={userOptions}
          searchable
          search={userSearch}
          onSearch={(v) => onUserSearch(null, v)}
          itemsMax={5}
        />
        <Center pb={2} justifyContent="space-between">
          <Box mt={1} display="flex" gap={1}>
            <Button
              type="button"
              disabled={!user || sharePortfolioState?.isLoading}
              size="small"
              radius={2}
              onClick={handleSubmit}
            >
              {t('submit')}
            </Button>
            <Button
              disabled={!user}
              variant="outlined"
              size="small"
              radius={2}
              onClick={handleClose}
            >
              {t('cancel')}
            </Button>
          </Box>
          <CopyToClipboard text={window.location.href} copyText={t('link_copied')}>
            <Button
              type="button"
              variant="outlined"
              size="small"
              radius={2}
              iconLeft="Link"
              sx={{ paddingRight: 0 }}
            >
              {t('copy_link')}
            </Button>
          </CopyToClipboard>
        </Center>
      </Box>
    </DropdownMenu>
  );
};

export default SharePortfolio;
