import { LinearProgress as MuiLinearProgress, styled } from '@mui/material';

const Root = styled(MuiLinearProgress)(({ theme }) => ({
  '&.MuiLinearProgress-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.success.contrastText,
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: theme.shape.borderRadius,
  },
}));

const LinearProgress = (props) => {
  return <Root {...props} />;
};

export default LinearProgress;
