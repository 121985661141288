import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';

import { dev } from 'utils/config';
import { keyCodes } from 'utils/helpers';
import { useCombineRefs } from 'hooks';
import Icon from 'components/Icon';
import Fieldset from 'components/Dev/Fieldset';

const Placeholder = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(),
  color: theme.palette.input.placeholder,
  opacity: 0.5,
  whiteSpace: 'nowrap',
}));

const Root = styled(Box, {
  label: 'InputContainer-Root',
  shouldForwardProp: (prop) =>
    !['error', 'disabled', 'fullWidth', 'startAdornment', 'endAdornment'].includes(prop),
})(({ theme, error, disabled, fullWidth, minWidth, startAdornment, endAdornment, cursor }) => ({
  ...theme.typography.body4,
  cursor: cursor || 'default',
  minWidth: minWidth || '40px',
  minHeight: '40px',
  maxHeight: '40px',
  width: fullWidth ? '100%' : 'auto',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.input.main,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows.small,
  display: 'inline-flex',
  alignItems: 'center',
  padding: [theme.spacing(1), theme.spacing(1.5)].join(' '),
  backgroundColor: theme.palette.other.white,
  outlineColor: 'transparent',
  outlineWidth: 0,
  paddingLeft: startAdornment ? theme.spacing(1) : theme.spacing(1.5),
  paddingRight: endAdornment ? theme.spacing(1) : theme.spacing(1.5),
  transition: theme.transitions.create(['box-shadow', 'border', 'width', 'color'], {
    duration: theme.transitions.duration.shortest,
  }),

  '& .MuiInputAdornment-root': {
    opacity: 0.5,
    color: theme.palette.primary.main,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
    }),
  },

  // Hover, focus
  '&:hover, &:focus': {
    boxShadow: theme.shadows.medium,
    borderColor: theme.palette.primary.light,

    ...(error && {
      borderColor: theme.palette.error.main,
    }),

    [`${Placeholder}`]: {
      opacity: 1,
    },
    '& .MuiInputAdornment-root': {
      opacity: 1,
    },
  },

  // Error
  ...(error && {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.light,
  }),

  // Disabled
  ...(disabled && {
    opacity: 0.5,
    boxShadow: 'none',
    pointerEvents: 'none',
  }),
}));

/**
 * Wrapper with project input styles.
 */
const InputContainer = forwardRef((props, ref) => {
  const { children, placeholder, error, disabled, fullWidth, iconLeft, iconRight, ...rest } = props;

  const rootRef = useRef(null);
  const handleRef = useCombineRefs(ref, rootRef);

  const startAdornment = useMemo(() => {
    if (iconLeft) {
      const thisIcon =
        typeof iconLeft === 'string' && Icon[iconLeft] ? <Icon name={iconLeft} /> : iconLeft;

      return <InputAdornment position="start">{thisIcon}</InputAdornment>;
    }
    return null;
  }, [iconLeft]);

  const endAdornment = useMemo(() => {
    if (iconRight) {
      const thisIcon =
        typeof iconRight === 'string' && Icon[iconRight] ? <Icon name={iconRight} /> : iconRight;

      return <InputAdornment position="end">{thisIcon}</InputAdornment>;
    }
    return null;
  }, [iconRight]);

  useEffect(() => {
    const { current: root } = rootRef;

    if (root) {
      const listenTrigger = (e) => {
        if (e.keyCode === keyCodes.enter || e.keyCode === keyCodes.space) {
          if (document.activeElement === root) {
            e.preventDefault();
            root.click();
          }
        }
      };
      const listenEsc = (e) => {
        if (e.keyCode === keyCodes.esc) {
          if (document.activeElement === root) {
            root.blur();
          }
        }
      };
      window.addEventListener('keydown', listenTrigger);
      window.addEventListener('keydown', listenEsc);

      return () => {
        window.removeEventListener('keydown', listenTrigger);
        window.removeEventListener('keydown', listenEsc);
      };
    }
  }, []);

  return (
    <Root
      ref={handleRef}
      tabIndex="0"
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      {...rest}
    >
      {startAdornment}

      <Box flexGrow={1} display="flex" alignItems="center">
        {placeholder && !children && <Placeholder>{placeholder}</Placeholder>}
        {children}
      </Box>

      {endAdornment}
    </Root>
  );
});

if (dev) {
  const Demo = () => {
    const [placeholder, setPlaceholder] = useState('Enter email');
    const [children, setChildren] = useState();
    const [error, setError] = useState();
    const [disabled, setDisabled] = useState();
    const [fullWidth, setFullWidth] = useState();
    const [iconLeft, setIconLeft] = useState();
    const [iconRight, setIconRight] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="placeholder"
            value={placeholder}
            onChange={setPlaceholder}
            options={[undefined, 'Enter email']}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, 'foo.bar@mail.com']}
          />
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="fullWidth"
            value={fullWidth}
            onChange={setFullWidth}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="iconLeft"
            value={iconLeft}
            onChange={setIconLeft}
            options={[undefined, 'Search', 'Calendar']}
          />
          <Fieldset.Field
            legend="iconRight"
            value={iconRight}
            onChange={setIconRight}
            options={[undefined, 'Eye', 'Birthday']}
          />
        </Fieldset>

        <InputContainer
          error={error}
          disabled={disabled}
          fullWidth={fullWidth}
          placeholder={placeholder}
          iconLeft={iconLeft}
          iconRight={iconRight}
        >
          {children}
        </InputContainer>
      </Box>
    );
  };
  InputContainer.Demo = Demo;
}

export default InputContainer;
