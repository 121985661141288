import { AppBar } from '@mui/material';

import { withProps } from 'hooks';
import { Center } from 'components';
import { ProjectButton } from 'views';

import HeaderNavigation from './HeaderNavigation';
import HeaderProfile from './HeaderProfile';

const Toolbar = withProps(Center, {
  px: 4,
  py: 1.5,
  height: '64px',
  maxHeight: '64px',
  justifyContent: 'stretch',
});

const Header = (props) => {
  const { empty } = props;

  return (
    <AppBar position="relative" sx={{ boxShadow: 'none' }}>
      <Toolbar>
        <ProjectButton mr={6.5} />

        {!empty && (
          <>
            <HeaderNavigation />
            <HeaderProfile />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
