import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';

import { useUsersSearch } from 'hooks';

import {
  FormTitle,
  FormRow,
  Input,
  TextEditor,
  SelectMultiple,
  Tag,
  Icon,
  DayTimePicker,
} from 'components';

const GroupForm = (props) => {
  const {
    spaceId,
    rules: { required },
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'GroupForm' });

  const {
    options: userOptions,
    tempSearch: userSearch,
    onSearch: onUserSearch,
  } = useUsersSearch({
    spaceId: spaceId,
    mapToOptions: true,
    filters: {
      limit: 50,
      roles: [],
      groups: [],
    },
  });

  const renderUserTag = useCallback(({ option, index, tagProps, onDelete }) => {
    return (
      <Tag
        key={`${option.label}-${index}`}
        label={option.label}
        clickable
        color="textBlack.light"
        bgcolor="input.main"
        size="medium"
        onClick={onDelete}
        avatar={option.avatar}
        avatarSize={24}
        endAdornment={<Icon color="error" name="Close" />}
        px={1}
        {...tagProps}
      />
    );
  }, []);

  return (
    <>
      <FormTitle width={1}>
        <Typography color="secondary.main" variant="body2">
          {t('group_information')}
        </Typography>
      </FormTitle>

      <Stack mt={2} gap={2} width={1}>
        <FormRow label={t('name.label')}>
          <Input.Control
            fullWidth
            name="name"
            initFocus
            placeholder={t('name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow optional label={t('day_and_time.label')} alignItems="flex-start">
          <DayTimePicker.Control name="day_and_time" />
        </FormRow>
      </Stack>

      <FormTitle width={1} mt={3}>
        <Typography color="secondary.main" variant="body2">
          {t('place_of_gathering')}
        </Typography>
      </FormTitle>

      <Stack mt={2} width={1}>
        <FormRow optional label={t('address.label')}>
          <Input.Control fullWidth name="address" placeholder={t('address.placeholder')} />
        </FormRow>
      </Stack>

      <FormTitle width={1} mt={3}>
        <Typography color="secondary.main" variant="body2">
          {t('description')}
        </Typography>
      </FormTitle>

      <Stack mt={2} width={1}>
        <FormRow optional label={t('group_description.label')} alignItems="flex-start">
          <TextEditor.Control
            fullWidth
            name="description"
            placeholder={t('group_description.placeholder')}
          />
        </FormRow>
      </Stack>

      <FormTitle mt={2} width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('choose_group')}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        <FormRow label={t('leader_id.label')} alignItems="flex-start">
          <SelectMultiple.Control
            fullWidth
            chips={false}
            itemsMax={4}
            max={1}
            name="leader_id"
            placeholder={t('leader_id.placeholder')}
            options={userOptions}
            inputValue={userSearch}
            onInputChange={onUserSearch}
            renderPreviewItem={renderUserTag}
            rules={{ required }}
          />
        </FormRow>
      </Stack>

      <FormTitle mt={2} width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('add_students')}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        <FormRow label={t('participants.label')} alignItems="flex-start">
          <SelectMultiple.Control
            fullWidth
            chips={false}
            itemsMax={4}
            name="participants"
            placeholder={t('participants.placeholder')}
            options={userOptions}
            inputValue={userSearch}
            onInputChange={onUserSearch}
            renderPreviewItem={renderUserTag}
          />
        </FormRow>
      </Stack>
    </>
  );
};

export default GroupForm;
