import { useMemo, useState } from 'react';
import { Stack, styled, Box } from '@mui/material';

import { dev } from 'utils/config';
import { generateArray } from 'utils/helpers';

import { IconButton, Button } from 'components';

const PAGES_TO_REVEAL = 3;

const PaginationButton = styled(Button, { label: 'Pagination-Button' })(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: theme.shape.borderRadius,
}));

const Pagination = (props) => {
  const { total = 0, perPage = 10, page = 1, loading, onChangePage, ...rest } = props;

  const pages = useMemo(() => {
    const totalPages = Math.ceil(total / perPage);
    return generateArray(totalPages);
  }, [total, perPage]);

  const pagination = useMemo(() => {
    const totalPages = pages.length;

    if (totalPages <= PAGES_TO_REVEAL) return pages;
    const localPages = [...pages];

    if (page < PAGES_TO_REVEAL) {
      localPages.splice(PAGES_TO_REVEAL, totalPages - 1 - PAGES_TO_REVEAL, '...');
    } else if (page > totalPages - PAGES_TO_REVEAL) {
      localPages.splice(1, totalPages - 1 - PAGES_TO_REVEAL, '...');
    } else {
      localPages.splice(1, page - PAGES_TO_REVEAL, '...');
      localPages.splice(
        2 + PAGES_TO_REVEAL,
        localPages.length - PAGES_TO_REVEAL - PAGES_TO_REVEAL,
        '...',
      );
    }

    return localPages;
  }, [page, pages]);

  const prevPage = () => onChangePage(page - 1);
  const nextPage = () => onChangePage(page + 1);
  const goToPage = (n) => onChangePage(n);

  if (!total) return null;

  return (
    <Stack direction="row" spacing={1} {...rest}>
      <IconButton
        disabled={page === 1 || loading}
        radius={1}
        border
        variant="outlined"
        color="tag"
        name="ArrowLeft"
        onClick={prevPage}
      />
      {pagination.map((n, index) => (
        <PaginationButton
          key={`page-${n}-${index}`}
          size="small"
          variant="outlined"
          color={page === n ? 'primary' : 'tag'}
          onClick={() => n !== '...' && goToPage(n)}
        >
          {n}
        </PaginationButton>
      ))}

      <IconButton
        disabled={page === pages.length || loading}
        radius={1}
        border
        variant="outlined"
        color="tag"
        name="ArrowRight"
        onClick={nextPage}
      />
    </Stack>
  );
};

if (dev) {
  const Demo = () => {
    const [page, setPage] = useState(2);

    return (
      <Box p={2}>
        <Pagination total={200} page={page} perPage={10} onChangePage={setPage} />
      </Box>
    );
  };

  Pagination.Demo = Demo;
}

export default Pagination;
