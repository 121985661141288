// String with 9 to 11 digits
export const phoneRegexp = /^\d{9,11}$/;

export const emailRegexp =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const descriptionSpecialsRegexp = /[@#$^&*]/;

export const includesNumbersRegexp = /\d/;

export const includesSpecialsRegexp = /[!@#$%^&*()_]/;

export const includesUppercaseRegexp = /[A-Z]/;
