import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Collapse } from '@mui/material';

import { getArray, editorStateToRawHtml, makeOptions } from 'utils/helpers';

import {
  useGetMeQuery,
  useUploadTemporaryFileMutation,
  useCreateStudentMutation,
  useCreateStudentHealthDetailsMutation,
  useUpdateStudentHealthDetailsMutation,
  useUpdateStudentMutation,
  useUpdateStudentPermissionsMutation,
  useCreateParentMutation,
  useUpdateParentMutation,
  useGetRelationsQuery,
} from 'store/api';
import { useForm, useMessage, useAuth, useGetParentProfile } from 'hooks';

import { ProjectLayout } from 'layouts';
import {
  PageSubheader,
  StudentPersonalInformationForm,
  StudentGuardiansInformationForm,
  StudentHealthDetailsForm,
  StudentSettingsForm,
} from 'views';
import { Container, Paper, Stepper } from 'components';

import { mapParentToFormData, mapParentsToHealthData } from './mappers';
import { updateParentRelations } from './helpers';

const contentWidth = 936;

const FORMS = {
  PERSONAL_INFORMATION: 'personal_information',
  PARENTS_INFORMATION: 'parents_information',
  HEALTH_DETAILS: 'health_details',
  SETTINGS: 'settings',
};

const STEPS = [
  { id: FORMS.PERSONAL_INFORMATION },
  { id: FORMS.PARENTS_INFORMATION },
  { id: FORMS.HEALTH_DETAILS },
  { id: FORMS.SETTINGS },
];

const NewStudent = (props) => {
  const { backUrl = '/administration/users' } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'new_student' });
  const { auth, progress } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { form: personalInformationForm, valid: personalInformationFormValid } = useForm();
  const { form: parentsInformationForm, valid: parentsInformationFormValid } = useForm();
  const { form: healthDetailsForm, valid: healthDetailsFormValid } = useForm();
  const { form: settingsForm, valid: settingsFormValid } = useForm();

  const studentId = personalInformationForm.watch('student_id');

  const [step, setStep] = useState(0);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data: relations } = useGetRelationsQuery();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [createStudent, createStudentState] = useCreateStudentMutation();
  const [createStudentHealthDetails, createStudentHealthDetailsState] =
    useCreateStudentHealthDetailsMutation();
  const [updateStudentHealthDetails, updateStudentHealthDetailsState] =
    useUpdateStudentHealthDetailsMutation();
  const [updateStudent, updateStudentState] = useUpdateStudentMutation();
  const [updateStudentPermissions, updateStudentPermissionsState] =
    useUpdateStudentPermissionsMutation();
  const [createParent, createParentState] = useCreateParentMutation();
  const [updateParent, updateParentState] = useUpdateParentMutation();
  const [getParentProfile, parentProfileState] = useGetParentProfile();

  const submitStudentInformation = useCallback(
    async (formData) => {
      const {
        student_id,
        avatar_file_id = [],
        cached_avatar_id,
        avatar_last_modified,
        groups,
        birthday,
        ...restFormData
      } = formData;
      const [avatar] = avatar_file_id;

      let avatarId = cached_avatar_id;
      if (avatar instanceof File && avatar.lastModified !== avatar_last_modified) {
        try {
          const { file_id } = await uploadFile({ file: avatar }).unwrap();
          avatarId = file_id;
          personalInformationForm.setValue('cached_avatar_id', file_id);
          personalInformationForm.setValue(`avatar_last_modified`, avatar.lastModified);
        } catch (e) {
          console.error(e);
          m.error(t('error.failed_upload_photo'));
        }
      }

      const postData = {
        ...restFormData,
        avatar_file_id: avatarId,
        birth_date: birthday
          ? new Date(+birthday.year, birthday.month, +birthday.day, 2)
          : undefined,
        groups: getArray(groups).map((g) => g.value),
      };

      if (student_id) {
        try {
          await updateStudent({
            id: student_id,
            permissions: {},
            ...postData,
          }).unwrap();
        } catch (e) {
          m.error(t('error.failed_update_user'));
          return;
        }
      } else {
        try {
          const { user_id, avatar_url } = await createStudent({
            spaceId: me?.space_id,
            permissions: {},
            ...postData,
          }).unwrap();

          personalInformationForm.setValue('student_id', user_id);
          if (avatar_url) {
            personalInformationForm.setValue('avatar_file_id', [avatar_url]);
            personalInformationForm.setValue('cached_avatar_id', avatar_url);
          }
        } catch (e) {
          if (!!e?.data?.detail?.error) {
            return m.error(e.data.detail.error);
          }
          return m.error(t('error.failed_create_user'));
        }
      }

      setStep(1);
    },
    [me, m, t, personalInformationForm, uploadFile, createStudent, updateStudent],
  );

  const submitParentsInformation = useCallback(
    async (formData) => {
      if (!parentsInformationForm.formState.isDirty) return setStep(2);

      const studentId = personalInformationForm.getValues('student_id');
      if (!studentId) return setStep(0);

      const { parents } = formData;

      const mapParent = async (parent, index) => {
        const {
          parent_id,
          note,
          avatar_file_id,
          cached_avatar_id,
          avatar_last_modified,
          full_name,
          relation,
          notify_projects,
          api_relations,
          api_language,
          ...restParent
        } = parent;

        const [avatar] = avatar_file_id;

        let avatarId = cached_avatar_id || avatar;
        if (avatar instanceof File && avatar.lastModified !== avatar_last_modified) {
          try {
            const { file_id } = await uploadFile({ file: avatar }).unwrap();
            avatarId = file_id;
            parentsInformationForm.setValue(`parents.${index}.cached_avatar_id`, file_id);
            parentsInformationForm.setValue(
              `parents.${index}.avatar_last_modified`,
              avatar.lastModified,
            );
          } catch (e) {
            console.error(e);
            m.error(t('error.failed_upload_photo'));
          }
        }

        try {
          const postData = {
            ...restParent,
            studentId,
            parentId: parent_id,
            avatar_file_id: avatarId,
            full_name: getArray(full_name)[0]?.label,
            notes: editorStateToRawHtml(note) || undefined,
            permissions: {},
          };

          if (parent_id) {
            postData.relations = updateParentRelations(api_relations, {
              student_id: studentId,
              relation,
              notify_projects,
            });
            postData.language = api_language || 'en';

            parentsInformationForm.setValue(`parents.${index}.api_relations`, postData.relations);

            await updateParent(postData).unwrap();
          } else {
            const { user_id, avatar_url } = await createParent(
              Object.assign(postData, { notify_projects, relation }),
            ).unwrap();
            if (user_id) {
              parentsInformationForm.setValue(`parents.${index}.parent_id`, user_id);
            }
            if (avatar_url) {
              parentsInformationForm.setValue(`parents.${index}.avatar_file_id`, [avatar_url]);
              parentsInformationForm.setValue(`parents.${index}.cached_avatar_id`, avatar_url);
            }
          }

          return parent;
        } catch (e) {
          console.error(e);
          if (!!e?.data?.detail?.error) {
            return m.error(e.data.detail.error);
          } else {
            m.error(t('error.failed_create_parent', { name: getArray(full_name)[0]?.label }));
          }
        }

        return false;
      };

      const result = await Promise.all(parents.map(mapParent));
      if (result.some((v) => v === false)) return;

      healthDetailsForm.reset(mapParentsToHealthData(healthDetailsForm.getValues(), result));

      setStep(2);
    },
    [
      parentsInformationForm,
      healthDetailsForm,
      personalInformationForm,
      uploadFile,
      createParent,
      updateParent,
      m,
      t,
    ],
  );

  const submitHealthDetails = useCallback(
    async (formData) => {
      const studentId = personalInformationForm.getValues('student_id');
      if (!studentId) return setStep(0);

      const { health_id, note, food_allergies, drugs_allergies, ...healthDetails } = formData;

      const apiFn = health_id ? updateStudentHealthDetails : createStudentHealthDetails;

      try {
        const { health_id } = await apiFn({
          id: studentId,
          ...healthDetails,
          note: editorStateToRawHtml(note) || undefined,
          food_allergies: editorStateToRawHtml(food_allergies) || undefined,
          drugs_allergies: editorStateToRawHtml(drugs_allergies) || undefined,
        }).unwrap();

        if (health_id) {
          healthDetailsForm.setValue(`health_id`, health_id);
        }
      } catch (e) {
        console.error(e);
        if (!!e?.data?.detail?.error) {
          return m.error(e.data.detail.error);
        }

        return m.error(t('error.failed_update_health_details'));
      }

      setStep(3);
    },
    [
      healthDetailsForm,
      personalInformationForm,
      createStudentHealthDetails,
      updateStudentHealthDetails,
      m,
      t,
    ],
  );

  const submitSettings = useCallback(
    async (formData) => {
      const studentId = personalInformationForm.getValues('student_id');
      if (!studentId) return setStep(0);

      const { password, ...permissions } = formData;

      try {
        const { status } = await updateStudentPermissions({
          id: studentId,
          password,
          permissions,
        }).unwrap();

        if (status === 'OK') {
          navigate(`/administration/students/${studentId}`);
        }
      } catch (e) {
        if (!!e?.data?.detail?.error) {
          return m.error(e.data.detail.error);
        }

        m.error(t('error.failed_update_user_permissions'));
      }
    },
    [personalInformationForm, updateStudentPermissions, navigate, m, t],
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const submitFn = [
          submitStudentInformation,
          submitParentsInformation,
          submitHealthDetails,
          submitSettings,
        ][step];

        if (!submitFn) return;

        submitFn(formData);
      } catch (err) {}
    },
    [step, submitStudentInformation, submitParentsInformation, submitHealthDetails, submitSettings],
  );

  const handleSelectParentProfile = async (option, details) => {
    const { index } = details || {};

    const studentId = personalInformationForm.getValues('student_id');
    const parent = await getParentProfile(option.id);
    if (!parent) return;

    const values = parentsInformationForm.getValues();
    parentsInformationForm.reset({
      parents: getArray(values?.parents).map((p, i) => {
        if (i === index) return mapParentToFormData(parent, studentId);
        return p;
      }),
    });
  };

  const stepsWithLabels = useMemo(
    () => STEPS.map((s) => ({ ...s, label: t(`steps.${s.id}`) })),
    [t],
  );

  const isValidStep = {
    [FORMS.PERSONAL_INFORMATION]: personalInformationFormValid,
    [FORMS.PARENTS_INFORMATION]:
      parentsInformationFormValid || !parentsInformationForm.formState.isDirty,
    [FORMS.HEALTH_DETAILS]: healthDetailsFormValid,
    [FORMS.SETTINGS]: settingsFormValid,
  }[STEPS[step]?.id];

  const isLastStep = STEPS.length - 1 === step;
  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadFileState.isLoading ||
    updateStudentState.isLoading ||
    updateStudentPermissionsState.isLoading ||
    createStudentHealthDetailsState.isLoading ||
    updateStudentHealthDetailsState.isLoading ||
    createStudentState.isLoading ||
    createParentState.isLoading ||
    updateParentState.isLoading ||
    parentProfileState.isLoading;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={backUrl}
          backTitle={t('page_actions.back_button')}
          pageActions={{
            [t('page_actions.previous_step')]: {
              type: 'button',
              disabled: loading || step === 0,
              variant: 'outlined',
              size: 'small',
              iconLeft: 'ArrowLeft',
              radius: 2,
              onClick: () => setStep(step - 1),
            },
            ...(isLastStep
              ? {
                  [t('page_actions.create')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    form: STEPS[step]?.id,
                  },
                }
              : {
                  [t('page_actions.next_step')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    iconRight: 'ArrowRight',
                    form: STEPS[step]?.id,
                  },
                }),
          }}
        />
      }
    >
      <Container width={contentWidth} py={4} position="relative">
        <Stepper currentStep={step} steps={stepsWithLabels} />

        {STEPS[step] && (
          <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
            {t(`page_title.${STEPS[step].id}`)}
          </Typography>
        )}

        <Paper shadow="standard" width="100%" overflow="visible" pt={3} pb={5} px={9}>
          <Collapse sx={{ width: '100%' }} in={step === 0}>
            <StudentPersonalInformationForm
              me={me}
              id={FORMS.PERSONAL_INFORMATION}
              studentId={studentId}
              visible={step === 0}
              form={personalInformationForm}
              onSubmit={handleSubmit}
            />
          </Collapse>
          <Collapse sx={{ width: '100%' }} in={step === 1}>
            <StudentGuardiansInformationForm
              id={FORMS.PARENTS_INFORMATION}
              spaceId={me?.space_id}
              visible={step === 0}
              form={parentsInformationForm}
              onSubmit={handleSubmit}
              relations={makeOptions(relations?.relations)}
              onSelectParentProfile={handleSelectParentProfile}
            />
          </Collapse>
          <Collapse sx={{ width: '100%' }} in={step === 2}>
            <StudentHealthDetailsForm
              id={FORMS.HEALTH_DETAILS}
              visible={step === 0}
              form={healthDetailsForm}
              onSubmit={handleSubmit}
            />
          </Collapse>
          <Collapse sx={{ width: '100%' }} in={step === 3}>
            <StudentSettingsForm
              id={FORMS.SETTINGS}
              visible={step === 0}
              form={settingsForm}
              onSubmit={handleSubmit}
            />
          </Collapse>
        </Paper>
      </Container>
    </ProjectLayout>
  );
};

export default NewStudent;
