import { createSelector, createSlice } from '@reduxjs/toolkit';

const name = 'user';

const initialState = {
  role: null,
  logged_in: !!localStorage.getItem('access_token'),
};

const selectRoot = (store) => store[name];

const selectIsLoggedIn = createSelector(
  selectRoot,
  (state) => state.logged_in,
);

const selectors = {
  selectIsLoggedIn,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    login: (state, { payload }) => {
      const { access_token } = payload;
      localStorage.setItem('access_token', access_token);
      state.logged_in = true;
    },
    logout: (state) => {
      localStorage.removeItem('access_token');
      state.logged_in = false;
    },
  },
});

slice.selectors = selectors;

export default slice;
