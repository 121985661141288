import { useMemo, useCallback, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS, ROLE } from 'utils/constants';
import { formatDate, getArray } from 'utils/helpers';
import { withProps, useAuth, useMessage } from 'hooks';
import {
  useGetMeQuery,
  useGetProjectQuery,
  useDeleteProjectMutation,
  useAssignProjectMutation,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, Gallery, Icon, IconButton } from 'components';
import { ArticleGeneralInformation, PageSubheader, Steps, AssignProjectModal } from 'views';
import MetaData from 'views/ArticleGeneralInformation/MetaData';

import AssignedStudents from './AssignedStudents';
import RequestProject from './RequestProject';
import RequestSuccessModal from './RequestSuccessModal';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Project = () => {
  const { id } = useParams();

  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_project' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: project,
    isLoading,
    isFetching,
  } = useGetProjectQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [requestSuccessModalOpen, setRequestSuccessModalOpen] = useState(false);

  const [deleteProject, deleteProjectState] = useDeleteProjectMutation();
  const [assignProject, assignProjectState] = useAssignProjectMutation();

  const handleDeleteProject = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteProject({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/learning/projects');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteProject, navigate, id, t, m]);

  const closeAssignModal = useCallback(() => {
    setAssignModalOpen(false);
  }, [setAssignModalOpen]);

  const handleAssignProject = useCallback(
    async ({ student, group, self }) => {
      try {
        const { project_ids } = await assignProject({
          id,
          student_id: student || undefined,
          group_id: group || undefined,
        }).unwrap();

        if (project_ids?.length === 0) return;

        m.success(t('project_assigned'));
        closeAssignModal();

        if (self) {
          navigate(`/learning/student-project/${project_ids[0]}`);
        }
      } catch (err) {
        console.error(err);
        m.error(t('error.failed_assign_project'));
      }
    },
    [id, m, t, assignProject, closeAssignModal, navigate],
  );

  const tags = useMemo(() => {
    return getArray(project?.tags).map((tag) => ({ label: tag.name, id: tag.id }));
  }, [project]);

  const photos = useMemo(() => {
    return getArray(project?.photos).map((photo) => ({ src: photo }));
  }, [project]);

  const isProjectRequested = useMemo(() => {
    if (!project?.requested_users?.length || !me) return false;
    const { requested_users } = project;

    const found = requested_users.find((user) => !!user[me?.id]);
    if (!found) return;

    const now = new Date();
    const requestDate = new Date(found[me?.id]);

    const diffTime = Math.abs(now - requestDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 1;
  }, [project, me]);

  const isProjectAssigned = useMemo(() => {
    if (!project || !me) return false;
    const { assigned_students } = project;
    return (
      assigned_students?.length > 0 &&
      assigned_students.findIndex((student) => student?.id === me.id) !== -1
    );
  }, [project, me]);

  const collectionLinks = useMemo(() => {
    if (!project?.collections?.length) return [];
    return project.collections.map((collection) => ({
      label: collection.name,
      to: `/learning/collections/${collection.id}`,
    }));
  }, [project]);

  const withPhotos = project?.photos?.length > 0;
  const withSteps = project?.steps?.length > 0;
  const loading = isLoading || isFetching || deleteProjectState.isLoading;

  const pageActions = {
    [t('page_actions.edit')]: {
      type: 'button',
      disabled: loading,
      variant: 'outlined',
      size: 'small',
      name: 'Edit',
      border: true,
      Component: IconButton,
      to: `${pathname}/edit`,
      aclConfig: {
        roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
        permissions: [PERMISSIONS.EDIT_PROJECT],
      },
    },
    [t('page_actions.delete')]: {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeleteProject,
      aclConfig: {
        roles: [ROLE.ADMIN, ROLE.TEACHER],
        permissions: [PERMISSIONS.DELETE_PROJECT],
      },
    },
    [t(isProjectAssigned ? 'page_actions.project_assigned' : 'page_actions.work_on_project')]: {
      type: 'button',
      size: 'small',
      disabled: loading || assignProjectState.isLoading || isProjectAssigned,
      variant: 'outlined',
      onClick: () => handleAssignProject({ student: me?.id, self: true }),
      radius: 2,
      sx: { paddingLeft: 0, paddingRight: 0 },
      aclConfig: { roles: [ROLE.ADMIN, ROLE.TEACHER], permissions: [PERMISSIONS.ASSIGN_USER] },
    },
    [t('page_actions.assign_project')]: {
      type: 'button',
      size: 'small',
      disabled: loading || assignProjectState.isLoading,
      radius: 2,
      sx: { paddingLeft: 0, paddingRight: 0 },
      onClick: () => setAssignModalOpen(true),
      aclConfig: { roles: [ROLE.ADMIN, ROLE.TEACHER], permissions: [PERMISSIONS.ASSIGN_USER] },
    },
    [isProjectRequested ? t('page_actions.project_requested') : t('page_actions.request_project')]:
      {
        type: 'button',
        size: 'small',
        disabled: loading || isProjectRequested,
        radius: 2,
        sx: { paddingLeft: 0, paddingRight: 0 },
        projectId: id,
        Component: RequestProject,
        onSuccess: () => setRequestSuccessModalOpen(true),
        aclConfig: { roles: [ROLE.STUDENT], permissions: [PERMISSIONS.REQUEST_PROJECT] },
      },
  };

  return (
    <ProjectLayout
      subheader={
        project && (
          <PageSubheader
            title={project.name}
            backUrl="/learning/projects"
            backTitle={t('back_button')}
            pageActions={pageActions}
          />
        )
      }
    >
      <Article py={4}>
        <Container>
          {!loading && project && (
            <ArticleGeneralInformation
              slug="Project"
              author={project.author}
              description={project.description}
              level={project.level}
              tags={tags}
              sources={project.sources}
              dateCreated={formatDate(project.date_created)}
              customMetaData={
                <MetaData>
                  {project.idea && (
                    <MetaData.Relation
                      title="Idea:"
                      link={{ to: `/ideas/${project.idea.id}`, label: project.idea.name }}
                    />
                  )}
                  {collectionLinks?.length > 0 ? (
                    <MetaData.Relation title="Collection:" links={collectionLinks} />
                  ) : undefined}
                </MetaData>
              }
            />
          )}

          {withPhotos && (
            <>
              <Center my={2} justifyContent="flex-start">
                <Center width={24} height={24}>
                  <Icon name="Image" />
                </Center>
                <Typography ml={0.5} variant="body2" color="textBlack.dark">
                  {t('gallery')}
                </Typography>
              </Center>
              <Gallery images={photos} />
            </>
          )}

          {withSteps && (
            <>
              <Typography variant="subtitle3" color="textBlack.dark">
                {t('project_steps')}
              </Typography>
              <Steps mt={2} steps={project?.steps} />
            </>
          )}

          {project?.assigned_students?.length > 0 ? (
            <AssignedStudents students={project.assigned_students} mb={4} />
          ) : undefined}
        </Container>
      </Article>

      <AssignProjectModal
        spaceId={project?.space_id}
        open={assignModalOpen}
        onClose={closeAssignModal}
        loading={assignProjectState.isLoading}
        onValue={handleAssignProject}
      />

      <RequestSuccessModal
        open={requestSuccessModalOpen}
        onClose={() => setRequestSuccessModalOpen(false)}
      />
    </ProjectLayout>
  );
};

export default Project;
