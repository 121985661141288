import { useMemo } from 'react';

import { useAuth } from 'hooks';

import { useGetMeQuery } from 'store/api';
import { hasPermission } from 'utils/helpers';

const isRoleAllowed = (roles, role) => {
  if (!roles) return true;
  return roles.indexOf(role) !== -1;
};

const useAcl = () => {
  const { auth } = useAuth();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  return useMemo(() => {
    return {
      allow: ({ roles, permissions }) => {
        const roleAllowed = isRoleAllowed(roles, me?.role);
        const permissionGranted =
          !permissions ||
          me?.profile?.permissions?.super_admin ||
          hasPermission(me?.profile?.permissions, permissions);

        return roleAllowed && permissionGranted;
      },
    };
  }, [me]);
};

export default useAcl;
