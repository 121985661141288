import { useEffect, useCallback } from 'react';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FILTER_TYPE, PERMISSIONS } from 'utils/constants';
import { useGetPortfoliosQuery, useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter, useAcl, useGetPortfolioCreationOptions } from 'hooks';
import { getArray } from 'utils/helpers';

import { ProjectLayout } from 'layouts';
import {
  Center,
  Icon,
  Input,
  Button,
  ProgressBox,
  Pagination,
  PaperCard,
  DropdownMenu,
} from 'components';
import { SidebarFilters, FiltersBar, CardContent } from 'views';

import mapRequest from './mapRequest';

const { selectFilters, selectActiveFilters, selectPageFilters, selectPagination } = selectors;

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const ActionsRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const PortfolioList = () => {
  const acl = useAcl();
  const { t } = useTranslation('pages', { keyPrefix: 'portfolio_list' });
  const { auth, progress } = useAuth();
  const {
    onSearch,
    tempSearch,
    onSetFilter,
    onSetActiveFilter,
    onResetPageFilter,
    onResetActiveFilters,
    onChangePage,
  } = usePageFilter();

  const filters = useSelector(selectFilters);
  const pageFilters = useSelector(selectPageFilters);
  const activeFilters = useSelector(selectActiveFilters);
  const pagination = useSelector(selectPagination);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data, isLoading, isFetching } = useGetPortfoliosQuery(
    mapRequest({ me, filters: pageFilters, pagination }),
    {
      skip: (auth && !me?.space_id) || progress,
    },
  );

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  useEffect(() => {
    if (!data) return;
    onSetFilter('users', FILTER_TYPE.USERS, { users: data.users, total: data.users_qty });
    onSetFilter('groups', FILTER_TYPE.GROUPS, {
      groups: data.groups,
      total: data.groups_qty,
    });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress;
  const options = useGetPortfolioCreationOptions();

  return (
    <ProjectLayout
      subheader={
        <Center flexGrow={1}>
          <PageTitle>
            <Typography variant="subtitle2">{t('subheader.title')}</Typography>
          </PageTitle>
          <Grid container width="100%" mx={0} spacing={3}>
            <Grid item xs={6}>
              <Input
                value={tempSearch}
                onValue={onSearch}
                iconLeft="Search"
                fullWidth={true}
                placeholder={t('subheader.page_search')}
              />
            </Grid>
            <Grid item xs={6}>
              {acl.allow({ permissions: [PERMISSIONS.CREATE_PORTFOLIO] }) && (
                <ActionsRoot>
                  <DropdownMenu
                    minWidth={200}
                    target={<Button iconRight="ArrowDown">{t('create_portfolio')}</Button>}
                    options={options}
                  />
                </ActionsRoot>
              )}
            </Grid>
          </Grid>
        </Center>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filtersConfig={{ tags: { grouped: true } }}
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Box p={4} pl={3}>
        <FiltersBar
          filters={activeFilters}
          mb={3}
          onRemove={handleRemoveSelectedFilters}
          onClear={onResetActiveFilters}
        />

        <ProgressBox loading={loading}>
          <Grid container spacing={3}>
            {getArray(data?.portfolios).map((portfolio) => (
              <Grid key={`portfolios-grid-${portfolio.id}`} item xs={6}>
                <PaperCard to={`/portfolio/${portfolio.id}`}>
                  <CardContent
                    variant="portfolio_list_item"
                    title={portfolio.name}
                    id={portfolio.id}
                    projectsQty={portfolio.projects_qty}
                    photo={portfolio.avatar}
                    userAvatar={portfolio.user.avatar}
                    username={portfolio.user.name}
                  />
                </PaperCard>
              </Grid>
            ))}
          </Grid>
        </ProgressBox>

        <Pagination
          total={data?.portfolios_qty}
          page={pagination?.page}
          mt={6}
          onChangePage={onChangePage}
        />
      </Box>
    </ProjectLayout>
  );
};

export default PortfolioList;
