import { useMemo, useState } from 'react';

const useToggle = (init) => {
  const [value, setValue] = useState(!!init);

  const toggle = useMemo(() => {
    const toggleHandler = function(v) {
      if (typeof v === 'boolean') {
        setValue(v);
        return;
      }
      setValue((prev) => !prev);
    };
    toggleHandler.off = () => {
      toggleHandler(false);
    };
    toggleHandler.on = () => {
      toggleHandler(true);
    };
    return toggleHandler;
  }, []);

  return useMemo(() => ([value, toggle]), [value, toggle]);
};

export default useToggle;
