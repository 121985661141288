import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const keyPrefix = 'signup.step3';

const Step3 = () => {
  const { t } = useTranslation('pages', { keyPrefix });

  return (
    <Box py={5} textAlign="center">
      <Typography variant="body3" color="textBlack.light">
        {t('check_your_email')}
      </Typography>
    </Box>
  );
};

export default Step3;
