import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import { useCombineRefs, useMixRules, useRules } from 'hooks';
import { dev } from 'utils/config';
import { getArray, propagateRefs } from 'utils/helpers';
import Input from 'components/Input';
import Fieldset from 'components/Dev/Fieldset';

const InputSection = styled(Input, {
  label: 'VerificationInput-Section',
})({
  input: {
    textAlign: 'center',
  },
});

/**
 * TODO: display error?
 * TODO: allow default value?
 * TODO: navigation with keyboard (backspace, arrow keys)?
 * 
 * Component could not be uncontrolled. value and onChange are required!
 * @prop {number} [gap=3] - Gap between inputs
 * @prop {number} sections - Required. Number of inputs
 * @prop {number} symbols - Required. Number of max symbols for each input
 * @prop {string['text'|'number']} [type] - Type of input values (not type of the inputs)
 * @prop {string[]} [value] - Array of inputs values
 * @prop {function} [onChange] - Returns an array of inputs new values
 * @prop {boolean} [initFocus] - Focus first section input when prop has changed to true.
 */
const VerificationInput = forwardRef((props, ref) => {
  const {
    gap = 3,
    sections,
    symbols,
    value,
    onChange,
    fullWidth,
    width,
    type,
    onBlur,
    onFocus,
    initFocus,
    ...rest
  } = props;

  const rootRef = useRef(null);
  const handleRef = useCombineRefs(rootRef, ref);

  useEffect(() => {
    const { current: root } = rootRef;

    if (root) {
      const inputs = root.querySelectorAll('input');
      let focusIndex = null;

      const listenFocus = (e) => {
        const inputIndex = [...inputs].findIndex((inp) => inp === e.target);

        if (Number.isFinite(inputIndex) && inputIndex <= sections) {
          if (focusIndex === null) {
            typeof onFocus === 'function' && onFocus(e);
          }
          focusIndex = inputIndex;
        }
      };
      const listenBlur = (e) => {
        if ([...inputs].every((inp) => inp !== e.relatedTarget)) {
          typeof onBlur === 'function' && onBlur(e);
        }
      };

      [...inputs].forEach((inp) => {
        inp.addEventListener('focus', listenFocus);
        inp.addEventListener('blur', listenBlur);
      });

      return () => {
        [...inputs].forEach((inp) => {
          inp.removeEventListener('focus', listenFocus);
          inp.removeEventListener('blur', listenBlur);
        });
      };
    }
  }, [sections, onFocus, onBlur]);

  const slices = useMemo(() => {
    const values = new Array(sections).fill('').map((v, i) => {
      return typeof getArray(value)[i] === 'string'
        ? value[i].slice(0, symbols)
        : v;
    }, []);

    return values;
  }, [value, sections, symbols]);

  const moveFocus = useCallback((index, v) => {
    const currentValue = v[index];
    const { current: root } = rootRef;

    if (!root) {
      return;
    }
    if (currentValue.length >= symbols) {
      const inputs = root.querySelectorAll('input');
      const currentInput = inputs[index];

      if (index + 1 < v.length) {
        const nextInput = inputs[index + 1];
        nextInput.focus();
      } else {
        currentInput.blur();
      }
    }
  }, [symbols]);

  const handleChange = useCallback((index) => (v) => {
    const newValue = slices
      .map((s, i) => index === i ? v : s)
      .map((s) => type === 'number' ? s.replace(/\D/g, '') : s);

    typeof onChange === 'function' && onChange(newValue);
    moveFocus(index, newValue);
  }, [slices, onChange, moveFocus, type]);

  return (
    <Box
      width={fullWidth ? '100%' : width}
      gap={gap}
      display="flex"
      ref={handleRef}
      {...rest}
    >
      {slices.map((v, i) => (
        <InputSection
          key={i}
          value={v}
          initFocus={i === 0 && initFocus}
          onValue={handleChange(i)}
          autoComplete={false}
          inputProps={{
            maxLength: symbols,
          }}
        />
      ))}
    </Box>
  );
});

const defValue = [''];

VerificationInput.Control = forwardRef((props, ref) => {
  const {
    name,
    rules: initRules,
    onBlur,
    onChange,
    sections,
    symbols,
    defaultValue = defValue,
    ...rest
  } = props;

  const { control, trigger } = useFormContext();
  const { required } = useRules();

  const validateSections = useCallback((v) => {
    const valid = getArray(v).join('').length === sections * symbols;
    return valid || required;
  }, [sections, symbols, required]);

  const rules = useMixRules(initRules, validateSections);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const {
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleOnChange = (v) => {
          controlOnChange(v);
          typeof onChange === 'function' && onChange(v);
        };
        const handleOnBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <VerificationInput
            symbols={symbols}
            sections={sections}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            ref={propagateRefs(ref, controlRef)}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [value, setValue] = useState();
    const [gap, setGap] = useState();
    const [sections, setSections] = useState(4);
    const [symbols, setSymbols] = useState(4);
    const [width, setWidth] = useState();
    const [type, setType] = useState();

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="gap"
            value={gap}
            onChange={setGap}
            options={[undefined, 2, 3, 5, 10]}
          />
          <Fieldset.Field
            legend="sections"
            value={sections}
            onChange={setSections}
            options={[4, 6, 8]}
          />
          <Fieldset.Field
            legend="symbols"
            value={symbols}
            onChange={setSymbols}
            options={[4, 2, 1]}
          />
          <Fieldset.Field
            legend="width"
            value={width}
            onChange={setWidth}
            options={[undefined, 600, 400, 300, 200, '100%']}
          />
          <Fieldset.Field
            legend="type"
            value={type}
            onChange={setType}
            options={[undefined, 'number', 'text']}
          />
        </Fieldset>

        <VerificationInput
          value={value}
          onChange={setValue}
          width={width}
          gap={gap}
          sections={sections}
          symbols={symbols}
          type={type}
        />
      </Box>
    )
  };
  VerificationInput.Demo = Demo;
}

export default VerificationInput;
