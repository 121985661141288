import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography, Box } from '@mui/material';

import { useRegisterSpaceMutation, useUploadTemporaryFileMutation } from 'store/api';

import { notebook } from 'assets/images/holders';
import { useMessage } from 'hooks';
import { GuestLayout } from 'layouts';
import { ImageHolder, Paper, Ref } from 'components';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';

const keyPrefix = 'signup';

const SignUp = () => {
  const { t } = useTranslation('pages', { keyPrefix });
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const m = useMessage();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [registerSpace, registerSpaceState] = useRegisterSpaceMutation();

  const loading = uploadFileState.isLoading || registerSpaceState.isLoading;

  const handleSubmitStep1 = useCallback((formData) => {
    setData((prev) => ({ ...prev, ...formData }));
    setStep(2);
  }, []);

  const handleSubmitStep2 = useCallback(
    async (formData) => {
      const result = { ...data, ...formData };
      const [file] = result.avatar;

      if (file) {
        try {
          const { file_id } = await uploadFile({ file }).unwrap();
          result.avatar_file_id = file_id;
        } catch (ex) {
          console.error(ex);
          m.error('error_avatar_upload');
          return;
        }
      }
      delete result.avatar;
      delete result.agree_tos;

      if (!result.email) {
        delete result.email;
      }
      if (!result.phone_number) {
        delete result.phone_number;
      }
      if (data.type === 'organization') {
        delete result.first_name;
        delete result.last_name;
      } else {
        delete result.organization;
      }

      try {
        await registerSpace(result).unwrap();

        if (!result.email) {
          return navigate('/phone-verification');
        }
        setStep(3);
      } catch (ex) {
        console.error(ex);

        if (ex.status === 422) {
          ex.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!ex?.data?.detail?.error) {
          return m.error(ex.data.detail.error);
        } else if (ex.status === 409) {
          return m.error(t('error_409'));
        } else {
          m.error(t('error_unknown'));
        }
      }
    },
    [data, uploadFile, registerSpace, navigate, m, t],
  );

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        src={notebook}
        origin="container"
        bgcolor="primary.main"
        alt={t('title_image.alt')}
      />
      <Typography mt={3} color="primary.dark" variant="subtitle2">
        {t('welcome')}
      </Typography>

      <Typography mb={3} color="primary.dark" variant="body3">
        {t('start_journey')}
      </Typography>

      <Paper width={696} alignItems="center" loading={loading}>
        <Collapse in={step === 1}>
          <Step1 visible={step === 1} onSubmit={handleSubmitStep1} />
        </Collapse>

        <Collapse in={step === 2}>
          <Step2
            visible={step === 2}
            type={data?.type}
            onCancel={() => setStep(1)}
            onSubmit={handleSubmitStep2}
          />
        </Collapse>

        <Collapse in={step === 3}>
          <Step3 />
        </Collapse>

        {step < 3 && (
          <Box mb={2} mt={1}>
            <Typography variant="body4" color="primary">
              {t('have_an_account')}
            </Typography>

            <span> </span>

            <Typography variant="button2" color="primary">
              <Ref to="/login">{t('login')}</Ref>
            </Typography>
          </Box>
        )}
      </Paper>
    </GuestLayout>
  );
};

export default SignUp;
