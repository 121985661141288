import { useGetStudentQuery, useGetParentQuery, useGetUserQuery } from 'store/api';
import { ROLE } from 'utils/constants';

const mapParent = (parent) => {
  if (!parent) return {};
  return {
    ...parent,
    profile: {
      id: parent.id,
      full_name: parent.full_name,
      avatar: parent.avatar,
    },
  };
};

const mapStudent = (student) => {
  if (!student) return {};
  return {
    ...student,
    profile: {
      ...student.profile,
      full_name: `${student.profile.first_name} ${student.profile.last_name}`,
    },
  };
};

const mapAdmin = (parent) => {
  if (!parent) return {};
  return {
    ...parent,
    profile: {
      ...parent.profile,
      full_name: parent.profile.name,
    },
  };
};

const useGetProfile = ({ id, role }) => {
  const {
    data: student,
    isLoading: studentLoading,
    isFetching: studentFetching,
  } = useGetStudentQuery(
    { id },
    {
      skip: !id || role !== ROLE.STUDENT,
    },
  );

  const {
    data: parent,
    isLoading: parentLoading,
    isFetching: parentFetching,
  } = useGetParentQuery(
    { id },
    {
      skip: !id || role !== ROLE.PARENT,
    },
  );

  const {
    data: user,
    isLoading: userLoading,
    isFetching: userFetching,
  } = useGetUserQuery(
    { user_id: id },
    {
      skip: !id || [ROLE.ADMIN, ROLE.TEACHER].indexOf(role) === -1,
    },
  );

  if (role === ROLE.STUDENT) return [mapStudent(student), studentLoading || studentFetching];
  if (role === ROLE.PARENT) return [mapParent(parent), parentLoading || parentFetching];
  if ([ROLE.ADMIN, ROLE.TEACHER].indexOf(role) !== -1)
    return [mapAdmin(user), userLoading || userFetching];
  return [];
};

export default useGetProfile;
