import { createSelector, createSlice } from '@reduxjs/toolkit';

const name = 'pageFilters';

const initialState = {
  searchValue: '',
  perPage: 10,
  page: 1,
  filters: {},
  activeFilters: {},
};

const selectRoot = (store) => store[name];

const selectFilters = createSelector(selectRoot, (state) => state.filters);
const selectActiveFilters = createSelector(selectRoot, (state) => state.activeFilters);
const selectSearch = createSelector(selectRoot, (state) => state.search);
const selectPageFilters = createSelector(selectRoot, (state) => ({
  search: state.searchValue,
  activeFilters: state.activeFilters,
}));
const selectPagination = createSelector(selectRoot, (state) => ({
  perPage: state.perPage,
  page: state.page,
}));

export const selectors = {
  selectFilters,
  selectActiveFilters,
  selectSearch,
  selectPageFilters,
  selectPagination,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setFilter: (state, { payload }) => {
      const { name, type, value } = payload;

      state.filters[name] = {
        type,
        value,
      };
    },
    setActiveFilter: (state, { payload }) => {
      const { name, value } = payload;
      state.activeFilters[name] = value;
    },
    setActiveFilters: (state, { payload }) => {
      const { filters } = payload;
      state.activeFilters = filters;
    },
    setSearch: (state, { payload }) => {
      const { search } = payload;
      state.searchValue = search;
    },
    setPagination: (state, { payload }) => {
      const { page, perPage } = payload;
      if (page) state.page = page;
      if (perPage) state.perPage = perPage;
    },
    resetPageFilter: () => initialState,
  },
});

slice.selectors = selectors;

export default slice;
