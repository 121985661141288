import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatPhoneNumber } from 'utils/helpers';

import { Paper, Center, Tag } from 'components';

const ParentCard = (props) => {
  const { name, phoneNumber, email, location, relation } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'user_profile.ParentCard' });

  return (
    <Paper width={1} shadow="standard">
      <Center width={1} mb={3} justifyContent="space-between">
        <Typography variant="subtitle5" color="textBlack.dark">
          {name}
        </Typography>
        {relation && <Tag size="medium" label={relation} bgcolor="input.main" />}
      </Center>
      <Stack gap={2} width={1}>
        <Center justifyContent="space-between">
          <Typography variant="body4" color="textBlack.light" minWidth={148}>
            {t('phone')}
          </Typography>
          <Typography variant="body2" color="textBlack.dark">
            {formatPhoneNumber(phoneNumber)}
          </Typography>
        </Center>
        {email && (
          <Center justifyContent="space-between" sx={{ '& a': { color: 'textRegular.light' } }}>
            <Typography variant="body4" color="textBlack.light" minWidth={148}>
              {t('email')}
            </Typography>
            <a href={`mailto:${email}`}>
              <Typography variant="body2">{email}</Typography>
            </a>
          </Center>
        )}
        {location && (
          <Center justifyContent="space-between">
            <Typography variant="body4" color="textBlack.light" minWidth={148}>
              {t('location')}
            </Typography>
            <Typography
              maxWidth={276}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              variant="body2"
              color="textBlack.dark"
            >
              {location}
            </Typography>
          </Center>
        )}
      </Stack>
    </Paper>
  );
};
export default ParentCard;
