import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack as MuiStack, Box, Typography, Grid } from '@mui/material';

import { allowedImageTypes, ROLE } from 'utils/constants';
import { editorStateToRawHtml, makeOptions, getArray, getProfileBasePath } from 'utils/helpers';

import {
  useUploadTemporaryFileMutation,
  useCreateParentMutation,
  useGetMeQuery,
  useGetRelationsQuery,
} from 'store/api';
import { useForm, useMessage, withProps, useAuth, useUsersSearch, useCountrySearch } from 'hooks';

import { ProjectLayout } from 'layouts';
import { PageSubheader } from 'views';

import {
  Container,
  Paper,
  Form,
  FormRow,
  PhoneField,
  Input,
  TextEditor,
  Dropzone,
  Select,
  Checkbox,
  SelectMultiple,
  FormTitle,
} from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const contentWidth = 936;

const FORM_ID = 'parents_information';

const NewParent = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_parent' });
  const m = useMessage();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const {
    form: parentsInformationForm,
    valid: parentsInformationFormValid,
    rules: { phone, email, required, startsWithUppercase },
  } = useForm();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [createParent, createParentState] = useCreateParentMutation();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { options: studentOptions, loading: studentsLoading } = useUsersSearch({
    spaceId: me?.space_id,
    disabled: isLoading || isFetching,
    mapToOptions: true,
    filters: {
      roles: ['student'],
      groups: [],
    },
  });

  const { data: relations } = useGetRelationsQuery();

  const {
    options: countryOptions,
    search: countrySearch,
    onSearch: onCountrySearch,
  } = useCountrySearch({
    mapToOptions: true,
  });

  const handleSubmit = useCallback(
    async (formData) => {
      const {
        note,
        avatar_file_id,
        cached_avatar_id,
        avatar_last_modified,
        studentId,
        country,
        phone_number,
        ...parent
      } = formData;

      const [avatar] = avatar_file_id;

      let avatarId = cached_avatar_id;
      if (avatar instanceof File && avatar.lastModified !== avatar_last_modified) {
        try {
          const { file_id } = await uploadFile({ file: avatar }).unwrap();
          avatarId = file_id;
          parentsInformationForm.setValue(`cached_avatar_id`, file_id);
          parentsInformationForm.setValue(`avatar_last_modified`, avatar.lastModified);
        } catch (e) {
          console.error(e);
          m.error(t('error.failed_upload_photo'));
        }
      }

      try {
        const { user_id } = await createParent({
          ...parent,
          country: country || '',
          studentId: getArray(studentId)[0]?.value,
          notes: editorStateToRawHtml(note) || undefined,
          permissions: {},
          avatar_file_id: avatarId,
          phone_number: phone_number || null,
        }).unwrap();

        if (user_id) return navigate(`${getProfileBasePath(ROLE.PARENT)}/${user_id}`);
      } catch (err) {
        console.error(err);
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error.failed_create_parent'));
        }
      }
    },
    [parentsInformationForm, uploadFile, createParent, m, navigate, t],
  );

  const loading = uploadFileState.isLoading || createParentState.isLoading || studentsLoading;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={`/administration/users`}
          backTitle={t('page_actions.back_button')}
          pageActions={{
            [t('page_actions.cancel')]: {
              type: 'button',
              disabled: loading || !parentsInformationForm.formState.isDirty,
              variant: 'outlined',
              size: 'small',
              radius: 2,
              onClick: () => parentsInformationForm.reset(),
            },
            [t('page_actions.create')]: {
              type: 'submit',
              disabled: loading || !parentsInformationFormValid,
              size: 'small',
              radius: 2,
              form: FORM_ID,
            },
          }}
        />
      }
    >
      <Container width={contentWidth} py={4} position="relative">
        <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
          {t(`page_title`)}
        </Typography>

        <Paper shadow="standard" width="100%" overflow="visible" pt={3} pb={5} px={9}>
          <Form id={FORM_ID} width="100%" form={parentsInformationForm} onSubmit={handleSubmit}>
            <Box position="relative">
              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('guardian_information')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow optional label={t('avatar.label')} alignItems="flex-start">
                  <Dropzone.Control fullWidth accept={allowedImageTypes} name={`avatar_file_id`} />
                </FormRow>
                <FormRow label={t('full_name.label')} alignItems="flex-start">
                  <Input.Control
                    fullWidth
                    type="text"
                    name="full_name"
                    placeholder={t('full_name.placeholder')}
                    rules={{
                      required,
                      validate: (v) => startsWithUppercase(v, t('full_name.label')),
                    }}
                  />
                </FormRow>
                <FormRow label={t('relation.label')} alignItems="flex-start">
                  <Grid container>
                    <Grid item xs={6} pr={1}>
                      <SelectMultiple.Control
                        fullWidth
                        name="studentId"
                        placeholder={t('related_to.placeholder')}
                        options={studentOptions}
                        itemsMax={3}
                        max={1}
                        rules={{ required }}
                      />
                    </Grid>
                    <Grid item xs={6} pl={1}>
                      <Select.Control
                        fullWidth
                        name={`relation`}
                        placeholder={t('relation.placeholder')}
                        options={makeOptions(relations?.relations)}
                        rules={{ required }}
                        max={1}
                      />
                    </Grid>
                  </Grid>

                  <Box pl={1}>
                    <Checkbox.Control
                      size="xsmall"
                      name={`notify_projects`}
                      label={t('notify_projects.placeholder')}
                    />
                  </Box>
                </FormRow>
              </Stack>
              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('contact_section')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow label={t('email.label')}>
                  <Input.Control
                    fullWidth
                    type="email"
                    name={`email`}
                    placeholder={t('email.placeholder')}
                    rules={{
                      validate: email,
                      required,
                    }}
                  />
                </FormRow>
                <FormRow optional label={t('phone_number.label')}>
                  <PhoneField.Control
                    optional
                    fullWidth
                    name={`phone_number`}
                    placeholder={t('phone_number.placeholder')}
                    rules={{
                      phone,
                    }}
                  />
                </FormRow>
              </Stack>

              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('address_section')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow label={t('address.label')} alignItems="flex-start" optional>
                  <Input.Control
                    fullWidth
                    name={`address`}
                    placeholder={t('address.placeholder')}
                  />
                </FormRow>
                <FormRow label={t('apart.label')} alignItems="flex-start" optional>
                  <Input.Control fullWidth name={`apart`} placeholder={t('apart.placeholder')} />
                </FormRow>
                <FormRow label={t('country.label')} alignItems="flex-start" optional>
                  <Select.Control
                    fullWidth
                    name={`country`}
                    placeholder={t('country.placeholder')}
                    itemsMax={4}
                    options={countryOptions}
                    searchable
                    search={countrySearch}
                    onSearch={onCountrySearch}
                  />
                </FormRow>
                <FormRow label={t('city.label')} alignItems="flex-start" optional>
                  <Input.Control fullWidth name={`city`} placeholder={t('city.placeholder')} />
                </FormRow>

                <FormRow label={t('state.label')} alignItems="flex-start" optional>
                  <Grid container>
                    <Grid item xs={6} pr={1}>
                      <Input.Control
                        fullWidth
                        name={`state`}
                        placeholder={t('state.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={6} pl={1}>
                      <Input.Control
                        fullWidth
                        name={`zipcode`}
                        placeholder={t('zipcode.placeholder')}
                      />
                    </Grid>
                  </Grid>
                </FormRow>

                <FormTitle width="100%">
                  <Typography color="secondary.main" variant="body2">
                    {t('notes')}
                  </Typography>
                </FormTitle>
                <Stack mt={2} mb={2} width="100%">
                  <FormRow label={t('note.label')} alignItems="flex-start" optional>
                    <TextEditor.Control
                      optional
                      fullWidth
                      name={`note`}
                      placeholder={t('note.placeholder')}
                    />
                  </FormRow>
                </Stack>
              </Stack>
            </Box>
          </Form>
        </Paper>
      </Container>
    </ProjectLayout>
  );
};

export default NewParent;
