import { useMemo } from 'react';
import { Modal as MuiModal, styled, Typography, Box, Stack } from '@mui/material';

import { Paper, IconButton } from 'components';

const StyledModal = styled(MuiModal)(({ theme }) => ({}));

const Root = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.success.contrastText,
  color: theme.palette.primary.dark,
  '&:hover, &:focus': {
    color: theme.palette.other.white,
  },
}));

const Title = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const FooterRoot = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export const modalSizes = {
  small: 365,
  medium: 703,
};

const Modal = (props) => {
  const {
    open,
    onClose,
    children,
    size = 'small',
    closeButtonSize = 'xsmall',
    loading,
    paperProps,
    ...rest
  } = props;

  const width = useMemo(() => {
    if (typeof size === 'string' && modalSizes[size]) {
      return modalSizes[size];
    }
    if (Number.isFinite(size)) {
      return size;
    }
    return modalSizes.small;
  }, [size]);

  return (
    <StyledModal open={open} onClose={onClose} {...rest}>
      <Root px={3} py={0} shadow="smooth" width={width} loading={loading} {...paperProps}>
        <CloseButton
          type="button"
          name="Close"
          size={closeButtonSize}
          color="success"
          onClick={onClose}
        />
        {children}
      </Root>
    </StyledModal>
  );
};

const ModalHeader = (props) => {
  const { title, ...rest } = props;
  return (
    <Title width={1} {...rest}>
      <Typography color="secondary.main" variant="body2">
        {title}
      </Typography>
    </Title>
  );
};

const ModalBody = (props) => {
  const { children, ...rest } = props;
  return (
    <Box width={1} py={3} {...rest}>
      {children}
    </Box>
  );
};

const ModalFooter = (props) => {
  const { children, ...rest } = props;
  return (
    <FooterRoot width={1} direction="row" pt={2} pb={3} spacing={1.5} {...rest}>
      {children}
    </FooterRoot>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
