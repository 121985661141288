import { forwardRef, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

import { dev } from 'utils/config';
import { useCombineRefs } from 'hooks';
import Fieldset from 'components/Dev/Fieldset';

/**
 * Parent must have a position prop setted to absolute or rleative (!)
 * @prop {boolean} [show] - Show ripple within container
 */
const Ripple = forwardRef((props, ref) => {
  const { show } = props;

  const rippleRef = useRef(null);
  const handleRef = useCombineRefs(ref, rippleRef);

  useEffect(() => {
    const { current: ripple } = rippleRef;

    if (ripple) {
      if (show) {
        ripple.pulsate();
      } else {
        ripple.stop({});
      }
      return () => {
        ripple.stop({});
      };
    }
  }, [show]);

  return (<TouchRipple center ref={handleRef} />);
});

if (dev) {
  const Demo = () => {
    const [show, setShow] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="show"
            value={show}
            onChange={setShow}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre">
          {`<Box position="relative" p={2} border="1px solid black" borderRadius={2}>\n`}
          {`  Target\n`}
          {`  <Ripple show={${show}} />\n`}
          {`</Box>\n`}
        </Box>

        <Box position="relative" p={2} border="1px solid black" borderRadius={2}>
          Target
          <Ripple show={show} />
        </Box>
      </Box>
    );
  };
  Ripple.Demo = Demo;
}

export default Ripple;
