import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { useValidateEmailMutation } from 'store/api';
import { mail_box } from 'assets/images/holders';
import { sleep } from 'utils/helpers';
import { GuestLayout } from 'layouts';
import { ImageHolder, Paper, Ref } from 'components';

const keyPrefix = 'email_verification';

const EmailVerification = () => {
  const { t } = useTranslation('pages', { keyPrefix });
  const [validateEmail, { isLoading, isSuccess, isError }] = useValidateEmailMutation();
  const [done, setDone] = useState();
  const isRequestMade = isLoading || isSuccess || isError;

  const handleSubmit = useCallback(async (formData) => {
    try {
      // Allow to read the message
      await sleep();
      await validateEmail(formData).unwrap();
      setDone(true);
    } catch (ex) {
      console.error(ex);
      setDone(false);
    }
  }, [validateEmail]);

  // TODO: reduce special symbols (+=, etc);
  const hash = window.location.search.split('hash=')[1];

  const message = useMemo(() => {
    if (done === undefined) {
      return t('please_wait');
    }
    if (done) {
      return (
        <Trans t={t} i18nKey="done">
          Done! Now you can <Ref to="/login">login</Ref>.
        </Trans>
      );
    }
    return t('error');
  }, [t, done]);

  useEffect(() => {
    if (done === undefined && !isRequestMade) {
      if (!hash) {
        setDone(false);
        return;
      }
      handleSubmit({ hash });
    }
  }, [hash, done, handleSubmit, isRequestMade]);

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        src={mail_box}
        origin="container"
        alt={t('title')}
        shadow="smooth"
      />
      <Typography my={3} color="primary.dark" variant="subtitle2">
        {t('title')}
      </Typography>

      <Paper
        py={10}
        width={696}
        alignItems="center"
        loading={isLoading || done === undefined}
      >
        <Typography color="textBlack.light" variant="body3">
          {message}
        </Typography>
      </Paper>
    </GuestLayout>
  );
};

export default EmailVerification;
