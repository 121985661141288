import { forwardRef, useState } from 'react';
import styled from '@mui/material/styles/styled';
import MuiProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { dev } from 'utils/config';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(MuiProgress, {
  label: 'Progress-Root',
})({
  '& svg circle': {
    strokeLinecap: 'round',
  },
});

const Progress = forwardRef((props, ref) => {
  const {
    sx,
    thickness = 8,
    color = 'primary.dark',
    ...rest
  } = props;

  return (
    <Root
      ref={ref}
      thickness={thickness}
      sx={{ color, ...sx }}
      {...rest}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [color, setColor] = useState();
    const [thickness, setThickness] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="color"
            value={color}
            onChange={setColor}
            options={[undefined, 'primary.dark', 'secondary.main', 'error.main', 'orange', '#aaa']}
          />
          <Fieldset.Field
            legend="thickness"
            value={thickness}
            onChange={setThickness}
            options={[undefined, 8, 1, 2, 3, 5, 10, 15, 25]}
          />
        </Fieldset>

        <Progress
          color={color}
          thickness={thickness}
        />
      </Box>
    );
  };
  Progress.Demo = Demo;
}

export default Progress;
