import { forwardRef, useState } from 'react';
import { Box, Badge as MuiBadge, styled } from '@mui/material';

import { dev } from 'utils/config';
import Icon from 'components/Icon';
import Fieldset from 'components/Dev/Fieldset';

const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    ...theme.typography.badge,
    '&:not(.MuiBadge-dot)': {
      top: 2,
      right: 1,
      height: 16,
      minWidth: 16,
      padding: '0 4px',
    },
  },
}));

// TODO: Add position, max props (if needed)
const Mark = forwardRef((props, ref) => {
  const {
    children,
    content,
    color = 'white',
    bgcolor = 'error.dark',
  } = props;

  return (
    <Badge
      ref={ref}
      badgeContent={content}
      sx={{
        '& .MuiBadge-badge': { color, bgcolor },
      }}
    >
      {children}
    </Badge>
  );
});

if (dev) {
  const Demo = () => {
    const [color, setColor] = useState();
    const [bgcolor, setBgcolor] = useState();
    const [content, setContent] = useState(2);

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="color"
            value={color}
            onChange={setColor}
            options={[undefined, 'white', 'primary.main', 'red', '#000000']}
          />
          <Fieldset.Field
            legend="bgcolor"
            value={bgcolor}
            onChange={setBgcolor}
            options={[undefined, 'error.dark', 'primary.dark', 'secondary.light', 'blue', '#225599']}
          />
          <Fieldset.Field
            legend="content"
            value={content}
            onChange={setContent}
            options={[undefined, 2, 42, 999]}
          />
        </Fieldset>

        <Box component="pre">
          {`<Mark\n`}
          {`  color="${color}"\n`}
          {`  bgcolor="${bgcolor}"\n`}
          {`  content={${content}}\n`}
          {`>\n`}
          {`  <Icon.Notification color="secondary" />\n`}
          {`</Mark>\n`}
        </Box>

        <Mark
          color={color}
          bgcolor={bgcolor}
          content={content}
        >
          <Icon.Notification color="secondary" />
        </Mark>
      </Box>
    );
  };
  Mark.Demo = Demo;
}

export default Mark;
