import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';

import { useGetProjectsQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';

const { selectPageFilters } = selectors;

const useProjectSearch = (opts) => {
  const { disabled } = opts || {};

  const { tempSearch, onSearch, onSetFilter, onResetPageFilter } = usePageFilter({
    minSearchLength: 0,
  });

  const pageFilters = useSelector(selectPageFilters);

  const requestData = useMemo(() => {
    const { activeFilters } = pageFilters || {};

    return {
      name: pageFilters?.search || null,
      tags: getArray(activeFilters?.tags).map((tag) => tag.id),
      levels: getArray(activeFilters?.levels).map((level) => level.id),
      limit: 20,
      offset: 0,
    };
  }, [pageFilters]);

  const { data, isLoading, isFetching } = useGetProjectsQuery(requestData, {
    skip: disabled || !pageFilters?.search,
  });

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
  }, [data, onSetFilter]);

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  return useMemo(() => {
    return {
      projects: data?.projects,
      loading: isLoading || isFetching,
      searchValue: tempSearch,
      onSearch,
    };
  }, [tempSearch, onSearch, isLoading, isFetching, data]);
};

export default useProjectSearch;
