import { getArray } from 'utils/helpers';

const mapRequest = ({ me, filters, pagination }) => {
  const { search, activeFilters } = filters;

  const { tags, levels } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    user_id: me?.id,
    name: search || null,
    tags: getArray(tags).map((tag) => tag.id),
    levels: getArray(levels).map((level) => level.id),
    limit: perPage,
    offset: offset,
  };
};

export default mapRequest;
