import { Box, Stack, styled, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';

import { propagateRefs } from 'utils/helpers';

import { Tag } from 'components';

import { days } from './defaults';

const DayPickerRoot = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.input.main,
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius * 3,
  display: 'inline-flex',
  boxShadow: theme.shadows.small,
}));

const StyledTag = styled(Tag, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    background: active ? theme.palette.primary.main : theme.palette.other.white,
    color: active ? theme.palette.other.white : theme.palette.textBlack.dark,

    '&:hover, &:focus': {
      background: active ? theme.palette.primary.main : undefined,
    },
  }),
);

const DayPicker = (props) => {
  const { value = [], onValue } = props;

  const { t } = useTranslation('components', { keyPrefix: 'DayPicker' });

  const handleChange = (day) => {
    typeof onValue === 'function' &&
      onValue(value.indexOf(day) === -1 ? [...value, day] : value.filter((d) => d !== day));
  };

  return (
    <DayPickerRoot
      direction="row"
      divider={
        <Divider
          sx={{ margin: '4px', borderColor: 'other.divider' }}
          orientation="vertical"
          flexItem
        />
      }
    >
      {days.map((day) => {
        const active = value.includes(day);
        return (
          <StyledTag
            size="medium"
            active={active}
            px={1}
            clickable
            key={day}
            label={t(`days.${day}`)}
            onClick={() => handleChange(day)}
          />
        );
      })}
    </DayPickerRoot>
  );
};

DayPicker.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onValue, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <DayPicker
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  DayPicker.Demo = () => {
    return (
      <Box>
        <DayPicker />
      </Box>
    );
  };
}

export default DayPicker;
