import { Stack as MuiStack, Typography, styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps } from 'hooks';

import { Input, IconButton, Icon, Repeater, FormRow, TextEditor, Button, Center } from 'components';
import { UploadImage } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const FormTitle = styled(Center)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
  justifyContent: 'space-between',
}));

const ProjectStepsForm = (props) => {
  const {
    rules: { required },
    defaultSourceValue,
    defaultStepValue,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'ProjectStepsForm' });

  return (
    <Repeater.Control
      name="steps"
      keyProperty="name"
      defaultValue={[{ ...defaultStepValue(0) }]}
      itemDefaultValue={defaultStepValue}
      AddButtonComponent={(props) => (
        <FormRow>
          <Button type="button" variant="outlined" iconLeft="Add" {...props}>
            {t('add_new')}
          </Button>
        </FormRow>
      )}
    >
      {({ name, index, remove }) => (
        <Box>
          <FormTitle mt={index > 0 ? 1 : undefined} width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('step', { index: index + 1 })}
            </Typography>

            <IconButton
              radius={1}
              border
              size="xsmall"
              name="Delete"
              variant="outlined"
              color="error"
              onClick={() => remove(index)}
            />
          </FormTitle>
          <Stack mt={2}>
            <FormRow label={t('name.label')}>
              <Input.Control
                fullWidth
                type="text"
                name={`${name}.name`}
                rules={{ required }}
                placeholder={t('name.placeholder')}
              />
            </FormRow>
            <FormRow optional label={t('description.label')} alignItems="flex-start">
              <TextEditor.Control
                optional
                fullWidth
                name={`${name}.description`}
                placeholder={t('description.placeholder')}
              />
            </FormRow>
            <FormRow label={t('sources.label')} optional alignItems="flex-start">
              <Repeater.Control
                name={`${name}.sources`}
                keyProperty="url"
                defaultValue={[{ ...defaultSourceValue }]}
                itemDefaultValue={{ ...defaultSourceValue }}
              >
                {({ name, index, remove }) => (
                  <Input.Control
                    fullWidth
                    type="text"
                    name={`${name}.url`}
                    placeholder={t('sources.placeholder')}
                    iconRight={
                      <IconButton variant="text" color="error" onClick={() => remove(index)}>
                        <Icon name="Close" />
                      </IconButton>
                    }
                  />
                )}
              </Repeater.Control>
            </FormRow>

            <FormRow label={t('photos.label')} optional alignItems="flex-start">
              <UploadImage.Control name={`${name}.photos`} />
            </FormRow>
          </Stack>
        </Box>
      )}
    </Repeater.Control>
  );
};

export default ProjectStepsForm;
