import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { actions, selectors } from 'store';
import { getString } from 'utils/helpers';
import { validateEmail, validatePhone } from 'utils/validators';
import useMessage from './useMessage.hook';

const { selectIsLoggedIn } = selectors;
const {
  login: loginAction,
  logout: logoutAction,
  useAuthorizeUserMutation,
} = actions;

export const getUsernamePhone = (username) => {
  return getString(username).replace(/\D/g, '');
};

export const getUsernameType = (username) => {
  const v = getString(username);
  const asPhone = getUsernamePhone(v);
  const isEmail = validateEmail(v);
  const isPhone = validatePhone(asPhone);

  if (isEmail) {
    return 'email';
  }
  if (isPhone) {
    return 'phone';
  }
};

export const getUsername = (username) => {
  const type = getUsernameType(username);

  if (type === 'email') {
    return username;
  }
  if (type === 'phone') {
    return getUsernamePhone(username);
  }
  return username;
};

const keyPrefix = 'auth';

/**
 * @returns {object} where
 * @prop {function} login - Login action
 * @prop {function} logout - Logout action
 * @prop {boolean|undefined} auth - Auth status. Be attentive - boolean means auth status, undefined
 * means status is unknown yet (e.g. auth request in progress)
 * @prop {boolean} progress - Auth status is updating right now.
 */
const useAuth = () => {
  const [authorizeUser, authorizeUserState] = useAuthorizeUserMutation();

  const { t } = useTranslation('hooks', { keyPrefix });
  const auth = useSelector(selectIsLoggedIn);
  const m = useMessage();

  const login = useCallback(async (data) => {
    const username = getUsername(data.username);

    try {
      const body = { ...data, username };
      const response = await authorizeUser(body).unwrap();
      loginAction(response);
      return response;
    } catch (ex) {
      console.error(ex);

      if (ex.status === 409) {
        m.error(t('error_409'));
      }
      if (ex.status === 401) {
        m.error(t('error_401'));
      }
      logoutAction();
      return ex;
    }
  }, [authorizeUser, m, t]);

  const logout = useCallback(() => {
    logoutAction();
  }, []);

  const progress = authorizeUserState.isLoading;

  return useMemo(() => ({
    login,
    logout,
    progress,
    auth: progress ? undefined : auth,
  }), [login, logout, progress, auth]);
};

export default useAuth;
