import { useMemo } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form';
import useRules from './useRules.hook';

/**
 * @param {object} [props] - the same props as for 'react-hook-form' useForm hook.
 * @returns {object} - Object with next fields:
 *  form - the same as 'react-hook-form' useForm hook returned value
 *  rules - useRules.hook returned value (common validation rules)
 *  valid - boolean (shortcut for form.formState.isValid)
 */
const useForm = (props) => {
  const form = useReactHookForm({
    mode: 'all',
    shouldUnregister: false,
    ...props,
  });
  const rules = useRules();
  const valid = !!form.formState.isValid;

  return useMemo(() => ({ form, rules, valid }), [form, rules, valid]);
};

export default useForm;
