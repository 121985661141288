import { Typography, Box, Stack } from '@mui/material';
import { styled, darken } from '@mui/material/styles';

import { Center, LinearProgress } from 'components';

import { IconStar } from './icons';

const Root = styled(Box, {
  label: 'ProfileExperience-Root',
})(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 3,
  height: 40,
  minWidth: 146,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  backgroundColor: theme.palette.primary.dark,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover, &:focus': {
    outlineColor: 'transparent',
    outlineWidth: 0,
    outlineOffset: 0,
    backgroundColor: darken(theme.palette.primary.dark, 0.1),
  },
}));

const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: '3px',
  backgroundColor: theme.palette.success.contrastText,
  '& > span': {
    backgroundColor: theme.palette.success.main,
  },
}));

const ProfileExperience = (props) => {
  const { points = 0, pointsToNextLevel = 0, level, ...rest } = props;

  const progress = (points / pointsToNextLevel) * 100;

  return (
    <Root hidden color="inherit" {...rest}>
      <Center width={1}>
        <IconStar level={level} fontSize="small" name="Level" />
        <Box flexGrow={1} pl={1} pb={0.5}>
          <Center justifyContent="flex-start">
            <Stack direction="row" spacing={0.5} color="other.line" divider={<Box>/</Box>}>
              <Typography variant="body4" color="other.white">
                {points}
              </Typography>
              <Typography variant="body4">{pointsToNextLevel}</Typography>
            </Stack>

            <Typography variant="body4" color="warning.main" ml={0.5}>
              XP
            </Typography>
          </Center>
          <StyledProgress variant="determinate" value={progress} />
        </Box>
      </Center>
    </Root>
  );
};

export default ProfileExperience;
