import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Stack as MuiStack, Box, Typography, Grid } from '@mui/material';

import { allowedImageTypes, ROLE } from 'utils/constants';
import { editorStateToRawHtml, getProfileBasePath } from 'utils/helpers';

import {
  useUploadTemporaryFileMutation,
  useUpdateParentMutation,
  useGetParentQuery,
} from 'store/api';
import { useForm, useMessage, withProps, useCountrySearch } from 'hooks';

import { ProjectLayout } from 'layouts';
import { PageSubheader } from 'views';

import {
  Container,
  Paper,
  Form,
  FormRow,
  PhoneField,
  Input,
  TextEditor,
  Dropzone,
  Select,
  FormTitle,
} from 'components';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const contentWidth = 936;

const FORM_ID = 'parents_information';

const mapInitialData = (parent) => {
  if (!parent) return {};

  const userData = {
    full_name: parent.full_name || '',
    avatar_file_id: [parent.avatar],
    cached_avatar_id: parent.avatar,
    phone_number: parent.phone_number || '',
    email: parent.email || '',
    relation: parent.role || '',
    country: parent.country || '',
    city: parent.city || '',
    state: parent.state || '',
    apart: parent.apartment || '',
    address: parent.address || '',
    zipcode: parent.zip || '',
    note: getInitialTextEditorState(parent.notes),
    relations: parent.relations,
    language: parent.preferred_language,
  };

  return userData;
};

const EditParent = () => {
  const { id } = useParams();

  const { t } = useTranslation('pages', { keyPrefix: 'edit_parent' });
  const m = useMessage();

  const {
    form: parentsInformationForm,
    valid: parentsInformationFormValid,
    rules: { phone, email, required },
  } = useForm();

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [updateParent, updateParenState] = useUpdateParentMutation();

  const {
    data: parent,
    isLoading: parentLoading,
    isFetching: parentFetching,
  } = useGetParentQuery(
    { id },
    {
      skip: !id,
    },
  );

  useEffect(() => {
    parentsInformationForm.reset(mapInitialData(parent));
  }, [parentsInformationForm, parent]);

  const {
    options: countryOptions,
    search: countrySearch,
    onSearch: onCountrySearch,
  } = useCountrySearch({
    mapToOptions: true,
  });

  const handleSubmit = useCallback(
    async (formData) => {
      const { note, avatar_file_id, cached_avatar_id, avatar_last_modified, ...parent } = formData;

      const [avatar] = avatar_file_id;

      let avatarId = cached_avatar_id;
      if (avatar instanceof File && avatar.lastModified !== avatar_last_modified) {
        try {
          const { file_id } = await uploadFile({ file: avatar }).unwrap();
          avatarId = file_id;
          parentsInformationForm.setValue(`cached_avatar_id`, file_id);
          parentsInformationForm.setValue(`avatar_last_modified`, avatar.lastModified);
        } catch (e) {
          console.error(e);
          m.error(t('error.failed_upload_photo'));
        }
      }

      try {
        const { status } = await updateParent({
          ...parent,
          parentId: id,
          notes: editorStateToRawHtml(note) || undefined,
          permissions: {},
          avatar_file_id: avatarId,
        }).unwrap();

        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        console.error(err);
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error.failed_update_parent'));
        }
      }
    },
    [parentsInformationForm, id, uploadFile, updateParent, m, t],
  );

  const loading =
    uploadFileState.isLoading || updateParenState.isLoading || parentLoading || parentFetching;

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={`${getProfileBasePath(ROLE.PARENT)}/${id}`}
          backTitle={t('page_actions.back_button')}
          pageActions={{
            [t('page_actions.cancel')]: {
              type: 'button',
              disabled: loading || !parentsInformationForm.formState.isDirty,
              variant: 'outlined',
              size: 'small',
              radius: 2,
              onClick: () => parentsInformationForm.reset(),
            },
            [t('page_actions.edit')]: {
              type: 'submit',
              disabled: loading || !parentsInformationFormValid,
              size: 'small',
              radius: 2,
              form: FORM_ID,
            },
          }}
        />
      }
    >
      <Container width={contentWidth} py={4} position="relative">
        <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
          {t(`page_title`)}
        </Typography>

        <Paper shadow="standard" width="100%" overflow="visible" pt={3} pb={5} px={9}>
          <Form id={FORM_ID} width="100%" form={parentsInformationForm} onSubmit={handleSubmit}>
            <Box position="relative">
              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('guardian_information')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow optional label={t('avatar.label')} alignItems="flex-start">
                  <Dropzone.Control fullWidth accept={allowedImageTypes} name={`avatar_file_id`} />
                </FormRow>
                <FormRow label={t('full_name.label')} alignItems="flex-start">
                  <Input.Control
                    fullWidth
                    type="text"
                    name="full_name"
                    placeholder={t('full_name.placeholder')}
                    rules={{ required }}
                  />
                </FormRow>
              </Stack>

              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('contact_section')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow label={t('phone_number.label')}>
                  <PhoneField.Control
                    optional
                    fullWidth
                    name={`phone_number`}
                    placeholder={t('phone_number.placeholder')}
                    rules={{
                      phone,
                    }}
                  />
                </FormRow>

                <FormRow label={t('email.label')}>
                  <Input.Control
                    fullWidth
                    type="email"
                    name={`email`}
                    placeholder={t('email.placeholder')}
                    rules={{
                      validate: email,
                    }}
                  />
                </FormRow>
              </Stack>

              <FormTitle width="100%">
                <Typography color="secondary.main" variant="body2">
                  {t('address_section')}
                </Typography>
              </FormTitle>
              <Stack mt={2} mb={2} width="100%">
                <FormRow label={t('address.label')} alignItems="flex-start">
                  <Input.Control
                    fullWidth
                    name={`address`}
                    placeholder={t('address.placeholder')}
                  />
                </FormRow>
                <FormRow label={t('apart.label')} alignItems="flex-start">
                  <Input.Control fullWidth name={`apart`} placeholder={t('apart.placeholder')} />
                </FormRow>
                <FormRow label={t('country.label')} alignItems="flex-start">
                  <Select.Control
                    fullWidth
                    name={`country`}
                    placeholder={t('country.placeholder')}
                    itemsMax={4}
                    options={countryOptions}
                    searchable
                    search={countrySearch}
                    onSearch={onCountrySearch}
                  />
                </FormRow>
                <FormRow label={t('city.label')} alignItems="flex-start">
                  <Input.Control fullWidth name={`city`} placeholder={t('city.placeholder')} />
                </FormRow>

                <FormRow label={t('state.label')} alignItems="flex-start">
                  <Grid container>
                    <Grid item xs={6} pr={1}>
                      <Input.Control
                        fullWidth
                        name={`state`}
                        placeholder={t('state.placeholder')}
                      />
                    </Grid>
                    <Grid item xs={6} pl={1}>
                      <Input.Control
                        fullWidth
                        name={`zipcode`}
                        placeholder={t('zipcode.placeholder')}
                      />
                    </Grid>
                  </Grid>
                </FormRow>

                <FormTitle width="100%">
                  <Typography color="secondary.main" variant="body2">
                    {t('notes')}
                  </Typography>
                </FormTitle>
                <Stack mt={2} mb={2} width="100%">
                  <FormRow label={t('note.label')} alignItems="flex-start">
                    <TextEditor.Control
                      optional
                      fullWidth
                      name={`note`}
                      placeholder={t('note.placeholder')}
                    />
                  </FormRow>
                </Stack>
              </Stack>
            </Box>
          </Form>
        </Paper>
      </Container>
    </ProjectLayout>
  );
};

export default EditParent;
