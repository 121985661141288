import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { dev } from 'utils/config';
import { ROLE } from 'utils/constants';

import { useAuth, useAcl } from 'hooks';

import { Progress } from 'components';

import HeaderNavButton from './HeaderNavButton';

const keyPrefix = 'header';

const links = [
  {
    to: '/about',
    label: 'about',
    auth: false,
    isActive: (path) => path === '/' || path.startsWith('/about'),
  },
  {
    to: '/dashboard',
    label: 'dashboard',
    auth: true,
    roles: [ROLE.PARENT, ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
  },
  {
    to: '/ideas',
    label: 'ideas',
    roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
  },
  {
    to: '/learning',
    label: 'learning',
    auth: true,
    roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
  },
  {
    to: '/portfolio',
    label: 'portfolio',
    auth: true,
    roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
  },
  {
    to: '/administration',
    label: 'administration',
    auth: true,
    roles: [ROLE.ADMIN, ROLE.TEACHER],
  },
  {
    to: '/knowledge-base',
    label: 'knowledge_base',
    roles: [ROLE.ADMIN, ROLE.TEACHER, ROLE.STUDENT],
  },
];

const HeaderNavigation = () => {
  const acl = useAcl();
  const { t } = useTranslation('views', { keyPrefix });
  const { pathname } = useLocation();
  const { auth, progress } = useAuth();

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={3}>
        {progress && <Progress color="primary.light" size={30} />}

        {!progress &&
          links.map((link) => {
            if (
              link.roles !== undefined &&
              !acl.allow({ roles: link.roles, permissions: link.permissions }) &&
              auth
            )
              return null;

            const allow =
              (link.auth === true && auth) ||
              (link.auth === false && !auth) ||
              link.auth === undefined;

            const active =
              typeof link.isActive === 'function'
                ? link.isActive(pathname)
                : pathname.startsWith(link.to);

            return (
              <Fragment key={link.to}>
                {allow && (
                  <HeaderNavButton to={link.to} active={active}>
                    {t(link.label)}
                  </HeaderNavButton>
                )}
              </Fragment>
            );
          })}

        {dev && (
          <HeaderNavButton to="/dev" active={pathname.startsWith('/dev')}>
            {t('dev')}
          </HeaderNavButton>
        )}
      </Stack>
    </Box>
  );
};

export default HeaderNavigation;
