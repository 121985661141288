import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { search } from 'assets/images/holders';

import { Modal, ImageHolder, Center, Button } from 'components';

const BlockIdeaModal = (props) => {
  const { open, onClose, onDelete } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'single_idea.block_idea' });

  return (
    <Modal open={open} onClose={onClose} size={516}>
      <Modal.Body>
        <Center pt={3} pb={3} flexDirection="column">
          <ImageHolder
            p={1}
            size={96}
            alt={t('banned_words')}
            src={search}
            origin="container"
            shadow="smooth"
            mb={2}
            bgcolor="warning.contrastText"
          />
          <Typography variant="subtitle3" maxWidth={374} textAlign="center" color="success.dark">
            {t('banned_words')}
          </Typography>

          <Center gap={1.5} mt={4}>
            <Button size="small" radius={2} type="button" color="error" onClick={onDelete}>
              {t('block')}
            </Button>
            <Button variant="outlined" size="small" radius={2} type="button" onClick={onClose}>
              {t('back')}
            </Button>
          </Center>
        </Center>
      </Modal.Body>
    </Modal>
  );
};

export default BlockIdeaModal;
