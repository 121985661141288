import { useEffect, useMemo, useState } from 'react';

const defProps = {};
const cache = [];

/**
 * Preload an image and returns the link only after loading. Allows to avoid partial image
 * rendering effect. Usage example:
 *
 *  const {
 *    src,  // will be null untill image loading
 *    error,  // returns error text in case of loading error
 *    loading,  // will be true while loading
 *  } = useImagePreload({ src: '/path/to/your/image.png' });
 *
 *  return (
 *    <img src={src} />
 *  );
 *
 * Actually error has no sens at the moment, and could be handled better.
 *
 * TODO:
 * Add onLoad and onError callbacks if needed.
 * Improve error handling if needed.
 */
const useImagePreload = (props = defProps) => {
  const { src } = props;

  const [link, setLink] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState(null);

  // If [src] has changed, refresh state and start preload process again.
  useEffect(() => {
    if (src !== source) {
      setLink(null);
      setError(null);
      setLoading(false);
      setSource(src);
    }
  }, [source, src]);

  // Preload image
  useEffect(() => {
    if (source) {
      // Ignore already loaded images
      if (cache.includes(source)) {
        setLink(source);
        return;
      }
      setLoading(true);
      let mounted = true;
      const image = new Image();

      const listenLoad = () => {
        if (mounted) {
          cache.push(source);
          setLink(source);
          setLoading(false);
        }
      };
      const listenError = () => {
        if (mounted) {
          // Error could be handled in other way if needed
          setError('Cannot load image...');
          setLoading(false);
          setLink(null);
        }
      };
      image.addEventListener('load', listenLoad);
      image.addEventListener('error', listenError);
      image.src = source;

      return () => {
        mounted = false;
        image.removeEventListener('load', listenLoad);
        image.removeEventListener('error', listenError);
      };
    }
  }, [source]);

  return useMemo(() => ({
    error,
    loading,
    src: cache.includes(src) ? src : link,
  }), [link, error, loading, src]);
};

export default useImagePreload;
