export const ROLE = {
  ADMIN: 'admin',
  TEACHER: 'teacher',
  STUDENT: 'student',
  PARENT: 'parent',
};

export const PERMISSIONS = {
  CREATE_IDEA: 'create_idea',
  CREATE_PROJECT: 'create_project',
  CREATE_COLLECTION: 'create_collection',
  CREATE_USER: 'create_user',
  CREATE_GROUP: 'create_group',
  CREATE_PORTFOLIO: 'create_portfolio',
  DELETE_IDEA: 'delete_idea',
  DELETE_PROJECT: 'delete_project',
  DELETE_COLLECTION: 'delete_collection',
  DELETE_USER: 'delete_user',
  DELETE_GROUP: 'delete_group',
  DELETE_PORTFOLIO: 'delete_portfolio',
  EDIT_IDEA: 'edit_idea',
  EDIT_PROJECT: 'edit_project',
  EDIT_COLLECTION: 'edit_collection',
  EDIT_USER: 'edit_user',
  EDIT_GROUP: 'edit_group',
  EDIT_PORTFOLIO: 'edit_portfolio',
  ASSIGN_USER: 'assign_user',
  EDIT_MAIN_PROJECT: 'edit_main_project',
  REQUEST_PROJECT: 'request_project',
  SUPER_ADMIN: 'super_admin',
  MODERATE_IDEA: 'moderate_idea',
  REQUEST_COLLECTION: 'request_collection',
  EDIT_PROJECT_STATUS: 'edit_project_status',
};

export const NOTIFICATIONS = {
  NEW_USER: 'new_user',
  NEW_PROJECT: 'new_project',
  PROJECT_ASSIGN: 'project_assign',
  PROJECT_REQUEST: 'project_request',
  PROJECT_PUBLISH: 'project_publish',
  PROJECT_STATUS_UPDATE: 'project_status_update',
  NEW_PROJECT_PHOTO: 'new_project_photo',
  PROJECT_KID_ASSIGN: 'project_kid_assign',
  NEW_PORTFOLIO: 'new_portfolio',
  SHARED_PORTFOLIO: 'shared_portfolio',
  REPLY_TO_COMMENT: 'reply_to_comment',
  NEW_GROUP: 'new_group',
  USER_ADDED_TO_GROUP: 'user_added_to_group',
  GROUP_LEADER: 'group_leader',
  COLLECTION_ASSIGN: 'collection_assign',
  NEW_COLLECTION: 'new_collection',
  COLLECTION_COMPLETED: 'collection_completed',
  NEW_IDEA: 'new_idea',
  COLLECTION_REQUEST: 'collection_request',
  PROJECT_ASSIGNED_TO: 'project_assigned_to',
};

export const TEACHER_PERMISSIONS = {
  create_user: [PERMISSIONS.CREATE_USER],
  change_project_status: [PERMISSIONS.EDIT_PROJECT_STATUS],
  create_ideas_collections_projects: [
    PERMISSIONS.CREATE_IDEA,
    PERMISSIONS.CREATE_COLLECTION,
    PERMISSIONS.CREATE_PROJECT,
  ],
  edit_ideas_collections_projects: [
    PERMISSIONS.EDIT_IDEA,
    PERMISSIONS.EDIT_COLLECTION,
    PERMISSIONS.EDIT_PROJECT,
    PERMISSIONS.EDIT_MAIN_PROJECT,
  ],
  delete_ideas_collections_projects: [
    PERMISSIONS.DELETE_IDEA,
    PERMISSIONS.DELETE_COLLECTION,
    PERMISSIONS.DELETE_PROJECT,
  ],
  assign_ideas_collections_projects: [PERMISSIONS.ASSIGN_USER],
  create_portfolio: [PERMISSIONS.CREATE_PORTFOLIO],
  edit_portfolio: [PERMISSIONS.EDIT_PORTFOLIO],
  delete_portfolio: [PERMISSIONS.DELETE_PORTFOLIO],
};

export const STUDENT_PERMISSIONS = {
  request_project: [PERMISSIONS.REQUEST_PROJECT],
  change_project_status: [PERMISSIONS.EDIT_PROJECT_STATUS],
  edit_own_projects: [PERMISSIONS.EDIT_PROJECT],
  create_ideas: [PERMISSIONS.CREATE_IDEA],
  edit_delete_ideas: [PERMISSIONS.DELETE_IDEA, PERMISSIONS.EDIT_IDEA],
  create_portfolio: [PERMISSIONS.CREATE_PORTFOLIO],
  edit_own_portfolio: [PERMISSIONS.EDIT_PORTFOLIO],
  delete_own_portfolio: [PERMISSIONS.DELETE_PORTFOLIO],
};

export const ADMIN_NOTIFICATION_PERMISSIONS = [
  NOTIFICATIONS.NEW_USER,
  NOTIFICATIONS.NEW_GROUP,
  NOTIFICATIONS.NEW_PROJECT,
  NOTIFICATIONS.NEW_PORTFOLIO,
  NOTIFICATIONS.NEW_COLLECTION,
  NOTIFICATIONS.NEW_IDEA,
  NOTIFICATIONS.NEW_PROJECT_PHOTO,
  NOTIFICATIONS.PROJECT_STATUS_UPDATE,
  NOTIFICATIONS.SHARED_PORTFOLIO,
  NOTIFICATIONS.COLLECTION_COMPLETED,
  NOTIFICATIONS.PROJECT_REQUEST,
];

export const TEACHER_NOTIFICATION_PERMISSIONS = [
  NOTIFICATIONS.NEW_PROJECT,
  NOTIFICATIONS.NEW_COLLECTION,
  NOTIFICATIONS.PROJECT_STATUS_UPDATE,
  NOTIFICATIONS.NEW_PORTFOLIO,
  NOTIFICATIONS.SHARED_PORTFOLIO,
  NOTIFICATIONS.NEW_PROJECT_PHOTO,
  NOTIFICATIONS.COLLECTION_COMPLETED,
  NOTIFICATIONS.PROJECT_REQUEST,
  NOTIFICATIONS.USER_ADDED_TO_GROUP,
  NOTIFICATIONS.GROUP_LEADER,
  NOTIFICATIONS.NEW_IDEA,
];

export const STUDENT_NOTIFICATION_PERMISSIONS = [
  NOTIFICATIONS.PROJECT_ASSIGNED_TO,
  NOTIFICATIONS.COLLECTION_ASSIGN,
  NOTIFICATIONS.PROJECT_PUBLISH,
  NOTIFICATIONS.REPLY_TO_COMMENT,
  NOTIFICATIONS.SHARED_PORTFOLIO,
  NOTIFICATIONS.USER_ADDED_TO_GROUP,
  NOTIFICATIONS.GROUP_LEADER,
];

export const PARENT_NOTIFICATION_PERMISSIONS = [
  NOTIFICATIONS.PROJECT_STATUS_UPDATE,
  NOTIFICATIONS.PROJECT_KID_ASSIGN,
];

export const FILTER_TYPE = {
  TAG_FILTER: 'tag_filter',
  LEVEL_FILTER: 'level_filter',
  GROUPS: 'groups',
  USERS: 'users',
  ROLES: 'roles',
  STATUS: 'status',
};

export const LEVEL = {
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard',
};

export const STATUS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
};

export const OBJECT_TYPE = {
  PROJECT: 'project',
  STUDENT_PROJECT: 'student_project',
  COLLECTION: 'collection',
  STUDENT_COLLECTION: 'student_collection',
  USER: 'user',
  GROUP: 'group',
  IDEA: 'idea',
  PORTFOLIO: 'portfolio',
  PROJECT_CARD: 'project_card',
  QUESTION: 'question',
  TIP: 'tip',
};

export const SORT_DIRECTION = {
  DEFAULT: 0,
  ASC: 1,
  DESC: 2,
};

export const allowedImageTypes = ['image/jpeg', 'image/png'];

export const NOTIFICATIONS_POLLING_INTERVAL = 20000;
