import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetMeQuery } from 'store/api';
import { useAuth } from 'hooks';

import { ProjectLayout } from 'layouts';

import AdminDashboard from './AdminDashboard';
import StudentDashboard from './StudentDashboard';
import ParentDashboard from './ParentDashboard';
import { ROLE } from 'utils/constants';

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const Dashboard = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  const { auth } = useAuth();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  let DashboardComponent = StudentDashboard;
  if (me?.role === ROLE.ADMIN) {
    DashboardComponent = AdminDashboard;
  } else if (me?.role === ROLE.PARENT) {
    DashboardComponent = ParentDashboard;
  }

  return (
    <ProjectLayout
      subheader={
        <PageTitle>
          <Typography variant="subtitle2">{t('subheader.title')}</Typography>
        </PageTitle>
      }
    >
      <DashboardComponent />
    </ProjectLayout>
  );
};

export default Dashboard;
