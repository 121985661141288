import { useState, useEffect, useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetMeQuery, useGetSpaceQuery, useDeleteSpaceMutation } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useMessage, usePageFilter } from 'hooks';
import { getArray, formatDate } from 'utils/helpers';

import { ProjectLayout } from 'layouts';
import { Icon, Input, Paper } from 'components';
import { SidebarFilters, FiltersBar, PageSubheader, CardContent } from 'views';

import NavigationTabs from './NavigationTabs';
import Ideas from './Ideas';
import Projects from './Projects';
import Collections from './Collections';

const { selectFilters, selectActiveFilters } = selectors;

const Space = () => {
  const { id } = useParams();

  const m = useMessage();
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'space' });
  const { onSearch, tempSearch, onSetActiveFilter, onResetPageFilter, onResetActiveFilters } =
    usePageFilter();

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);

  const [tab, setTab] = useState('ideas');

  const { data: me } = useGetMeQuery();
  const { data: space } = useGetSpaceQuery({ id }, { skip: !Boolean(id) });

  const [deleteSpace, deleteSpaceState] = useDeleteSpaceMutation();

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  const handleDeleteSpace = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deleteSpace({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate('/administration/spaces');
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deleteSpace, navigate, id, t, m]);

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={space?.name}
          backUrl="/administration/spaces"
          backTitle={t('back_button')}
          pageActions={
            me?.profile?.permissions?.super_admin
              ? {
                  [t('page_actions.block_account')]: {
                    type: 'button',
                    size: 'small',
                    color: 'error',
                    variant: 'outlined',
                    disabled: deleteSpaceState.isLoading,
                    onClick: handleDeleteSpace,
                  },
                }
              : {}
          }
        >
          <Box width={576} ml={3}>
            <Input
              value={tempSearch}
              onValue={onSearch}
              iconLeft="Search"
              fullWidth={true}
              placeholder={t('subheader.page_search')}
            />
          </Box>
        </PageSubheader>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Box p={4} pl={3}>
        <Box width={576} mb={4}>
          <Typography component="h3" variant="subtitle5" color="textBlack.dark" mb={2}>
            {t('personal_information')}
          </Typography>
          <Paper width={1} shadow="standard">
            {space && (
              <CardContent
                variant="space"
                avatar={space.avatar}
                name={space.name}
                role={space.role}
                members={space.members}
                status={space.status}
                registrationDate={formatDate(space.registration_date)}
              />
            )}
          </Paper>
        </Box>

        <Box display="flex" mb={3}>
          <NavigationTabs value={tab} onValue={setTab} />
        </Box>

        <FiltersBar
          filters={activeFilters}
          mb={3}
          onRemove={handleRemoveSelectedFilters}
          onClear={onResetActiveFilters}
        />

        {tab === 'ideas' && <Ideas spaceId={id} />}
        {tab === 'projects' && <Projects spaceId={id} />}
        {tab === 'collections' && <Collections spaceId={id} />}
      </Box>
    </ProjectLayout>
  );
};

export default Space;
