import { useMemo } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getArray } from 'utils/helpers';

import {
  Icon,
  ButtonBase,
  Center,
  Ref,
  CopyToClipboard,
  Paper,
  Image,
  YoutubeEmbed,
} from 'components';

const LinkButton = styled(ButtonBase)(({ theme }) => ({
  padding: 0,
  height: 'auto',
  color: theme.palette.secondary.light,
}));

const SourceItem = (props) => {
  const { label, url, preview, ...rest } = props;

  return (
    <Box {...rest}>
      <Center justifyContent="flex-start">
        <Typography variant="body3" mr={0.5}>
          <Ref component="a" blank to={url} color="textRegular.light">
            {label}
          </Ref>
        </Typography>
        <CopyToClipboard text={url}>
          <LinkButton type="button" variant="text">
            <Icon name="Link" fontSize="small" />
          </LinkButton>
        </CopyToClipboard>
      </Center>
      {preview && <Box mt={1}>{preview}</Box>}
    </Box>
  );
};

const SourcePreviewCard = (props) => {
  const { title, description, imageSrc, ...rest } = props;

  return (
    <Paper display="flex" flexDirection="row" p={3} {...rest}>
      <Box>
        {title && (
          <Typography variant="subtitle3" component="h3" mb={2} color="textBlack.main">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body3" component="p" color="textBlack.dark">
            {description}
          </Typography>
        )}
      </Box>
      {imageSrc && (
        <Box ml={2} flexShrink={0} sx={{ width: '434px', img: { width: '100%' } }}>
          <Image src={imageSrc} />
        </Box>
      )}
    </Paper>
  );
};

const SourceYoutubeEmbed = (props) => {
  const { embedId } = props;

  return <YoutubeEmbed embedId={embedId} />;
};

const Sources = (props) => {
  const { sources: externalSources } = props;

  const { t } = useTranslation('views', { keyPrefix: 'ArticleGeneralInformation' });

  const sources = useMemo(() => {
    return getArray(externalSources)
      .map((source) => {
        const link = typeof source === 'string' ? source : source?.link;

        const hasDescription = Boolean(source?.description);
        const hasTitle = Boolean(source?.title);
        const hasLink = Boolean(link);

        if (!hasLink) return null;

        const match = link.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);

        const isYoutubeVideo = match?.length > 1 && match[2]?.length > 0;

        if (isYoutubeVideo) {
          return (
            <SourceItem
              key={`source-${source?.title}`}
              label={source?.title}
              url={source?.link}
              preview={<SourceYoutubeEmbed title={source?.title} embedId={match[2]} />}
            />
          );
        }

        if (hasDescription) {
          return (
            <SourceItem
              key={`source-${source.title}`}
              label={source.title}
              url={source.link}
              preview={
                <SourcePreviewCard description={source.description} imageSrc={source.image} />
              }
            />
          );
        }

        if (hasTitle) {
          return (
            <SourceItem key={`source-${source.title}`} label={source.title} url={source.link} />
          );
        }

        return null;
      })
      .filter(Boolean);
  }, [externalSources]);

  if (sources.length === 0) return null;

  return (
    <Box mb={1}>
      <Center mb={1} justifyContent="flex-start">
        <Icon name="Link" fontSize="small" />
        <Typography ml={0.5} variant="body2" color="textBlack.dark">
          {t('sources')}
        </Typography>
      </Center>

      <Stack px={2.25} spacing={2}>
        {sources}
      </Stack>
    </Box>
  );
};

export default Sources;
