import { useState } from 'react';
import { Typography, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dev } from 'utils/config';
import { useAcl } from 'hooks';

import { Button, Center, Container } from 'components';
import Fieldset from 'components/Dev/Fieldset';

const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(0, -50%)',
  opacity: 1,
  color: theme.palette.secondary.light,
  maxWidth: 216,
  '& .MuiSvgIcon-root': {
    fontSize: '12px',
  },
  '& > div': {
    width: '100%',
  },
  '& .MuiTypography-root': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const PageTitle = styled(Box)({
  flexShrink: 0,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const Subheader = styled(Box)({
  position: 'relative',
});

const PageActions = styled(Center)(({ theme }) => ({
  gap: theme.spacing(1.5),
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(0, -50%)',
}));

/**
 * @prop {string} [title] - Page title
 * @prop {string} [backUrl] - Url to be redirected to
 * @prop {string} [backTitle] - Back button label
 * @prop {number} [containerWidth=1056] - Container width
 */
const PageSubheader = (props) => {
  const { title, children, backUrl, backTitle, containerWidth = 1056, pageActions } = props;

  const acl = useAcl();

  const { t } = useTranslation('views', { keyPrefix: 'PageSubheader' });

  const hasActions = pageActions && Object.keys(pageActions).length > 0;

  return (
    <Subheader flexGrow={1}>
      {backUrl && (
        <BackButton to={backUrl} variant="text" iconLeft="ArrowLeft">
          <Typography variant="body3">{backTitle || t('back')}</Typography>
        </BackButton>
      )}
      <Container width={containerWidth}>
        <Center width={1} justifyContent="flex-start">
          {title && (
            <PageTitle>
              <Typography noWrap variant="subtitle3">
                {title}
              </Typography>
            </PageTitle>
          )}
          {children}
        </Center>
      </Container>

      {hasActions && (
        <PageActions>
          {Object.entries(pageActions).map(([name, action]) => {
            const { onClick, label, Component, aclConfig, ...restAction } = action;

            const ButtonComponent = Component || Button;

            const allowed = !aclConfig || acl.allow(aclConfig);

            if (!allowed) return null;

            return (
              <ButtonComponent
                key={`page-action-${name}`}
                onClick={onClick}
                radius={2}
                {...restAction}
              >
                {label || name}
              </ButtonComponent>
            );
          })}
        </PageActions>
      )}
    </Subheader>
  );
};

if (dev) {
  const titleOptions = [
    undefined,
    '51 Important questions of computer',
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus error, ullam corrupti vel nesciunt eveniet labore corporis debitis. Repellat, ex?',
  ];

  const backUrlOptions = [undefined, '/dev', '/'];

  const backTitleOptions = [
    undefined,
    'Back to Tips',
    'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  ];

  const Demo = () => {
    const [containerWidth, setContainerWidth] = useState(768);
    const [title, setTitle] = useState(titleOptions[1]);
    const [backUrl, setBackUrl] = useState(backUrlOptions[1]);
    const [backTitle, setBackTitle] = useState(backTitleOptions[1]);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field legend="title" value={title} onChange={setTitle} options={titleOptions} />
          <Fieldset.Field
            legend="backUrl"
            value={backUrl}
            onChange={setBackUrl}
            options={backUrlOptions}
          />
          <Fieldset.Field
            legend="backTitle"
            value={backTitle}
            onChange={setBackTitle}
            options={backTitleOptions}
          />
          <Fieldset.Field
            legend="containerWidth"
            value={containerWidth}
            onChange={setContainerWidth}
            options={[undefined, 768, 1056]}
          />
        </Fieldset>
        <PageSubheader
          containerWidth={containerWidth}
          title={title}
          backUrl={backUrl}
          backTitle={backTitle}
        />
      </Box>
    );
  };
  PageSubheader.Demo = Demo;
}

export default PageSubheader;
