import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { GuestLayout } from 'layouts';
import { ImageHolder, Ref } from 'components';

import { not_found } from 'assets/images/holders';

const keyPrefix = 'not_found';

const NotFound = () => {
  const { t } = useTranslation('pages', { keyPrefix });

  return (
    <GuestLayout>
      <ImageHolder
        p="1px"
        alt="404"
        size={56}
        stroke={1}
        src={not_found}
        color="primary.main"
      />

      <Typography color="primary.dark" variant="subtitle2">
        {t('page_not_found')}
      </Typography>

      <Ref to="/">
        {t('back_to_site')}
      </Ref>
    </GuestLayout>
  );
};

export default NotFound;
