import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

import { ProjectLayout } from 'layouts';
import { Container, Paper, Button, Center } from 'components';
import { PageSubheader } from 'views';

const contentWidth = 936;

const NewProject = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_user' });
  const navigate = useNavigate();

  return (
    <ProjectLayout
      subheader={<PageSubheader backUrl="/administration/users" backTitle={t('back_button')} />}
    >
      <Container width={contentWidth} py={4}>
        <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
          {t('choose_role')}
        </Typography>

        <Paper width="100%" overflow="visible" pt={4} pb={4} px={9}>
          <Center width="100%" flexDirection="column" gap={2}>
            <Box>
              <Button
                size="large"
                width={216}
                iconLeft="Student"
                variant="outlined"
                onClick={() => {
                  navigate('/administration/students/new');
                }}
              >
                {t('page_actions.create_student')}
              </Button>
            </Box>
            <Box>
              <Button
                size="large"
                width={216}
                iconLeft="Parent"
                variant="outlined"
                onClick={() => {
                  navigate('/administration/parents/new');
                }}
              >
                {t('page_actions.create_parent')}
              </Button>
            </Box>
            <Box>
              <Button
                size="large"
                width={216}
                iconLeft="Teacher"
                variant="outlined"
                onClick={() => {
                  navigate('/administration/teachers/new');
                }}
              >
                {t('page_actions.create_teacher')}
              </Button>
            </Box>
            <Box>
              <Button
                size="large"
                width={216}
                iconLeft="Admin"
                variant="outlined"
                onClick={() => {
                  navigate('/administration/admins/new');
                }}
              >
                {t('page_actions.create_admin')}
              </Button>
            </Box>
          </Center>
        </Paper>
      </Container>
    </ProjectLayout>
  );
};

export default NewProject;
