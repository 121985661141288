import { Box } from '@mui/material';

const YoutubeEmbed = (props) => {
  const { embedId } = props;

  if (!embedId) return null;

  return (
    <Box sx={{ '& iframe': { width: '100%' } }}>
      <iframe
        width="853"
        height="570"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Box>
  );
};

export default YoutubeEmbed;
