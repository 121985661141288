import { forwardRef } from 'react';
import { CardActionArea, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { Paper } from 'components';

const Root = styled(Paper, { label: 'PaperCard-Root' })(({ border, theme }) => ({
  padding: 0,
  width: '100%',
  height: '100%',
  flexShrink: 0,
  border: border ? '1px solid' : undefined,
  borderColor: border ? theme.palette.controls.main : undefined,
}));

const PaperCard = forwardRef((props, ref) => {
  const { size, border, onClick, to, children, actionAreaStyle = {}, ...rest } = props;

  return (
    <Root
      radius={size === 'small' ? 2 : undefined}
      shadow={!border ? 'standard' : 'none'}
      border={border}
      size={size}
      ref={ref}
      {...rest}
    >
      <CardActionArea
        component={Link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: size === 'small' ? 1.5 : 2,
          '& .MuiCardActionArea-focusHighlight': { background: 'transparent' },
          ...actionAreaStyle,
        }}
        to={to || ''}
        onClick={onClick}
      >
        {children}
      </CardActionArea>
    </Root>
  );
});

export default PaperCard;
