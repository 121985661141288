import { TEACHER_PERMISSIONS } from 'utils/constants';
import { hasPermission } from 'utils/helpers';

const mapInitialData = (user) => {
  if (!user) return {};

  const userData = {
    password: '',
    new_password: '',
  };

  Object.entries(TEACHER_PERMISSIONS).forEach(([name, permissions]) => {
    userData[name] = hasPermission(user?.profile?.permissions, permissions);
  });

  return userData;
};

export default mapInitialData;
