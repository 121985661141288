import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, styled } from '@mui/material';
import { Editor, EditorState, RichUtils, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';

import { propagateRefs, rawHtmlToEditorState } from 'utils/helpers';

import { Center, IconButton, InputContainer, FormLabel, FormHelperText } from 'components';

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
  LEFT: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'left',
  },
  RIGHT: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'right',
  },
  CENTER: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
  },
  JUSTIFY: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'justify',
  },
};

const BLOCK_TYPES = [
  { name: 'BulletedList', style: 'unordered-list-item' },
  { name: 'NumberedList', style: 'ordered-list-item' },
];

const INLINE_STYLES = [
  { name: 'Bold', style: 'BOLD' },
  { name: 'Italic', style: 'ITALIC' },
  { name: 'Underline', style: 'UNDERLINE' },
  { name: 'Strike', style: 'STRIKETHROUGH' },
];

const ALIGNMENT_STYLES = [
  { name: 'AlignLeft', style: 'LEFT' },
  { name: 'AlignCenter', style: 'CENTER' },
  { name: 'AlignRight', style: 'RIGHT' },
  { name: 'Justify', style: 'JUSTIFY' },
];

const ActionsHolder = styled(Center)(({ theme }) => ({
  padding: theme.spacing(0.5),
  boxShadow: theme.shadows.small,
  borderRadius: theme.shape.borderRadius * 2,
}));

const StyledInputContainer = styled(InputContainer)(({ theme }) => ({
  maxHeight: '100%',
  '& .DraftEditor-root': {
    width: '100%',
  },
  '& .public-DraftEditor-content': {
    minHeight: 146,
    maxHeight: 250,
    paddingRight: theme.spacing(1),
    cursor: 'text',
    overflow: 'auto',
    color: theme.palette.textBlack.dark,
  },
  '& .public-DraftEditorPlaceholder-root': {
    zIndex: 'inherit',
  },
  '& .DraftEditor-editorContainer': {
    zIndex: 'inherit',
  },
}));

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    color: active ? theme.palette.secondary.main : theme.palette.secondary.light,
  }),
);

const StyleButton = ({ onToggle, name, style, active }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    if (onToggle) {
      onToggle(style);
    }
  };

  return (
    <StyledIconButton
      name={name}
      active={active}
      size="xsmall"
      color="text"
      sx={{ minWidth: 24 }}
      onMouseDown={onMouseDown}
    />
  );
};

const BlockStyleControls = (props) => {
  const { editorState, onToggle, ...rest } = props;

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <ActionsHolder {...rest}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.name}
          active={type.style === blockType}
          name={type.name}
          style={type.style}
          onToggle={onToggle}
        />
      ))}
    </ActionsHolder>
  );
};

const AlignmentStyleControls = (props) => {
  const { editorState, onToggle, ...rest } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <ActionsHolder {...rest}>
      {ALIGNMENT_STYLES.map((type) => (
        <StyleButton
          key={type.name}
          active={currentStyle.has(type.style)}
          name={type.name}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </ActionsHolder>
  );
};

const InlineStyleControls = (props) => {
  const { editorState, onToggle, ...rest } = props;
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <ActionsHolder {...rest}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.name}
          active={currentStyle.has(type.style)}
          name={type.name}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </ActionsHolder>
  );
};

const TextEditor = (props) => {
  const {
    error,
    disabled,
    label: formLabel,
    optional,
    value: editorState,
    placeholder,
    fullWidth,
    onBlur,
    onChange,
    styleControlsLayout = ['inline', 'block', 'alignment'],
    ...rest
  } = props;

  const onEditorChange = (state) => {
    typeof onChange === 'function' && onChange(state);
  };

  const toggleBlockType = (blockType) => {
    onEditorChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleAlignmentStyle = (style) => {
    const stylesToClear = ['LEFT', 'CENTER', 'RIGHT', 'JUSTIFY'].filter((item) => item !== style);

    const nextEditorState = RichUtils.toggleInlineStyle(editorState, style);

    const contentWithoutStyles = stylesToClear.reduce(
      (newContentState, style) =>
        Modifier.removeInlineStyle(newContentState, nextEditorState.getSelection(), style),
      nextEditorState.getCurrentContent(),
    );

    onEditorChange(EditorState.push(nextEditorState, contentWithoutStyles, 'change-inline-style'));
  };

  const helperText = typeof error !== 'boolean' ? error : null;

  if (!editorState) return;

  return (
    <Box width={fullWidth ? 1 : undefined}>
      {formLabel && (
        <Box mb={0.5}>
          <FormLabel error={!!error} disabled={disabled} optional={!!optional}>
            {formLabel}
          </FormLabel>
        </Box>
      )}

      <Center justifyContent="flex-start" mb={1}>
        {styleControlsLayout.includes('inline') && (
          <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} mr={1} />
        )}
        {styleControlsLayout.includes('block') && (
          <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} mr={1} />
        )}
        {styleControlsLayout.includes('alignment') && (
          <AlignmentStyleControls editorState={editorState} onToggle={toggleAlignmentStyle} />
        )}
      </Center>
      <Box>
        <StyledInputContainer disabled={disabled} fullWidth error={!!error} onBlur={onBlur}>
          <Editor
            textAlignment="justify"
            placeholder={placeholder || 'Tell a story...'}
            customStyleMap={styleMap}
            editorState={editorState}
            onChange={onEditorChange}
            disabled={disabled}
            {...rest}
          />
        </StyledInputContainer>
      </Box>
      <FormHelperText error disabled={disabled}>
        {helperText}
      </FormHelperText>
    </Box>
  );
};

export const getInitialState = (initialContent) => {
  if (!initialContent) return EditorState.createEmpty();
  const contentState = rawHtmlToEditorState(initialContent);
  return EditorState.createWithContent(contentState);
};

TextEditor.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onChange, onBlur, inputRef, ...rest } = props;
  const { control, trigger, getFieldState } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={getInitialState(defaultValue)}
      render={({ field, fieldState }) => {
        const { isTouched } = getFieldState(name);
        const { error } = fieldState;
        const {
          ref: controlRef,
          onChange: controlOnChange,
          onBlur: controlOnBlur,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };

        const handleBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };

        return (
          <TextEditor
            ref={ref}
            error={isTouched && (error?.message || !!error)}
            inputRef={propagateRefs(inputRef, controlRef)}
            onBlur={handleBlur}
            {...rest}
            {...restField}
            onChange={handleChange}
          />
        );
      }}
    />
  );
});

export default TextEditor;
