import { useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUsersSearch, useMessage } from 'hooks';
import { useGetMeQuery, useRequestCollectionMutation } from 'store/api';

import { DropdownMenu, Button, Select } from 'components';
import { ROLE } from 'utils/constants';

const RequestCollection = (props) => {
  const { collectionId, children, onClick, onSuccess, ...rest } = props;

  const m = useMessage();
  const { t } = useTranslation('pages', { keyPrefix: 'single_collection.request_collection' });

  const [user, setUser] = useState(null);

  const [requestCollection, requestCollectionState] = useRequestCollectionMutation();
  const { data: me, isLoading, isFetching } = useGetMeQuery(undefined);

  const {
    options: userOptions,
    tempSearch: userSearch,
    onSearch: onUserSearch,
  } = useUsersSearch({
    spaceId: me?.space_id,
    disabled: isLoading || isFetching,
    mapToOptions: true,
    filters: {
      roles: [ROLE.TEACHER, ROLE.ADMIN],
      groups: [],
    },
  });

  const handleClose = useCallback(() => {
    setUser(null);
  }, []);

  const handleSubmit = async () => {
    try {
      const { status } = await requestCollection({ id: collectionId, user_id: me?.id }).unwrap();

      if (status === 'OK') {
        typeof onSuccess === 'function' && onSuccess();
        handleClose();
      }
    } catch (err) {
      console.error(err);
      m(t('error'));
    }
  };

  return (
    <DropdownMenu onAfterClose={handleClose} target={<Button {...rest}>{children}</Button>}>
      <Box width={332}>
        <Select
          collapse
          box={false}
          name="user"
          iconLeft="User"
          placeholder={t('choose_user')}
          fullWidth
          value={user}
          onValue={setUser}
          options={userOptions}
          searchable
          search={userSearch}
          onSearch={(v) => onUserSearch(null, v)}
          itemsMax={5}
        />
        <Box mt={1}>
          <Button
            type="button"
            disabled={!user || requestCollectionState?.isLoading}
            fullWidth
            size="small"
            radius={2}
            onClick={handleSubmit}
          >
            {t('submit')}
          </Button>
          <Button
            disabled={!user}
            fullWidth
            variant="text"
            size="small"
            radius={2}
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </DropdownMenu>
  );
};

export default RequestCollection;
