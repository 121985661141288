import { forwardRef, useState, useMemo } from 'react';
import { SvgIcon, Box } from '@mui/material';

import { dev } from 'utils/config';
import Fieldset from 'components/Dev/Fieldset';

import { ReactComponent as IconAdd } from 'assets/icons/add.svg';
import { ReactComponent as IconAdmin } from 'assets/icons/admin.svg';
import { ReactComponent as IconAlphabetical } from 'assets/icons/alphabetical.svg';
import { ReactComponent as IconAlphabetical2 } from 'assets/icons/alphabetical2.svg';
import { ReactComponent as IconAnalytic } from 'assets/icons/analitic.svg';
import { ReactComponent as IconAnalytic2 } from 'assets/icons/analitic2.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow_left.svg';
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as IconArrowUp } from 'assets/icons/arrow_up.svg';
import { ReactComponent as IconBarChart } from 'assets/icons/bar_chart.svg';
import { ReactComponent as IconBirthday } from 'assets/icons/birthday.svg';
import { ReactComponent as IconBookmark } from 'assets/icons/bookmark.svg';
import { ReactComponent as IconBookmarkFilled } from 'assets/icons/bookmark_filled.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg';
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';
import { ReactComponent as IconCheckBold } from 'assets/icons/check_bold.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconCheckboxChecked } from 'assets/icons/checkbox_checked.svg';
import { ReactComponent as IconCheckboxEmpty } from 'assets/icons/checkbox_empty.svg';
import { ReactComponent as IconClock } from 'assets/icons/clock.svg';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';
import { ReactComponent as IconCode } from 'assets/icons/code.svg';
import { ReactComponent as IconCollectionBookmark } from 'assets/icons/collection_bookmark.svg';
import { ReactComponent as IconCollection } from 'assets/icons/collection.svg';
import { ReactComponent as IconCollection2 } from 'assets/icons/collection2.svg';
import { ReactComponent as IconDate } from 'assets/icons/date.svg';
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { ReactComponent as IconDrag } from 'assets/icons/drag.svg';
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';
import { ReactComponent as IconEnvelope } from 'assets/icons/envelope.svg';
import { ReactComponent as IconEyeOff } from 'assets/icons/eye_off.svg';
import { ReactComponent as IconEye } from 'assets/icons/eye.svg';
import { ReactComponent as IconFile } from 'assets/icons/file.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter.svg';
import { ReactComponent as IconForum } from 'assets/icons/forum.svg';
import { ReactComponent as IconGaming } from 'assets/icons/gaming.svg';
import { ReactComponent as IconGenerate } from 'assets/icons/generate.svg';
import { ReactComponent as IconGroup } from 'assets/icons/group.svg';
import { ReactComponent as IconIdea } from 'assets/icons/idea.svg';
import { ReactComponent as IconIdea2 } from 'assets/icons/idea2.svg';
import { ReactComponent as IconImage } from 'assets/icons/image.svg';
import { ReactComponent as IconInfo } from 'assets/icons/info.svg';
import { ReactComponent as IconLink } from 'assets/icons/link.svg';
import { ReactComponent as IconLocation } from 'assets/icons/location.svg';
import { ReactComponent as IconLocation2 } from 'assets/icons/location2.svg';
import { ReactComponent as IconMail } from 'assets/icons/mail.svg';
import { ReactComponent as IconMediaPause } from 'assets/icons/media_pause.svg';
import { ReactComponent as IconMediaPlay } from 'assets/icons/media_play.svg';
import { ReactComponent as IconMoreHorizontal } from 'assets/icons/more_horizontal.svg';
import { ReactComponent as IconMoreVertical } from 'assets/icons/more_vertical.svg';
import { ReactComponent as IconNotification } from 'assets/icons/notification.svg';
import { ReactComponent as IconOrganization } from 'assets/icons/organization.svg';
import { ReactComponent as IconParent } from 'assets/icons/parent.svg';
import { ReactComponent as IconPhone } from 'assets/icons/phone.svg';
import { ReactComponent as IconPhone2 } from 'assets/icons/phone2.svg';
import { ReactComponent as IconProgramming } from 'assets/icons/programming.svg';
import { ReactComponent as IconProject } from 'assets/icons/project.svg';
import { ReactComponent as IconReply } from 'assets/icons/reply.svg';
import { ReactComponent as IconReply2 } from 'assets/icons/reply2.svg';
import { ReactComponent as IconResizeCorner } from 'assets/icons/resize_corner.svg';
import { ReactComponent as IconRobotic } from 'assets/icons/robotic.svg';
import { ReactComponent as IconRobotic2 } from 'assets/icons/robotic2.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconSend } from 'assets/icons/send.svg';
import { ReactComponent as IconSetting } from 'assets/icons/setting.svg';
import { ReactComponent as IconStudent } from 'assets/icons/student.svg';
import { ReactComponent as IconStudent2 } from 'assets/icons/student2.svg';
import { ReactComponent as IconTag } from 'assets/icons/tag.svg';
import { ReactComponent as IconTags } from 'assets/icons/tags.svg';
import { ReactComponent as IconTeacher } from 'assets/icons/teacher.svg';
import { ReactComponent as IconUpload } from 'assets/icons/upload.svg';
import { ReactComponent as IconUser } from 'assets/icons/user.svg';
import { ReactComponent as IconVideo } from 'assets/icons/video.svg';
import { ReactComponent as IconWorkflow } from 'assets/icons/workflow.svg';
import { ReactComponent as IconAlignCenter } from 'assets/icons/align_center.svg';
import { ReactComponent as IconAlignLeft } from 'assets/icons/align_left.svg';
import { ReactComponent as IconAlignRight } from 'assets/icons/align_right.svg';
import { ReactComponent as IconBold } from 'assets/icons/bold.svg';
import { ReactComponent as IconItalic } from 'assets/icons/italic.svg';
import { ReactComponent as IconJustify } from 'assets/icons/justify.svg';
import { ReactComponent as IconStrike } from 'assets/icons/strike.svg';
import { ReactComponent as IconUnderline } from 'assets/icons/underline.svg';
import { ReactComponent as IconBulletedList } from 'assets/icons/bulleted_list.svg';
import { ReactComponent as IconNumberedList } from 'assets/icons/numbered_list.svg';
import { ReactComponent as IconLevel } from 'assets/icons/level.svg';

export {
  IconAdd,
  IconAdmin,
  IconAlphabetical,
  IconAlphabetical2,
  IconAnalytic,
  IconAnalytic2,
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBarChart,
  IconBirthday,
  IconBookmark,
  IconBookmarkFilled,
  IconCalendar,
  IconCamera,
  IconCheckBold,
  IconCheck,
  IconCheckboxChecked,
  IconCheckboxEmpty,
  IconClock,
  IconClose,
  IconCode,
  IconCollectionBookmark,
  IconCollection,
  IconCollection2,
  IconDate,
  IconDelete,
  IconDownload,
  IconDrag,
  IconEdit,
  IconEnvelope,
  IconEyeOff,
  IconEye,
  IconFile,
  IconFilter,
  IconForum,
  IconGaming,
  IconGenerate,
  IconGroup,
  IconIdea,
  IconIdea2,
  IconImage,
  IconInfo,
  IconLink,
  IconLocation,
  IconLocation2,
  IconMail,
  IconMediaPause,
  IconMediaPlay,
  IconMoreHorizontal,
  IconMoreVertical,
  IconNotification,
  IconOrganization,
  IconParent,
  IconPhone,
  IconPhone2,
  IconProgramming,
  IconProject,
  IconReply,
  IconReply2,
  IconResizeCorner,
  IconRobotic,
  IconRobotic2,
  IconSearch,
  IconSend,
  IconSetting,
  IconStudent,
  IconStudent2,
  IconTag,
  IconTags,
  IconTeacher,
  IconUpload,
  IconUser,
  IconVideo,
  IconWorkflow,
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconItalic,
  IconJustify,
  IconStrike,
  IconUnderline,
  IconBulletedList,
  IconNumberedList,
  IconLevel,
}

const customSizeValues = {
  'xsmall': 16,
  'small': 24,
  'medium': 32,
};

const wrapIcon = (Icon) => {
  /**
   * @prop {string['xsmall'|'small'|'medium'|'large']|number} [fontSize] - icon size
   * @prop {number} [rotate=0] - Rotate icon
   * @prop {boolean} [flipX] - Flip horizontaly
   * @prop {boolean} [flipY] - Flip vertically
   */
  return forwardRef((props, ref) => {
    const { fontSize = 'xsmall', sx, rotate = 0, flipX, flipY, ...rest } = props;

    const fs = typeof fontSize === 'string' && !customSizeValues[fontSize] ? fontSize : undefined;

    const transform = useMemo(() => {
      return [
        `rotate(${rotate}deg)`,
        flipX && 'scaleX(-1)',
        flipY && 'scaleY(-1)',
      ].filter(Boolean).join(' ');
    }, [rotate, flipX, flipY]);

    return (
      <SvgIcon
        {...rest}
        ref={ref}
        fontSize={fs}
        component={Icon}
        sx={{
          transform,
          fontSize: Number.isFinite(fontSize) ? fontSize : customSizeValues[fontSize],
          transition: (theme) => theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
          }),
          ...sx,
        }}
      />
    );
  });
};

export const Add = wrapIcon(IconAdd);
export const Admin = wrapIcon(IconAdmin);
export const Alphabetical = wrapIcon(IconAlphabetical);
export const Alphabetical2 = wrapIcon(IconAlphabetical2);
export const Analytic = wrapIcon(IconAnalytic);
export const Analytic2 = wrapIcon(IconAnalytic2);
export const ArrowDown = wrapIcon(IconArrowDown);
export const ArrowLeft = wrapIcon(IconArrowLeft);
export const ArrowRight = wrapIcon(IconArrowRight);
export const ArrowUp = wrapIcon(IconArrowUp);
export const BarChart = wrapIcon(IconBarChart);
export const Birthday = wrapIcon(IconBirthday);
export const Bookmark = wrapIcon(IconBookmark);
export const BookmarkFilled = wrapIcon(IconBookmarkFilled);
export const Calendar = wrapIcon(IconCalendar);
export const Camera = wrapIcon(IconCamera);
export const CheckBold = wrapIcon(IconCheckBold);
export const Check = wrapIcon(IconCheck);
export const CheckboxChecked = wrapIcon(IconCheckboxChecked);
export const CheckboxEmpty = wrapIcon(IconCheckboxEmpty);
export const Clock = wrapIcon(IconClock);
export const Close = wrapIcon(IconClose);
export const Code = wrapIcon(IconCode);
export const CollectionBookmark = wrapIcon(IconCollectionBookmark);
export const Collection = wrapIcon(IconCollection);
export const Collection2 = wrapIcon(IconCollection2);
export const Date = wrapIcon(IconDate);
export const Delete = wrapIcon(IconDelete);
export const Download = wrapIcon(IconDownload);
export const Drag = wrapIcon(IconDrag);
export const Edit = wrapIcon(IconEdit);
export const Envelope = wrapIcon(IconEnvelope);
export const EyeOff = wrapIcon(IconEyeOff);
export const Eye = wrapIcon(IconEye);
export const File = wrapIcon(IconFile);
export const Filter = wrapIcon(IconFilter);
export const Forum = wrapIcon(IconForum);
export const Gaming = wrapIcon(IconGaming);
export const Generate = wrapIcon(IconGenerate);
export const Group = wrapIcon(IconGroup);
export const Idea = wrapIcon(IconIdea);
export const Idea2 = wrapIcon(IconIdea2);
export const Image = wrapIcon(IconImage);
export const Info = wrapIcon(IconInfo);
export const Link = wrapIcon(IconLink);
export const Location = wrapIcon(IconLocation);
export const Location2 = wrapIcon(IconLocation2);
export const Mail = wrapIcon(IconMail);
export const MediaPause = wrapIcon(IconMediaPause);
export const MediaPlay = wrapIcon(IconMediaPlay);
export const MoreHorizontal = wrapIcon(IconMoreHorizontal);
export const MoreVertical = wrapIcon(IconMoreVertical);
export const Notification = wrapIcon(IconNotification);
export const Organization = wrapIcon(IconOrganization);
export const Parent = wrapIcon(IconParent);
export const Phone = wrapIcon(IconPhone);
export const Phone2 = wrapIcon(IconPhone2);
export const Programming = wrapIcon(IconProgramming);
export const Project = wrapIcon(IconProject);
export const Reply = wrapIcon(IconReply);
export const Reply2 = wrapIcon(IconReply2);
export const ResizeCorner = wrapIcon(IconResizeCorner);
export const Robotic = wrapIcon(IconRobotic);
export const Robotic2 = wrapIcon(IconRobotic2);
export const Search = wrapIcon(IconSearch);
export const Send = wrapIcon(IconSend);
export const Setting = wrapIcon(IconSetting);
export const Student = wrapIcon(IconStudent);
export const Student2 = wrapIcon(IconStudent2);
export const Tag = wrapIcon(IconTag);
export const Tags = wrapIcon(IconTags);
export const Teacher = wrapIcon(IconTeacher);
export const Upload = wrapIcon(IconUpload);
export const User = wrapIcon(IconUser);
export const Video = wrapIcon(IconVideo);
export const Workflow = wrapIcon(IconWorkflow);
export const AlignCenter = wrapIcon(IconAlignCenter);
export const AlignLeft = wrapIcon(IconAlignLeft);
export const AlignRight = wrapIcon(IconAlignRight);
export const Bold = wrapIcon(IconBold);
export const Italic = wrapIcon(IconItalic);
export const Justify = wrapIcon(IconJustify);
export const Strike = wrapIcon(IconStrike);
export const Underline = wrapIcon(IconUnderline);
export const BulletedList = wrapIcon(IconBulletedList);
export const NumberedList = wrapIcon(IconNumberedList);
export const Level = wrapIcon(IconLevel);

const icons = {
  Add,
  Admin,
  Alphabetical,
  Alphabetical2,
  Analytic,
  Analytic2,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BarChart,
  Birthday,
  Bookmark,
  BookmarkFilled,
  Calendar,
  Camera,
  CheckBold,
  Check,
  CheckboxChecked,
  CheckboxEmpty,
  Clock,
  Close,
  Code,
  CollectionBookmark,
  Collection,
  Collection2,
  Date,
  Delete,
  Download,
  Drag,
  Edit,
  Envelope,
  EyeOff,
  Eye,
  File,
  Filter,
  Forum,
  Gaming,
  Generate,
  Group,
  Idea,
  Idea2,
  Image,
  Info,
  Link,
  Location,
  Location2,
  Mail,
  MediaPause,
  MediaPlay,
  MoreHorizontal,
  MoreVertical,
  Notification,
  Organization,
  Parent,
  Phone,
  Phone2,
  Programming,
  Project,
  Reply,
  Reply2,
  ResizeCorner,
  Robotic,
  Robotic2,
  Search,
  Send,
  Setting,
  Student,
  Student2,
  Tag,
  Tags,
  Teacher,
  Upload,
  User,
  Video,
  Workflow,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Bold,
  Italic,
  Justify,
  Strike,
  Underline,
  BulletedList,
  NumberedList,
  Level,
};

const Icon = forwardRef((props, ref) => {
  const { name, ...rest } = props;
  const TargetIcon = icons[name];

  if (!TargetIcon && dev) {
    console.warn(`Wrong icon name [${name}]. Allowed names: ${Object.keys(icons).join(', ')}`);
  }
  return !TargetIcon || !name ? null : (
    <TargetIcon {...rest} ref={ref} />
  );
});
Object.entries(icons).forEach(([name, value]) => {
  Icon[name] = value;
});

if (dev) {
  const Demo = () => {
    const [fontSize, setFontSize] = useState();
    const [color, setColor] = useState();

    return (
      <Box>
        <Box p={2} display="flex">
          <Fieldset>
            <Fieldset.Field
              legend="color"
              value={color}
              onChange={setColor}
              options={[undefined, 'primary', 'secondary', 'success', 'warning', 'error']}
            />
            <Fieldset.Field
              legend="fontSize"
              value={fontSize}
              onChange={setFontSize}
              options={[undefined, 'xsmall', 'small', 'medium', 'large', 15]}
            />
          </Fieldset>
        </Box>

        <Box>
          <div>Example:</div>
          <Box component="pre">
            {`<Icon name="Add" color="${color}" fontSize="${fontSize}" />`}
          </Box>
        </Box>

        <Box>
          <div>Or:</div>
          <Box component="pre">
            {`<Icon.Add color="${color}" fontSize="${fontSize}" />`}
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap" gap={4} my={2}>
          {Object.entries(icons).map(([name, Icon]) => {
            return (
              <Box
                key={name}
                width="60px"
                height="40px"
                flexShrink={0}
                display="flex"
                flexDirection="column"
                alignItems="center"
                title={`<Icon.${name} color="${color}" fontSize="${fontSize}" /> or <Icon name="${name}" color="${color}" fontSize="${fontSize}" />`}
              >
                <Icon color={color} fontSize={fontSize} />
                <Box component="span" fontSize="10px" mt={1}>{name}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };
  Icon.Demo = Demo;
}

export default Icon;
