import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps } from 'hooks';
import { STUDENT_PERMISSIONS } from 'utils/constants';

import { Form, FormRow, FormTitle, Switch } from 'components';
import { PasswordField } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const StudentSettingsForm = (props) => {
  const { id, onSubmit, form, passwordOptional = false } = props;

  const { t } = useTranslation('views', { keyPrefix: 'StudentSettingsForm' });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('password_section')}
        </Typography>
      </FormTitle>
      <Stack mt={2} mb={2} width="100%">
        <FormRow label={t('password.label')}>
          <PasswordField.Control
            fullWidth
            generate
            newPassword
            name="password"
            placeholder={t('password.placeholder')}
            optional={passwordOptional}
          />
        </FormRow>
      </Stack>

      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('individual_permissions')}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        {Object.entries(STUDENT_PERMISSIONS).map(([name]) => (
          <FormRow key={name} label={t(`permissions.${name}`)}>
            <Switch.Control fullWidth name={name} />
          </FormRow>
        ))}
      </Stack>
    </Form>
  );
};

export default StudentSettingsForm;
