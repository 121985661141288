import { useCallback } from 'react';
import useRules from './useRules.hook';

/**
 * Validator for cases when only the one of fields is required.
 * Do not forget to add fields deps
 * @param {object} form - react-hook-form "form" object
 * @param {object} fields - Object with validated fields, wher a key is field form name, and a value
 * is the field label.
 * 
 * Example:
 *  const validate = useOneOfRequired(form, {
 *    phone: t('phone.label'),
 *    email: t('email.label'),
 *  });
 *  ...
 *  name="email"
 *  rules={{
 *    validate,
 *    deps: ['phone'],
 *  }}
 *  ...
 *  name="phone"
 *  rules={{
 *    validate,
 *    deps: ['email'],
 *  }}
 */
const useOneOfRequired = (form, fields) => {
  const { getValues, getFieldState } = form;
  const { oneOfRequired } = useRules();

  return useCallback(() => {
    const fieldNames = Object.keys(fields);
    const fieldLabels = Object.values(fields);

    const vals = fieldNames.map((name) => getValues(name));
    const touch = fieldNames.map((name) => getFieldState(name).isTouched);

    // Validate only if all of fields are touched
    if (touch.every(Boolean)) {
      // Return an error if all of fields are empty
      if (!vals.some(Boolean)) {
        return oneOfRequired(...fieldLabels);
      }
    }
  }, [getValues, getFieldState, fields, oneOfRequired]);
};

export default useOneOfRequired;
