import { Box, Typography, Grid, styled } from '@mui/material';

import { Paper, Center, Tag } from 'components';

const ItemRoot = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.controls.main}`,
  borderRadius: theme.shape.borderRadius * 2,
}));

const ProjectStatus = (props) => {
  const { title, items = [] } = props;

  return (
    <Paper width={1} shadow="small">
      <Box width={1}>
        <Typography component="div" mb={1} variant="body2" color="textBlack.light">
          {title}
        </Typography>

        <Grid container spacing={2}>
          {items?.map((item) => (
            <Grid item xs={2.4} key={item.label}>
              <ItemRoot px={1.5} py={1}>
                <Center h={48} justifyContent="flex-start">
                  <Typography variant="subtitle3">{item.count}</Typography>
                  <Tag
                    ml={1}
                    label={`${item.percentage} %`}
                    sx={{
                      border: '1px solid',
                      borderColor: 'controls.main',
                      backgroundColor: 'input.main',
                    }}
                  />
                </Center>
                <Typography variant="body4" color="textBlack.light">
                  {item.label}
                </Typography>
              </ItemRoot>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProjectStatus;
