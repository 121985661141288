import { STUDENT_PERMISSIONS } from 'utils/constants';
import { getArray, hasPermission } from 'utils/helpers';

import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

export const mapInitialData = (student) => {
  if (!student) return {};

  const { profile, relations, health } = student;

  const birthdayDate = profile?.birth_date ? new Date(profile?.birth_date) : undefined;

  const personal = {
    username: student?.profile?.username || '',
    first_name: student?.profile?.first_name || '',
    last_name: student?.profile?.last_name || '',
    avatar_file_id: [student?.profile?.avatar],
    cached_avatar_id: student?.profile?.avatar,
    phone_number: student?.phone_number || '',
    email: student?.email || '',
    language: student?.profile?.preferred_language || '',
    permissions: student?.profile?.permissions || {},
    groups: getArray(student?.groups).map((g) => ({
      label: g.name,
      value: g.id,
      leader_id: g?.leader?.id,
    })),
    birthday: birthdayDate
      ? {
          year: `${birthdayDate.getFullYear()}`,
          month: birthdayDate.getMonth(),
          day: `${birthdayDate.getDate()}`,
        }
      : undefined,
  };

  const parents = getArray(relations).map((p) => {
    const relation = getArray(p.relations).find((r) => r.student_id === student.id);

    return {
      parent_id: p.id,
      avatar_file_id: [p.avatar],
      cached_avatar_id: p.avatar,
      full_name: [{ value: p.id, label: p.full_name }],
      phone_number: p.phone_number,
      email: p.email,
      country: p.country,
      city: p.city,
      state: p.state || '',
      apart: p.apartment || '',
      address: p.address || '',
      zipcode: p.zip || '',
      note: getInitialTextEditorState(p.notes),
      relation: relation?.relation,
      notify_projects: relation?.notify_projects,
      api_language: p.preferred_language,
      api_relations: p.relations,
    };
  });

  const studentHealth = getArray(health)[0];

  const studentDetails = studentHealth
    ? {
        health_id: studentHealth.id,
        first_contact_name: studentHealth.first_contact_name,
        first_contact_phone: studentHealth.first_contact_phone,
        second_contact_name: studentHealth.second_contact_name,
        second_contact_phone: studentHealth.second_contact_phone,
        note: getInitialTextEditorState(studentHealth.notes),
        food_allergies: getInitialTextEditorState(studentHealth.food_allergies),
        drugs_allergies: getInitialTextEditorState(studentHealth.drugs_allergies),
      }
    : {};

  const settings = {
    password: '',
  };

  Object.entries(STUDENT_PERMISSIONS).forEach(([name, permissions]) => {
    settings[name] = hasPermission(student?.profile?.permissions, permissions);
  });

  return { personal, parents, health: studentDetails, settings };
};

export const mapParentToFormData = (parent, studentId) => {
  const relation = parent.relations.find((r) => r.student_id === studentId);

  return {
    parent_id: parent.id,
    avatar_file_id: [parent.avatar],
    full_name: [{ ...parent, avatarSize: 16, pl: 1, label: parent.full_name, value: parent.id }],
    email: parent.email || '',
    phone_number: parent.phone_number || null,
    note: getInitialTextEditorState(parent.notes),
    zipcode: parent.zip || '',
    address: parent.address || '',
    apart: parent.address || '',
    state: parent.state || '',
    relation: relation ? relation.relation : undefined,
    notify_projects: relation ? relation.notify_projects : false,
    country: parent.country,
    city: parent.city,
    api_relations: parent.relations,
    api_language: parent.preferred_language,
  };
};

export const mapStudentToFormData = (student, changedPermissions) => {
  const postData = {
    id: student.id,
    username: student.profile.username,
    phone_number: student.phone_number || null,
    first_name: student.profile.first_name,
    last_name: student.profile.last_name,
    birth_date: new Date(student.profile.birth_date).toISOString(),
    language: student.profile.preferred_language,
    avatar_file_id: student.profile.avatar,
    permissions: {},
    groups: getArray(student.groups).map((g) => g.id),
  };

  Object.entries(STUDENT_PERMISSIONS).forEach(([name, permissions]) => {
    permissions.forEach((permission) => {
      postData.permissions[permission] = changedPermissions[name];
    });
  });

  return postData;
};
