import { forwardRef, useRef, useState, useLayoutEffect } from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled'

import { useCombineRefs } from 'hooks';
import { dev } from 'utils/config';
import Progress from 'components/Progress';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(Box, {
  label: 'ProgressBox-Root',
  shouldForwardProp: (prop) => !['disableEvents', 'loading', 'overflow'].includes(prop),
})(({ loading, disableEvents, overflow }) => ({
  overflow: overflow || 'auto',
  position: 'relative',
  pointerEvents: !disableEvents && loading ? 'none' : 'all',
}));

const StickyWrapper = styled(Box, {
  label: 'ProgressBox-StickyWrapper',
})({
  zIndex: 10,
  position: 'sticky',
  top: '50%',
  left: '50%',
});

const ProgressWrapper = styled(Box, {
  label: 'ProgressBox-Wrapper',
})({
  zIndex: 1,
  position: 'absolute',
  left: '50%',
  top: '50%',
  display: 'inline-block',
  transform: 'translate(-50%, -50%)',
  fontSize: 0,
});

const Backdrop = styled(Box, {
  label: 'ProgressBox-Backdrop',
  shouldForwardProp: (prop) => !['loading', 'backdrop'].includes(prop),
})(({ theme, backdrop, loading, width, height }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: width || 0,
  height: height || 0,
  backgroundColor: theme.palette.primary.light,
  transition: theme.transitions.create('opacity'),
  opacity: loading && backdrop ? 0.25 : 0,
  zIndex: 10,
}));

/**
 * @prop {boolean} [loading] - Show loading indicator
 * @prop {boolean} [backdrop=true] - Show backdrop while loading
 * @prop {boolean} [disableEvents] - Disable element events while loading (including scrolling)
 */
const ProgressBox = forwardRef((props, ref) => {
  const {
    loading,
    children,
    disableEvents = true,
    backdrop = true,
    ...rest
  } = props;

  const rootRef = useRef(null);
  const backdropRef = useRef(null);
  const handleRootRef = useCombineRefs(rootRef, ref);
  const [backdropWidth, setBackdropWidth] = useState(0);
  const [backdropHeight, setBackdropHeight] = useState(0);

  useLayoutEffect(() => {
    const { current: rootEl } = rootRef;
    const { current: backdropEl } = backdropRef;

    if (rootEl && backdropEl) {
      if (loading && backdrop) {
        setBackdropWidth(rootEl.scrollWidth);
        setBackdropHeight(rootEl.scrollHeight);
      } else {
        setBackdropWidth(0);
        setBackdropHeight(0);
      }
    }
  }, [backdrop, loading]);

  return (
    <Root
      {...rest}
      ref={handleRootRef}
      loading={loading}
      disableEvents={disableEvents}
    >
      {loading && (
        <StickyWrapper>
          <ProgressWrapper>
            <Progress />
          </ProgressWrapper>
        </StickyWrapper>
      )}

      {children}

      <Backdrop
        ref={backdropRef}
        loading={loading}
        backdrop={backdrop}
        width={backdropWidth}
        height={backdropHeight}
      />
    </Root>
  );
});

if (dev) {
  const Demo = () => {
    const [loading, setLoading] = useState(true);
    const [backdrop, setBackdrop] = useState(true);
    const [disableEvents, setDisableEvents] = useState(true);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="loading"
            value={loading}
            onChange={setLoading}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="backdrop"
            value={backdrop}
            onChange={setBackdrop}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="disableEvents"
            value={disableEvents}
            onChange={setDisableEvents}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box
          display="flex"
          maxHeight="500px"
          height="500px"
          overflow="hidden"
          gap="50px"
        >
          <ProgressBox
            border="1px solid black"
            loading={loading}
            height="100%"
            width="300px"
            backdrop={backdrop}
            disableEvents={disableEvents}
          />

          <ProgressBox
            border="1px solid black"
            height="100%"
            width="300px"
            overflow="auto"
            loading={loading}
            backdrop={backdrop}
            disableEvents={disableEvents}
          >
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
            <div>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat accusantium eaque perspiciatis odio, saepe aperiam natus sit voluptates dicta, ad blanditiis, sint ea maiores asperiores?</div>
          </ProgressBox>
        </Box>

      </Box>
    );
  };
  ProgressBox.Demo = Demo;
}

export default ProgressBox;
