import { Box, darken, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import {
  collections,
  expirience,
  fancy_circle_1,
  fancy_circle_2,
  footer_layers,
  ideas_example,
  line1,
  line2,
  line3,
  line4,
  line5,
  portfolio,
  project_screenshot_1,
  project_screenshot_glass,
  projects,
  section_2_background,
  statistic,
  video,
} from 'assets/images/pages/home';

import { withProps } from 'hooks';
import { MainLayout } from 'layouts';
import { Button, Center, Image, Ref } from 'components';
import { ProjectButton } from 'views';

import Loop from './Loop';
import PresentationCard from './PresentationCard';

const landingWidth = 1256;

const Section = styled(withProps(Center, { component: 'section' }), {
  label: 'Section',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Column = styled(Box, {
  label: 'Column',
})({
  flexGrow: 1,
  width: '50%',
  maxWidth: '50%',
  minWidth: '50%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const BodyOffset = styled(Box, {
  label: 'BodyOffset',
})({ flexGrow: 1 });

const BodyBox = styled(BodyOffset, {
  label: 'BodyBox',
  shouldForwardProp: (prop) => !['debug'].includes(prop),
})(({ debug }) => ({
  display: 'flex',
  position: 'relative',
  minWidth: landingWidth,
  [`${Column}`]: {
    '&:first-of-type': {
      outline: debug ? '1px dotted red' : undefined,
    },
    '&:last-of-type': {
      outline: debug ? '1px dotted red' : undefined,
    },
  },
}));

const keyPrefix = 'home';

const Home = () => {
  const { t } = useTranslation('pages', { keyPrefix });

  return (
    <MainLayout bgcolor="input.main">

      {/* SECTION 1 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column>
            <Box mt="121px" mb="284px" maxWidth={552}>
              <Typography
                fontSize="68px"
                fontWeight={700}
                fontFamily="Hind Vadodara"
                color="primary.dark"
                letterSpacing="0.015em"
                lineHeight={1.1}
              >
                {t('section_1.title.a_place_for_those')}
              </Typography>

              <Typography
                fontSize="68px"
                fontWeight={300}
                color="textBlack.dark"
                letterSpacing="0.015em"
                lineHeight={1.1}
              >
                {t('section_1.title.who_create_and_teach')}
              </Typography>

              <Typography
                mt={2}
                fontSize="18px"
                fontWeight={300}
                color="textBlack.dark"
                lineHeight={2}
              >
                {t('section_1.title.description')}
              </Typography>

              <Box mt={3}>
                <Button
                  size="large"
                  width={152}
                  radius={2}
                  to="/login"
                >
                  {t('section_1.join_for_free')}
                </Button>
              </Box>
            </Box>
          </Column>

          <Column alignItems="flex-start">
            <Box
              width={1}
              height={1}
              ml="111px"
              bgcolor="primary.main"
              sx={{ borderBottomLeftRadius: '64px' }}
            />

            <Image
              src={fancy_circle_1}
              alt={t('fancy_circle.alt')}
              position="absolute"
              top={4.47}
              left={176.19}
            />
            <Center position="absolute" top={106.73}>
              <Loop
                position="absolute"
                top={-42.73}
                right={-48}
              />

              <Image
                src={project_screenshot_glass}
                alt={t('project_screenshot.glass.alt')}
                position="relative"
              />

              <Image
                src={project_screenshot_1}
                alt={t('project_screenshot.screenshot.alt')}
                position="absolute"
              />
              <Image
                src={fancy_circle_1}
                alt={t('fancy_circle.alt')}
                position="absolute"
                width={16.48}
                right={-8}
                bottom={167.28}
              />
            </Center>
          </Column>
        </BodyBox>

        <BodyOffset>
          <Box height={1} width={1} bgcolor="primary.main" />
        </BodyOffset>
      </Section>

      {/* SECTION 2 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Image
            src={section_2_background}
            alt={t('project_screenshot.screenshot.alt')}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          />
          <Column pt="191px" pb="369px">
            <PresentationCard
              icon="Teacher"
              title={t('section_2.card_1.title')}
              content={t('section_2.card_1.content')}
            />
          </Column>

          <Column pt="287px" pb="197px">
            <PresentationCard
              icon="User"
              title={t('section_2.card_2.title')}
              content={t('section_2.card_2.content')}
              alignSelf="flex-end"
            />
          </Column>
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 3 */}
      <Section
        py={10}
        bgcolor="primary.main"
        alignItems="center"
        flexDirection="column"
      >
        <Typography
          mb={7.5}
          fontSize="68px"
          fontWeight={600}
          lineHeight={1.1}
          letterSpacing="0.015em"
          color="other.white"
        >
          {t('section_3.title')}
        </Typography>

        <Box position="relative">

          {/* TODO: Replace with real video */}
          <Image
            src={video}
            mx="-169.5px"
            mt="-120px"
            mb="-220px"
          />

          <Loop position="absolute" top={-20} right={-110} />

          <Image
            src={fancy_circle_2}
            alt={t('fancy_circle.alt')}
            position="absolute"
            top={157.43}
            left={-164}
          />

          <Image
            src={fancy_circle_2}
            alt={t('fancy_circle.alt')}
            position="absolute"
            width={16.48}
            top={-122.92}
            right={-60.48}
          />
        </Box>
      </Section>

      {/* SECTION 4 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column>
            <Image
              src={ideas_example}
              position="absolute"
              top={275}
              left={-23}
            />
          </Column>

          <Column pt="204px">
            <PresentationCard
              caption={t('section_4.card.caption')}
              title={t('section_4.card.title')}
              content={t('section_4.card.content')}
              width={576}
              alignSelf="flex-end"
              actions={
                <Button radius={2} to="/ideas">
                  {t('section_4.card.actions.see_all_ideas')}
                </Button>
              }
            />
          </Column>

          <Image
            src={line1}
            alt={t('line.alt')}
            position="absolute"
            transform="translateY(100%)"
            bottom={0}
            left={304.55}
          />
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 5 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column pt="415px" pb="119px">
            <PresentationCard
              title={t('section_5.card.title')}
              content={t('section_5.card.content')}
              width={576}
            />
          </Column>

          <Column>
            <Image
              src={projects}
              alt={t('section_5.image.alt')}
              position="absolute"
              top={278}
              left={-50}
            />
          </Column>

          <Image
            src={line2}
            alt={t('line.alt')}
            position="absolute"
            bottom={0}
            right={267.86}
            transform="translateY(100%)"
          />
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 6 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column>
            <Image
              src={collections}
              alt={t('section_6.image.alt')}
              position="absolute"
              top={122}
              right={0}
            />
          </Column>

          <Column pt="315px" pb="202px">
            <PresentationCard
              title={t('section_6.card.title')}
              content={t('section_6.card.content')}
              width={576}
              alignSelf="flex-end"
            />
          </Column>

          <Image
            src={line3}
            alt={t('line.alt')}
            position="absolute"
            bottom={0}
            left={380}
            transform="translateY(100%)"
          />
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 7 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column pt="354px" pb="19px">
            <PresentationCard
              title={t('section_7.card.title')}
              content={t('section_7.card.content')}
              width={576}
            />
          </Column>

          <Column>
            <Image
              src={portfolio}
              alt={t('section_7.image.alt')}
              position="absolute"
              top={294.47}
              left={20}
            />
          </Column>

          <Image
            src={line4}
            alt={t('line.alt')}
            position="absolute"
            bottom={0}
            right={252}
            transform="translateY(100%)"
          />
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 8 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column>
            <Image
              src={statistic}
              alt={t('section_8.image.alt')}
              position="absolute"
              top={120}
              right={0}
            />
          </Column>

          <Column pt="335.95px" pb="183px">
            <PresentationCard
              title={t('section_8.card.title')}
              content={t('section_8.card.content')}
              alignSelf="flex-end"
              width={576}
            />
          </Column>

          <Image
            src={line5}
            alt={t('line.alt')}
            position="absolute"
            bottom={0}
            left={340}
            transform="translateY(100%)"
          />
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* SECTION 9 */}
      <Section>
        <BodyOffset />

        <BodyBox>
          <Column pt="300px" pb="248px">
            <PresentationCard
              title={t('section_9.card.title')}
              content={t('section_9.card.content')}
              width={576}
            />
          </Column>

          <Column>
            <Image
              src={expirience}
              alt={t('section_9.image.alt')}
              position="absolute"
              right={0}
              top={320}
            />
          </Column>
        </BodyBox>

        <BodyOffset />
      </Section>

      {/* FOOTER */}
      <Section
        pt="125px"
        bgcolor="primary.main"
        alignItems="center"
        flexDirection="column"
        overflow="hidden"
      >
        <Box width={581} mb={7}>
          <Box width={1} position="relative">
            <Image
              src={footer_layers}
              alt={t('footer.image.alt')}
              position="absolute"
              top={-75}
              left={530}
            />

            <Loop
              position="absolute"
              top={0}
              left={0}
              blur={0.8}
            >
              <Box
                position="absolute"
                width={581}
                top={0}
                left={0}
              >
                <Typography
                  pt="39px"
                  fontSize="68px"
                  fontWeight={600}
                  lineHeight={1.25}
                  letterSpacing="0.015em"
                  textAlign="center"
                  sx={{
                    color: (theme) => darken(theme.palette.primary.dark, 0.4),
                  }}
                >
                  {t('footer.start_your_journey')}
                </Typography>
              </Box>
            </Loop>

            <Typography
              pt="39px"
              color="other.white"
              fontSize="68px"
              fontWeight={600}
              lineHeight={1.25}
              letterSpacing="0.015em"
              textAlign="center"
            >
              {t('footer.start_your_journey')}
            </Typography>
          </Box>
        </Box>

        <Box mb={12}>
          <Button
            radius={2}
            size="large"
            to="sign-up"
            sx={{
              borderColor: 'transparent',
              bgcolor: 'primary.dark',
              '&:hover': {
                bgcolor: (theme) => darken(theme.palette.primary.dark, 0.2),
              },
            }}
          >
            {t('footer.join_makernotebook')}
          </Button>
        </Box>

        <Box mb={2} width={1} px="152px">
          <Box
            width={1}
            height="1px"
            bgcolor="primary.dark"
          />
        </Box>

        <Box mb={2}>
          <ProjectButton />
        </Box>

        <Box mb={4}>
          <Stack spacing="70px" direction="row" color="other.white">
            <Ref color="inherit" to="/about">
              {t('footer.about')}
            </Ref>

            <Ref color="inherit" to="/ideas">
              {t('footer.ideas')}
            </Ref>

            <Ref color="inherit" to="/knowledge-base">
              {t('footer.knowledge_base')}
            </Ref>
          </Stack>
        </Box>
      </Section>

    </MainLayout>
  );
};

export default Home;
