export const updateParentRelations = (relations = [], item) => {
  let foundIndex = false;
  for (let i = 0; i < relations.length; i++) {
    if (relations[i]?.student_id !== item.student_id) return;

    foundIndex = i;
    break;
  }

  if (foundIndex === false) return [...relations, item];

  return relations.map((r) => (r.student_id === item.student_id ? item : r));
};
