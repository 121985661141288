import { forwardRef, useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';

import { dev } from 'utils/config';
import { useCombineRefs } from 'hooks';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(Box, {
  label: 'RippleBox-Root',
})({
  position: 'relative',
});

/**
 * Box with ripple effect (like a button click feedback)
 * @prop {boolean} [ripple] - Enable/disable ripple effect on click
 * @prop {boolean} [show] - Show ripple
 */
const RippleBox = forwardRef((props, ref) => {
  const { children, onClick, ripple, show, ...rest } = props;

  const rootRef = useRef(null);
  const rippleRef = useRef(null);
  const handleRef = useCombineRefs(ref, rootRef);

  const handleRipple = useCallback((e) => {
    const { current: root } = rootRef;
    const { current: rippleApi } = rippleRef;

    if (root && rippleApi && ripple) {
      rippleApi.start(e, { center: false });
      setTimeout(() => rippleApi.stop({}), 100);
    }
    typeof onClick === 'function' && onClick(e);
  }, [onClick, ripple]);

  return (
    <Root
      {...rest}
      ref={handleRef}
      onClick={handleRipple}
    >
      {children}
      <TouchRipple ref={rippleRef} />
    </Root>
  );
});

if (dev) {
  const Demo = () => {
    const [ripple, setRipple] = useState(true);

    return (
      <Box p={2}>
        <Box p={1}>Just click on the box</Box>
        <Fieldset>
          <Fieldset.Field
            legend="ripple"
            value={ripple}
            onChange={setRipple}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre" mb={2}>
          {`<RippleBox\n`}
          {`  p={4}\n`}
          {`  ripple={${ripple}}\n`}
          {`  bgcolor="yellow"\n`}
          {`  border="1px solid blue"\n`}
          {`>\n`}
          {`  Ripple Box\n`}
          {`</RippleBox>\n`}
        </Box>

        <RippleBox
          p={4}
          ripple={ripple}
          bgcolor="yellow"
          border="1px solid blue"
        >
          Ripple Box
        </RippleBox>
      </Box>
    );
  };
  RippleBox.Demo = Demo;
}

export default RippleBox;
