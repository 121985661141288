import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { cotton } from 'assets/images/holders';

import { Modal, ImageHolder, Center, Button } from 'components';

const ProgressUpdateSuccessModal = (props) => {
  const { open, onClose, onRequestAddStudentWork } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'single_project.progress_update' });

  return (
    <Modal open={open} onClose={onClose} size={516}>
      <Modal.Body>
        <Center pt={3} pb={3} flexDirection="column">
          <ImageHolder
            p={1}
            size={96}
            alt={t('experience_earned')}
            src={cotton}
            origin="container"
            shadow="smooth"
            mb={2}
            bgcolor="warning.contrastText"
          />
          <Typography variant="subtitle3" maxWidth={374} textAlign="center" color="success.dark">
            {t('exp_change', { points: 5 })}
          </Typography>
          <Typography variant="body4" maxWidth={374} textAlign="center" color="textBlack.dark">
            {t('experience_earned')}
          </Typography>

          <Center gap={1.5} mt={4}>
            <Button size="small" radius={2} type="button" onClick={onClose}>
              {t('back')}
            </Button>
            <Typography>{t('or')}</Typography>
            <Button
              size="small"
              radius={2}
              type="button"
              variant="outlined"
              onClick={onRequestAddStudentWork}
            >
              {t('add_more')}
            </Button>
          </Center>
        </Center>
      </Modal.Body>
    </Modal>
  );
};

export default ProgressUpdateSuccessModal;
