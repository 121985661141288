import { Box, styled } from '@mui/material';

import MainLayout from 'layouts/MainLayout';

const Root = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  backgroundColor: theme.palette.other.background,
}));

const Subheader = styled(Box)(({ theme }) => ({
  height: 64,
  flexShrink: 0,
  flexGrow: 0,
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Sidebar = styled(Box)(({ theme }) => ({
  width: 248,
  flexShrink: 0,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ProjectLayout = (props) => {
  const { children, subheader, sidebar, subheaderProps, sidebarProps, ...rest } = props;

  return (
    <MainLayout {...rest}>
      <Root>
        {subheader && (
          <Subheader {...subheaderProps} px={4}>
            {subheader}
          </Subheader>
        )}

        <Box display="flex" flexGrow={1} overflow="hidden">
          {sidebar && <Sidebar {...sidebarProps}>{sidebar !== true && sidebar}</Sidebar>}

          <Box flexGrow={1} overflow="hidden auto">
            {children}
          </Box>
        </Box>
      </Root>
    </MainLayout>
  );
};

export default ProjectLayout;
