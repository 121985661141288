import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useForm, useMessage, useAuth, useUploadPhotos, useCreateTag } from 'hooks';
import { getArray, capitalize } from 'utils/helpers';
import {
  useGetMeQuery,
  useUpdateProjectMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
  useGetProjectQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper, Tabs } from 'components';
import { PageSubheader, ProjectInformationForm, ProjectStepsForm } from 'views';
import { getLevelStyles } from 'views/LevelTag';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };
const defaultStepValue = (idx) => ({
  step_number: idx,
  name: '',
  photos: [],
  sources: [{ ...defaultSourceValue }],
});

const mapInitialData = (project) => {
  if (!project) {
    return {
      sources: [defaultSourceValue],
    };
  }

  const steps = getArray(project.steps).map((step) => ({
    id: step.id,
    step_number: step.step,
    name: step.name,
    description: getInitialTextEditorState(step.description),
    photos: step.photos.map((photo) => ({ url: photo })),
    sources: step.sources.map((source) => ({ url: source.link })),
  }));

  return {
    idea_id: project?.idea?.id,
    name: project.name,
    copyright: project.copyright,
    level: project.level,
    public: project.public,
    description: getInitialTextEditorState(project.description),
    sources: project.sources.map((source) => ({ url: source.link })),
    tags: project.tags.map((tag) => ({ value: tag.id, label: tag.name })),
    photos: project.photos.map((photo) => ({ url: photo })),
    steps: steps?.length > 0 ? steps : [defaultStepValue(0)],
  };
};

const EditProject = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'edit_project' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const [tab, setTab] = useState('information');

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: project,
    isLoading: projectLoading,
    isFetching: projectFetching,
  } = useGetProjectQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery();

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createTag, createTagState] = useCreateTag({ me });
  const [updateProject, updateProjectState] = useUpdateProjectMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  useEffect(() => {
    form.reset(mapInitialData(project));
  }, [form, project]);

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const uploadProjectPhotos = useCallback(
    async (postData) => {
      try {
        const { photos, steps } = postData;

        let projectPhotoIds = [];
        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;
          projectPhotoIds = photoIds;
        }

        const promises = steps.map(async (step, index) => {
          if (step?.photos?.length === 0) return step;
          const photoIds = await uploadPhotos(`steps[${index}].photos`, photos);
          return { ...step, photos: photoIds };
        });

        const stepsWithPhotoIds = await Promise.all(promises);

        return {
          ...postData,
          photos: projectPhotoIds,
          steps: stepsWithPhotoIds,
        };
      } catch (err) {
        console.error(err);
        m.error(t('error_failed_upload_photos'));
        return postData;
      }
    },
    [uploadPhotos, m, t],
  );

  const handleUpdateProject = useCallback(
    async (formData) => {
      try {
        const postData = mapRequest(formData);
        const postDataWithPhotoIds = await uploadProjectPhotos(postData);

        const { status } = await updateProject({
          id,
          ...postDataWithPhotoIds,
        }).unwrap();

        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_update'));
        }
      }
    },
    [id, updateProject, m, uploadProjectPhotos, t],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const tabOptions = useMemo(
    () => [
      {
        label: t('tabs.project_information'),
        value: 'information',
      },
      {
        label: t('tabs.project_steps'),
        value: 'steps',
      },
    ],
    [t],
  );

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadPhotosState.isLoading ||
    updateProjectState.isLoading ||
    projectLoading ||
    projectFetching;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleUpdateProject}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/learning/projects"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                type: 'button',
                disabled: loading,
                variant: 'outlined',
                size: 'small',
                onClick: reset,
              },
              [t('page_actions.edit')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={2} component="h3" color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>

          <Tabs
            mb={2}
            width={396}
            variant="fullWidth"
            value={tab}
            onValue={setTab}
            options={tabOptions}
          />

          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            {tab === 'information' && (
              <ProjectInformationForm
                rules={rules}
                loadingTags={loadingTags}
                tagsOptions={tagsOptions}
                levelOptions={levelOptions}
                loadingLevels={loadingLevels}
                defaultSourceValue={defaultSourceValue}
                onCreateTag={createTag}
              />
            )}
            {tab === `steps` && (
              <ProjectStepsForm
                rules={rules}
                defaultStepValue={defaultStepValue}
                defaultSourceValue={defaultSourceValue}
              />
            )}
          </Paper>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default EditProject;
