import { useState, useMemo, useEffect } from 'react';

import { getArray } from 'utils/helpers';
import { useGetGroupsQuery } from 'store/api';

const useGroupSearch = (opts) => {
  const { userId, spaceId, disabled, mapToOptions, onData } = opts || {};

  const [search, setSearch] = useState('');

  const { data, isLoading, isFetching } = useGetGroupsQuery(
    { user_id: userId, space_id: spaceId, limit: 100, offset: 0 },
    {
      skip: disabled,
    },
  );

  useEffect(() => {
    if (!data) return;
    typeof onData === 'function' && onData(data);
  }, [data, onData]);

  return useMemo(() => {
    const result = {
      data,
      loading: isLoading || isFetching,
      search,
      onSearch: (_e, val) => setSearch(val),
    };

    if (mapToOptions && data?.groups) {
      result.options = getArray(data?.groups).map((group) => ({
        value: group.id,
        label: group.name,
        leader_id: group?.leader?.id,
      }));
    }

    return result;
  }, [data, isLoading, isFetching, search, setSearch, mapToOptions]);
};

export default useGroupSearch;
