import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from 'utils/constants';
import { getArray } from 'utils/helpers';

import { Paper, ImageHolder, Center, DropdownMenu, Button, PaperCard } from 'components';
import { CardContent } from 'views';
import { useAcl, useGetPortfolioCreationOptions } from 'hooks';

import { page_empty } from 'assets/images/holders';

const PortfolioTab = (props) => {
  const { portfolios = [], loading } = props;

  const acl = useAcl();
  const { t } = useTranslation('pages', { keyPrefix: 'profile.portfolio' });

  const options = useGetPortfolioCreationOptions();

  return (
    <Box pt={3}>
      {!portfolios?.length && (
        <Paper width="100%" loading={loading}>
          <Center width="100%" py={5} flexDirection="column">
            <ImageHolder mb={3} alt="no results" size={210} src={page_empty} />
            <Typography component="div" mb={2} color="primary" variant="body2">
              {t('empty_text')}
            </Typography>
            {acl.allow({ permissions: [PERMISSIONS.CREATE_PORTFOLIO] }) && (
              <DropdownMenu
                minWidth={200}
                target={<Button iconRight="ArrowDown">{t('create_portfolio')}</Button>}
                options={options}
              />
            )}
          </Center>
        </Paper>
      )}
      {portfolios?.length > 0 && (
        <Grid container spacing={3}>
          {getArray(portfolios).map((portfolio) => (
            <Grid item xs={6} key={portfolio.id}>
              <PaperCard size="small" to={`/profile/portfolio/${portfolio.id}`}>
                <CardContent
                  variant="portfolio"
                  photo={portfolio?.avatar}
                  title={portfolio.name}
                  projectsQty={portfolio.projects_qty}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PortfolioTab;
