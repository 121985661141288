import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dev } from 'utils/config';
import { makeOptions, capitalize } from 'utils/helpers';

import { Gallery } from 'components';
import { getLevelStyles } from 'views/LevelTag';
import Fieldset from 'components/Dev/Fieldset';

import avatar from 'assets/images/examples/avatar.png';

import TagsBar from './TagsBar';
import Description from './Description';
import Sources from './Sources';
import MetaData from './MetaData';

const ArticleGeneralInformation = (props) => {
  const { t } = useTranslation('views', { keyPrefix: 'ArticleGeneralInformation' });

  const {
    slug,
    tags,
    maxTags,
    author,
    dateCreated,
    description,
    bookmarked,
    onBookmark,
    sources,
    photos,
    level,
    customMetaData,
    hideTitle = false,
    ...rest
  } = props;

  const tagGroups = useMemo(() => {
    const result = [];
    if (!!level) {
      result.push({
        getExtraTagProps: () => ({
          sx: getLevelStyles({ level, border: true }),
        }),
        title: t('level'),
        tags: [{ id: level, name: capitalize(level) }],
      });
    }

    if (tags?.length > 0) {
      result.push({ title: t('related_tags'), tags: tags, max: maxTags });
    }

    return result;
  }, [maxTags, tags, level, t]);

  return (
    <Box {...rest}>
      {!hideTitle && (
        <Typography component="div" variant="subtitle5" mb={2} color="textBlack.dark">
          {t('title')}
        </Typography>
      )}

      {tagGroups.length > 0 && <TagsBar groups={tagGroups} />}

      {customMetaData}
      {!customMetaData && (author || dateCreated) && (
        <MetaData bookmarked={bookmarked} onBookmark={onBookmark}>
          {author && <MetaData.Author author={author} slug={slug} />}
          {dateCreated && <MetaData.DateCreated dateCreated={dateCreated} />}
        </MetaData>
      )}

      {description && <Description description={description} title={t('description')} />}
      {sources?.length > 0 && <Sources sources={sources} />}
      {photos?.length > 0 && (
        <Box px={2}>
          <Gallery images={photos} />
        </Box>
      )}
    </Box>
  );
};

if (dev) {
  const tags = makeOptions([
    'creativity',
    'innovation',
    'modeling',
    'development',
    'design',
    'quiz',
  ]).map((option) => ({
    id: option.value,
    name: option.label,
  }));

  const sources = makeOptions(['cardboard_robotic_hydraulic_arm', 'general_information']).map(
    (option) => ({
      link: `https://www.google.com/search?q=${option.label}`,
      title: option.label,
    }),
  );

  const description = `In this Instructables we will be showing you how to make your very own Hydraulic Arm! This
  project focuses on the principles of hydraulic movements. To do that we made a small scale
  demonstration of a Robotic Hydraulic Arm. We managed to do some pretty cool tasks with our
  ''Arm'', do check out the video down below for a better understanding!`;

  const author = {
    avatar: avatar,
    fullName: 'Mega Foggy',
  };

  const dateCreated = '03.03.2022';

  const Demo = () => {
    const [bookmarked, setBookmarked] = useState(true);
    const [withAuthor, setWithAuthor] = useState(true);
    const [withCreationDate, setWithCreationDate] = useState(true);
    const [withCustomMetaData, setWithCustomMetaData] = useState(false);
    const [withDescription, setWithDescription] = useState(true);
    const [withTags, setWithTags] = useState(true);
    const [withSources, setWithSources] = useState(true);
    const [maxTags, setMaxTags] = useState(5);
    const [slug, setSlug] = useState('Tip');

    const customMetaData = (
      <MetaData>
        <MetaData.Relation title="Idea:" label="Foundations: Modeling" link="/" />
        <MetaData.Relation title="Collection:" label="Foundations: Modeling" link="/" />
      </MetaData>
    );

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="bookmarked"
            value={bookmarked}
            onChange={setBookmarked}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="author"
            value={withAuthor}
            onChange={setWithAuthor}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="dateCreated"
            value={withCreationDate}
            onChange={setWithCreationDate}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="customMetaData"
            value={withCustomMetaData}
            onChange={setWithCustomMetaData}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="description"
            value={withDescription}
            onChange={setWithDescription}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="sources"
            value={withSources}
            onChange={setWithSources}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="tags"
            value={withTags}
            onChange={setWithTags}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="maxTags"
            value={maxTags}
            onChange={setMaxTags}
            options={[undefined, 2, 5]}
          />
          <Fieldset.Field
            legend="slug"
            value={slug}
            onChange={setSlug}
            options={[undefined, 'Tip', 'Question']}
          />
        </Fieldset>
        <ArticleGeneralInformation
          slug={slug}
          bookmarked={bookmarked}
          author={withAuthor && author}
          description={withDescription && description}
          tags={withTags && tags}
          maxTags={maxTags}
          sources={withSources && sources}
          dateCreated={withCreationDate && dateCreated}
          customMetaData={withCustomMetaData && customMetaData}
          onBookmark={setBookmarked}
        />
      </Box>
    );
  };

  ArticleGeneralInformation.Demo = Demo;
}

export default ArticleGeneralInformation;
