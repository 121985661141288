import { Box, Typography, Stack, Divider, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getArray, formatDate } from 'utils/helpers';
import { Paper, Icon, Center, Tag } from 'components';

const StudentsRoot = styled(Stack, { label: 'Workflow-StudentsRoot' })(({ theme, color }) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 4,
    top: 0,
    left: 6,
    bottom: 0,
    backgroundColor: 'currentColor',
    borderRadius: theme.shape.borderRadius,
  },
}));

const STATUS_TO_COLOR = {
  Assigned: 'secondary.light',
  Completed: 'primary.dark',
  Published: 'textRegular.dark',
  'In Progress': 'warning.dark',
};

const StatusItem = (props) => {
  const { status, color, author = {}, date_created } = props;

  return (
    <Box>
      <Center justifyContent="flex-start" color={color}>
        <Center
          mr={1}
          sx={{
            backgroundColor: 'currentColor',
            borderRadius: '50%',
            width: 16,
            height: 16,
          }}
        >
          <Icon name="CheckBold" sx={{ color: 'other.white' }} />
        </Center>
        <Typography variant="caption1">{status}</Typography>
      </Center>

      <StudentsRoot sx={{ color }} pl={3} mt={1.5} spacing={1}>
        <Stack
          direction="row"
          divider={
            <Divider
              sx={{ margin: '5px 16px', borderColor: 'other.line' }}
              orientation="vertical"
              flexItem
            />
          }
        >
          <Tag
            avatar={author.avatar}
            bgcolor="input.main"
            color="textRegular.light"
            label={`${author.first_name} ${author.last_name}`}
          />
          <Center>
            <Icon name="Calendar" />
            <Typography variant="caption1" color="textBlack.light" ml={1}>
              {formatDate(date_created, 'dd.MM.yyyy hh:mm a')}
            </Typography>
          </Center>
        </Stack>
      </StudentsRoot>
    </Box>
  );
};

const Workflow = (props) => {
  const { data, ...rest } = props;

  const { t } = useTranslation('views', { keyPrefix: 'Workflow' });

  return (
    <Box {...rest}>
      <Center mb={2} justifyContent="flex-start">
        <Icon name="Workflow" fontSize="small" />

        <Typography ml={0.5} variant="body2" component="h3" color="textBlack.dark">
          {t('workflow')}
        </Typography>
      </Center>
      <Box pl={3}>
        <Paper shadow="small" width={1} p={3}>
          <Stack spacing={1.5}>
            {getArray(data).map((item) => (
              <StatusItem
                key={`status-${item.status}-${item.date_created}`}
                status={item.status}
                author={item.author}
                date_created={item.date_created}
                color={STATUS_TO_COLOR[item.status]}
              />
            ))}
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

export default Workflow;
