import { Box, styled } from '@mui/material';

import { Center } from 'components';

const BodyOffset = styled(Box, {
  label: 'BodyOffset',
})({ flexGrow: 1 });

const BodyBox = styled(BodyOffset, {
  label: 'BodyBox',
  shouldForwardProp: (prop) => !['width'].includes(prop),
})(({ width }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  maxWidth: width,
}));

/**
 * @prop {string|number} width - Width of the container
 */
const Container = ({ children, width = 1056, ...rest }) => {
  return (
    <Center flexGrow={1}>
      <BodyOffset />
      <BodyBox width={width} flexDirection="column" {...rest}>
        {children}
      </BodyBox>
      <BodyOffset>
        <Box height={1} width={1} bgcolor="primary.main" />
      </BodyOffset>
    </Center>
  );
};

export default Container;
