import { useMemo } from 'react';
import { Divider, Typography, CardActionArea } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getArray } from 'utils/helpers';
import { Tags, Paper, AvatarGroup, Center } from 'components';

const KnowledgeCard = (props) => {
  const { t } = useTranslation('views', { keyPrefix: 'KnowledgeCard' });

  const { to, title, tags, comments, commentsQty, lastCommentTime, ...rest } = props;

  const commentAvatars = useMemo(() => {
    const avatars = [];
    getArray(comments).forEach((comment) => {
      if (avatars.findIndex((avatar) => avatar.id === comment.user_id) === -1) {
        avatars.push({ id: comment.user_id, src: comment.avatar });
      }
    });
    return avatars;
  }, [comments]);

  const withComments = commentAvatars.length > 0;

  return (
    <Paper
      shadow="standard"
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: 'controls.main',
      }}
      {...rest}
    >
      <CardActionArea
        component={Link}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: 2,
          '& .MuiCardActionArea-focusHighlight': { background: 'transparent' },
        }}
        to={to}
      >
        <Typography component="div" variant="subtitle4" mb={3}>
          {title}
        </Typography>

        <Center flexDirection="column">
          {withComments && (
            <Center mb={2}>
              <AvatarGroup max={5} size="xsmall" avatars={commentAvatars} />
              <Typography ml={1} variant="body4" color="textBlack.light">
                {t('comments_count', { count: comments.length })}
              </Typography>
              {lastCommentTime && (
                <>
                  <Divider sx={{ margin: '5px 8px' }} orientation="vertical" flexItem />
                  <Typography variant="body4" color="textBlack.contrastText">
                    {t('last_comment_date', { date_string: lastCommentTime })}
                  </Typography>
                </>
              )}
            </Center>
          )}

          <Tags title={t('tags')} tags={tags} name={title} />
        </Center>
      </CardActionArea>
    </Paper>
  );
};

export default KnowledgeCard;
