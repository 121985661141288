import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { selectors } from 'store/state/pageFilter';

import { FILTER_TYPE } from 'utils/constants';
import { useGetQuestionsQuery } from 'store/api';
import { getArray } from 'utils/helpers';
import { usePageFilter, useAuth } from 'hooks';

import { ProgressBox } from 'components';
import { KnowledgeCard } from 'views';

import mapRequest from './mapRequest';

const { selectPageFilters } = selectors;

const Questions = (props) => {
  const { me } = props;

  const filters = useSelector(selectPageFilters);

  const { auth, progress } = useAuth();
  const { onSetFilter } = usePageFilter();

  const { data, isLoading, isFetching } = useGetQuestionsQuery(mapRequest(me, filters), {
    skip: (auth && !me?.space_id) || progress,
  });

  useEffect(() => {
    if (!data?.tags) return;
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.tags, total: data.tags_qty });
  }, [data, onSetFilter]);

  const loading = progress || isLoading || isFetching;

  return (
    <ProgressBox loading={loading}>
      <Grid container spacing={3}>
        {getArray(data?.questions).map((question) => (
          <Grid key={`questions-grid-${question.id}`} item xs={6}>
            <KnowledgeCard
              to={`/knowledge-base/question/${question.id}`}
              title={question.name}
              comments={question.comments}
              commentsQty={question.comments_qty}
              lastCommentTime={question.last_comment_time}
              tags={question.tags}
            />
          </Grid>
        ))}
      </Grid>
    </ProgressBox>
  );
};

export default Questions;
