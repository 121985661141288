import { useLocation } from 'react-router-dom';

import OwnPortfolio from './OwnPortfolio';
import GroupsPortfolio from './GroupsPortfolio';

const NewPortfolio = (props) => {
  const { state } = useLocation();
  if (state?.source === 'own_projects') return <OwnPortfolio {...props} />;
  return <GroupsPortfolio {...props} />;
};

export default NewPortfolio;
