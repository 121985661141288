import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography, Collapse } from '@mui/material';

import {
  useForm,
  useMessage,
  useAuth,
  useUploadPhotos,
  useCreateTag,
  useProjectSearch,
} from 'hooks';
import { getArray, capitalize } from 'utils/helpers';
import {
  useGetMeQuery,
  useUpdateCollectionMutation,
  useUpdateCollectionProjectsMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
  useGetCollectionQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper, Tabs } from 'components';
import { PageSubheader, CollectionInformationForm, ProjectSearch } from 'views';
import { getLevelStyles } from 'views/LevelTag';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

import { mapProjectsRequest, mapInformationRequest } from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };

const mapInitialData = (collection) => {
  if (!collection) return {};
  return {
    name: collection.name,
    level: collection.level,
    description: getInitialTextEditorState(collection.description),
    tags: collection.tags.map((tag) => ({ value: tag.id, label: tag.name })),
    photos: collection.photos.map((photo) => ({ url: photo })),
    projects: collection.projects,
  };
};

const NewCollection = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'edit_collection' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const [tab, setTab] = useState('information');

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: collection,
    isLoading: collectionLoading,
    isFetching: collectionFetching,
  } = useGetCollectionQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const {
    searchValue: projectSearchValue,
    onSearch: onProjectSearch,
    projects,
    loading: projectsLoading,
  } = useProjectSearch({});

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery();

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createTag, createTagState] = useCreateTag({ me });
  const [updateCollection, updateCollectionState] = useUpdateCollectionMutation();
  const [updateCollectionProjects, updateCollectionProjectsState] =
    useUpdateCollectionProjectsMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  useEffect(() => {
    form.reset(mapInitialData(collection));
  }, [form, collection]);

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleUpdateCollection = useCallback(
    async (formData) => {
      try {
        const { photos } = formData;
        const postData = mapInformationRequest(formData);

        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;

          postData.photos = photoIds;
        }

        const { status } = await updateCollection({
          id,
          ...postData,
        }).unwrap();

        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_update'));
        }
      }
    },
    [id, updateCollection, m, t, uploadPhotos],
  );

  const handleUpdateProjects = useCallback(
    async (formData) => {
      try {
        const postData = mapProjectsRequest(formData);

        const { status } = await updateCollectionProjects({
          id,
          ...postData,
        }).unwrap();

        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        if (err?.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else {
          m.error(t('error_failed_update'));
        }
      }
    },
    [id, updateCollectionProjects, m, t],
  );

  const handleSubmit = useCallback(
    (formData) => {
      const fn = tab === 'information' ? handleUpdateCollection : handleUpdateProjects;
      fn(formData);
    },
    [tab, handleUpdateCollection, handleUpdateProjects],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const tabOptions = useMemo(
    () => [
      {
        label: t('tabs.collection_information'),
        value: 'information',
      },
      {
        label: t('tabs.projects'),
        value: 'projects',
      },
    ],
    [t],
  );

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadPhotosState.isLoading ||
    updateCollectionProjectsState.isLoading ||
    updateCollectionState.isLoading ||
    collectionLoading ||
    collectionFetching;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleSubmit}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/learning/collections"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                type: 'button',
                disabled: loading,
                variant: 'outlined',
                size: 'small',
                onClick: reset,
              },
              [t(
                tab === 'information'
                  ? 'page_actions.edit_information'
                  : 'page_actions.edit_projects',
              )]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={2} component="h3" color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>

          <Tabs
            mb={2}
            width={396}
            variant="fullWidth"
            value={tab}
            onValue={setTab}
            options={tabOptions}
          />

          <Collapse in={tab === 'information'}>
            <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
              <CollectionInformationForm
                rules={rules}
                loadingTags={loadingTags}
                tagsOptions={tagsOptions}
                levelOptions={levelOptions}
                loadingLevels={loadingLevels}
                defaultSourceValue={defaultSourceValue}
                onCreateTag={createTag}
              />
            </Paper>
          </Collapse>
          <Collapse in={tab === 'projects'}>
            <ProjectSearch.Control
              searchPlaceholder={t('search_project')}
              projects={projects}
              loading={projectsLoading}
              name="projects"
              search={projectSearchValue}
              onSearch={onProjectSearch}
            />
          </Collapse>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewCollection;
