import { Box } from '@mui/material';

import { Header } from 'views';

const MainLayout = (props) => {
  const { children, headerProps, ...rest } = props;

  return (
    <Box
      {...rest}
      width="100vw"
      height="100vh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Header {...headerProps} />

      <Box height="100%" overflow="auto">
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
