import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useForm } from 'hooks';
import { Button, Center, Form, Input } from 'components';

const keyPrefix = 'forgot_password.mail';

const RestoreWithMail = (props) => {
  const { onSubmit } = props;
  const visible = useMatch('/forgot-password/mail');
  const { t } = useTranslation('pages', { keyPrefix});
  const { form, valid, rules: { requiredIf, email }} = useForm();
  const { reset } = form;

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [reset, visible]);

  return (
    <Center flexDirection="column" width={456} pb={2}>
      <Typography variant="body3" color="textBlack.light" textAlign="center">
        {t('title')}
      </Typography>

      <Form
        mt={4}
        form={form}
        onSubmit={onSubmit}
        width="100%"
      >
        <Input.Control
          fullWidth
          initFocus={!!visible}
          name="email"
          type="email"
          placeholder={t('email.placeholder')}
          rules={{
            required: requiredIf(visible),
            validate: email,
          }}
        />

        <Center mt={14} justifyContent="space-between" gap={1}>
          <Button
            fullWidth
            variant="outlined"
            to="/forgot-password"
          >
            {t('cancel')}
          </Button>

          <Button
            fullWidth
            type="submit"
            disabled={!valid}
          >
            {t('send')}
          </Button>
        </Center>
      </Form>
    </Center>
  );
};

export default RestoreWithMail;
