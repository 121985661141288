import { useMemo, useEffect, useState } from 'react';

import { useGetChildProjectQuery, useGetStatusesQuery } from 'store/api';

import { Center, Select } from 'components';

import useUpdateProjectStatus from './hooks/useUpdateProjectStatus.hook';

const ProjectStatus = (props) => {
  const { projectId, admin } = props;

  const [projectStatus, setProjectStatus] = useState(null);

  const { updateStatus, loading: updatingStatus } = useUpdateProjectStatus();

  const { data: statuses } = useGetStatusesQuery();

  const {
    data: project,
    isLoading,
    isFetching,
  } = useGetChildProjectQuery(
    {
      id: projectId,
    },
    { skip: !Boolean(projectId) },
  );

  useEffect(() => {
    if (!project) return;
    setProjectStatus(project?.project_status);
  }, [project]);

  const statusOptions = useMemo(() => {
    if (!statuses) return [];
    return Object.values(statuses).map((status) => ({ label: status, value: status }));
  }, [statuses]);

  const handleChange = (s) => {
    if (!project) return;
    setProjectStatus(s);
    updateStatus({ ...project, status: s });
  };

  const loading = isLoading || isFetching || updatingStatus;

  return (
    <Center minWidth={144}>
      <Select
        fullWidth
        name="status"
        value={projectStatus}
        options={statusOptions}
        itemsMax={4}
        onValue={handleChange}
        disabled={loading || (!admin && projectStatus === 'Published')}
      />
    </Center>
  );
};

export default ProjectStatus;
