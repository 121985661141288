import { useCallback } from 'react';
import { Stack } from '@mui/material';

import { FILTER_TYPE } from 'utils/constants';
import { getArray } from 'utils/helpers';

import TagsFilter from './TagsFilter';
import LevelFilter from './LevelFilter';

const getComponentByType = (type) => {
  switch (type) {
    case FILTER_TYPE.TAG_FILTER:
      return TagsFilter;
    case FILTER_TYPE.LEVEL_FILTER:
      return LevelFilter;
    default:
      return null;
  }
};

const QuickFilters = (props) => {
  const { filtersConfig = {}, filters = {}, activeFilters = {}, onChange, ...rest } = props;

  const handleChange = useCallback(
    (name, value) => {
      if (typeof onChange !== 'function') return;
      onChange(name, value);
    },
    [onChange],
  );

  return (
    <Stack direction="row" spacing={1.5} {...rest}>
      {getArray(
        Object.entries(filters).map(([name, filter]) => {
          const Component = getComponentByType(filter.type);
          if (!Component) return null;

          const config = filtersConfig[name];
          return (
            <Component
              name={name}
              key={`sidebar-filter-${name}`}
              {...filter.value}
              {...config}
              value={activeFilters[name]}
              onValue={(value) => handleChange(name, value)}
            />
          );
        }),
      )}
    </Stack>
  );
};

export default QuickFilters;
