import { useTranslation } from 'react-i18next';
import { Stack, styled, alpha, darken } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { ROLE, NOTIFICATIONS_POLLING_INTERVAL } from 'utils/constants';
import { actions } from 'store';
import { useGetMeQuery, useGetNotificationsQuery } from 'store/api';
import { useAuth } from 'hooks';
import { Center, Button, Icon, IconButton, Mark, Progress } from 'components';

import ProfileButton from './ProfileButton';
import ProfileExperience from './ProfileExperience';

const { openNotificationsModal } = actions;

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !['active'].includes(prop),
})(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.dark : undefined,
  '&:hover, &:focus': {
    backgroundColor: active
      ? darken(theme.palette.primary.dark, 0.1)
      : alpha(theme.palette.primary.dark, 0.5),
  },
}));

const keyPrefix = 'header';

const HeaderProfile = () => {
  const { t } = useTranslation('views', { keyPrefix });
  const { auth, progress } = useAuth();
  const { pathname } = useLocation();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data: notifications } = useGetNotificationsQuery(
    { space_id: me?.space_id, limit: 20, offset: 0, status: true },
    { skip: !me, pollingInterval: NOTIFICATIONS_POLLING_INTERVAL },
  );

  const loading = isLoading || isFetching || progress;

  return (
    <Center flexGrow={1} justifyContent="flex-end">
      <Stack direction="row" spacing={1}>
        {loading && <Progress size={30} color="primary.light" />}

        {!loading && (
          <>
            {auth === false && (
              <>
                <Button variant="contained" to="/sign-up">
                  {t('signup')}
                </Button>

                <Button variant="outlined" to="/login">
                  {t('login')}
                </Button>
              </>
            )}

            {auth === true && (
              <Stack direction="row" spacing={2} alignItems="center">
                <StyledIconButton
                  type="button"
                  onClick={() => {
                    openNotificationsModal();
                  }}
                >
                  <Mark content={notifications?.notifications_qty}>
                    <Icon.Notification fontSize={24} color="inherit" />
                  </Mark>
                </StyledIconButton>

                {me?.role !== ROLE.PARENT && (
                  <StyledIconButton active={pathname.startsWith('/favorites')} to="/favorites">
                    <Icon.BookmarkFilled fontSize={24} />
                  </StyledIconButton>
                )}

                {me?.role === ROLE.STUDENT && (
                  <ProfileExperience
                    level={me?.achievements?.current_level}
                    points={me?.achievements?.current_points}
                    pointsToNextLevel={me?.achievements?.points_to_next_level}
                  />
                )}

                <ProfileButton me={me} />
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Center>
  );
};

export default HeaderProfile;
