import { Box, Typography } from '@mui/material';

import { ReactComponent as ExpLevel } from 'assets/icons/exp_level.svg';
import { ReactComponent as Exp } from 'assets/icons/exp.svg';

export const IconLevel = ({ level = 1 }) => (
  <Box position="relative" lineHeight={0}>
    <ExpLevel />

    <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <Typography variant="subtitle2" fontWeight={500} color="warning.dark">
        {level}
      </Typography>
    </Box>
  </Box>
);

export const IconExp = () => <Exp />;
