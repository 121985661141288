import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Collapse, Typography } from '@mui/material';

import { mail_box } from 'assets/images/holders';
import { useValidateSmsMutation } from 'store/api';
import { useForm } from 'hooks';
import { GuestLayout } from 'layouts';
import { Button, Form, FormHelperText, ImageHolder, Paper, Ref } from 'components';
import { VerificationInput } from 'views';

const keyPrefix = 'phone_verification';

const PhoneVerification = () => {
  const { t } = useTranslation('pages', { keyPrefix });
  const { form, valid } = useForm();
  const [validateSms, validateSmsState] = useValidateSmsMutation();

  const handleSubmit = useCallback(async (formData) => {
    try {
      const hash = formData.hash.join('');
      await validateSms({ hash }).unwrap();
    } catch (ex) {
      console.error(ex);
    }
    form.reset();
  }, [validateSms, form]);

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        alt={t('title')}
        src={mail_box}
        origin="container"
        shadow="smooth"
      />

      <Typography my={3} color="primary.dark" variant="subtitle2">
        {t('title')}
      </Typography>

      <Paper
        py={3}
        width={696}
        alignItems="center"
        loading={validateSmsState.isLoading}
      >

        <Collapse in={!validateSmsState.isSuccess}>
          <Form
            form={form}
            my={1}
            width={456}
            textAlign="center"
            onSubmit={handleSubmit}
          >
            <Typography variant="body3" color="textBlack.light">
              {t('enter_verification_code')}
            </Typography>

            <VerificationInput.Control
              mt={3}
              initFocus
              sections={6}
              symbols={1}
              type="number"
              name="hash"
              onChange={validateSmsState.reset}
            />

            <FormHelperText error>
              {validateSmsState.error && t('verify_error')}
            </FormHelperText>

            <Box mt={4} width={200} display="inline-block">
              <Button fullWidth disabled={!valid} type="submit">
                {t('verify')}
              </Button>
            </Box>
          </Form>
        </Collapse>

        <Collapse in={!!validateSmsState.isSuccess}>
          <Box width={456} textAlign="center" py={5}>
            <Typography variant="body3" color="textBlack.light">
              <Trans t={t} i18nKey="done">
                Done! Now you can <Ref to="/login">login</Ref>.
              </Trans>
            </Typography>
          </Box>
        </Collapse>
      </Paper>
    </GuestLayout>
  );
};

export default PhoneVerification;
