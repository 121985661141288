import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter, useUsersSearch } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, getProfileBasePath } from 'utils/helpers';

import { CardContent } from 'views';
import { ProgressBox, Pagination, PaperCard } from 'components';

const { selectPageFilters, selectPagination } = selectors;

const getGroupTags = (groups) => groups.map((group) => ({ id: group.id, name: group.group_name }));

const mapRequest = (pageFilters, pagination) => {
  const { search, activeFilters } = pageFilters;

  const { roles, groups } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    name: search || undefined,
    roles: getArray(roles).map((role) => role.id),
    groups: getArray(groups).map((group) => group.id),
    limit: perPage,
    offset: offset,
  };
};

const Users = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'administration' });
  const { auth, progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const { data, loading: usersLoading } = useUsersSearch({
    spaceId: me?.space_id,
    disabled: isLoading || isFetching,
    mapToOptions: true,
    filters: mapRequest(pageFilters, pagination),
  });

  useEffect(() => {
    if (!data) return;
    onSetFilter('roles', FILTER_TYPE.ROLES, {
      tags: getArray(data.roles).map((role) => ({
        id: role.role_title,
        name: t(`roles.${role.role_title}`),
        qty: role.roles_qty,
      })),
      total: data.roles_qty,
    });
    onSetFilter('groups', FILTER_TYPE.GROUPS, {
      groups: getArray(data.groups).map((group) => ({
        id: group.id,
        name: group.group_name,
        qty: group.group_qty,
      })),
      total: data.groups_qty,
    });
  }, [onSetFilter, data, t]);

  const loading = isLoading || isFetching || progress || usersLoading;

  return (
    <Box>
      <ProgressBox loading={loading}>
        <Grid container spacing={3}>
          {getArray(data?.users).map((user) => (
            <Grid key={`users-grid-${user.id}`} item xs={6}>
              <PaperCard to={`${getProfileBasePath(user.role)}/${user.id}`}>
                <CardContent
                  variant="user"
                  avatar={user.avatar}
                  username={user.username}
                  fullName={user.full_name}
                  role={user.role}
                  email={user.email}
                  groups={getGroupTags(user.groups)}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </ProgressBox>

      <Pagination
        total={data?.users_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </Box>
  );
};

export default Users;
