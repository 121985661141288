import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback } from 'react';

import { getArray } from 'utils/helpers';
import { Center, Input, Tag } from 'components';

const TagsCount = styled(Tag)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.input.main,
}));

const TagHolder = styled(Center)({
  justifyContent: 'flex-start',
});

const StyledTag = styled(Tag, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    border: '1px solid',
    borderColor: theme.palette.controls.main,
    backgroundColor: active ? theme.palette.controls.main : theme.palette.input.main,
  }),
);

const checkActive = (tags = [], tag) => {
  const foundIndex = tags.findIndex((item) => item.id === tag.id);
  return foundIndex !== -1;
};

const searchGroups = (groups, search) => {
  return getArray(groups).filter((group) => {
    return group.name.toLowerCase().includes(search.toLowerCase());
  });
};

const TagItem = (props) => {
  const { tag, onChange, ...rest } = props;
  return (
    <TagHolder mb={1}>
      <StyledTag
        size="medium"
        p={1}
        clickable
        label={tag.name}
        onClick={() => onChange(tag)}
        {...rest}
      />
      {tag.qty > 0 && (
        <Typography ml={1} variant="caption1" color="textBlack.light">
          ({tag.qty})
        </Typography>
      )}
    </TagHolder>
  );
};

const GroupsFilter = (props) => {
  const { value = [], total, groups, onValue, onSearch } = props;

  const { t } = useTranslation('views', { keyPrefix: 'GroupsFilter' });

  const [searchValue, setSearchValue] = useState('');

  const filteredGroups = useMemo(() => {
    if (!searchValue) return groups;
    return searchGroups(groups, searchValue);
  }, [groups, searchValue]);

  const handleSearch = useCallback(
    (search) => {
      setSearchValue(search);
      typeof onSearch === 'function' && onSearch(search);
    },
    [onSearch, setSearchValue],
  );

  const handleChange = useCallback(
    (tag) => {
      if (typeof onValue !== 'function') return;

      const foundIndex = value.findIndex((item) => item.id === tag.id);
      if (foundIndex === -1) {
        onValue([...value, tag]);
      } else {
        const changed = [...value];
        changed.splice(foundIndex, 1);
        onValue(changed);
      }

      handleSearch('');
    },
    [value, onValue, handleSearch],
  );

  return (
    <Box display="flex" flexDirection="column">
      <Center mb={1} justifyContent="space-between">
        <Typography color="textBlack.light">{t('title')}</Typography>
        {total > 0 && <TagsCount size="small" label={total} />}
      </Center>

      <Center>
        <Input
          onValue={handleSearch}
          value={searchValue}
          iconLeft="Search"
          fullWidth
          placeholder={t('search')}
        />
      </Center>

      {filteredGroups && (
        <Stack mt={1} sx={{ maxHeight: '432px', overflow: 'auto' }}>
          {filteredGroups.map((group, index) => (
            <TagItem
              key={`group-filter-${group.id}-${index}`}
              tag={group}
              active={checkActive(value, group)}
              onChange={handleChange}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default GroupsFilter;
