import { useState } from 'react';
import { Box, Typography, Collapse } from '@mui/material';

import * as AllComponents from 'components';

const Components = () => {
  const [state, setState] = useState([]);

  return (
    <Box p={4} bgcolor="white">
      <Box mb={3}>
        Blue components has <b>Control</b> sub-component, and could be used within {`<Form />`} component context as a form fields (take a look at README.md).
      </Box>
      {Object.entries(AllComponents).map(([name, Component]) => (
        <Box key={name}>
          <Box display="flex" alignItems="center">
            <Box
              mr={2}
              width="40px"
              height="40px"
              fontSize="30px"
              component="button"
              disabled={!Component.Demo}
              onClick={() => {
                setState((prev) => {
                  if (!Component.Demo) {
                    return prev;
                  }
                  if (prev.includes(name)) {
                    return prev.filter((v) => v !== name);
                  }
                  return [...prev, name];
                });
              }}
            >
              {`${state.includes(name) ? '-' : '+'}`}
            </Box>

            <Typography
              variant="subtitle2"
              color={!Component.Control ? 'black' : 'blue'}
              sx={{
                opacity: !Component.Demo ? 0.25 : 1,
              }}
            >
              {`<${name} />`}
            </Typography>

            {!Component.Demo && (
              <Box ml={2}>No demo</Box>
            )}
          </Box>

          {!!Component.Demo && (
            <Collapse in={state.includes(name)}>
              <code>import {`{ ${name} }`} from 'components';</code><br />

              <Box mt={2}>
                <Component.Demo open={state.includes(name)} />
              </Box>
            </Collapse>
          )}

          <hr size="1" />
        </Box>
      ))}
    </Box>
  );
};

export default Components;
