import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import { PERMISSIONS, ROLE } from 'utils/constants';
import { getArray } from 'utils/helpers';
import { useAcl } from 'hooks';

import { PaperCard, Button, SwiperNavigation, Center } from 'components';
import { CardContent } from 'views';

const SliderWrapper = styled(Box)(({ theme }) => ({
  ' & .swiper': {
    padding: '0 12px',
    margin: '0 -24px',
  },
  ' & .swiper-wrapper': {
    paddingTop: '12px',
    paddingBottom: '24px',
  },
  ' & .swiper-pagination': {
    bottom: 'initial',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 4,
    paddingRight: 20,
  },
  ' & .swiper-pagination-bullet': {
    width: 24,
    height: 4,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.other.paginationBullet,
  },
  ' & .swiper-pagination-bullet-active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SliderItemHolder = styled(Box, {
  shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})(({ theme, isVisible }) => ({
  transition: theme.transitions.create(),
  width: '100%',
  opacity: isVisible ? 1 : 0,
  boxSizing: 'border-box',
  '& > .MuiBox-root': { width: '100%', boxSizing: 'border-box' },
}));

const StudentWork = (props) => {
  const {
    navigation,
    studentWorks,
    onRequestAddStudentWork,
    onRequestAddVideo,
    onRequestEditStudentWork,
    ...rest
  } = props;

  const acl = useAcl();

  const { t } = useTranslation('views', { keyPrefix: 'StudentWork' });

  return (
    <Box {...rest}>
      <SliderWrapper>
        <Swiper
          watchSlidesProgress
          spaceBetween={0}
          slidesPerView={3}
          pagination={{ clickable: true }}
          modules={[Pagination]}
        >
          {getArray(studentWorks).map((work, index) => (
            <SwiperSlide key={`student-work-${work.name}`}>
              {({ isVisible }) => (
                <SliderItemHolder isVisible={isVisible} px={1.5}>
                  <PaperCard
                    p={0}
                    actionAreaStyle={{ p: 0 }}
                    onClick={(e) => {
                      e.preventDefault();
                      typeof onRequestEditStudentWork === 'function' &&
                        onRequestEditStudentWork(work, index);
                    }}
                  >
                    <CardContent
                      variant="student_work"
                      photo={work.link}
                      title={work.name}
                      description={work.description}
                    />
                  </PaperCard>
                </SliderItemHolder>
              )}
            </SwiperSlide>
          ))}

          {navigation && studentWorks?.length > 0 && <SwiperNavigation mt={0} mb={2} />}
        </Swiper>
      </SliderWrapper>

      <Center textAlign="right" gap={2} justifyContent="flex-end">
        <Button
          iconLeft={'Image'}
          variant="outlined"
          onClick={onRequestAddStudentWork}
          sx={{ paddingRight: 0 }}
        >
          {t('add_photos')}
        </Button>
        {acl.allow({
          roles: [ROLE.ADMIN, ROLE.TEACHER],
          permissions: [PERMISSIONS.EDIT_PROJECT_STATUS],
        }) &&
          onRequestAddVideo && (
            <Button
              iconLeft={'Video'}
              variant="outlined"
              onClick={onRequestAddVideo}
              sx={{ paddingRight: 0 }}
            >
              {t('add_video')}
            </Button>
          )}
      </Center>
    </Box>
  );
};

export default StudentWork;
