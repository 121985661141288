import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, Collapse, styled } from '@mui/material';

import { capitalize, getArray } from 'utils/helpers';
import { Paper, Center, ImageHolder, Select, RippleBox, Icon, PaperCard, Button } from 'components';
import { LevelTag } from 'views';

const StatisticsItemRoot = styled(Center)(({ theme }) => ({
  boxShadow: theme.shadows.small,
  minHeight: '40px',
  borderRadius: theme.shape.borderRadius * 2,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  '& .MuiTypography-subtitle3': {
    lineHeight: '40px',
  },
}));

const ToggleBox = styled(RippleBox)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StatisticsItem = (props) => {
  const { count, label, ...rest } = props;
  return (
    <StatisticsItemRoot justifyContent="flex-start" {...rest}>
      <Typography variant="subtitle3">{count || 0}</Typography>
      <Typography variant="body4" ml={1}>
        {label}
      </Typography>
    </StatisticsItemRoot>
  );
};

export const ItemCard = (props) => {
  const { data } = props;
  return (
    <PaperCard to={data.to} border size="small">
      <Center width="100%">
        <ImageHolder mr={2} flexShrink={0} size={64} src={data.photo} />
        <Box flexGrow={1}>
          <Center justifyContent="space-between">
            <LevelTag level={data.level} label={capitalize(data.level)} size="small" mb={2} />
          </Center>
          <Typography color="textBlack.dark" component="div" variant="body2">
            {data.name}
          </Typography>
        </Box>
      </Center>
    </PaperCard>
  );
};

const ProgressStatistics = (props) => {
  const {
    title,
    statistics,
    statuses,
    status,
    onStatusChange,
    data,
    total = 0,
    perPage = 1,
    page = 1,
    loading,
    onLoadMore,
    CustomItemComponent,
    ...rest
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'ProgressStatistics' });

  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  const ItemComponent = CustomItemComponent || ItemCard;

  return (
    <Paper width={1} shadow="small" border overflow="visible" loading={loading} {...rest}>
      <ToggleBox ripple width={1} px={1} borderRadius={2} mx={-1} onClick={toggle}>
        <Typography color="textBlack.dark" variant="subtitle5">
          {title}
        </Typography>
        <Icon name={open ? 'ArrowUp' : 'ArrowDown'} />
      </ToggleBox>

      {statuses?.length > 0 && (
        <Box width={256} mt={2}>
          <Select value={status} fullWidth options={statuses} onValue={onStatusChange} />
        </Box>
      )}

      <Grid container mt={1.5} columnSpacing={3}>
        {getArray(statistics).map(({ label, count, ...rest }) => (
          <Grid item xs={4} key={`statistic-item-${label}`}>
            <StatisticsItem count={count} label={label} {...rest} />
          </Grid>
        ))}
      </Grid>
      <Box width={1}>
        <Collapse in={open}>
          <Grid container mt={0} columnSpacing={3} rowSpacing={2}>
            {getArray(data).map((item) => (
              <Grid item xs={4} key={item.id}>
                <ItemComponent data={item} />
              </Grid>
            ))}
          </Grid>
          {page * perPage < total && (
            <Box textAlign="center" mt={2}>
              <Button type="button" size="small" variant="outlined" onClick={onLoadMore}>
                {t('show_more')}
              </Button>
            </Box>
          )}
        </Collapse>
      </Box>
    </Paper>
  );
};

export default ProgressStatistics;
