import { createTheme, darken, alpha } from '@mui/material/styles';

import { dev } from 'utils/config';

import * as colors from './colors';
import * as typo from './typography';
import * as shadows from './shadows';

// Material UI dependency
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import '@fontsource/hind-vadodara/300.css';
import '@fontsource/hind-vadodara/400.css';
import '@fontsource/hind-vadodara/500.css';
import '@fontsource/hind-vadodara/700.css';

const scrollWidth = 8;

const defaultTheme = createTheme();
const customShadows = defaultTheme.shadows;
customShadows.standard = shadows.shadow_standard;
customShadows.smooth = shadows.shadow_smooth;
customShadows.dark = shadows.shadow_dark;
customShadows.small = shadows.shadow_small;
customShadows.big = shadows.shadow_big;
customShadows.control = shadows.shadow_control;

export const palette = {
  divider: colors.other_divider,
  primary: {
    light: colors.primary_light,
    main: colors.primary_default,
    dark: colors.primary_dark,
    contrastText: colors.primary_background,
  },
  secondary: {
    light: colors.secondary_light,
    main: colors.secondary_default,
    dark: colors.secondary_dark,
    contrastText: colors.secondary_background,
  },
  success: {
    light: colors.success_light,
    main: colors.success_default,
    dark: colors.success_dark,
    contrastText: colors.success_background,
  },
  error: {
    light: colors.error_light,
    main: colors.error_default,
    dark: colors.error_dark,
    contrastText: colors.error_background,
  },
  warning: {
    light: colors.warning_light,
    main: colors.warning_default,
    dark: colors.warning_dark,
    contrastText: colors.warning_background,
  },
  text: {
    primary: colors.text_black_default,
    secondary: colors.text_default,
    disabled: colors.text_disabled,
  },
  progress: {
    primary: colors.progress_main,
    contrastText: colors.progress_light,
  },

  // Custom:
  textRegular: {
    light: colors.text_light,
    main: colors.text_default,
    dark: colors.text_dark,
    contrastText: colors.text_background,
  },
  textBlack: {
    light: colors.text_black_light,
    main: colors.text_black_default,
    dark: colors.text_black_dark,
    contrastText: colors.text_black_background,
  },
  tag: {
    light: colors.text_tag_light,
    main: colors.text_tag_default,
    disabled: colors.text_tag_disabled,
  },
  disabled: {
    light: colors.disabled_light,
    main: colors.disabled_default,
  },
  other: {
    white: colors.other_white,
    black: colors.other_black,
    tooltip: colors.other_tooltip,
    line: colors.other_line,
    divider: colors.other_divider,
    hover: colors.other_hover,
    background: colors.other_background,
    paginationBullet: colors.other_pagination_bullet,
  },
  chip: {
    main: colors.other_chip_default,
    primary: colors.other_chip_primary,
    purple: colors.other_chip_purple,
  },
  controls: {
    hover: colors.other_controls_hover,
    main: colors.other_controls_default,
    disabled: colors.other_controls_disabled,
  },
  input: {
    hover: colors.other_input_hover,
    main: colors.other_input_default,
    disabled: colors.other_input_disabled,
    placeholder: colors.other_input_placeholder,
  },
};

const theme = createTheme({
  palette,
  typography: {
    ...typo.paragraph,
    fontSize: 14,
    htmlFontSize: 16,
    paragraph: typo.paragraph,
    paragraph2: typo.paragraph_sm,

    subtitle1: typo.display_xl,
    subtitle2: typo.display_l,
    subtitle3: typo.display_m,
    subtitle4: typo.display_s,
    subtitle5: typo.display_sm,

    body2: typo.body_m,
    body3: typo.body_s,
    body4: typo.body_xs,

    caption1: typo.caption1,
    caption2: typo.caption2,

    overline1: typo.overline1,

    button1: typo.button_l,
    button2: typo.button_m,
    button3: typo.button_s,
    button4: typo.button_tab,

    chip1: typo.chip_m,
    chip2: typo.chip_s,
    badge: typo.badge,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *:not(body)::-webkit-scrollbar {
          width: ${scrollWidth}px;
          max-height: ${scrollWidth}px;
          border-radius: ${scrollWidth}px;
          background-color: ${colors.primary_background};
        }
        *:not(body)::-webkit-scrollbar:hover {
          background-color: ${darken(colors.primary_background, 0.1)};
        }
        *:not(body)::-webkit-scrollbar-track {
          border-radius: ${scrollWidth}px;
          background-color: ${colors.primary_light};
        }
        *:not(body)::-webkit-scrollbar-thumb {
          border-radius: ${scrollWidth}px;
          background-color: ${colors.primary_default};
        }
        *:not(body)::-webkit-scrollbar-thumb:hover {
          background-color: ${darken(colors.primary_default, 0.1)};
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 600000s 0s, color 600000s 0s;
          -webkit-box-shadow: 0 0 0px 1000px ${alpha(colors.primary_light, 0.7)} inset;
        }
        input[data-autocompleted] {
          background-color: transparent !important;
          -webkit-box-shadow: 0 0 0px 1000px ${alpha(colors.primary_light, 0.7)} inset;
        }
        input:-internal-autofill-selected {
          background-color: transparent !important;
          -webkit-box-shadow: 0 0 0px 1000px ${alpha(colors.primary_light, 0.7)} inset;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-box-shadow: 0 0 0px 1000px ${alpha(colors.primary_light, 0.7)} inset;
        }
      `,
    },
  },
  shadows: customShadows,
});

if (dev) {
  window.mknb = { ...window.mknb, theme };
}

export default theme;
