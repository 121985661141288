import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, styled } from '@mui/material';

import { allowedImageTypes } from 'utils/constants';
import { useForm } from 'hooks';

import {
  Modal,
  Center,
  Button,
  FormRow,
  Input,
  TextEditor,
  Dropzone,
  Form,
  ImageHolder,
  IconButton,
} from 'components';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

const Actions = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(7),
}));

/**
 * @prop {boolean} [editable]
 */
const StudentWorkModal = (props) => {
  const { loading, initialData, open, onClose, onDelete, onSubmit } = props;

  const [state, setState] = useState('create');

  const { t } = useTranslation('views', { keyPrefix: 'StudentWorkModal' });
  const {
    form,
    rules: { required },
    valid,
  } = useForm();

  const handleClose = useCallback(() => {
    form.reset();
    typeof onClose === 'function' && onClose();
  }, [onClose, form]);

  useEffect(() => {
    if (!open) {
      setState('create');
      form.reset({});
    } else if (initialData?.name) {
      setState('view');
      form.reset({
        ...initialData,
        photo: [initialData.link],
        description: getInitialTextEditorState(initialData.description),
      });
    }
  }, [open, form, initialData]);

  const handleSubmit = useCallback(
    (formData) => {
      typeof onSubmit === 'function' && onSubmit(formData, state);
    },
    [onSubmit, state],
  );

  const hideLabels = state === 'view';

  return (
    <Modal loading={loading} size={516} open={open} onClose={handleClose} closeButtonSize="small">
      <Modal.Body>
        <Form stopPropagation form={form} pt={4} pb={2} onSubmit={handleSubmit}>
          {state === 'view' && (
            <Actions>
              <IconButton
                type="button"
                radius={2}
                border
                name="Edit"
                size="small"
                variant="outlined"
                onClick={() => setState('edit')}
                disabled={loading}
              />
              <IconButton
                type="button"
                radius={2}
                border
                name="Delete"
                size="small"
                variant="outlined"
                color="error"
                onClick={onDelete}
                disabled={loading}
              />
            </Actions>
          )}
          <Box textAlign="center" mb={2}>
            <Typography variant="subtitle3" color="success.dark">
              {t('add_photos')}
            </Typography>
          </Box>

          {state === 'view' ? (
            <Box textAlign="center" mb={3} width={1}>
              <ImageHolder
                src={initialData?.link}
                origin="container"
                alt="photo"
                width="100%"
                height={128}
              />
            </Box>
          ) : (
            <Dropzone.Control
              accept={allowedImageTypes}
              minHeight={128}
              name="photo"
              rules={{ required }}
              fullWidth
              mb={3}
            />
          )}

          <FormRow hideLabel={hideLabels} labelWidth={80} label={t('name.label')} mb={2}>
            {state === 'view' ? (
              <Typography variant="subtitle5" color="textBlack.dark">
                {initialData?.name}
              </Typography>
            ) : (
              <Input.Control
                fullWidth
                rules={{ required, minLength: 6 }}
                name="name"
                placeholder={t('name.placeholder')}
              />
            )}
          </FormRow>
          <FormRow
            hideLabel={hideLabels}
            labelWidth={80}
            label={!hideLabels && t('description.label')}
            alignItems="flex-start"
          >
            <TextEditor.Control
              fullWidth
              rules={{ required, minLength: 6 }}
              name="description"
              placeholder={t('description.placeholder')}
              disabled={state === 'view'}
            />
          </FormRow>

          <Center mt={3} gap={1.5}>
            <Button
              type="submit"
              radius={2}
              size="small"
              disabled={!valid || loading || state === 'view'}
            >
              {t('save')}
            </Button>
            <Button
              type="button"
              radius={2}
              variant="outlined"
              size="small"
              onClick={() => {
                form.reset();
                if (state === 'edit') setState('view');
              }}
            >
              {t('cancel')}
            </Button>
          </Center>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StudentWorkModal;
