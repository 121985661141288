import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getArray, editorStateToRawHtml } from 'utils/helpers';
import { useAuth, useForm, useMessage } from 'hooks';
import { useGetMeQuery, useGetGroupQuery, useEditGroupMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { PageSubheader, GroupForm } from 'views';
import { Paper, Form, Container } from 'components';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

const contentWidth = 936;

const mapInitialData = (group) => {
  if (!group) {
    return {};
  }

  const mappedSchedule = [];

  Object.entries(getArray(group.schedule)[0]).forEach(([day, time]) => {
    if (!time) return;
    mappedSchedule.push({ day, time: time.replace(/^(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2') });
  });

  return {
    name: group.name,
    address: group.address,
    description: getInitialTextEditorState(group.description),
    leader_id: [
      { value: group?.leader?.id, label: group?.leader?.username, avatar: group?.leader?.avatar },
    ],
    participants: getArray(group.participants).map((p) => ({
      value: p.id,
      label: p.username,
      avatar: p.avatar,
    })),
    day_and_time: mappedSchedule,
  };
};

const EditGroup = () => {
  const { id } = useParams();

  const { t } = useTranslation('pages', { keyPrefix: 'edit_group' });
  const { auth } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const [editGroup, editGroupState] = useEditGroupMutation();

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: group,
    isLoading: groupLoading,
    isFetching: groupFetching,
  } = useGetGroupQuery(
    { id },
    {
      skip: !auth,
    },
  );

  useEffect(() => {
    form.reset(mapInitialData(group));
  }, [form, group]);

  const handleSubmit = async (formData) => {
    try {
      const postData = {
        groupId: id,
        name: formData.name,
        description: editorStateToRawHtml(formData.description) || undefined,
        address: formData.address || '',
        participants: getArray(formData.participants).map((p) => p?.value),
        leader_id: getArray(formData.leader_id)[0]?.value,
        time: {},
      };

      getArray(formData.day_and_time).forEach((d) => {
        if (!d.time) return;
        postData.time[d.day] = d.time;
      });

      const { status } = await editGroup(postData).unwrap();

      if (status === 'OK') m.success(t('success_update'));
    } catch (e) {
      console.error(e);
      m.error(t('error.failed_edit_group'));
    }
  };

  const loading =
    isLoading || isFetching || editGroupState.isLoading || groupLoading || groupFetching;

  return (
    <Form id={`new-group-form`} width={1} form={form} onSubmit={handleSubmit}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backTitle={t('back_button')}
            backUrl={`/administration/groups/${id}`}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
                radius: 2,
              },
              [t('page_actions.edit')]: {
                type: 'submit',
                size: 'small',
                radius: 2,
                disabled: !valid || loading || !form.formState.isDirty,
              },
            }}
          />
        }
      >
        <Container width={contentWidth}>
          <Box py={4}>
            <Typography width={1} component="h3" color="secondary.main" variant="subtitle3">
              {t('page_title')}
            </Typography>

            <Box pt={3}>
              <Paper width={1} overflow="visible" pt={3} pb={5} px={9} loading={loading}>
                <GroupForm spaceId={me?.space_id} form={form} rules={rules} />
              </Paper>
            </Box>
          </Box>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default EditGroup;
