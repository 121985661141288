import { forwardRef, useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { Box, FormControlLabel, styled, Switch as MuiSwitch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import { useCombineRefs } from 'hooks';

import { FormLabel } from 'components';
import Fieldset from 'components/Dev/Fieldset';

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 20,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(24px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: theme.palette.other.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.other.white,
      opacity: 1,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.controls.disabled,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: theme.shadows.control,
    width: 20,
    height: 20,
    borderRadius: 10,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    opacity: 1,
    backgroundColor: theme.palette.controls.main,
    boxSizing: 'border-box',
  },
}));

const Switch = forwardRef((props, ref) => {
  const {
    label: initLabel,
    fullWidth,
    initFocus,
    disabled,
    inputRef,
    onChange,
    onValue,
    ...rest
  } = props;

  const innerInputRef = useRef(null);
  const handleInputRef = useCombineRefs(inputRef, innerInputRef);

  const handleChange = useCallback(
    (e) => {
      typeof onChange === 'function' && onChange(e);
      typeof onValue === 'function' && onValue(e.target.checked);
    },
    [onChange, onValue],
  );

  const label = useMemo(() => {
    if (!initLabel) {
      return null;
    }
    return (
      <FormLabel ml={0.5} disabled={disabled}>
        {initLabel}
      </FormLabel>
    );
  }, [initLabel, disabled]);

  useEffect(() => {
    const { current: input } = innerInputRef;

    if (input && initFocus) {
      input.focus();
    }
  }, [initFocus]);

  return (
    <FormControlLabel
      sx={{ width: fullWidth ? '100%' : undefined, marginLeft: 0 }}
      ref={ref}
      label={label}
      disabled={disabled}
      control={
        <StyledSwitch
          {...rest}
          disabled={disabled}
          onChange={handleChange}
          inputRef={handleInputRef}
        />
      }
    />
  );
});

Switch.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, inputRef, onChange, onBlur, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue || false}
      render={({ field }) => {
        const {
          value,
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <Switch
            ref={ref}
            inputRef={propagateRefs(inputRef, controlRef)}
            onChange={handleChange}
            onBlur={handleBlur}
            checked={value}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [label, setLabel] = useState('Agree with terms of use');
    const [disabled, setDisabled] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Agree with terms of use']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre" p={2}>
          {`<Switch\n`}
          {`  label={${label}}\n`}
          {`  disabled={${disabled}}\n`}
          {`/>\n`}
        </Box>

        <Switch label={label} disabled={disabled} />
      </Box>
    );
  };

  Switch.Demo = Demo;
}

export default Switch;
