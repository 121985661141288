import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { allowedImageTypes } from 'utils/constants';
import { withProps, useRules } from 'hooks';

import { Form, Input, FormRow, Dropzone, TextEditor, FormTitle, Switch } from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const PortfolioPersonalInformation = (props) => {
  const { id, onSubmit, visible, form } = props;

  const { required } = useRules();

  const { t } = useTranslation('views', { keyPrefix: 'PortfolioPersonalInformation' });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {t('personal_information')}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        <FormRow label={t('photo.label')} alignItems="flex-start">
          <Dropzone.Control
            rules={{ required }}
            fullWidth
            accept={allowedImageTypes}
            name="photo"
          />
        </FormRow>

        <FormRow label={t('first_name.label')}>
          <Input.Control
            fullWidth
            name="first_name"
            initFocus={visible}
            placeholder={t('first_name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow label={t('last_name.label')}>
          <Input.Control
            fullWidth
            name="last_name"
            placeholder={t('last_name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow optional label={t('about_me.label')} alignItems="flex-start">
          <TextEditor.Control fullWidth name="about_me" placeholder={t('about_me.placeholder')} />
        </FormRow>

        <FormTitle width="100%">
          <Typography color="secondary.main" variant="body2">
            {t('portfolio_details')}
          </Typography>
        </FormTitle>

        <FormRow label={t('name.label')}>
          <Input.Control
            fullWidth
            name="name"
            initFocus={visible}
            placeholder={t('name.placeholder')}
            rules={{ required }}
          />
        </FormRow>

        <FormRow label={t('public.label')}>
          <Switch.Control fullWidth name="public" />
        </FormRow>
      </Stack>
    </Form>
  );
};

export default PortfolioPersonalInformation;
