import { forwardRef, useMemo, Fragment, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import { usa, spain } from 'assets/images/flags';
import Select from 'components/Select';
import Avatar from 'components/Avatar';

const optionsList = [
  {
    value: 'en',
    src: usa,
  },
  {
    value: 'sp',
    src: spain,
  },
];

const keyPrefix = 'LanguageSelect';

/**
 * List of pre-defined project languages
 * @prop {function} [onChange] - change handler, returns language value itself
 * @prop {string} [value='us'] - initial value (component is not controlled)
 */
const LanguageSelect = forwardRef((props, ref) => {
  const { onChange, value = optionsList[0].value, ...rest } = props;

  const { t } = useTranslation('views', { keyPrefix });

  const options = useMemo(() => {
    return optionsList.map(({ value, src }) => ({
      value,
      label: (
        <Fragment key={value}>
          <Avatar circle src={src} size="xsmall" />
          <Box component="span" ml={1}>
            {t(`options.${value}`)}
          </Box>
        </Fragment>
      ),
    }));
  }, [t]);

  const handleChange = useCallback(
    (v) => {
      typeof onChange === 'function' && onChange(v);
    },
    [onChange],
  );

  return <Select ref={ref} value={value} onValue={handleChange} options={options} {...rest} />;
});

LanguageSelect.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue = optionsList[0].value, onChange, ...rest } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field /* , fieldState */ }) => {
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };

        return (
          <LanguageSelect
            ref={propagateRefs(controlRef, ref)}
            onChange={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box p={2}>
        <Box width="300px">
          <LanguageSelect />
        </Box>
      </Box>
    );
  };
  LanguageSelect.Demo = Demo;
}

export default LanguageSelect;
