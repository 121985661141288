import { useCallback, useMemo, useState } from 'react';
import { Box, Stack, styled } from '@mui/material'

import { makeOptions } from 'utils/helpers';
import { ProjectLayout } from 'layouts';

import Images from './Images';
import Theme from './Theme';
import Components from './Components';
import Views from './Views';

const MenuItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  color: active ? theme.palette.primary.contrastText : undefined,
  backgroundColor: active ? theme.palette.primary.main : undefined,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    backgroundColor: active ? undefined : theme.palette.primary.light,
  },
}));

const options = makeOptions(['components', 'views', 'theme', 'images']);

const Dev = () => {
  const [volume, setVolume] = useState('components');

  const handleMenuClick = useCallback((v) => () => {
    setVolume(v);
  }, []);

  const content = useMemo(() => {
    switch (volume) {
      case 'components': return <Components />;
      case 'views': return <Views />;
      case 'theme': return <Theme />;
      case 'images': return <Images />;
      default: return null;
    }
  }, [volume]);

  return (
    <ProjectLayout
      sidebar={
        <Stack spacing={0} mt={2}>
          {options.map((opt) => (
            <MenuItem
              key={opt.value}
              active={volume === opt.value}
              onClick={handleMenuClick(opt.value)}
            >
              {opt.label}
            </MenuItem>
          ))}
        </Stack>
      }
    >
      {content}
    </ProjectLayout>
  );
};

export default Dev;
