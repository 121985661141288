import { Typography } from '@mui/material';

import { Center, Paper, Icon } from 'components';

const AverageTimeSpentCard = ({ title, value, ...rest }) => {
  return (
    <Paper shadow="small" width={1} {...rest}>
      <Center mb={2} color="textBlack.light">
        <Icon fontSize={24} name="Collection" />
        <Typography variant="body2" ml={0.5}>
          {title}
        </Typography>
      </Center>
      <Typography variant="subtitle5" color="textBlack.main">
        {value}
      </Typography>
    </Paper>
  );
};

export default AverageTimeSpentCard;
