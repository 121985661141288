import { forwardRef, useCallback, useMemo, useState } from 'react';
import { Box, Typography, styled, Stack } from '@mui/material';

import { dev } from 'utils/config';
import { makeScroll } from 'styles/helpers';
import { getArray, getFunc, makeOptions } from 'utils/helpers';
import Tag, { tagSizes } from 'components/Tag';
import Button from 'components/Button';
import Fieldset from 'components/Dev/Fieldset';
import avatar from 'assets/images/examples/avatar.png';

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.input.placeholder,
}));

/**
 * @prop {string} [label] - Menu label
 * @prop {number} [maxHeight] - Max height of whole container.
 * @prop {number} [itemsMax] - Max number of visible option items (scroll will be added).
 * @prop {boolean} [itemsFullWidth=true] - Should option items take all the width or not.
 * @prop {string['small'|'medium']|number} - Option items size.
 * @prop {object[]} [options] - Array of option items. "value" and "label" props are required.
 * Label could be a React Element. All the other properties will be passed to <Tag /> component
 * (e.g. avatar).
 * @prop {function} [onSelect] - Callback function for option items click (will pass the item
 * itself as an argument)
 * @prop {ReactNode} [children] - Content of Menu (will be rendered only if no options passed).
 * @prop {ReactNode} [footer] - Footer content
 */
const Menu = forwardRef((props, ref) => {
  const {
    label,
    footer,
    options,
    children,
    maxHeight,
    onSelect,
    itemsMax,
    itemsFullWidth = true,
    itemsSize = tagSizes.small,
    getExtraTagProps,
    ...rest
  } = props;

  const opts = useMemo(() => getArray(options), [options]);
  const optionsNumber = useMemo(() => opts.length, [opts]);

  const itemSize = useMemo(() => {
    if (typeof itemsSize === 'string' && Number.isFinite(tagSizes[itemsSize])) {
      return tagSizes[itemsSize];
    }
    if (Number.isFinite(itemsSize) && itemsSize > 0) {
      return itemsSize;
    }
    return tagSizes.small;
  }, [itemsSize]);

  const maxScrollHeight = useMemo(() => {
    if (Number.isFinite(itemsMax) && itemsMax > 0 && optionsNumber > itemsMax) {
      return (itemSize * itemsMax) + (itemsMax * 4);
    }
    return undefined;
  }, [itemsMax, itemSize, optionsNumber]);

  const handleSelect = useCallback((item) => (e) => {
    getFunc(onSelect)(item);
    getFunc(item?.onClick)(e);
  }, [onSelect]);

  return (
    <Box
      ref={ref}
      display="inline-flex"
      flexDirection="column"
      maxHeight={maxHeight}
      {...rest}
    >
      {label && (
        <Label mb={1} variant="caption2">
          {label}
        </Label>
      )}

      {/* render options if exists */}
      {optionsNumber > 0 && (
        <Box
          pr={1}
          overflow="auto"
          maxHeight={maxScrollHeight}
          mr={-0.5}
          sx={{ ...makeScroll(4) }}
        >
          <Stack spacing={0.5} alignItems="flex-start">
            {opts.map((option, index) => {
              const { value, ...tagProps } = option;
              const extraTagProps = typeof getExtraTagProps === "function" && getExtraTagProps({ option, index });
              return (
                <Tag
                  {...tagProps}
                  clickable
                  key={value}
                  size={itemSize}
                  fullWidth={!!itemsFullWidth}
                  onClick={handleSelect({ value, ...tagProps })}
                  {...extraTagProps}
                />
              )
            })}
          </Stack>
        </Box>
      )}

      {/* children */}
      {children && !optionsNumber && (
        <Box
          pr={1}
          overflow="auto"
          sx={{ ...makeScroll }}
        >
          {children}
        </Box>
      )}

      {/* footer */}
      {footer && (
        <Box mt={0.5}>
          {footer}
        </Box>
      )}
    </Box>
  );
});

if (dev) {
  const Demo = () => {
    const [minWidth, setMinWidth] = useState(200);
    const [label, setLabel] = useState('Select an option');
    const [children, setChildren] = useState();
    const [avatarSrc, setAvatarSrc] = useState();
    const [options, setOptions] = useState('[...]');
    const [footer, setFooter] = useState('<...>');
    const [itemsMax, setItemsMax] = useState(5);
    const [itemsFullWidth, setItemsFullWidth] = useState();
    const [itemsSize, setItemsSize] = useState();
    const [maxHeight, setMaxHeight] = useState();

    const opts = useMemo(() => {
      let result = null;

      if (options) {
        result = makeOptions(['megga_foggy', 'jone_doe', 'noiro_bogdan', 'bruce_waine', 'vitaliy_kim', 'arestovich', 'anonimouse'])

        if (avatarSrc) {
          result = result.map((o) => ({ ...o, avatar }));
        }
      }
      return result;
    }, [options, avatarSrc]);

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="minWidth"
            value={minWidth}
            onChange={setMinWidth}
            options={[undefined, 400, 300, 200, 100, 50]}
          />
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Select an option']}
          />
          <Fieldset.Field
            legend="options"
            value={options}
            onChange={setOptions}
            options={[undefined, '[...]']}
          />
          <Fieldset.Field
            legend="options[].avatar"
            value={avatarSrc}
            onChange={setAvatarSrc}
            options={[undefined, './avatar.png']}
          />
          <Fieldset.Field
            legend="itemsMax"
            value={itemsMax}
            onChange={setItemsMax}
            options={[undefined, 3, 5, 10, 20]}
          />
          <Fieldset.Field
            legend="itemsFullWidth"
            value={itemsFullWidth}
            onChange={setItemsFullWidth}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="itemsSize"
            value={itemsSize}
            onChange={setItemsSize}
            options={[undefined, 'small', 'medium', 40]}
          />
          <Fieldset.Field
            legend="maxHeight"
            value={maxHeight}
            onChange={setMaxHeight}
            options={[undefined, 100, 200, 300, 500]}
          />
          <Fieldset.Field
            legend="footer"
            value={footer}
            onChange={setFooter}
            options={[undefined, '<...>']}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, '<...>']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Menu\n`}
          {`  label="${label}"\n`}
          {`  minWidth={${minWidth}}\n`}
          {`  itemsMax={${itemsMax}}\n`}
          {`  itemsFullWidth={${itemsFullWidth}}\n`}
          {`  itemsSize={${itemsSize}}\n`}
          {`  maxHeight={${maxHeight}}\n`}
          {footer && (
          <>
          {`  footer={\n`}
          {`    <>\n`}
          {`      <Button size="small" fullWidth>Submit</Button>\n`}
          {`      <Button size="small" fullWidth variant="text">Cancel</Button>\n`}
          {`    </>\n`}
          {`  }\n`}
          </>
          )}
          {options && (
          <>
          {`  options={[\n`}
          {`    {\n`}
          {`      label: 'Megga Foggy',\n`}
          {`      value: 'megga_foggy',\n`}
          {avatarSrc && (
          <>
          {`      avatar: './avatar.png',\n`}
          </>
          )}
          {`    },\n`}
          {`    ...\n`}
          {`  ]}\n`}
          </>
          )}
          {`>\n`}
          {children && (
          <>
          {`  <Box>Some additional content...</Box>\n`}
          </>
          )}
          {`</Menu>\n`}
        </Box>

        <Menu
          minWidth={minWidth}
          label={label}
          options={opts}
          itemsMax={itemsMax}
          itemsFullWidth={itemsFullWidth}
          itemsSize={itemsSize}
          maxHeight={maxHeight}
          footer={!footer ? undefined : (
            <>
              <Button size="small" fullWidth>Submit</Button>
              <Button size="small" fullWidth variant="text">Cancel</Button>
            </>
          )}
        >
          {!children ? undefined : (
            <Box>Some additional content...</Box>
          )}
        </Menu>
      </Box>
    );
  };
  Menu.Demo = Demo;
}

export default Menu;
