import { useState, useCallback } from 'react';
import { Box, Collapse, styled, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dev } from 'utils/config';
import { getArray } from 'utils/helpers';

import { Center, IconButton, RippleBox, Button } from 'components';
import { ArticleGeneralInformation } from 'views';

const Root = styled(Stack, { label: 'Steps-Root' })({});

const ToggleButton = styled(IconButton, { label: 'Steps-ToggleButton' })(({ theme }) => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  marginRight: theme.spacing(1),
}));

const StepItem = styled(RippleBox, { label: 'Steps-StepItem' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.other.white,
  borderRadius: theme.shape.borderRadius * 2,
}));

const Steps = (props) => {
  const { steps, ...rest } = props;

  const [openStep, setOpenStep] = useState(-1);

  const { t } = useTranslation('views', { keyPrefix: 'Steps' });

  const toggleStep = useCallback(
    (idx) => {
      setOpenStep((old) => {
        if (idx === old) return -1;
        return idx;
      });
    },
    [setOpenStep],
  );

  return (
    <Root spacing={2} flexDirection="column" {...rest}>
      {getArray(steps).map((step) => {
        const isOpen = openStep === 0 || openStep === step.step;
        const photos = getArray(step?.photos).map((photo) => ({ src: photo }));
        return (
          <Box key={`step-${step.id}-${step.step}`}>
            <StepItem ripple justifyContent="flex-start" onClick={() => toggleStep(step.step)}>
              <ToggleButton name={isOpen ? 'ArrowUp' : 'ArrowRight'} />
              <Typography mr={1} variant="body2" color="primary">
                {t('step', { index: step.step })}
              </Typography>
              <Typography variant="body3" color="textBlack.dark">
                {step.name}
              </Typography>
            </StepItem>
            <Collapse mt={2} in={isOpen}>
              <ArticleGeneralInformation
                mt={2}
                hideTitle
                description={step.description}
                sources={step.sources}
                photos={photos}
              />
            </Collapse>
          </Box>
        );
      })}
      <Center justifyContent="flex-end" onClick={() => toggleStep(0)}>
        <Button variant="outlined">{openStep === 0 ? t('hide_all') : t('show_all')}</Button>
      </Center>
    </Root>
  );
};

if (dev) {
  const steps = [{ step: 1, name: '123' }];

  const Demo = () => {
    return (
      <Box p={2}>
        <Steps steps={steps} />
      </Box>
    );
  };
  Steps.Demo = Demo;
}

export default Steps;
