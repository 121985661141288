import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Collapse } from '@mui/material';

import {
  useForm,
  useMessage,
  useAuth,
  useUploadPhotos,
  useCreateTag,
  useProjectSearch,
} from 'hooks';
import { getArray, capitalize } from 'utils/helpers';
import {
  useGetMeQuery,
  useCreateCollectionMutation,
  useUpdateCollectionProjectsMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper, Tabs } from 'components';
import { PageSubheader, CollectionInformationForm, ProjectSearch } from 'views';
import { getLevelStyles } from 'views/LevelTag';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };

const NewCollection = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_collection' });
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();
  const navigate = useNavigate();

  const [tab, setTab] = useState('information');

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery();

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const {
    searchValue: projectSearchValue,
    onSearch: onProjectSearch,
    projects,
    loading: projectsLoading,
  } = useProjectSearch({});

  const [createTag, createTagState] = useCreateTag({ me });
  const [createCollection, createCollectionState] = useCreateCollectionMutation();
  const [updateCollectionProjects, updateCollectionProjectsState] =
    useUpdateCollectionProjectsMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  const handleCreateCollection = useCallback(
    async (formData) => {
      try {
        const { photos } = formData;
        const postData = mapRequest(formData);

        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;

          postData.photos = photoIds;
        }

        const { collection_id } = await createCollection({
          id: me.space_id,
          ...postData,
        }).unwrap();

        const { status } = await updateCollectionProjects({
          id: collection_id,
          projects: getArray(formData?.projects).map((project) => project.id),
        }).unwrap();

        if (status === 'OK') {
          navigate(`/learning/collections/${collection_id}`);
          return;
        }

        m.error('collection_created_without_projects', { collection_id });
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_create'));
        }
      }
    },
    [createCollection, updateCollectionProjects, m, me, t, uploadPhotos, navigate],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const tabOptions = useMemo(
    () => [
      {
        label: t('tabs.collection_information'),
        value: 'information',
      },
      {
        label: t('tabs.projects'),
        value: 'projects',
      },
    ],
    [t],
  );

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadPhotosState.isLoading ||
    updateCollectionProjectsState.isLoading ||
    createCollectionState.isLoading;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleCreateCollection}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/learning/collections"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.previous_step')]: {
                type: 'button',
                disabled: loading || tab === 'information',
                variant: 'outlined',
                size: 'small',
                onClick: () => setTab('information'),
              },
              ...(tab === 'projects'
                ? {
                    [t('page_actions.create')]: {
                      type: 'submit',
                      size: 'small',
                      disabled: !valid || loading,
                    },
                  }
                : {
                    [t('page_actions.next_step')]: {
                      type: 'button',
                      size: 'small',
                      disabled: !valid || loading,
                      onClick: () => setTab('projects'),
                    },
                  }),
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
            {tab === 'information' ? t('page_information_title') : t('page_projects_title')}
          </Typography>

          <Tabs
            mb={2}
            width={396}
            variant="fullWidth"
            value={tab}
            onValue={setTab}
            options={tabOptions}
          />

          <Collapse in={tab === 'information'}>
            <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
              <CollectionInformationForm
                rules={rules}
                loadingTags={loadingTags}
                tagsOptions={tagsOptions}
                levelOptions={levelOptions}
                loadingLevels={loadingLevels}
                defaultSourceValue={defaultSourceValue}
                onCreateTag={createTag}
              />
            </Paper>
          </Collapse>
          <Collapse in={tab === 'projects'}>
            <ProjectSearch.Control
              searchPlaceholder={t('search_project')}
              projects={projects}
              loading={projectsLoading}
              name="projects"
              search={projectSearchValue}
              onSearch={onProjectSearch}
            />
          </Collapse>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewCollection;
