import { useCallback } from 'react';
import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { allowedImageTypes } from 'utils/constants';
import { withProps, useGroupSearch } from 'hooks';

import {
  Input,
  FormRow,
  Dropzone,
  PhoneField,
  SelectMultiple,
  Tag,
  FormTitle,
  Icon,
} from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const UserInformationForm = (props) => {
  const {
    me,
    title,
    visible,
    rules: { required, email, phone, startsWithUppercase },
    withGroups = true,
    extraFields,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'UserInformationForm' });

  const {
    options: groupOptions,
    search: groupSearch,
    onSearch: onGroupSearch,
  } = useGroupSearch({
    spaceId: me?.space_id,
    disabled: !me?.space_id,
    mapToOptions: true,
  });

  const renderGroupTag = useCallback(({ option, index, tagProps, onDelete }) => {
    return (
      <Tag
        key={`${option.label}-${index}`}
        label={option.label}
        clickable
        color="textBlack.light"
        bgcolor="input.main"
        size="medium"
        onClick={onDelete}
        startAdornment={<Icon name="Group" />}
        endAdornment={<Icon color="error" name="Close" />}
        px={1}
        {...tagProps}
      />
    );
  }, []);

  return (
    <>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {title}
        </Typography>
      </FormTitle>
      <Stack mt={2} width="100%">
        <FormRow optional label={t('avatar.label')} alignItems="flex-start">
          <Dropzone.Control fullWidth accept={allowedImageTypes} name="avatar" />
        </FormRow>

        <FormRow label={t('first_name.label')}>
          <Input.Control
            fullWidth
            name="first_name"
            initFocus={visible}
            placeholder={t('first_name.placeholder')}
            rules={{ required, validate: (v) => startsWithUppercase(v, t('first_name.label')) }}
          />
        </FormRow>

        <FormRow label={t('last_name.label')}>
          <Input.Control
            fullWidth
            name="last_name"
            placeholder={t('last_name.placeholder')}
            rules={{ required, validate: (v) => startsWithUppercase(v, t('last_name.label')) }}
          />
        </FormRow>

        <FormTitle width="100%">
          <Typography color="secondary.main" variant="body2">
            {t('contact.title')}
          </Typography>
        </FormTitle>

        <FormRow label={t('email.label')}>
          <Input.Control
            fullWidth
            type="email"
            name="email"
            placeholder={t('email.placeholder')}
            rules={{ required, validate: email }}
          />
        </FormRow>

        <FormRow optional label={t('phone.label')}>
          <PhoneField.Control
            optional
            fullWidth
            name="phone_number"
            placeholder={t('phone.placeholder')}
            rules={{ phone }}
          />
        </FormRow>

        {withGroups && (
          <>
            <FormTitle mt={2} width="100%">
              <Typography color="secondary.main" variant="body2">
                {t('groups_classes')}
              </Typography>
            </FormTitle>
            <Stack mt={2} width="100%">
              <FormRow label={t('groups.label')} alignItems="flex-start">
                <SelectMultiple.Control
                  fullWidth
                  chips={false}
                  name="groups"
                  placeholder={t('groups.placeholder')}
                  options={groupOptions}
                  inputValue={groupSearch}
                  onInputChange={onGroupSearch}
                  renderPreviewItem={renderGroupTag}
                />
              </FormRow>
            </Stack>
          </>
        )}
      </Stack>
      {extraFields}
    </>
  );
};

export default UserInformationForm;
