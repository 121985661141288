import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { getArray } from 'utils/helpers';

import { Center, Tag, Paper, Icon } from 'components';

const TopTags = (props) => {
  const { title, description, tags = [] } = props;

  return (
    <Paper shadow="small" width={1}>
      <Center mb={1} color="textBlack.light">
        <Icon fontSize="16px" name="Tags" />
        <Typography variant="body2" ml={0.5}>
          {title}
        </Typography>
      </Center>
      <Typography variant="body4" color="textBlack.contrastText" pl={2.5}>
        {description}
      </Typography>

      <List
        dense
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          height: 256,
          flexFlow: 'column wrap',
        }}
      >
        {getArray(tags).map((item, index) => (
          <ListItem key={item.id} disableGutters sx={{ width: 'auto' }}>
            <ListItemIcon sx={{ minWidth: 28 }}>
              <Typography variant="body2" color="tag.main">
                {index + 1}.
              </Typography>
            </ListItemIcon>
            <ListItemText
              sx={{ marginTop: '4px', marginBottom: '4px', display: 'flex', alignItems: 'center' }}
              primary={
                <Tag
                  size="medium"
                  label={item.name}
                  sx={{
                    border: '1px solid',
                    borderColor: 'controls.main',
                    backgroundColor: 'input.main',
                  }}
                />
              }
              secondary={
                <Typography color="textBlack.contrastText" ml={1} variant="caption1">
                  ({item.qty})
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default TopTags;
