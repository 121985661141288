import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';

import { useGetChildCollectionsQuery } from 'store/api';
import { getArray, capitalize } from 'utils/helpers';

import { Center, ImageHolder, LinearProgress, PaperCard } from 'components';
import { ProgressStatistics, LevelTag } from 'views';

import mapRequest from './mapRequest';

const PER_PAGE = 9;

const CollectionCard = (props) => {
  const { data } = props;

  const { t } = useTranslation('views', { keyPrefix: 'CardContent' });

  const progress = useMemo(() => {
    if (!data) return 0;
    return (data.projects_published / data.projects_qty) * 100;
  }, [data]);

  return (
    <PaperCard to={data.to} border size="small">
      <Center mb={2.5} justifyContent="space-between" width={1}>
        <Box display="flex" alignItems="center">
          <ImageHolder mr={1} flexShrink={0} size={24} src={data.photo} />
          <Typography component="div" variant="body2" color="text.secondary">
            {data.name}
          </Typography>
        </Box>
        <LevelTag
          level={data.level}
          label={<Typography variant="caption1"> {capitalize(data.level)}</Typography>}
          size="small"
        />
      </Center>
      <Box width={1}>
        <LinearProgress variant="determinate" value={progress} />

        <Center mt={0.5} justifyContent="space-between">
          <Typography component="div" variant="caption1" color="textBlack.light">
            {t('project_published')}
          </Typography>
          <Typography component="div" variant="caption1" color="textBlack.light">
            {data.projects_published}/{data.projects_qty}
          </Typography>
        </Center>
      </Box>
    </PaperCard>
  );
};

const MyCollections = (props) => {
  const { me, pageFilters, loading, auth } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'learning' });

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: childCollections,
    isLoading: childCollectionsLoading,
    isFetching: childCollectionsFetching,
  } = useGetChildCollectionsQuery(
    mapRequest({
      me,
      filters: pageFilters,
      pagination: { perPage: PER_PAGE, page: currentPage },
    }),
    {
      skip: (auth && !me?.space_id) || loading,
    },
  );

  const statistics = useMemo(() => {
    const { statistics } = childCollections || {};
    return [
      {
        label: t('statistics.assigned'),
        name: 'assigned',
        count: statistics?.assigned || 0,
        color: 'secondary.light',
      },
      {
        label: t('statistics.in_progress'),
        name: 'progress',
        count: statistics?.progress || 0,
        color: 'warning.dark',
      },
      {
        label: t('statistics.completed'),
        name: 'completed',
        count: statistics?.completed || 0,
        color: 'success.dark',
      },
    ];
  }, [childCollections, t]);

  const childCollectionList = useMemo(() => {
    const { collections } = childCollections || {};
    return getArray(collections).map((collection) => ({
      ...collection,
      id: collection.id,
      level: collection.level,
      name: collection.name,
      photo: collection.avatar,
      to: `/learning/child-collection/${collection.id}`,
    }));
  }, [childCollections]);

  return (
    <ProgressStatistics
      title={t('my_collections')}
      loading={loading || childCollectionsLoading || childCollectionsFetching}
      statistics={statistics}
      data={childCollectionList}
      perPage={PER_PAGE}
      page={currentPage}
      total={childCollections?.collections_qty || 0}
      onLoadMore={() => setCurrentPage(currentPage + 1)}
      CustomItemComponent={CollectionCard}
    />
  );
};

export default MyCollections;
