import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import { Messages } from 'views';

import store from 'store';
import theme from 'styles';
import ProjectRoutes from 'routes';
import 'i18n';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Messages>
          <ProjectRoutes />
        </Messages>
      </ThemeProvider>
    </Provider>
  );
};

export default <App />;
