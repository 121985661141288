import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getArray } from 'utils/helpers';

import {
  validateEmail,
  validateNotEmptyArray,
  validatePasswordWithDetails,
  validatePhone,
  validateDescriptionWithDetails,
  validateBirthday,
} from 'utils/validators';

const keyPrefix = 'validators';

/**
 * Why? To keep translations for common errors in one place.
 */
const useRules = () => {
  const { t } = useTranslation('helpers', { keyPrefix });

  const required = t('required');

  /**
   * Validate in defined order
   * rules={{ validate: order([...validators]) }}
   */
  const order = useCallback(
    (validators) => (v) => {
      const result = getArray(validators).reduce((res, validator) => {
        if (res) {
          return res;
        }
        return validator(v);
      }, null);

      return result || undefined;
    },
    [],
  );

  /**
   * rules={{ validate: username }}
   */
  const username = useCallback(
    (v) => {
      if (v) {
        const isValidEmail = validateEmail(v);
        const isValidPhone = validatePhone(v.replace(/\D/g, ''));
        return isValidEmail || isValidPhone || t('username');
      }
    },
    [t],
  );

  /**
   * rules={{ validate: email }}
   */
  const email = useCallback(
    (v) => {
      if (v) {
        return validateEmail(v) || t('email');
      }
    },
    [t],
  );

  /**
   * rules={{ validate: birthday }}
   */
  const birthday = useCallback(
    (v) => {
      if (v?.day === undefined && v?.year === undefined && v?.month === undefined) return true;
      if (v) {
        const { valid } = validateBirthday(v);
        return valid || t('birthday');
      }
    },
    [t],
  );

  /**
   * rules={{
   *  required: (v) => !v && oneOfRequired('Name', 'Email'),
   * }}
   */
  const oneOfRequired = useCallback(
    (...args) => {
      const names = args.join(', ');
      return t('oneOfRequired', { names });
    },
    [t],
  );

  /**
   * rules={{ validate: phone }}
   */
  const phone = useCallback(
    (v) => {
      if (v) {
        return validatePhone(v) || t('phone.wrong_phone_number');
      }
    },
    [t],
  );

  /**
   * rules={{ validate: phone }}
   */
  const description = useCallback(
    (v) => {
      if (!v) return required;

      const details = validateDescriptionWithDetails(v);

      if (!details.valid) {
        if (!details.length) {
          return t('description.length');
        }
        if (!details.special) {
          return t('description.specials');
        }
      }
    },
    [t, required],
  );

  /**
   * rules={{ validate: password }}
   */
  const passwordBase = useCallback(
    (v) => {
      const details = validatePasswordWithDetails(v);

      if (!details.valid) {
        if (!details.length) {
          return t('password.length');
        }
        if (!details.number) {
          return t('password.number');
        }
        if (!details.special) {
          return t('password.specials');
        }
        if (!details.uppercase) {
          return t('password.uppercase');
        }
      }
    },
    [t],
  );

  /**
   * rules={{ validate: password }}
   */
  const password = useCallback(
    (v) => {
      if (!v) return required;
      return passwordBase(v);
    },
    [required, passwordBase],
  );

  /**
   * rules={{ validate: password }}
   */
  const passwordOptional = useCallback(
    (v) => {
      if (!v) return true;
      return passwordBase(v);
    },
    [passwordBase],
  );

  /**
   * rules={{ validate: requiredArray }}
   */
  const requiredArray = useCallback(
    (v) => {
      // Array must be not empty
      return validateNotEmptyArray(v) || t('required');
    },
    [t],
  );

  /**
   * rules={{ required: requiredIf(cond) }}
   */
  const requiredIf = useCallback(
    (cond) => {
      if (cond) {
        return required;
      }
      return false;
    },
    [required],
  );

  /**
   * rules={{ validate: (v) => startsWithUppercase(v, 'Name) }}
   */
  const startsWithUppercase = useCallback(
    (v, name) => {
      if (v) {
        const valid = v[0].toUpperCase() === v[0];
        return valid || t('startsWithUppercase', { name });
      }
    },
    [t],
  );

  return useMemo(
    () => ({
      required,
      phone,
      email,
      password,
      passwordBase,
      passwordOptional,
      requiredArray,
      oneOfRequired,
      requiredIf,
      username,
      order,
      description,
      birthday,
      startsWithUppercase,
    }),
    [
      requiredArray,
      oneOfRequired,
      phone,
      email,
      required,
      requiredIf,
      password,
      passwordOptional,
      passwordBase,
      username,
      order,
      description,
      birthday,
      startsWithUppercase,
    ],
  );
};

export default useRules;
