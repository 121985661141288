import { useState, forwardRef, useMemo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs, generateRandomPassword } from 'utils/helpers';
import { validatePasswordWithDetails } from 'utils/validators';
import { useMixRules, useRules, useToggle, withProps } from 'hooks';

import { Input, Icon, IconButton, Checkbox, Button, Center } from 'components';

import Fieldset from 'components/Dev/Fieldset';

const IndicatorContainer = styled(Box, {
  label: 'PasswordField-IndicatorContainer',
})(({ theme }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  marginLeft: theme.spacing(1),
  pointerEvents: 'none',
}));

const IndicatorItem = styled(Box, {
  label: 'PasswordField-Indicator',
})(({ theme }) => ({
  flexGrow: 1,
  minWidth: '50%',
  marginTop: theme.spacing(1),
}));

const Indicator = withProps(IndicatorItem, {
  size: 'xsmall',
});

const GenerateButton = styled(Button, { label: 'PasswordField-GenerateButton' })(({ theme }) => ({
  marginLeft: theme.spacing(2),
  minWidth: 152,
  '& .MuiTypography-root': {
    paddingRight: theme.spacing(0.5),
  },
}));

const keyPrefix = 'PasswordField';

/**
 * Input field with type "password" and button that allows to show/hide entered password
 * All the props the same as for <Input /> component, excluding "type" and "iconRight".
 * @prop {boolean} [newPassword] - If true, will disable browser auto-fill
 * @prop {string|boolean} [error] - Custom error text/state
 * @prop {boolean} [validation=true] - Password validation
 * @prop {boolean} [indicateValidation] - Show validation indicator on the bottom. If true,
 * error message will not appear (except the custom error message passed into the component from
 * outside) (TODO: what about error state?)
 */
const PasswordField = forwardRef((props, ref) => {
  const {
    validation = true,
    newPassword,
    generate = false,
    error: customError,
    indicateValidation,
    fullWidth,
    value,
    onValue,
    ...rest
  } = props;

  const { t } = useTranslation('views', { keyPrefix });

  const [show, toggleShow] = useToggle(false);
  const { password: passwordValidator } = useRules();

  const iconName = show ? 'EyeOff' : 'Eye';
  const type = show ? 'text' : 'password';

  const validationDetials = useMemo(() => {
    return validatePasswordWithDetails(value);
  }, [value]);

  const error = useMemo(() => {
    if (typeof customError === 'string' || typeof customError === 'boolean') {
      return customError;
    }
    if (!validation) return false;
    if (!value) return false;
    const result = passwordValidator(value);
    return indicateValidation ? !!result : result;
  }, [customError, validation, value, passwordValidator, indicateValidation]);

  const handleValue = useCallback(
    (v) => {
      typeof onValue === 'function' && onValue(v);
    },
    [onValue],
  );

  return (
    <Center alignItems="flex-start" width={fullWidth ? '100%' : undefined}>
      <Input
        ref={ref}
        {...rest}
        error={error}
        type={type}
        value={value}
        fullWidth={fullWidth}
        onValue={handleValue}
        autoComplete={newPassword ? 'new-password' : undefined}
        iconRight={
          <IconButton variant="text" color="primary" onClick={toggleShow}>
            <Icon name={iconName} />
          </IconButton>
        }
      >
        {indicateValidation && (
          <IndicatorContainer>
            <Indicator>
              <Checkbox
                checked={!!validationDetials.length}
                label={t('validation_indicator.length')}
              />
            </Indicator>

            <Indicator>
              <Checkbox
                checked={!!validationDetials.number}
                label={t('validation_indicator.number')}
              />
            </Indicator>

            <Indicator>
              <Checkbox
                checked={!!validationDetials.special}
                label={t('validation_indicator.special')}
              />
            </Indicator>

            <Indicator>
              <Checkbox
                checked={!!validationDetials.uppercase}
                label={t('validation_indicator.uppercase')}
              />
            </Indicator>
          </IndicatorContainer>
        )}
      </Input>
      {generate && (
        <GenerateButton
          type="button"
          iconLeft="Generate"
          variant="outlined"
          ml={2}
          onClick={() => {
            handleValue(generateRandomPassword());
            if (!show) toggleShow(true);
          }}
        >
          {t('generate_random')}
        </GenerateButton>
      )}
    </Center>
  );
});

PasswordField.Control = forwardRef((props, ref) => {
  const { name, rules: initRules, optional, defaultValue = '', onChange, onBlur, inputRef, ...rest } = props;

  const { control, trigger } = useFormContext();
  const { password, passwordOptional } = useRules();
  const rules = useMixRules(initRules, optional ? passwordOptional : password);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error, isTouched } = fieldState;
        const {
          value,
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (v) => {
          if (v === value) return;
          controlOnChange(v);
          typeof onChange === 'function' && onChange(v);
        };
        const handleBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        
        return (
          <PasswordField
            ref={ref}
            optional={optional}
            value={value}
            error={isTouched && (error?.message || !!error)}
            inputRef={propagateRefs(inputRef, controlRef)}
            onValue={handleChange}
            onBlur={handleBlur}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [value, setValue] = useState('mypassword');
    const [error, setError] = useState();
    const [indicateValidation, setIndicateValidation] = useState(true);

    return (
      <Box>
        <Box mb={2}>
          All the props the same as for {`<Input />`} component, excluding "type" and "iconRight".
        </Box>

        <Fieldset>
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, true, false, 'Password is required!']}
          />
          <Fieldset.Field
            legend="indicateValidation"
            value={indicateValidation}
            onChange={setIndicateValidation}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre">
          {`<PasswordField\n`}
          {`  label="Password"\n`}
          {`  value={value}\n`}
          {`  onValue={setValue}\n`}
          {`  error={${error}}\n`}
          {`/>\n`}
        </Box>

        <Box p={2}>
          <PasswordField
            label="Password"
            value={value}
            onValue={setValue}
            error={error}
            indicateValidation={indicateValidation}
          />
        </Box>
      </Box>
    );
  };
  PasswordField.Demo = Demo;
}

export default PasswordField;
