import { useState, useMemo, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetGroupProjectStatisticsQuery, useGetGroupProjectsQuery } from 'store/api';
import { getArray } from 'utils/helpers';

import { AverageTimeSpentCard } from 'views';

import { GroupFilterBar } from './FilterBar';
import ProjectStatistics from './ProjectStatistics';

const PER_PAGE = 12;

const GroupProjectStatistics = (props) => {
  const { groupId } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    group: groupId,
    status: 'Assigned',
  });

  const handleFiltersChange = useCallback((name, v) => {
    setFilters((f) => ({ ...f, [name]: v }));
  }, []);

  const {
    data: projectStatistics,
    isLoading,
    isFetching,
  } = useGetGroupProjectStatisticsQuery({ id: filters?.group }, { skip: !Boolean(filters?.group) });

  const {
    data: childProjects,
    isLoading: childProjectsLoading,
    isFetching: childProjectsFetching,
  } = useGetGroupProjectsQuery(
    {
      group_id: filters?.group,
      status: filters?.status ? [filters.status] : [],
      collections: [],
      limit: PER_PAGE * currentPage,
      offset: 0,
    },
    {
      skip: !Boolean(filters?.group),
    },
  );

  const childProjectList = useMemo(() => {
    const { child_projects } = childProjects || {};
    return getArray(child_projects).map((project) => ({
      id: project.id,
      name: project.name,
      owner: {
        id: project.project_owner?.id,
        name: project.project_owner?.name,
        avatar: project.project_owner?.avatar,
      },
      to: `/learning/student-project/${project.id}`,
    }));
  }, [childProjects]);

  const loading = isLoading || isFetching;

  return (
    <Box width={1}>
      <Box>
        <GroupFilterBar groupId={groupId} value={filters} onValue={handleFiltersChange} mb={2} />
      </Box>
      <ProjectStatistics
        mb={4}
        title={t('projects')}
        loading={childProjectsLoading || childProjectsFetching}
        statuses={childProjects?.status || []}
        status={filters?.status}
        projects={childProjectList}
        total={childProjects?.child_projects_qty || 0}
        onLoadMore={() => setCurrentPage(currentPage + 1)}
        page={currentPage}
        perPage={PER_PAGE}
      />
      <Grid container spacing={3} mb={3}>
        <Grid item xs={6}>
          <AverageTimeSpentCard
            loading={loading}
            title={t('average_time_spent_on_project')}
            value={projectStatistics?.time_spent?.project}
          />
        </Grid>
        <Grid item xs={6}>
          <AverageTimeSpentCard
            loading={loading}
            title={t('average_time_spent_on_collection')}
            value={projectStatistics?.time_spent?.collection}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupProjectStatistics;
