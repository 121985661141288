import { useMemo } from 'react';

import { isObject } from 'utils/helpers';

/**
 * Usefull hook for controlled components. It mixes given rules with another one.
 * For example PhoneField component could take any custom validator, but also includes
 * default phone number validator. This hook allows to add a phone number validator to
 * provided rules.
 * @param {object} [rules] - <Controller /> react hook form [rules] property
 * @param {function} validator - Validation function that should be added.
 * 
 * Example of usage:
 * 
 *  ...
 *  const rules = useMixRules(props.rules, phoneNumberValidator);
 * 
 *  <Controller
 *    rules={rules}
 *    ...
 */
const useMixRules = (rules, validator) => {
  return useMemo(() => ({
    ...rules,
    validate: (v) => {
      if (typeof rules?.validate === 'function') {
        const result = rules.validate(v);

        if (result !== true) {
          return result;
        }
      }
      if (isObject(rules?.validate)) {
        const result = Object.values(rules.validate).reduce((res, handler) => {
          if (res === true) {
            return handler(v);
          }
          return res;
        }, true);

        if (result !== true) {
          return result;
        }
      }
      return validator(v);
    },
  }), [rules, validator]);
};

export default useMixRules;
