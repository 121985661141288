import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PARENT_NOTIFICATION_PERMISSIONS } from 'utils/constants';

import { FormTitle, FormRow, Switch } from 'components';
import { LanguageSelect } from 'views';

const Settings = (props) => {
  const { onNotificationChange, onLanguageChange } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'settings' });

  return (
    <>
      <FormTitle width={1}>
        <Typography color="secondary.main" variant="body2">
          {t('interface')}
        </Typography>
      </FormTitle>

      <Stack mt={2} mb={3} width={1} spacing={1.5}>
        <FormRow label={t('language.label')}>
          <LanguageSelect.Control
            fullWidth
            name="language"
            placeholder={t('language.placeholder')}
            onChange={onLanguageChange}
          />
        </FormRow>
      </Stack>

      <FormTitle width={1}>
        <Typography color="secondary.main" variant="body2">
          {t('notifications')}
        </Typography>
      </FormTitle>

      <Stack mt={2} width={1} spacing={1.5}>
        {PARENT_NOTIFICATION_PERMISSIONS.map((name) => (
          <FormRow key={name} label={t(`notification_permissions.${name}`)}>
            <Switch.Control
              fullWidth
              name={`notifications.${name}`}
              onChange={(e) => onNotificationChange(e, name)}
            />
          </FormRow>
        ))}
      </Stack>
    </>
  );
};

export default Settings;
