import { useMemo } from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks';
import { useGetMeQuery, useGetGroupsQuery } from 'store/api';
import { ROLE, STUDENT_PERMISSIONS, TEACHER_PERMISSIONS } from 'utils/constants';
import { getArray, hasPermission, formatPhoneNumber } from 'utils/helpers';

import { Center, Paper, Avatar, Tags, Icon, FormRow, Switch, IconButton } from 'components';

const UserTab = (props) => {
  const { id = 'me' } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'profile.user' });
  const { auth, progress } = useAuth();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: groups,
    isLoading: groupsIsLoading,
    isFetching: groupsIsFetching,
  } = useGetGroupsQuery(
    { user_id: me?.id, space_id: me?.space_id, limit: 10, offset: 0 },
    {
      skip: !auth || !me,
    },
  );

  const groupsLoading = groupsIsLoading || groupsIsFetching;
  const loading = isLoading || isFetching || progress || groupsLoading;

  const roleTags = useMemo(() => {
    if (!me) return [];

    const iconName = {
      [ROLE.ADMIN]: 'Admin',
      [ROLE.TEACHER]: 'Teacher',
      [ROLE.PARENT]: 'Parent',
      [ROLE.STUDENT]: 'Student',
    }[me.role];

    return [
      {
        pl: 1,
        name: t(`roles.${me.role}`),
        startAdornment: iconName ? <Icon name={iconName} /> : null,
        value: me.role,
      },
    ];
  }, [me, t]);

  const groupTags = useMemo(() => {
    return getArray(groups?.groups).map((group) => ({
      id: group.id,
      name: group.name,
      startAdornment: <Icon name="Group" />,
      pl: 1,
    }));
  }, [groups]);

  const rolePermissions = me?.role === ROLE.STUDENT ? STUDENT_PERMISSIONS : TEACHER_PERMISSIONS;

  return (
    <Box pt={3}>
      <Center mb={2} justifyContent="space-between">
        <Typography variant="subtitle5">{t('personal_information')}</Typography>

        {me?.role === ROLE.ADMIN && (
          <IconButton
            name="Edit"
            type="button"
            disabled={loading}
            variant="outlined"
            size="small"
            border
            to={`/profile/${id}/edit`}
          />
        )}
      </Center>

      <Paper mb={5} width={1} shadow="standard" loading={loading}>
        <Center>
          <Box>
            <Avatar src={me?.profile?.avatar} size={168} radius={2} />
          </Box>
          <Box pl={2} color="textBlack.light">
            <Typography color="textBlack.dark" variant="body2">
              {me?.profile?.name}
            </Typography>

            <Stack
              mt={1.5}
              direction="row"
              divider={
                <Divider
                  sx={{ margin: '0 12px', borderColor: 'other.line' }}
                  orientation="vertical"
                  flexItem
                />
              }
            >
              {me?.phone_number && (
                <Center>
                  <Icon name="Phone" fontSize="small" />
                  <Typography variant="body3" ml={0.5}>
                    {formatPhoneNumber(me.phone_number)}
                  </Typography>
                </Center>
              )}
              {me?.email && (
                <Center>
                  <Icon name="Mail" fontSize="small" />
                  <Typography variant="body3" ml={0.5}>
                    {me?.email}
                  </Typography>
                </Center>
              )}
            </Stack>

            <Tags titleProps={{ minWidth: 56 }} tags={roleTags} title={t('role')} mt={2.5} />
            <Tags titleProps={{ minWidth: 56 }} tags={groupTags} title={t('groups')} mt={2} />
          </Box>
        </Center>
      </Paper>

      {me?.role !== ROLE.PARENT && (
        <>
          <Center mb={2} justifyContent="space-between">
            <Typography variant="subtitle5">{t('individual_permission')}</Typography>
            {me?.role === ROLE.ADMIN && (
              <IconButton
                name="Edit"
                type="button"
                disabled={loading}
                variant="outlined"
                size="small"
                border
                to={`/profile/${id}/settings/edit`}
              />
            )}
          </Center>

          <Paper mb={5} width={1} shadow="standard" loading={loading}>
            <Box
              width={1}
              pb={1}
              mb={1}
              sx={{ borderBottom: '1px solid', borderColor: 'other.divider' }}
            >
              <Typography>{t('individual_permission')}</Typography>
            </Box>
            <Stack spacing={1}>
              {Object.entries(rolePermissions).map(([name, permissions]) => (
                <FormRow key={name} label={t(`permissions.${name}`)}>
                  <Switch
                    checked={hasPermission(me?.profile?.permissions, permissions)}
                    fullWidth
                    disabled
                    name={name}
                  />
                </FormRow>
              ))}
            </Stack>
          </Paper>
        </>
      )}
    </Box>
  );
};

export default UserTab;
