import { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetMeQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';

import { ProjectLayout } from 'layouts';
import { Center, Tabs, Icon, Input, DropdownMenu, Button, Modal } from 'components';
import { SidebarFilters } from 'views';

import Tips from './Tips';
import Questions from './Questions';

const { selectFilters, selectActiveFilters } = selectors;

const DEFAULT_TAB = 'tips';

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const ActionsRoot = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const KnowledgeBase = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const { t } = useTranslation('pages', { keyPrefix: 'knowledge_base' });
  const { auth } = useAuth();
  const { onSearch, tempSearch, onSetActiveFilter, onResetPageFilter } = usePageFilter();

  const [tab, setTab] = useState(slug || DEFAULT_TAB);
  const [signupInformationOpen, setSignupInformationOpen] = useState(false);

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const tabOptions = useMemo(() => {
    return [
      {
        value: 'tips',
        label: t('tips'),
        icon: 'Idea2',
        width: 192,
      },
      {
        value: 'questions',
        label: t('questions'),
        icon: 'Forum',
        width: 192,
      },
    ];
  }, [t]);

  const content = useMemo(() => {
    switch (tab) {
      case 'tips':
        return <Tips me={me} />;
      case 'questions':
        return <Questions me={me} />;
      default:
        return null;
    }
  }, [tab, me]);

  const handleTabValue = useCallback(
    (value) => {
      setTab(value);
      navigate(`/knowledge-base/${value}`, { replace: false });
    },
    [setTab, navigate],
  );

  const closeSignupInformationModal = useCallback(() => {
    setSignupInformationOpen(false);
  }, [setSignupInformationOpen]);

  useEffect(() => {
    if (!slug) {
      navigate(`/knowledge-base/${DEFAULT_TAB}`);
    }
  }, [slug, navigate]);

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  return (
    <ProjectLayout
      subheader={
        <Center flexGrow={1}>
          <PageTitle>
            <Typography variant="subtitle2">{t('subheader.title')}</Typography>
          </PageTitle>
          <Grid container width="100%" mx={0} spacing={3}>
            <Grid item xs={6}>
              <Input
                value={tempSearch}
                onValue={onSearch}
                iconLeft="Search"
                fullWidth={true}
                placeholder={t('subheader.page_search')}
              />
            </Grid>
            <Grid item xs={6}>
              <ActionsRoot>
                <DropdownMenu
                  minWidth={200}
                  target={<Button iconRight="ArrowDown">{t('subheader.create_new')}</Button>}
                  options={[
                    {
                      pl: 1,
                      startAdornment: <Icon name="Idea" />,
                      label: t('subheader.create_tip'),
                      value: 'create_tip',
                      onClick: () => {
                        if (me) {
                          navigate('/knowledge-base/tip/new');
                        } else {
                          setSignupInformationOpen(true);
                        }
                      },
                    },
                    {
                      pl: 1,
                      startAdornment: <Icon name="Forum" />,
                      label: t('subheader.create_question'),
                      value: 'create_question',
                      onClick: () => {
                        if (me) {
                          navigate('/knowledge-base/question/new');
                        } else {
                          setSignupInformationOpen(true);
                        }
                      },
                    },
                  ]}
                />
              </ActionsRoot>
            </Grid>
          </Grid>
        </Center>
      }
      sidebar={
        <Box px={4} py={5} pr={2}>
          <Stack direction="row" alignItems="center" mb={3}>
            <Icon fontSize="inherit" name="Filter" />
            <Typography variant="body2" ml={1}>
              {t('sidebar.filter_by')}
            </Typography>
          </Stack>

          <SidebarFilters
            filters={filters}
            activeFilters={activeFilters}
            onChange={onSetActiveFilter}
          />
        </Box>
      }
    >
      <Modal open={signupInformationOpen} onClose={closeSignupInformationModal}>
        <Modal.Body mx={-1} width="auto">
          <Center pt={4} flexDirection="column">
            <Typography display="inline-block" mb={2} variant="body4">
              {t('signup_information.text')}
            </Typography>
            <Button to="/sign-up">{t('signup_information.signup')}</Button>
          </Center>
        </Modal.Body>
      </Modal>

      <Box p={4} pl={3}>
        <Center pb={3} justifyContent="space-between">
          <Tabs
            sx={{ backgroundColor: 'other.white' }}
            value={tab}
            variant="standard"
            options={tabOptions}
            onValue={handleTabValue}
          />

          {/* TODO: Add sort button */}
        </Center>

        {content}
      </Box>
    </ProjectLayout>
  );
};

export default KnowledgeBase;
