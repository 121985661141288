import { forwardRef, useMemo, useState } from 'react';
import { Typography, Stack, Box } from '@mui/material';

import { dev } from 'utils/config';
import { withProps } from 'hooks';
import Icon from 'components/Icon';
import ButtonBase from 'components/ButtonBase';
import Fieldset from 'components/Dev/Fieldset';

const IconWrapper = withProps(Box, {
  component: 'span',
  fontSize: '16px',
  lineHeight: 0,
});

const getIconContent = (icon) => {
  if (!icon) {
    return null;
  }
  if (typeof icon === 'string') {
    return <Icon fontSize="inherit" name={icon} />;
  }
  return icon;
};

/**
 * Button
 * @param {string|ReactElement} [iconLeft] - Left icon (<Icon /> component "name" prop or React Element)
 * @param {string|ReactElement} [iconRight] - Right icon (<Icon /> component "name" prop or React Element)
 * Other params passes to <ButtonBase /> component
 */
const Button = forwardRef((props, ref) => {
  const {
    size,
    children,
    iconLeft,
    iconRight,
    color = 'primary',
    variant = 'contained',
    ...rest
  } = props;

  const iconLeftContent = useMemo(() => {
    return getIconContent(iconLeft);
  }, [iconLeft]);

  const iconRightContent = useMemo(() => {
    return getIconContent(iconRight);
  }, [iconRight]);

  return (
    <ButtonBase
      {...rest}
      ref={ref}
      size={size}
      color={color}
      variant={variant}
    >
      <Stack spacing={0} direction="row" alignItems="center">
        {iconLeftContent && (
          <IconWrapper>{iconLeftContent}</IconWrapper>
        )}

        <Typography
          variant="button2"
          sx={{
            pl: iconLeft ? 0.5 : 1,
            pr: iconRight ? 0.5 : 1,
          }}
        >
          {children}
        </Typography>

        {iconRightContent && (
          <IconWrapper>{iconRightContent}</IconWrapper>
        )}
      </Stack>
    </ButtonBase>
  );
});

if (dev) {
  const Demo = () => {
    const [size, setSize] = useState();
    const [variant, setVariant] = useState();
    const [disabled, setDisabled] = useState();
    const [iconLeft, setIconLeft] = useState();
    const [iconRight, setIconRight] = useState();

    return (
      <Box>
        <Box mb={2}>For the icon options you can use {`<Icon />`} component icon names (string), or pass your own icon as a React Component. All the other options the same as Material UI Button options.</Box>
        <Fieldset>
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'xsmall', 'small', 'medium', 'large']}
          />
          <Fieldset.Field
            legend="variant"
            value={variant}
            onChange={setVariant}
            options={[undefined, 'contained', 'outlined', 'text']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="iconLeft"
            value={iconLeft}
            onChange={setIconLeft}
            options={[undefined, 'Add', 'Student']}
          />
          <Fieldset.Field
            legend="iconRight"
            value={iconRight}
            onChange={setIconRight}
            options={[undefined, 'Add', 'Student']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Button\n`}
          {`  size="${size}"\n`}
          {`  iconLeft="${iconLeft}"\n`}
          {`  iconRight="${iconRight}"\n`}
          {`  variant="${variant}"\n`}
          {`  disabled={${disabled}}\n`}
          {`>\n`}
          {`  Button Text\n`}
          {`</Button>\n`}
        </Box>

        <Button
          size={size}
          iconLeft={iconLeft}
          iconRight={iconRight}
          variant={variant}
          disabled={disabled}
        >
          Button Text
        </Button>
      </Box>
    );
  };
  Button.Demo = Demo;
}

export default Button;
