import { forwardRef, useState } from 'react';
import { Box, Zoom, Tooltip as MuiTooltip, useTheme, styled, tooltipClasses } from '@mui/material';

import { dev } from 'utils/config';
import Center from 'components/Center';
import IconButton from 'components/IconButton';
import Fieldset from 'components/Dev/Fieldset';

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.body4,
    backgroundColor: theme.palette.other.tooltip,
    [`& .${tooltipClasses.arrow}`]: {
      width: '13px',
      height: '13px',
      color: theme.palette.other.tooltip,
      '&::before': {
        position: 'relative',
      },
    },
  },
  '&[data-popper-placement=bottom]': {
    [`& .${tooltipClasses.arrow}::before`]: {
      bottom: '3px',
      borderTopLeftRadius: '4px',
      transform: 'rotate(45deg) skew(15deg, 15deg)',
    },
  },
  '&[data-popper-placement=top]': {
    [`& .${tooltipClasses.arrow}::before`]: {
      bottom: '-3px',
      borderTopLeftRadius: '4px',
      transform: 'rotate(45deg) skew(15deg, 15deg)',
    },
  },
  '&[data-popper-placement=right]': {
    [`& .${tooltipClasses.arrow}::before`]: {
      left: '-3px',
      borderBottomLeftRadius: '4px',
      transform: 'rotate(45deg) skew(-15deg, -15deg)',
    },
  },
  '&[data-popper-placement=left]': {
    [`& .${tooltipClasses.arrow}::before`]: {
      left: '3px',
      borderBottomLeftRadius: '4px',
      transform: 'rotate(45deg) skew(-15deg, -15deg)',
    },
  },
}));

const Tooltip = forwardRef((props, ref) => {
  const {
    open,
    title,
    children,
    arrow = true,
    placement,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <StyledTooltip
      ref={ref}
      {...rest}
      open={open}
      arrow={arrow}
      title={title}
      placement={placement}
      TransitionComponent={Zoom}
      enterDelay={theme.transitions.duration.complex}
    >
      {children}
    </StyledTooltip>
  );
});

if (dev) {
  const Demo = (props) => {
    const { open } = props;

    const [placement, setPlacement] = useState();
    const [arrow, setArrow] = useState(true);

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="arrow"
            value={arrow}
            onChange={setArrow}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="placement"
            value={placement}
            onChange={setPlacement}
            options={[undefined, 'bottom', 'top', 'left', 'right']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Tooltip\n`}
          {`  arrow={${arrow}}\n`}
          {`  placement="${placement}"\n`}
          {`  title={\n`}
          {`    <Center flexDirection="column">\n`}
          {`      <div>Welcome!</div>\n`}
          {`      <div>This is a tooltip!</div>\n`}
          {`    </Center>\n`}
          {`  }\n`}
          {`>\n`}
          {`  <IconButton name="Student" />\n`}
          {`</Tooltip>\n`}
        </Box>

        <Box p={8} display="flex" gap={20}>
          <Tooltip
            open={open}
            arrow={arrow}
            placement={placement}
            title={
              <Center flexDirection="column">
                <div>Welcome!</div>
                <div>This is a tooltip!</div>
              </Center>
            }
          >
            <IconButton name="Student" />
          </Tooltip>

          <Tooltip
            arrow={arrow}
            placement={placement}
            title={
              <Center flexDirection="column">
                <div>Welcome!</div>
                <div>This is a tooltip!</div>
              </Center>
            }
          >
            <IconButton name="Birthday" />
          </Tooltip>
        </Box>
      </Box>
    );
  };
  Tooltip.Demo = Demo;
}

export default Tooltip;
