import Box from '@mui/material/Box';

const Fieldset = (props) => {
  const { children } = props;

  return (
    <Box display="flex" flexWrap="wrap" mb={4}>
      {children}
    </Box>
  );
};
Fieldset.Field = (props) => {
  const { options, legend, value, onChange } = props;

  return (
    <Box component="fieldset" display="flex" flexDirection="column">
      <legend>{legend}</legend>

      {options.map((opt) => (
        <Box key={String(opt)} component="label" mr={2}>
          <input
            type="radio"
            value={String(opt)}
            checked={opt === value}
            onChange={() => onChange(opt)}
          />
          <Box component="span" ml={1}>{String(opt)}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default Fieldset;
