import { dev } from 'utils/config';

import en from './en';
import sp from './sp';

const resources = { en, sp };

if (dev) {
  window.mknb = {
    ...window.mknb,
    i18n: { resources },
  };
}

export default resources;
