import { Box, styled } from '@mui/material';

import { Tags } from 'components';

const Root = styled(Box, { label: 'TagsBar-Root' })(({ theme }) => ({
  backgroundColor: theme.palette.other.white,
  borderRadius: theme.shape.borderRadius * 3,
  marginBottom: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'inline-flex',
}));

const TagsBar = (props) => {
  const { groups, ...rest } = props;

  return (
    <Root py={0.5} px={2} boxShadow="standard" {...rest}>
      {groups.map((group) => {
        const { title, tags, ...groupRest } = group;
        return (
          <Tags
            key={`tags-bar-${title}`}
            width="auto"
            getExtraTagProps={() => ({
              sx: {
                border: '1px solid',
                borderColor: 'controls.main',
                backgroundColor: 'input.main',
              },
            })}
            title={title}
            tags={tags}
            {...groupRest}
          />
        );
      })}
    </Root>
  );
};

export default TagsBar;
