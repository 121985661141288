import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { useAuth, useForm, useMessage } from 'hooks';
import {
  useUpdateAchievementsMutation,
  useUpdateNotificationPermissionsMutation,
  useGetMeQuery,
  useGetSpaceQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';

import { ROLE } from 'utils/constants';
import { getLanguage } from 'utils/helpers';

import { Paper, Container, Form } from 'components';

import StudentSettings from './StudentSettings';
import AdminSettings from './AdminSettings';
import ParentSettings from './ParentSettings';
import TeacherSettings from './TeacherSettings';

const PageTitle = styled(Box)({
  width: 216,
  flexShrink: 0,
});

const Settings = () => {
  const m = useMessage();
  const { t, i18n } = useTranslation('pages', { keyPrefix: 'settings' });
  const { auth } = useAuth();
  const { form } = useForm();

  const [updateAchievements, updateAchievementsState] = useUpdateAchievementsMutation();
  const [updateNotifications, updateNotificationsState] =
    useUpdateNotificationPermissionsMutation();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  useEffect(() => {
    if (!me) return;
    form.setValue('language', getLanguage());
    form.setValue('notifications', me.profile.notifications);
  }, [form, me]);

  const { data: space } = useGetSpaceQuery({ id: me?.space_id }, { skip: !Boolean(me?.space_id) });

  useEffect(() => {
    if (!space) return;
    form.setValue('achievements_enabled', space.achievements_enabled);
  }, [form, space]);

  const handleLanguageChange = async (v) => {
    i18n.changeLanguage(v);
  };

  const handleStudentAchievementsChange = async (e) => {
    if (!me?.space_id) return;

    try {
      const { status } = await updateAchievements({
        status: e.target.checked,
        space_id: me.space_id,
      }).unwrap();
      if (status === 'OK') {
        m.success(t('success.achievements_updated'));
      }
    } catch (e) {
      console.error(e);
      m.error(t('error.failed_update_achievements'));
    }
  };

  const handleNotificationsChange = async (e, name) => {
    if (!me?.id) return;

    const oldPermissions = form.getValues('notifications') || {};

    try {
      const { status } = await updateNotifications({
        notification_permissions: { ...oldPermissions, [name]: e.target.checked },
        user_id: me.id,
      }).unwrap();
      if (status === 'OK') {
        m.success(t('success.notifications_updated'));
      }
    } catch (e) {
      console.error(e);
      m.error(t('error.failed_update_notifications'));
    }
  };

  let SettingsComponent = StudentSettings;
  if (me?.role === ROLE.ADMIN) {
    SettingsComponent = AdminSettings;
  } else if (me?.role === ROLE.PARENT) {
    SettingsComponent = ParentSettings;
  } else if (me?.role === ROLE.TEACHER) {
    SettingsComponent = TeacherSettings;
  }

  const loading =
    isLoading ||
    isFetching ||
    updateAchievementsState.isLoading ||
    updateNotificationsState.isLoading;

  return (
    <ProjectLayout
      subheader={
        <PageTitle>
          <Typography variant="subtitle2">{t('subheader.title')}</Typography>
        </PageTitle>
      }
    >
      <Form form={form}>
        <Container>
          <Box py={4} width={1}>
            <Paper
              shadow="standard"
              loading={loading}
              overflow="visible"
              pt={3}
              pb={5}
              px={9}
              width={1}
            >
              <SettingsComponent
                onNotificationChange={handleNotificationsChange}
                onLanguageChange={handleLanguageChange}
                onAchievementsChange={handleStudentAchievementsChange}
              />
            </Paper>
          </Box>
        </Container>
      </Form>
    </ProjectLayout>
  );
};

export default Settings;
