import { createSelector, createSlice } from '@reduxjs/toolkit';

const name = 'notifications';

const initialState = {
  modal_open: false,
};

const selectRoot = (store) => store[name];

const selectIsNotificationsModalOpen = createSelector(selectRoot, (state) => state.modal_open);

const selectors = {
  selectIsNotificationsModalOpen,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    openNotificationsModal: (state) => {
      state.modal_open = true;
    },
    closeNotificationsModal: (state) => {
      state.modal_open = false;
    },
  },
});

slice.selectors = selectors;

export default slice;
