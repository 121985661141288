const source = {
  components: {
    FormLabel: {
      optional: 'optional',
    },
    Dropzone: {
      text: 'Drag & drop files or <1>Browse</1>',
      description: 'Supported formates',
      upload_new: 'Upload new',
    },
    Select: {
      placeholder: 'Select',
      empty: 'Nothing to select',
    },
    SelectMultiple: {
      placeholder: 'Select',
      empty: 'Nothing to select',
      select_option: 'Select an option',
      select_or_create: 'Select an option or create one',
      create: 'Create',
    },
    CopyToClipboard: {
      text_copied: 'Text copied to clipboard',
    },
    Comment: {
      reply: 'Reply',
      hide_replies: 'Hide all {{count}} replies',
      show_replies: 'Show all {{count}} replies',
      time_spent: '{{date_string}} ago',
      signup_information: 'To interact and leave comment, please sign up first.',
      signup: 'Sign up',
      compose: {
        send: 'Leave comment',
        cancel: 'Cancel',
        not_active: 'Not active',
        type: 'Type',
      },
    },
    Stepper: {
      step: 'Step {{index}}',
    },
    DayPicker: {
      days: {
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
      },
    },
    DayTimePicker: {
      days: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
      },
    },
    BirthdayInput: {
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        septemper: 'Septemper',
        august: 'August',
        october: 'October',
        november: 'November',
        december: 'December',
      },
    },
  },
  views: {
    header: {
      dashboard: 'Dashboard',
      ideas: 'Ideas',
      about: 'About',
      learning: 'Learning',
      portfolio: 'Portfolio',
      administration: 'Administration',
      knowledge_base: 'Knowledge Base',
      dev: '<Dev />',
      signup: 'Sign Up',
      login: 'Log in',
      signout: 'Sign out',
      profile: 'Profile',
      settings: 'Settings',
    },
    LanguageSelect: {
      options: {
        en: 'English',
        sp: 'Spanish',
      },
    },
    PasswordField: {
      generate_random: 'Generate random',
      validation_indicator: {
        length: '8 Characters minimum',
        number: 'One number',
        special: 'One special character',
        uppercase: 'One uppercase character',
      },
    },
    ProjectButton: {
      app_name: 'Maker Notebook',
    },
    TagsFilter: {
      title: 'Tag',
      search: 'Search',
    },
    UsersFilter: {
      title: 'Users',
      search: 'Search',
    },
    GroupsFilter: {
      title: 'Groups',
      search: 'Search',
    },
    LevelFilter: {
      title: 'Level',
    },
    RolesFilter: {
      title: 'Roles',
    },
    StatusFilter: {
      title: 'Account status',
    },
    FiltersBar: {
      clear_all: 'Clear all',
      title: {
        levels: 'Level:',
        tags: 'Related tags:',
        users: 'Users:',
        roles: 'Roles:',
        leaders: 'Leaders:',
        groups: 'Groups:',
        type: 'User type:',
        status: 'Account status:',
      },
    },
    KnowledgeCard: {
      comments_count: '{{count}} Comments',
      last_comment_date: 'Last comment {{date_string}} ago',
      tags: 'Tags:',
    },
    IdeaCard: {
      tags: 'Tags:',
    },
    PageSubheader: {
      back: 'Back',
    },
    ArticleGeneralInformation: {
      title: 'General information',
      level: 'Level:',
      related_tags: 'Related tags:',
      description: 'Description:',
      author_title: '{{name}} author:',
      creation_date: 'Creation date:',
      sources: 'Sources',
      link_copied: 'Link copied to clipboard!',
    },
    Repeater: {
      add_new: 'Add more',
    },
    IdeaForm: {
      name: {
        label: 'Idea name',
        placeholder: 'Type in',
      },
      photos: {
        label: 'Image',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Select option',
      },
      level: {
        label: 'Level',
        placeholder: 'Select option',
      },
      description: {
        title: 'Description',
        label: 'Description',
        placeholder: 'Text',
      },
      copyright: {
        label: 'Copyright',
        placeholder: 'e.g. Patricia',
      },
      sources: {
        label: 'Source of the idea',
        placeholder: 'e.g. links to videos, articles, websites',
      },
      public: {
        label: 'Make public',
      },
    },
    TipForm: {
      name: {
        label: 'Tip name',
        placeholder: 'Type in',
      },
      public: {
        label: 'Make public',
      },
      photos: {
        label: 'Image',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Select option',
      },
      description: {
        title: 'Description',
        label: 'Description',
        placeholder: 'Text',
      },
      copyright: {
        label: 'Copyright',
        placeholder: 'e.g. Patricia',
      },
      sources: {
        label: 'Source of a tip',
        placeholder: 'e.g. links to videos, articles, websites',
      },
    },
    ProjectInformationForm: {
      name: {
        label: 'Project name',
        placeholder: 'Type in',
      },
      photos: {
        label: 'Image',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Select option',
      },
      description: {
        title: 'Description',
        label: 'Description',
        placeholder: 'Text',
      },
      copyright: {
        label: 'Copyright',
        placeholder: 'e.g. Megan',
      },
      sources: {
        label: 'Source of an idea',
        placeholder: 'e.g. links to videos, articles, websites',
      },
      level: {
        label: 'Level',
        placeholder: 'Select option',
      },
    },
    CollectionInformationForm: {
      name: {
        label: 'Collection name',
        placeholder: 'Type in',
      },
      photos: {
        label: 'Image',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Select option',
      },
      description: {
        title: 'Description',
        label: 'Description',
        placeholder: 'Text',
      },
      level: {
        label: 'Level',
        placeholder: 'Select option',
      },
    },
    ProjectStepsForm: {
      add_new: 'Add more step',
      step: 'Step {{index}}',
      name: {
        label: 'Step name',
        placeholder: 'Type in',
      },
      photos: {
        label: 'Image',
      },
      description: {
        title: 'Description',
        label: 'Description',
        placeholder: 'Text',
      },
      sources: {
        label: 'Source of an idea',
        placeholder: 'e.g. links to videos, articles, websites',
      },
    },
    QuestionForm: {
      name: {
        label: 'Question',
        placeholder: 'Type in',
      },
      public: {
        label: 'Make public',
      },
      photos: {
        label: 'Image',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Select option',
      },
    },
    UserInformationForm: {
      groups_classes: 'Groups/Classes',
      groups: {
        label: 'Add user',
        placeholder: 'e.x groups/classes',
      },
      contact: {
        title: 'Contact',
      },
      first_name: {
        label: 'First Name',
        placeholder: 'e.x Emmett',
      },
      last_name: {
        label: 'Last Name',
        placeholder: 'e.x Brown',
      },
      avatar: {
        label: 'User photo',
      },
      email: {
        label: 'Email',
        placeholder: 'e.x firstname@jmail.com',
      },
      phone: {
        label: 'Phone',
        placeholder: 'e.x +995-123-4567',
      },
    },
    IndividualSettingsForm: {
      individual_permissions: 'Individual permissions',
      permissions: {
        create_user: 'Create new user',
        change_project_status: 'Change project status',
        create_ideas_collections_projects: 'Create ideas/collections/projects',
        edit_ideas_collections_projects: 'Edit ideas/collections/projects',
        delete_ideas_collections_projects: 'Delete ideas/collections/projects',
        assign_ideas_collections_projects: 'Assign collections/projects',
        create_portfolio: 'Create new portfolio',
        edit_own_portfolio: 'Edit own portfolio',
        edit_portfolio: 'Edit portfolio',
        delete_portfolio: 'Delete portfolio',
      },
      old_password: {
        label: 'User password',
        placeholder: 'Type here',
      },
      new_password: {
        label: 'New password',
        placeholder: 'Type here',
      },
    },
    Steps: {
      step: 'Step {{index}} - ',
      show_all: 'Show All Steps',
      hide_all: 'Hide All Steps',
    },
    QuickFilters: {
      apply: 'Apply',
      clear_all: 'Clear all',
      TagsFilter: {
        tags: 'Tags',
      },
      LevelFilter: {
        levels: 'Levels',
      },
    },
    ProjectCard: {
      tags: 'Tags:',
      assigned: 'Assigned:',
      assigned_students: '{{count}} students',
    },
    CardContent: {
      project_type: 'Project type',
      project_status: 'Project status',
      project_published: 'Project Published',
      includes_projects: 'Includes projects:',
      user: 'User:',
      role: 'Role:',
      email: 'Email:',
      groups: 'Groups:',
      role_student: 'Student',
      role_teacher: 'Teacher',
      role_parent: 'Parent',
      role_admin: 'Admin',
      role_personal: 'Individual maker',
      role_organization: 'Organization',
      location: 'Location:',
      members: 'Members:',
      registration_date: 'Registration date:',
      group_leader: 'Group leader:',
      students_in_group: 'Students in group:',
      tags: 'Tags:',
      projects: 'Projects:',
      schedule: {
        every: 'Every {{day}}',
        sunday: 'Sun',
        monday: 'Mon',
        tuesday: 'Tue',
        wednesday: 'Wed',
        thursday: 'Thu',
        friday: 'Fri',
        saturday: 'Sat',
      },
    },
    StudentWork: {
      student_work: 'Student Work',
      photos_of_process: 'Photos of the proccess',
      add_photos: 'Add photo of the process',
      add_video: 'Add student video',
    },
    Workflow: {
      workflow: 'Workflow',
    },
    AssignProjectModal: {
      choose_student: 'Choose student',
      assign_project: 'Assign this project',
      cancel: 'Cancel',
      search_student: 'Search for a student',
      search_group: 'Search for a group',
    },
    AssignCollectionModal: {
      choose_student: 'Choose student',
      assign_collection: 'Assign this collection',
      cancel: 'Cancel',
      search_student: 'Search for a student',
      search_group: 'Search for a group',
      choose_student_group: 'Choose students or groups',
    },
    AssignedStudents: {
      students_working_on_project: 'Students/groups working on this project:',
      filter_by: 'Filter By',
      project_status: 'Project status',
      user: 'User',
      show_more: 'Show more',
    },
    AddStudentVideoModal: {
      save: 'Save',
      cancel: 'Cancel',
      video: {
        label: 'Upload the project video or add a link to it',
        placeholder: 'Paste the video link here',
      },
    },
    AddProgressPhotosModal: {
      add_photos: 'Add photos of process!',
      name: {
        label: 'Title',
        placeholder: 'e.x Foggymegan',
      },
      description: {
        label: 'Description',
        placeholder: 'Text',
      },
      save: 'Save',
      cancel: 'Cancel',
    },
    StudentWorkModal: {
      add_photos: 'Add photos of process!',
      name: {
        label: 'Title',
        placeholder: 'e.x Foggymegan',
      },
      description: {
        label: 'Description',
        placeholder: 'Text',
      },
      save: 'Save',
      cancel: 'Cancel',
    },
    ProgressStatistics: {
      show_more: 'Show more',
    },
    ProjectSearch: {
      search_project: 'Search for a project',
      tags: 'Tags:',
      apply: 'Apply',
      select: 'Select',
      clear_all: 'Clear all',
      choose_max_projects: 'Choose a maximum of {{max}} projects',
      error: {
        max_projects: 'Choose a maximum of {{max}} projects',
      },
    },
    PortfolioEditProjectsForm: {
      error: {
        failed_upload_photo: 'Failed to upload the photo.',
      },
    },
    PortfolioGroupSearchForm: {
      search_groups: 'Search for a groups',
    },
    PortfolioPersonalInformation: {
      portfolio_details: 'Portfolio details',
      personal_information: 'Personal information',
      first_name: {
        label: 'First Name',
        placeholder: 'e.x Emmett',
      },
      last_name: {
        label: 'Last Name',
        placeholder: 'e.x Brown',
      },
      about_me: {
        label: 'About me',
        placeholder:
          'Write what you are interested in, and what hobbies you have. Write what you think is necessary.',
      },
      name: {
        label: 'Portfolio name',
        placeholder: 'Type in',
      },
      photo: {
        label: 'User photo',
      },
      public: {
        label: 'Make public',
      },
    },
    StudentPersonalInformationForm: {
      personal_information: 'Personal information',
      groups_classes: 'Groups/Classes',
      generate_random: 'Generate random',
      user_is_leader:
        'This user is the leader of the group. Please change the group leader and try again.',
      first_name: {
        label: 'First Name',
        placeholder: 'e.x Emmett',
      },
      last_name: {
        label: 'Last Name',
        placeholder: 'e.x Brown',
      },
      username: {
        label: 'Username',
        placeholder: 'Type in',
      },
      avatar_file_id: {
        label: 'User photo',
      },
      phone: {
        label: 'Phone',
        placeholder: 'e.x +995-123-4567',
      },
      birthday: {
        label: 'Birthday',
        day: 'DD',
        month: 'December',
        year: 'YYYY',
      },
      language: {
        label: 'Language',
        placeholder: 'Select language',
      },
      groups: {
        label: 'Add user to groups/classes',
        placeholder: 'e.x groups/classes',
      },
    },
    StudentGuardiansInformationForm: {
      contact_section: 'Contact',
      guardian_information: 'Personal / Guardian information',
      add_new: 'Add Parent/Guardian {{count}}',
      notes: 'Notes',
      address_section: 'Address',
      full_name: {
        label: 'Fullname',
        placeholder: 'e.x Jane/Joe Doe',
      },
      avatar: {
        label: 'User photo',
      },
      relation: {
        label: 'Relation to student',
        placeholder: 'Select',
      },
      phone_number: {
        label: 'Phone',
        placeholder: 'e.x +995-123-4567',
      },
      email: {
        label: 'Email',
        placeholder: 'Type in',
      },
      note: {
        label: 'Note',
        placeholder: 'Text',
      },
      address: {
        label: 'Address',
        placeholder: 'Example: 2972 Westheimer Rd.',
      },
      apart: {
        label: 'Apart, suite,etc.',
        placeholder: 'e.x 3rd floor',
      },
      city: {
        label: 'City',
        placeholder: 'e.x Madrid',
      },
      country: {
        label: 'Country',
        placeholder: 'Select country',
      },
      state: {
        label: 'State / Province',
        placeholder: 'Select state',
      },
      zipcode: {
        placeholder: 'Zip / postal code',
      },
      is_emergency_contact: {
        placeholder: 'Appoint as an emergency contact',
      },
      notify_projects: {
        placeholder: 'Send notification of completed projects ',
      },
    },
    StudentHealthDetailsForm: {
      emergency_contact: 'Emergency Contact {{count}}',
      notes: 'Notes',
      allergies: 'Allergies',
      food_allergies: {
        label: 'Food allergies',
        placeholder: 'Text',
      },
      drugs_allergies: {
        label: 'Drag allergies',
        placeholder: 'Text',
      },
      emergency_contact_name: {
        label: 'Name surname',
        placeholder: 'e.x Jane/Joe Doe',
      },
      emergency_contact_phone: {
        label: 'Phone',
        placeholder: 'Type in',
      },
      note: {
        label: 'Note',
        placeholder: 'Text',
      },
    },
    StudentSettingsForm: {
      password_section: 'Password',
      individual_permissions: 'Individual permissions',
      password: {
        label: 'User password',
        placeholder: 'Type in',
      },
      permissions: {
        create_project: 'Create new project',
        edit_ideas: 'Edit own ideas',
        delete_ideas: 'Delete own ideas',
        edit_password: 'Change password',
        change_project_status: 'Change project status',
        create_projects_from_ideas: 'Create projects from ideas',
        edit_delete_ideas: 'Edit/delete own ideas',
        create_ideas: 'Create new ideas',
        create_portfolio: 'Create new portfolio',
        edit_own_portfolio: 'Edit own portfolio',
        delete_own_portfolio: 'Delete own portfolio',
        edit_portfolio: 'Edit portfolio',
        delete_portfolio: 'Delete portfolio',
        edit_own_projects: 'Delete own projects',
        request_project: 'Request project',
      },
    },
    GroupForm: {
      group_information: 'Group information',
      place_of_gathering: 'Place of gathering',
      description: 'Description',
      add_students: 'Add students to the group',
      choose_group: 'Choose a group leader',
      name: {
        label: 'Group name',
        placeholder: 'Type in',
      },
      group_description: {
        label: 'Group Description',
        placeholder: 'Text',
      },
      address: {
        label: 'Address',
        placeholder: 'Type in',
      },
      leader_id: {
        label: 'Group leader',
        placeholder: 'e.x Jone/Jane Doe',
      },
      participants: {
        label: 'Add student',
        placeholder: 'e.x Jone/Jane Doe',
      },
      day_and_time: {
        label: 'Day and time',
      },
    },
    MetaData: {
      every: 'Every',
      at: 'at',
    },
    NotificationsModal: {
      notifications: 'Notifications',
      empty: "You don't have any notifications",
      clear_all: 'Clear all',
      mark_read: 'Mark as read',
      mark_unread: 'Mark as unread',
      view_all: 'View all notifications',
      error: {
        notification_status_change: 'Failed to change notification status.',
      },
    },
  },
  helpers: {
    alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    validators: {
      required: 'This field is required!',
      password: {
        length: 'Must contain 8 characters minimum',
        number: 'Must contain at least one number',
        specials: 'Must contain at least one special character',
        uppercase: 'Must contain at least one uppercase character',
      },
      description: {
        length: 'Valid description must be from 3 to 500 characters long',
        specials:
          'Valid description can only contain alphanumeric characters, underscores, periods and dashes',
      },
      phone: {
        wrong_phone_number: 'Wrong phone number',
      },
      username: 'Bad username (email or phone number with 12 digits)',
      email: 'Wrong email format',
      birthday: 'Wrong birthday date',
      oneOfRequired: 'One of these fields is required: {{names}}',
      startsWithUppercase: '{{name}} must begin with an uppercase letter',
    },
  },
  hooks: {
    use_bookmark_idea: {
      bookmarked: 'Idea added to favorites.',
      removed: 'Idea removed from favorites.',
      failed: 'Failed to add an idea to favorites.',
    },
    auth: {
      error_409: 'User not found',
      error_401: 'Login or password is invalid',
    },
    upload_photos: {
      error_photos_upload: 'Failed to upload photos.',
    },
    get_profile: {
      failed: 'Failed to load a profile.',
    },
    get_portfolio_creation_options: {
      from_group_projects: 'From group projects',
      from_student_projects: "From students' projects",
      from_own_projects: 'From own projects',
    },
  },
  layouts: {},
  pages: {
    settings: {
      notifications: 'Notifications',
      individual_permissions: 'Individual permissions',
      interface: 'Interface',
      subheader: {
        title: 'Settings',
      },
      language: {
        label: 'Language',
        placeholder: 'Select language',
      },
      permissions: {
        student_achievements: 'Achievements for students',
      },
      notification_permissions: {
        new_user: 'New user',
        new_group: 'New group',
        new_project: 'New project',
        new_collection: 'New collection',
        new_portfolio: 'New portfolio',
        project_status_update: 'Change of project status',
        project_assigned_to: 'A new project was assigned to a user',
        reply_to_comment: 'Reply to comments',
        new_idea: 'New idea',
        new_project_photo: 'A new photo was added to the student project',
        shared_portfolio: 'Portfolio was shared',
        collection_completed: 'Collection completed',
        project_request: 'Project request',
        user_added_to_group: 'A user was added to a group',
        group_leader: 'A user was appointed as a group leader',
        project_kid_assign: 'A new project was assigned to a kid.',
        collection_assign: 'A new collection was assigned to a user',
        project_publish: 'Project status changes to published',
      },
      success: {
        achievements_updated: 'Achievements for students successfully updated!',
        notifications_updated: 'Notification permissions successfully updated!',
        language_updated: 'Language successfully updated!',
      },
      error: {
        failed_update_achievements: 'Failed to update achievements for students.',
        failed_update_notifications: 'Failed to update notification permissions.',
        failed_update_language: 'Failed to update a language.',
      },
    },
    dashboard: {
      students_statistic: 'Students statistic',
      my_statistic: 'My statistic',
      projects: 'Projects',
      show_more: 'Show more',
      search_user: 'Search user',
      empty_projects: 'No projects found.',
      average_time_spent_on_project: 'Average time spent on project',
      average_time_spent_on_collection: 'Average time spent on collection',
      subheader: {
        title: 'Dashboard',
      },
      filters: {
        filter_by: 'Filter by',
        status: 'Status',
        group: 'Group',
      },
      experience: {
        title: 'Experience',
        level: '{{level}} level',
        points_to_next_level: 'Earn {{points}} experience points to advance another level',
        points_earned: 'Experience points earned',
        tabs: {
          today: 'Today',
          week: '7 days',
          month: '30 days',
          year: 'Year',
        },
      },
    },
    home: {
      fancy_circle: {
        alt: 'Fancy circle',
      },
      loop: {
        alt: 'Loop image',
      },
      project_screenshot: {
        glass: {
          alt: 'Glass',
        },
        screenshot: {
          alt: 'Project screenshot',
        },
      },
      line: {
        alt: 'Steps connection line',
      },
      section_1: {
        join_for_free: 'Join for free',
        title: {
          a_place_for_those: 'A place for those',
          who_create_and_teach: 'who create and teach to create',
          description:
            "Maker Notebook is a free online platform for makers and those who teach how to make. It is ideal for those who want to monitor their progress or their students' progress.",
        },
      },
      section_2: {
        card_1: {
          title: 'For teachers',
          content:
            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
        },
        card_2: {
          title: 'For individual makers',
          content:
            'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
        },
      },
      section_3: {
        title: 'Block with video',
      },
      section_4: {
        card: {
          caption: 'What can you do here?',
          title: 'Get inspired by ideas or create your own',
          content:
            'Ideas is a place where you can find inspiration while browsing ideas for your future projects. Or you can add your ideas here, whatever inspires you.',
          actions: {
            see_all_ideas: 'See all ideas',
          },
        },
        ideas_example: {
          alt: 'Ideas example',
        },
      },
      section_5: {
        card: {
          title: 'Turn ideas into projects',
          content:
            'A project is something you or your students will be working on or are already working on.',
        },
        image: {
          alt: 'Projects example',
        },
      },
      section_6: {
        card: {
          title: 'Combine projects into collections',
          content:
            'If you notice that you have projects with a similar theme, you can combine them into collections and learn a skill faster.',
        },
        image: {
          alt: 'Collections example',
        },
      },
      section_7: {
        card: {
          title: 'Add completed projects to your portfolio',
          content:
            'Build a digital portfolio that you can share with your friends, teachers that showcases who you are, the skills you’ve developed and what you are able to do!',
        },
        image: {
          alt: 'Portfolio example',
        },
      },
      section_8: {
        card: {
          title: 'Track statistics',
          content: 'You can track your statistics or the statistics of your students.',
        },
        image: {
          alt: 'Statistics example',
        },
      },
      section_9: {
        card: {
          title: 'Get experience points for your activity',
          content:
            'You or your students can earn extra points for being active. It can become your additional motivation or just entertainment.',
        },
        image: {
          alt: 'Expirience example',
        },
      },
      footer: {
        image: {
          alt: 'Layers',
        },
        start_your_journey: 'Start your maker journey!',
        join_makernotebook: 'Join maker notebook',
        about: 'About',
        ideas: 'Ideas',
        knowledge_base: 'Knowledge Base',
      },
    },
    knowledge_base: {
      tips: 'Tips',
      questions: 'Questions',
      subheader: {
        title: 'Knowledge',
        page_search: 'Search for a tip/question',
        create_new: 'Create new',
        create_tip: 'Create tip',
        create_question: 'Create question',
      },
      signup_information: {
        signup: 'Sign up',
        text: 'To create a  new Tip / Question, please sign up first.',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
    },
    ideas: {
      subheader: {
        title: 'Ideas',
        page_search: 'Search for an idea',
        create_new: 'Create new idea',
      },
      signup_information: {
        signup: 'Sign up',
        text: 'To create a new idea, please sign up first.',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
    },
    learning: {
      projects: 'Projects',
      collections: 'Collections',
      my_projects: 'My projects',
      all_projects: 'All projects',
      my_collections: 'My collections',
      all_collections: 'All collections',
      subheader: {
        title: 'Learning',
        page_search: 'Search for a project/collection',
        create_new: 'Create',
        create_project: 'Create project',
        create_collection: 'Create collection',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
      statistics: {
        assigned: 'Assigned',
        in_progress: 'In progress',
        completed: 'Completed',
        abandoned: 'Abandoned',
        published: 'Published',
      },
    },
    new_project: {
      create_project: 'Create project',
      choose_idea_title: 'First, choose an idea that inspired you!',
      search_idea: 'Search for an idea',
      back_button: 'Back to Projects',
      error_failed_create: 'Failed to create a new project.',
      page_information_title: "Let's add project information!",
      page_steps_title: 'Cool! you can add additional steps to the project',
      tabs: {
        project_information: 'Project information',
        project_steps: 'Project steps',
      },
      page_actions: {
        previous_step: 'Previous step',
        create: 'Create project',
        from_scratch: 'From scratch',
        from_idea: 'From idea',
        next_step: 'Next step',
      },
      collection: {
        label: 'Add to collection',
        placeholder: 'Select option',
      },
    },
    edit_project: {
      back_button: 'Back to Projects',
      error_failed_update: 'Failed to update the project.',
      success_update: 'Project successfully updated.',
      page_title: "Let's edit the project!",
      page_actions: {
        cancel: 'Cancel',
        edit: 'Edit project',
      },
      tabs: {
        project_information: 'Project information',
        project_steps: 'Project steps',
      },
      form_title: 'Edit Project',
    },
    new_collection: {
      back_button: 'Back to Collections',
      collection_created_without_projects:
        "Couldn't update projects, collection id: {{collection_id}}",
      error_failed_create: 'Failed to create a new collection.',
      page_information_title: "Let's create a new collection!",
      page_projects_title: 'Choose the projects that will be included in the collection',
      search_project: 'Projects included to collection',
      tags: 'Tags:',
      tabs: {
        collection_information: 'Collection information',
        projects: 'Projects',
      },
      page_actions: {
        previous_step: 'Previous step',
        next_step: 'Next step',
        create: 'Create collection',
      },
    },
    edit_collection: {
      back_button: 'Back to Collections',
      error_failed_update: 'Failed to update the collection.',
      success_update: 'Collection successfully updated.',
      page_title: "Let's edit the collection!",
      search_project: 'Projects included to collection',
      page_actions: {
        cancel: 'Cancel',
        edit_information: 'Edit collection',
        edit_projects: 'Edit projects',
      },
      tabs: {
        collection_information: 'Collection information',
        projects: 'Projects',
      },
    },
    new_idea: {
      back_button: 'Back to Ideas',
      error_failed_create: 'Failed to create a new idea.',
      page_title: "Let's create a new idea!",
      page_actions: {
        cancel: 'Cancel',
        create: 'Create idea',
      },
      form_title: 'New Idea',
    },
    edit_idea: {
      back_button: 'Back to Idea',
      error_failed_update: 'Failed to update the idea.',
      success_update: 'Idea successfully updated.',
      page_title: "Let's edit the idea!",
      page_actions: {
        cancel: 'Cancel',
        edit: 'Edit idea',
      },
      form_title: 'Edit Idea',
    },
    new_tip: {
      back_button: 'Back to Tips',
      error_409: 'The tip already exist.',
      error_failed_create: 'Failed to create a new tip.',
      page_title: "Let's create a new tip!",
      page_actions: {
        cancel: 'Cancel',
        create: 'Create tip',
      },
      form_title: 'New Tip',
    },
    edit_tip: {
      back_button: 'Back to Tips',
      error_failed_update: 'Failed to update the tip.',
      success_update: 'Tip successfully updated.',
      page_title: "Let's edit the tip!",
      page_actions: {
        cancel: 'Cancel',
        edit: 'Edit tip',
      },
      form_title: 'Edit Tip',
    },
    new_question: {
      back_button: 'Back to Questions',
      page_title: "Let's create a new question",
      page_actions: {
        cancel: 'Cancel',
        create: 'Create question',
      },
      form_title: 'New Question',
      error_409: 'Failed to create question',
      error_failed_create: 'Failed to create a new question.',
    },
    edit_question: {
      back_button: 'Back to Questions',
      page_title: "Let's edit the question!",
      page_actions: {
        cancel: 'Cancel',
        edit: 'Edit question',
      },
      form_title: 'Edit Question',
      error_failed_update: 'Failed to update the question.',
      success_update: 'Question successfully updated.',
    },
    single_tip: {
      ask_delete_permission: 'Are you sure you want to delete this tip?',
      back_button: 'Back to Tips',
      gallery: 'Gallery',
      page_actions: {
        edit: 'Edit',
        delete: 'Delete',
      },
      error: {
        failed_delete: 'Failed to delete tip',
      },
    },
    single_project: {
      ask_delete_permission: 'Are you sure you want to delete this project?',
      back_button: 'Back to Projects',
      project_steps: 'Project Steps',
      gallery: 'Gallery',
      project_assigned: 'Project successfully assigned.',
      success_add_photo: 'Photo of the process successfully added!',
      success_update_photo: 'Photo of the process successfully updated!',
      success_delete_photo: 'Photo of the process successfully deleted!',
      success_add_video: 'Project video successfully added!',
      success_status_update: 'Project status successfully updated!',
      student_work: 'Student Work',
      photos_of_process: 'Photos of the proccess',
      project_type: 'Project type:',
      request_project: {
        submit: 'Request project',
        cancel: 'Cancel',
        choose_user: 'Choose user',
        error: 'Failed to request a project',
        success_message:
          'Your request has been sent if you do not receive a response within 24 h, you can resend your request.',
      },
      progress_update: {
        back: 'Back to the project',
        add_more: 'Add more photos',
        or: 'or',
        experience_earned: 'Pefectly done! experience earned',
        exp_change: '+{{points}} xp',
      },
      page_actions: {
        edit: 'Edit',
        delete: 'Delete',
        work_on_project: 'Work on this project',
        project_assigned: 'Project assigned',
        assign_project: 'Assign this project',
        request_project: 'Request this project',
        project_requested: 'Project requested',
      },
      error: {
        failed_delete: 'Failed to delete project.',
        failed_assign_project: 'Failed to assign project.',
        failed_add_photo: 'Failed to add photo of the process.',
        failed_upload_photo: 'Failed to upload the photo.',
        failed_add_video: 'Failed to add project video',
      },
    },
    single_idea: {
      ask_delete_permission: 'Are you sure you want to delete this idea?',
      back_button: 'Back to Ideas',
      gallery: 'Gallery',
      publish_success: 'Idea successfully published!',
      related_projects: 'Project Inspired By The Idea',
      page_actions: {
        edit: 'Edit',
        delete: 'Delete',
        block: 'Block idea',
        make_public: 'Make it public',
        hide_idea: 'Hide idea',
        create_project: 'Create project from idea',
      },
      error: {
        publish_failed: 'Failed to publish idea.',
        failed_delete: 'Failed to delete idea',
      },
      block_idea: {
        banned_words: 'Banned words found!',
        block: 'Block this idea',
        back: 'Go back',
      },
    },
    single_question: {
      ask_delete_permission: 'Are you sure you want to delete this question?',
      back_button: 'Back to Questions',
      comments: 'Comments',
      gallery: 'Gallery',
      error: {
        failed_create_comment: 'Failed to create comment',
        failed_delete: 'Failed to delete question',
      },
    },
    single_collection: {
      ask_delete_permission: 'Are you sure you want to delete this collection?',
      back_button: 'Back to Collections',
      general_information: 'General information',
      description: 'Description',
      owner: 'Collection owner:',
      progress: 'Progress:',
      date_created: 'Creation date:',
      related_projects: 'Projects included in this collection:',
      level: 'Level:',
      related_tags: 'Related tags:',
      students_working_on_project: 'Students/groups working on this collection:',
      filter_by: 'Filter By',
      user: 'User',
      group: 'Group',
      collection_assigned: 'Collection has been assigned successfully!',

      page_actions: {
        work_on_collection: 'Work on this collection',
        collection_assigned: 'Collection assigned',
        assign_collection: 'Assign this collection',
        request_collection: 'Request this collection',
        collection_requested: 'Collection requested',
      },

      error: {
        failed_delete: 'Failed to delete a collection.',
        failed_assign_collection: 'Failed to assign collection.',
      },

      request_collection: {
        submit: 'Request collection',
        cancel: 'Cancel',
        choose_user: 'Choose user',
        error: 'Failed to request a collection',
        success_message:
          'Your request has been sent if you do not receive a response within 24 h, you can resend your request.',
      },
    },
    not_found: {
      page_not_found: 'Page Not Found',
      back_to_site: 'Back to site',
    },
    login: {
      title_image: {
        alt: 'Notebook',
      },
      welcome: 'Welcome to Maker Notebook space!',
      email: {
        label: 'Email / Username',
        placeholder: 'e.x namesurname1',
      },
      password: {
        label: 'Password',
        placeholder: 'Type here',
      },
      verify_your_phone: 'Please <1>verify your phone number</1> before login',
      forgot_password: 'Forgot your password?',
      dont_have_account: "Don't have an account?",
      signup: 'Sign up',
      submit: 'Submit',
      error_403: 'This user is not verified yet.',
    },
    forgot_password: {
      title_image: {
        alt: 'Forgot password',
      },
      forgot_password: 'Forgot your password?',
      select_method: 'Select the password reset method that works best for you',
      check: 'Check your {{method}}!',
      success_message:
        'If your {{method}} exists in our database, you will receive a password recovery link at your {{method}} in a few minutes.',
      via_sms: 'Via sms',
      via_mail: 'Via mail',
      cancel: 'Cancel',
      back: 'Back',
      sms: {
        title: 'Enter your login phone number below. We will send you a recovery code.',
        phone_number: {
          placeholder: '+-5 (-3) 5789',
        },
        cancel: 'Cancel',
        send: 'Send',
      },
      mail: {
        title:
          'Enter your login email below. We will send you an email with a link to reset your account password.',
        email: {
          placeholder: 'namesurname@gmail.com',
        },
        cancel: 'Cancel',
        send: 'Send',
        success:
          'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.',
      },
      method: {
        phone: 'phone number',
        email: 'email address',
      },
      error: {
        fetch_error: 'Something went wrong, please try again later.',
        wrong: 'User with provided {{method}} does not exist',
      },
    },
    reset_password: {
      title: 'Enter your new password',
      update: 'Update',
      password: {
        label: 'Password',
        placeholder: 'Type here',
      },
      bad_link: 'The link you use to reset your password is broken or overdue',
      success: 'Your password has been changed successfully!',
    },
    login_from_invitation: {
      title: 'Welcome to Maker Notebook space!',
      login: 'Log in',
      password: {
        label: 'Password',
        placeholder: 'Type here',
      },
      language: {
        label: 'Language',
        placeholder: 'Select language',
      },
      something_went_wrong: 'Something went wrong, please try again later.',
      success: 'Your password has been changed successfully!',
    },
    signup: {
      title_image: {
        alt: 'Notebook',
      },
      welcome: 'Welcome to Maker Notebook space!',
      start_journey: 'Start your Maker journey in 2 easy steps.',
      have_an_account: 'Already have an account?',
      login: 'Log in',
      step1: {
        type: {
          personal: {
            label: 'Individual maker',
          },
          organization: {
            label: 'Organization',
          },
        },
        email: {
          label: 'Email',
          placeholder: 'e.x firstname@jmail.com',
        },
        phone: {
          label: 'Phone',
          placeholder: 'e.x +995-123-4567',
        },
        password: {
          label: 'Password',
          placeholder: 'Type here',
        },
        agree_tos: {
          label: 'I agree to the <1>Terms of Service</1> and the <3>Privacy Policy</3>',
        },
        next: 'Next',
      },
      step2: {
        avatar: {
          label: 'Profile picture',
        },
        first_name: {
          label: 'First Name',
          placeholder: 'e.x Emmett',
        },
        last_name: {
          label: 'Last Name',
          placeholder: 'e.x Brown',
        },
        organization: {
          label: 'Organization name',
          placeholder: 'e.x Makers',
        },
        language: {
          label: 'Language',
        },
        cancel: 'Back',
        submit: 'Sign Up',
      },
      step3: {
        check_your_email: 'Done! Check your email or phone number to continue.',
      },
      error_unknown: 'Something went wrong. Cannot register space...',
      error_409: 'User already exists',
      error_avatar_upload: 'Cannot upload the avatar.',
    },
    email_verification: {
      title: 'Email verification',
      please_wait: 'Please wait, verification in progress...',
      done: 'Done! Now you can <1>login</1>.',
      error: 'Bad link or no hash provided. Please, follow email instructions.',
    },
    phone_verification: {
      title: 'Phone verification',
      enter_verification_code:
        'We sent you 6-digit verification code on your phone. Please enter it below to confirm your phone number.',
      verify: 'Verify',
      verify_error: 'Bad verification code. Try again.',
      done: 'Done! Now you can <1>login</1>.',
    },
    profile: {
      page_title: 'Profile',
      tabs: {
        user_information: 'User information',
        portfolio: 'Portfolio',
      },
      settings: {
        page_title: "Let's edit individual settings!",
        back_title: 'Back to Profile',
        update_success: 'Settings successfully updated.',
        update_failed: 'Failed to update user settings.',
        password: 'Password',
        page_actions: {
          cancel: 'Cancel',
          save: 'Save changes',
        },
      },
      user: {
        page_title: "Let's edit user information!",
        back_title: 'Back to Profile',
        personal_information: 'Personal information',
        individual_permission: 'Individual permission',
        role: 'Role:',
        groups: 'Groups:',
        update_success: 'Successfully updated user information.',
        update_failed: 'Failed to update user information.',
        permissions: {
          create_user: 'Create new user',
          change_project_status: 'Change project status',
          create_ideas_collections_projects: 'Create ideas/collections/projects',
          edit_ideas_collections_projects: 'Edit ideas/collections/projects',
          delete_ideas_collections_projects: 'Delete ideas/collections/projects',
          assign_ideas_collections_projects: 'Assign collections/projects',
          create_project: 'Create new project',
          edit_ideas: 'Edit own ideas',
          delete_ideas: 'Delete own ideas',
          edit_password: 'Change password',
          create_projects_from_ideas: 'Create projects from ideas',
          edit_delete_ideas: 'Edit/delete own ideas',
          create_ideas: 'Create new ideas',
          create_portfolio: 'Create new portfolio',
          edit_own_portfolio: 'Edit own portfolio',
          edit_portfolio: 'Edit portfolio',
          delete_portfolio: 'Delete portfolio',
          delete_own_portfolio: 'Delete own portfolio',
          edit_own_projects: 'Delete own projects',
          request_project: 'Request project',
        },
        page_actions: {
          cancel: 'Cancel',
          save: 'Save changes',
        },
        roles: {
          admin: 'Admin',
          parent: 'Parent',
          student: 'Student',
          teacher: 'Teacher',
        },
      },
      portfolio: {
        from_group_projects: 'From group projects',
        from_student_projects: "From students' projects",
        from_own_projects: 'From own projects',
        create_portfolio: 'Create portfolio',
        empty_text: "You don't have a portfolio yet",
      },
    },
    new_portfolio: {
      back_button: 'Back to Portfolio',
      page_groups_title: 'Choose the groups whose projects you want to add to the portfolio',
      page_projects_title: 'Choose group projects you want to have in your portfolio',
      page_edit_projects_title:
        'Perfectly! Here you can edit information about projects that will be in the portfolio.',
      page_about_me_title: "Almost finished! Now it's time to add some information about you.",
      error_failed_create: 'Failed to create a new portfolio.',
      page_actions: {
        previous_step: 'Previous step',
        next_step: 'Next step',
        create: 'Create portfolio',
      },
      steps: {
        groups: 'Groups',
        projects: 'Projects',
        edit_projects: 'Projects editing',
        about_me: 'About you',
      },
    },
    edit_portfolio: {
      back_button: 'Back to portfolio',
      page_groups_title: 'Choose the groups whose projects you want to add to the portfolio',
      page_projects_title: 'Choose group projects you want to have in your portfolio',
      page_edit_projects_title:
        'Perfectly! Here you can edit information about projects that will be in the portfolio.',
      page_about_me_title: "Almost finished! Now it's time to add some information about you.",
      error_failed_update: 'Failed to update the portfolio.',
      success_update: 'Portfolio successfully updated.',
      page_actions: {
        previous_step: 'Previous step',
        next_step: 'Next step',
        cancel: 'Cancel',
        edit: 'Edit portfolio',
      },
      steps: {
        groups: 'Groups',
        projects: 'Projects',
        edit_projects: 'Projects editing',
        about_me: 'About you',
      },
    },
    single_portfolio: {
      about_me: 'About me',
      back_button: 'Back to Portfolio',
      ask_delete_permission: 'Are you sure you want to delete this portfolio?',
      my_projects: 'My Projects',
      page_actions: {
        share_portfolio: 'Share portfolio',
      },
      share_portfolio: {
        submit: 'Share',
        cancel: 'Cancel',
        copy_link: 'Copy link',
        link_copied: 'Link copied to clipboard!',
        choose_user: 'Choose user',
        error: 'Failed to share a portfolio.',
        success_message: 'You have successfuly shared a portfolio.',
      },
    },
    portfolio_list: {
      subheader: {
        title: 'Portfolio',
        page_search: 'Search for a porfolio',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
      create_portfolio: 'New portfolio',
      from_group_projects: 'From group projects',
      from_student_projects: "From students' projects",
      from_own_projects: 'From own projects',
    },
    new_student: {
      page_actions: {
        back_button: 'Back to Users',
        next_step: 'Next step',
        previous_step: 'Previous step',
        create: 'Create new user',
      },
      steps: {
        personal_information: 'Student information',
        parents_information: 'Parents information',
        health_details: 'Health details',
        settings: 'Settings permissions',
      },
      page_title: {
        personal_information: "Let's add student information!",
        parents_information: 'Perfect! Now fill in the information about the parents/guardians',
        health_details: "Almost finished! It's time to fill out health information",
        settings: 'And the final step!',
      },
      error: {
        failed_update_user: 'Failed to update a user',
        failed_create_parent: 'Failed to create a new parent - {{name}}.',
        failed_create_user: 'Failed to create a new user',
        failed_update_health_details: 'Failed to update health details.',
      },
    },
    edit_student: {
      page_actions: {
        back_button: 'Back to User',
        next_step: 'Next step',
        previous_step: 'Previous step',
        edit: 'Save changes',
      },
      steps: {
        personal_information: 'Student information',
        parents_information: 'Parents information',
        health_details: 'Health details',
        settings: 'Settings permissions',
      },
      page_title: {
        personal_information: "Let's edit student information!",
        parents_information: 'Perfect! Now fill in the information about the parents/guardians',
        health_details: "Almost finished! It's time to fill out health information",
        settings: 'And the final step!',
      },
      success: {
        update: 'Student successfully updated.',
      },
      error: {
        failed_create_parent: 'Failed to create a new parent - {{name}}.',
        failed_update_user: 'Failed to update a user',
        failed_update_user_permissions: 'Failed to update users permissions',
        failed_update_health_details: 'Failed to update health details.',
      },
    },
    new_parent: {
      page_title: "Let's add parent information!",
      contact_section: 'Contact',
      guardian_information: 'Personal information',
      add_new: 'Add Parent/Guardian {{count}}',
      notes: 'Notes',
      address_section: 'Address',
      error: {
        failed_create_parent: 'Failed to create a parent.',
      },
      page_actions: {
        back_button: 'Back to Users',
        cancel: 'Cancel',
        create: 'Create new user',
      },
      full_name: {
        label: 'Fullname',
        placeholder: 'e.x Jane/Joe Doe',
      },
      avatar: {
        label: 'User photo',
      },
      relation: {
        label: 'Relation to student',
        placeholder: 'Role',
      },
      related_to: {
        placeholder: 'Related to',
      },
      phone_number: {
        label: 'Phone',
        placeholder: 'e.x +995-123-4567',
      },
      email: {
        label: 'Email',
        placeholder: 'Type in',
      },
      note: {
        label: 'Note',
        placeholder: 'Text',
      },
      address: {
        label: 'Address',
        placeholder: 'Example: 2972 Westheimer Rd.',
      },
      apart: {
        label: 'Apart, suite,etc.',
        placeholder: 'e.x 3rd floor',
      },
      city: {
        label: 'City',
        placeholder: 'e.x Madrid',
      },
      country: {
        label: 'Country',
        placeholder: 'Select country',
      },
      state: {
        label: 'State / Province',
        placeholder: 'Select state',
      },
      zipcode: {
        placeholder: 'Zip / postal code',
      },
      is_emergency_contact: {
        placeholder: 'Appoint as an emergency contact',
      },
      notify_projects: {
        placeholder: 'Send notification of completed projects ',
      },
    },
    edit_parent: {
      page_title: "Let's edit parent information!",
      contact_section: 'Contact',
      guardian_information: 'Personal information',
      notes: 'Notes',
      address_section: 'Address',
      error: {
        failed_update_parent: 'Failed to update a parent.',
      },
      page_actions: {
        back_button: 'Back to User',
        cancel: 'Cancel',
        edit: 'Save changes',
      },
      full_name: {
        label: 'Fullname',
        placeholder: 'e.x Jane/Joe Doe',
      },
      avatar: {
        label: 'User photo',
      },
      phone_number: {
        label: 'Phone',
        placeholder: 'e.x +995-123-4567',
      },
      email: {
        label: 'Email',
        placeholder: 'Type in',
      },
      note: {
        label: 'Note',
        placeholder: 'Text',
      },
      address: {
        label: 'Address',
        placeholder: 'Example: 2972 Westheimer Rd.',
      },
      apart: {
        label: 'Apart, suite,etc.',
        placeholder: 'e.x 3rd floor',
      },
      city: {
        label: 'City',
        placeholder: 'e.x Madrid',
      },
      country: {
        label: 'Country',
        placeholder: 'Select country',
      },
      state: {
        label: 'State / Province',
        placeholder: 'Select state',
      },
      zipcode: {
        placeholder: 'Zip / postal code',
      },
    },
    new_user: {
      back_button: 'Back to Users',
      choose_role: 'First, choose a user role',
      page_actions: {
        create_student: 'Student',
        create_parent: 'Parent',
        create_teacher: 'Teacher',
        create_admin: 'Admin',
      },
    },
    new_teacher: {
      back_button: 'Back to Users',
      personal_information: 'Personal information',

      error: {
        failed_create_teacher: 'Failed to create a teacher.',
      },

      tabs: {
        information: 'Teacher information',
        settings: 'Individual settings',
      },

      page_actions: {
        cancel: 'Cancel',
        create: 'Create new user',
      },

      page_title: {
        information: "Let's add teacher information!",
        settings: 'And the final step!',
      },
    },
    edit_teacher: {
      back_button: 'Back to User',
      personal_information: 'Personal information',

      error: {
        failed_update_teacher: 'Failed to update a teacher.',
      },

      tabs: {
        information: 'Teacher information',
        settings: 'Individual settings',
      },

      page_actions: {
        cancel: 'Cancel',
        edit: 'Save changes',
      },

      page_title: {
        information: "Let's edit teacher information!",
        settings: 'And the final step!',
      },
    },
    new_admin: {
      back_button: 'Back to Users',
      personal_information: 'Personal information',
      password: 'Password',

      error: {
        failed_create_admin: 'Failed to create an admin.',
      },

      tabs: {
        information: 'Admin information',
        settings: 'Individual settings',
      },

      page_actions: {
        cancel: 'Cancel',
        create: 'Create new user',
      },

      page_title: {
        information: "Let's add admin information!",
        settings: 'And the final step!',
      },
    },
    edit_admin: {
      back_button: 'Back to User',
      personal_information: 'Personal information',
      password: 'Password',
      success_update: 'Admin successfully updated.',

      error: {
        failed_update_admin: 'Failed to update an admin.',
      },

      tabs: {
        information: 'Admin information',
        settings: 'Individual settings',
      },

      page_actions: {
        cancel: 'Cancel',
        edit: 'Save changes',
      },

      page_title: {
        information: "Let's edit admin information!",
        settings: 'And the final step!',
      },
    },
    administration: {
      users: 'Users',
      groups: 'Groups',
      spaces: 'Admin accounts',

      subheader: {
        title: 'Administration',
        page_search: 'Search for a user or group',
        create_new: 'Create',
        create_user: 'Create user',
        create_group: 'Create group',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
      statistics: {
        assigned: 'Assigned',
        in_progress: 'In progress',
        completed: 'Completed',
      },
      roles: {
        student: 'Student',
        teacher: 'Teacher',
        parent: 'Parent',
        admin: 'Admin',
        organization: 'Organization',
        personal: 'Individual maker',
      },
    },
    space: {
      ask_delete_permission: 'Are you sure you want to delete this space?',
      personal_information: 'Personal information',
      back_button: 'Back to platform users',
      tabs: {
        ideas: 'Ideas',
        projects: 'Projects',
        collections: 'Collections',
      },
      page_actions: {
        block_account: 'Block account',
      },
      subheader: {
        page_search: 'Start searching...',
      },
      sidebar: {
        filter_by: 'Filter by',
      },
      error: {
        failed_delete: 'Failed to delete the space',
      },
    },
    user_profile: {
      back_title: 'Back to Users',
      personal_information: 'Personal information',
      parents: 'Parents/Guardians ',
      health_details: 'Student Health Details',
      emergency_contacts: 'Emergency contacts',
      allergies: 'Allergies',
      drugs: 'Drugs:',
      food: 'Food:',
      individual_permissions: 'Individual Permission',
      role: 'Role: ',
      groups: 'Groups: ',
      password: 'Password',
      average_time_spent_on_project: 'Average time spent on project',
      average_time_spent_on_collection: 'Average time spent on collection',
      project_status: 'Project status',
      sidebar: {
        filter_by: 'Filter by',
      },
      tabs: {
        profile: 'Profile',
        projects: 'Projects',
        portfolio: 'Portfolio',
      },
      roles: {
        student: 'Student',
        teacher: 'Teacher',
        parent: 'Parent',
        admin: 'Admin',
      },
      permissions: {
        create_project: 'Create new project',
        edit_ideas: 'Edit own ideas',
        delete_ideas: 'Delete own ideas',
        edit_password: 'Change password',
        change_project_status: 'Change project status',
        create_projects_from_ideas: 'Create projects from ideas',
        edit_delete_ideas: 'Edit/delete own ideas',
        create_ideas: 'Create new ideas',
        create_portfolio: 'Create new portfolio',
        edit_own_portfolio: 'Edit own portfolio',
        delete_own_portfolio: 'Delete own portfolio',
        edit_portfolio: 'Edit portfolio',
        delete_portfolio: 'Delete portfolio',
        edit_own_projects: 'Delete own projects',
        request_project: 'Request project',
      },
      ParentCard: {
        phone: 'Phone:',
        location: 'Location:',
        email: 'Email:',
      },
      statistics: {
        assigned: 'Assigned Projects',
        progress: 'Projects in progress',
        completed: 'Completed projects',
        published: 'Published projects',
        abandoned: 'Abandoned projects',
      },
      top_10_tags: {
        title: 'Top 10 tags used by this user',
        description:
          'Based on topics, ideas, projects, and collections choosen by the students or assigned to them.',
      },
    },
    user_portfolio: {
      back_title: 'Back to Users',
      from_group_projects: 'From group projects',
      create_portfolio: 'Create portfolio',
      empty_text: "User don't have a portfolio yet",
    },
    new_group: {
      back_button: 'Back to Groups',
      page_title: "Let's add group information!",

      error: {
        failed_create_group: 'Failed to create a group.',
      },

      page_actions: {
        cancel: 'Cancel',
        create: 'Create new group',
      },
    },
    edit_group: {
      back_button: 'Back to Group',
      page_title: 'Edit group information!',
      success_update: 'Group successfully updated.',
      error_failed_update: 'Failed to update the group.',

      page_actions: {
        cancel: 'Cancel',
        edit: 'Save changes',
      },
      error: {
        failed_edit_group: 'Failed to edit the group.',
      },
    },
    single_group: {
      back_button: 'Back to Groups',
      general_information: 'General information',
      students: 'Students',
      students_search: 'Search for a student',
      date_and_time: 'Date and time:',
      location: 'Location:',
      description: 'Description',
      portfolio_empty_text: "Group doesn't have a portfolio yet",
      create_portfolio: 'Create new portfolio',
      project_status: 'Project status',
      average_time_spent_on_project: 'Average time spent on project',
      average_time_spent_on_collection: 'Average time spent on collection',
      tabs: {
        information: 'Group information',
        projects: 'Projects',
        portfolio: 'Portfolio',
      },
      tags: {
        group_leader: 'Group leader:',
        students: 'Students:',
      },
      statistics: {
        assigned: 'Assigned Projects',
        progress: 'Projects in progress',
        completed: 'Completed projects',
        published: 'Published projects',
        abandoned: 'Abandoned projects',
      },
      top_10_tags: {
        title: 'Top 10 tags used by this user',
        description:
          'Based on topics, ideas, projects, and collections choosen by the students or assigned to them.',
      },
    },
  },
};

export default source;
