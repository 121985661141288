import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography, Collapse } from '@mui/material';

import {
  useGetMeQuery,
  useGetPortfolioQuery,
  useUpdatePortfolioMutation,
  useUploadTemporaryFileMutation,
  useGetUserProjectsQuery,
} from 'store/api';
import { useForm, useMessage, useAuth, useUploadPortfolioAvatars } from 'hooks';

import { ProjectLayout } from 'layouts';
import {
  PageSubheader,
  PortfolioGroupProjectSearchForm,
  PortfolioPersonalInformationForm,
  PortfolioEditProjectsForm,
} from 'views';
import { Container, Paper, Stepper } from 'components';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

import mapRequest from './mapRequest';
import mapInitialData from './mapInitialData';

const contentWidth = 936;

const FORMS = {
  GROUPS_SEARCH: 'groups',
  PROJECTS_SEARCH: 'projects',
  PROJECTS_EDIT: 'edit_projects',
  ABOUT_ME: 'about_me',
};

const STEPS = [{ id: FORMS.PROJECTS_SEARCH }, { id: FORMS.PROJECTS_EDIT }, { id: FORMS.ABOUT_ME }];

const EditPortfolio = (props) => {
  const { id } = useParams();
  const { backUrl = '/portfolio' } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'edit_portfolio' });
  const { auth, progress } = useAuth();
  const m = useMessage();

  const { form: projectSearchForm, valid: projectSearchFormValid } = useForm();
  const { form: projectsEditForm, valid: projectsEditFormValid } = useForm();
  const { form: aboutMeForm, valid: aboutMeFormValid } = useForm();

  const [step, setStep] = useState(0);
  const [groupTab, setGroupTab] = useState(null);

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();
  const [uploadProjectAvatars, uploadProjectAvatarsState] = useUploadPortfolioAvatars();
  const [updatePortfolio, updatePortfolioState] = useUpdatePortfolioMutation();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: portfolio,
    isLoading: portfolioLoading,
    isFetching: portfolioFetching,
  } = useGetPortfolioQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const { data: projects } = useGetUserProjectsQuery(
    {
      user_id: portfolio?.user,
      status: ['Published'],
      collections: [],
      limit: 100,
      offset: 0,
    },
    { skip: !portfolio?.user },
  );

  useEffect(() => {
    if (!portfolio && !projects) return;

    const { about, groups } = mapInitialData({ portfolio, projects, isIndividual: true });
    aboutMeForm.reset(about);
    projectSearchForm.reset({ groups });
    setGroupTab(groups[0]);
  }, [portfolio, projects, projectSearchForm, aboutMeForm]);

  const handleUpdatePortfolio = useCallback(
    async (formData) => {
      try {
        const postData = mapRequest(
          {
            about: formData,
            groups: projectsEditForm.getValues().groups,
            me,
          },
          true,
        );

        const { photo } = formData;
        const [file] = photo || [];

        if (file instanceof File) {
          const { file_id } = await uploadFile({ file }).unwrap();
          postData.photo = file_id;
        }

        postData.project_cards = await uploadProjectAvatars(postData.project_cards);

        const { status } = await updatePortfolio({ id, ...postData }).unwrap();
        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        console.error(err);
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_update'));
        }
      }
    },
    [id, me, uploadFile, uploadProjectAvatars, projectsEditForm, updatePortfolio, m, t],
  );

  const isLastStep = STEPS.length - 1 === step;
  const loading =
    portfolioFetching ||
    progress ||
    portfolioLoading ||
    portfolioLoading ||
    uploadFileState.isLoading ||
    uploadProjectAvatarsState.isLoading ||
    updatePortfolioState.isLoading;

  const submitProjectsSearch = useCallback(
    (formData) => {
      projectsEditForm.setValue(
        'groups',
        formData.groups.map((group) => {
          return {
            ...group,
            selected: group.selected.map((project) => ({
              ...project,
              description:
                typeof project.description === 'string'
                  ? getInitialTextEditorState(project.description)
                  : project.description,
            })),
          };
        }),
      );
      setGroupTab(formData.groups[0]);
      setStep((curr) => curr + 1);
    },
    [setStep, setGroupTab, projectsEditForm],
  );

  const submitProjectsEdit = useCallback(() => {
    setStep((curr) => curr + 1);
  }, [setStep]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        const submitFn = [submitProjectsSearch, submitProjectsEdit, handleUpdatePortfolio][step];

        if (!submitFn) return;

        submitFn(formData);
      } catch (err) {}
    },
    [step, submitProjectsSearch, submitProjectsEdit, handleUpdatePortfolio],
  );

  const stepsWithLabels = useMemo(
    () => STEPS.map((s) => ({ ...s, label: t(`steps.${s.id}`) })),
    [t],
  );

  const isValidStep = {
    [FORMS.PROJECTS_SEARCH]: projectSearchFormValid,
    [FORMS.PROJECTS_EDIT]: projectsEditFormValid,
    [FORMS.ABOUT_ME]: aboutMeFormValid,
  }[STEPS[step]?.id];

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={backUrl}
          backTitle={t('back_button')}
          pageActions={{
            [t('page_actions.previous_step')]: {
              type: 'button',
              disabled: loading || step === 0,
              variant: 'outlined',
              size: 'small',
              iconLeft: 'ArrowLeft',
              radius: 2,
              onClick: () => setStep(step - 1),
            },
            ...(isLastStep
              ? {
                  [t('page_actions.edit')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    form: STEPS[step]?.id,
                  },
                }
              : {
                  [t('page_actions.next_step')]: {
                    type: 'submit',
                    disabled: loading || !isValidStep,
                    size: 'small',
                    radius: 2,
                    iconRight: 'ArrowRight',
                    form: STEPS[step]?.id,
                  },
                }),
          }}
        />
      }
    >
      <Container width={contentWidth} py={4} position="relative">
        <Stepper steps={stepsWithLabels} currentStep={step} />

        {STEPS[step] && (
          <Typography width="100%" component="h3" mb={2} color="secondary.main" variant="subtitle3">
            {t(`page_${STEPS[step].id}_title`)}
          </Typography>
        )}

        <Collapse sx={{ width: '100%' }} in={step === 0}>
          <PortfolioGroupProjectSearchForm
            id={FORMS.PROJECTS_SEARCH}
            form={projectSearchForm}
            onSubmit={handleSubmit}
            activeGroup={groupTab}
          />
        </Collapse>
        <Collapse sx={{ width: '100%' }} in={step === 1}>
          <PortfolioEditProjectsForm
            id={FORMS.PROJECTS_EDIT}
            form={projectsEditForm}
            onSubmit={handleSubmit}
            activeGroup={groupTab}
          />
        </Collapse>
        <Collapse sx={{ width: '100%' }} in={step === 2}>
          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <PortfolioPersonalInformationForm
              id={FORMS.ABOUT_ME}
              form={aboutMeForm}
              onSubmit={handleSubmit}
              visible={step === 2}
            />
          </Paper>
        </Collapse>
      </Container>
    </ProjectLayout>
  );
};

export default EditPortfolio;
