import { darken } from '@mui/material/styles';

import { STATUS } from 'utils/constants';

import * as colors from './colors';

// Material UI dependency
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const makeScroll = (w) => ({
  '&:not(body)::-webkit-scrollbar': {
    width: `${w}px`,
    maxHeight: `${w}px`,
    borderRadius: `${w}px`,
    backgroundColor: colors.primary_background,
  },
  '&:not(body)::-webkit-scrollbar:hover': {
    backgroundColor: darken(colors.primary_background, 0.1),
  },
  '&:not(body)::-webkit-scrollbar-track': {
    borderRadius: `${w}px`,
    backgroundColor: colors.primary_light,
  },
  '&:not(body)::-webkit-scrollbar-thumb': {
    borderRadius: `${w}px`,
    backgroundColor: colors.primary_default,
  },
  '&:not(body)::-webkit-scrollbar-thumb:hover': {
    backgroundColor: darken(colors.primary_default, 0.1),
  },
});

/**
 * Makes border dash array styles with ability to configure parameters note presented in css.
 * Returned string must be applied to 'border-image' property (!).
 * @param {number[]} [dash=[2, 2]] - Pattern of dash array
 * @param {number} [width=1] - Border width.
 * @param {string} [color='#000000'] - Border color.
 * @param {string} [cap='square'] - SVG stroke-linecap property.
 * @param {number} [radius] - Border radius
 */
export const makeSvgDashBorder = (props) => {
  const { dash = [2, 2], width = 1, color = '#000000', cap = 'square', radius } = props;

  const borderColor = color.replace('#', '%23');

  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${radius}' ry='${radius}' stroke='${borderColor}' stroke-width='${width}' stroke-dasharray='${dash.join(
    '%2c ',
  )}' stroke-dashoffset='0' stroke-linecap='${cap}'/%3e%3c/svg%3e");`;
};

export const getStatusStyles = ({ status, border }) => {
  switch (status) {
    case STATUS.COMPLETED:
      return {
        border: border && '1px solid',
        borderColor: border && 'primary.light',
        color: 'success.dark',
        backgroundColor: 'success.contrastText',
      };
    case STATUS.ACTIVE:
      return {
        border: border && '1px solid',
        borderColor: border && 'primary.light',
        color: 'success.dark',
        backgroundColor: 'success.contrastText',
      };
    case STATUS.IN_PROGRESS:
      return {
        border: border && '1px solid',
        borderColor: border && 'warning.dark',
        color: 'warning.dark',
        backgroundColor: 'warning.contrastText',
      };
    case STATUS.PENDING:
      return {
        border: border && '1px solid',
        borderColor: border && 'controls.main',
        color: 'secondary.contrastText',
        backgroundColor: 'input.main',
      };
    case STATUS.BLOCKED:
      return {
        border: border && '1px solid',
        borderColor: border && 'error.light',
        color: 'error.dark',
        backgroundColor: 'error.contrastText',
      };
    default:
      return {};
  }
};
