import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, useTheme } from '@mui/material';

import { capitalize, getArray } from 'utils/helpers';
import { useForm } from 'hooks';

import { Icon, PaperCard } from 'components';
import { CardContent } from 'views';

import FilterForm from './FilterForm';

const AssignedStudents = (props) => {
  const { students, ...rest } = props;

  const theme = useTheme();
  const { form } = useForm();
  const watchStudent = form.watch('student');
  const watchStatus = form.watch('status');

  const { t } = useTranslation('views', { keyPrefix: 'AssignedStudents' });

  const filteredStudents = useMemo(() => {
    if (!watchStudent && !watchStatus) return students;

    return students.filter((student) => {
      const selectedStudent = watchStudent && student.id === watchStudent;
      const selectedStatus = watchStatus && student.project_status === watchStatus;

      if (!watchStudent) return selectedStatus;
      if (!watchStatus) return selectedStudent;
      return selectedStatus && selectedStudent;
    });
  }, [watchStudent, watchStatus, students]);

  const formOptions = useMemo(() => {
    if (!students) return {};

    const usersById = {};
    const statuses = [];

    for (let i = 0; i < students.length; i++) {
      const student = students[i];

      if (!statuses.includes(student.project_status)) {
        statuses.push(student.project_status);
      }

      if (!usersById[student.id]) {
        usersById[student.id] = {
          label: student.name,
          value: student.id,
          avatar: student.avatar,
        };
      }
    }

    return {
      statuses: statuses.map((status) => ({ value: status, label: status })),
      users: Object.values(usersById),
    };
  }, [students]);

  return (
    <Box component="section" {...rest}>
      <Typography variant="subtitle3" component="h3" mb={1}>
        {t('students_working_on_project')}
      </Typography>

      <FilterForm formOptions={formOptions} form={form} />

      <Grid container spacing={3}>
        {getArray(filteredStudents).map((student) => (
          <Grid item xs={6} key={student.id}>
            <PaperCard>
              <CardContent
                photo={student.avatar}
                title={student.name}
                status={{
                  id: student.project_status,
                  name: student.project_status,
                  pl: 1,
                  color: 'warning.dark',
                  bgcolor: theme.palette.warning.background,
                  startAdornment: (
                    <Box
                      sx={{
                        borderRadius: '50%',
                        width: 8,
                        height: 8,
                        backgroundColor: 'currentColor',
                      }}
                    />
                  ),
                }}
                type={{
                  id: student.project_type,
                  name: capitalize(student.project_type),
                  color: 'textRegular.light',
                  pl: 1,
                  startAdornment: (
                    <Icon name={student.project_type === 'individual' ? 'User' : 'Group'} />
                  ),
                }}
                variant="project_student"
              />
            </PaperCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AssignedStudents;
