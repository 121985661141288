import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { useGetCollectionsQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';

import { CardContent } from 'views';
import { ProgressBox, PaperCard, Pagination } from 'components';

import { mapProjectsRequest } from './mappers';

const { selectPageFilters, selectPagination } = selectors;

const Collections = (props) => {
  const { spaceId } = props;

  const { auth, progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const { data, isLoading, isFetching } = useGetCollectionsQuery(
    mapProjectsRequest({ spaceId, filters: pageFilters, pagination }),
    {
      skip: (auth && !spaceId) || progress,
    },
  );

  useEffect(() => {
    if (!data?.filter_data) return;
    onSetFilter('levels', FILTER_TYPE.LEVEL_FILTER, {
      levels: data.filter_data.levels.map((level) => ({
        ...level,
        id: level.name,
        name: capitalize(level.name),
      })),
    });
    onSetFilter('tags', FILTER_TYPE.TAG_FILTER, { tags: data.filter_data.tags });
  }, [data, onSetFilter]);

  const loading = isLoading || isFetching || progress;

  return (
    <>
      <ProgressBox loading={loading} mt={3} minHeight={400}>
        <Grid container spacing={3}>
          {getArray(data?.collections).map((collection) => (
            <Grid key={`collections-grid-${collection.id}`} item xs={4}>
              <PaperCard actionAreaStyle={{ p: 0 }} to={`/learning/collections/${collection.id}`}>
                <CardContent
                  variant="collection"
                  id={collection.id}
                  title={collection.name}
                  tags={collection.tags}
                  avatar={collection.avatar}
                  projectsQty={collection.projects_qty}
                  level={collection.level}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </ProgressBox>
      <Pagination
        total={data?.collections_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default Collections;
