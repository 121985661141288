import { getArray, editorStateToRawHtml } from 'utils/helpers';

export const mapInformationRequest = (formData) => {
  const { name, tags, level, photos, description } = formData;

  return {
    name: name,
    photos: photos || [],
    level: level,
    description: editorStateToRawHtml(description) || undefined,
    tags: getArray(tags).map((tag) => tag.value),
  };
};

export const mapProjectsRequest = (formData) => {
  const { projects } = formData;

  return {
    projects: getArray(projects).map((project) => project.id),
  };
};
