import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, styled } from '@mui/material';

import { getArray } from 'utils/helpers';
import { Paper, Center, Tag, PaperCard, Button } from 'components';

const StatisticsItemRoot = styled(Center)(({ theme }) => ({
  boxShadow: theme.shadows.small,
  minHeight: '40px',
  borderRadius: theme.shape.borderRadius * 2,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  '& .MuiTypography-subtitle3': {
    lineHeight: '40px',
  },
}));

export const StatisticsItem = (props) => {
  const { count, label, ...rest } = props;
  return (
    <StatisticsItemRoot justifyContent="flex-start" {...rest}>
      <Typography variant="subtitle3">{count || 0}</Typography>
      <Typography variant="body4" ml={1}>
        {label}
      </Typography>
    </StatisticsItemRoot>
  );
};

export const ItemCard = (props) => {
  const { data } = props;
  return (
    <PaperCard to={data.to} border size="small">
      <Box
        minHeight={65}
        height={1}
        width={1}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          color="textBlack.dark"
          component="div"
          variant="body2"
          mb={1}
          width={1}
        >
          {data.name}
        </Typography>
        <Tag
          label={data?.owner?.name}
          avatar={data?.owner?.avatar}
          avatarSize={24}
          bgcolor="input.main"
        />
      </Box>
    </PaperCard>
  );
};

const colorByStatus = {
  Assigned: 'secondary.light',
  'In Progress': 'warning.dark',
  Published: 'success.dark',
};

const ProjectStatistics = (props) => {
  const {
    title,
    statistics,
    status,
    statuses,
    projects,
    total = 0,
    perPage = 1,
    page = 1,
    loading,
    onLoadMore,
    ...rest
  } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  return (
    <Paper width={1} shadow="small" border overflow="visible" loading={loading} {...rest}>
      <Typography color="textBlack.dark" variant="subtitle5">
        {title}
      </Typography>

      <Grid container mt={1.5} columnSpacing={3}>
        {getArray(statuses).map(({ name, qty }) => (
          <Grid item xs={4} key={`statistic-item-${name}`}>
            <StatisticsItem count={qty} label={name} color={colorByStatus[name]} />
          </Grid>
        ))}
      </Grid>
      <Box width={1}>
        {projects?.length > 0 ? (
          <Grid container mt={0} columnSpacing={3} rowSpacing={2}>
            {getArray(projects).map((item) => (
              <Grid item xs={4} key={item.id}>
                <ItemCard data={item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>
            <Typography variant="body3" color="textBlack.light">
              {t('empty_projects')}
            </Typography>
          </Box>
        )}
        {page * perPage < total && (
          <Box textAlign="center" mt={2}>
            <Button type="button" size="small" variant="outlined" onClick={onLoadMore}>
              {t('show_more')}
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default ProjectStatistics;
