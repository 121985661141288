import { forwardRef, useState } from 'react';
import { Box, styled } from '@mui/material';

import { dev } from 'utils/config';
import ProgressBox from 'components/ProgressBox';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(ProgressBox, {
  label: 'Paper-Root',
  shouldForwardProp: (prop) => !['radius', 'shadow'].includes(prop),
})(({ theme, radius, shadow, bgcolor }) => ({
  minHeight: '60px',
  position: 'relative',
  background: !bgcolor ? theme.palette.other.white : undefined,
  boxShadow: shadow && theme.shadows[shadow],
  borderRadius: theme.shape.borderRadius * radius,
  transition: theme.transitions.create(['all'], {
    duration: theme.transitions.duration.complex,
  }),
}));

/**
 * Wrapper that looks like a card or a piece of paper.
 * @prop {number} [p=2] - Padding.
 * @prop {number} [radius=4] - Border radius.
 * @prop {string|null} [shadow="dark"] - Shadow type (one of theme.shadow names or null).
 * Rest of props will be passed to the Mui Box component.
 */
const Paper = forwardRef((props, ref) => {
  const {
    p = 2,
    shadow = 'dark',
    children,
    radius = 4,
    width,
    ...rest
  } = props;

  return (
    <Root
      p={p}
      ref={ref}
      radius={radius}
      shadow={shadow}
      // Setting maxWidth makes container responsive
      width={width ? '100%' : undefined}
      maxWidth={width}
      display="inline-flex"
      flexDirection="column"
      alignItems="flex-start"
      {...rest}
    >
      {children}
    </Root>
  )
});

if (dev) {
  const Demo = () => {
    const [p, setP] = useState();
    const [loading, setLoading] = useState();
    const [radius, setRadius] = useState();
    const [shadow, setShadow] = useState();

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="loading"
            value={loading}
            onChange={setLoading}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="p"
            value={p}
            onChange={setP}
            options={[undefined, 1, 2, 3, 5, 10]}
          />
          <Fieldset.Field
            legend="radius"
            value={radius}
            onChange={setRadius}
            options={[undefined, 1, 2, 3, 5, 10]}
          />
          <Fieldset.Field
            legend="shadow"
            value={shadow}
            onChange={setShadow}
            options={[undefined, 'dark', 'smooth', 'standard', 'small', 'big']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Paper\n`}
          {`  p={${p}}\n`}
          {`  radius={${radius}}\n`}
          {`  shadow="${shadow}"\n`}
          {`  loading="${loading}"\n`}
          {`>\n`}
          {`  Paper text\n`}
          {`</Paper>\n`}
        </Box>

        <Paper p={p} radius={radius} shadow={shadow} loading={loading}>
          Paper text
        </Paper>
      </Box>
    );
  };
  Paper.Demo = Demo;
};

export default Paper;
