import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Collapse, Box } from '@mui/material';

import { useForm, useMessage, useAuth, useUploadPhotos, useCreateTag } from 'hooks';
import { getArray, capitalize } from 'utils/helpers';
import {
  useGetMeQuery,
  useCreateProjectMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
  useGetIdeaQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper, Tabs, Button, Center, FormRow } from 'components';
import { PageSubheader, ProjectInformationForm, ProjectStepsForm, CollectionSelect } from 'views';
import { getLevelStyles } from 'views/LevelTag';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

import IdeaSearch from './IdeaSearch';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };
const defaultStepValue = (idx) => ({
  step_number: idx,
  name: '',
  photos: [],
  sources: [{ ...defaultSourceValue }],
});

const mapInitialData = (idea) => {
  if (!idea) {
    return {
      sources: [defaultSourceValue],
    };
  }

  return {
    idea_id: idea.id,
    name: idea.name,
    copyright: idea.copyright,
    level: idea.level,
    public: idea.public,
    description: getInitialTextEditorState(idea.description),
    sources: idea.sources.map((source) => ({ url: source.link })),
    tags: idea.tags.map((tag) => ({ value: tag.id, label: tag.name })),
    photos: idea.photos.map((photo) => ({ url: photo })),
    steps: [defaultStepValue(0)],
  };
};

const NewProject = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_project' });
  const { state, pathname } = useLocation();
  const { auth, progress } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();
  const navigate = useNavigate();

  const [tab, setTab] = useState('information');
  const [step, setStep] = useState(1);
  const [selectedIdea, setSelectedIdea] = useState(null);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery();

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const {
    data: idea,
    isLoading: ideaLoading,
    isFetching: ideaFetching,
  } = useGetIdeaQuery(
    {
      id: state?.idea_id,
    },
    { skip: !Boolean(state?.idea_id) || state?.source !== 'idea' },
  );

  useEffect(() => {
    if (!idea) return;
    form.reset(mapInitialData(idea));
    setStep(3);
    setSelectedIdea(idea);
  }, [idea, form]);

  useEffect(() => {
    function resetState() {
      window.history.replaceState({}, document.title);
    }
    window.addEventListener('beforeunload', () => resetState);
    return () => {
      resetState();
      window.removeEventListener('beforeunload', resetState);
    };
  }, []);

  const [createTag, createTagState] = useCreateTag({ me });
  const [createProject, createProjectState] = useCreateProjectMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  const uploadProjectPhotos = useCallback(
    async (postData) => {
      try {
        const { photos, steps } = postData;

        let projectPhotoIds = [];
        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;
          projectPhotoIds = photoIds;
        }

        const promises = steps.map(async (step, index) => {
          if (step?.photos?.length === 0) return step;
          const photoIds = await uploadPhotos(`steps[${index}].photos`, photos);
          return { ...step, photos: photoIds };
        });

        const stepsWithPhotoIds = await Promise.all(promises);

        return {
          ...postData,
          photos: projectPhotoIds,
          steps: stepsWithPhotoIds,
        };
      } catch (err) {
        console.error(err);
        m.error(t('error_failed_upload_photos'));
        return postData;
      }
    },
    [uploadPhotos, m, t],
  );

  const handleCreateProject = useCallback(
    async (formData) => {
      try {
        const postData = mapRequest(formData);
        const postDataWithPhotoIds = await uploadProjectPhotos(postData);

        const { project_id } = await createProject({
          id: me.space_id,
          ...postDataWithPhotoIds,
        }).unwrap();
        if (project_id) navigate(`/learning/projects/${project_id}`);
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_create'));
        }
      }
    },
    [createProject, m, uploadProjectPhotos, me, t, navigate],
  );

  const handleIdeaSelect = useCallback(() => {
    form.reset(mapInitialData(selectedIdea));
    navigate(pathname, { state: { source: 'idea', idea_id: selectedIdea?.id } });
    setStep(3);
  }, [selectedIdea, setStep, form, navigate, pathname]);

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const tabOptions = useMemo(
    () => [
      {
        label: t('tabs.project_information'),
        value: 'information',
      },
      {
        label: t('tabs.project_steps'),
        value: 'steps',
      },
    ],
    [t],
  );

  const loading =
    isLoading ||
    isFetching ||
    ideaLoading ||
    ideaFetching ||
    progress ||
    uploadPhotosState.isLoading ||
    createProjectState.isLoading;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleCreateProject}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/learning/projects"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.previous_step')]: {
                type: 'button',
                disabled: loading || step === 1,
                variant: 'outlined',
                size: 'small',
                onClick: () => {
                  if (state?.source === 'scratch') return setStep(1);
                  setStep(step - 1);
                },
              },
              ...(step === 3
                ? {
                    [t('page_actions.create')]: {
                      type: 'submit',
                      size: 'small',
                      disabled: !valid || loading,
                    },
                  }
                : {
                    [t('page_actions.next_step')]: {
                      type: 'submit',
                      size: 'small',
                      disabled: !selectedIdea?.id || loading,
                      onClick: handleIdeaSelect,
                    },
                  }),
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Collapse in={step === 1}>
            <Typography
              width="100%"
              component="h3"
              mb={2}
              color="secondary.main"
              variant="subtitle3"
            >
              {t('create_project')}
            </Typography>

            <Paper width="100%" overflow="visible" pt={4} pb={4} px={9} loading={loading}>
              <Center width="100%" flexDirection="column">
                <Box mb={2}>
                  <Button
                    size="large"
                    width={216}
                    iconLeft="Edit"
                    variant="outlined"
                    onClick={() => {
                      setStep(3);
                      navigate(pathname, { state: { source: 'scratch' } });
                    }}
                  >
                    {t('page_actions.from_scratch')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="large"
                    width={216}
                    iconLeft="Idea"
                    variant="outlined"
                    onClick={() => {
                      setStep(2);
                      navigate(pathname, { state: { source: 'idea' } });
                    }}
                  >
                    {t('page_actions.from_idea')}
                  </Button>
                </Box>
              </Center>
            </Paper>
          </Collapse>
          <Collapse in={step === 2}>
            <IdeaSearch
              selectedIdea={selectedIdea}
              disabled={step !== 2}
              tabOptions={tabOptions}
              onSelect={setSelectedIdea}
            />
          </Collapse>
          <Collapse in={step === 3}>
            <Typography
              width="100%"
              component="h3"
              mb={2}
              color="secondary.main"
              variant="subtitle3"
            >
              {tab === 'information' ? t('page_information_title') : t('page_steps_title')}
            </Typography>

            <Tabs
              mb={2}
              width={396}
              variant="fullWidth"
              value={tab}
              onValue={setTab}
              options={tabOptions}
            />

            <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
              {tab === 'information' && (
                <ProjectInformationForm
                  rules={rules}
                  loadingTags={loadingTags}
                  tagsOptions={tagsOptions}
                  levelOptions={levelOptions}
                  loadingLevels={loadingLevels}
                  defaultSourceValue={defaultSourceValue}
                  onCreateTag={createTag}
                  extraFields={
                    <>
                      <FormRow optional label={t('collection.label')} alignItems="flex-start">
                        <CollectionSelect
                          optional
                          fullWidth
                          name="collections"
                          placeholder={t('collection.placeholder')}
                        />
                      </FormRow>
                    </>
                  }
                />
              )}
              {tab === `steps` && (
                <ProjectStepsForm
                  rules={rules}
                  defaultStepValue={defaultStepValue}
                  defaultSourceValue={defaultSourceValue}
                />
              )}
            </Paper>
          </Collapse>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewProject;
