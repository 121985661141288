import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { Box, styled, Typography } from '@mui/material';

import { cloud_upload, upload } from 'assets/images/holders';
import { dev } from 'utils/config';
import { getArray, propagateRefs } from 'utils/helpers';
import { makeSvgDashBorder } from 'styles/helpers';
import { useToggle } from 'hooks';

import Image from 'components/Image';
import Icon from 'components/Icon';
import FormLabel from 'components/FormLabel';
import Center from 'components/Center';
import Ref from 'components/Ref';
import FormHelperText from 'components/FormHelperText';
import Ripple from 'components/Ripple';
import Fieldset from 'components/Dev/Fieldset';
import ImageHolder from 'components/ImageHolder';
import Button from 'components/Button';

const FormWrapper = styled(Box, {
  label: 'Dropzone-FormWrapper',
})({
  display: 'inline-block',
});

const Root = styled(Center, {
  label: 'Dropzone-Root',
  shouldForwardProp: (prop) => !['disabled', 'error', 'noClick', 'minHeight'].includes(prop),
})(({ theme, minHeight, disabled, error, noClick }) => ({
  minHeight: minHeight || 190,
  position: 'relative',
  cursor: disabled
    ? 'not-allowed'
    : (noClick ? 'default' : 'pointer'),
  padding: theme.spacing(2),
  transition: theme.transitions.create(),
  borderRadius: theme.shape.borderRadius * 2,
  opacity: disabled ? 0.5 : 1,
  backgroundImage: makeSvgDashBorder({
    dash: [3, 6],
    width: error ? 3.5 : 2.5,
    radius: theme.shape.borderRadius * 2,
    color: error ? theme.palette.error.main : theme.palette.primary.light,
  }),
  '&:hover, &:focus': {
    outlineWidth: 0,
    outlineColor: 'transparent',
    backgroundImage: disabled
      ? undefined
      : makeSvgDashBorder({
          dash: [3, 6],
          width: error ? 3.5 : 2.5,
          radius: theme.shape.borderRadius * 2,
          color: error ? theme.palette.error.dark : theme.palette.primary.main,
        }),
  },
  '& > *': {
    pointerEvents: disabled ? 'none' : 'all',
  },
}));

const acceptNames = {
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'application/msword': 'DOC',
  'application/pdf': 'PDF',
};

const keyPrefix = 'Dropzone';

/**
 * Passing the "children" will overwrite default container layout.
 *
 * @prop {File[]} value - Array of files.
 * @prop {function} onChange - Returns array of files according to "complete" property.
 *
 * @prop {boolean} [complete] - If false, each files selecting will replace previous files.
 * Otherwise will complete files array with new files (if allowed by "multiple"/"maxFiles" props).
 * @prop {number} [maxFiles=Infinity] - Max files length. Works only with "multiple" prop (Infinite).
 * @prop {boolean} [multiple] - Allow multiple files selection.
 * @prop {string[]} [accept] - Accepted formats (mime types). If you want to list these formats in
 * description, add needed mime-type into object "acceptNames" above the component.
 * @prop {ReactRef} [inputRef] - File input element reference
 *
 * @prop {string} [label] - Label
 * @prop {boolean} [optional] - Show (optional) mark next to the label, if presents.
 * @prop {boolean} [fullWidth] - Full width
 * @prop {string|ReactNode|boolean} - Boolean value switch default title image on/off. String
 * value works as an image url. React Node will replace default image with.
 * @prop {ReactNode} [text] - Replace default message below the image.
 * @prop {ReactNode|boolean} [description=true] - Show/hide description on the bottom, or replace
 * with custom text/element.
 * @prop {boolean|string} [error] - Show error styles, and the error message if needed.
 * @prop {boolean} [disabled] - Disable dropzone, show disabled styles.
 * @prop {function} [onFocus] - Dropzone focus event handler
 * @prop {function} [onBlur] - Dropzone blur event handler
 * @prop {boolean} [initRef] - Focus element on mount
 *
 * All the other props will be passed to root <Box /> component
 */
const Dropzone = forwardRef((props, ref) => {
  const {
    accept,
    children,
    complete,
    description = true,
    disabled,
    error,
    fullWidth,
    image,
    inputRef,
    label,
    maxFiles = 1,
    multiple = false,
    onChange,
    optional,
    onFocus,
    onBlur,
    text,
    value,
    preview = true,
    initFocus,
    minHeight,
    ...rest
  } = props;

  const { t } = useTranslation('components', { keyPrefix });
  const [focus, setFocus] = useState(false);
  const [dialog, toggleDialog] = useToggle(false);
  const [lastSelectedImage, setLastSelectedImage] = useState(null);

  const max = useMemo(() => (!multiple ? 1 : maxFiles), [maxFiles, multiple]);

  const dis = useMemo(
    () => (complete && getArray(value).length >= max ? true : disabled),
    [value, max, complete, disabled],
  );

  const acceptFormats = useMemo(() => {
    return getArray(accept).reduce(
      (res, mime) => ({
        ...res,
        [mime]: [],
      }),
      {},
    );
  }, [accept]);

  const formats = useMemo(() => {
    return getArray(accept)
      .reduce((res, mime) => {
        if (acceptNames[mime]) {
          return [...res, acceptNames[mime]];
        }
        return res;
      }, [])
      .join(', ');
  }, [accept]);

  const imagePreview = useMemo(() => {
    if (!preview) return null;

    if (Array.isArray(value) && value.length === 0) {
      if (lastSelectedImage) setLastSelectedImage(null);
      return null;
    }

    let previewSrc;
    if (value?.length > 0) {
      previewSrc = value[value.length - 1];
    } else if (lastSelectedImage) {
      previewSrc = lastSelectedImage;
    }

    if (previewSrc instanceof File) {
      previewSrc = URL.createObjectURL(previewSrc);
    }

    if (!lastSelectedImage) setLastSelectedImage(previewSrc);

    return previewSrc;
  }, [lastSelectedImage, value, preview]);

  const handleDropAccepted = useCallback(
    (files) => {
      const result = !complete ? [...files] : [...getArray(value), ...files].slice(0, max);

      if (preview && result.length > 0) {
        const allowed = result.filter((f) => f.type.includes('image/'));
        const last = allowed[result.length - 1];

        if (last instanceof File) {
          setLastSelectedImage(URL.createObjectURL(last));
        }
      }
      if (typeof onChange === 'function') {
        onChange(result);
        setFocus(false);
      }
    },
    [complete, onChange, value, max, preview],
  );

  const {
    getRootProps,
    getInputProps,
    inputRef: dzInputRef,
    rootRef: dzRootRef,
  } = useDropzone({
    multiple,
    maxFiles,
    disabled: dis,
    accept: acceptFormats,
    noClick: !!imagePreview,
    onDropAccepted: handleDropAccepted,
    onFileDialogOpen: toggleDialog.on,
    onFileDialogCancel: toggleDialog.off,
  });

  useEffect(() => {
    const { current: input } = dzRootRef;

    if (input && initFocus) {
      input.focus();
    }
  }, [dzRootRef, initFocus]);

  useImperativeHandle(inputRef, () => dzInputRef.current, [dzInputRef]);

  const handleUploadNewClick = useCallback(() => {
    const { current: input } = dzInputRef;

    if (input) {
      input.click();
    }
  }, [dzInputRef]);

  const handleOnFocus = useCallback(
    (...args) => {
      if (dialog) {
        return;
      }
      setFocus(true);
      typeof onFocus === 'function' && onFocus(...args);
    },
    [onFocus, dialog],
  );

  const handleOnBlur = useCallback(
    (...args) => {
      if (dialog) {
        return;
      }
      setFocus(false);
      typeof onBlur === 'function' && onBlur(...args);
    },
    [onBlur, dialog],
  );

  const rootProps = getRootProps({
    disabled: dis,
    error: !!error,
    onFocus: handleOnFocus,
    onBlur: handleOnBlur,
    tabIndex: !!imagePreview ? -1 : 0,
    noClick: !!imagePreview,
  });
  const inputProps = getInputProps();

  const imageElement = useMemo(() => {
    if (image === false) {
      return null;
    }
    if (image && image !== true && typeof image !== 'string') {
      return image;
    }
    const src = image === true || !image ? cloud_upload : image;

    return <Image mb={3} src={src} width="65px" alt="Upload" />;
  }, [image]);

  const helperText = typeof error !== 'boolean' ? error : null;

  return (
    <FormWrapper {...rest} ref={ref} width={fullWidth ? '100%' : undefined}>
      {label && (
        <Box mb={0.5}>
          <FormLabel disabled={dis} error={!!error} optional={!!optional}>
            {label}
          </FormLabel>
        </Box>
      )}
      <Root minHeight={minHeight} {...rootProps}>
        <input {...inputProps} />
        <Ripple show={focus && !imagePreview} />

        {!children && (
          <Center flexDirection="column" position="relative">
            {!imagePreview && (
              <>
                {imageElement}

                <Typography variant="button1" color="textBlack.light">
                  {text && text}

                  {!text && (
                    <Trans t={t} i18nKey="text">
                      Drag & drop files or <Ref component="span">Browse</Ref>
                    </Trans>
                  )}
                </Typography>
              </>
            )}

            {imagePreview && (
              <Center flexDirection="column" maxWidth={110}>
                <ImageHolder
                  src={imagePreview}
                  alt="Preview"
                  size={100}
                  stroke={2}
                  radius={3}
                  color="other.white"
                  mb={1}
                />
                <Button size="small" variant="outlined" onClick={handleUploadNewClick}>
                  {t('upload_new')}
                </Button>
              </Center>
            )}

            {description && (
              <Typography mt={1} variant="caption1" color="textBlack.light">
                {typeof description !== 'string' && formats && (
                  <>
                    {t('description')}: {formats}
                  </>
                )}

                {typeof description === 'string' && <>{description}</>}
              </Typography>
            )}
          </Center>
        )}

        {children}
      </Root>

      <FormHelperText error disabled={dis}>
        {helperText}
      </FormHelperText>
    </FormWrapper>
  );
});

const def = [];

Dropzone.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue = def, onBlur, onChange, inputRef, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const {
          ref: controlRef,
          onChange: controlOnChange,
          onBlur: controlOnBlur,
          ...restField
        } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleOnBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <Dropzone
            ref={ref}
            error={error?.message || !!error}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputRef={propagateRefs(inputRef, controlRef)}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [minWidth, setMinWidth] = useState(456);
    const [disabled, setDisabled] = useState();
    const [fullWidth, setFullWidth] = useState();
    const [image, setImage] = useState();
    const [label, setLabel] = useState('Profile picture');
    const [text, setText] = useState();
    const [description, setDescription] = useState();
    const [children, setChildren] = useState();
    const [accept, setAccept] = useState("['image/jpeg', 'image/png']");
    const [error, setError] = useState();

    const src = useMemo(() => {
      if (image === './upload.png') {
        return upload;
      }
      if (image === '<Icon.User />') {
        return <Icon.User fontSize={100} color="primary" />;
      }
      return image;
    }, [image]);

    const childs = useMemo(() => {
      if (children) {
        return <div>Custom layout</div>;
      }
      return children;
    }, [children]);

    const accepted = useMemo(() => {
      switch (accept) {
        case "['image/jpeg', 'image/png']":
          return ['image/jpeg', 'image/png'];
        case "['application/pdf', 'application/msword']":
          return ['application/pdf', 'application/msword'];
        default:
          return accept;
      }
    }, [accept]);

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="minWidth"
            value={minWidth}
            onChange={setMinWidth}
            options={[undefined, 100, 234, 456, 600]}
          />
          <Fieldset.Field
            legend="fullWidth"
            value={fullWidth}
            onChange={setFullWidth}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Profile picture']}
          />
          <Fieldset.Field
            legend="image"
            value={image}
            onChange={setImage}
            options={[undefined, true, false, './upload.png', '<Icon.User />']}
          />
          <Fieldset.Field
            legend="text"
            value={text}
            onChange={setText}
            options={[undefined, 'Click to upload']}
          />
          <Fieldset.Field
            legend="description"
            value={description}
            onChange={setDescription}
            options={[undefined, true, false, 'Images only']}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, '<div>Custom layout</div>']}
          />
          <Fieldset.Field
            legend="accept"
            value={accept}
            onChange={setAccept}
            options={[
              undefined,
              "['image/jpeg', 'image/png']",
              "['application/pdf', 'application/msword']",
            ]}
          />
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, 'This field is required!', true, false]}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Dropzone
          error={error}
          disabled={disabled}
          image={src}
          label={label}
          fullWidth={fullWidth}
          minWidth={minWidth}
          text={text}
          description={description}
          children={childs}
          accept={accepted}
        />
      </Box>
    );
  };
  Dropzone.Demo = Demo;
}

export default Dropzone;
