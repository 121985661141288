import { Box, Stack } from '@mui/material';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { propagateRefs } from 'utils/helpers';

import { Center, Select, Icon, Tag, IconButton, DayPicker } from 'components';

import { timeSeries } from './defaults';

const DayTimePicker = forwardRef((props, ref) => {
  const { name, value = [], onValue } = props;

  const { t } = useTranslation('components', { keyPrefix: 'DayTimePicker' });

  const days = value.map((v) => v.day);

  const handleDaySelect = (changedDays) => {
    typeof onValue === 'function' &&
      onValue(
        changedDays.map((d) => {
          const foundIndex = days.indexOf(d);
          if (foundIndex !== -1) return Object.assign({}, value[foundIndex]);
          return { day: d };
        }),
      );
  };

  const handleRemove = (idx) => {
    const valueCopy = value.slice();
    valueCopy.splice(idx, 1);

    typeof onValue === 'function' && onValue(valueCopy);
  };

  return (
    <Box ref={ref}>
      <DayPicker value={days} onValue={handleDaySelect} />

      <Stack gap={1} mt={1.5}>
        {value?.map((field, idx) => (
          <Center justifyContent="flex-start" key={field.day}>
            <Tag
              startAdornment={<Icon name="Calendar" />}
              bgcolor="input.main"
              size="large"
              label={t(`days.${field.day}`)}
              pl={1}
              minWidth={112}
              mr={1}
              sx={{
                border: '1px solid',
                borderColor: 'controls.main',
                backgroundColor: 'input.main',
              }}
            />
            <Center width={140} mr={0.5}>
              <Select.Control
                placeholder="--:--"
                options={timeSeries}
                box={false}
                fullWidth
                iconLeft={<Icon name="Clock" />}
                itemsMax={5}
                itemsMinWidth={120}
                name={`${name}.${idx}.time`}
              />
            </Center>
            <IconButton variant="text" color="error" onClick={() => handleRemove(idx)}>
              <Icon name="Close" />
            </IconButton>
          </Center>
        ))}
      </Stack>
    </Box>
  );
});

DayTimePicker.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onValue, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <DayTimePicker
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

export default DayTimePicker;
