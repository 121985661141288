import { forwardRef, useMemo, useState } from 'react';
import { Box, Typography, useTheme, alpha } from '@mui/material';

import { dev } from 'utils/config';
import Holder from 'components/Holder';
import Fieldset from 'components/Dev/Fieldset';

/**
 * @param {boolean} [soft]
 * @param {string['small'|'medium']} [size]
 * @param {string['default'|'primary'|'secondary'|'success'|'error']} [variant]
 */
const Chip = forwardRef((props, ref) => {
  const {
    children,
    size,
    variant = 'default',
    soft,
    ...rest
  } = props;

  const theme = useTheme();

  const colorScheme = useMemo(() => {
    return {
      default: {
        main: {
          color: theme.palette.other.black,
          bgcolor: theme.palette.chip.main,
        },
        soft: {
          color: theme.palette.other.black,
          bgcolor: theme.palette.chip.main,
        },
      },
      primary: {
        main: {
          color: theme.palette.other.white,
          bgcolor: theme.palette.primary.main,
        },
        soft: {
          color: theme.palette.primary.dark,
          bgcolor: alpha(theme.palette.chip.primary, 0.5),
        },
      },
      secondary: {
        main: {
          color: theme.palette.other.white,
          bgcolor: theme.palette.secondary.contrastText,
        },
        soft: {
          color: theme.palette.secondary.light,
          bgcolor: theme.palette.chip.purple,
        },
      },
      success: {
        main: {
          color: theme.palette.other.white,
          bgcolor: theme.palette.success.dark,
        },
        soft: {
          color: theme.palette.success.main,
          bgcolor: theme.palette.success.contrastText,
        },
      },
      error: {
        main: {
          color: theme.palette.other.white,
          bgcolor: theme.palette.error.main,
        },
        soft: {
          color: theme.palette.error.main,
          bgcolor: theme.palette.error.contrastText,
        },
      },
    };
  }, [theme]);

  const colors = useMemo(() => {
    const type = soft ? 'soft' : 'main';
    return colorScheme[variant][type];
  }, [colorScheme, variant, soft]);

  return (
    <Holder
      {...rest}
      px={1}
      ref={ref}
      bgcolor={colors.bgcolor}
      radius={size === 'small' ? 1 : 2}
      height={size === 'small' ? 16 : 24}
    >
      <Typography
        color={colors.color}
        variant={size === 'small' ? 'chip2' : 'chip1'}
      >
        {children}
      </Typography>
    </Holder>
  );
});

if (dev) {
  const Demo = () => {
    const [size, setSize] = useState();
    const [soft, setSoft] = useState();
    const [variant, setVariant] = useState('primary');

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'small', 'medium']}
          />
          <Fieldset.Field
            legend="variant"
            value={variant}
            onChange={setVariant}
            options={[undefined, 'primary', 'secondary', 'success', 'error']}
          />
          <Fieldset.Field
            legend="soft"
            value={soft}
            onChange={setSoft}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre">
          {`<Chip\n`}
          {`  size="${size}"\n`}
          {`  variant="${variant}"\n`}
          {`  soft={${soft}}\n`}
          {`>\n`}
          {`  Example Chip\n`}
          {`</Chip>\n`}
        </Box>

        <Chip size={size} variant={variant} soft={soft}>
          Example Chip
        </Chip>
      </Box>
    );
  }
  Chip.Demo = Demo;
}

export default Chip;
