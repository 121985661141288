const mapRequest = (me, formData) => {
  return {
    first_name: formData.first_name || '',
    last_name: formData.last_name || '',
    email: formData.email || '',
    phone_number: formData.phone_number || null,
    avatar_file_id: formData.avatar_file_id || '',
    language: formData.language || 'en',
    permissions: me?.profile?.permissions,
  };
};

export default mapRequest;
