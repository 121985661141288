import { useState, useMemo, useEffect } from 'react';

import { getArray } from 'utils/helpers';
import { useGetUsersQuery } from 'store/api';

const SEARCH_DELAY = 800;
let timeout = null;

const useUsersSearch = (opts) => {
  const { spaceId, disabled, mapToOptions = false, filters, onData } = opts || {};

  const [tempSearch, setTempSearch] = useState('');
  const [search, setSearch] = useState('');

  const { data, isLoading, isFetching } = useGetUsersQuery(
    Object.assign({ space_id: spaceId, limit: 8, offset: 0, name: search || undefined }, filters),
    {
      skip: disabled || !spaceId,
    },
  );

  useEffect(() => {
    if (!data) return;
    typeof onData === 'function' && onData(data);
  }, [data, onData]);

  return useMemo(() => {
    const result = {
      data,
      loading: isLoading || isFetching,
      search,
      tempSearch,
      onSearch: (_e, val) => {
        setTempSearch(val);
        if (timeout) clearTimeout(timeout);

        if (val) {
          timeout = setTimeout(() => {
            setSearch(val);
          }, SEARCH_DELAY);
        } else {
          setSearch('');
        }
      },
    };
    if (mapToOptions) {
      result.options = getArray(data?.users).map((student) => ({
        ...student,
        label: student.username,
        value: student.id,
        avatar: student.avatar,
        avatarSize: 16,
        pl: 1,
      }));
    }
    return result;
  }, [data, isLoading, isFetching, search, tempSearch, mapToOptions, setSearch]);
};

export default useUsersSearch;
