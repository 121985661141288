import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { Tab as MuiTab, styled } from '@mui/material';

import { useCombineRefs } from 'hooks';
import Icon from 'components/Icon';

const StyledTab = styled(MuiTab, {
  label: 'Tab-Root',
})(({ theme }) => ({
  '&.MuiTab-root': {
    ...theme.typography.button2,
    overflow: 'unset',
    padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`,
    color: theme.palette.primary.main,
    textTransform: 'none',
    minHeight: 0,
    borderRadius: theme.shape.borderRadius * 2,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
    '&.MuiTab-labelIcon-start .MuiTab-iconWrapper': {
      marginRight: theme.spacing(0.5),
      marginBottom: '1px',
    },
    '&.MuiTab-labelIcon-end .MuiTab-iconWrapper': {
      marginLeft: theme.spacing(0.5),
      marginBottom: '1px',
    },
    '&.MuiTab-labelIcon-top .MuiTab-iconWrapper': {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0.5),
    },
    '&.MuiTab-labelIcon-bottom .MuiTab-iconWrapper': {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0.5),
    },
    '&.Mui-selected': {
      zIndex: 1,
      color: theme.palette.other.white,
    },
  },
}));

/**
 * @prop {number|string} [width] - Tab width
 * @prop {string|ReactNode} [icon] - Icon name (from <Icon /> component) or Icon component itself.
 * @prop {string['start'|'end'|'top'|'bottom']} [iconPosition="start"] - Icon position
 * Rest of props will be passed to the Mui Tab component
 * @prop {boolean} [initFocus] - Initial focus (works after the value changed from falsy to true)
 */
const Tab = forwardRef((props, ref) => {
  const { width, icon, iconPosition = 'start', initFocus, ...rest } = props;

  const rootRef = useRef(null);
  const handleRef = useCombineRefs(rootRef, ref);

  const tabIcon = useMemo(() => {
    if (typeof icon === 'string' && Icon[icon]) {
      return <Icon fontSize={18} name={icon} />;
    }
    return icon;
  }, [icon]);

  useEffect(() => {
    const { current: tab } = rootRef;

    if (tab && initFocus) {
      tab.focus();
    }
  }, [initFocus]);

  return (
    <StyledTab
      ref={handleRef}
      sx={{ width }}
      icon={tabIcon}
      iconPosition={iconPosition}
      className={`MuiTab-labelIcon-${iconPosition}`}
      variant="outlined"
      {...rest}
    />
  );
});

export default Tab;
