import { forwardRef, useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, OutlinedInput, styled, InputAdornment } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import { useCombineRefs } from 'hooks';
import { Icon, FormLabel, FormHelperText } from 'components';
import Fieldset from 'components/Dev/Fieldset';

export const StyledInput = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'outline',
})(({ theme, outline, startAdornment, endAdornment }) => ({
  // Styles priority selector
  '&.MuiInputBase-root.MuiOutlinedInput-root': {
    minHeight: '40px',
    backgroundColor: theme.palette.other.white,
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: outline ? theme.shadows.small : 'none',
    paddingLeft: startAdornment ? theme.spacing(1) : 0,
    paddingRight: endAdornment ? theme.spacing(1) : 0,
    '& input': {
      ...theme.typography.body4,
      color: theme.palette.textBlack.dark,
      '&::placeholder': {
        opacity: 0.5,
        color: theme.palette.input.placeholder,
      },
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: outline ? 1 : 0,
      borderStyle: 'solid',
      borderColor: theme.palette.input.main,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.shortest,
      }),
    },
    '& .MuiInputAdornment-root': {
      opacity: 0.5,
      color: theme.palette.primary.main,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.shortest,
      }),
    },

    // Hover, focus
    '&:hover, &.Mui-focused': {
      '& input::placeholder': {
        opacity: 1,
      },
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.light,
      },
      '& .MuiInputAdornment-root': {
        opacity: 1,
      },
    },

    // Error
    '&.Mui-error': {
      '& input': {
        color: theme.palette.error.dark,
      },
      '& .MuiInputAdornment-root': {
        color: theme.palette.error.main,
      },
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.light,
      },
      '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
      '&.Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },

    // Disabled
    '&.Mui-disabled': {
      boxShadow: 'none',

      // Styles priority selector
      '&.MuiInputBase-root.MuiOutlinedInput-root': {
        '& .MuiInputAdornment-root': {
          opacity: 0.5,
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.other.hover,
        },
        '&:hover': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.other.hover,
          },
          '& .MuiInputAdornment-root': {
            color: theme.palette.primary.light,
          },
        },
        '&.Mui-focused': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            boxShadow: 'none',
            borderColor: theme.palette.other.hover,
          },
        },
      },
    },
  },
}));

/**
 * TODO: It would be nice to rewrite Input using InputContainer component to keep all the styles
 * in one place
 * @prop {boolean|string} [error] - Error text or boolean state
 * @prop {string|ReactNode} [iconLeft] - Name of the icon (from <Icon /> component) or a react
 * component itself
 * @prop {boolean} [optional] - Shows muted "(optional)" text right after label (and only in case label
 * is visible and exists)
 * @prop {string|ReactNode} [irconRight] - The same as above, for the right adornment.
 * @prop {function} [onValue] - The same as onChange, but returns only the value (e.target.value).
 */
const Input = forwardRef((props, ref) => {
  const {
    outline = true,
    type,
    error,
    label,
    optional,
    disabled,
    iconLeft,
    iconRight,
    fullWidth,
    value,
    onValue,
    onChange,
    onBlur,
    inputRef,
    placeholder,
    defaultValue,
    autoComplete,
    inputComponent,
    initFocus,
    inputProps: initInputProps,
    children,
    min,
    max,
    ...rest
  } = props;

  const innerInputRef = useRef(null);
  const handleInputRef = useCombineRefs(inputRef, innerInputRef);
  const helperText = typeof error !== 'boolean' ? error : null;

  const inputProps = useMemo(() => {
    const result = { ...initInputProps };

    if (autoComplete) {
      result.autoComplete = autoComplete;
    }
    return result;
  }, [autoComplete, initInputProps]);

  const startAdornment = useMemo(() => {
    if (iconLeft) {
      const thisIcon =
        typeof iconLeft === 'string' && Icon[iconLeft] ? <Icon name={iconLeft} /> : iconLeft;

      return <InputAdornment position="start">{thisIcon}</InputAdornment>;
    }
    return null;
  }, [iconLeft]);

  const endAdornment = useMemo(() => {
    if (iconRight) {
      const thisIcon =
        typeof iconRight === 'string' && Icon[iconRight] ? <Icon name={iconRight} /> : iconRight;

      return <InputAdornment position="end">{thisIcon}</InputAdornment>;
    }
    return null;
  }, [iconRight]);

  const handleChange = useCallback(
    (e, ...args) => {
      typeof onChange === 'function' && onChange(e, ...args);
      typeof onValue === 'function' && onValue(e.target.value);
    },
    [onChange, onValue],
  );

  useEffect(() => {
    const { current: input } = innerInputRef;

    if (input && initFocus) {
      input.focus();
    }
  }, [initFocus]);

  return (
    <FormControl ref={ref} fullWidth={fullWidth} error={!!error} {...rest}>
      {label && (
        <FormLabel error={!!error} disabled={disabled} optional={!!optional}>
          {label}
        </FormLabel>
      )}

      <StyledInput
        type={type}
        size="small"
        error={!!error}
        disabled={disabled}
        placeholder={placeholder}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        inputRef={handleInputRef}
        inputProps={inputProps}
        inputComponent={inputComponent}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        outline={outline}
      />

      <FormHelperText error disabled={disabled}>
        {helperText}
      </FormHelperText>

      {children}
    </FormControl>
  );
});

Input.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue, onChange, onBlur, inputRef, ...rest } = props;
  const { control, getFieldState, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => {
        const { isTouched } = getFieldState(name);
        const { error } = fieldState;
        const {
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleBlur = (...args) => {
          trigger(name);
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        return (
          <Input
            ref={ref}
            error={isTouched && (error?.message || !!error)}
            inputRef={propagateRefs(inputRef, controlRef)}
            onChange={handleChange}
            onBlur={handleBlur}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [label, setLabel] = useState('Email address:');
    const [disabled, setDisabled] = useState();
    const [error, setError] = useState();
    const [iconLeft, setIconLeft] = useState('Mail');
    const [iconRight, setIconRight] = useState();
    const [fullWidth, setFullWidth] = useState();
    const [placeholder, setPlaceholder] = useState('Enter email');
    const [optional, setOptional] = useState(true);

    return (
      <Box>
        <Box mb={2}>All the other props will be passed to the {`<FormControl />`} component.</Box>

        <Fieldset>
          <Fieldset.Field
            legend="label"
            value={label}
            onChange={setLabel}
            options={[undefined, 'Email address:']}
          />
          <Fieldset.Field
            legend="optional"
            value={optional}
            onChange={setOptional}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="error"
            value={error}
            onChange={setError}
            options={[undefined, true, false, 'This field is required!']}
          />
          <Fieldset.Field
            legend="iconLeft"
            value={iconLeft}
            onChange={setIconLeft}
            options={[undefined, 'Search', 'Phone', 'Mail']}
          />
          <Fieldset.Field
            legend="iconRight"
            value={iconRight}
            onChange={setIconRight}
            options={[undefined, 'Eye', 'Calendar', 'ArrowDown']}
          />
          <Fieldset.Field
            legend="fullWidth"
            value={fullWidth}
            onChange={setFullWidth}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="placeholder"
            value={placeholder}
            onChange={setPlaceholder}
            options={[undefined, 'Enter email']}
          />
        </Fieldset>

        <Box component="pre">
          {`<Input\n`}
          {`  label="${label}"\n`}
          {`  optional={${optional}}\n`}
          {`  error={${error}}\n`}
          {`  iconLeft="${iconLeft}"\n`}
          {`  iconRight="${iconRight}"\n`}
          {`  disabled={${disabled}}\n`}
          {`  fullWidth={${fullWidth}}\n`}
          {`  placeholder="${placeholder}"\n`}
          {`/>\n`}
        </Box>

        <Box p={2}>
          <Input
            label={label}
            error={error}
            optional={optional}
            iconLeft={iconLeft}
            disabled={disabled}
            iconRight={iconRight}
            fullWidth={fullWidth}
            placeholder={placeholder}
          />
        </Box>
      </Box>
    );
  };
  Input.Demo = Demo;
}

export default Input;
