import { forwardRef, useMemo, useRef, useState } from 'react';
import { Box, Popover as MuiPopover, styled, Zoom } from '@mui/material';

import { dev } from 'utils/config';
import Button from 'components/Button';
import Fieldset from 'components/Dev/Fieldset';

const StyledPopover = styled(MuiPopover, {
  shouldForwardProp: (prop) => !['offsetX', 'offsetY'].includes(prop),
})(({ theme, offsetX, offsetY }) => ({
  '& .MuiPopover-paper': {
    padding: theme.spacing(1),
    boxShadow: theme.shadows.big,
    borderRadius: theme.shape.borderRadius * 2,
    margin: `${theme.spacing(offsetY)} ${theme.spacing(offsetX)}`,
  },
}));

const getOrigin = (origin) => {
  switch (origin) {
    case 'topRight': return {
      vertical: 'top',
      horizontal: 'right',
    };
    case 'topLeft': return {
      vertical: 'top',
      horizontal: 'left',
    };
    case 'bottomRight': return {
      vertical: 'bottom',
      horizontal: 'right',
    };
    case 'bottomLeft':
    default: return {
      vertical: 'bottom',
      horizontal: 'left',
    };
  }
};

/**
 * Popover
 * @prop {HTMLElement} anchorEl - The element next to which you want to show the popover.
 * @prop {boolean} open - Popover state (open/close)
 * @prop {function} onClose - Callback fired when the popover change its state to close.
 * @prop {ReactNode} children - The popover's content.
 * @prop {string['topRight'|'topLeft'|'bottomRight'|'bottomLeft']} [anchorOrigin="bottomLeft"] -
 * Anchor element origin point (the same as MUI Popover anchor origin but in one string in format
 * <vertical><horizontal> (camelCase)).
 * @prop {string['topRight'|'topLeft'|'bottomRight'|'bottomLeft']} [transformOrigin="topLeft"] -
 * The same as previous prop, but for "transformOrigin" point.
 * @prop {number} [offsetX=0] - Popover horizontal offset (integer represents theme.spacing value).
 * Could be a negative number.
 * @prop {number} [offsetY=1] - The same as previous but for vertical offset.
 */
const Popover = forwardRef((props, ref) => {
  const {
    open,
    onClose,
    children,
    anchorEl,
    offsetX = 0,
    offsetY = 1,
    anchorOrigin: originAnchor = 'bottomLeft',
    transformOrigin: originTransform = 'topLeft',
  } = props;

  const anchorOrigin = useMemo(() => {
    return getOrigin(originAnchor);
  }, [originAnchor]);

  const transformOrigin = useMemo(() => {
    return getOrigin(originTransform);
  }, [originTransform]);

  if (!anchorEl) {
    return null;
  }
  return (
    <StyledPopover
      ref={ref}
      open={open}
      offsetX={offsetX}
      offsetY={offsetY}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      TransitionComponent={Zoom}
    >
      {children}
    </StyledPopover>
  );
});

if (dev) {
  const Demo = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [anchorOrigin, setAnchorOrigin] = useState();
    const [transformOrigin, setTransformOrigin] = useState();
    const [offsetX, setOffsetX] = useState();
    const [offsetY, setOffsetY] = useState();

    return (
      <Box p={2}>
        <Fieldset>
          <Fieldset.Field
            legend="open"
            value={open}
            onChange={setOpen}
            options={[true, false]}
          />
          <Fieldset.Field
            legend="anchorOrigin"
            value={anchorOrigin}
            onChange={setAnchorOrigin}
            options={[undefined, 'bottomLeft', 'bottomRight', 'topLeft', 'topRight']}
          />
          <Fieldset.Field
            legend="transformOrigin"
            value={transformOrigin}
            onChange={setTransformOrigin}
            options={[undefined, 'bottomLeft', 'bottomRight', 'topLeft', 'topRight']}
          />
          <Fieldset.Field
            legend="offsetX"
            value={offsetX}
            onChange={setOffsetX}
            options={[undefined, 0, 1, 2, 3, -1, -2, -3]}
          />
          <Fieldset.Field
            legend="offsetY"
            value={offsetY}
            onChange={setOffsetY}
            options={[undefined, 0, 1, 2, 3, -1, -2, -3]}
          />
        </Fieldset>

        <Box component="pre">
          {`<Button\n`}
          {`  ref={anchorRef}\n`}
          {`  onClick={() => setOpen(true)}\n`}
          {`>\n`}
          {`  Trigger\n`}
          {`</Button>\n`}
          {`\n`}
          {`<Popover\n`}
          {`  open={${open}}\n`}
          {`  offsetX={${offsetX}}\n`}
          {`  offsetY={${offsetY}}\n`}
          {`  anchorEl={anchorRef.current}\n`}
          {`  onClose={() => setOpen(false)}\n`}
          {`  anchorOrigin="${anchorOrigin}"\n`}
          {`  transformOrigin="${transformOrigin}"\n`}
          {`>\n`}
          {`  Popover content\n`}
          {`</Popover>\n`}
        </Box>

        <Box p={4}>
          <Button
            ref={anchorRef}
            onClick={() => setOpen(true)}
          >
            Trigger
          </Button>

          <Popover
            open={open}
            offsetX={offsetX}
            offsetY={offsetY}
            anchorEl={anchorRef.current}
            onClose={() => setOpen(false)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            Popover content
          </Popover>
        </Box>

      </Box>
    );
  };
  Popover.Demo = Demo;
}

export default Popover;
