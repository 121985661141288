import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadTemporaryFileMutation } from 'store/api';

import { useMessage } from 'hooks';

const useUploadProjectAvatars = () => {
  const { t } = useTranslation('hooks', { keyPrefix: 'upload_photos' });
  const m = useMessage();
  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();

  const handleUpload = useCallback(
    async (projectCards) => {
      if (projectCards.length === 0) return [];

      const uploadPromises = projectCards.map(async (project) => {
        if (project.avatar instanceof File) {
          try {
            const { file_id } = await uploadFile({ file: project.avatar }).unwrap();
            return { ...project, avatar: file_id };
          } catch (e) {
            console.error(e);
            m.error(t('error_photos_upload'));
            return project;
          }
        }

        return project;
      });

      const projectCardsWithAvatars = await Promise.all(uploadPromises);
      return projectCardsWithAvatars;
    },
    [uploadFile, m, t],
  );

  return [handleUpload, uploadFileState];
};

export default useUploadProjectAvatars;
