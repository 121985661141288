import { Box, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formatPhoneNumber } from 'utils/helpers';

import { Center, Paper, Avatar, Tags, Icon } from 'components';

const PersonalInformation = (props) => {
  const { loading, avatar, username, fullName, phoneNumber, email, birthDate, roles, groups } =
    props;

  const { t } = useTranslation('pages', { keyPrefix: 'user_profile' });

  return (
    <Paper mb={5} width={1} shadow="standard" loading={loading}>
      <Center>
        <Box>
          <Avatar src={avatar} size={168} radius={2} />
        </Box>
        <Box pl={2} color="textBlack.light">
          <Typography color="textBlack.dark" variant="body2">
            {username} {username && fullName ? `(${fullName})` : fullName}
          </Typography>

          <Stack
            mt={1.5}
            direction="row"
            divider={
              <Divider
                sx={{ margin: '0 12px', borderColor: 'other.line' }}
                orientation="vertical"
                flexItem
              />
            }
          >
            {phoneNumber && (
              <Center>
                <Icon name="Phone" fontSize="small" />
                <Typography variant="body3" ml={0.5}>
                  {formatPhoneNumber(phoneNumber)}
                </Typography>
              </Center>
            )}
            {email && (
              <Center>
                <Icon name="Mail" fontSize="small" />
                <Typography variant="body3" ml={0.5}>
                  {email}
                </Typography>
              </Center>
            )}

            {birthDate && (
              <Center>
                <Icon name="Birthday" fontSize="small" />
                <Typography variant="body3" ml={0.5}>
                  {birthDate}
                </Typography>
              </Center>
            )}
          </Stack>

          <Tags titleProps={{ minWidth: 56 }} tags={roles} title={t('role')} mt={2.5} />
          <Tags titleProps={{ minWidth: 56 }} max={5} tags={groups} title={t('groups')} mt={2} />
        </Box>
      </Center>
    </Paper>
  );
};

export default PersonalInformation;
