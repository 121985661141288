import { forwardRef, useCallback, useState } from 'react';
import InputMask from 'react-input-mask';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import { propagateRefs } from 'utils/helpers';
import Input from 'components/Input';
import Fieldset from 'components/Dev/Fieldset';

/**
 * All the props equal to <Input /> component props. Also <InputMask /> component props are
 * available.
 */
const MaskField = forwardRef((props, ref) => {
  const {
    mask,
    maskChar = null,
    value,
    onChange,
    onValue,
    disabled,
    inputRef,
    ...rest
  } = props;

  const handleChange = useCallback((e) => {
    typeof onChange === 'function' && onChange(e);
    typeof onValue === 'function' && onValue(e.target.value);
  }, [onChange, onValue]);

  return (
    <InputMask
      ref={ref}
      mask={mask}
      maskChar={maskChar}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      {...rest}
    >
      {(inputProps) => (
        <Input
          disabled={disabled}
          inputRef={inputRef}
          {...inputProps}
        />
      )}
    </InputMask>
  );
});

MaskField.Control = forwardRef((props, ref) => {
  const { name, defaultValue = '', rules, onChange, inputRef, onBlur, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const {
          ref: controlRef,
          onBlur: controlOnBlur,
          onChange: controlOnChange,
          ...restField
        } = field;

        const handleChange = (...args) => {
          controlOnChange(...args);
          typeof onChange === 'function' && onChange(...args);
        };
        const handleBlur = (...args) => {
          controlOnBlur(...args);
          typeof onBlur === 'function' && onBlur(...args);
        };
        <MaskField
          ref={ref}
          onBlur={handleBlur}
          onChange={handleChange}
          error={error?.message || !!error}
          inputRef={propagateRefs(controlRef, inputRef)}
          {...rest}
          {...restField}
        />
      }}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [mask, setMask] = useState('+999 (999) 999 999');
    const [maskChar, setMaskChar] = useState('_');

    return (
      <Box>
        <Fieldset>
          <Fieldset.Field
            legend="mask"
            value={mask}
            onChange={setMask}
            options={[undefined, '+999 (999) 999 999', '9999-9999-9999-9999; 999']}
          />
          <Fieldset.Field
            legend="maskChar"
            value={maskChar}
            onChange={setMaskChar}
            options={[undefined, '_', '*', '#', '-']}
          />
        </Fieldset>

        <Box component="pre">
          {`<MaskField\n`}
          {`  label="Mask field"\n`}
          {`  mask="${mask}"\n`}
          {`  maskChar="${maskChar}"\n`}
          {`/>\n`}
        </Box>

        <Box p={2}>
          <MaskField
            label="Mask field"
            mask={mask}
            maskChar={maskChar}
          />
        </Box>
      </Box>
    );
  };
  MaskField.Demo = Demo;
}

export default MaskField;
