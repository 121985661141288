import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { useForm, useMessage, useAuth, useUploadPhotos, useCreateTag } from 'hooks';
import { getArray, capitalize } from 'utils/helpers';
import {
  useGetMeQuery,
  useCreateIdeaMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper } from 'components';
import { PageSubheader, IdeaForm } from 'views';
import { getLevelStyles } from 'views/LevelTag';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };

const NewIdea = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'new_idea' });
  const { auth, progress } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { form, valid, rules } = useForm();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery();

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createTag, createTagState] = useCreateTag({ me });
  const [createIdea, createIdeaState] = useCreateIdeaMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleCreateIdea = useCallback(
    async (formData) => {
      try {
        const { photos } = formData;
        const postData = mapRequest(formData);

        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;

          postData.photos = photoIds;
        }

        const { idea_id } = await createIdea({
          spaceId: me.space_id,
          ...postData,
        }).unwrap();

        if (idea_id) navigate(`/ideas/${idea_id}`);
      } catch (err) {
        if (err.status === 422) {
          err.data.detail.forEach((item) => {
            m.error(item.msg);
          });
        } else if (!!err?.data?.detail?.error) {
          return m.error(err.data.detail.error);
        } else {
          m.error(t('error_failed_create'));
        }
      }
    },
    [createIdea, uploadPhotos, me, m, t, navigate],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const loading =
    isLoading || isFetching || progress || uploadPhotosState.isLoading || createIdeaState.isLoading;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleCreateIdea}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl="/ideas"
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.create')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={3} color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>

          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <IdeaForm
              title={t('form_title')}
              rules={rules}
              loadingTags={loadingTags}
              tagsOptions={tagsOptions}
              levelOptions={levelOptions}
              loadingLevels={loadingLevels}
              defaultSourceValue={defaultSourceValue}
              onCreateTag={createTag}
            />
          </Paper>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewIdea;
