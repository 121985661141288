import { forwardRef, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import Image from 'components/Image';
import Holder from 'components/Holder';
import Fieldset from 'components/Dev/Fieldset';

import projectPreview from 'assets/images/examples/project_preview.png';

/**
 * Fit image into defined rectangle.
 * @param {string} src - Image path
 * @param {number|string} [size] - Define width/height of the image or container.
 * @param {number|string} [width] - Define width (more priority than [size]).
 * @param {number|string} [height] - Define height (more priority than [size]).
 * @param {number} [radius=2] - Container and image border radius.
 * @param {number} [stroke] - Border width (number represents theme radius sizing (1 === 4px)).
 * @param {string} [color] - Border color (could be a theme color path, e.g. 'primary.main').
 * @param {string['image'|'container']} - Where to apply size
 * @param {inherit} [fit=true] - <Image /> prop
 * @param {inherit} alt - <Image /> prop
 * @param {inherit} transition - <Image /> prop
 */
const ImageHolder = forwardRef((props, ref) => {
  const {
    p,
    src,
    alt,
    size,
    width,
    height,
    radius = 2,
    stroke,
    color,
    transition,
    fit = true,
    origin = 'image',
    ...rest
  } = props;

  if (!Number.isFinite(radius)) {
    throw new Error('[ImageHolder]: radius property should be an integer');
  }
  const imageSizes = useMemo(() => {
    const result = {
      width: '100%',
      height: '100%',
    };
    if (origin === 'image') {
      result.width = width || size || 'auto';
      result.height = height || size || 'auto';
    }
    return result;
  }, [origin, size, width, height]);

  const containerSizes = useMemo(() => {
    const result = {
      width: 'auto',
      height: 'auto',
    };
    if (origin === 'container') {
      result.width = width || size || 'auto';
      result.height = height || size || 'auto';
    }
    return result;
  }, [origin, size, width, height]);

  // Define inner image radius in case of padding
  const imageRadius = useMemo(() => {
    if (!p) {
      return undefined;
    }
    const s = Number.isFinite(stroke) ? stroke : 0;

    if (Number.isFinite(p)) {
      const pd = p / 2;
      return radius - (s / 4) - pd;
    }
    return 'inherit';
  }, [radius, p, stroke]);

  return (
    <Holder
      p={p}
      ref={ref}
      radius={radius}
      stroke={stroke}
      color={color}
      {...containerSizes}
      {...rest}
    >
      <Image
        src={src}
        alt={alt}
        fit={fit}
        {...imageSizes}
        transition={transition}
        sx={{ borderRadius: imageRadius }}
      />
    </Holder>
  );
});

if (dev) {
  const sizeOptions = [undefined, 'auto', 100, 200, 300, 500];
  const strokeOptions = [undefined, 0, 1, 2, 3, 5, 10];
  const colorOptions = [
    undefined,
    'primary.main',
    'secondary.main',
    'success.main',
    'warning.main',
    'error.main',
    'red',
    '#0033FF',
  ];
  const Demo = () => {
    const [origin, setOrigin] = useState('image');
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const [stroke, setStroke] = useState(2);
    const [radius, setRadius] = useState(5);
    const [size, setSize] = useState(500)
    const [color, setColor] = useState('primary.main');
    const [p, setP] = useState();

    return (
      <Box>
        <Box>Combination of {`<Holder />`} and {`<Image />`} components.</Box>

        <Box>
          <Fieldset>
            <Fieldset.Field
              legend="origin"
              value={origin}
              onChange={setOrigin}
              options={[undefined, 'image', 'container']}
            />
            <Fieldset.Field
              legend="size"
              value={size}
              onChange={setSize}
              options={sizeOptions}
            />
            <Fieldset.Field
              legend="width"
              value={width}
              onChange={setWidth}
              options={sizeOptions}
            />
            <Fieldset.Field
              legend="height"
              value={height}
              onChange={setHeight}
              options={sizeOptions}
            />
            <Fieldset.Field
              legend="color"
              value={color}
              onChange={setColor}
              options={colorOptions}
            />
            <Fieldset.Field
              legend="stroke"
              value={stroke}
              onChange={setStroke}
              options={strokeOptions}
            />
            <Fieldset.Field
              legend="radius"
              value={radius}
              onChange={setRadius}
              options={strokeOptions}
            />
            <Fieldset.Field
              legend="p"
              value={p}
              onChange={setP}
              options={strokeOptions}
            />
          </Fieldset>

          <Box component="pre">
            {`<ImageHolder\n`}
            {`  p={${p}}\n`}
            {`  size={${size}}\n`}
            {`  width={${width}}\n`}
            {`  height={${height}}\n`}
            {`  src="${projectPreview}"\n`}
            {`  color="${color}"\n`}
            {`  stroke={${stroke}}\n`}
            {`  radius={${radius}}\n`}
            {`/>\n`}
          </Box>

          <ImageHolder
            p={p}
            src={projectPreview}
            size={size}
            width={width}
            height={height}
            origin={origin}
            color={color}
            stroke={stroke}
            radius={radius}
            transition={false}
          />
        </Box>
      </Box>
    );
  };
  ImageHolder.Demo = Demo;
}

export default ImageHolder;
