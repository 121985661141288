import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROLE, PERMISSIONS } from 'utils/constants';

import { useAcl } from 'hooks';

import { Icon } from 'components';

const useGetPortfolioCreationOptions = (params) => {
  const { user_id } = params || {};

  const acl = useAcl();
  const navigate = useNavigate();

  const { t } = useTranslation('hooks', { keyPrefix: 'get_portfolio_creation_options' });

  return useMemo(() => {
    if (!acl.allow({ permissions: [PERMISSIONS.CREATE_PORTFOLIO] })) return [];

    const opts = [];
    if (acl.allow({ role: [ROLE.TEACHER, ROLE.ADMIN] })) {
      opts.push({
        pl: 1,
        startAdornment: <Icon name="Group" />,
        label: t('from_group_projects'),
        value: 'group_projects',
        onClick: () => navigate('/profile/portfolio/new', { state: { source: 'group_projects' } }),
      });
    }

    opts.push({
      pl: 1,
      startAdornment: <Icon name="Project" />,
      label: t('from_own_projects'),
      value: 'own_projects',
      onClick: () =>
        navigate('/profile/portfolio/new', { state: { source: 'own_projects', user_id } }),
    });

    return opts;
  }, [t, navigate, acl, user_id]);
};

export default useGetPortfolioCreationOptions;
