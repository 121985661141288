import { styled, Typography, Box } from '@mui/material';

import { dev } from 'utils/config';
import { getArray, makeOptions } from 'utils/helpers';

import { ButtonBase } from 'components';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}));

const PadButton = styled(ButtonBase, { label: 'PadSelect-PadButton' })(({ theme, active }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: theme.shape.borderRadius,
  border: '1px solid',
  borderColor: active ? theme.palette.primary.main : theme.palette.controls.main,
}));

const PadSelect = (props) => {
  const { width = 200, pads, value = [], onValue, ...rest } = props;

  return (
    <Root width={width} {...rest}>
      {getArray(pads).map((pad, index) => {
        const active = value.includes(pad.value);
        return (
          <PadButton
            key={`pad-button-${pad.value}-${index}`}
            color={active ? 'primary' : 'input'}
            active={active}
            type="button"
            size="xsmall"
            onClick={() => onValue(pad?.value)}
          >
            <Typography variant="body4">{pad.label}</Typography>
          </PadButton>
        );
      })}
    </Root>
  );
};

if (dev) {
  const alphabeticalList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const Demo = () => {
    return (
      <Box p={2}>
        <PadSelect value={['A', 'H', 'M']} pads={makeOptions(alphabeticalList)} />
      </Box>
    );
  };
  PadSelect.Demo = Demo;
}

export default PadSelect;
