import { Box, Stack, Typography, Divider, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { capitalize } from 'utils/helpers';

import { Tag, Icon, ButtonBase, Center, Ref } from 'components';

const BookmarkButton = styled(ButtonBase)(({ theme }) => ({
  padding: 0,
  height: 'auto',
  color: theme.palette.textBlack.dark,
  marginRight: 4,
}));

const MetaData = (props) => {
  const { onBookmark, bookmarked, children } = props;

  return (
    <Center width={1} justifyContent="flex-start" mb={3}>
      {onBookmark && (
        <BookmarkButton
          type="button"
          variant="text"
          sx={{ color: 'textBlack.main' }}
          onClick={() => {
            if (onBookmark) onBookmark(!bookmarked);
          }}
        >
          <Icon
            name={bookmarked ? 'BookmarkFilled' : 'Bookmark'}
            fontSize="small"
            color="inherit"
          />
        </BookmarkButton>
      )}
      <Stack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        divider={
          <Divider
            sx={{ margin: '5px 16px', borderColor: 'other.line' }}
            orientation="vertical"
            flexItem
          />
        }
      >
        {children}
      </Stack>
    </Center>
  );
};

const Author = (props) => {
  const { author, slug } = props;

  const { t } = useTranslation('views', { keyPrefix: 'ArticleGeneralInformation' });

  if (!author) return null;

  return (
    <>
      <Typography variant="body2" color="textBlack.dark">
        {t('author_title', { name: slug })}
      </Typography>
      <Tag
        ml={0.5}
        avatar={author.avatar}
        label={author.name}
        bgcolor="input.main"
        color="textRegular.light"
      />
    </>
  );
};

const DateCreated = (props) => {
  const { dateCreated } = props;

  const { t } = useTranslation('views', { keyPrefix: 'ArticleGeneralInformation' });

  if (!dateCreated) return null;

  return (
    <>
      <Typography mr={0.5} variant="body2" color="textBlack.dark">
        {t('creation_date')}
      </Typography>
      <Typography variant="body3" color="textBlack.dark">
        {dateCreated}
      </Typography>
    </>
  );
};

const Relation = (props) => {
  const { title, link, links } = props;

  return (
    <>
      <Typography mr={0.5} variant="body2" color="textBlack.dark">
        {title}
      </Typography>
      {link && (
        <Typography variant="body3" key={`relation-link-${link.label}`}>
          <Ref color="textRegular.light" to={link.to}>
            {link.label}
          </Ref>
        </Typography>
      )}
      {links && (
        <Stack
          direction="row"
          divider={
            <Box component="span" mr={0.5}>
              ,
            </Box>
          }
        >
          {links.map((link) => (
            <Typography variant="body3" key={`relation-link-${link.label}`}>
              <Ref color="textRegular.light" to={link.to}>
                {link.label}
              </Ref>
            </Typography>
          ))}
        </Stack>
      )}
    </>
  );
};

const Schedule = (props) => {
  const { title, schedule = {} } = props;

  const { t } = useTranslation('views', { keyPrefix: 'MetaData' });

  return (
    <>
      <Icon fontSize={24} name="Calendar" />
      <Typography mx={0.5} variant="body2" color="textBlack.dark">
        {title}
      </Typography>

      {schedule && (
        <Stack
          direction="row"
          flexWrap="wrap"
          divider={
            <Box component="span" mr={0.5}>
              ,
            </Box>
          }
        >
          {Object.entries(schedule).map(([day, time]) =>
            time ? (
              <Typography variant="body3" key={`relation-link-${day}`}>
                {t('every')} {capitalize(day)} {t('at')}{' '}
                {time.replace(/^(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2')}
              </Typography>
            ) : undefined,
          )}
        </Stack>
      )}
    </>
  );
};

const Text = (props) => {
  const { title, text } = props;

  return (
    <>
      <Typography mr={0.5} variant="body2" color="textBlack.dark">
        {title}
      </Typography>
      <Typography variant="body3">{text}</Typography>
    </>
  );
};

MetaData.Author = Author;
MetaData.DateCreated = DateCreated;
MetaData.Relation = Relation;
MetaData.Schedule = Schedule;
MetaData.Text = Text;

export default MetaData;
