const makeTimeOptions = () => {
  let interval = 30;
  let options = [];
  let startTime = 0;

  for (var i = 0; startTime < 24 * 60; i++) {
    let hh = Math.floor(startTime / 60);
    let mm = startTime % 60;
    let time = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
    options[i] = { label: time, value: time };
    startTime = startTime + interval;
  }

  return options;
};

export const timeSeries = makeTimeOptions();
