import { Typography, Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon, Select, Form } from 'components';

const Filter = (props) => {
  const { formOptions, ...rest } = props;

  const { t } = useTranslation('pages', { keyPrefix: 'single_collection' });

  return (
    <Form {...rest}>
      <Stack direction="row" alignItems="center" mb={1}>
        <Icon fontSize="inherit" name="Filter" />
        <Typography variant="body2" ml={1}>
          {t('filter_by')}
        </Typography>
      </Stack>

      <Stack direction="row" mb={2} spacing={2}>
        <Box width={288}>
          <Select.Control
            fullWidth
            options={formOptions?.users || []}
            name="student"
            itemsSize="small"
            placeholder={t('user')}
          />
        </Box>
        <Box width={288}>
          <Select.Control
            fullWidth
            options={formOptions?.groups || []}
            name="group"
            placeholder={t('group')}
          />
        </Box>
      </Stack>
    </Form>
  );
};

export default Filter;
