import { forwardRef, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

import { dev } from 'utils/config';
import Icon from 'components/Icon';
import ButtonBase from 'components/ButtonBase';
import Fieldset from 'components/Dev/Fieldset';

const Button = styled(ButtonBase, {
  label: 'IconButton-Root',
  shouldForwardProp: (prop) => !['border'].includes(prop),
})(({ theme, border, color }) => ({
  padding: theme.spacing(0.25),
  ...(!border && {
    borderColor: 'transparent',
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  '&:focus': {
    boxShadow: 'none',
    '&.MuiButton-contained': {
      backgroundColor: theme.palette[color]?.dark,
    },
    '&.MuiButton-outlined, &.MuiButton-text': {
      color: theme.palette[color]?.dark,
    },
  },
}));

/**
 * Wrapper arround icon to make it behave as a button
 */
const IconButton = forwardRef((props, ref) => {
  const {
    name,
    fontSize,
    hidden,
    children,
    color = 'primary',
    border = false,
    size = 'small',
    ...rest
  } = props;

  const icon = useMemo(() => {
    if (name) {
      return (<Icon name={name} fontSize={fontSize} />);
    }
    return children;
  }, [name, fontSize, children]);

  return (
    <Button
      radius={2}
      {...rest}
      ref={ref}
      size={size}
      color={color}
      disableRipple
      border={border}
    >
      {icon}
    </Button>
  );
});

if (dev) {
  const Demo = () => {
    const [color, setColor] = useState();
    const [variant, setVariant] = useState();
    const [border, setBorder] = useState();
    const [name, setName] = useState('Add');
    const [disabled, setDisabled] = useState();

    return (
      <Box>
        <Box mb={2}>Instead of "name" property React Element also could be passed as a child.</Box>
        <Fieldset>
          <Fieldset.Field
            legend="color"
            value={color}
            onChange={setColor}
            options={[undefined, 'primary', 'secondary', 'error', 'warning']}
          />
          <Fieldset.Field
            legend="name"
            value={name}
            onChange={setName}
            options={['Add', 'Student', 'Birthday']}
          />
          <Fieldset.Field
            legend="disabled"
            value={disabled}
            onChange={setDisabled}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="border"
            value={border}
            onChange={setBorder}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="variant"
            value={variant}
            onChange={setVariant}
            options={[undefined, 'contained', 'outlined', 'text']}
          />
        </Fieldset>

        <Box component="pre">
          {`<IconButton\n`}
          {`  name="${name}"\n`}
          {`  color="${color}"\n`}
          {`  disabled={${disabled}}\n`}
          {`  border={${border}}\n`}
          {`  variant={${variant}}\n`}
          {`/>\n`}
        </Box>

        <Box mb={2}>
          <IconButton
            name={name}
            color={color}
            disabled={disabled}
            border={border}
            variant={variant}
          />
        </Box>
      </Box>
    );
  };
  IconButton.Demo = Demo;
}

export default IconButton;
