import { Stack as MuiStack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { withProps } from 'hooks';

import {
  Input,
  Select,
  SelectMultiple,
  IconButton,
  Icon,
  Repeater,
  FormRow,
  TextEditor,
  Switch,
  FormTitle,
} from 'components';
import { UploadImage } from 'views';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const IdeaForm = (props) => {
  const {
    title,
    rules: { required, startsWithUppercase },
    defaultSourceValue,

    tagsOptions,
    loadingTags,

    levelOptions,
    loadingLevels,

    onCreateTag,
  } = props;

  const { t } = useTranslation('views', { keyPrefix: 'IdeaForm' });

  return (
    <>
      <FormTitle width="100%">
        <Typography color="secondary.main" variant="body2">
          {title}
        </Typography>
      </FormTitle>
      <Stack mt={2}>
        <FormRow label={t('name.label')}>
          <Input.Control
            fullWidth
            type="text"
            name="name"
            rules={{
              required,
              minLength: 6,
              validate: (v) => startsWithUppercase(v, t('name.label')),
            }}
            placeholder={t('name.placeholder')}
          />
        </FormRow>
        <FormRow optional label={t('sources.label')} alignItems="flex-start">
          <Repeater.Control
            name="sources"
            keyProperty="url"
            defaultValue={[{ ...defaultSourceValue }]}
            itemDefaultValue={{ ...defaultSourceValue }}
          >
            {({ name, index, remove }) => (
              <Input.Control
                fullWidth
                type="text"
                name={`${name}.url`}
                placeholder={t('sources.placeholder')}
                iconRight={
                  <IconButton variant="text" color="error" onClick={() => remove(index)}>
                    <Icon name="Close" />
                  </IconButton>
                }
              />
            )}
          </Repeater.Control>
        </FormRow>

        <FormRow label={t('copyright.label')} optional>
          <Input.Control
            fullWidth
            type="text"
            name="copyright"
            placeholder={t('copyright.placeholder')}
          />
        </FormRow>

        <FormRow label={t('photos.label')} optional alignItems="flex-start">
          <UploadImage.Control name="photos" />
        </FormRow>

        <FormRow label={t('level.label')}>
          <Select.Control
            fullWidth
            name="level"
            placeholder={t('level.placeholder')}
            rules={{ required }}
            options={levelOptions}
            disabled={loadingLevels}
          />
        </FormRow>

        <FormRow label={t('tags.label')} optional>
          <SelectMultiple.Control
            creatable
            fullWidth
            itemsMax={6}
            defaultValue={[]}
            options={tagsOptions}
            disabled={loadingTags}
            name="tags"
            placeholder={t('tags.placeholder')}
            onCreate={onCreateTag}
          />
        </FormRow>

        <FormRow label={t('description.label')} alignItems="flex-start" optional>
          <TextEditor.Control
            fullWidth
            name="description"
            placeholder={t('description.placeholder')}
          />
        </FormRow>

        <FormRow label={t('public.label')}>
          <Switch.Control fullWidth name="public" />
        </FormRow>
      </Stack>
    </>
  );
};

export default IdeaForm;
