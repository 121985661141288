import { bindActionCreators, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';

import api, { actions as apiActions } from './api';
import user from './state/user';
import pageFilter from './state/pageFilter';
import notifications from './state/notifications';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [user.name]: user.reducer,
    [pageFilter.name]: pageFilter.reducer,
    [notifications.name]: notifications.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat([
        ({ dispatch }) =>
          (next) =>
          (action) => {
            // Logout on 401 error status
            if (isRejectedWithValue(action) && action?.payload?.status === 401) {
              dispatch(user.actions.logout());
            }
            return next(action);
          },
      ]),
});

const boundActions = bindActionCreators(
  {
    ...user.actions,
    ...pageFilter.actions,
    ...notifications.actions,
  },
  store.dispatch,
);

export const actions = {
  ...boundActions,
  ...apiActions,
};

export const selectors = {
  ...user.selectors,
  ...pageFilter.selectors,
  ...notifications.selectors,
};

export default store;
