import { forwardRef } from 'react';
import { Box } from '@mui/material';

import { dev } from 'utils/config';
import { makeOptions } from 'utils/helpers';
import Dropdown from 'components/Dropdown';
import Menu from 'components/Menu';
import Button from 'components/Button';

/**
 * Combination of <Dropdown /> and <Menu /> components with properties of both.
 */
const DropdownMenu = forwardRef((props, ref) => {
  const {
    trigger,
    target,
    box,
    collapse,
    anchorOrigin,
    transformOrigin,
    offsetX,
    offsetY,
    fullWidth,
    onToggle,
    open,
    paperProps,
    onAfterClose,
    menuRef,
    ...rest
  } = props;

  return (
    <Dropdown
      ref={ref}
      box={box}
      collapse={collapse}
      open={open}
      target={target}
      trigger={trigger}
      offsetX={offsetX}
      offsetY={offsetY}
      onToggle={onToggle}
      fullWidth={fullWidth}
      paperProps={paperProps}
      anchorOrigin={anchorOrigin}
      onAfterClose={onAfterClose}
      transformOrigin={transformOrigin}
    >
      <Menu width="100%" ref={menuRef} {...rest} />
    </Dropdown>
  );
});

if (dev) {
  const Demo = () => {
    return (
      <Box p={2}>
        <Box mb={2}>Combination of {`<Dropdown />`} and {`<Menu />`} components, with properties of both.</Box>

        <Box component="pre" mb={2}>
          {`<Box p={2} display="flex" gap={2}>\n`}
          {`  <DropdownMenu\n`}
          {`    box\n`}
          {`    minWidth={200}\n`}
          {`    target={<Button>Profile</Button>}\n`}
          {`    options={makeOptions(['profile', 'settings'])}\n`}
          {`    footer={<Button size="small" fullWidth>Sign Out</Button>}\n`}
          {`  />\n`}
          {`\n`}
          {`  <DropdownMenu\n`}
          {`    minWidth={200}\n`}
          {`    target={<Button>Profile</Button>}\n`}
          {`    options={makeOptions(['profile', 'settings'])}\n`}
          {`    footer={<Button size="small" fullWidth>Sign Out</Button>}\n`}
          {`  />\n`}
          {`</Box>\n`}
        </Box>

        <Box p={2} display="flex" gap={2}>
          <DropdownMenu
            box
            minWidth={200}
            target={<Button>Profile</Button>}
            options={makeOptions(['profile', 'settings'])}
            footer={<Button size="small" fullWidth>Sign Out</Button>}
          />

          <DropdownMenu
            minWidth={200}
            target={<Button>Profile</Button>}
            options={makeOptions(['profile', 'settings'])}
            footer={<Button size="small" fullWidth>Sign Out</Button>}
          />
        </Box>
      </Box>
    );
  };
  DropdownMenu.Demo = Demo;
}

export default DropdownMenu;
