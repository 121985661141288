import {
  forwardRef,
  useState,
  Children,
  useMemo,
  cloneElement,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';

import { Box, Collapse, styled } from '@mui/material';

import { dev } from 'utils/config';
import { keyCodes, propagateRefs } from 'utils/helpers';

import Button from 'components/Button';
import Fieldset from 'components/Dev/Fieldset';

const Root = styled(Box, {
  label: 'DropdownCollapse-Root',
})({
  display: 'inline-block',
});

const AdjustContainer = styled(Box, {
  label: 'DropdwonBox-AdjustContainer',
})(({ theme }) => ({
  position: 'relative',
}));

const StyledCollapse = styled(Collapse, {
  label: 'DropdownCollapse-Collapse',
})({
  width: '100%',
  '&.MuiCollapse-hidden': {
    width: 0,
  },
});

/**
 * Allows to attach a dropdown to an element. Will automatically show the dropdown on trigger event
 * (click by default), and hide it after click/focus outside.
 * NOTE! This component properly works only with static "target" elements!
 * NOTE! Dropdown will not collapse if an inner element has been focused or clicked.
 * TODO: Add hover trigger if needed.
 * @prop {string['click'|'focus']} [trigger="click"] - Which trigger event should show the dropdown
 * content.
 * @prop {ReactNode} target - Trigger target (button, icon, etc). Should be able to add a trigger
 * event listener (onClick, onFocus).
 * @prop {ReactNode} children - The dropdown content.
 * @prop {boolean} [fullWidth] - Setup 100% width to the root (take all the content width). Not too
 * @prop {function} [onToggle] - toggle callback, returns new state boolean
 * @prop {boolean} [open] - if presented, will be used as a state (controlled component)
 * @prop {function} [onAfterClose] - will be fired after close animation finishing
 */
const DropdownCollapse = forwardRef((props, ref) => {
  const {
    children,
    target,
    trigger = 'click',
    fullWidth,
    onToggle,
    paperProps,
    open: customOpen,
    onAfterClose,
    ...rest
  } = props;

  const rootRef = useRef(null);
  const cloneRef = useRef(null);
  const targetRef = useRef(null);

  const [innerOpen, setInnerOpen] = useState(!!customOpen);

  const open = useMemo(
    () => (typeof customOpen === 'boolean' ? customOpen : innerOpen),
    [customOpen, innerOpen],
  );

  const triggerTarget = useMemo(() => {
    const targetChilds = Children.toArray(target);

    if (targetChilds.length > 1) {
      console.error('[DrpdownBox]: only one node allowed as a target (first one will be used)');
    }
    return (
      targetChilds[0] &&
      cloneElement(targetChilds[0], {
        ref: propagateRefs(targetRef, targetChilds[0].ref),
      })
    );
  }, [target]);

  const handleToggle = useCallback(
    (v) => {
      const value = typeof v === 'boolean' ? v : !open;
      typeof onToggle === 'function' && onToggle(value);
      setInnerOpen(value);
    },
    [onToggle, open],
  );

  useLayoutEffect(() => {
    const { current } = targetRef;
    const { current: clone } = cloneRef;

    if (current && clone) {
      clone.innerHTML = current.outerHTML;
    }
  }, []);

  useLayoutEffect(() => {
    const { current } = targetRef;
    const { current: root } = rootRef;
    const { current: clone } = cloneRef;

    if (current && root) {
      if (clone) clone.innerHTML = current.outerHTML;

      const listenEsc = (e) => {
        if (e.keyCode === keyCodes.esc) {
          handleToggle(false);
        }
      };
      const listenClick = () => {
        trigger === 'click' && handleToggle();
      };
      const listenFocus = () => {
        trigger === 'focus' && handleToggle(true);
      };
      const listenOutsideClick = (e) => {
        if (['click', 'focus'].includes(trigger)) {
          if (!root.contains(e.target)) {
            handleToggle(false);
          }
        }
      };
      const listenOutBlur = (e) => {
        if (['focus', 'click'].includes(trigger)) {
          if (!e.relatedTarget || !root.contains(e.relatedTarget)) {
            handleToggle(false);
          }
        }
      };
      current.addEventListener('click', listenClick);
      current.addEventListener('focus', listenFocus);
      window.addEventListener('focusout', listenOutBlur);
      window.addEventListener('click', listenOutsideClick);
      window.addEventListener('keydown', listenEsc);

      return () => {
        current.removeEventListener('click', listenClick);
        current.removeEventListener('focus', listenFocus);
        window.removeEventListener('focusout', listenOutBlur);
        window.removeEventListener('click', listenOutsideClick);
        window.removeEventListener('keydown', listenEsc);
      };
    }
  }, [trigger, handleToggle]);

  if (!triggerTarget) {
    console.error('[DropdownCollapse]: "target" prop is required!');
    return null;
  }

  return (
    <Root ref={ref} width={fullWidth ? '100%' : 'auto'} {...rest}>
      <AdjustContainer>
        <Box open={open} ref={rootRef} tabIndex={open ? 0 : undefined}>
          {triggerTarget}

          <StyledCollapse in={open} onExited={onAfterClose}>
            <Box mt={0.5}>{children}</Box>
          </StyledCollapse>
        </Box>
      </AdjustContainer>
    </Root>
  );
});

if (dev) {
  const Demo = () => {
    const [trigger, setTrigger] = useState();
    const [open, setOpen] = useState();
    const [fullWidth, setFullWidth] = useState();

    return (
      <Box>
        <Box mb={2}>Click trigger will not open dropdown with "Tab" focusing.</Box>

        <Fieldset>
          <Fieldset.Field
            legend="trigger"
            value={trigger}
            onChange={setTrigger}
            options={[undefined, 'click', 'focus']}
          />
          <Fieldset.Field
            legend="open"
            value={open}
            onChange={setOpen}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="fullWidth"
            value={fullWidth}
            onChange={setFullWidth}
            options={[undefined, true, false]}
          />
        </Fieldset>

        <Box component="pre">
          {`<DropdownCollapse\n`}
          {`  trigger="${trigger}"\n`}
          {`  open={${open}}\n`}
          {`  fullWidth={${fullWidth}}\n`}
          {`  target={\n`}
          {`    <Button>Dropdown button</Button>\n`}
          {`  }\n`}
          {`>\n`}
          {`  <Box display="flex" width="300px">\n`}
          {`    Dropdown content\n`}
          {`  </Box>\n`}
          {`</DropdownCollapse>\n`}
        </Box>

        <Box p={4}>
          <DropdownCollapse
            trigger={trigger}
            open={open}
            fullWidth={fullWidth}
            target={<Button>Dropdown</Button>}
          >
            <Box display="flex">
              <div>Dropdown content</div>
            </Box>
          </DropdownCollapse>
        </Box>
      </Box>
    );
  };
  DropdownCollapse.Demo = Demo;
}

export default DropdownCollapse;
