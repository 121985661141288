import { getArray, editorStateToRawHtml } from 'utils/helpers';
import {
  phoneRegexp,
  includesNumbersRegexp,
  includesSpecialsRegexp,
  includesUppercaseRegexp,
  emailRegexp,
  descriptionSpecialsRegexp,
} from 'utils/regexps';

export const validatePhone = (v) => phoneRegexp.test(v);

export const validateEmail = (v) => emailRegexp.test(v);

export const validateNotEmptyArray = (v) => getArray(v).filter(Boolean).length > 0;

export const validatePasswordLength = (v) => {
  return typeof v === 'string' && v.length >= 8;
};

export const validatePasswordNumber = (v) => {
  return typeof v === 'string' && includesNumbersRegexp.test(v);
};

export const validatePasswordSpecials = (v) => {
  return typeof v === 'string' && includesSpecialsRegexp.test(v);
};

export const validatePasswordUppercase = (v) => {
  return typeof v === 'string' && includesUppercaseRegexp.test(v);
};

export const validatePasswordWithDetails = (v) => {
  const length = validatePasswordLength(v);
  const number = validatePasswordNumber(v);
  const special = validatePasswordSpecials(v);
  const uppercase = validatePasswordUppercase(v);
  const valid = length && number && special && uppercase;

  return { valid, length, number, special, uppercase };
};

export const validateDescriptionWithDetails = (v) => {
  const value = editorStateToRawHtml(v);
  const length = typeof value === 'string' && value.length >= 10;
  const special = typeof value === 'string' && !descriptionSpecialsRegexp.test(value);

  const valid = length && special;
  return { valid, length, special };
};

export const validatePassword = (v) => {
  return validatePasswordWithDetails(v).valid;
};

export const validateBirthday = (v) => {
  const now = new Date();

  const day = +v.day > 0 && +v.day <= 31;
  const month = !!v?.month || v?.month === 0;
  const year = +v?.year <= now.getFullYear() && +v?.year > 1900;
  const valid = day && month && year;

  return { valid, day, month, year };
};
