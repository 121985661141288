import { useState, useMemo, useEffect, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { usePageFilter, useMessage } from 'hooks';
import { getArray, propagateRefs, reorder } from 'utils/helpers';

import { selectors } from 'store/state/pageFilter';

import { Paper, Input, PaperCard, Button } from 'components';
import { QuickFilters, CardContent, FiltersBar } from 'views';

const { selectFilters, selectActiveFilters } = selectors;

const ProjectsRoot = styled(Stack, { label: 'ProjectSearch-ProjectsRoot' })(({ theme }) => ({
  minHeight: '500px',
  maxHeight: '500px',
  overflow: 'auto',
}));

const isSelected = (value, option) => {
  return value.some((selected) => option.id === selected.id);
};

const ProjectItem = forwardRef((props, ref) => {
  const { project, selected, locked, onSelect, onDelete, ...rest } = props;

  return (
    <PaperCard
      ref={ref}
      key={project.id}
      size="small"
      border
      sx={selected ? { borderColor: 'primary.main' } : undefined}
      onClick={(e) => {
        e.preventDefault();
        if (locked) return;
        typeof onSelect === 'function' && onSelect(project);
      }}
      {...rest}
    >
      <CardContent
        variant={locked ? 'project_reordable' : 'project_selectable'}
        size="small"
        id={project.id}
        owner={project.owner || project.project_owner}
        title={project.name}
        description={project.description}
        photos={project.photos}
        selected={selected}
        onDelete={() => {
          typeof onDelete === 'function' && onDelete(project);
        }}
      />
    </PaperCard>
  );
});

const ProjectSearch = forwardRef((props, ref) => {
  const { t } = useTranslation('views', { keyPrefix: 'ProjectSearch' });
  const m = useMessage();

  const {
    searchPlaceholder = t('search_project'),
    max = -1,
    value = [],
    projects,
    loading,
    search,
    onSearch,
    onSelect,
    onValue,
  } = props;

  const [locked, setLocked] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  const { onSetActiveFilter, onResetPageFilter, onResetActiveFilters } = usePageFilter({
    minSearchLength: 0,
  });

  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(selectActiveFilters);

  useEffect(() => {
    return () => onResetPageFilter();
  }, [onResetPageFilter]);

  useEffect(() => {
    setCurrentValue((current) => {
      if (current?.length === 0 && value.length > 0) {
        setLocked(true);
        return value;
      }
      return current;
    });
  }, [value, setLocked]);

  const handleRemoveSelectedFilters = useCallback(
    ({ name, value }) => {
      const changed = getArray(activeFilters[name]).filter((item) => item.id !== value.id);
      onSetActiveFilter(name, changed.length > 0 ? changed : undefined);
    },
    [onSetActiveFilter, activeFilters],
  );

  const handleSelectProject = useCallback(
    (project) => {
      typeof onSelect === 'function' && onSelect(project);

      const foundIndex = currentValue.findIndex((item) => item.id === project.id);
      if (foundIndex === -1) {
        setCurrentValue([...currentValue, project]);
      } else {
        const changed = [...currentValue];
        changed.splice(foundIndex, 1);
        setCurrentValue(changed);
      }
    },
    [currentValue, onSelect, setCurrentValue],
  );

  const handleDeleteProject = useCallback(
    (project) => {
      const foundIndex = currentValue.findIndex((item) => item.id === project.id);
      const changed = [...currentValue];
      changed.splice(foundIndex, 1);
      setCurrentValue(changed);
      typeof onValue === 'function' && onValue(changed);
    },
    [currentValue, onValue],
  );

  const validateProjects = useCallback(() => {
    if (max !== -1 && currentValue?.length > max) {
      m.error(t('error.max_projects', { max }));
      return false;
    }

    return true;
  }, [max, currentValue, m, t]);

  const handleLockProjects = useCallback(() => {
    if (!validateProjects()) return;

    onSearch('');
    setLocked(true);
    setCurrentValue([]);
    typeof onValue === 'function' && onValue(currentValue);
  }, [onValue, currentValue, onSearch, validateProjects]);

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      setCurrentValue((items) => {
        const reordered = reorder(items, result.source.index, result.destination.index);
        typeof onValue === 'function' && onValue(reordered);
        return reordered;
      });
    },
    [setCurrentValue, onValue],
  );

  const handleSearch = useCallback(
    (s) => {
      typeof onSearch === 'function' && onSearch(s);
      if (locked && s.length > 0) {
        setLocked(false);
        setCurrentValue(value);
      }
    },
    [onSearch, value, locked],
  );

  const filteredProjects = useMemo(() => {
    if (locked) return value;
    return projects || [];
  }, [projects, value, locked]);

  return (
    <Paper ref={ref} width="100%" overflow="visible" pt={4} pb={4} px={15} loading={loading}>
      <Input
        value={search}
        onValue={handleSearch}
        iconLeft="Search"
        fullWidth
        placeholder={searchPlaceholder}
      />
      {max !== -1 && (
        <Typography mt={0.5} variant="caption1" color="textBlack.contrastText">
          {t('choose_max_projects', { max })}
        </Typography>
      )}

      <FiltersBar
        mt={2}
        size="small"
        filters={activeFilters}
        onRemove={handleRemoveSelectedFilters}
        onClear={onResetActiveFilters}
      />

      <QuickFilters
        mt={2}
        filters={filters}
        activeFilters={activeFilters}
        onChange={onSetActiveFilter}
      />

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ProjectsRoot
              width={1}
              mt={2}
              spacing={2}
              pr={1.5}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {getArray(filteredProjects).map((project, index) => {
                const selected = isSelected(currentValue, project);
                return (
                  <Draggable
                    key={project.id}
                    draggableId={project.id}
                    index={index}
                    isDragDisabled={!locked}
                  >
                    {(provided, snapshot) => (
                      <ProjectItem
                        project={project}
                        selected={selected}
                        locked={locked}
                        onSelect={handleSelectProject}
                        onDelete={handleDeleteProject}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{ ...provided.draggableProps.style }}
                      />
                    )}
                  </Draggable>
                );
              })}
            </ProjectsRoot>
          )}
        </Droppable>
      </DragDropContext>

      {currentValue?.length > 0 && !locked && (
        <Stack direction="row" spacing={1.5} mt={2.5}>
          <Button border={2} type="button" size="small" onClick={handleLockProjects}>
            {t('select')}
          </Button>
        </Stack>
      )}
    </Paper>
  );
});

ProjectSearch.Control = forwardRef((props, ref) => {
  const { name, rules, defaultValue = [], onValue, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const { ref: controlRef, onChange: controlOnChange, ...restField } = field;

        const handleOnChange = (...args) => {
          controlOnChange(...args);
          typeof onValue === 'function' && onValue(...args);
        };

        return (
          <ProjectSearch
            name={name}
            ref={propagateRefs(controlRef, ref)}
            error={error?.message || !!error}
            onValue={handleOnChange}
            {...rest}
            {...restField}
          />
        );
      }}
    />
  );
});

export default ProjectSearch;
