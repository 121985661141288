import { forwardRef, useMemo, useState } from 'react';
import { useTheme, styled, Box, Avatar as MuiAvatar, Badge as MuiBadge } from '@mui/material';

import { dev } from 'utils/config';
import { withProps } from 'hooks';
import Fieldset from 'components/Dev/Fieldset';
import avatar from 'assets/images/examples/avatar.png';

const StyledBadge = styled(MuiBadge, {
  shouldForwardProp: (prop) => !['circle', 'radius'].includes(prop),
})(({ theme, circle, radius }) => ({
  '& .MuiBadge-badge': {
    borderWidth: '1px',
    borderColor: theme.palette.success.contrastText,
    borderStyle: 'solid',
    '&.MuiBadge-anchorOriginBottomRight': {
      bottom: circle ? '13%' : (radius === 'auto' ? '5%' : `${radius}px`),
      right: circle ? '13%' : (radius === 'auto' ? '5%' : `${radius}px`),
    },
  },
}));

const Badge = withProps(StyledBadge, {
  overlap: 'circular',
  variant: 'dot',
  color: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
});

const StyledAvatar = styled(MuiAvatar, {
  shouldForwardProp: (prop) => prop !== 'size',
})(({ size }) => ({
  fontSize: `${size / 2}px`,
}));

export const sizeScheme = {
  medium: 40,
  small: 32,
  xsmall: 24,
};

/**
 * @prop {boolean} [circle] - Use circle avatar
 * @prop {string['auto']|number} [radius='auto'] - Calculate border radius automatically ('auto')
 * or setup custom border radius (not working if "circle" property set to [true]).
 * @prop {number|string['medium'|'small'|'xsmall']} [size=40] - Avatar size
 * @prop {string} [color="primary.main"] - Empty avatar background
 * @prop {string} [children] - Fallback text
 * @prop {string} [src] - Image link
 * @prop {boolean} [badge] - Show avatar badge
 */
const Avatar = forwardRef((props, ref) => {
  const {
    sx,
    src,
    badge,
    color = 'primary.main',
    circle,
    size = 40,
    border = true,
    radius = 'auto',
    children,
    ...rest
  } = props;

  const theme = useTheme();

  const sizing = useMemo(() => {
    return {
      width: typeof size === 'string' ? sizeScheme[size] : size,
      height: typeof size === 'string' ? sizeScheme[size] : size,
    };
  }, [size]);

  const borderRadius = useMemo(() => {
    if (circle) {
      return undefined;
    }
    if (Number.isFinite(radius)) {
      return radius;
    }
    return Math.max(1, sizing.width / 5 / theme.shape.borderRadius);
  }, [radius, circle, theme, sizing]);

  return (
    <Badge {...rest} invisible={!badge} circle={circle} radius={radius}>
      <StyledAvatar
        src={src}
        size={sizing.width}
        variant={circle ? 'circular' : 'rounded'}
        sx={{
          ...sx,
          ...sizing,
          borderRadius,
          bgcolor: color,
          border: `1px solid white`,
          borderWidth: border ? 1 : 0,
        }}
      >
        {children}
      </StyledAvatar>
    </Badge>
  );
});

if (dev) {
  const Demo = () => {
    const [src, setSrc] = useState('./avatar.png');
    const [children, setChildren] = useState();
    const [badge, setBadge] = useState();
    const [size, setSize] = useState();
    const [circle, setCircle] = useState();
    const [color, setColor] = useState();
    const [radius, setRadius] = useState();

    return (
      <Box bgcolor="white" p={3}>
        <Fieldset>
          <Fieldset.Field
            legend="color"
            value={color}
            onChange={setColor}
            options={[undefined, 'primary.main', 'secondary.light', 'red', '#0055FF']}
          />
          <Fieldset.Field
            legend="circle"
            value={circle}
            onChange={setCircle}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="size"
            value={size}
            onChange={setSize}
            options={[undefined, 'medium', 'small', 'xsmall', 50, 70]}
          />
          <Fieldset.Field
            legend="children"
            value={children}
            onChange={setChildren}
            options={[undefined, 'A', 'NB']}
          />
          <Fieldset.Field
            legend="src"
            value={src}
            onChange={setSrc}
            options={[undefined, './avatar.png']}
          />
          <Fieldset.Field
            legend="badge"
            value={badge}
            onChange={setBadge}
            options={[undefined, true, false]}
          />
          <Fieldset.Field
            legend="radius"
            value={radius}
            onChange={setRadius}
            options={[undefined, 'auto', 1, 2, 5, 10]}
          />
        </Fieldset>

        <Box mt={2}>
          <Box component="pre">
            {`<Avatar\n`}
            {`  color="${color}"\n`}
            {`  size="${size}"\n`}
            {`  badge={${badge}}\n`}
            {`  circle={${circle}}\n`}
            {`  radius={${radius}}\n`}
            {`  src={${src}}\n`}
            {`>\n`}
            {`  ${children}\n`}
            {`</Avatar>\n`}
          </Box>

          <Avatar
            radius={radius}
            color={color}
            size={size}
            badge={badge}
            circle={circle}
            src={!!src ? avatar : src}
          >
            {children}
          </Avatar>
        </Box>
      </Box>
    );
  };
  Avatar.Demo = Demo;
}

export default Avatar;
