import { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetSpacesQuery } from 'store/api';
import { selectors } from 'store/state/pageFilter';
import { useAuth, usePageFilter } from 'hooks';
import { FILTER_TYPE } from 'utils/constants';
import { getArray, formatDate } from 'utils/helpers';

import { CardContent } from 'views';
import { ProgressBox, Pagination, PaperCard } from 'components';

const { selectPageFilters, selectPagination } = selectors;

const mapRequest = (pageFilters, pagination) => {
  const { activeFilters } = pageFilters;

  const { status, type } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    status: getArray(status).map((s) => s.id),
    type: getArray(type).map((t) => t.id),
    limit: perPage,
    offset: offset,
  };
};

const AdminAccounts = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'administration' });
  const { progress } = useAuth();
  const { onSetFilter, onChangePage } = usePageFilter();

  const pageFilters = useSelector(selectPageFilters);
  const pagination = useSelector(selectPagination);

  const {
    data,
    isLoading: spacesLoading,
    isFetching: spacesFetching,
  } = useGetSpacesQuery(mapRequest(pageFilters, pagination));

  useEffect(() => {
    if (!data) return;
    onSetFilter('type', FILTER_TYPE.ROLES, {
      tags: getArray(data.filter_data.space_type).map((role) => ({
        id: role.name,
        name: t(`roles.${role.name}`),
        qty: role.qty,
      })),
    });
    onSetFilter('status', FILTER_TYPE.STATUS, {
      tags: getArray(data.filter_data.space_status).map((status) => ({
        id: status.name,
        name: status.name,
        qty: status.qty,
      })),
    });
  }, [onSetFilter, data, t]);

  const loading = progress || spacesLoading || spacesFetching;

  return (
    <Box>
      <ProgressBox loading={loading}>
        <Grid container spacing={3}>
          {getArray(data?.spaces).map((space) => (
            <Grid key={`spaces-grid-${space.id}`} item xs={6}>
              <PaperCard to={`/administration/spaces/${space.id}`}>
                <CardContent
                  variant="space"
                  avatar={space.avatar}
                  name={space.name}
                  role={space.role}
                  members={space.members}
                  status={space.status}
                  registrationDate={formatDate(space.registration_date)}
                />
              </PaperCard>
            </Grid>
          ))}
        </Grid>
      </ProgressBox>

      <Pagination
        total={data?.spaces_qty}
        page={pagination?.page}
        mt={6}
        onChangePage={onChangePage}
      />
    </Box>
  );
};

export default AdminAccounts;
