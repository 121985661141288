import {
  NotFound,
  Dev,
  Login,
  SignUp,
  ForgotPassword,
  EmailVerification,
  PhoneVerification,
  ResetPassword,
  Home,
  KnowledgeBase,
  Tip,
  NewTip,
  EditTip,
  Question,
  NewQuestion,
  EditQuestion,
  NewIdea,
  Idea,
  EditIdea,
  Ideas,
  Profile,
  EditProfile,
  NewProject,
  Project,
  Learning,
  NewCollection,
  Collection,
  ChildProject,
  EditProject,
  EditCollection,
  ChildCollection,
  NewPortfolio,
  Portfolio,
  PortfolioList,
  EditPortfolio,
  NewStudent,
  NewParent,
  NewUser,
  NewTeacher,
  NewAdmin,
  Administration,
  UserProfile,
  UserPortfolio,
  UserProjects,
  NewGroup,
  EditGroup,
  Group,
  GroupPortfolio,
  GroupProjects,
  LoginFromInvitation,
  EditAdmin,
  EditParent,
  FavoriteIdeas,
  EditTeacher,
  EditStudent,
  Dashboard,
  Settings,
  Space,
} from 'pages';

import { dev } from 'utils/config';
import { ROLE } from 'utils/constants';

const routes = [
  {
    index: true,
    path: '/',
    element: <Home />,
    auth: false,
    authRedirect: '/dashboard',
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    auth: true,
  },
  {
    path: '/settings',
    element: <Settings />,
    auth: true,
  },
  {
    path: '/about',
    element: <Home />,
  },
  {
    path: '/knowledge-base',
    element: <KnowledgeBase />,
  },
  {
    path: '/knowledge-base/:slug',
    element: <KnowledgeBase />,
  },
  {
    path: '/knowledge-base/tip/:id',
    element: <Tip />,
  },
  {
    path: '/knowledge-base/tip/:id/edit',
    element: <EditTip />,
    auth: true,
    authRedirect: '/login',
  },
  {
    path: '/knowledge-base/tip/new',
    element: <NewTip />,
    auth: true,
    authRedirect: '/login',
  },
  {
    path: '/knowledge-base/question/:id',
    element: <Question />,
  },
  {
    path: '/knowledge-base/question/:id/edit',
    element: <EditQuestion />,
    auth: true,
    authRedirect: '/login',
  },
  {
    path: '/knowledge-base/question/new',
    element: <NewQuestion />,
    auth: true,
    authRedirect: '/login',
  },
  {
    path: '/ideas',
    element: <Ideas />,
  },
  {
    path: '/favorites',
    element: <FavoriteIdeas />,
    auth: true,
  },
  {
    path: '/ideas/new',
    element: <NewIdea />,
    auth: true,
    authRedirect: '/login',
  },
  {
    path: '/ideas/:id/edit',
    element: <EditIdea />,
    authRedirect: '/login',
  },
  {
    path: '/ideas/:id',
    element: <Idea admin />,
  },
  {
    path: '/administration/ideas/:id',
    element: <Idea admin />,
    auth: true,
    admin: true,
  },
  {
    path: '/login',
    element: <Login />,
    auth: false,
  },
  {
    path: '/new-password',
    element: <LoginFromInvitation />,
    auth: false,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    auth: false,
  },
  {
    path: '/forgot-password/sms',
    element: <ForgotPassword />,
    auth: false,
  },
  {
    path: '/forgot-password/mail',
    element: <ForgotPassword />,
    auth: false,
  },
  {
    path: '/email-verification',
    element: <EmailVerification />,
    auth: false,
  },
  {
    path: '/phone-verification',
    element: <PhoneVerification />,
    auth: false,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    auth: false,
  },
  {
    path: 'sign-up',
    element: <SignUp />,
    auth: false,
  },
  {
    path: '/profile',
    element: <Profile />,
    auth: true,
  },
  {
    path: '/portfolio',
    element: <PortfolioList />,
    auth: true,
  },
  {
    path: '/portfolio/:id',
    element: <Portfolio backUrl="/portfolio" />,
    auth: true,
  },
  {
    path: '/portfolio/new',
    element: <NewPortfolio backUrl="/portfolio" redirectPath="/portfolio" />,
    auth: true,
  },
  {
    path: '/portfolio/:id/edit',
    element: <EditPortfolio redirectPath="/portfolio" />,
    auth: true,
  },
  {
    path: '/profile/me',
    element: <Profile />,
    auth: true,
  },
  {
    path: '/profile/me/portfolio',
    element: <Profile />,
    auth: true,
  },
  {
    path: '/profile/:id',
    element: <Profile />,
    auth: true,
  },
  {
    path: '/profile/:id/portfolio',
    element: <Profile />,
    auth: true,
  },
  {
    path: '/profile/portfolio/new',
    element: <NewPortfolio backUrl="/profile/me/portfolio" redirectPath="/profile/portfolio" />,
    auth: true,
  },
  {
    path: '/profile/portfolio/:id/edit',
    element: <EditPortfolio redirectPath="/profile/portfolio" />,
    auth: true,
  },
  {
    path: '/profile/portfolio/:id',
    element: <Portfolio backUrl="/profile/me/portfolio" />,
    auth: true,
  },
  {
    path: '/profile/me/edit',
    element: <EditProfile />,
    auth: true,
  },
  {
    path: '/profile/:id/edit',
    element: <EditProfile />,
    auth: true,
  },
  {
    path: '/profile/me/settings/edit',
    element: <EditProfile />,
    auth: true,
  },
  {
    path: '/profile/:id/settings/edit',
    element: <EditProfile />,
    auth: true,
  },
  {
    path: '/learning',
    element: <Learning />,
    auth: true,
  },
  {
    path: '/learning/:slug',
    element: <Learning />,
    auth: true,
  },
  {
    path: '/learning/projects/new',
    element: <NewProject />,
    auth: true,
  },
  {
    path: '/learning/projects/:id',
    element: <Project />,
    auth: true,
  },
  {
    path: '/learning/projects/:id/edit',
    element: <EditProject />,
    auth: true,
  },
  {
    path: '/learning/student-project/:id',
    element: <ChildProject />,
    auth: true,
  },
  {
    path: '/learning/collections/new',
    element: <NewCollection />,
    auth: true,
  },
  {
    path: '/learning/collections/:id',
    element: <Collection />,
    auth: true,
  },
  {
    path: '/learning/child-collection/:id',
    element: <ChildCollection />,
    auth: true,
  },
  {
    path: '/learning/collections/:id/edit',
    element: <EditCollection />,
    auth: true,
  },
  {
    path: '/administration/groups/new',
    element: <NewGroup />,
    auth: true,
  },
  {
    path: '/administration/groups/:id',
    element: <Group />,
    auth: true,
  },
  {
    path: '/administration/spaces/:id',
    element: <Space />,
    auth: true,
  },
  {
    path: '/administration/groups/:id/information',
    element: <Group />,
    auth: true,
  },
  {
    path: '/administration/groups/:id/portfolio',
    element: <GroupPortfolio />,
    auth: true,
  },
  {
    path: '/administration/groups/:id/projects',
    element: <GroupProjects />,
    auth: true,
  },
  {
    path: '/administration/groups/:id/edit',
    element: <EditGroup />,
    auth: true,
  },
  {
    path: '/administration/students/new',
    element: <NewStudent />,
    auth: true,
  },
  {
    path: '/administration/parents/new',
    element: <NewParent />,
    auth: true,
  },
  {
    path: '/administration/teachers/new',
    element: <NewTeacher />,
    auth: true,
  },
  {
    path: '/administration/teachers/:id/edit',
    element: <EditTeacher />,
    auth: true,
  },
  {
    path: '/administration/admins/new',
    element: <NewAdmin />,
    auth: true,
  },
  {
    path: '/administration/users/new',
    element: <NewUser />,
    auth: true,
  },
  {
    path: '/administration/students/:id',
    element: <UserProfile role={ROLE.STUDENT} />,
    auth: true,
  },
  {
    path: '/administration/students/:id/edit',
    element: <EditStudent />,
    auth: true,
  },
  {
    path: '/administration/students/:id/portfolio',
    element: <UserPortfolio role={ROLE.STUDENT} />,
    auth: true,
  },
  {
    path: '/administration/students/:id/projects',
    element: <UserProjects role={ROLE.STUDENT} />,
    auth: true,
  },
  {
    path: '/administration/admins/:id',
    element: <UserProfile role={ROLE.ADMIN} />,
    auth: true,
  },
  {
    path: '/administration/admins/:id/edit',
    element: <EditAdmin />,
    auth: true,
  },
  {
    path: '/administration/parents/:id',
    element: <UserProfile role={ROLE.PARENT} />,
    auth: true,
  },
  {
    path: '/administration/parents/:id/edit',
    element: <EditParent />,
    auth: true,
  },
  {
    path: '/administration/teachers/:id',
    element: <UserProfile role={ROLE.TEACHER} />,
    auth: true,
  },
  {
    path: '/administration/teachers/:id/portfolio',
    element: <UserPortfolio role={ROLE.TEACHER} />,
    auth: true,
  },
  {
    path: '/administration',
    element: <Administration />,
    auth: true,
  },
  {
    path: '/administration/:slug',
    element: <Administration />,
    auth: true,
  },

  dev && {
    path: '/dev',
    element: <Dev />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
].filter(Boolean);

export default routes;
