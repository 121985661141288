import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';

const mapInitialData = ({ portfolio, projects: userProjects, isIndividual = false }) => {
  if (!portfolio) return {};

  const { project_cards } = portfolio;

  const groups = project_cards.map((group) => {
    const projects = group.project_cards.map((project) => {
      return {
        id: project.project,
        name: project.name,
        owner: project.owner,
        description: project.description,
        photos: [project.avatar],
        student_works: project.photos,
        sources: project.sources,
        student_video: project.student_video,
      };
    });

    return {
      label: isIndividual ? 'own' : group.group_name,
      value: isIndividual ? 'own' : group.group_id,
      projects: isIndividual ? userProjects?.child_projects : projects,
      selected: projects,
    };
  });

  const about = {
    name: portfolio.name,
    first_name: portfolio.first_name,
    last_name: portfolio.last_name,
    about_me: getInitialTextEditorState(portfolio.about_me),
    public: portfolio.public,
    photo: [portfolio.photo],
  };

  return {
    about,
    groups,
  };
};

export default mapInitialData;
