import { Trans, useTranslation } from 'react-i18next';
import { Box, Collapse, Stack as MuiStack, Typography } from '@mui/material';

import { useCallback, useState } from 'react';

import { notebook } from 'assets/images/holders';
import { withProps, useForm, useMessage } from 'hooks';
import useAuth, { getUsernameType } from 'hooks/useAuth.hook';
import { GuestLayout } from 'layouts';
import { PasswordField, UsernameField } from 'views';
import { Button, ImageHolder, Paper, Form, Ref } from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const keyPrefix = 'login';

const Login = () => {
  const { t } = useTranslation('pages', { keyPrefix });
  const {
    form,
    valid,
    rules: { required },
  } = useForm();
  const { login, progress } = useAuth();
  const [verifyPhone, setVerifyPhone] = useState(false);
  const m = useMessage();

  const handleLogin = useCallback(
    async (formData) => {
      const isPhone = getUsernameType(formData.username) === 'phone';
      const response = await login({ ...formData });

      if (response.status === 403) {
        if (isPhone) {
          setVerifyPhone(true);
          return;
        }
        m.error(t('error_403'));
      }
    },
    [login, m, t],
  );

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        alt={t('title_image.alt')}
        src={notebook}
        origin="container"
        bgcolor="primary.main"
      />

      <Typography my={3} color="primary.dark" variant="subtitle2">
        {t('welcome')}
      </Typography>

      <Paper width={696} alignItems="center" loading={progress}>
        <Form my={1} width={456} form={form} onSubmit={handleLogin}>
          <Stack>
            <UsernameField.Control
              fullWidth
              initFocus
              name="username"
              rules={{ required }}
              label={t('email.label')}
              placeholder={t('email.placeholder')}
            />

            <Stack spacing={1} width="100%" alignItems="flex-start">
              <PasswordField.Control
                fullWidth
                name="password"
                validation={false}
                rules={{ required }}
                label={t('password.label')}
                placeholder={t('password.placeholder')}
              />

              <Typography variant="body4">
                <Ref to="/forgot-password">{t('forgot_password')}</Ref>
              </Typography>
            </Stack>

            <Collapse in={!!verifyPhone}>
              <Box my={3}>
                <Typography variant="body3" color="textBlack.light">
                  <Trans t={t} i18nKey="verify_your_phone">
                    Please <Ref to="/phone-verification">verify your phone number</Ref> before login
                  </Trans>
                </Typography>
              </Box>
            </Collapse>

            <Stack width={216}>
              <Button disabled={!valid} fullWidth type="submit">
                {t('submit')}
              </Button>

              <Box>
                <Typography variant="body4" color="primary">
                  {t('dont_have_account')}
                </Typography>

                <span> </span>

                <Typography variant="button2" color="primary">
                  <Ref to="/sign-up">{t('signup')}</Ref>
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Form>
      </Paper>
    </GuestLayout>
  );
};

export default Login;
