import { Box, Stack, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState, useMemo, useCallback, Fragment } from 'react';

import { SORT_DIRECTION } from 'utils/constants';
import { getArray, makeOptions } from 'utils/helpers';
import { Center, Input, Tag, PadSelect, IconButton } from 'components';

const TagsCount = styled(Tag)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.controls.main,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.input.main,
}));

const TagHolder = styled(Center)({
  justifyContent: 'flex-start',
});

const StyledTag = styled(Tag, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    border: '1px solid',
    borderColor: theme.palette.controls.main,
    backgroundColor: active ? theme.palette.controls.main : theme.palette.input.main,
  }),
);

const checkActive = (tags = [], tag) => {
  const foundIndex = tags.findIndex((item) => item.id === tag.id);
  return foundIndex !== -1;
};

const getAlphabeticalList = (tags, order = SORT_DIRECTION.ASC) => {
  const tagsByGroupName = {};

  tags.forEach((tag) => {
    const groupName = tag.name[0];
    if (!tagsByGroupName[groupName]) {
      tagsByGroupName[groupName] = [tag];
    } else {
      tagsByGroupName[groupName].push(tag);
    }
  });

  const sortedKeys = Object.keys(tagsByGroupName).sort();
  if (order === SORT_DIRECTION.DESC) sortedKeys.reverse();

  const ordered = sortedKeys.reduce((obj, key) => {
    obj[key] = tagsByGroupName[key];
    if (order === SORT_DIRECTION.DESC) obj[key].reverse();
    return obj;
  }, {});

  return ordered;
};

const searchTags = (tags, search, grouped = false) => {
  if (!grouped) {
    return getArray(tags).filter((tag) => {
      return tag.name.toLowerCase().includes(search.toLowerCase());
    });
  }

  return Object.entries(tags).reduce((obj, [groupName, list]) => {
    const filteredList = getArray(list).filter((tag) =>
      tag.name.toLowerCase().includes(search.toLowerCase()),
    );
    obj[groupName] = filteredList;
    return obj;
  }, {});
};

const TagItem = (props) => {
  const { tag, onChange, ...rest } = props;
  return (
    <TagHolder mb={1}>
      <StyledTag size="medium" clickable label={tag.name} onClick={() => onChange(tag)} {...rest} />
      {tag.qty > 0 && (
        <Typography ml={1} variant="caption1" color="textBlack.light">
          ({tag.qty})
        </Typography>
      )}
    </TagHolder>
  );
};

const TagsFilter = (props) => {
  const { value = [], total, tags: rawTags, grouped = false, onValue, onSearch } = props;

  const { t: th } = useTranslation('helpers');
  const { t } = useTranslation('views', { keyPrefix: 'TagsFilter' });

  const [order, setOrder] = useState(SORT_DIRECTION.DEFAULT);
  const [searchValue, setSearchValue] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  const tags = useMemo(() => {
    if (!grouped) return rawTags;
    return getAlphabeticalList(rawTags, order);
  }, [grouped, rawTags, order]);

  const filteredTags = useMemo(() => {
    if (!searchValue) return tags;
    return searchTags(tags, searchValue, grouped);
  }, [tags, grouped, searchValue]);

  const handleSearch = useCallback(
    (search) => {
      setSearchValue(search);
      typeof onSearch === 'function' && onSearch(search);
    },
    [onSearch, setSearchValue],
  );

  const handleChange = useCallback(
    (tag) => {
      if (typeof onValue !== 'function') return;

      const foundIndex = value.findIndex((item) => item.id === tag.id);
      if (foundIndex === -1) {
        onValue([...value, tag]);
      } else {
        const changed = [...value];
        changed.splice(foundIndex, 1);
        onValue(changed);
      }

      handleSearch('');
    },
    [value, onValue, handleSearch],
  );

  const handleSelectGroup = useCallback(
    (value) => {
      setSelectedGroups((old) => {
        const foundIndex = old.findIndex((one) => one === value);
        if (foundIndex === -1) return [...old, value];
        return old.filter((one) => one !== value);
      });
    },
    [setSelectedGroups],
  );

  const togglePad = useCallback(() => {
    setOrder((old) => {
      if (old === SORT_DIRECTION.DESC) return 0;
      return (old += 1);
    });
  }, [setOrder]);

  return (
    <Box display="flex" flexDirection="column">
      <Center mb={1} justifyContent="space-between">
        <Typography color="textBlack.light">{t('title')}</Typography>
        {total > 0 && <TagsCount size="small" label={total} />}
      </Center>

      <Center>
        <Input
          onValue={handleSearch}
          value={searchValue}
          iconLeft="Search"
          fullWidth
          placeholder={t('search')}
        />

        {grouped && (
          <IconButton
            sx={{ boxShadow: 'small', marginLeft: 1 }}
            border={order !== 0}
            variant="outlined"
            size="medium"
            name={order === 2 ? 'Alphabetical2' : 'Alphabetical'}
            fontSize="small"
            onClick={togglePad}
          />
        )}
      </Center>

      {order !== 0 && (
        <PadSelect
          mt={1}
          value={selectedGroups}
          pads={makeOptions(th('alphabet').split(''))}
          onValue={handleSelectGroup}
        />
      )}

      {!grouped && filteredTags && (
        <Stack mt={1} sx={{ maxHeight: '432px', overflow: 'auto' }}>
          {filteredTags.map((tag, index) => (
            <TagItem
              key={`tag-filter-${tag.id}-${index}`}
              tag={tag}
              active={checkActive(value, tag)}
              onChange={handleChange}
            />
          ))}
        </Stack>
      )}

      {grouped && filteredTags && (
        <Stack mt={1} sx={{ maxHeight: '432px', overflow: 'auto' }}>
          {Object.entries(filteredTags).map(([groupName, list], index) => {
            if (
              list?.length === 0 ||
              (selectedGroups.length > 0 && !selectedGroups.includes(groupName))
            )
              return null;

            return (
              <Fragment key={`alphabetical-group-${groupName}-${index}`}>
                <Typography variant="body2" color="textBlack.dark">
                  {groupName}
                </Typography>
                <Stack mt={1}>
                  {list.map((tag, index) => (
                    <TagItem
                      key={`tag-filter-${tag.id}-${index}`}
                      tag={tag}
                      active={checkActive(value, tag)}
                      onChange={handleChange}
                    />
                  ))}
                </Stack>
              </Fragment>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

export default TagsFilter;
