import { useState } from 'react';
import { Box, Stack, Typography, Collapse } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { allowedImageTypes } from 'utils/constants';
import { editorStateToRawHtml } from 'utils/helpers';
import { useMessage, useRules } from 'hooks';
import { useUploadTemporaryFileMutation } from 'store/api';

import { Paper, TextEditor, IconButton, YoutubeEmbed, Dropzone, Form } from 'components';
import { StudentWork, StudentWorkModal } from 'views';

const parseYoutubeEmbedId = (link) => {
  if (!link) return false;

  const match = link.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);

  const isYoutubeVideo = match?.length > 1 && match[2]?.length > 0;
  if (!isYoutubeVideo) return false;

  return match[2];
};

const GroupProjects = (props) => {
  const { name, control, rules = {} } = props;
  const { requiredArray, required, description } = rules;

  const { t } = useTranslation('views', { keyPrefix: 'PortfolioEditProjectsForm' });
  const m = useMessage();

  const { fields, remove, update } = useFieldArray({
    control: control,
    name: name,
    keyName: 'fieldId',
  });

  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  const [openAddProgressModal, setOpenAddProgressModal] = useState(false);
  const [selectedStudentWork, setSelectedStudentWork] = useState(null);

  const [uploadFile, uploadFileState] = useUploadTemporaryFileMutation();

  const closeStudentWorkModal = () => {
    setSelectedProjectIndex(null);
    setSelectedStudentWork(null);
    setOpenAddProgressModal(false);
  };

  const handeAddPhotoOfProcess = async (formData, state) => {
    if (selectedProjectIndex === null) return;

    const selectedProject = { ...fields[selectedProjectIndex] };

    const { photo } = formData;
    const [file] = photo || [];

    const studentWork = {
      photo: formData.photo,
      link: file instanceof File ? URL.createObjectURL(file) : file,
      name: formData.name,
      description: editorStateToRawHtml(formData.description) || undefined,
    };

    if (file instanceof File) {
      try {
        const { file_id } = await uploadFile({ file }).unwrap();

        studentWork.photo = [file_id];
      } catch (err) {
        m.error(t('error.failed_upload_photo'));
        return;
      }
    }

    let studentWorks = selectedProject.student_works.slice(0);
    if (state === 'edit') {
      studentWorks.splice(selectedStudentWork.index, 1, studentWork);
    } else {
      studentWorks.push(studentWork);
    }

    const changed = {
      ...selectedProject,
      student_works: studentWorks,
    };

    update(selectedProjectIndex, changed);
    closeStudentWorkModal();
  };

  const handleDeletePhotoOfProcess = (projectIndex) => {
    const selectedProject = { ...fields[projectIndex] };
    update(projectIndex, {
      ...selectedProject,
      student_works: selectedProject.student_works.filter(
        (_w, i) => i !== selectedStudentWork.index,
      ),
    });
    closeStudentWorkModal();
  };

  const handleSelectProject = (index) => {
    setSelectedProjectIndex(index);
    setOpenAddProgressModal(true);
  };

  return (
    <>
      <Stack spacing={4}>
        {fields.map((project, index) => (
          <Paper key={project.fieldId} overflow="hidden" position="relative" shadow="standard">
            <Box
              pt={2}
              pr={2}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                zIndex: 3,
                '& .MuiFormControlLabel-root': { marginRight: 0 },
              }}
            >
              <IconButton
                radius={1}
                color="error"
                variant="outlined"
                border
                size="xsmall"
                name="Delete"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  remove(index);
                }}
              />
            </Box>

            <Box display="flex" alignItems="flex-start" width={1}>
              <Box width={216} mr={2} flexShrink={0}>
                <Dropzone.Control
                  fullWidth
                  name={`${name}[${index}].photos`}
                  accept={allowedImageTypes}
                  rules={{ requiredArray, required }}
                  textAlign="center"
                />
              </Box>

              <Box flexGrow={1}>
                <Typography component="h4" variant="subtitle5" mb={3}>
                  {project.name}
                </Typography>
                <TextEditor.Control
                  rules={{ required, description }}
                  fullWidth
                  name={`${name}[${index}].description`}
                />
              </Box>
            </Box>

            <Box width={1} mt={3}>
              <StudentWork
                navigation
                studentWorks={project?.student_works}
                mb={4}
                onRequestEditStudentWork={(work, wIndex) => {
                  handleSelectProject(index);
                  setSelectedStudentWork({ ...work, index: wIndex });
                }}
                onRequestAddStudentWork={() => {
                  handleSelectProject(index);
                }}
              />
            </Box>

            <Box width={1} mt={2}>
              <YoutubeEmbed embedId={parseYoutubeEmbedId(project?.student_video)} />
            </Box>
          </Paper>
        ))}
      </Stack>

      <StudentWorkModal
        loading={uploadFileState.isLoading}
        open={openAddProgressModal}
        initialData={selectedStudentWork}
        onClose={closeStudentWorkModal}
        onDelete={() => {
          if (!fields[selectedProjectIndex]) return;
          handleDeletePhotoOfProcess(selectedProjectIndex);
        }}
        onSubmit={handeAddPhotoOfProcess}
      />
    </>
  );
};

const EditProjectsForm = (props) => {
  const { id, onSubmit, activeGroup, form } = props;

  const { required, requiredArray } = useRules();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'groups',
    keyName: 'fieldId',
  });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      {fields.map((field, index) => (
        <Collapse key={field.fieldId} in={field.value === activeGroup?.value}>
          <GroupProjects
            conrol={form.control}
            rules={{ required, requiredArray }}
            projects={field.projects}
            name={`groups.${index}.selected`}
          />
        </Collapse>
      ))}
    </Form>
  );
};

export default EditProjectsForm;
