import { useParams } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PERMISSIONS } from 'utils/constants';
import { getArray, getProfileBasePath } from 'utils/helpers';
import { useGetProfile, useGetPortfolioCreationOptions, useAcl } from 'hooks';
import { useGetPortfoliosQuery } from 'store/api';

import { page_empty } from 'assets/images/holders';

import { ProjectLayout } from 'layouts';
import { Container, Center, ImageHolder, DropdownMenu, Button, Paper, PaperCard } from 'components';
import { PageSubheader, CardContent } from 'views';

import NavigationTabs from '../NavigationTabs';

const Portfolio = (props) => {
  const { role } = props;

  const acl = useAcl();
  const profileBasePath = getProfileBasePath(role);

  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation('pages', { keyPrefix: 'user_portfolio' });

  const [profile, loading] = useGetProfile({ id, role });

  const { data: portfolios } = useGetPortfoliosQuery(
    {
      spaceId: profile?.space_id,
      users: [profile?.id],
      groups: [],
      name: null,
      limit: 10,
      offset: 0,
    },
    { skip: !profile?.space_id || !profile?.id },
  );

  const options = useGetPortfolioCreationOptions({ user_id: id });
  const canCreatePortfolio = acl.allow({ permissions: [PERMISSIONS.CREATE_PORTFOLIO] });

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={'/administration/users'}
          backTitle={t('back_title')}
          title={profile?.profile?.username}
          pageActions={
            portfolios?.portfolios_qty > 0 && canCreatePortfolio
              ? {
                  [t('portfolio.create_portfolio')]: {
                    to: '/profile/portfolio/new',
                    size: 'small',
                    radius: 2,
                    onClick: () =>
                      navigate('/profile/portfolio/new', {
                        state: { source: 'own_projects', user_id: id },
                      }),
                  },
                }
              : undefined
          }
        />
      }
    >
      <Container>
        <Box py={4}>
          <NavigationTabs
            role={role}
            id={id}
            value={`${id}/portfolio`}
            profileBasePath={profileBasePath}
          />
          <Box pt={3}>
            {!portfolios?.length && (
              <Paper width="100%" loading={loading}>
                <Center width="100%" py={5} flexDirection="column">
                  <ImageHolder mb={3} alt="no results" size={210} src={page_empty} />
                  <Typography component="div" mb={2} color="primary" variant="body2">
                    {t('empty_text')}
                  </Typography>
                  {canCreatePortfolio && (
                    <DropdownMenu
                      minWidth={200}
                      target={<Button iconRight="ArrowDown">{t('create_portfolio')}</Button>}
                      options={options}
                    />
                  )}
                </Center>
              </Paper>
            )}
            {portfolios?.length > 0 && (
              <Grid container spacing={3}>
                {getArray(portfolios).map((portfolio) => (
                  <Grid item xs={6} key={portfolio.id}>
                    <PaperCard size="small" to={`/profile/portfolio/${portfolio.id}`}>
                      <CardContent
                        variant="portfolio"
                        photo={portfolio?.user?.avatar}
                        title={portfolio.name}
                        projectsQty={portfolio.projects_qty}
                      />
                    </PaperCard>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>
    </ProjectLayout>
  );
};

export default Portfolio;
