import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import { Box, Collapse, Stack, Typography } from '@mui/material';

import { useResetEmailPasswordMutation, useResetSmsPasswordMutation } from 'store/api';
import { mail_box } from 'assets/images/holders';
import { GuestLayout } from 'layouts';
import { Button, Center, ImageHolder, Paper, Ref } from 'components';

import RestoreWithSms from './RestoreWithSms';
import RestoreWithMail from './RestoreWithMail';

const keyPrefix = 'forgot_password';

const ForgotPassword = () => {
  const { t } = useTranslation('pages', { keyPrefix });

  const rootPath = useMatch('/forgot-password');
  const smsPath = useMatch('/forgot-password/sms');
  const mailPath = useMatch('/forgot-password/mail');

  const [resetEmailPassword, resetEmailPasswordState] = useResetEmailPasswordMutation();
  const [resetSmsPassword, resetSmsPasswordState] = useResetSmsPasswordMutation();

  const method = useMemo(() => {
    if (smsPath) {
      return 'phone';
    }
    if (mailPath) {
      return 'email';
    }
    // root
  }, [mailPath, smsPath]);

  const methodLabel = useMemo(() => {
    if (method) {
      return t(`method.${method}`);
    }
  }, [method, t]);

  const handleReset = useCallback(
    async (data) => {
      const handler = method === 'phone' ? resetSmsPassword : resetEmailPassword;

      try {
        await handler(data);
      } catch (ex) {
        console.error(ex);
      }
    },
    [resetEmailPassword, resetSmsPassword, method],
  );

  const resetState = useMemo(() => {
    if (method) {
      return method === 'phone' ? resetSmsPasswordState : resetEmailPasswordState;
    }
  }, [method, resetSmsPasswordState, resetEmailPasswordState]);

  const loading = resetState?.isLoading;
  const isError = resetState?.isError;
  const isSuccess = resetState?.isSuccess;
  const error = resetState?.error;
  const isDone = isError || isSuccess;

  const errorMessage = useMemo(() => {
    if (error?.status === 409 && methodLabel) {
      return t(`error.wrong`, { method: methodLabel });
    }
    if (error?.status === 'FETCH_ERROR') {
      return t('error.fetch_error');
    }
    return null;
  }, [error, methodLabel, t]);

  const handleBack = useCallback(() => {
    resetEmailPasswordState.reset();
  }, [resetEmailPasswordState]);

  return (
    <GuestLayout headerProps={{ empty: true }}>
      <ImageHolder
        p={1}
        size={56}
        alt={t('title_image.alt')}
        src={mail_box}
        origin="container"
        shadow="smooth"
      />

      <Typography my={3} color="primary.dark" variant="subtitle2">
        {isSuccess ? t('check', { method }) : t('forgot_password')}
      </Typography>

      <Paper py={3} width={696} alignItems="center" loading={loading}>
        <Collapse in={!!rootPath} width="100%">
          <Center flexDirection="column" pb={2}>
            <Typography variant="body3" color="textBlack.light">
              {t('select_method')}
            </Typography>

            <Box mt={7} width={217}>
              <Stack direction="column" spacing={2}>
                <Button fullWidth variant="outlined" iconLeft="Forum" to="/forgot-password/sms">
                  {t('via_sms')}
                </Button>

                <Button fullWidth variant="outlined" iconLeft="Envelope" to="/forgot-password/mail">
                  {t('via_mail')}
                </Button>
              </Stack>

              <Box mt={7}>
                <Button fullWidth to="/login">
                  {t('cancel')}
                </Button>
              </Box>
            </Box>
          </Center>
        </Collapse>

        <Collapse in={!!smsPath && !isDone}>
          <RestoreWithSms onSubmit={handleReset} />
        </Collapse>

        <Collapse in={!!mailPath && !isDone}>
          <RestoreWithMail onSubmit={handleReset} />
        </Collapse>

        <Collapse in={resetEmailPasswordState.isSuccess}>
          <Box width={456} textAlign="center">
            <Typography variant="body3" color="textBlack.light">
              {t('mail.success')}
            </Typography>
          </Box>
        </Collapse>

        <Collapse in={resetEmailPasswordState.isError}>
          <Box py={1}>
            <Box mb={4}>
              <Typography variant="body3" color="textBlack.light">
                {errorMessage}
              </Typography>
            </Box>

            <Center>
              <Ref onClick={handleBack}>{t('back')}</Ref>
            </Center>
          </Box>
        </Collapse>
      </Paper>
    </GuestLayout>
  );
};

export default ForgotPassword;
