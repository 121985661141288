import { BrowserRouter, Routes } from 'react-router-dom';

import { NotificationsModal } from 'views';

import routes from './routes';
import useProtectedRoutes from './useProjectRoutes.hook';

const ProjectRoutes = () => {
  const content = useProtectedRoutes(routes);

  return (
    <BrowserRouter>
      <Routes>{content}</Routes>
      <NotificationsModal open />
    </BrowserRouter>
  );
};

export default ProjectRoutes;
