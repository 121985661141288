import { Box, Typography, styled } from '@mui/material';

import * as assets from 'assets';
import { getArray } from 'utils/helpers';
import { Image, Center } from 'components';

const ImageBlock = styled(Center)(() => ({
  flexDirection: 'column',
}));

const Images = () => {
  return (
    <Box p={4}>
      <Box>
        <Typography variant="subtitle2">Holders</Typography>
        <Typography component="code">/src/assets/images/holders</Typography>

        <Box mt={2} display="flex" gap={2} flexWrap="wrap">
          {getArray(Object.entries(assets?.images?.holders)).map(([name, value]) => (
            <ImageBlock key={name}>
              <Image
                mb={1}
                src={value}
                alt={name}
                height={150}
              />
              <span>{name}</span>
            </ImageBlock>
          ))}
        </Box>
      </Box>

      <Box mt={5}>
        <Typography variant="subtitle2">Flags</Typography>
        <Typography component="code">/src/assets/images/flags/</Typography>

        <Box mt={2} display="flex" gap={2} flexWrap="wrap">
          {getArray(Object.entries(assets?.images?.flags)).map(([name, value]) => (
            <ImageBlock key={name}>
              <Image
                mb={1}
                src={value}
                alt={name}
                height={100}
              />
              <span>{name}</span>
            </ImageBlock>
          ))}
        </Box>
      </Box>

      <Box mt={5}>
        <Typography variant="subtitle2">Icons</Typography>
        <Typography component="code">/src/assets/icons/</Typography>

        <Box mt={2} display="flex" gap={4} flexWrap="wrap">
          {getArray(Object.entries(assets?.icons)).map(([name, value]) => (
            <ImageBlock key={name} width={120}>
              <Image
                mb={1}
                src={value}
                alt={name}
                height={40}
              />
              <span>{name}.svg</span>
            </ImageBlock>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Images;
