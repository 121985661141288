import { useState, useCallback } from 'react';
import { styled, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getArray } from 'utils/helpers';

import { Button, Modal, Checkbox } from 'components';

const TagsList = styled(Grid, { label: 'TagsFilter-List' })({
  '& .MuiTypography-body2': {
    marginBottom: 0,
  },
});

const checkActive = (tags = [], tag) => {
  const foundIndex = tags.findIndex((item) => item.id === tag.id);
  return foundIndex !== -1;
};

const TagsFilter = (props) => {
  const { value = [], tags, onValue } = props;

  const { t } = useTranslation('views', { keyPrefix: 'QuickFilters' });

  const [currentValue, setCurrentValue] = useState([]);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (tag) => {
      const foundIndex = currentValue.findIndex((item) => item.id === tag.id);
      if (foundIndex === -1) {
        setCurrentValue([...currentValue, tag]);
      } else {
        const changed = [...currentValue];
        changed.splice(foundIndex, 1);
        setCurrentValue(changed);
      }
    },
    [currentValue, setCurrentValue],
  );

  const handleApply = useCallback(() => {
    setOpen(false);
    typeof onValue === 'function' && onValue(currentValue);
  }, [currentValue, setOpen, onValue]);

  const handleClear = useCallback(() => {
    typeof onValue === 'function' && onValue([]);
    setCurrentValue([]);
  }, [onValue]);

  const openModal = useCallback(() => {
    setOpen(true);
    setCurrentValue(value);
  }, [setOpen, setCurrentValue, value]);

  return (
    <>
      <Button
        radius={2}
        variant="outlined"
        size="small"
        iconLeft="Tag"
        onClick={openModal}
        sx={{ paddingRight: 0 }}
      >
        {t('TagsFilter.tags')}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)} size="medium">
        <Modal.Header title={t('TagsFilter.tags')} />
        <Modal.Body>
          <TagsList container px={1}>
            {getArray(tags).map((tag) => (
              <Grid item xs={4} key={tag.id}>
                <Checkbox
                  size="xsmall"
                  labelVariant="body2"
                  label={tag.name}
                  checked={checkActive(currentValue, tag)}
                  onChange={() => {
                    handleChange(tag);
                  }}
                />
              </Grid>
            ))}
          </TagsList>
        </Modal.Body>

        <Modal.Footer>
          <Button size="small" onClick={handleApply}>
            {t('apply')}
          </Button>
          <Button size="small" variant="outlined" onClick={handleClear}>
            {t('clear_all')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TagsFilter;
