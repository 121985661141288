import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getArray, editorStateToRawHtml } from 'utils/helpers';
import { useAuth, useForm, useMessage } from 'hooks';
import { useGetMeQuery, useCreateGroupMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Paper, Form, Container } from 'components';
import { PageSubheader, GroupForm } from 'views';

const contentWidth = 936;

const NewGroup = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('pages', { keyPrefix: 'new_group' });
  const { auth } = useAuth();
  const { form, valid, rules } = useForm();
  const m = useMessage();

  const [createGroup, createGroupState] = useCreateGroupMutation();

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const handleSubmit = async (formData) => {
    try {
      const postData = {
        spaceId: me?.space_id,
        name: formData.name,
        description: editorStateToRawHtml(formData.description) || undefined,
        address: formData.address || '',
        participants: getArray(formData.participants).map((p) => p?.value),
        leader_id: getArray(formData.leader_id)[0]?.value,
        time: {},
      };

      getArray(formData.day_and_time).forEach((d) => {
        if (!d.time) return;
        postData.time[d.day] = d.time;
      });

      const { group_id } = await createGroup(postData).unwrap();

      if (group_id) return navigate(`/administration/groups/${group_id}`);
    } catch (e) {
      console.error(e);
      m.error(t('error.failed_create_group'));
    }
  };

  const loading = isLoading || isFetching || createGroupState.isLoading;

  return (
    <Form id={`new-group-form`} width={1} form={form} onSubmit={handleSubmit}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backTitle={t('back_button')}
            backUrl={`/administration/groups`}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
                radius: 2,
              },
              [t('page_actions.create')]: {
                type: 'submit',
                size: 'small',
                radius: 2,
                disabled: !valid || loading || !form.formState.isDirty,
              },
            }}
          />
        }
      >
        <Container width={contentWidth}>
          <Box py={4}>
            <Typography width={1} component="h3" color="secondary.main" variant="subtitle3">
              {t('page_title')}
            </Typography>

            <Box pt={3}>
              <Paper width={1} overflow="visible" pt={3} pb={5} px={9} loading={loading}>
                <GroupForm spaceId={me?.space_id} form={form} rules={rules} />
              </Paper>
            </Box>
          </Box>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default NewGroup;
