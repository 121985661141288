import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUploadTemporaryFilesMutation } from 'store/api';

import { useMessage } from 'hooks';

const useUploadPhotos = (props) => {
  const { onUpload } = props || {};

  const { t } = useTranslation('hooks', { keyPrefix: 'upload_photos' });
  const m = useMessage();
  const [uploadFiles, uploadFilesState] = useUploadTemporaryFilesMutation();

  const handlePhotosUpload = useCallback(
    async (name, photos) => {
      if (photos.length === 0) return [];

      let photoIds = [];
      try {
        const filesToUpload = photos.filter((photo) => photo instanceof File);

        if (filesToUpload.length > 0) {
          const { file_ids } = await uploadFiles({ files: filesToUpload }).unwrap();
          photoIds = file_ids.map((file) => file?.id);

          typeof onUpload === 'function' &&
            onUpload({
              name,
              files: filesToUpload.map((file) => {
                const found = file_ids.find((one) => one.name === file.name);
                return { ...found, url: URL.createObjectURL(file) };
              }),
            });
        } else {
          photoIds = photos.map((photo) => photo?.id || photo?.url || photo);
        }
      } catch (ex) {
        console.error(ex);
        m.error(t('error_photos_upload'));
        return [];
      }

      return photoIds.filter(Boolean);
    },
    [onUpload, uploadFiles, m, t],
  );

  return [handlePhotosUpload, uploadFilesState];
};

export default useUploadPhotos;
