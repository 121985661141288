import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ROLE, STUDENT_PERMISSIONS } from 'utils/constants';
import { getArray, hasPermission, getProfileBasePath } from 'utils/helpers';
import { useGetProfile } from 'hooks';

import { ProjectLayout } from 'layouts';
import { Container, Center, IconButton, Tag, Icon, Paper, FormRow, Switch } from 'components';
import { PageSubheader, PasswordField } from 'views';

import NavigationTabs from '../NavigationTabs';
import PersonalInformation from './PersonalInformation';
import ParentCard from './ParentCard';

const getRelation = (parent, studentId) => {
  if (!parent?.relations) return;
  const found = parent.relations.find((r) => r.student_id === studentId);
  return found?.relation;
};

const getLocation = (parent) => {
  if (!parent) return;
  const address = [];

  if (parent.address) address.push(parent.address);
  if (parent.apartment) address.push(parent.apartment);
  if (parent.city) address.push(`${parent.city},`);
  if (parent.state) address.push(parent.state);
  if (parent.zip) address.push(parent.zip);
  if (parent.country) address.push(parent.country);

  return address.join(' ');
};

const Profile = (props) => {
  const { role } = props;

  const profileBasePath = getProfileBasePath(role);

  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'user_profile' });

  const [profile, loading] = useGetProfile({ id, role });

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          backUrl={'/administration/users'}
          backTitle={t('back_title')}
          title={profile?.profile?.username || profile?.profile?.full_name}
        />
      }
    >
      <Container>
        <Box py={4}>
          <NavigationTabs role={role} id={id} value={id} profileBasePath={profileBasePath} />

          <Box pt={3}>
            {/* PERSONAL INFORMATION */}
            <Center mb={2} justifyContent="space-between">
              <Typography variant="subtitle5">{t('personal_information')}</Typography>

              <IconButton
                name="Edit"
                type="button"
                variant="outlined"
                size="small"
                border
                to={`${profileBasePath}/${id}/edit`}
              />
            </Center>
            <PersonalInformation
              loading={loading}
              phoneNumber={profile?.phone_number}
              email={profile?.email}
              groups={profile?.groups}
              avatar={profile?.profile?.avatar}
              birthDate={profile?.profile?.birth_date}
              username={profile?.profile?.username}
              fullName={`${profile?.profile?.full_name}`}
              roles={
                profile?.role
                  ? [
                      {
                        id: profile?.role,
                        name: t(`roles.${profile?.role}`),
                        pl: 1,
                        startAdornment: (
                          <Icon
                            name={
                              {
                                student: 'Student',
                                admin: 'Admin',
                                parent: 'Parent',
                                teacher: 'Teacher',
                              }[profile?.role]
                            }
                          />
                        ),
                      },
                    ]
                  : undefined
              }
            />

            {/* PARENTS */}
            {role === ROLE.STUDENT && profile?.relations?.length > 0 && (
              <Box mb={3}>
                <Center mb={2} justifyContent="space-between">
                  <Typography variant="subtitle5">{t('parents')}</Typography>

                  <IconButton
                    name="Edit"
                    type="button"
                    variant="outlined"
                    size="small"
                    border
                    to={`${profileBasePath}/${id}/edit`}
                  />
                </Center>

                <Grid container spacing={3}>
                  {getArray(profile?.relations).map((parent) => (
                    <Grid item xs={6} key={parent.id}>
                      <ParentCard
                        name={parent.full_name}
                        email={parent.email}
                        phoneNumber={parent.phone_number}
                        relation={getRelation(parent, id)}
                        location={getLocation(parent)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}

            {/* EMERGENCY CONTACTS */}
            {role === ROLE.STUDENT && (
              <Box mb={2}>
                <Center mb={2} justifyContent="space-between">
                  <Center>
                    <Typography variant="subtitle5">{t('health_details')}</Typography>
                    <Tag
                      ml={2}
                      size="medium"
                      label={t('emergency_contacts')}
                      bgcolor="input.main"
                    />
                  </Center>

                  <IconButton
                    name="Edit"
                    type="button"
                    variant="outlined"
                    size="small"
                    border
                    to={`${profileBasePath}/${id}/edit`}
                  />
                </Center>

                {getArray(profile?.health).map((healthDetails) => (
                  <Box key={healthDetails.id}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <ParentCard
                          name={healthDetails.first_contact_name}
                          phoneNumber={healthDetails.first_contact_phone}
                        />
                      </Grid>
                      {healthDetails.second_contact_name && (
                        <Grid item xs={6}>
                          <ParentCard
                            name={healthDetails.second_contact_name}
                            phoneNumber={healthDetails.second_contact_phone}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <Paper width={1} shadow="standard" mt={2}>
                      <Typography variant="subtitle5" component="h4" mb={2}>
                        {t('allergies')}
                      </Typography>

                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Typography variant="body4" color="textBlack.light">
                            {t('food')}
                          </Typography>

                          <Typography
                            color="textBlack.light"
                            variant="body4"
                            component="div"
                            mt={1}
                            dangerouslySetInnerHTML={{ __html: healthDetails.food_allergies }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body4" color="textBlack.light">
                            {t('drugs')}
                          </Typography>

                          <Typography
                            color="textBlack.light"
                            variant="body4"
                            component="div"
                            mt={1}
                            dangerouslySetInnerHTML={{ __html: healthDetails.drugs_allergies }}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                ))}
              </Box>
            )}

            {/* INDIVIDUAL PERMISSIONS */}
            {role === ROLE.STUDENT && (
              <Paper mt={2} width={1} shadow="standard">
                <Box mb={2} width={456}>
                  <FormRow label={t(`password`)} labelWidth={160}>
                    <PasswordField
                      fullWidth
                      value="Password!13"
                      name="password"
                      type="password"
                      disabled
                    />
                  </FormRow>
                </Box>

                <Box
                  width={1}
                  pb={1}
                  mb={1}
                  sx={{ borderBottom: '1px solid', borderColor: 'other.divider' }}
                >
                  <Typography>{t('individual_permissions')}</Typography>
                </Box>
                <Stack spacing={1}>
                  {Object.entries(STUDENT_PERMISSIONS).map(([name, permissions]) => (
                    <FormRow key={name} label={t(`permissions.${name}`)}>
                      <Switch
                        checked={hasPermission(profile?.profile?.permissions, permissions)}
                        fullWidth
                        disabled
                        name={name}
                      />
                    </FormRow>
                  ))}
                </Stack>
              </Paper>
            )}
          </Box>
        </Box>
      </Container>
    </ProjectLayout>
  );
};

export default Profile;
