import { forwardRef, useMemo, useState } from 'react';
import { Box, Chip as MuiChip, styled } from '@mui/material';

import { dev } from 'utils/config';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Fieldset from 'components/Dev/Fieldset';
import avatarExample from 'assets/images/examples/avatar.png';

const Chip = styled(MuiChip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.controls.default,
  '&:hover': {
    background: theme.palette.controls.hover,
  },
  '&:focus': {
    background: theme.palette.controls.hover,
    boxShadow: theme.shadows.dark,
  },
  '&.Mui-disabled': {
    opacity: 1,
    color: theme.palette.text.disabled,
    background: theme.palette.controls.disabled,
    '& .MuiAvatar-root': {
      opacity: 0.5,
    },
  },
  '&.MuiChip-label': {
    ...theme.typography.body4,
  },
  '& .MuiChip-deleteIcon': {
    fontSize: 16,
    color: 'inherit',
    marginRight: theme.spacing(1),
    opacity: 0.5,
    '&:hover': {
      color: 'inherit',
      opacity: 1,
    },
  },
  '& .MuiChip-icon': {
    fontSize: 16,
    color: 'inherit',
    marginLeft: theme.spacing(1.5),
    marginRight: -theme.spacing(0.5),
  },
}));

/**
 * @param {string|ReactElement} [children] - Badge main content
 * @param {string|ReactElement} [icon] - Left icon
 * @param {string} [avatar] - Avatar image link (more priority than "icon")
 * @param {string} [avatarText] - Avatar fallback text
 * @param {boolean} [disabled] - Disable the badge
 * @param {function} [onClick] - Will make the badge clickable
 * @param {function} [onDelete] - Will add Close icon to the right
 */
const Badge = forwardRef((props, ref) => {
  const {
    icon,
    avatar,
    onClick,
    onDelete,
    disabled,
    children,
    avatarText,
  } = props;

  if (children && typeof children !== 'string') {
    throw new Error('[Badge]: children must be a string.');
  }
  const isAvatar = Boolean(avatar || avatarText);

  const iconContent = useMemo(() => {
    if (!icon || isAvatar) {
      return null;
    }
    if (typeof icon === 'string') {
      return <Icon name={icon} />;
    }
    return icon;
  }, [icon, isAvatar]);

  return (
    <Chip
      onClick={onClick}
      onDelete={onDelete}
      label={children}
      icon={iconContent}
      disabled={disabled}
      deleteIcon={
        <Icon.Close />
      }
      avatar={!isAvatar ? null : (
        <Avatar
          src={avatar}
          size="xsmall"
          border={false}
          sx={{ ml: 0.5, mr: -0.5 }}
        >
          {avatarText}
        </Avatar>
      )}
    />
  );
});

if (dev) {
  const Demo = () => {
    const [icon, setIcon] = useState('Bookmark');
    const [avatar, setAvatar] = useState();
    const [avatarText, setAvatarText] = useState();
    const [disabled, setDisabled] = useState();
    const [onClick, setOnClick] = useState('console.log');
    const [onDelete, setOnDelete] = useState();

    return (
      <Box>
        <Box>
          <Fieldset>
            <Fieldset.Field
              legend="icon"
              value={icon}
              onChange={setIcon}
              options={[undefined, 'Bookmark', 'Birthday', 'CheckBold']}
            />
            <Fieldset.Field
              legend="avatar"
              value={avatar}
              onChange={setAvatar}
              options={[undefined, './avatar.png']}
            />
            <Fieldset.Field
              legend="avatarText"
              value={avatarText}
              onChange={setAvatarText}
              options={[undefined, 'A', 'NB']}
            />
            <Fieldset.Field
              legend="disabled"
              value={disabled}
              onChange={setDisabled}
              options={[undefined, true, false]}
            />
            <Fieldset.Field
              legend="onClick"
              value={onClick}
              onChange={setOnClick}
              options={[undefined, 'console.log']}
            />
            <Fieldset.Field
              legend="onDelete"
              value={onDelete}
              onChange={setOnDelete}
              options={[undefined, 'console.log']}
            />
          </Fieldset>
        </Box>

        <Box component="pre">
          {`<Badge\n`}
          {`  icon="${icon}"\n`}
          {`  avatar="${avatar}"\n`}
          {`  avatarText="${avatarText}"\n`}
          {`  disabled={${disabled}}\n`}
          {`  onClick={${onClick}}\n`}
          {`  onDelete={${onDelete}}\n`}
          {`>\n`}
          {`  Badge text\n`}
          {`</Badge>\n`}
        </Box>

        <Box>
          <Badge
            icon={icon}
            avatar={!!avatar ? avatarExample : undefined}
            avatarText={avatarText}
            disabled={disabled}
            onClick={onClick ? console.log : undefined}
            onDelete={onDelete ? console.log : undefined}
          >
            Badge text
          </Badge>
        </Box>
      </Box>
    );
  };
  Badge.Demo = Demo;
}

export default Badge;
