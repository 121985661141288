import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import { getArray, capitalize } from 'utils/helpers';
import { useForm, useMessage, useAuth, useUploadPhotos } from 'hooks';
import {
  useGetMeQuery,
  useUpdateIdeaMutation,
  useCreateTagMutation,
  useGetTagsQuery,
  useGetLevelsQuery,
  useGetIdeaQuery,
} from 'store/api';

import { ProjectLayout } from 'layouts';
import { Container, Form, Paper } from 'components';
import { PageSubheader, IdeaForm } from 'views';
import { getInitialState as getInitialTextEditorState } from 'components/TextEditor';
import { getLevelStyles } from 'views/LevelTag';

import mapRequest from './mapRequest';

const contentWidth = 936;
const defaultSourceValue = { url: '' };

const mapInitialData = (idea) => {
  if (!idea) {
    return {
      sources: [defaultSourceValue],
    };
  }

  return {
    name: idea.name,
    copyright: idea.copyright,
    level: idea.level,
    public: idea.public,
    description: getInitialTextEditorState(idea.description),
    sources: idea.sources.map((source) => ({ url: source.link })),
    tags: idea.tags.map((tag) => ({ value: tag.id, label: tag.name })),
    photos: idea.photos.map((photo) => ({ url: photo })),
  };
};

const EditIdea = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'edit_idea' });
  const { auth, progress } = useAuth();
  const m = useMessage();

  const { form, valid, rules } = useForm();

  const {
    data: me,
    isLoading,
    isFetching,
  } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: idea,
    isLoading: ideaLoading,
    isFetching: ideaFetching,
  } = useGetIdeaQuery(
    {
      id,
    },
    { skip: !Boolean(id) },
  );

  const {
    data: levels,
    isLoading: levelsLoading,
    isFetching: levelsFetching,
  } = useGetLevelsQuery(undefined, {
    skip: !Boolean(me?.space_id),
  });

  const {
    data: tags,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
  } = useGetTagsQuery(
    { spaceId: me?.space_id },
    {
      skip: !Boolean(me?.space_id),
    },
  );

  const [createTag, createTagState] = useCreateTagMutation();
  const [updateIdea, updateIdeaState] = useUpdateIdeaMutation();
  const [uploadPhotos, uploadPhotosState] = useUploadPhotos({
    onUpload: ({ name, files }) => {
      form.setValue(name, files);
    },
  });

  useEffect(() => {
    form.reset(mapInitialData(idea));
  }, [form, idea]);

  const reset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleErrorResponse = useCallback(
    (err, generalMessage) => {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else if (!!err?.data?.detail?.error) {
        return m.error(err.data.detail.error);
      } else if (generalMessage) {
        m.error(generalMessage);
      }
    },
    [m],
  );

  const handleUpdateIdea = useCallback(
    async (formData) => {
      try {
        const { photos } = formData;
        const postData = mapRequest(formData);

        if (photos.length > 0) {
          const photoIds = await uploadPhotos('photos', photos);
          if (photoIds.length === 0) return;

          postData.photos = photoIds;
        }

        const { status } = await updateIdea({
          spaceId: me.space_id,
          id,
          ...postData,
        }).unwrap();

        if (status === 'OK') m.success(t('success_update'));
      } catch (err) {
        handleErrorResponse(err, t('error_failed_update'));
      }
    },
    [updateIdea, handleErrorResponse, id, uploadPhotos, me, m, t],
  );

  const handleCreateTag = useCallback(
    async (option, onOptionCreated) => {
      if (!option?.label) return;

      try {
        const response = await createTag({ name: option.label, spaceId: me?.space_id }).unwrap();
        const { id, name } = response;

        if (id) {
          onOptionCreated({ label: name, value: id });
        }
      } catch (err) {
        handleErrorResponse(err);
      }
    },
    [createTag, me, handleErrorResponse],
  );

  const tagsOptions = useMemo(() => {
    return getArray(tags?.tags).map((tag) => ({ label: tag.name, value: tag.id }));
  }, [tags]);

  const levelOptions = useMemo(() => {
    if (!levels) return [];
    return Object.entries(levels).map(([value, label]) => ({
      value,
      label: capitalize(label),
      sx: getLevelStyles({ level: value }),
    }));
  }, [levels]);

  const loading =
    isLoading ||
    isFetching ||
    progress ||
    uploadPhotosState.isLoading ||
    updateIdeaState.isLoading ||
    ideaLoading ||
    ideaFetching;

  const loadingTags = tagsLoading || tagsFetching || createTagState.isLoading;
  const loadingLevels = levelsLoading || levelsFetching;

  return (
    <Form width="100%" form={form} onSubmit={handleUpdateIdea}>
      <ProjectLayout
        subheader={
          <PageSubheader
            backUrl={`/ideas/${id}`}
            backTitle={t('back_button')}
            pageActions={{
              [t('page_actions.cancel')]: {
                onClick: reset,
                type: 'button',
                disabled: loading || !form.formState.isDirty,
                variant: 'outlined',
                size: 'small',
              },
              [t('page_actions.edit')]: {
                type: 'submit',
                size: 'small',
                disabled: !valid || loading || !form.formState.isDirty,
              },
            }}
          />
        }
      >
        <Container width={contentWidth} py={4}>
          <Typography width="100%" mb={3} color="secondary.main" variant="subtitle3">
            {t('page_title')}
          </Typography>

          <Paper width="100%" overflow="visible" pt={3} pb={5} px={9} loading={loading}>
            <IdeaForm
              title={t('form_title')}
              rules={rules}
              loadingTags={loadingTags}
              tagsOptions={tagsOptions}
              levelOptions={levelOptions}
              loadingLevels={loadingLevels}
              defaultSourceValue={defaultSourceValue}
              onCreateTag={handleCreateTag}
            />
          </Paper>
        </Container>
      </ProjectLayout>
    </Form>
  );
};

export default EditIdea;
