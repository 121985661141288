import { useMemo, useCallback } from 'react';
import { styled, Typography, Box, Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from 'utils/constants';
import { getArray, capitalize } from 'utils/helpers';
import { withProps, useAuth, useMessage } from 'hooks';
import { useGetMeQuery, useGetPortfolioQuery, useDeletePortfolioMutation } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container, IconButton, ImageHolder, Paper, PaperCard } from 'components';
import { PageSubheader, CardContent } from 'views';

import SharePortfolio from './SharePortfolio';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const Text = styled(Typography)(({ theme }) => ({}));

const Portfolio = (props) => {
  const { backUrl = '/portfolio' } = props;

  const { id } = useParams();
  const { pathname } = useLocation();
  const { t } = useTranslation('pages', { keyPrefix: 'single_portfolio' });
  const { auth } = useAuth();
  const m = useMessage();
  const navigate = useNavigate();

  const { data: me } = useGetMeQuery(undefined, {
    skip: !auth,
  });

  const {
    data: portfolio,
    isLoading,
    isFetching,
  } = useGetPortfolioQuery(
    {
      id,
    },
    { skip: !Boolean(id) || !auth },
  );

  const [deletePortfolio, deletePortfolioState] = useDeletePortfolioMutation();

  const handleDeletePortfolio = useCallback(async () => {
    if (!id) {
      m.error(t('error.failed_delete'));
      return;
    }

    const result = window.confirm(t('ask_delete_permission'));
    if (!result) return;

    try {
      const { status } = await deletePortfolio({
        id,
      }).unwrap();

      if (status === 'OK') {
        navigate(backUrl);
      } else {
        m.error(t('error.failed_delete'));
      }
    } catch (err) {
      if (err.status === 422) {
        err.data.detail.forEach((item) => {
          m.error(item.msg);
        });
      } else {
        m.error(t('error.failed_delete'));
      }
    }
  }, [deletePortfolio, navigate, id, backUrl, t, m]);

  const isOwner = me && portfolio && me?.id === portfolio?.user;
  const loading = isLoading || isFetching || deletePortfolioState.isLoading;

  const pageActions = useMemo(() => {
    const actions = {};

    actions[t('page_actions.edit')] = {
      type: 'button',
      disabled: loading,
      variant: 'outlined',
      size: 'small',
      name: 'Edit',
      border: true,
      Component: IconButton,
      to: `${pathname}/edit`,
      aclConfig: {
        permissions: [PERMISSIONS.EDIT_PORTFOLIO],
      },
    };
    actions[t('page_actions.delete')] = {
      type: 'button',
      size: 'small',
      disabled: loading,
      color: 'error',
      name: 'Delete',
      Component: IconButton,
      onClick: handleDeletePortfolio,
      aclConfig: {
        permissions: [PERMISSIONS.DELETE_PORTFOLIO],
      },
    };

    if (isOwner) {
      actions[t('page_actions.share_portfolio')] = {
        type: 'button',
        size: 'small',
        disabled: loading,
        radius: 2,
        sx: { paddingLeft: 0, paddingRight: 0 },
        portfolioId: id,
        Component: SharePortfolio,
        onClick: handleDeletePortfolio,
      };
    }

    return actions;
  }, [id, loading, handleDeletePortfolio, isOwner, pathname, t]);

  return (
    <ProjectLayout
      subheader={
        portfolio && (
          <PageSubheader
            title={portfolio.name}
            backUrl={backUrl}
            backTitle={t('back_button')}
            pageActions={pageActions}
          />
        )
      }
    >
      <Article py={13} pb={16}>
        <Container>
          <Typography variant="subtitle5" component="h3" mb={2} color="textBlack.dark">
            {t('about_me')}
          </Typography>
          <Paper shadow="standard" py={3}>
            <Center>
              <ImageHolder size={128} src={portfolio?.photo} flexShrink={0} />
              <Box pl={2} flexGrow={1}>
                {portfolio && (
                  <Typography variant="body2" color="textBlack.dark">
                    {portfolio.first_name} {portfolio.last_name}
                  </Typography>
                )}
                <Text
                  component="div"
                  variant="paragraph"
                  dangerouslySetInnerHTML={{ __html: portfolio?.about_me }}
                />
              </Box>
            </Center>
          </Paper>

          {getArray(portfolio?.project_cards).map((group) => (
            <Box mt={4} key={`group-${group.group_name}`}>
              <Typography variant="subtitle5" component="h3" mb={2} color="textBlack.dark">
                {group?.group_id ? capitalize(group.group_name) : t('my_projects')}
              </Typography>

              <Grid container spacing={3}>
                {getArray(group.project_cards).map((project) => (
                  <Grid key={`projects-grid-${project.id}`} item xs={4}>
                    <PaperCard
                      actionAreaStyle={{ p: 0 }}
                      to={`/learning/student-project/${project.project}`}
                    >
                      <CardContent
                        variant="student_work"
                        title={project.name}
                        photo={project.avatar}
                        description={project.description}
                      />
                    </PaperCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default Portfolio;
