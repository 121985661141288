import { Box, styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';

import { dev } from 'utils/config';
import { getArray } from 'utils/helpers';

import { ImageHolder, SwiperNavigation } from 'components';

import gallery1 from 'assets/images/examples/gallery1.png';
import gallery2 from 'assets/images/examples/gallery2.png';
import gallery3 from 'assets/images/examples/gallery3.png';
import gallery4 from 'assets/images/examples/gallery4.png';
import gallery5 from 'assets/images/examples/gallery5.png';
import projectPreview from 'assets/images/examples/project_preview.png';

const GalleryWrapper = styled(Box)(({ theme }) => ({
  ' & .swiper': {
    padding: '0 12px',
    margin: '0 -20px',
  },
  ' & .swiper-wrapper': {
    paddingTop: '12px',
  },
  ' & .swiper-pagination': {
    bottom: 'initial',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 4,
    paddingRight: 20,
  },
  ' & .swiper-pagination-bullet': {
    width: 24,
    height: 4,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.other.paginationBullet,
  },
  ' & .swiper-pagination-bullet-active': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const GalleryItemHolder = styled(Box, {
  shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})(({ theme, isVisible }) => ({
  transition: theme.transitions.create(),
  width: '100%',
  opacity: isVisible ? 1 : 0,
  boxSizing: 'border-box',
  '& > .MuiBox-root': { width: '100%', boxSizing: 'border-box' },
}));

const Gallery = (props) => {
  const { images, slidesPerView = 5, ...rest } = props;

  return (
    <GalleryWrapper>
      <Swiper
        watchSlidesProgress
        spaceBetween={0}
        slidesPerView={slidesPerView}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        {...rest}
      >
        {getArray(images).map((image) => (
          <SwiperSlide key={image.src}>
            {({ isVisible }) => (
              <GalleryItemHolder isVisible={isVisible} px={1}>
                <ImageHolder
                  sx={{ backgroundColor: 'other.white', boxShadow: 'smooth' }}
                  p={1}
                  fit="cover"
                  width="100%"
                  height={106}
                  src={image.src}
                />
              </GalleryItemHolder>
            )}
          </SwiperSlide>
        ))}

        <SwiperNavigation />
      </Swiper>
    </GalleryWrapper>
  );
};

if (dev) {
  const slides = [
    {
      label: 'Project 1',
      src: gallery1,
    },
    {
      label: 'Project 2',
      src: gallery2,
    },
    {
      label: 'Project 3',
      src: gallery3,
    },
    {
      label: 'Project 4',
      src: gallery4,
    },
    {
      label: 'Project 5',
      src: gallery5,
    },
    {
      label: 'Project 6',
      src: projectPreview,
    },
  ];

  const Demo = () => {
    return (
      <Box p={2}>
        <Gallery images={slides} />
      </Box>
    );
  };

  Gallery.Demo = Demo;
}

export default Gallery;
