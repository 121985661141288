import { TEACHER_PERMISSIONS } from 'utils/constants';

const mapRequest = (me, formData) => {
  let postData = {
    first_name: me?.profile?.first_name || '',
    last_name: me?.profile?.last_name || '',
    email: me?.email || '',
    phone_number: me?.phone_number || null,
    avatar_file_id: me?.profile?.avatar || '',
    language: me?.profile?.language || 'en',
    permissions: { super_admin: true },
  };

  Object.entries(TEACHER_PERMISSIONS).forEach(([name, permissions]) => {
    permissions.forEach((permission) => {
      postData.permissions[permission] = formData[name];
    });
  });

  return postData;
};

export default mapRequest;
