import { LEVEL } from 'utils/constants';

import { Tag } from 'components';

export const getLevelStyles = ({ level, border }) => {
  switch (level) {
    case LEVEL.EASY:
      return {
        border: border && '1px solid',
        borderColor: border && 'primary.light',
        color: 'success.dark',
        backgroundColor: 'success.contrastText',
      };
    case LEVEL.MEDIUM:
      return {
        border: border && '1px solid',
        borderColor: border && 'warning.dark',
        color: 'warning.dark',
        backgroundColor: 'warning.contrastText',
      };
    case LEVEL.HARD:
      return {
        border: border && '1px solid',
        borderColor: border && 'error.dark',
        color: 'error.dark',
        backgroundColor: 'error.contrastText',
      };
    default:
      return {};
  }
};

const LevelTag = (props) => {
  const { level, border, ...rest } = props;

  return <Tag sx={getLevelStyles({ level, border })} {...rest} />;
};

export default LevelTag;
