import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { allowedImageTypes } from 'utils/constants';
import { useForm } from 'hooks';

import { Modal, Center, Button, FormRow, Input, TextEditor, Dropzone, Form } from 'components';

const AddProgressPhotosModal = (props) => {
  const { loading, open, onClose, onSubmit } = props;

  const { t } = useTranslation('views', { keyPrefix: 'AddProgressPhotosModal' });
  const {
    form,
    rules: { required, description },
    valid,
  } = useForm();

  const handleClose = useCallback(() => {
    form.reset();
    typeof onClose === 'function' && onClose();
  }, [onClose, form]);

  useEffect(() => {
    if (!open) form.reset();
  }, [open, form]);

  return (
    <Modal size={516} open={open} onClose={handleClose}>
      <Modal.Body>
        <Form stopPropagation form={form} pt={4} pb={2} onSubmit={onSubmit}>
          <Box textAlign="center" mb={2}>
            <Typography variant="subtitle3" color="success.dark">
              {t('add_photos')}
            </Typography>
          </Box>

          <Dropzone.Control
            accept={allowedImageTypes}
            minHeight={128}
            name="photo"
            rules={{ required }}
            fullWidth
            mb={3}
          />

          <FormRow labelWidth={80} label={t('name.label')} mb={2}>
            <Input.Control
              fullWidth
              rules={{ required, minLength: 6 }}
              name="name"
              placeholder={t('name.placeholder')}
            />
          </FormRow>
          <FormRow labelWidth={80} label={t('description.label')} alignItems="flex-start">
            <TextEditor.Control
              fullWidth
              rules={{ description, required, minLength: 6 }}
              name="description"
              placeholder={t('description.placeholder')}
            />
          </FormRow>

          <Center mt={3} gap={1.5}>
            <Button type="submit" radius={2} size="small" disabled={!valid || loading}>
              {t('save')}
            </Button>
            <Button
              type="button"
              radius={2}
              variant="outlined"
              size="small"
              onClick={() => {
                form.reset();
              }}
            >
              {t('cancel')}
            </Button>
          </Center>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddProgressPhotosModal;
