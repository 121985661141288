import { getArray } from 'utils/helpers';

export const mapIdeasRequest = ({ spaceId, filters, pagination }) => {
  const { search, activeFilters } = filters;

  const { tags, levels } = activeFilters || {};

  const perPage = pagination?.perPage || 10;
  const offset = perPage * (pagination.page - 1);

  return {
    spaceId: spaceId,
    name: search || null,
    tags: getArray(tags).map((tag) => tag.id),
    levels: getArray(levels).map((level) => level.id),
    limit: perPage,
    offset: offset,
  };
};

export const mapProjectsRequest = ({ spaceId, filters, pagination, lazy, status }) => {
  const { search, activeFilters } = filters;

  const { tags, levels } = activeFilters || {};

  const currentPage = pagination?.page || 1;
  const perPage = pagination?.perPage || 10;

  const limit = lazy ? perPage * currentPage : perPage;
  const offset = lazy ? 0 : perPage * (pagination.page - 1);

  return {
    space_id: spaceId,
    name: search || null,
    tags: getArray(tags).map((tag) => tag.id),
    levels: getArray(levels).map((level) => level.id),
    limit,
    offset,
    status,
  };
};
