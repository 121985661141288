import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { isObject } from 'utils/helpers';
import { palette } from 'styles/theme';

const Palette = (props) => {
  const { name, value } = props;

  if (typeof name !== 'string' ) {
    return null;
  }
  if (!value || (typeof value !== 'string' && typeof value !== 'object')) {
    return null;
  }
  if (typeof value === 'string') {
    return (
      <Box display="flex" alignItems="center" gap={1} mb={0.5}>
        <Box
          display="inline-flex"
          borderRadius={1}
          bgcolor={value}
          width={24}
          height={24}
          border="1px solid black"
        />
        <Box ml={1} component="strong" width="90px">{value}</Box>
        <span>{name}</span>
      </Box>
    );
  }
  return (
    <Box mb={3}>
      {Object.entries(value).map(([nestedName, nestedValue]) => (
        <Palette
          key={`${name}.${nestedName}`}
          name={`${name}.${nestedName}`}
          value={nestedValue}
        />
      ))}
    </Box>
  );
};

const Theme = () => {
  const theme = useTheme();
  const typography = useMemo(() => {
    return Object.entries(theme.typography).reduce((res, [name, value]) => {
      return isObject(value) ? [...res, name] : res;
    }, []);
  }, [theme]);

  const paletteKeys = useMemo(() => {
    const priority = ['primary', 'secondary', 'success', 'warning', 'error'];

    return Object.keys(palette).sort((a, b) => {
      if (priority.includes(a) && priority.includes(b)) {
        return priority.indexOf(a) < priority.indexOf(b);
      }
      if (priority.includes(a) && !priority.includes(b)) {
        return -1;
      }
      if (!priority.includes(a) && priority.includes(b)) {
        return 1;
      }
      return a.localeCompare(b);
    });
  }, []);

  return (
    <Box p={4}>
      <Box>
        <Typography variant="subtitle2">theme.palette</Typography>

        {paletteKeys.map((name) => (
          <Box key={name}>
            <Palette
              name={name}
              value={palette[name]}
            />
          </Box>
        ))}
      </Box>

      <Box mt={8}>
        <Typography variant="subtitle2">theme.typography</Typography>

        <Box display="flex" gap={4} mt={2} flexDirection="column">
          {typography.map((name) => (
            <Typography key={name} variant={name}>typography.{name} example text</Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Theme;
