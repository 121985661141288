import { Stack as MuiStack, Box, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

import { allowedImageTypes } from 'utils/constants';
import { withProps, useRules, useCountrySearch, useUsersSearch } from 'hooks';

import {
  Form,
  FormRow,
  PhoneField,
  Input,
  TextEditor,
  Dropzone,
  Select,
  Checkbox,
  IconButton,
  Button,
  FormTitle,
  SelectMultiple,
} from 'components';

const Stack = withProps(MuiStack, {
  spacing: 2,
  alignItems: 'center',
});

const StudentGuardiansInformationForm = (props) => {
  const { id, spaceId, relations, onSubmit, onSelectParentProfile, form } = props;

  const { phone, email, required } = useRules();

  const { t } = useTranslation('views', { keyPrefix: 'StudentGuardiansInformationForm' });

  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'parents',
    keyName: 'fieldId',
  });

  const { options: parentOptions, onSearch: onParentSearch } = useUsersSearch({
    spaceId,
    mapToOptions: true,
    filters: {
      limit: 20,
      roles: ['parent'],
      groups: [],
    },
  });

  const {
    options: countryOptions,
    search: countrySearch,
    onSearch: onCountrySearch,
  } = useCountrySearch({
    mapToOptions: true,
  });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      {fields.map((field, index) => (
        <Box position="relative" key={field.fieldId}>
          <IconButton
            radius={1}
            border
            size="xsmall"
            name="Delete"
            variant="outlined"
            color="error"
            onClick={() => remove(index)}
            sx={{ position: 'absolute', right: 0, top: 0 }}
          />
          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('guardian_information')}
            </Typography>
          </FormTitle>
          <Stack mt={2} mb={2} width="100%">
            <FormRow label={t('avatar.label')} alignItems="flex-start">
              <Dropzone.Control
                fullWidth
                accept={allowedImageTypes}
                name={`parents.${index}.avatar_file_id`}
              />
            </FormRow>
            <FormRow label={t('full_name.label')} alignItems="flex-start">
              <SelectMultiple.Control
                fullWidth
                creatable
                name={`parents.${index}.full_name`}
                placeholder={t('full_name.placeholder')}
                itemsMax={4}
                max={1}
                options={parentOptions}
                onInputChange={onParentSearch}
                onCreate={(option) => {
                  form.setValue(`parents.${index}.full_name`, [
                    { label: option.label, value: option.label },
                  ]);
                }}
                onValue={(value) => {
                  const [option] = value;
                  if (option?.value !== option?.label) {
                    typeof onSelectParentProfile === 'function' &&
                      onSelectParentProfile(option, { name: `parents.${index}.full_name`, index });
                  }
                }}
              />
            </FormRow>
            <FormRow label={t('relation.label')} alignItems="flex-start">
              <Select.Control
                fullWidth
                name={`parents.${index}.relation`}
                placeholder={t('relation.placeholder')}
                options={relations}
                max={1}
                rules={{ required }}
              />
              <Box pl={1}>
                <Checkbox.Control
                  size="xsmall"
                  name={`parents.${index}.is_emergency_contact`}
                  label={t('is_emergency_contact.placeholder')}
                />
                <Checkbox.Control
                  size="xsmall"
                  name={`parents.${index}.notify_projects`}
                  label={t('notify_projects.placeholder')}
                />
              </Box>
            </FormRow>
          </Stack>

          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('contact_section')}
            </Typography>
          </FormTitle>
          <Stack mt={2} mb={2} width="100%">
            <FormRow label={t('phone_number.label')}>
              <PhoneField.Control
                optional
                fullWidth
                name={`parents.${index}.phone_number`}
                placeholder={t('phone_number.placeholder')}
                rules={{
                  phone,
                }}
              />
            </FormRow>

            <FormRow label={t('email.label')}>
              <Input.Control
                fullWidth
                type="email"
                name={`parents.${index}.email`}
                placeholder={t('email.placeholder')}
                rules={{
                  validate: email,
                  required,
                }}
              />
            </FormRow>
          </Stack>

          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('notes')}
            </Typography>
          </FormTitle>
          <Stack mt={2} mb={2} width="100%">
            <FormRow label={t('note.label')} alignItems="flex-start">
              <TextEditor.Control
                optional
                fullWidth
                name={`parents.${index}.note`}
                placeholder={t('note.placeholder')}
              />
            </FormRow>
          </Stack>

          <FormTitle width="100%">
            <Typography color="secondary.main" variant="body2">
              {t('address_section')}
            </Typography>
          </FormTitle>
          <Stack mt={2} mb={2} width="100%">
            <FormRow label={t('address.label')} alignItems="flex-start">
              <Input.Control
                fullWidth
                name={`parents.${index}.address`}
                placeholder={t('address.placeholder')}
              />
            </FormRow>
            <FormRow label={t('apart.label')} alignItems="flex-start">
              <Input.Control
                fullWidth
                name={`parents.${index}.apart`}
                placeholder={t('apart.placeholder')}
              />
            </FormRow>
            <FormRow label={t('country.label')} alignItems="flex-start">
              <Select.Control
                fullWidth
                name={`parents.${index}.country`}
                placeholder={t('country.placeholder')}
                itemsMax={4}
                options={countryOptions}
                searchable
                search={countrySearch}
                onSearch={onCountrySearch}
              />
            </FormRow>
            <FormRow label={t('city.label')} alignItems="flex-start">
              <Input.Control
                fullWidth
                name={`parents.${index}.city`}
                placeholder={t('city.placeholder')}
              />
            </FormRow>
            <FormRow label={t('state.label')} alignItems="flex-start">
              <Grid container>
                <Grid item xs={6} pr={1}>
                  <Input.Control
                    fullWidth
                    name={`parents.${index}.state`}
                    placeholder={t('state.placeholder')}
                  />
                </Grid>
                <Grid item xs={6} pl={1}>
                  <Input.Control
                    fullWidth
                    name={`parents.${index}.zipcode`}
                    placeholder={t('zipcode.placeholder')}
                  />
                </Grid>
              </Grid>
            </FormRow>
          </Stack>
        </Box>
      ))}
      <FormRow mt={3}>
        <Button type="button" variant="outlined" iconLeft="Add" onClick={() => append({})}>
          {t('add_new', { count: fields.length + 1 })}
        </Button>
      </FormRow>
    </Form>
  );
};

export default StudentGuardiansInformationForm;
