import { useCallback } from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useForm } from 'hooks';

import { Avatar, Input, Center, Button, Form } from 'components';

const CommentAction = styled(Button)({});

const getDefaultValue = (commentToReply) => {
  if (!commentToReply) return '';
  return `${commentToReply?.author_name}, `;
};

const CommentCompose = (props) => {
  const { disabled, me, commentToReply, onSendComment, onInteraction, onCancel } = props;

  const { t } = useTranslation('components', { keyPrefix: 'Comment.compose' });

  const {
    form,
    valid,
    rules: { required },
  } = useForm();

  const cancel = useCallback(() => {
    form.reset();
    onCancel && onCancel();
  }, [form, onCancel]);

  const handleFormSubmit = useCallback(
    (formData) => {
      if (!onSendComment) return;
      onSendComment({ ...formData, replyCommentId: commentToReply?.id });
      cancel();
    },
    [cancel, commentToReply, onSendComment],
  );

  return (
    <Form width="100%" form={form} onSubmit={handleFormSubmit}>
      <Box display="flex" mb={3}>
        <Box mr={2} flexShrink={0}>
          <Avatar badge src={me?.profile?.avatar} />
        </Box>
        <Box flexGrow={1} onClick={onInteraction}>
          <Center width="100%">
            <Input.Control
              name="text"
              disabled={disabled}
              fullWidth
              placeholder={disabled ? t('not_active') : t('type')}
              initFocus={Boolean(commentToReply)}
              defaultValue={getDefaultValue(commentToReply)}
              rules={{ required }}
            />
          </Center>

          <Center mt={2} sx={{ gap: '12px' }} justifyContent="flex-start">
            <CommentAction type="submit" disabled={!valid || disabled} size="small">
              {t('send')}
            </CommentAction>
            <CommentAction
              type="button"
              disabled={disabled}
              variant="outlined"
              size="small"
              onClick={cancel}
            >
              {t('cancel')}
            </CommentAction>
          </Center>
        </Box>
      </Box>
    </Form>
  );
};

export default CommentCompose;
