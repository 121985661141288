import { useMemo } from 'react';
import { useSnackbar } from 'notistack';

const useMessage = (defaultVariant = 'success') => {
  const { enqueueSnackbar } = useSnackbar();

  const message = useMemo(() => {
    const messanger = function(text, variant = defaultVariant, config = {}) {
      enqueueSnackbar(text, { variant, ...config });
    };
    messanger.default = (text, ...rest) => messanger(text, 'default', ...rest);
    messanger.success = (text, ...rest) => messanger(text, 'success', ...rest);
    messanger.error = (text, ...rest) => messanger(text, 'error', ...rest);
    messanger.info = (text, ...rest) => messanger(text, 'info', ...rest);
    messanger.warning = (text, ...rest) => messanger(text, 'warning', ...rest);
    messanger.warn = messanger.warning;
    return messanger;
  }, [enqueueSnackbar, defaultVariant]);

  return message;
};

export default useMessage;
