import { useMemo, useState } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetUserAchievementsQuery, useGetMeQuery } from 'store/api';

import { Tabs, Center, LinearProgress, Paper } from 'components';

import { IconLevel, IconExp } from './icons';

const Card = styled(Box, { label: 'ExperienceCard' })(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.controls.main,
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
}));

const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: '5px',
  backgroundColor: theme.palette.progress.contrastText,
  '& > span': {
    backgroundColor: theme.palette.progress.primary,
  },
}));

const Experience = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });

  const [tab, setTab] = useState('today');

  const { data: me } = useGetMeQuery();
  const {
    data: achievements,
    isLoading,
    isFetching,
  } = useGetUserAchievementsQuery({ time: tab, user_id: me?.id }, { skip: !me });

  const tabOptions = [
    {
      value: 'today',
      label: t('experience.tabs.today'),
    },
    {
      value: 'week',
      label: t('experience.tabs.week'),
    },
    {
      value: 'month',
      label: t('experience.tabs.month'),
    },
    {
      value: 'year',
      label: t('experience.tabs.year'),
    },
  ];

  const progress = useMemo(() => {
    if (!achievements) return;

    return (achievements.current_points / achievements.points_to_next_level) * 100;
  }, [achievements]);

  const loading = isLoading || isFetching;

  return (
    <Paper shadow="small" width={1} loading={loading} overflow="visible">
      <Center mb={2} justifyContent="space-between" width={1}>
        <Typography variant="subtitle5" color="textBlack.dark">
          {t('experience.title')}
        </Typography>

        <Tabs
          width={396}
          variant="fullWidth"
          sx={{ backgroundColor: 'other.white' }}
          value={tab}
          options={tabOptions}
          onValue={setTab}
        />
      </Center>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Card>
            <Box flexShrink={0} width={80}>
              <IconLevel level={achievements?.current_level || 1} />
            </Box>

            <Box pl={1} flexGrow={1}>
              <Typography variant="subtitle5">
                {t('experience.level', { level: achievements?.current_level || 1 })}
              </Typography>
              <Typography component="div" variant="body4" color="textBlack.light" mb={1.5}>
                {t('experience.points_to_next_level', {
                  points: achievements?.points_to_next_level || 0,
                })}
              </Typography>

              <StyledProgress variant="determinate" value={progress} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <Box flexShrink={0} width={80}>
              <IconExp level={1} />
            </Box>

            <Box pl={1} flexGrow={1}>
              <Typography variant="subtitle5" color="success.dark">
                + {achievements?.filter_points}
              </Typography>
              <Typography component="div" variant="body4" color="textBlack.light" mb={1.5}>
                {t('experience.points_earned')}
              </Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Experience;
