import { useState, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Collapse } from '@mui/material';

import { getArray } from 'utils/helpers';
import { useRules } from 'hooks';

import { Form } from 'components';
import { ProjectSearch } from 'views';

const InternalProjectSearch = (props) => {
  const { name, projects, onValue, ...rest } = props;

  const [search, setSearch] = useState('');

  const filteredProjects = useMemo(() => {
    if (!projects) return [];
    if (!search) return getArray(projects);

    return getArray(projects).filter((project) =>
      project.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [projects, search]);

  return (
    <ProjectSearch.Control
      name={name}
      projects={filteredProjects}
      search={search}
      onSearch={setSearch}
      max={3}
      {...rest}
    />
  );
};

const GroupProjectSearchForm = (props) => {
  const { id, onSubmit, activeGroup, form } = props;

  const { required, requiredArray } = useRules();

  const { fields } = useFieldArray({
    control: form.control,
    name: 'groups',
    keyName: 'fieldId',
  });

  return (
    <Form id={id} width="100%" form={form} onSubmit={onSubmit}>
      {fields.map((field, index) => (
        <Collapse key={field.fieldId} in={field.value === activeGroup?.value}>
          <InternalProjectSearch
            rules={{ required, requiredArray }}
            projects={field.projects}
            name={`groups.${index}.selected`}
          />
        </Collapse>
      ))}
    </Form>
  );
};

export default GroupProjectSearchForm;
