import { styled, Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { withProps, useAuth } from 'hooks';
import { useGetGroupProjectStatisticsQuery, useGetGroupQuery } from 'store/api';

import { ProjectLayout } from 'layouts';
import { Center, Container } from 'components';
import { PageSubheader, RelatedProjects, ProjectStatus, AverageTimeSpentCard } from 'views';

import NavigationTabs from './NavigationTabs';

const Article = styled(withProps(Center, { component: 'article' }), {
  label: 'Article',
})(({ alignItems }) => ({
  alignItems: alignItems || 'normal',
}));

const GroupProjects = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'single_group' });
  const { auth } = useAuth();

  const { data: group } = useGetGroupQuery({ id }, { skip: !Boolean(id) || !auth });

  const { data: projectStatistics } = useGetGroupProjectStatisticsQuery(
    { id: id },
    { skip: !Boolean(id) },
  );

  const statusItems = [
    {
      count: projectStatistics?.statistics?.assigned,
      percentage: projectStatistics?.statistics?.assigned_percent,
      label: t('statistics.assigned'),
    },
    {
      count: projectStatistics?.statistics?.progress,
      percentage: projectStatistics?.statistics?.progress_percent,
      label: t('statistics.progress'),
    },
    {
      count: projectStatistics?.statistics?.completed,
      percentage: projectStatistics?.statistics?.completed_percent,
      label: t('statistics.completed'),
    },
    {
      count: projectStatistics?.statistics?.published,
      percentage: projectStatistics?.statistics?.published_percent,
      label: t('statistics.published'),
    },
    {
      count: projectStatistics?.statistics?.abandoned,
      percentage: projectStatistics?.statistics?.abandoned_percent,
      label: t('statistics.abandoned'),
    },
  ];

  return (
    <ProjectLayout
      subheader={
        <PageSubheader
          title={group?.name}
          backUrl="/administration/groups"
          backTitle={t('back_button')}
          pageActions={{
            [t('create_portfolio')]: {
              type: 'button',
              size: 'small',
              to: '/portfolio/new',
            },
          }}
        />
      }
    >
      <Article py={4}>
        <Container>
          <Box mb={3}>
            <NavigationTabs tab="projects" groupId={group?.id} />
          </Box>
          <Box mb={3}>
            <ProjectStatus title={t('project_status')} items={statusItems} />
          </Box>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <AverageTimeSpentCard
                title={t('average_time_spent_on_project')}
                value={projectStatistics?.time_spent?.project}
              />
            </Grid>
            <Grid item xs={6}>
              <AverageTimeSpentCard
                title={t('average_time_spent_on_collection')}
                value={projectStatistics?.time_spent?.collection}
              />
            </Grid>
          </Grid>
          <Box>
            <RelatedProjects projects={group?.projects} />
          </Box>
        </Container>
      </Article>
    </ProjectLayout>
  );
};

export default GroupProjects;
